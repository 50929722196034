import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';

export function getStatus(documentStatus: string | null): DocumentStatusEnum {
  const existingStatuses = Object.values(DocumentStatusEnum);

  if (!documentStatus) {
    return DocumentStatusEnum.DRAFT;
  }

  if (!existingStatuses.includes(documentStatus as DocumentStatusEnum)) {
    throw new Error('Expected existing document status');
  }

  return documentStatus as DocumentStatusEnum;
}

export const getClassNameForStatus = (documentStatus: DocumentStatusEnum): string => {
  return `lease-badge--${documentStatus.replace(/[_]/g, '-')}`;
};
