import { createDocumentImage as createDocumentImageMutation } from '../api/mutations';

const MAX_UPLOAD_SIZE_IN_BYTES = 2 * 1024 * 1024;
const IMAGE_TOO_BIG_ERROR = 'Image size is too large, please select a smaller file';

export default class SimpleleaseImageUploadAdapter {
  constructor(loader, apolloClient, documentId, errorNotifier) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.apolloClient = apolloClient;
    this.documentId = documentId;
    this.errorNotifier = errorNotifier;
  }

  _arrayBufferToBase64(buffer) {
    const bytes = new Uint8Array(buffer);
    let binary = '';

    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  }

  upload() {
    return this.loader.file.then(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('load', event => {
          const base64 = this._arrayBufferToBase64(event.target.result);

          if (file.size > MAX_UPLOAD_SIZE_IN_BYTES) {
            this.errorNotifier({ message: IMAGE_TOO_BIG_ERROR });
            reject();
          }

          const variables = {
            documentId: this.documentId,
            documentType: 'offer',
            fileData: { filename: file.name, content: base64, contentEncodingType: 'base64' },
          };
          this.apolloClient
            .mutate({ mutation: createDocumentImageMutation, variables })
            .then(response => {
              const imagePath = response?.data?.createDocumentImage?.path;
              const imageURL = `${process.env.REACT_APP_API}${imagePath}`;
              const resolveData = { default: imageURL };
              resolve(resolveData);
            })
            .catch(e => reject(`Error upload file: ${e.message}`));
        });
        reader.addEventListener('error', () => reject(`Error reading file: ${file.name}`));
        reader.readAsArrayBuffer(file);
      });
    });
  }

  abort() {
    console.log('IMAGE UPLOAD ABORT!');
  }
}
