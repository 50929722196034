import React from 'react';
import { FormattedMessage } from 'react-intl';
import PermissionIcon from '../../permission-icon';
import { description as permissionDescription, name as permissionName } from '../../../utils/permission-messages';
import styles from '../styles.module.scss';

const PermissionsChart = ({ allPermissions, renderState }) => (
  <ul className={styles.root}>
    {allPermissions.map((permission, i) => (
      <li key={permission.key} className={styles.row}>
        <div className={styles.iconContainer}>
          <PermissionIcon permission={permission} withAltText={false} className="text-primary" />
        </div>
        <div className={styles.textContainer}>
          <h4 className="m-0">
            <FormattedMessage {...permissionName(permission)} />
          </h4>
          <p className="m-0">
            <FormattedMessage {...permissionDescription(permission)} />
          </p>
        </div>
        <div className={styles.stateContainer}>{renderState(permission)}</div>
      </li>
    ))}
  </ul>
);

export default PermissionsChart;
