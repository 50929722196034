import { UnsignDocumentConfirmationModalContainer } from '../../../../lease-document-wizard-custom-stack/unsign-document-confirmation-modal/UnsignDocumentConfirmationModalContainer';
import React from 'react';

interface Props {
  show: boolean;
  onHide: () => void;
  dealId: string;
  teamId: string;
  documentId?: string;
}

const CancelExternalSigningModal = ({ show, onHide, dealId, teamId, documentId }: Props) => {
  return <UnsignDocumentConfirmationModalContainer show={show} onHide={onHide} dealId={dealId} teamId={teamId} documentId={documentId} />;
};

export default CancelExternalSigningModal;
