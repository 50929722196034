import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  selectInteractionsFeedToken,
  selectLeaseDocumentId,
} from '../../../../../store/lease-document/selectors/lease-document.selectors';
import { Card } from './components/card';
import { ActivityCardEnum } from '../../../../card/ActivitiesCardConstants';
import { FlatFeed, StreamApp } from 'react-activity-feed';
import { DocumentActivityTypesEnum } from './documentActivityDropdown';
import { ActivitiesNavigation, ActivityPropsType, DocumentActivityActions } from './components/constants';
import { getCommentsForLeaseDocumentSection } from '../../../../../utils/api/get-comments-for-lease-document-section/get-comments-for-lease-document-section';
import { useApolloClient } from '@apollo/client';
import { getDocumentSuggestionById } from '../../../../../utils/api/get-document-suggestion-by-id/get-document-suggestion-by-id';
import { ReactComponent as ChevronRightIcon } from '../../../../../shared/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../../../shared/icons/chevron-left.svg';
import styles from './components/styles.module.scss';
import { RenderDocumentActivityData } from './helpers';
import { FormattedMessage } from 'react-intl';
import { TitleFormattedMessage } from '../../../../titleFormattedMessage';

type Props = {
  currentDocumentActivityType: DocumentActivityTypesEnum;
  shouldAllActivitiesBeShown: boolean;
};

const MAX_ACTIVITIES_NUMBER_TO_DISPLAY = 99;

const API_KEY = process.env.REACT_APP_GETSTREAM_API_KEY as string;
const APP_ID = process.env.REACT_APP_GETSTREAM_APP_ID as string;

const DocumentActivitySidebar = ({ currentDocumentActivityType, shouldAllActivitiesBeShown }: Props) => {
  const interactionsFeedToken = useSelector(selectInteractionsFeedToken);
  const documentId = useSelector(selectLeaseDocumentId);
  const [incomingActivitiesCount, setIncomingActivitiesCount] = useState(0);
  const [selectedActivityId, setSelectedActivityId] = useState<string | null>();
  const [hoveredActivityId, setHoveredActivityId] = useState<string | null>();
  const [isActivityDataLoading, setIsActivityDataLoading] = useState(false);
  const [activitiesNumber, setActivitiesNumber] = useState(0);
  const [selectedActivityState, setSelectedActivityState] = useState<string | null>(null);
  const apolloClient = useApolloClient();

  setTimeout(() => {
    setActivitiesNumber(document.getElementsByClassName('activityButton').length);
  }, 1000);

  const handleActivityNavigation = (action: ActivitiesNavigation) => {
    const currentActivityButton = document.getElementById(`button--${selectedActivityId}`);
    const startActivityButton = document.querySelector('[name="document-activity-item-button"]') as HTMLElement;

    const siblingToNavigate = !currentActivityButton
      ? startActivityButton
      : action === ActivitiesNavigation.NEXT
      ? (currentActivityButton?.nextSibling as HTMLElement)
      : (currentActivityButton?.previousSibling as HTMLElement);

    const siblingId = siblingToNavigate?.id?.split('--')[1];

    siblingToNavigate?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });

    siblingToNavigate?.click();

    setHoveredActivityId(siblingId);
  };

  const handleActivityClick = useCallback(
    async ({
      activityProps,
      sectionId,
      activityDatabaseId,
    }: {
      activityProps: ActivityPropsType;
      sectionId: string;
      activityDatabaseId: string;
    }) => {
      setIsActivityDataLoading(true);
      setSelectedActivityState(null);
      setSelectedActivityId(activityProps.id);

      const annotationThreads = window.editor.plugins.get('Annotations')._visibleAnnotations._items;

      const isCommentActive = window.document.querySelectorAll(`[data-comment=${sectionId}]`);
      const isSuggestionActive = window.document.querySelectorAll(
        `[data-suggestion=${sectionId || activityProps?.extra?.external_id}]`
      );

      // TODO: Figure out where we can get the ThreadId from
      const checkIfReplyWasDeleted = async (internalId: string, sectionId: string, documentId: string) => {
        return await getCommentsForLeaseDocumentSection(
          documentId!.toString(),
          sectionId.toString(),
        )(apolloClient).then((data: any) => {
          if (data.comments[0].id === internalId.toString()) {
            return data.isResolved;
          } else {
            const relevantComment = data.comments?.find((reply: { id: string }) => {
              return reply.id === internalId.toString();
            });
            return relevantComment?.isResolved;
          }
        });
      };

      if (activityProps.description === DocumentActivityActions.NEW_SUGGESTION) {
        const suggestionStatus = await getDocumentSuggestionById(activityProps?.extra?.external_id || sectionId)(
          apolloClient
        ).then((data: any) => {
          data.status !== 'pending' && setSelectedActivityState(`This suggestion has been ${data.status}`);
          return data.status;
        });
        if (suggestionStatus !== 'pending') {
          setIsActivityDataLoading(false);
          return;
        }
      }

      if (activityProps.description === DocumentActivityActions.NEW_COMMENT) {
        if (!isCommentActive.length) {
          setSelectedActivityState('This comment has been deleted');
          setIsActivityDataLoading(false);
          return;
        }
      }

      if (activityProps.description === DocumentActivityActions.NEW_SUGGESTION_REPLY) {
        const suggestionStatus = await getDocumentSuggestionById(activityProps?.extra?.external_id || sectionId)(
          apolloClient
        ).then((data: any) => {
          data.status !== 'pending' && setSelectedActivityState(`This suggestion has been ${data.status}`);
          return data.status;
        });

        if (suggestionStatus !== 'pending') {
          setIsActivityDataLoading(false);
          return;
        }

        const isReplyDeleted = await checkIfReplyWasDeleted(
          activityDatabaseId,
          activityProps?.extra?.external_id || sectionId,
          documentId as string
        );

        if (isReplyDeleted) {
          setIsActivityDataLoading(false);
          return setSelectedActivityState('This suggestion reply has been deleted');
        }
      }

      if (activityProps.description === DocumentActivityActions.NEW_COMMENT_REPLY) {
        if (!isCommentActive.length) {
          setIsActivityDataLoading(false);
          return setSelectedActivityState('This comment has been deleted');
        }
        const isReplyDeleted = await checkIfReplyWasDeleted(
          activityDatabaseId,
          activityProps?.extra?.external_id || sectionId,
          documentId as string
        );

        if (isReplyDeleted) {
          setIsActivityDataLoading(false);
          return setSelectedActivityState('This comment reply has been deleted');
        }
      }

      const selectedAnnotation = annotationThreads.find((currentAnnotation: any) => {
        return currentAnnotation?.view?.mainView?._model?.id === (sectionId || activityProps?.extra?.external_id);
      });

      isCommentActive[0]?.scrollIntoView();
      isSuggestionActive[0]?.scrollIntoView();

      selectedAnnotation?.view?.focusTracker.set('isFocused', true);

      setIsActivityDataLoading(false);

      setTimeout(() => {
        selectedAnnotation?.view?.focusTracker.set('isFocused', false);
      }, 100);
    },
    [apolloClient, documentId]
  );

  const renderActivityData = useCallback(
    (activityProps: ActivityPropsType) => {
      return RenderDocumentActivityData({
        activityProps,
        currentDocumentActivityType,
        shouldAllActivitiesBeShown,
        selectedActivityId,
        selectedActivityState,
        hoveredActivityId,
        isActivityDataLoading,
        handleActivityClick,
        setHoveredActivityId,
      });
    },
    [
      currentDocumentActivityType,
      shouldAllActivitiesBeShown,
      handleActivityClick,
      hoveredActivityId,
      isActivityDataLoading,
      selectedActivityId,
      selectedActivityState,
    ]
  );

  const renderPlaceholder = () => (
    <div className="text-center pl-4 pr-4">
      <FormattedMessage id="activity.no-data-message" defaultMessage="No comments or suggestions" />
    </div>
  );

  return (
    <>
      <div className={styles.navigationContainer}>
        <Button onClick={() => handleActivityNavigation(ActivitiesNavigation.PREV)}>
          <span className="pr-1">
            <ChevronLeftIcon height={8} />
          </span>
          <TitleFormattedMessage id="cta.prev" defaultMessage="Prev" />
        </Button>
        <span>
          {activitiesNumber < MAX_ACTIVITIES_NUMBER_TO_DISPLAY
            ? activitiesNumber
            : `${MAX_ACTIVITIES_NUMBER_TO_DISPLAY}+`}{' '}
          items
        </span>
        <Button onClick={() => handleActivityNavigation(ActivitiesNavigation.NEXT)}>
          <TitleFormattedMessage id="cta.next" defaultMessage="Next" />
          <span className="pl-1">
            <ChevronRightIcon height={8} />
          </span>
        </Button>
      </div>
      <Card isBlue isFullHeight width={ActivityCardEnum.BOOTSTRAP_WIDTH}>
        <StreamApp apiKey={API_KEY} appId={APP_ID} token={interactionsFeedToken as string}>
          <FlatFeed
            notify={true}
            options={{ limit: 100 }}
            Notifier={props => {
              const activitiesCount = (props.adds?.length as number) || (props.deletes?.length as number);
              if (activitiesCount !== incomingActivitiesCount) {
                //@ts-ignore
                props.onClick();
                setIncomingActivitiesCount(activitiesCount);
              }
              return <></>;
            }}
            Activity={props => {
              //@ts-ignore
              const activityProps = props.activity as ActivityPropsType;
              return renderActivityData(activityProps);
            }}
            Placeholder={renderPlaceholder}
            feedGroup="timeline"
          />
        </StreamApp>
      </Card>
    </>
  );
};

export default DocumentActivitySidebar;
