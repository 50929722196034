import React from 'react';
import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import get from 'lodash/get';
import c from 'classnames';

import { FormikErrorMessage } from '../error-message';

import styles from './styles.module.scss';

export const CheckboxOrRadio = ({
  id,
  name,
  text,
  className,
  type,
  label,
  indeterminate = false,
  as = 'input',
  wrapperClassName,
  value,
  ...rest
}) => {
  const inputRef = React.useRef();

  // Set the `indeterminate` state of the field as appropriate
  React.useEffect(() => {
    if (!inputRef?.current) return;
    inputRef.current.indeterminate = indeterminate;
  }, [as, inputRef, indeterminate]);

  return (
    <Form.Group controlId={id} className={wrapperClassName}>
      <Form.Check
        as={as}
        name={name}
        custom
        className={c(styles.root, styles[type], className)}
        label={<span>{label}</span>} // Extra wrapper around the label so we can set overflow: hidden; on the label without hiding the checkbox.
        type={type}
        value={value}
        ref={as === 'input' ? inputRef : undefined}
        {...rest}
      />
      {text ? <Form.Text>{text}</Form.Text> : null}
    </Form.Group>
  );
};
const CheckboxOrRadioField = props => {
  const { name, hideFeedback } = props;
  const formik = useFormikContext();
  const errors = formik && formik.errors;
  const touched = formik && formik.touched;
  const feedback = hideFeedback ? null : <FormikErrorMessage name={name} />;

  return (
    <CheckboxOrRadio
      as={Field}
      isValid={get(touched, name) && !get(errors, name)}
      isInvalid={get(touched, name) && get(errors, name)}
      feedback={feedback}
      {...props}
    />
  );
};

export const CheckboxField = ({ ...props }) => <CheckboxOrRadioField type="checkbox" {...props} />;
export const RadioField = props => <CheckboxOrRadioField type="radio" {...props} />;
// Vanilla fields for use outside of Formik forms
export const Checkbox = props => <CheckboxOrRadio type="checkbox" {...props} />;

export default CheckboxOrRadioField;
