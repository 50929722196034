import React, { ChangeEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dropdown } from 'react-bootstrap';
import { DropdownBody, DropdownItem, DropdownToggle } from '../dropdown';
import SearchInput from '../search-input';
import { Checkbox } from '../checkbox-or-radio-field';
import slugify from '../../utils/slugify';
import styles from './DocumentTable.module.scss';
import classNames from 'classnames';
import FilterSidebar from './filterSidebar';
import { ReactComponent as FilterIcon } from '../../shared/icons/filter-icon.svg';
import { ReactComponent as HistoryIcon } from '../../shared/icons/history-icon.svg';

type DocumentTableSearchbarProps = {
  filterTitleText: string;
  setFilterTitleText: (value: React.SetStateAction<string>) => void;
  filterStatuses: Set<string>;
  allStatuses: string[];
  setFilterStatuses: (value: React.SetStateAction<Set<string>>) => void;
  setShowFilter: (value: boolean) => void;
  setShowActivity: (value: boolean) => void;
};

const DocumentTableSearchbar = ({
  filterTitleText,
  setFilterTitleText,
  filterStatuses,
  allStatuses,
  setFilterStatuses,
  setShowFilter,
  setShowActivity,
}: DocumentTableSearchbarProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={'d-flex flex-fill'}>
      <SearchInput
        className={classNames(styles.searchInboxBox, 'mr-3 w-100')}
        value={filterTitleText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterTitleText(event.currentTarget.value)}
        placeholder={formatMessage({
          id: 'document.filter.title.placeholder',
          defaultMessage: 'Search Document Name',
        })}
        aria-label={formatMessage({
          id: 'document.filter.title.label',
          defaultMessage: 'Name',
        })}
      />
      <div className="mobile-flex">
        <Button className="filterBtn" onClick={() => setShowFilter(true)}>
          <FilterIcon width={24} height={24} />
        </Button>
        <Button className="filterBtn ml-3" onClick={() => setShowActivity(true)}>
          <HistoryIcon width={24} height={24} />
        </Button>
      </div>

      <Dropdown className="non-mobile">
        <Dropdown.Toggle
          id="dropdown-filter-statuses"
          data-testid="dropdown-filter-statuses"
          as={DropdownToggle}
          className="w-100 overflow-hidden text-capitalize"
        >
          <FormattedMessage
            id="documents.filter.status.label-readout"
            defaultMessage={`{filterCount, select,
                      0 {Status}
                      1 {Status ({status})}
                      other {{filterCount} Statuses}
                    }`}
            values={{
              filterCount: filterStatuses.size === allStatuses?.length ? 0 : filterStatuses.size,
              status: filterStatuses.size ? filterStatuses.values().next().value : '',
            }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <DropdownItem highlightable>
            <Checkbox
              checked={filterStatuses.size === Array.from(new Set(allStatuses)).length}
              indeterminate={filterStatuses.size > 0 && filterStatuses.size < (allStatuses?.length || 0)}
              id="dropdown-filter-creators-_all"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const checkbox = event.currentTarget;

                if (checkbox.checked) {
                  setFilterStatuses(new Set(allStatuses));
                } else {
                  setFilterStatuses(new Set());
                }
              }}
              label={formatMessage({ id: 'deals.filter.creator.all-teams', defaultMessage: 'All Statuses' })}
            />
          </DropdownItem>
          <DropdownBody>
            {Array.from(new Set<string>(allStatuses))?.map((status: string) => {
              return (
                <DropdownItem key={status} highlightable>
                  <Checkbox
                    key={slugify(status)}
                    value={status}
                    checked={filterStatuses.has(status)}
                    id={`dropdown-filter-status-${slugify(status)}`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const checkbox = event.currentTarget;
                      const newSet = new Set(filterStatuses);
                      if (checkbox.checked) newSet.add(checkbox.value);
                      else newSet.delete(checkbox.value);
                      setFilterStatuses(newSet);
                    }}
                    label={status}
                    className="text-capitalize"
                  />
                </DropdownItem>
              );
            })}
          </DropdownBody>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DocumentTableSearchbar;
