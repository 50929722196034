import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Checkbox } from '../../../../checkbox-or-radio-field';
import { BoxTooltip } from '../../../../tooltip';
import { TitleFormattedMessage } from '../../../../titleFormattedMessage';

import styles from './styles.module.scss';
import { useMutation, useQuery } from '@apollo/client';
import { updateUserCommunicationPreference } from '../../../../../api/mutations';
import { getCurrentUser } from '../../../../../api/queries';
import { useSelector } from 'react-redux';
import { selectLeaseDocumentStatus } from '../../../../../store/lease-document/selectors/lease-document.selectors';
import { DocumentStatusEnum } from '../../../../../shared/constants/leaseDocument';

const TrackChangesTooltip = () => {
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const [shouldNotShowAgain, setShouldNotShowAgain] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const [updateCommunicationPreferences] = useMutation(updateUserCommunicationPreference);
  const { data } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data?.currentUser) {
      return;
    }

    if (data?.currentUser?.communicationPreferences?.show_track_changes_word_tooltip === false) {
      setIsOpen(false);
    } else {
      setIsOpen(documentStatus === DocumentStatusEnum.DRAFT);
    }
  }, [data?.currentUser?.communicationPreferences]);

  const { formatMessage } = useIntl();

  const handleSetOkTrackChanges = async () => {
    if (shouldNotShowAgain) {
      await updateCommunicationPreferences({ variables: { preferences: { showTrackChangesWordTooltip: false } } });
    }
    setIsOpen(false);
  };

  return (
    <BoxTooltip id="trackChangesTooltip" isOpen={isOpen}>
      <div className={styles.trackChangesTooltip}>
        <h3 className="pb-2">Turn Track Changes On/Off</h3>
        <Checkbox
          checked={shouldNotShowAgain}
          id="not-show-track-changes-tooltip"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const checkbox = event.currentTarget;
            if (checkbox.checked) {
              setShouldNotShowAgain(true);
            } else {
              setShouldNotShowAgain(false);
            }
          }}
          label={formatMessage({
            id: 'trackChanges.tooltip.notShowAgain',
            defaultMessage: 'Do not show this again',
          })}
          disabled={false}
        />
        <div>
          <Button onClick={handleSetOkTrackChanges}>
            <TitleFormattedMessage id="close" defaultMessage="Close" />
          </Button>
        </div>
      </div>
    </BoxTooltip>
  );
};

export default TrackChangesTooltip;
