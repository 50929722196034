import { gql } from '@apollo/client';

import { TEAM_LIST_DEAL_DATA, TEAM_PERMISSION_DATA, TEAM_DATA } from './fragments';
import { graphql } from '../gql';

export const getCurrentUser = graphql(/* GraphQL */ `
  query GetCurrentUser {
    currentUser {
      id
      uuid
      email
      fullName
      companyName
      fullAccess
      customerIdentifier
      subscriptionIdentifier
      currentProductIdentifier
      dealCount
      dealLimit
      subscriptionName
      subscriptionPaidAt
      subscriptionAmount
      subscriptionCurrency
      subscriptionExpiresAt
      subscriptionStartsAt
      communicationPreferences
      subscriptionTerm
      deals {
        id
        title
        status
        receiverAcceptedInvitationDate
        team {
          id
        }
        receiverTeam {
          id
          name
        }
      }
      dealInvitations: receivedInvitations {
        id
        token
        status
        email
        deals {
          id
          title
          receiverAcceptedInvitationDate
        }
        senderName
      }
      pendingDeals {
        id
        receiverAcceptedInvitationDate
        title
        status
        receiverTeam {
          id
          name
        }
      }
      allTeams {
        id
        uuid
        name
        party {
          id
        }
      }
      ownedTeams {
        id
      }
      parties {
        id
      }
      pendingDeals {
        id
      }
    }
  }
`);

export const getPortalUrl = graphql(/* GraphQL */`
  query GetPortalUrl {
    currentUser {
      id
      portalUrl
    }
  }
`);

export const getCurrentUsername = graphql(/* GraphQL */`
  query GetCurrentUsername {
    currentUser {
      id
      fullName
    }
  }
`);

export const getPermissions = gql`
  query GetCurrentUserPermissions($teamId: ID!) {
    currentUser {
      id
      teamPermissionsByTeam(teamId: $teamId) {
        ...TeamPermissionData
      }
      viewableDealsByTeam(teamId: $teamId)
      ownedTeams {
        id
      }
    }
  }
  ${TEAM_PERMISSION_DATA}
`;

export const getDealsForTeam = gql`
  query getDealsForTeam($teamId: ID!) {
    dealsForTeam(teamId: $teamId) {
      currentUserRole
      ...TeamListDealData
    }
  }
  ${TEAM_LIST_DEAL_DATA}
`;

// This is essentially the same as getDealById (below), but is geared to get
// the same data as getDealForTeam (above), just for a single deal
export const getDealForTeam = gql`
  query getDealForTeam($dealId: ID!) {
    getDealById(dealId: $dealId) {
      ...TeamListDealData
    }
  }
  ${TEAM_LIST_DEAL_DATA}
`;

export const getDealById = graphql(/* GraphQL */`
  query GetDealById($dealId: ID!) {
    getDealById(dealId: $dealId) {
      id
      activityCardToken
      furthestPage
      dealType
      dealSizeInSquareFeet
      status
      createDate
      creatorName
      receiverEmail
      receiverInvitedDate
      invitationSentBy {
        id
        email
        fullName
      }
      receiverAcceptedInvitationDate
      invitationAcceptedBy
      title
      optionalAttributeSet {
        id
        buildingClass
        buildingName
        buildingSizeInSquareFeet
        capRate
        lotSize
        lotSizeUnit
        netOperatingIncome
        numberOfFloors
        operatingCostsInDollarsPerYear
        dealManagement
        taxesInDollars
        taxRateUnit
        unitFloorNumber
        yearBuilt
        zoning
      }
      receiverTeam {
        id
        party {
          id
        }
        adminUser {
          id
        }
      }
    }
  }
`);

export const getParties = graphql(/* GraphQL */`
  query getParties {
    parties {
      id
      name
    }
  }
`);

export const getTeamById = gql`
  query getTeamById($teamId: ID!) {
    teamById(teamId: $teamId) {
      ...TeamData
    }
  }
  ${TEAM_DATA}
`;

export const getRestrictionsByCurrentUser = graphql(/* GraphQL */`
  query getRestrictionsByCurrentUser($teamId: ID!, $dealId: ID) {
    getRestrictionsByCurrentUser(teamId: $teamId, dealId: $dealId)
  }
`);

export const getResetExpiryByToken = graphql(/* GraphQL */`
  query getResetExpiryByToken($resetToken: String!) {
    resetExpiryByToken(resetToken: $resetToken) {
      id
      expiresAt
    }
  }
`);

export const getInvitationByToken = graphql(/* GraphQL */`
  query getInvitationByToken($token: String!) {
    getInvitationByToken(token: $token) {
      id
      onboarding {
        redirectTo
        tagline
        userFields
        loginAuthToken
      }
      isNewCounterparty
      document {
        id
      }
      dealIds
      deals {
        id
      }
      email
      partyId
      partyName
      senderEmail
      senderName
      status
      teamName
      token
      isNewCounterparty
    }
  }
`);

export const getOrganizations = graphql(/* GraphQL */`
  query getOrganizations {
    organizations {
      id
      name
      membersOnly
      termsOfUseRequired
      teamRequestsAllowed
      termsOfUse {
        id
        content
        termsOfUse
      }
      organizationAddendums {
        id
        content
        termsOfUse
      }
      tooltip
      adminTeam {
        id
      }
    }
  }
`);

export const hasUserTeamOwnedOrganization = graphql(/* GraphQL */`
  query hasUserTeamOwnedOrganization($teamId: ID!) {
    teamById(teamId: $teamId) {
      organization {
        id
      }
    }
  }
`);

export const getOrganizationByTeam = graphql(/* GraphQL */`
  query GetOrganizationByTeam($teamId: ID!) {
    teamById(teamId: $teamId) {
      organization {
        id
        name
        adminTeam {
          id
          uuid
        }
        organizationRequests {
          id
          requesterType
          requesterId
          isPaid
          user {
            id
            fullName
            email
            uuid
          }
          membershipId
          status
          requesterName
          expiresAt
        }
      }
    }
  }
`);

export const organizationsAccessByUser = graphql(/* GraphQL */`
  query OrganizationsAccessByUser {
    organizationsAccessByUser {
      id
      requesterType
      requesterId
      isPaid
      portalUrl
      user {
        id
        fullName
      }
      membershipId
      status
      requesterName
      expiresAt
      organization {
        id
        name
        membersOnly
        termsOfUseRequired
        tooltip
        updatedAt
        termsOfUseRequired
        termsOfUse {
          id
          content
          createdAt
          termsOfUse
          updatedAt
        }
        organizationRequests {
          id
        }
        organizationAddendums {
          id
          content
          createdAt
          termsOfUse
          updatedAt
        }
      }
    }
  }
`);

export const getOrganizationById = graphql(/* GraphQL */`
  query GetOrganizationById($organizationId: ID!) {
    getOrganizationById(id: $organizationId) {
      id
      name
      membersOnly
      termsOfUseRequired
      teamRequestsAllowed
      termsOfUse {
        id
        content
        termsOfUse
      }
      organizationAddendums {
        id
        content
        termsOfUse
      }
      tooltip
      adminTeam {
        id
      }
      paymentLinkUrl
    }
  }
`);
