import React, { useEffect, useState, useCallback, useRef } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { LeaseDocumentWizardHeaderTitleProps } from '../lease-document-wizard-header.types';
import './lease-document-wizard-header-title.scss';

export const LeaseDocumentWizardHeaderTitle = ({
  title,
  titleBadge,
  onSaveTitle = () => {},
  canEdit,
}: LeaseDocumentWizardHeaderTitleProps) => {
  const intl = useIntl();
  const inputId = 'lease-wizard-title-editor';
  const [value, setValue] = useState(title);
  const [previousValue, setPreviousValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = useCallback(() => {
    if (!value) {
      setValue(previousValue);
      return;
    }
    onSaveTitle(value);
  }, [onSaveTitle, value, previousValue]);

  const handleChange = useCallback(
    evt => {
      setPreviousValue(value);
      setValue(evt.target.value);
    },
    [value]
  );

  const handleKeyDown = useCallback(evt => {
    // on hit enter
    if (evt.keyCode === 13) {
      evt.preventDefault();
      setValue(evt.target.value);
      inputRef.current?.blur();
    }
  }, []);

  let labelTitle;
  if (canEdit) {
    labelTitle = intl.formatMessage({ id: 'lease-wizard.header.hover.title', defaultMessage: 'Rename title' });
  }

  useEffect(() => {
    setValue(title);
  }, [title]);

  return (
    <div className="lease-document-wizard-header-title">
      {canEdit && (
        <input
          ref={inputRef}
          type="text"
          id={inputId}
          value={value}
          onChange={handleChange}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          data-testid="headerTitleInput"
          className={cn('lease-document-wizard-header-title__input form-control')}
        />
      )}

      <label
        htmlFor={inputId}
        className={cn('lease-document-wizard-header-title__label', {
          'lease-document-wizard-header-title__label--can-edit': canEdit,
        })}
        data-testid="headerTitleLabel"
        title={labelTitle}
      >
        {value}
      </label>
      {titleBadge}
    </div>
  );
};
