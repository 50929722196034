import React, { Dispatch, SetStateAction, useCallback } from 'react';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Permissions } from '../../../../shared/constants/permissions';
import PermissionIcon from '../../../permission-icon';
import Toggle from '../../../toggle';
import RemoveTeamMemberSidebarButton from './removeDealTeamMemberButton';
import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';
import { useMutation, useQuery } from '@apollo/client';
import { mutations, queriesV2 } from '../../../../api';
import { useTeamInfo } from '../../../../team-id-context';
import { useNotifications } from '../../../../notifications';
import { getTeamById } from '../../../../api/queries';
import { SidebarTeamMember } from '../../../teamMembersList/components/teamMembersTable/helpers';
import { useAuth } from '../../../../auth';
import { Permission } from '../../../../store/permissions/permissions.reducer';
import { ReactComponent as DangerIcon } from '../../../../shared/icons/alert-danger-icon.svg';
import { union } from 'lodash';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

export interface DealTeamTableSubRowProps {
  row: {
    original: SidebarTeamMember;
  };
  setUserToRemoveFromDeal: Dispatch<SetStateAction<SidebarTeamMember | null>>;
}

const DealTeamTableSubRow = ({ row, setUserToRemoveFromDeal }: DealTeamTableSubRowProps) => {
  const { teamId } = useTeamInfo();
  const [{ user }] = useAuth();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const { data: allPermissions } = useQuery(queriesV2.getPermissions);
  const [resendInvitation] = useMutation(mutations.resendInvitation);
  const [cancelInvitation] = useMutation(mutations.cancelInvitation, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
    ],
  });
  const [updateUserPermissions] = useMutation(mutations.updateUserPermissions, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
    ],
  });
  const [updatePendingInvitation] = useMutation(mutations.updatePendingInvitation, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
    ],
  });

  const { original: userRowData } = row;
  const editPermissions = !!allPermissions
    ? allPermissions.permissions.filter(
        (permission: any) => permission.key !== Permissions.Whitelist && permission.key !== Permissions.View
      )
    : [];

  const isRowUserCurrent = user.id === userRowData.id;
  const shouldShowRemoveTeamMemberFromTeamButton =
    !userRowData.isAdmin && !userRowData.invitationId && !isRowUserCurrent;

  const handleResendInvitation = useCallback(
    async (invitationId, email) => {
      try {
        await resendInvitation({ variables: { invitationId } });
        notifySuccess({
          message: {
            id: 'phrase.resend-invitation.success',
            defaultMessage: 'A new invitation has been sent to {email}',
            values: {
              email,
            },
          },
        });
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [notifyError, notifySuccess, resendInvitation]
  );

  const handleCancelInvitation = useCallback(
    async (invitationId, email) => {
      try {
        await cancelInvitation({ variables: { invitationId } });
        notifySuccess({
          message: {
            id: 'phrase.cancel-invitation.success',
            defaultMessage: 'Invitation has been cancelled',
            values: {
              email,
            },
          },
        });
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [cancelInvitation, notifyError, notifySuccess]
  );

  const handleToggleChange = useCallback(
    async (permissionKey, isChecked, userId) => {
      const isEditPermission = permissionKey === Permissions.Edit;
      const isApprovePermission = permissionKey === Permissions.Approve;
      const permissionUpdates = [
        {
          permissionKey,
          allowed: isChecked,
        },
      ];

      if (isChecked && isEditPermission) {
        permissionUpdates.push({
          permissionKey: Permissions.Comment,
          allowed: true,
        });
      }

      if (isChecked && isApprovePermission) {
        permissionUpdates.push({
          permissionKey: Permissions.Edit,
          allowed: true,
        });
      }

      try {
        await updateUserPermissions({
          variables: {
            userId,
            teamId,
            permissionUpdates,
          },
        });
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [notifyError, teamId, updateUserPermissions]
  );

  const handlePendingToggleChange = useCallback(
    async (permissionKey, isChecked, invitationId, permissionIds, allPermissions) => {
      let editedPermissionIds = permissionIds.map((item: Permission) => item.id);
      const isEditPermission = permissionKey === Permissions.Edit;
      const isApprovePermission = permissionKey === Permissions.Approve;

      const getPermissionId = (key: string) => {
        return allPermissions.filter((item: Permission) => item.key === key)[0].id;
      };

      const newTogglesPermissions: string[] = [getPermissionId(permissionKey)];

      if (isChecked && isEditPermission) {
        newTogglesPermissions.push(getPermissionId(Permissions.Comment));
      }

      if (isChecked && isApprovePermission) {
        newTogglesPermissions.push(getPermissionId(Permissions.Comment));
        newTogglesPermissions.push(getPermissionId(Permissions.Edit));
      }

      if (isChecked) {
        editedPermissionIds = union(editedPermissionIds, newTogglesPermissions);
      } else {
        editedPermissionIds = editedPermissionIds.filter((id: string) => id !== newTogglesPermissions[0]);
      }

      try {
        await updatePendingInvitation({
          variables: {
            id: invitationId,
            permissionIds: editedPermissionIds,
            dealIds: row.original.dealIds,
          },
        });
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [notifyError, row.original.dealIds, updatePendingInvitation]
  );

  const hasEditPermission = () =>
    userRowData.permissions.some((permissionItem: any) => permissionItem.key === Permissions.Edit);

  const hasApprovePermission = () =>
    userRowData.permissions.some((permissionItem: any) => permissionItem.key === Permissions.Approve);

  const isToggleDisabled = (permissionKey: any) => {
    const isCommentPermission = permissionKey === Permissions.Comment;
    const isEditPermission = permissionKey === Permissions.Edit;

    const isCommentDisabled = isCommentPermission && hasEditPermission();
    const isEditDisabled = isEditPermission && hasApprovePermission();

    return isCommentDisabled || isEditDisabled || user.id === userRowData.id || userRowData.isAdmin;
  };

  return !userRowData.invitationId ? (
    <div className="w-100 flex flex-column">
      <div className={classNames(styles.warningBackground, 'w-100 p-1')}>
        <DangerIcon width={15} height={15} />
        <FormattedMessage id="phrase.permissions-warning" defaultMessage="  Permissions apply to all deals" />
      </div>
      <div className={classNames(styles.permissionsSubRow, 'flex d-flex flex-column')}>
        {editPermissions
          ?.sort((a: any, b: any) => a.displayOrder - b.displayOrder)
          .map((permission: any) => {
            function hasPermission() {
              return userRowData.permissions.some((p: any) => p.key === permission.key);
            }

            const isCustomDisabledToggle = user.id === userRowData.id || userRowData.isAdmin;

            return (
              <div
                key={permission.id}
                className={classNames(
                  styles.permissionsSubRow,
                  'pr-5 m-0 mt-1 flex-row flex align-items-center justify-content-between'
                )}
              >
                <div>
                  <PermissionIcon permission={permission} />
                  <span className="ml-2 p-0 font-weight-bold">{permission?.name}</span>
                </div>
                <Toggle
                  data-testid="subrow_toggle"
                  checked={hasPermission()}
                  className={styles.customToggleStyles}
                  onChange={(event: any) =>
                    handleToggleChange(permission.key, event.currentTarget.checked, userRowData.id)
                  }
                  isCustomDisabledState={isCustomDisabledToggle}
                  disabled={isToggleDisabled(permission.key)}
                />
              </div>
            );
          })}
      </div>
      {shouldShowRemoveTeamMemberFromTeamButton && (
        <RemoveTeamMemberSidebarButton onClick={() => setUserToRemoveFromDeal(userRowData)} />
      )}
    </div>
  ) : (
    <div>
      <div>
        <div className={classNames(styles.warningBackground, 'w-100 p-1')}>
          <span className="p-0 m-0 ">
            <DangerIcon width={15} height={15} />
            <FormattedMessage id="phrase.sidebar-pending-invite" defaultMessage="  Invitation pending:" />
          </span>
          <Button
            className="p-0 ml-2"
            variant="link"
            onClick={() => handleResendInvitation(userRowData.invitationId, userRowData.email)}
          >
            <TitleFormattedMessage id="cta.resend-invitation" defaultMessage="Resend" />
          </Button>{' '}
          |
          <Button
            className="p-0 ml-1"
            variant="link"
            onClick={() => handleCancelInvitation(userRowData.invitationId, userRowData.email)}
          >
            <TitleFormattedMessage id="cta.cancel-invitation" defaultMessage="Cancel" />
          </Button>
        </div>
        {editPermissions?.map((permission: any) => {
          function hasPermission() {
            return userRowData.permissions.some((p: any) => p.key === permission.key);
          }

          return (
            <div
              key={permission.id}
              className={classNames(
                styles.permissionsSubRow,
                'pr-5 m-0 mt-1 flex-row flex align-items-center justify-content-between'
              )}
            >
              <div>
                <PermissionIcon permission={permission} withAltText={false} />
                <span className="ml-2 p-0 font-weight-bold">{permission?.name}</span>
              </div>
              <Toggle
                data-testid="subrow_toggle"
                checked={hasPermission()}
                className={styles.customToggleStyles}
                onChange={(event: any) =>
                  handlePendingToggleChange(
                    permission.key,
                    event.currentTarget.checked,
                    userRowData.invitationId,
                    userRowData.permissions,
                    allPermissions!.permissions
                  )
                }
                isCustomDisabledState={user.id === userRowData.id || userRowData.isAdmin}
                disabled={isToggleDisabled(permission.key)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DealTeamTableSubRow;
