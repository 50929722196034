import React, { useCallback, useRef, useState } from 'react';
import CancelSigningPreparationModal from './cancelSigningPreparationModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectLeaseDocumentId } from '../../../../store/lease-document/selectors/lease-document.selectors';
import { cancelPreparingToSignDocument } from '../../../../api/mutations';
import { useMutation } from '@apollo/client';
import { getDocumentById } from '../../../../api/queries_v2';
import { DocumentStatusEnum } from '../../../../shared/constants/leaseDocument';
import {
  dispatchEraseLeaseDocumentValuesFromStore,
  dispatchLeaseDocumentResponseToStore,
} from '../../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { useNotifications } from '../../../../notifications';

interface CancelSigningPreparationModalContainerProps {
  show: boolean;
  onHide: () => void;
}

const CancelSigningPreparationModalContainer = ({ show, onHide }: CancelSigningPreparationModalContainerProps) => {
  const documentId = useSelector(selectLeaseDocumentId);
  const [isLoading, setIsLoading] = useState(false);
  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const [cancelPreparingToSign] = useMutation(cancelPreparingToSignDocument, {
    refetchQueries: [{ query: getDocumentById, variables: { documentId } }],
  });
  const dispatch = useDispatch();
  const [, { error: notifyError }] = useNotifications();

  const handleError = useCallback(() => {
    notifyError({
      message: 'Unable to cancel preparing to signing process.',
    });
  }, [notifyError]);

  const handleConfirm = useCallback(async () => {
    const additionalMessage = additionalMessageInputRef.current?.value;
    setIsLoading(true);
    cancelPreparingToSign({ variables: { documentId: documentId!, additionalMessage } })
      .then((response: any) => {
        const document = response?.data?.cancelPreparingToSignDocument;

        if (!document) {
          handleError();
          return;
        }

        const { status, receiverTeam } = document;
        const isSharedDocumentInReviewingStatus = receiverTeam && status === DocumentStatusEnum.REVIEWING;
        const isInternalDocumentInDraftStatus =
          !receiverTeam && (status === DocumentStatusEnum.DRAFT || status === DocumentStatusEnum.UPLOADED);

        if (
          !isSharedDocumentInReviewingStatus &&
          !isInternalDocumentInDraftStatus &&
          status !== DocumentStatusEnum.SHARED
        ) {
          handleError();
          return;
        }

        dispatchEraseLeaseDocumentValuesFromStore(dispatch);
        dispatchLeaseDocumentResponseToStore(dispatch, response.data.cancelPreparingToSignDocument);
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setIsLoading(false);
        onHide();
      });
  }, [cancelPreparingToSign, dispatch, handleError, onHide, documentId]);

  return (
    <CancelSigningPreparationModal
      onHide={onHide}
      show={show}
      onConfirm={handleConfirm}
      isLoading={isLoading}
      additionalMessageInputRef={additionalMessageInputRef}
    />
  );
};

export default CancelSigningPreparationModalContainer;
