import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';

interface ArchiveModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onUpdateArchived: () => void;
}

const ArchiveModal = ({ isOpen = false, onClose, onUpdateArchived }: ArchiveModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header
        closeButton
        closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
        onClick={onClose}
      >
        <Modal.Title>
          <header>
            <FormattedMessage id="title.archive-deal" defaultMessage="Archive Deal" />
          </header>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage
            id="phrase.archive-deal"
            defaultMessage="Archiving a deal means it becomes inactive, you can no longer create documents for this deal."
          />
        </p>
        <p>
          <FormattedMessage
            id="phrase.archive-deal-reversal"
            defaultMessage="Archiving can be reversed at a later date."
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="text-secondary" onClick={onClose}>
          <FormattedMessage id="cta.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            onUpdateArchived();
          }}
        >
          <TitleFormattedMessage id="cta.archiveDeal" defaultMessage="Archive Deal" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveModal;
