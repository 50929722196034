import { MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR } from '../constants/EditorConstats';
import { PRIVATE_COMMENT_MARKER } from '../LeaseEditor';

export const changeSidebarMode = () => {
  const annotationsUIs = window.editor.plugins.get('AnnotationsUIs');
  const isActiveWideMode = Array.from(annotationsUIs.activeUIs).includes('wideSidebar');
  const isActiveNarrowMode = Array.from(annotationsUIs.activeUIs).includes('narrowSidebar');
  const commentsRepositoryPlugin = window.editor.plugins.get('CommentsRepository');

  const getIsCommentPrivate = (item: any) => {
    const threadId = item.innerView.element.dataset.threadId;
    const thread = commentsRepositoryPlugin.getCommentThread(threadId);
    return !thread.comments._items.some((item: any) => item.content === PRIVATE_COMMENT_MARKER);
  };

  if (window.innerWidth < MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR && isActiveWideMode) {
    annotationsUIs.deactivateAll();
    annotationsUIs.activate('narrowSidebar', (item: any) =>
      item.type === 'comment' ? getIsCommentPrivate(item) : true
    );
  } else if (window.innerWidth >= MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR && isActiveNarrowMode) {
    annotationsUIs.deactivateAll();
    annotationsUIs.activate('wideSidebar', (item: any) => (item.type === 'comment' ? getIsCommentPrivate(item) : true));
  }
};
