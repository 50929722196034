import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../lease-wizard/modals/cancelSigningPreparation/styles.module.scss';

export function determineActionModalMessage(modalType: string | null): string | React.ReactElement<any, any> {
  let message: string | React.ReactElement<any, any> = '';
  switch (modalType) {
    case 'terminate':
      message = (
        <FormattedMessage
          id="lease-document-wizard-modal.lease-terminate-content"
          defaultMessage="This will permanently delete the document and cannot be undone."
        />
      );
      break;

    case 'unsend':
      message = (
        <FormattedMessage
          id="lease-document-wizard-modal.lease-unsend-content"
          defaultMessage="Your counterparty will no longer be able to access the document once it is unsent."
        />
      );
      break;

    case 'signing-internal':
      message = (
        <div className={styles.main}>
          <p>
            This document has not been shared with the counterparty! They will not have access to the document inside of
            propdocs.
          </p>
          <p>
            Click <span>Prepare for Signing</span> to have your document signed as is.
          </p>
          <p>
            Click <span>Cancel</span> to send the document to the counterparty if you would like them to review prior to
            signing.
          </p>
        </div>
      );
      break;

    default:
      break;
  }

  return message;
}
