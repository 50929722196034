import React, { useCallback, useRef, useEffect } from 'react';
import className from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';

import { useNotifications } from '../../notifications';
import { generic as genericErrorMessage } from '../../error-messages';

import { ReactComponent as SuccessIcon } from '../../shared/icons/success-icon.svg';
import { ReactComponent as CloseIcon } from '../../shared/icons/close-icon.svg';

import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './styles.module.scss';

const TIMEOUT_MS = 5e3;
const SHOW_COUNT = 2; // Show the latest notifications, at maximum this many

function getIconByTheme(theme) {
  switch (theme) {
    case 'success':
      return SuccessIcon;
    case 'error':
    case 'warning':
    default:
      return null;
  }
}

function renderableMessage(message) {
  if (typeof message === 'object' && 'id' in message && 'defaultMessage' in message) {
    return <FormattedMessage {...message} />;
  }
  if (message instanceof Error) {
    console.error(message);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return message.toString();
    }
    return <FormattedMessage {...genericErrorMessage} />;
  }
  return message?.toString();
}

/**
 *  * A single toast notification
 *
 * It times itself out, or can be manually dismissed.
 * The timeout is paused when the mouse pointer is over.
 * @param {string} id - the notification id
 * @param {string} theme - the theme name
 * @param {string} message - thge notification message
 * @param {function} onClick - the on click handler
 * @param {function} onDismiss - the on dismiss handler
 * @param {function} onTimeout - the on timeout handler
 * @param {boolean} pinned - true to have the notification stay until dismissed (default false)
 * @returns {JSX.Element}
 * @constructor
 */
const Notification = ({ id, theme, message, onClick, onDismiss, onTimeout, pinned = false, hide = false }) => {
  const timerStartRef = useRef(null);
  const timerElapsedRef = useRef(0);
  const timerRef = useRef(null);

  const startTimer = useCallback(() => {
    clearTimeout(timerRef.current);
    timerStartRef.current = Date.now();
    timerRef.current = setTimeout(onTimeout, TIMEOUT_MS - timerElapsedRef.current);
  }, [onTimeout]);

  const pauseTimer = useCallback(() => {
    clearTimeout(timerRef.current);
    timerElapsedRef.current += Date.now() - timerStartRef.current;
  }, []);

  useEffect(() => {
    if (!pinned) {
      startTimer();
      return pauseTimer;
    }
  }, [startTimer, pauseTimer, pinned]);

  useEffect(() => {
    if (hide) {
      startTimer();
      return pauseTimer;
    }
  }, [startTimer, pauseTimer, hide]);

  const IconComponent = getIconByTheme(theme);
  const icon = IconComponent ? <IconComponent className="mr-3" /> : null;

  return (
    <article
      className={className(styles.notification, styles[theme])}
      onClick={onClick}
      onMouseEnter={!pinned ? pauseTimer : () => {}}
      onMouseLeave={!pinned ? startTimer : () => {}}
    >
      <div data-testid="notification-popup" className={styles.content}>
        {icon}
        {renderableMessage(message)}
      </div>
      <div>
        <Button
          variant="unstyled"
          className={styles.dismiss}
          onClick={event => {
            // Don't trigger main notification click handler
            event.stopPropagation();
            onDismiss();
          }}
        >
          <CloseIcon aria-hidden={true} />
          <div className="sr-only">
            <TitleFormattedMessage id="cta.dismiss" defaultMessage="Dismiss" />
          </div>
        </Button>
      </div>
    </article>
  );
};

/**
 * List of current notifications
 *
 * Intended to be displayed just once on the site.
 */
const Notifications = () => {
  const [notifications, { dismiss, timeout }] = useNotifications();

  return (
    <div className={styles.root}>
      {notifications.slice(0, SHOW_COUNT).map(props => (
        <Notification
          key={props.id}
          {...props}
          onDismiss={() => {
            dismiss(props.id);
          }}
          onTimeout={() => {
            timeout(props.id);
          }}
        />
      ))}
    </div>
  );
};

export default Notifications;
