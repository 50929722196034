import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { RequestReviewModalComponentProps } from './RequestInternalApprovalModalConstants';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';
import styles from './RequestReview.module.scss';

const RequestInternalApprovalModal = ({
  isOpen,
  onClose,
  modalFilters,
  onSubmit,
  submitDisabled,
}: RequestReviewModalComponentProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Modal show={isOpen} onHide={onClose} className={styles.requestInternalApprovalModalContainer} size="lg">
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title>
            <header>
              <FormattedMessage id="title.first-lease-template" defaultMessage="Request Internal Review" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalFilters}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div className={styles.requestInternalApprovalButtonsGroup}>
            <Button variant="link" className="text-secondary" onClick={onClose}>
              <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="secondary" onClick={onSubmit} disabled={submitDisabled}>
              <TitleFormattedMessage id="cta.cancel" defaultMessage="Send for review" />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestInternalApprovalModal;
