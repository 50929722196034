import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { MutationFunctionOptions, useMutation } from '@apollo/client';
import WebViewer, { Core } from '@pdftron/webviewer';
import { CustomAnnotationPopup } from '../../signing/components';
import { disableFormFieldsInDocument, disableUiFeatures, fixPdfDocumentRotation, reorderPdfPages } from '../../signing/helpers';
import { getExtensionByContentType } from './helpers';
import { ImportedDocumentSupportedContentTypes } from '../../../components/shared/modals/fileUploadModal/types';
import styles from '../../signing/styles.module.scss';
import IdleTimer from 'react-idle-timer';
import { IDLE_TIMER_TIMEOUT_MILLISECONDS } from '../../../components/lease-wizard/lease-editor/LeaseEditor';
import TimeoutModal from '../../../components/lease-wizard/lease-document-wizard-modal/custom-modals/timeoutModal/TimeoutModal';
import { setNecessaryHeaderItems } from '../helpers/headerToobarHandlers';
import { AnnotationType } from '../helpers/constants';
import { ReplaceImportedDocumentTemplateMutation, ReplaceImportedDocumentTemplateMutationVariables } from '../../../gql/graphql';
import { mutations } from '../../../api';
import { useAuth } from '../../../auth';

interface Props {
  template: {
    url: string;
    contentType: ImportedDocumentSupportedContentTypes;
    id: string;
    title: string;
    teamId: string;
  };
  hasUserEditPermission: boolean;
  hasUserCommentPermission: boolean;
  saveComments: (options: MutationFunctionOptions) => Promise<any>;
  saveFormFields: (options: MutationFunctionOptions) => Promise<any>;
  commentsStringUrl: string | null;
  formFieldsUrl: string | null;
  userData: { id: string | number; fullName: string };
  isTemplateLocked: boolean;
  navigateToTemplatesPage: () => void;
  isOrganizationTemplate?: boolean;
}

const UploadedTemplatesViewer = ({
  template: viewerDocument,
  hasUserEditPermission,
  hasUserCommentPermission,
  saveComments,
  saveFormFields,
  commentsStringUrl,
  userData,
  isTemplateLocked,
  navigateToTemplatesPage,
  isOrganizationTemplate,
  formFieldsUrl,
}: Props) => {
  const [{ user }] = useAuth();
  const viewer = useRef<HTMLDivElement>(null);
  const idleTimerRef = useRef<IdleTimer>(null);
  const currentUserName = `${userData.fullName}:id${userData.id}`;
  const [customSettingsApplied, setCustomSettingsApplied] = useState<boolean>(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false);
  const [replaceImportTemplate] = useMutation<
  ReplaceImportedDocumentTemplateMutation,
  ReplaceImportedDocumentTemplateMutationVariables
  >(mutations.replaceImportDocumentTemplate);

  const isTemplateCreator = !!user.allTeams.find((team: { id: string; }) => team.id === viewerDocument.teamId);

  const isUserPremium = user.subscriptionAmount > 0;

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer',
        initialDoc: viewerDocument.url,
        fullAPI: true,
        licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
        extension: getExtensionByContentType(viewerDocument.contentType),
        annotationUser: userData.id.toString(),
        filename: viewerDocument.title,
        css: `${process.env.PUBLIC_URL}/apryseStyles.css`,
      },
      viewer.current as HTMLDivElement
    ).then(async instance => {
      instance.UI.disableElements(['settingsButton']);

      const {
        Core: { documentViewer, Annotations, DisplayMode, DisplayModes },
      } = instance;
      const annotationManager = documentViewer.getAnnotationManager();

      if (!hasUserCommentPermission || isTemplateLocked) {
        annotationManager.enableReadOnlyMode();
      }

      instance.UI.setAnnotationContentOverlayHandler(annotation => {
        if (annotation.ToolName !== 'AnnotationCreateSticky') return <CustomAnnotationPopup annotation={annotation} />;
      });

      instance.UI.setAnnotationContentOverlayHandler(annotation => {
        if (annotation.Subject === AnnotationType.STRIKEOUT || annotation.Subject === AnnotationType.FREE_TEXT) {
          return;
        }
      });

      annotationManager.setCurrentUser(currentUserName);

      annotationManager.setAnnotationDisplayAuthorMap(userId => {
        const userName = userId?.split(':id');
        return userName?.[0];
      });

      annotationManager.setPermissionCheckCallback((author, annotation) => {
        if (annotation.Subject === AnnotationType.STRIKEOUT || annotation.Subject === AnnotationType.FREE_TEXT) {
          return hasUserEditPermission;
        }

        if (annotation.Subject === 'Note' && author) {
          const authorData = author.split(':id');
          const authorId = authorData[authorData?.length - 1];

          return authorId === userData.id;
        }

        return true;
      });

      annotationManager.addEventListener('fieldChanged', async (...args: any) => {
        const strikeoutAnnotList = annotationManager
          .getAnnotationsList()
          .filter(annot => annot.Subject === AnnotationType.STRIKEOUT);

        const freeTextAnnotList = annotationManager
          .getAnnotationsList()
          .filter(annot => annot.Subject === AnnotationType.FREE_TEXT);

        const fieldsString = await annotationManager.exportAnnotations({
          links: false,
          widgets: true,
          annotList: [...strikeoutAnnotList, ...freeTextAnnotList],
          fields: true,
        });

        if (isTemplateLocked) {
          return;
        }
        await saveFormFields({
          variables: { documentTemplateId: viewerDocument.id, xfdfString: fieldsString },
        });
      });

      annotationManager.disableRedaction();
      annotationManager.disableDraggingAcrossPages();

      setNecessaryHeaderItems({
        instance,
        isSigningProcessStarted: false,
        isDocumentCreator: true,
        hasUserEditPermission,
        hasUserCommentPermission,
        isOrganizationTemplate,
        documentId: viewerDocument.id,
        saveFormFields,
      });

      const allowReordering = hasUserEditPermission && isTemplateCreator && isUserPremium;

      disableUiFeatures(instance, allowReordering);

      instance.UI.setCustomNoteFilter(annot => !(annot instanceof instance.Core.Annotations.TextHighlightAnnotation));

      annotationManager.addEventListener('annotationChanged', async (annots, action, info) => {
        if (info.imported) {
          annots.forEach(async (annot: any) => {
            if (annot instanceof Annotations.SignatureWidgetAnnotation) {
              annot.hidden = true;
            }
          });
          return;
        }

        const formFieldCreationManagerMode = instance.Core.annotationManager.getFormFieldCreationManager();

        if (action === 'add' || action === 'modify') {
          const formFieldPlaceHolders = annots.filter((annotation: Core.Annotations.HTMLAnnotation) =>
            annotation.isFormFieldPlaceholder()
          );

          const checkboxes = formFieldPlaceHolders.filter(
            (annotation: Core.Annotations.HTMLAnnotation) =>
              annotation.getFormFieldPlaceHolderType() === 'CheckBoxFormField'
          );

          checkboxes.forEach((checkbox: Core.Annotations.MarkupAnnotation) => {
            checkbox.FillColor = new Annotations.Color(222, 226, 255);
            checkbox.StrokeColor = new Annotations.Color(0, 0, 0, 1);
          });

          const textFields = formFieldPlaceHolders.filter(
            (annotation: Core.Annotations.HTMLAnnotation) =>
              annotation.getFormFieldPlaceHolderType() === 'TextFormField'
          );

          textFields.forEach((textFields: Core.Annotations.MarkupAnnotation) => {
            textFields.FillColor = new Annotations.Color(222, 226, 255);
            textFields.StrokeColor = new Annotations.Color(222, 226, 255);
          });

          const textWidgets = annots.filter(
            (annotation: Core.Annotations.Annotation) => annotation instanceof Annotations.TextWidgetAnnotation
          );

          textWidgets.forEach((textWidget: Core.Annotations.HTMLAnnotation) => {
            textWidget.backgroundColor = new Annotations.Color(0, 0, 0, 0);

            textWidget.border = new Annotations.Border({
              color: new Annotations.Color(211, 211, 211),
              width: 1,
            });
          });
        }

        //@ts-ignore
        if (!!formFieldCreationManagerMode.isInFormFieldCreationMode()) {
          return;
        }

        annots.forEach(async (annot: any) => {
          if (annot instanceof instance.Core.Annotations.StickyAnnotation) {
            const xfdfCommentsString = await annotationManager.exportAnnotations({
              links: false,
              widgets: false,
              fields: false,
            });

            if (isTemplateLocked) {
              return;
            }

            await saveComments({
              variables: { documentTemplateId: viewerDocument.id, xfdfString: xfdfCommentsString },
            });
          }

          if (
            annot instanceof instance.Core.Annotations.TextStrikeoutAnnotation ||
            annot instanceof instance.Core.Annotations.FreeTextAnnotation
          ) {
            const strikeoutAnnotList = annotationManager
              .getAnnotationsList()
              .filter(annot => annot.Subject === AnnotationType.STRIKEOUT);

            const freetextAnnotList = annotationManager
              .getAnnotationsList()
              .filter(annot => annot.Subject === AnnotationType.FREE_TEXT);

            const fieldsString = await annotationManager.exportAnnotations({
              links: false,
              widgets: true,
              annotList: [...strikeoutAnnotList, ...freetextAnnotList],
              fields: true,
            });

            if (isTemplateLocked) {
              return;
            }

            await saveFormFields({
              variables: { documentTemplateId: viewerDocument.id, xfdfString: fieldsString },
            });
          }
        });
      });

      if (!hasUserEditPermission) {
        disableFormFieldsInDocument(instance);
      }

      instance.UI.setToolMode('TextSelect');

      documentViewer.addEventListener('documentLoaded', async () => {
        const displayModeManager = documentViewer.getDisplayModeManager();
        displayModeManager.setDisplayMode(new DisplayMode(documentViewer, DisplayModes.Single));
        displayModeManager.setDisplayMode(new DisplayMode(documentViewer, DisplayModes.Continuous));
      });

      documentViewer.addEventListener('pagesUpdated', async (e) => {
        const movedPagesCount = Object.keys(e.moved).length;

        const currentDocument = documentViewer.getDocument();

        if (movedPagesCount && currentDocument.arePagesAltered()) {
          await reorderPdfPages({
            instance, 
            document: viewerDocument, 
            teamId: viewerDocument.teamId,
            isTemplate: true,
            replaceImportDocument: replaceImportTemplate,
            licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY || '',
            saveFormFields,
            saveComments,
          });
        }
      });

      documentViewer.addEventListener('annotationsLoaded', async () => {
        if (formFieldsUrl) {
          const formFieldsUrlResponse = await fetch(formFieldsUrl);
          const formFieldsXfdfString = await formFieldsUrlResponse.text();
          await annotationManager.importAnnotations(formFieldsXfdfString);
        }
        if (!isOrganizationTemplate && commentsStringUrl) {
          const annotationsStringUrlResponse = await fetch(commentsStringUrl);
          const annotationsXfdfString = await annotationsStringUrlResponse.text();
          await annotationManager.importAnnotations(annotationsXfdfString);
        }
      });

      for (const hotkey in instance.UI.hotkeys.Keys) {
        instance.UI.hotkeys.off(hotkey);
      }

      setCustomSettingsApplied(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- should be called only once after mount

  const handleIdle = () => {
    idleTimerRef?.current?.reset();
    setShowTimeoutModal(true);
  };

  const handleTimeoutModalClose = () => {
    setShowTimeoutModal(false);
    idleTimerRef?.current?.reset();
  };

  return (
    <>
      <div
        className={classNames(styles.webviewer, styles['webviewer_view-uploaded'], {
          [styles['webviewer-display']]: customSettingsApplied,
        })}
        ref={viewer}
      />
      <IdleTimer
        key="idleTimer"
        ref={idleTimerRef as any}
        startOnMount
        element={document}
        onIdle={handleIdle}
        timeout={IDLE_TIMER_TIMEOUT_MILLISECONDS}
      />
      {showTimeoutModal && <TimeoutModal onClose={handleTimeoutModalClose} onTimeOut={navigateToTemplatesPage} />}
    </>
  );
};

export default UploadedTemplatesViewer;
