import { RadioField } from '../../../../components/checkbox-or-radio-field';
import { FormattedMessage } from 'react-intl';

export const SortingForm = () => {
  return (
    <>
      <RadioField
        id="mobileFilter.ascName"
        name="sort"
        value="ascName"
        label={<FormattedMessage id="mobileFilter.name.label" defaultMessage="By Name (A-Z)" />}
        hidefeedback="true"
      />
      <RadioField
        id="mobileFilter.descName"
        name="sort"
        value="descName"
        label={<FormattedMessage id="mobileFilter.descName.label" defaultMessage="By Name (Z-A)" />}
        hidefeedback="true"
      />
      <RadioField
        id="mobileFilter.email.asc"
        name="sort"
        value="ascEmail"
        label={<FormattedMessage id="mobileFilter.email.asc.label" defaultMessage="By Email (A-Z)" />}
        hidefeedback="true"
      />
      <RadioField
        id="mobileFilter.email.desc"
        name="sort"
        value="descEmail"
        label={<FormattedMessage id="mobileFilter.email.desc.label" defaultMessage="By Email (Z-A)" />}
        hidefeedback="true"
      />
    </>
  );
};
