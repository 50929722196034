/**
 * Convert a string from snake_case to camelCase
 *
 * @function snakeToCamel
 * @param {string} input - Input string in snake_case
 * @returns {string} - Output string in camelCase
 */
export default function snakeToCamel(input) {
  return input.replace(/[^a-zA-Z0-9]+(.)/g, (match, char) => char.toUpperCase())
}
