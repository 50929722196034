import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import Tooltip from '../../../tooltip';
import useInterval from '../../../../utils/use-interval';
import { ReactComponent as Clock } from '../../../../shared/icons/clock.svg';
import styles from './styles.module.scss';

export const LeaseDocumentWizardHeaderLastSaved = ({
  datetime,
  intervalInSecond,
  shouldBeShortenedTimeApplied,
}: {
  datetime: string;
  intervalInSecond: number;
  shouldBeShortenedTimeApplied?: boolean;
}) => {
  const [lastUpdatedTime, setLastUpdatedTime] = useState(dayjs(datetime).format('LLL'));
  const setUpdatedTimeFromNow = useCallback(() => setLastUpdatedTime(dayjs(datetime).fromNow()), [datetime]);
  const intervalForUpdate = intervalInSecond * 1000;

  useEffect(() => {
    setLastUpdatedTime(dayjs(dayjs.max(dayjs(), dayjs(datetime))).fromNow(true));
    setUpdatedTimeFromNow();
  }, [datetime, setUpdatedTimeFromNow]);

  useInterval(() => {
    setUpdatedTimeFromNow();
  }, intervalForUpdate);

  return (
    <>
      <Tooltip
        placement="bottom"
        icon={<Clock width={18} height={18} className={styles.clock} />}
        testId="activity-time-tooltip"
      >
        { dayjs(datetime).format("LLL") }
      </Tooltip>
      <span title={`${lastUpdatedTime}Z`}>{lastUpdatedTime}</span>
    </>
  );
};
