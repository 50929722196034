import React, { ChangeEvent, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { usePermissions } from '../auth';
import { graphqlErrorsToUiErrors, queries } from '../api';
import { useTeamInfo } from '../team-id-context';
import ErrorMessage from '../components/error-message';
import { ContentHeader } from '../components/private-layout';
import { TeamMembersListContainer } from '../components/teamMembersList';
import AddTeamMemberForm from '../forms/addTeamMemberForm';
import LoadingSpinner from '../components/loading-spinner';
import { Permissions } from '../shared/constants/permissions';
import InputTitle from './deals/components/statusDropdown/InputTitle';
import SearchInput from '../components/search-input';
import { DropdownBody, DropdownItem, DropdownToggle } from '../components/dropdown';
import { Checkbox } from '../components/checkbox-or-radio-field';
import slugify from '../utils/slugify';
import { fuzzyInputSearch } from './deals/helpers/determineDealsActionModalMessage';
import { TitleFormattedMessage } from '../components/titleFormattedMessage';
import { ContentBody } from '../components/private-layout/ContentBody';
import { ReactComponent as FilterIcon } from '../shared/icons/filter-icon.svg';
import styles from './styles.module.scss';
import FilterSidebar from './people/components/filterSidebar';

const Team = () => {
  const { loading: teamInfoLoading, teamId } = useTeamInfo();
  const { formatMessage } = useIntl();
  const [showAddMember, setShowAddMember] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterDeals, setFilterDeals] = useState<Set<string>>(new Set());
  const [dealTitleSearch, setDealTitleSearch] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [sorting, setSorting] = useState('');

  // Get permissions getter
  const { isAdmin, hasPermission } = usePermissions({ teamId });

  // Get information about the team and its members
  const { loading: teamQueryLoading, data: teamData, error: teamGraphqlError } = useQuery(queries.getTeamById, {
    skip: teamId == null,
    variables: {
      teamId,
    },
  });

  const { loading: dealsDataLoading, data: dealData } = useQuery(queries.getDealsForTeam, {
    skip: !teamId,
    variables: {
      teamId,
    },
  });

  // Are we still loading data
  const isLoading = teamInfoLoading || teamQueryLoading;

  // Control the "add member" modal

  const allDeals = useMemo(() => {
    if (dealsDataLoading || !dealData) {
      return [];
    }
    const allTeamDeals = dealData.dealsForTeam
      ?.filter((deal: { title: string }) => {
        if (dealTitleSearch) {
          return fuzzyInputSearch({ searchValue: deal.title, filteredText: dealTitleSearch });
        }

        return true;
      })
      .map((deal: { title: string; id: string }) => {
        return { title: deal.title, id: deal.id };
      })
      .sort((a: { title: string; id: string }, b: { title: string; id: string }) => a.title.localeCompare(b.title));

    return allTeamDeals;
  }, [dealsDataLoading, dealData, dealTitleSearch]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Get a team object
  const team = teamData?.teamById;
  const teamName = team.name;

  if (!team) {
    const errors = graphqlErrorsToUiErrors(teamGraphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  return (
    <>
      <Helmet title={formatMessage({ id: 'title.team', defaultMessage: 'Your team' })} />
      <ContentHeader>
        {isAdmin ? (
          <div className={'ml-3'}>
            <InputTitle title={teamName} isTeamTitleEditing />
          </div>
        ) : (
          <div className={styles.teamTitle}>
            <div data-testid="your-team-header" role={'heading'} className={'ml-3'}>
              <FormattedMessage id="title.team" defaultMessage={teamName} />
            </div>
          </div>
        )}
      </ContentHeader>
      <ContentBody>
        <Row className="mb-3">
          <Col className={'ml-3 mr-3 d-flex justify-content-between non-mobile-flex'}>
            <div className={'d-flex flex-fill'}>
              <SearchInput
                className="mr-3"
                value={filterText}
                onChange={(event: any) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'members.filter.title',
                  defaultMessage: 'Search by Name',
                })}
                aria-label={formatMessage({
                  id: 'members.filter.name',
                  defaultMessage: 'Name',
                })}
              />

              <div className={'mr-3'}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-filter-cities"
                    as={DropdownToggle}
                    /*
                  // @ts-ignore */
                    className="w-100 overflow-hidden"
                  >
                    <FormattedMessage
                      id="deals.filter.creator.label-readout"
                      defaultMessage={`{filterCount, select,
                      0 {Deals }
                      other {Deals ({filterCount})}
                    }`}
                      values={{
                        filterCount: filterDeals.size,
                        deal: filterDeals.size ? filterDeals.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className={classNames(styles.dropDownSearchBox, 'd-flex bg-light align-items-center')}>
                      <SearchInput
                        autoFocus
                        className="w-auto"
                        placeholder="Search Deals"
                        /*

            //@ts-ignore  */
                        style={{ flex: 'auto' }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setDealTitleSearch(e.currentTarget.value)}
                        value={dealTitleSearch}
                        id={`dealTitleSearchquery`}
                      />
                    </div>

                    {/* <DropdownItem highlightable>
                  <Checkbox
                    checked={!!allDeals.length && filterDeals.size === allDeals.length}
                    indeterminate={filterDeals.size > 0 && filterDeals.size < allDeals.length}
                    id="dropdown-filter-creators-_all"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const checkbox = event.currentTarget;
                      if (checkbox.checked) {
                        setFilterDeals(
                          new Set(
                            allDeals.map((creator: any) => {
                              return creator.id;
                            })
                          )
                        );
                      } else {
                        setFilterDeals(new Set());
                      }
                    }}
                    label={formatMessage({ id: 'deals.filter.creator.all-deals', defaultMessage: 'All Deals' })}
                  />
                </DropdownItem>*/}
                    <DropdownBody>
                      {allDeals.map((creator: any, i: number) => (
                        <DropdownItem key={`creator${i}`} highlightable>
                          <Checkbox
                            key={creator.id}
                            value={creator.title}
                            checked={filterDeals.has(creator.id)}
                            id={`dropdown-filter-cities-${slugify(creator.id)}`}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterDeals);
                              if (checkbox.checked) newSet.add(creator.id);
                              else newSet.delete(creator.id);
                              setFilterDeals(newSet);
                            }}
                            label={creator.title}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {(isAdmin || hasPermission(Permissions.Invite)) && (
              <>
                <Button variant="secondary" onClick={() => setShowAddMember(true)}>
                  <TitleFormattedMessage id="cta.add-team-member" defaultMessage={'Add team member'} />
                </Button>

                <Modal show={showAddMember} onHide={() => setShowAddMember(false)} size="lg">
                  <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
                    <Modal.Title>
                      <header>
                        <FormattedMessage id="title.add-team-member" defaultMessage="Add new team member" />
                      </header>
                    </Modal.Title>
                  </Modal.Header>
                  <AddTeamMemberForm
                    bodyContainer={Modal.Body}
                    actionsContainer={Modal.Footer}
                    renderSecondaryAction={() => (
                      <Button variant="link" className="text-secondary" onClick={() => setShowAddMember(false)}>
                        <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
                      </Button>
                    )}
                    onSuccess={() => setShowAddMember(false)}
                  />
                </Modal>
              </>
            )}
          </Col>
          <Col className={'mx-3 d-flex flex-column justify-content-between mobile-flex'}>
            {(isAdmin || hasPermission(Permissions.Invite)) && (
              <Button variant="secondary" onClick={() => setShowAddMember(true)} className="mobileHeaderBtn">
                <TitleFormattedMessage id="cta.add-team-member" defaultMessage={'Add team member'} />
              </Button>
            )}
            <div className={'d-flex flex-fill mt-3'}>
              <SearchInput
                iconClassName="mobileSearch__icon"
                inputClassName="mobileSearch"
                className="mobileSearch__container"
                value={filterText}
                onChange={(event: any) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'members.filter.title',
                  defaultMessage: 'Search by Name',
                })}
                aria-label={formatMessage({
                  id: 'members.filter.name',
                  defaultMessage: 'Name',
                })}
              />

              <Button className="filterBtn" onClick={() => setShowFilter(true)}>
                <FilterIcon width={24} height={24} />
              </Button>
            </div>
          </Col>
        </Row>
        <Row className={styles.contentTable}>
          <Col>
            <TeamMembersListContainer
              filterText={filterText}
              filterDeals={filterDeals}
              allDeals={allDeals}
              sorting={sorting}
            />
          </Col>
        </Row>
      </ContentBody>
      <FilterSidebar
        isOpen={showFilter}
        filterDeals={filterDeals}
        allDeals={allDeals}
        onClose={() => setShowFilter(!showFilter)}
        setSorting={setSorting}
        setFilterDeals={setFilterDeals}
      />
    </>
  );
};

export default Team;
