const STORAGE_KEY_BASE = 'latest-team-id';

/**
 * Get the storage key for the latest team ID, for a given user ID
 *
 * @function getKey
 * @param {string} userId - The user ID
 * @returns {string} - The local storage key for this user ID
 */
function getKey(userId) {
  return `${STORAGE_KEY_BASE}:${userId}`;
}

/**
 * Store the latest-viewed team ID
 *
 * @function set
 * @param {string} userId - The user ID
 * @param {string} teamId - The team ID
 * @returns {void}
 */
export function set(userId, teamId) {
  localStorage.setItem(getKey(userId), teamId);
}

/**
 * Get the latest-viewed team ID
 *
 * @function get
 * @param {string} userId - The user ID
 * @returns {?string} - Team ID or null
 */
export function get(userId) {
  return localStorage.getItem(getKey(userId));
}

/**
 * Clear the latest-viewed team ID
 *
 * @function remove
 * @param {string} userId - The user ID
 * @returns {void}
 */
export function remove(userId) {
  localStorage.removeItem(getKey(userId));
}
