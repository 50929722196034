import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../../../loading-spinner';
import { LeaseDocumentWizardCTAType } from '../../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import { LeaseDocumentWizardModal } from '../../LeaseDocumentWizardModal';

interface Props {
  show: boolean;
  onHide: () => void;
  handler: () => void;
  isLoading?: boolean;
  title?: string;
  submitButtonTitle: string;
  cancelButtonTitle: string;
  children?: React.ReactNode;
}

export function LeaseDealActionModal({
  onHide,
  handler,
  cancelButtonTitle,
  submitButtonTitle,
  isLoading,
  show,
  title,
  children,
}: Props) {
  const footerCTAs: Array<LeaseDocumentWizardCTAType> = [
    {
      onClick: onHide,
      label: (
        <FormattedMessage
          id="lease-document-wizard-preview-send-offer.cta.cancel"
          defaultMessage={`${cancelButtonTitle || 'Cancel'}`}
        />
      ),
      theme: 'outline-secondary',
      disabled: false,
    },
    {
      onClick: handler,
      label: (
        <>
          <FormattedMessage
            id="lease-document-wizard-preview-send-offer.cta.send-to-tenant"
            defaultMessage={submitButtonTitle}
          />
          {isLoading && <LoadingSpinner />}
        </>
      ),
      theme: 'secondary',
      disabled: isLoading,
    },
  ];

  return (
    <LeaseDocumentWizardModal size="lg" show={show} onHide={onHide} title={title} modalCTAs={footerCTAs}>
      <section className="lease-document-wizard-summary-modal">{children}</section>
    </LeaseDocumentWizardModal>
  );
}

LeaseDealActionModal.defaultProps = {
  handler: () => {},
};
