export interface DocumentSigner {
  id: string;
  order: number;
  embeddedSigningUrl: string;
  email: string;
  signedAt: string;
  token: string;
}

export interface DocumentSignerWithSignedAt extends Omit<DocumentSigner, 'signedAt' | 'embeddedSigningUrl' | 'token'> {
  signedAt?: string | null;
}

export type SetLeaseDocumentSigners = 'setLeaseDocumentSigners';
export type SetLeaseDocumentSignersAction = {
  type: SetLeaseDocumentSigners;
  signers: DocumentSigner[];
};

export const setLeaseDocumentSigners = (signers: DocumentSigner[]): SetLeaseDocumentSignersAction => ({
  type: 'setLeaseDocumentSigners',
  signers,
});
