/**
 * Turn a plain object into a FormData object
 *
 * @function objectToFormData
 * @param {Object} object - Input object
 * @returns {FormData} - FormData object
 */
export default function objectToFormData(object) {
  const formData = new FormData()

  for (const [k, v] of Object.entries(object)) {
    formData.append(k, v)
  }

  return formData
}
