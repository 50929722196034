import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseDocumentReceiverTeamIdAction } from '../actions/lease-document.actions';

export const handleSetLeaseDocumentReceiverTeamId = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentReceiverTeamIdAction
): LeaseDocumentStateType => ({
  ...state,
  receiverTeamId: action.receiverTeamId,
});
