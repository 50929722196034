import React, { useMemo, useCallback } from 'react';
import styles from './styles.module.scss';
import { useMutation } from '@apollo/client';
import { updateUserCommunicationPreference } from '../../../../api/mutations';
import { ReactComponent as BellOff } from '../../../../shared/icons/bell-off.svg';
import { ReactComponent as BellOn } from '../../../../shared/icons/bell-on.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ElementLoadingSpinner from '../../../../components/loading-element';

interface DealNotificationToggleProps {
  dealId: string;
  userDealNotifications: Record<string, boolean>;
  refetchUser: () => Promise<void>;
}

const DealNotificationToggle = ({ dealId, userDealNotifications, refetchUser }: DealNotificationToggleProps) => {
  const [updateCommunicationPreferences, { error, loading }] = useMutation(updateUserCommunicationPreference);

  // Determine if notifications are enabled for the deal
  const isNotificationsEnabled = useMemo(() => {
    return userDealNotifications ? userDealNotifications[dealId] : false;
  }, [dealId, userDealNotifications]);

  // Function to handle toggling notifications
  const toggleDealNotifications = useCallback(async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const editableNotifications = {...userDealNotifications}; // Shallow copy for immutability

    if (editableNotifications.hasOwnProperty(dealId)) {
      const notificationsArray = Object.entries(editableNotifications).map(([key, value]) => ({
        dealId: key,
        notify: key === dealId ? !value : value,
      }));

      try {
        await updateCommunicationPreferences({
          variables: { preferences: { dealNotifications: notificationsArray } }
        });
        await refetchUser();
      } catch (updateError) {
        console.error("Failed to update communication preferences:", updateError);
      }
    } else {
      console.warn('Deal ID does not exist in the object');
    }
  }, [userDealNotifications, dealId, updateCommunicationPreferences, refetchUser]);

  if (error) {
    console.error("Error updating communication preferences:", error.message);
  }

  // Return early if required props are not available
  if (!dealId || !userDealNotifications) {
    console.error("Missing dealId or userDealNotifications");
    return null;
  }

  if (loading) {
    return (
      <div
        id={`deal-notification-toggle-${dealId}`}
        className={styles.wrapper}
        onClick={toggleDealNotifications}
      >
        <ElementLoadingSpinner />
      </div>
    )
  }

  return (
    <>
      <div
        id={`deal-notification-toggle-${dealId}`}
        className={styles.wrapper}
        onClick={toggleDealNotifications}
      >
        {isNotificationsEnabled
          ? <BellOn data-testid={`deal-notification-toggle-${dealId}-on`} />
          : <BellOff data-testid={`deal-notification-toggle-${dealId}-off`} />
        }
      </div>
      <ReactTooltip
        style={{
          zIndex: 99,
          color: '#18191b',
          backgroundColor: '#ddebef',
          opacity: 0,
          fontWeight: 'normal',
          maxWidth: '300px',
          textAlign: 'center',
          padding: '8px',
          fontSize: '14px'
        }}
        anchorSelect={`#deal-notification-toggle-${dealId}`}
        id={`tooltip-${dealId}`}
        place="bottom"
      >
        {isNotificationsEnabled ? 'Notifications On' : 'Notifications Off'}
      </ReactTooltip>
    </>
  );
};

export default DealNotificationToggle;
