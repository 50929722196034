import React from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmActionModal from '../../../confirm-action-modal';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface RemoveMemberFromDealConfirmationModalProps {
  onClick: () => void;
  onHide: () => void;
  userToRemoveFromDeal: any;
}

const RemoveMemberFromDealConfirmationModal = ({
  onClick,
  onHide,
  userToRemoveFromDeal,
}: RemoveMemberFromDealConfirmationModalProps) => {
  const getMessage = () => {
    if (!userToRemoveFromDeal) return '';

    return (
      <>
        <p>
          <FormattedMessage
            id="phrase.confirm-removing-team-member-from-deal"
            defaultMessage="Are you sure you would like to remove {fullName} from this deal?"
            values={userToRemoveFromDeal}
          />
        </p>
        {userToRemoveFromDeal.isWhitelisted && (
          <p>
            <FormattedMessage
              id="phrase.user-will-no-longer-have-whitelist"
              defaultMessage="Removing this user from this deal will mean they will no longer have the <i>View All Deals</i> permission for this team."
              values={{
                ...userToRemoveFromDeal,
                i: (msg: string) => <em>{msg}</em>,
              }}
            />
          </p>
        )}
      </>
    );
  };

  return (
    <ConfirmActionModal
      show={!!userToRemoveFromDeal}
      onHide={onHide}
      onClick={onClick}
      title={<FormattedMessage id="title.remove-team-member-from-deal" defaultMessage="Remove team member?" />}
      message={getMessage()}
      action={<TitleFormattedMessage id="cta.remove" defaultMessage="Remove" />}
    />
  );
};

export default RemoveMemberFromDealConfirmationModal;
