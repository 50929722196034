import { ApolloClient } from '@apollo/client';
import { getDealById } from '../../../api/queries';
import { getDocumentsByDeal } from '../../../api/queries_v2';
import { graphql } from '../../../gql';

/**
 * Terminate a lease document offer
 * @param documentId
 * @param dealId
 */

export function terminateDocument({
  documentId,
  dealId,
  additionalMessage,
}: {
  documentId: string;
  dealId: string;
  additionalMessage?: string;
}) {
  return function(client: ApolloClient<object>): Promise<any> {
    const variables = {
      documentId,
      additionalMessage,
    };

    const mutation = graphql(/* GraphQL */`
      mutation terminateDocument($documentId: ID!, $additionalMessage: String) {
        terminateDocument(documentId: $documentId, additionalMessage: $additionalMessage) {
          id
          success
        }
      }
    `);

    const refetchQueries = [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
      {
        query: getDocumentsByDeal,
        variables: {
          dealId,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.terminateDocument);
  };
}
