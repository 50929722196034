import React, { ReactNode } from 'react';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';

import classNames from 'classnames';
import './lease-badge.scss';
import { getClassNameForStatus } from '../lease-badge-container/helpers';

type LeaseBadgeProps = {
  documentStatus: DocumentStatusEnum;
  children: ReactNode | string;
};
export const LeaseBadge = ({ documentStatus, children }: LeaseBadgeProps) => (
  <small role="presentation" className={classNames('lease-badge', getClassNameForStatus(documentStatus))}>
    {children}
  </small>
);
