import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import PropdocsModal from '../../../../modal/PropdocsModal';
import styles from './styles.module.scss'
import { setActiveSidenavPlaceholder, toggleSidenavPlaceholdersCollapsed } from '../../../../../store/navigation/navigation.actions';
import { selectSidenavPlaceholdersCollapsed } from '../../../../../store/navigation/navigation.selectors';
import { setClosePendingWarningReviewModal } from '../../../../../store/modals/modals.actions';

interface PlaceholderWarningModalProps {
  isOpen: boolean;
  placeholders: number;
  onReview?: () => void;
  onContinue: () => void;
  onHide?: () => void;
  modalTitle?: string;
  continueButtonText?: string;
  reviewButtonText?: string;
}

const PlaceholderWarningModal: React.FC<PlaceholderWarningModalProps> = ({
  isOpen = false,
  placeholders,
  onReview,
  onContinue,
  onHide,
  modalTitle = "Placeholders",
  continueButtonText = "Continue Anyways",
  reviewButtonText = "Review",
}) => {
  const dispatch = useDispatch();

  const [showPlaceholderWarningModal, setShowPlaceholderWarningModal] = useState(isOpen);

  const isPlaceholderSidenavCollapsed = useSelector(selectSidenavPlaceholdersCollapsed);

  const [collapsePlaceholderSidenav, setCollapsePlaceholderSidenav] = useState(isPlaceholderSidenavCollapsed);

  const handleReview = () => {
    setShowPlaceholderWarningModal(false);

    if (onReview) {
      onReview();
    }
    
    dispatch(setActiveSidenavPlaceholder(null));
    
    dispatch(setClosePendingWarningReviewModal(true));

    if (collapsePlaceholderSidenav) {
      dispatch(toggleSidenavPlaceholdersCollapsed(false));
    }
  };

  const handleContinue = () => {
    setShowPlaceholderWarningModal(false);
    onContinue();
  };

  useEffect(() => {
    setShowPlaceholderWarningModal(isOpen);
  },[isOpen]);

  useEffect(() => {
    setCollapsePlaceholderSidenav(isPlaceholderSidenavCollapsed);
  },[isPlaceholderSidenavCollapsed]);


  return (
    <PropdocsModal 
      size="md" 
      className={styles.placeholderWarningModal}
      title=<div className={styles.placeholderWarningModal__header}>{modalTitle}</div> 
      show={showPlaceholderWarningModal} 
      setShow={() => {
        setShowPlaceholderWarningModal(!showPlaceholderWarningModal);
        
        if (onHide) {
          onHide();
        }
      }}
    >
      <>
        <div className={styles.placeholderWarningModal__title}>There are {placeholders} incomplete required placeholders</div>
        <div className={styles.placeholderWarningModal__buttonsContainer}>
          <Button className={styles.placeholderWarningModal__buttonReview} variant="secondary" onClick={handleReview}>{reviewButtonText}</Button>
          <Button onClick={handleContinue} className={styles.placeholderWarningModal__buttonContinue}>{continueButtonText}</Button>
        </div>
      </>
    </PropdocsModal>
  );
};

export default PlaceholderWarningModal;