import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import LoadingSpinner from './loading-spinner';

type ButtonVariant = 'primary' | 'secondary';

interface SubmitButtonProps {
  label: string | ReactNode;
  onClick?: () => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
  className?: string;
}

const SubmitButton = ({ label, isSubmitting = false, ...rest }: SubmitButtonProps) => {
  return (
    <Button type="submit" variant="secondary" {...rest}>
      {isSubmitting ? <LoadingSpinner center={false} variant="" /> : label}
    </Button>
  );
};

export default SubmitButton;
