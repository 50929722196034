import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';
import { CommentInputType } from "../../../gql/graphql";

export type UpdateComments = {
  documentId: string;
  threadId: string;
  comments: CommentInputType | CommentInputType[];
  unlinkedAt?: string;
};

const mutation = graphql(/* GraphQL */`
  mutation UpdateDocumentComments($documentId: ID!, $threadId: String!, $comments: [CommentInputType!]!, $unlinkedAt: String) {
    updateDocumentComments(documentId: $documentId, comments: $comments, threadId: $threadId, unlinkedAt: $unlinkedAt)
  }
`);

export const updateDocumentComments = (options: UpdateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const updateDocumentCommentsWithResult = (options: UpdateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.updateDocumentComments.success);
  });
};
