import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LeaseDocumentWizardModal } from '../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import styles from './styles.module.scss';
import { OptionalMessageInputRef } from '../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/OptionalMessageInputRef';

interface CancelSigningPreparationModalProps {
  onHide: () => void;
  isLoading: boolean;
  onConfirm: () => void;
  show: boolean;
  additionalMessageInputRef: React.Ref<HTMLTextAreaElement>;
}

const CancelSigningPreparationModal = ({
  onHide,
  isLoading,
  onConfirm,
  show,
  additionalMessageInputRef,
}: CancelSigningPreparationModalProps) => {
  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onHide,
      label: 'Cancel',
      theme: 'outline-secondary',
    },
    {
      onClick: onConfirm,
      label: 'Edit Document',
      theme: 'secondary',
      isLoading,
      disabled: isLoading,
      className: styles['cancel-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={styles.title}
      size="lg"
      show={show}
      onHide={onHide}
      title={<FormattedMessage id="lease-document-wizard-modal.send-for-signing" defaultMessage="Edit Document" />}
      modalCTAs={modalCTAs}
    >
      <div className={styles.main}>
        To edit this document, you must cancel the signing preparation. When this document is returned to signing
        preparation, some of the inserted tags may be moved as a result of changes to the document.
      </div>
      <OptionalMessageInputRef ref={additionalMessageInputRef} />
    </LeaseDocumentWizardModal>
  );
};

export default CancelSigningPreparationModal;
