import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { removeReceiverTeamFromDeal } from "../../../../utils/api/remove_receiver_from_deal/remove_receiver_from_deal";
import { useNotifications } from "../../../../notifications";
import { useApolloClient } from "@apollo/client";
import {
  LeaseDealActionModal
} from "../../../lease-wizard/lease-document-wizard-modal/custom-modals/lease-deal-action-modal/LeaseDealActionModal";
import { REMOVE_COUNTERPARTY_MODAL_MESSAGE } from "../../../../pages/deals/helpers/determineDealsActionModalMessage";
import styles from '../../../../pages/deals/styles.module.scss';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface RemoveCounterpartyModalProps {
  dealId: string,
  teamId: string,
  userId: string,
}
const RemoveCounterpartyModal = ({ dealId }: RemoveCounterpartyModalProps) => {
  const apolloClient = useApolloClient();
  const [showRemoveCounterpartyModal, setShowRemoveCounterpartyModal] = useState(false);
  const [, { error: notifyError }] = useNotifications();
  const [isLoading, setLoading] = useState(false);
  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);

  const handleFailureResponse = (error: any) => {
    notifyError({
      message: {
        id: 'offers.terminate-lease-offer.error',
        defaultMessage: error || 'Something went wrong.',
      },
    });
  };

  const handleRemoveCounterparty = () => {
    setLoading(true);
    const additionalMessage = additionalMessageInputRef?.current?.value;
    removeReceiverTeamFromDeal({ dealId, additionalMessage })(apolloClient)
      .then(() => {
        setShowRemoveCounterpartyModal(false)
        setLoading(false);
      })
      .catch(error => {
        handleFailureResponse(error.message);
      })
      .finally(() => {
        setShowRemoveCounterpartyModal(false)
        setLoading(false);
      });
  };

  const modalInfo = {
    title: 'Remove Counterparty',
    submitButtonTitle: 'Remove Access',
    showAdditionalMessageField: true,
    message: REMOVE_COUNTERPARTY_MODAL_MESSAGE,
    cancelButtonTitle: 'Close',
  };

  return (
    <>
      <Button variant="outline-danger" className="w-100" onClick={() => setShowRemoveCounterpartyModal(true)}>
        <TitleFormattedMessage id="cta.remove" defaultMessage="Remove" />
      </Button>
      {showRemoveCounterpartyModal && (
        <LeaseDealActionModal
          show={showRemoveCounterpartyModal}
          onHide={() => setShowRemoveCounterpartyModal(false)}
          isLoading={isLoading}
          handler={handleRemoveCounterparty}
          title={modalInfo.title}
          submitButtonTitle={modalInfo.submitButtonTitle}
          cancelButtonTitle={modalInfo.cancelButtonTitle}
        >
          {modalInfo.message}
          {modalInfo.showAdditionalMessageField && (
            <div className={styles.dealModalInputWrapper}>
              <textarea
                className={styles.dealModalInputWrapper__input}
                ref={additionalMessageInputRef}
                placeholder="Message (Optional)"
              />
            </div>
          )}
        </LeaseDealActionModal>
      )}
    </>
  );
};

export default RemoveCounterpartyModal;
