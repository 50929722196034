import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetEditorSuggestionsAction } from '../actions/lease-document.actions';

export const handleSetEditorSuggestions = (
  state: LeaseDocumentStateType,
  action: SetEditorSuggestionsAction
): LeaseDocumentStateType => ({
  ...state,
  editorSuggestions: action.suggestions,
});
