import React from 'react';

import { ReactComponent as ChevronDownIcon } from '../../shared/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../shared/icons/chevron-up.svg';

import styles from './styles.module.scss';

interface DropdownPanelToggleProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
}

export const DropdownPanelToggle: React.FC<DropdownPanelToggleProps> = ({ title, onToggle, isOpen }) => {
  return (
    <div className={styles.dropdown__toggle} onClick={onToggle} data-testid={`dropdown-toggle-${title.toLowerCase()}`}>
      {title}
      {isOpen ? <ChevronUpIcon width={14} height={14} /> : <ChevronDownIcon width={14} height={14} />}
    </div>
  );
};
