import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useQuery, useApolloClient } from '@apollo/client';
import { Col } from 'react-bootstrap';
import { graphqlErrorsToUiErrors, queries, queriesV2, useTeamPermissions } from '../../api';
import { getDealById } from '../../api/queries';
import { useTeamInfo } from '../../team-id-context';
import { useDealId } from '../../deal-id-context';
import ErrorMessage from '../error-message';
import LoadingSpinner from '../loading-spinner';
import { TeamMembersListContainer } from '../teamMembersList';
import { HandleTeamActionsEnum } from '../../pages/deals/constants/AddOrEditTeamConstants';
import SelectTeamMembers from '../../forms/components/SelectTeamMembers';
import { Permissions } from '../../shared/constants/permissions';
import InviteNewTeamMemberModal from '../../pages/deals/modals/InviteNewTeamMemberModal';
import { NotFound } from '../../pages';
import { useLeaseDocumentTeamPermissions } from '../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { User } from '../lease-wizard/lease-editor/LeaseEditorTypes';
import { useAuth, usePermissions } from '../../auth';
import { Permission } from '../../gql/graphql';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './styles.module.scss';
import classNames from 'classnames';

const EditTeam = () => {
  const { teamId } = useTeamInfo();
  const [{ authLoading, user }] = useAuth();
  const apolloClient = useApolloClient();
  const dealId = useDealId();
  const [showAddMember, setShowAddMember] = useState(false);
  const { isAdmin } = usePermissions();
  const { loading: permissionsLoading, teamPermissions } = useTeamPermissions();
  const { hasInviteSendingPermission } = useLeaseDocumentTeamPermissions({ teamId, apolloClient });

  const { loading: getDealByIdDataLoading, data, error: graphqlError } = useQuery(getDealById, {
    fetchPolicy: 'no-cache',
    variables: {
      dealId,
    },
  });

  const { loading: teamQueryDataLoading, data: teamData } = useQuery(queries.getTeamById, {
    variables: {
      teamId,
    },
  });

  const { loading: dealTeamsQueryLoading, data: dealTeamsData } = useQuery(queriesV2.getDealTeamsByTeamId, {
    variables: {
      dealId,
    },
  });

  const loading =
    getDealByIdDataLoading || teamQueryDataLoading || dealTeamsQueryLoading || permissionsLoading || authLoading;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    const errors = graphqlErrorsToUiErrors(graphqlError);
    if (errors._general.id === 'api.error.not-found') {
      return <NotFound />;
    }
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const viewPermission = teamPermissions?.find(({ key }: any) => key === Permissions.View);
  const teamMembers = teamData.teamById.teamMembers.filter(
    (member: { user: User }) => isAdmin || member.user.id !== user.id
  );
  const {
    getDealById: { members: dealMembers },
  } = dealTeamsData;

  return (
    <>
      <Container fluid="md" className="pb-md-3 h-100 ">
        {hasInviteSendingPermission && (
          <Row>
            <Col lg={8} className={classNames(styles.existingMember, 'd-flex')}>
              <SelectTeamMembers
                teamMembers={teamMembers}
                dealMembers={dealMembers}
                dealId={dealId}
                viewPermission={viewPermission as Pick<Permission, 'id'>}
              />
            </Col>
            <Col lg={4} md={12} className={classNames('text-right', styles.InviteOuterBtn)}>
              <Button
                variant="primary"
                onClick={() => setShowAddMember(true)}
                className={classNames(styles.handleParticipantsButton, styles.inviteBtn)}
              >
                <TitleFormattedMessage
                  id="cta.add-team-member-to-deal"
                  defaultMessage={HandleTeamActionsEnum.INVITE_NEW_MEMBERS}
                />
              </Button>
            </Col>
          </Row>
        )}
        <Row className={classNames('h-100', styles.heightMobile)}>
          <Col className={classNames(styles.teamMembersListContainer, styles.contentMemberList)}>
            <TeamMembersListContainer showDealMembersOnly passedTeamData={teamData} />
          </Col>
        </Row>
      </Container>

      <InviteNewTeamMemberModal showModal={showAddMember} hideModal={() => setShowAddMember(false)} />
    </>
  );
};

export default EditTeam;
