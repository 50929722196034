import React from 'react';
import { SendDocumentForSigningModal } from '../../../../modals';

interface Props {
  show: boolean;
  onHide: () => void;
  documentId: string;
  dealId: string;
  teamId: string;
}

const SendToExternalSigningModal = ({ show, onHide, dealId, documentId, teamId }: Props) => {
  return (
    <SendDocumentForSigningModal show={show} documentId={documentId} onHide={onHide} dealId={dealId} teamId={teamId} />
  );
};

export default SendToExternalSigningModal;
