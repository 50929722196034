import { ChangeEvent } from 'react';
import { Checkbox, RadioField } from '../../../../components/checkbox-or-radio-field';
import { FormattedMessage, useIntl } from 'react-intl';
import slugify from '../../../../utils/slugify';
import { DealForFilter } from '.';

interface DealFormProps {
  deals: DealForFilter[];
  filtered: Set<string>;
  setFilterDeals: (value: Set<string>) => void;
}

export const DealForm: React.FC<DealFormProps> = ({ deals, filtered, setFilterDeals }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {/* <Checkbox
        checked={filtered.size === deals.length}
        indeterminate={filtered.size > 0 && filtered.size < deals.length}
        id="mobile-filter-deals-_all"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const checkbox = event.currentTarget;
          if (checkbox.checked) {
            setFilterDeals(new Set(deals.map(deal => deal.id)));
          } else {
            setFilterDeals(new Set());
          }
        }}
        label={formatMessage({
          id: 'deals.filter.deal.all-deals',
          defaultMessage: 'All Deals',
        })}
      /> */}
      {deals.map((deal: DealForFilter) => (
        <Checkbox
          key={deal.id}
          value={deal.id}
          checked={filtered.has(deal.id)}
          id={`mobile-filter-creators-${slugify(deal.id)}`}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const checkbox = event.currentTarget;
            const newSet = new Set(filtered);
            if (checkbox.checked) newSet.add(checkbox.value);
            else newSet.delete(checkbox.value);
            setFilterDeals(newSet);
          }}
          label={deal.title}
        />
      ))}
    </>
  );
};
