import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getStatus } from './helpers';
import {
  selectLeaseDocumentPossession,
  selectLeaseDocumentReceiverTeam,
  selectLeaseDocumentStatus,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import { LeaseBadge } from '../lease-badge/LeaseBadge';
import { getDocumentStatusIndicator } from '../../document-table/helpers/documentStatusIndication';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';

export const LeaseBadgeContainer = () => {
  const receiverTeamId = useSelector(selectLeaseDocumentReceiverTeam);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const hasCurrentTeamPossession = useSelector(selectLeaseDocumentPossession);
  const badgeStatusIndicator = getDocumentStatusIndicator(
    (documentStatus as DocumentStatusEnum) || DocumentStatusEnum.DRAFT,
    hasCurrentTeamPossession
  );

  const [currentStatus, setCurrentStatusMessage] = useState<DocumentStatusEnum | null>(null);

  let isShared = !!receiverTeamId;

  useEffect(() => {
    setCurrentStatusMessage(getStatus(documentStatus));
  }, [receiverTeamId, documentStatus, isShared]);

  return (
    <LeaseBadge documentStatus={currentStatus || DocumentStatusEnum.DRAFT}>
      {currentStatus && (
        <FormattedMessage
          id={`lease-document-wizard.badge.${currentStatus}-offer`}
          defaultMessage={badgeStatusIndicator}
        />
      )}
    </LeaseBadge>
  );
};
