import { SendReminderConfirmationModalContainer } from '../../../../lease-document-wizard-custom-stack/send-reminder-confirmation-modal/SendReminderConfirmationModalContainer';

interface Props {
  show: boolean;
  onHide: () => void;
  dealId: string;
  teamId: string;
  documentId?: string;
}

const SendSigningReminderModal = ({ show, onHide, dealId, teamId, documentId }: Props) => {
  return (
    <SendReminderConfirmationModalContainer
      show={show}
      onHide={onHide}
      dealId={dealId}
      teamId={teamId}
      documentId={documentId}
    />
  );
};

export default SendSigningReminderModal;
