import React from 'react'
import { FormattedMessage } from 'react-intl'
import ProgressBar from 'react-bootstrap/ProgressBar'

const PasswordStrength = ({ score }) => {
  if (score == null) {
    return null
  }

  return (
    <div className="d-flex align-items-center mt-1">
      <ProgressBar now={(score * 100) / 4} className="flex-grow-1" />
      <div className="ml-3 text-right" style={{ minWidth: '5em' }}>
        <FormattedMessage
          id="phrase.password-strength.feedback"
          defaultMessage={`<sr>Password strength:</sr> {score, select,
            0 {Very weak}
            1 {Weak}
            2 {Okay}
            3 {Moderate}
            4 {Strong}
          }`}
          values={{
            sr: msg => <span className="sr-only">{msg}</span>,
            score,
          }}
        />
      </div>
    </div>
  )
}

export default PasswordStrength
