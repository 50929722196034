import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useApolloClient, useQuery } from '@apollo/client';
import { usePagination, useSortBy, useTable, Row as RowTable } from 'react-table';
import { Button, Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDocumentTemplates, getOrganizationsTemplatesByUser } from '../../api/queries_v2';
import { fillPlaceholders, paths, useNavigate } from '../../routing';
import slugify from '../../utils/slugify';
import { useTeamInfo } from '../../team-id-context';
import { usePermissions } from '../../auth';
import { useUserRestrictions } from '../../auth/user-restrictions';
import { Dropdown, DropdownBody, DropdownItem, DropdownToggle } from '../../components/dropdown';
import SearchInput from '../../components/search-input';
import { ContentHeader } from '../../components/private-layout';
import { Table } from '../../components/table';
import { Checkbox } from '../../components/checkbox-or-radio-field';
import LoadingSpinner from '../../components/loading-spinner';
import Controls, { TemplateStatus } from '../templates/Controls';
import AddNewTemplateFormik from '../../forms/AddNewTemplateFormContainer';
import { useLeaseDocumentTeamPermissions } from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { Permissions } from '../../shared/constants/permissions';
import { TemplateType } from '../../consts/forms-types';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal, SetActiveModalAction } from '../../store/modals/modals.actions';
import { ModalActionsEnum } from '../../components/document-table/DocumentTableConstants';
import { FileUploadModal } from '../../components/shared/modals/fileUploadModal';
import { selectActiveModal } from '../../store/modals/modals.selectors';
import SubscriptionRequiredModal from '../modals/subscriptionModal/SubscriptionRequiredModal';
import Tooltip from '../../components/tooltip';
import { fuzzyInputSearch } from '../deals/helpers/determineDealsActionModalMessage';
import { DocumentTemplate } from '../../gql/graphql';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';
import styles from '../templates/styles.module.scss';
import { ContentBody } from '../../components/private-layout/ContentBody';
import { ReactComponent as FilterIcon } from '../../shared/icons/filter-icon.svg';
import classNames from 'classnames';
import { MobileTable } from '../../components/mobile-layout/MobileTable';
import { MobileTemplateItem } from '../../components/mobile-layout/MobileTemplateItem';
import { templateStatusMessages, statusOptions } from './constants';
import FilterSidebar from './components/filterSidebar';

enum AddTemplateActionStatus {
  CLAUSE = 'Clause',
}

type ColumnsCellType = {
  cell: {
    value: string;
    row: {
      values: {
        template: {
          id: string;
          organizationName: string;
          fromOrganization?: boolean;
        };
        status?: string;
        templateType?: TemplateType;
      };
    };
  };
};

const defaultTypeOptions = new Set<string>([TemplateType.CLAUSE]);
const defaultTemplateStatusOptions = new Set<string>([TemplateStatus.ACTIVE]);

const ClausesLandingPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { teamId } = useTeamInfo();
  const navigate = useNavigate();
  const { hasAccessToClauses, canCreateClauses } = useUserRestrictions();
  const activeModal = useSelector(selectActiveModal);
  const { isAdmin, hasPermission } = usePermissions() as {
    isAdmin: boolean;
    hasPermission: (permission: string) => boolean;
  };
  const apolloClient = useApolloClient();
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [showEditClause, setShowEditClause] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState<TemplateType | null>(null);
  const [filterText, setFilterText] = useState('');
  const [filterCreators, setFilterCreators] = useState(new Set());
  const [filterStatuses, setFilterStatuses] = React.useState(new Set(defaultTemplateStatusOptions));
  const [filterTemplateTypes] = React.useState(new Set(defaultTypeOptions));
  const [showFilter, setShowFilter] = useState(false);
  const [sorting, setSorting] = useState('');
  const canEdit = hasPermission(Permissions.Edit);
  const isTemplateEditable = isAdmin || canEdit;

  const [isSubscriptionModalActive, setSubscriptionModalActive] = useState(false);

  const { loading: isDocumentTemplatesDataLoading, data: documentTemplatesData, refetch } = useQuery(
    getDocumentTemplates,
    {
      fetchPolicy: 'network-only',
      skip: !teamId,
      variables: {
        teamId,
        templateType: 'clause',
      },
    }
  );

  const { loading: isUserOrgsTemplatesDataLoading, data: userOrgsTemplatesData } = useQuery(
    getOrganizationsTemplatesByUser,
    {
      fetchPolicy: 'network-only',
      skip: !teamId,
      variables: {
        teamId,
        templateType: 'clause',
      },
    }
  );

  const allTemplates = useMemo(() => {
    if (isDocumentTemplatesDataLoading || isUserOrgsTemplatesDataLoading || !documentTemplatesData) {
      return [];
    }

    const userTemplatesIds = documentTemplatesData?.getDocumentTemplates.map((template: any) => template.id);
    const organizationTemplates =
      userOrgsTemplatesData?.organizationsTemplatesByUser
        .filter((orgTemplate: any) => !userTemplatesIds?.includes(orgTemplate.id))
        .map((orgTemplate: any) => {
          return { ...orgTemplate, fromOrganization: true };
        }) ?? [];

    return [...(documentTemplatesData?.getDocumentTemplates ?? []), ...organizationTemplates];
  }, [isDocumentTemplatesDataLoading, documentTemplatesData, isUserOrgsTemplatesDataLoading, userOrgsTemplatesData]);

  const allCreators = useMemo(() => {
    if (!allTemplates) {
      return [];
    }

    const sortedCreatorsData = allTemplates
      .map((template: any) => (template?.team?.id !== teamId ? template?.team?.name : ''))
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index && !!value)
      .sort((a: any, b: any) => a.localeCompare(b));

    const userTeam = allTemplates.find((template: any) => template?.team?.id === teamId);

    if (userTeam) {
      sortedCreatorsData.unshift(`${userTeam.team?.name} (Your Team)`);
    }

    return sortedCreatorsData;
  }, [allTemplates, teamId]);

  useEffect(() => {
    setFilterCreators(new Set(allCreators));
  }, [allCreators]);

  const clauseIsUpdated = useCallback(() => {
    setShowAddTemplate(false);
    refetch();
  }, [refetch]);

  const onAddClauseModalHide = () => {
    setShowAddTemplate(false);
    setSelectedTemplateType(null);
  };

  const onAddClauseShowModal = (templateType: TemplateType) => {
    setShowAddTemplate(true);
    setSelectedTemplateType(templateType);
  };

  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage id="title.deals.title" defaultMessage="Clause Name" />,
        accessor: 'name',
        Cell: ({ cell: { value } }: ColumnsCellType) => {
          return (
            <div className={styles.headerTitleTableColumn}>
              <FormattedMessage id="cta.go-to.details" defaultMessage={value} />
            </div>
          );
        },
      },
      {
        Header: <FormattedMessage id="title.deals.template-creator" defaultMessage="Creator" />,
        accessor: 'templateCreator',
      },
      {
        Header: <FormattedMessage id="title.deals.clause-status" defaultMessage="Status" />,
        accessor: 'status',
        Cell: ({
          cell: {
            row: {
              values: { status },
            },
          },
        }: ColumnsCellType) => {
          return (
            <span className={styles.templateStatus}>
              <FormattedMessage
                id="phrase.clause-status-readout"
                defaultMessage={status}
                values={{ status, permissions }}
              />
            </span>
          );
        },
      },
      {
        Header: <FormattedMessage id="title.clauses.last-updated" defaultMessage="Last Updated" />,
        accessor: 'updatedAt',
        sortType: (a: RowTable<{ values: { updatedAt: string } }>, b: RowTable<{ values: { updatedAt: string } }>) => {
          return new Date(b.values.updatedAt).getTime() - new Date(a.values.updatedAt).getTime();
        },
        Cell: ({ cell: { value } }: any) => {
          return <span className="text-nowrap">{value && dayjs(value).format('LL')}</span>;
        },
      },
      {
        Header: (
          <span className="sr-only">
            <FormattedMessage id="title.deals.detailsLink" defaultMessage="Link to Deal Details" />
          </span>
        ),
        accessor: 'template',
        Cell: ({
          cell: {
            row: {
              values: { status, template, templateType },
            },
          },
        }: ColumnsCellType) => {
          const selectedClausesData = documentTemplatesData!.getDocumentTemplates.find(
            (fetchedClause: any) => fetchedClause.id === template.id
          );

          return (
            <div
              onClick={(evt: MouseEvent) => {
                evt.stopPropagation();
              }}
              className={styles.actionsTableColumn}
            >
              {!template.fromOrganization ? (
                <Controls
                  status={status || ''}
                  editPermission={isAdmin || canEdit}
                  selectedTemplateData={selectedClausesData}
                  teamId={teamId}
                  onSuccess={clauseIsUpdated}
                  templateType={TemplateType.CLAUSE}
                />
              ) : (
                <div className={styles.templatesTooltipContainer}>
                  <Tooltip placement="bottom" testId="organization-template-tooltip">
                    This Clause is available due to your active membership in {template.organizationName}, it cannot be
                    edited.
                  </Tooltip>
                </div>
              )}
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [clauseIsUpdated, canEdit, isAdmin, documentTemplatesData, teamId, permissions]
  );

  const clauseActions = {
    buttonText: 'Add New Clause',
    text: AddTemplateActionStatus.CLAUSE,
    onClick: () => canCreateClauses
      ? onAddClauseShowModal(TemplateType.CLAUSE)
      : setSubscriptionModalActive(true),
    isAvailable: true,
  };

  const onClauseDetail = (clause: any) => {
    const templateUrl = fillPlaceholders(
      !clause.fromOrganization ? paths.templates.document : paths.templates.orgTemplate,
      {
        templateId: clause.id,
        teamId,
      }
    );

    navigate(templateUrl);
  };

  const teamClausesData = useMemo(() => {
    const filteredData =
      allTemplates
        .filter((clause: any) => {
          const templateType = clause.templateType;

          const hasClauseActualStatus = filterStatuses.has(clause.status);
          const hasClauseActualType = filterTemplateTypes.has(templateType);
          if (!filterCreators.has(clause.team?.name) && !filterCreators.has(`${clause.team?.name} (Your Team)`)) {
            return false;
          }

          // Search text filter, document type and status filters
          return !(
            (filterText && !fuzzyInputSearch({ filteredText: filterText, searchValue: clause.name })) ||
            !hasClauseActualStatus ||
            !hasClauseActualType
          );
        })
        .map((clause: any) => {
          const templateType = clause.templateType;

          return {
            template: {
              id: clause.id,
              fromOrganization: clause.fromOrganization,
              organizationName: clause.team?.organization?.name,
            },
            name: formatMessage({
              id: 'format.name',
              defaultMessage: clause.name,
            }),
            status: clause.status,
            updatedAt: clause.updatedAt,
            templateType: templateType,
            templateCreator: clause.team?.name || '',

            onRowClick: () => {
              const templateUrl = fillPlaceholders(
                !clause.fromOrganization ? paths.templates.document : paths.templates.orgTemplate,
                {
                  templateId: clause.id,
                  teamId,
                }
              );

              navigate(templateUrl);
            },
          };
        }) ?? [];

    filteredData.sort((a: any, b: any) => {
      switch (sorting) {
        case 'ascName':
          return a.name.localeCompare(b.name);
        case 'descName':
          return b.name.localeCompare(a.name);
        case 'statusActive':
          return a.status.localeCompare(b.status);
        case 'statusArchived':
          return b.status.localeCompare(a.status);
        case 'dateToLatest':
          return b?.updatedAt?.localeCompare(a.updatedAt);
        case 'dateToNewest':
          return a?.updatedAt?.localeCompare(b.updatedAt);

        default:
          return a?.updatedAt && b?.updatedAt && b.updatedAt.localeCompare(a.updatedAt);
      }
    });

    return filteredData;
  }, [
    allTemplates,
    formatMessage,
    filterText,
    filterStatuses,
    filterTemplateTypes,
    filterCreators,
    allCreators.length,
    teamId,
    navigate,
    sorting,
  ]);

  const sortBy = useMemo(() => {
    switch (sorting) {
      case 'ascName':
        return {
          id: 'name',
          desc: false,
        };
      case 'descName':
        return {
          id: 'name',
          desc: true,
        };
      case 'statusActive':
        return {
          id: 'status',
          desc: false,
        };
      case 'statusArchived':
        return {
          id: 'status',
          desc: true,
        };
      case 'dateToLatest':
        return {
          id: 'updatedAt',
          desc: false,
        };
      case 'dateToNewest':
        return {
          id: 'updatedAt',
          desc: true,
        };

      default:
        return {
          id: 'updatedAt',
          desc: false,
        };
    }
  }, [sorting]);

  const table = useTable(
    {
      data: teamClausesData,
      //@ts-ignore
      columns,
      initialState: {
        sortBy: [sortBy],
      },
    },
    useSortBy,
    usePagination
  );

  if (isDocumentTemplatesDataLoading || isUserOrgsTemplatesDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet title={formatMessage({ id: 'title.clauses', defaultMessage: 'Clauses' })} />
      {(!hasAccessToClauses || isSubscriptionModalActive) && (
        <>
          <SubscriptionRequiredModal
            isOpen
            onClose={() => {
              setSubscriptionModalActive(false);
            }}
          />
        </>
      )}
      <ContentHeader>
        <Row>
          <Col lg={6} className={'ml-3 mr-3'}>
            <div className={styles.title} data-testid="deals-header" role={'heading'}>
              <FormattedMessage id="title.clauses" defaultMessage="Clauses" />
            </div>
          </Col>
        </Row>
      </ContentHeader>

      <ContentBody>
        <Row className="mb-3">
          <Col className={'ml-3 mr-3 d-flex justify-content-between non-mobile-flex'}>
            <div className={'d-flex flex-fill'}>
              <SearchInput
                className="mr-3"
                value={filterText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'deals.filter.title.placeholder',
                  defaultMessage: 'Search clause name',
                })}
                aria-label={formatMessage({
                  id: 'deals.filter.title.label',
                  defaultMessage: 'Name',
                })}
              />

              <div className={classNames(styles.filterDropdownContainer, 'mr-3')}>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-filter-status" as={DropdownToggle}>
                    <FormattedMessage
                      id="deals.filter.status.label-readout"
                      defaultMessage={`{filterCount, select,
                      0 {Status (any)}
                      1 {Status ({status, select,
                        active {Active}
                        archived {Archived}
                        other {{status}}
                      })}
                      other {{filterCount} statuses}
                    }`}
                      values={{
                        filterCount: filterStatuses.size === statusOptions.length ? 0 : filterStatuses.size,
                        status: filterStatuses.size ? filterStatuses.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropdownItem highlightable>
                      <Checkbox
                        checked={filterStatuses.size === statusOptions.length}
                        indeterminate={filterStatuses.size > 0 && filterStatuses.size < statusOptions.length}
                        id="dropdown-filter-statuses-_all"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.currentTarget;
                          if (checkbox.checked) {
                            setFilterStatuses(new Set(Object.keys(templateStatusMessages)));
                          } else {
                            setFilterStatuses(new Set());
                          }
                        }}
                        label={formatMessage({
                          id: 'deals.filter.status.all-statuses',
                          defaultMessage: 'Any status',
                        })}
                      />
                    </DropdownItem>
                    <DropdownBody>
                      {statusOptions.map(({ value: status, label }) => (
                        <DropdownItem key={status} highlightable>
                          <Checkbox
                            key={status}
                            value={status}
                            checked={filterStatuses.has(status)}
                            id={`dropdown-filter-statuses-${slugify(status)}`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterStatuses);
                              if (checkbox.checked) newSet.add(checkbox.value);
                              else newSet.delete(checkbox.value);
                              setFilterStatuses(newSet);
                            }}
                            label={<FormattedMessage {...label} />}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className={classNames(styles.filterDropdownContainer, 'mr-3')}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-filter-creator"
                    data-testid="dropdown-filter-creator"
                    as={DropdownToggle}
                    /*// @ts-ignore */
                    className="w-100 overflow-hidden"
                  >
                    <FormattedMessage
                      id="deals.filter.creator.label-readout"
                      defaultMessage={`{filterCount, select,
                        0 {Creator (all)}
                        1 {Creator ({creator})}
                        other {{filterCount} Teams}
                      }`}
                      values={{
                        filterCount: filterCreators.size === allCreators.length ? 0 : filterCreators.size,
                        creator: filterCreators.size ? filterCreators.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropdownItem highlightable>
                      <Checkbox
                        checked={filterCreators.size === allCreators.length}
                        indeterminate={filterCreators.size > 0 && filterCreators.size < allCreators.length}
                        id="dropdown-filter-creators-_all"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.currentTarget;
                          if (checkbox.checked) {
                            setFilterCreators(new Set(allCreators));
                          } else {
                            setFilterCreators(new Set());
                          }
                        }}
                        label={formatMessage({
                          id: 'deals.filter.creator.all-creators',
                          defaultMessage: 'All Creators',
                        })}
                      />
                    </DropdownItem>
                    <DropdownBody>
                      {allCreators.map((creator: any) => (
                        <DropdownItem key={creator} highlightable>
                          <Checkbox
                            key={creator}
                            value={creator}
                            checked={filterCreators.has(creator)}
                            id={`dropdown-filter-cities-${slugify(creator)}`}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterCreators);
                              if (checkbox.checked) newSet.add(checkbox.value);
                              else newSet.delete(checkbox.value);
                              setFilterCreators(newSet);
                            }}
                            label={creator}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {isTemplateEditable && (
              <>
                <div className={styles.addTemplateActionsContainer__dropdown}>
                  <Button data-testid="createClause" variant="secondary" onClick={clauseActions.onClick}>
                    <TitleFormattedMessage id="cta.create-clause" defaultMessage={clauseActions.buttonText} />
                  </Button>
                </div>
                <Modal
                  show={showAddTemplate}
                  onHide={onAddClauseModalHide}
                  size="sm"
                  className={styles.templateModalContainer}
                >
                  <div className={styles.templateModal}>
                    <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
                      <Modal.Title>
                        <header className={styles.templateModalHeader}>
                          <FormattedMessage
                            id="title.add-team-member"
                            data-testid="createClauseModalHeader"
                            defaultMessage={`Create ${AddTemplateActionStatus.CLAUSE}`}
                          />
                        </header>
                      </Modal.Title>
                    </Modal.Header>
                    <AddNewTemplateFormik
                      bodyContainer={Modal.Body}
                      actionsContainer={Modal.Footer}
                      renderSecondaryAction={() => (
                        <Button variant="link" className="text-secondary" onClick={onAddClauseModalHide}>
                          <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
                        </Button>
                      )}
                      onSuccess={clauseIsUpdated}
                      templateFormEditMode={false}
                      templateType={selectedTemplateType}
                    />
                  </div>
                </Modal>
                <Modal show={showEditClause} onHide={() => setShowEditClause(false)} size="sm">
                  <div className={styles.templateModal}>
                    <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
                      <Modal.Title>
                        <header>
                          <FormattedMessage id="title.add-team-member" defaultMessage="Edit Clause" />
                        </header>
                      </Modal.Title>
                    </Modal.Header>
                  </div>
                </Modal>
              </>
            )}
          </Col>
          <Col className={'mx-3 d-flex flex-column justify-content-between mobile-flex'}>
            {isTemplateEditable && (
              <Button variant="secondary" onClick={clauseActions.onClick} className="mobileHeaderBtn">
                <TitleFormattedMessage id="cta.create-clause" defaultMessage={clauseActions.buttonText} />
              </Button>
            )}
            <div className={'d-flex flex-fill mt-3'}>
              <SearchInput
                iconClassName="mobileSearch__icon"
                inputClassName="mobileSearch"
                className="mobileSearch__container"
                value={filterText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'deals.filter.title.placeholder',
                  defaultMessage: 'Search clause name',
                })}
                aria-label={formatMessage({
                  id: 'deals.filter.title.label',
                  defaultMessage: 'Name',
                })}
              />

              <Button className="filterBtn" onClick={() => setShowFilter(true)} data-testid="mobileFilterBtn">
                <FilterIcon width={24} height={24} />
              </Button>
            </div>
          </Col>
        </Row>

        <Row className={styles.contentTable}>
          <Col className={'mr-3 ml-3'}>
            <div>
              {teamClausesData.length ? (
                <>
                  <div className="non-mobile">
                    <Table striped isStickyHeader isActionColumn table={table} />
                  </div>
                  <div className="mobile">
                    <MobileTable>
                      {teamClausesData?.map((clause: any) => (
                        <MobileTemplateItem
                          key={`mobile-clause-${clause.template.id}`}
                          template={clause}
                          isAdmin={isAdmin}
                          canEdit={canEdit}
                          teamId={teamId}
                          selectedTemplate={documentTemplatesData?.getDocumentTemplates?.find(
                            tmp => tmp.id === clause.template.id
                          )}
                          templateIsUpdated={clauseIsUpdated}
                          onClick={() => onClauseDetail(clause.template)}
                        />
                      ))}
                    </MobileTable>
                  </div>
                </>
              ) : (
                <Container className="mt-5">
                  <div className="lead text-center">
                    <h2>
                      <FormattedMessage id="title.empty-clauses" defaultMessage="No Clauses" />
                    </h2>
                    <FormattedMessage
                      id="phrase.templates.no-templates"
                      defaultMessage="Your team's clauses will appear here."
                    />
                  </div>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </ContentBody>
      <FileUploadModal
        onClose={() => dispatch(setActiveModal(null))}
        isOpen={activeModal === ModalActionsEnum.UPLOAD_FILE}
        teamId={teamId}
        templateUpload
      />
      <FilterSidebar
        isOpen={showFilter}
        filterStatuses={filterStatuses}
        filterCreators={filterCreators}
        onClose={() => setShowFilter(!showFilter)}
        setSorting={setSorting}
        setFilterStatuses={setFilterStatuses}
        setFilterCreators={setFilterCreators}
        allCreators={allCreators}
      />
    </>
  );
};

export default ClausesLandingPage;
