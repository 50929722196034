import { ApolloClient, gql } from '@apollo/client';
import { getDocumentById } from '../../api/queries_v2';

type ResponsePayloadType = {
  id: string | number;
  additionalMessage?: string;
};

export const sendDocumentToReceiver = (documentId: string | number, additionalMessage?: string) => (
  client: ApolloClient<object>
): Promise<ResponsePayloadType> => {
  const variables = {
    documentId,
    additionalMessage,
  };

  const mutation = gql`
    mutation sendDocumentToReceiver($documentId: ID!, $additionalMessage: String) {
      sendDocumentToReceiver(documentId: $documentId, additionalMessage: $additionalMessage, confirm: true) {
        id
      }
    }
  `;

  const refetchQueries = [
    {
      query: getDocumentById,
      variables: {
        documentId,
      },
    },
  ];

  return client.mutate({ mutation, variables, refetchQueries }).then(({ data }: Record<any, any>) => {
    return data.sendDocumentToReceiver;
  });
};
