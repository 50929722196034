import { ChangeEvent } from 'react';
import { Checkbox, RadioField } from '../../../../components/checkbox-or-radio-field';
import { FormattedMessage, useIntl } from 'react-intl';
import slugify from '../../../../utils/slugify';
import { dealStatusMessages, statusOptions } from '../../constants';

interface StatusFormProps {
  filtered: Set<string>;
  setFilterStatuses: (value: Set<string>) => void;
}

export const StatusForm: React.FC<StatusFormProps> = ({ filtered, setFilterStatuses }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Checkbox
        checked={filtered.size === statusOptions.length}
        indeterminate={filtered.size > 0 && filtered.size < statusOptions.length}
        id="mobile-filter-statuses-_all"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const checkbox = event.currentTarget;
          if (checkbox.checked) {
            setFilterStatuses(new Set(Object.keys(dealStatusMessages)));
          } else {
            setFilterStatuses(new Set());
          }
        }}
        label={formatMessage({
          id: 'deals.filter.status.all-statuses',
          defaultMessage: 'All Statuses',
        })}
      />
      {statusOptions.map(({ value: status, label }) => (
        <Checkbox
          key={status}
          value={status}
          checked={filtered.has(status)}
          id={`mobile-filter-statuses-${slugify(status)}`}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const checkbox = event.currentTarget;
            const newSet = new Set(filtered);
            if (checkbox.checked) newSet.add(checkbox.value);
            else newSet.delete(checkbox.value);
            setFilterStatuses(newSet);
          }}
          label={<FormattedMessage {...label} />}
        />
      ))}
    </>
  );
};
