import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useTeamInfo } from '../../../team-id-context';
import { useDealId } from '../../../deal-id-context';
import LoadingSpinner from '../../../components/loading-spinner';
import CreateBlankDocumentModal from './CreateBlankDocumentModal';
import CreateDocumentInputFormik from '../../../forms/CreateDocumentInputForm';

type CreateDocumentModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateBlankDocumentModalContainer = ({ isOpen, onClose }: CreateDocumentModalComponentProps) => {
  const [creatingDocument, setCreatingDocument] = React.useState(false);
  const { teamId } = useTeamInfo();
  const dealId = useDealId();

  if (creatingDocument) {
    return <LoadingSpinner />;
  }

  const isCreatingLeaseDraft = () => {
    setCreatingDocument(true);
  };

  const onModalClose = () => {
    onClose();
  };

  const modalFilters = () => {
    return (
      <div>
        <div>
          <div className="text-muted font-weight-bold form-label">
            <FormattedMessage id="lease.template.creator" defaultMessage="Document Name" />
          </div>
          <CreateDocumentInputFormik
            dealId={dealId}
            teamId={teamId}
            isCreatingLeaseDraft={isCreatingLeaseDraft}
            onClose={onClose}
          ></CreateDocumentInputFormik>
        </div>
      </div>
    );
  };

  return <CreateBlankDocumentModal isOpen={isOpen} onClose={onModalClose} modalFilters={modalFilters()} />;
};

export default CreateBlankDocumentModalContainer;
