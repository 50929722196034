import React, { ChangeEvent, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { filterMembersBy } from '../../../../../../forms/helpers';
import { DropdownItem } from '../../../../../dropdown';
import HighlightMatches from '../../../../../highlight-matches';
import { Checkbox } from '../../../../../checkbox-or-radio-field';
import SearchInput from '../../../../../search-input';
import styles from '../../../../../../components/selectDeals/styles.module.scss';

const TeamMembersDropdown = forwardRef(
  (
    {
      children,
      dropdownId,
      onDealChange,
      dealChecked,
      dealValue,
      style,
      className,
      onClick,
      checkboxComponent: CheckboxComponent = Checkbox,
    }: any,
    ref: any
  ) => {
    const [query, setQuery] = useState('');

    const renderDealCheckboxes = () => (
      <>
        {children
          .filter((teamMember: any) => filterMembersBy(teamMember, query.trim()))
          .map((teamMember: any) => {
            const { fullName } = teamMember;

            return (
              <DropdownItem key={teamMember.id} highlightable as="li">
                <CheckboxComponent
                  className="w-100"
                  type="checkbox"
                  id={`${dropdownId}.select-deal.${teamMember.id}`}
                  key={teamMember.id}
                  value={dealValue(teamMember)}
                  checked={dealChecked(teamMember)}
                  onChange={(e: any) => onDealChange(e, teamMember)}
                  label={
                    //@ts-ignore
                    <HighlightMatches query={query.trim()}>{fullName}</HighlightMatches>
                  }
                />
              </DropdownItem>
            );
          })}
      </>
    );

    return (
      <div ref={ref} style={style} className={className} onClick={onClick}>
        <div className="p-2 d-flex bg-light align-items-center">
          <SearchInput
            autoFocus
            className="ml-3 w-auto"
            //@ts-ignore
            style={{ flex: 'auto' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value)}
            value={query}
            id={`${dropdownId}-query`}
            placeholder="Search Existing Team Member"
          />
        </div>
        <ul className={classNames(styles.resultsList, 'list-unstyled m-0')}>{renderDealCheckboxes()}</ul>
      </div>
    );
  }
);

export default TeamMembersDropdown;
