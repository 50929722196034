import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import {
  GlobalModalActionsEnum,
  ModalActionDescription,
  ModalActionsEnum,
} from '../../../../document-table/DocumentTableConstants';
import { LeaseDocumentWizardCTAType } from '../../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import { LeaseDocumentWizardModal } from '../../LeaseDocumentWizardModal';
import { LeaseLoadingSpinner } from '../../../lease-loading-spinner/LeaseLoadingSpinner';
import LoadingSpinner from '../../../../loading-spinner';
import InviteReceivingTeamFormik from '../../../../../forms/InviteReceivingTeamFormContainer';
import { confirmButtonTitle } from '../../../lease-editor/utils/defineConfirmButtonTitle';
import { Warning } from '../../../../shared/warning';
import { OptionalMessageInputRef } from './OptionalMessageInputRef';

interface LeaseDocumentWizardActionModalProps {
  show: boolean;
  onHide: () => void;
  handler: () => void;
  isDealShared?: boolean;
  isLoading?: boolean;
  isPossessionPending?: boolean;
  title?: string;
  activeModal?: GlobalModalActionsEnum | null;
  children?: React.ReactNode;
  suggestionsAlert?: {
    text: string;
    title: string;
  } | null;
  optionalMessageInputRef?: any;
  teamId: string;
  showCounterparty?: boolean;
  counterpartyName?: string;
  counterpartyEmail?: string;
}

export function LeaseDocumentWizardActionModal(props: LeaseDocumentWizardActionModalProps) {
  const isActiveSendDraftModal = props.activeModal === ModalActionsEnum.SEND;
  const isDealShared = props.isDealShared;
  const isActiveSendForSigningModal = props.activeModal === ModalActionsEnum.SEND_FOR_SIGNING;
  const isActiveTerminateModal = props.activeModal === ModalActionsEnum.TERMINATE;
  const isGrantAccessModalActive = props.activeModal === ModalActionsEnum.GRANT_ACCESS;

  const showCounterparty = props.showCounterparty || false;
  const validCounterparty = showCounterparty && props.counterpartyEmail && props.counterpartyName;

  const footerCTAs: Array<LeaseDocumentWizardCTAType> = [
    {
      onClick: props.onHide,
      label: <FormattedMessage id="lease-document-wizard-preview-send-offer.cta.cancel" defaultMessage="Cancel" />,
      theme: 'outline-secondary',
      disabled: false,
    },
    {
      onClick: props.handler,
      label: (
        <>
          <FormattedMessage
            id="lease-document-wizard-preview-send-offer.cta.send-to-tenant"
            defaultMessage={confirmButtonTitle(props.activeModal)}
          />
          {props.isPossessionPending && <LeaseLoadingSpinner />}
          {props.isLoading && <LoadingSpinner />}
        </>
      ),
      theme: 'secondary',
      disabled: props.isPossessionPending || props.isLoading,
    },
  ];

  const cancelButtonHandler = footerCTAs[0];
  const sendDocumentButtonHandler = footerCTAs[1]?.onClick;

  return (
    <LeaseDocumentWizardModal
      size="lg"
      show={props.show}
      onHide={props.onHide}
      title={
        !(isActiveSendDraftModal || isActiveSendForSigningModal) ? (
          <FormattedMessage id="lease-document-wizard-modal.lease-terminate-title" defaultMessage={props.title} />
        ) : (
          props.title
        )
      }
      titleClassName="text-left"
      modalCTAs={isDealShared || isActiveTerminateModal ? footerCTAs : null}
    >
      {isActiveSendDraftModal && validCounterparty && (
        <div className='modal-disclaimer'>
          <strong>Counterparty:</strong>&nbsp;{props.counterpartyName} ({props.counterpartyEmail})
        </div>
      )}
      {props.suggestionsAlert && <Warning text={props.suggestionsAlert.text} title={props.suggestionsAlert.title} />}
      {(isActiveSendDraftModal || isActiveSendForSigningModal) && isDealShared && (
        <FormattedMessage
          id="lease-document-wizard-preview-send-offer.description"
          defaultMessage={
            isActiveSendForSigningModal ? ModalActionDescription.SEND_FOR_SIGNING : ModalActionDescription.SEND
          }
        />
      )}

      {isGrantAccessModalActive ? (
        isDealShared && (
          <FormattedMessage
            id="lease-document-wizard-preview-send-offer.description"
            defaultMessage={ModalActionDescription.GRANT_ACCESS}
          />
        )
      ) : (
        <section>{props.children}</section>
      )}

      <div>
        {(isActiveSendDraftModal || isActiveSendForSigningModal || isGrantAccessModalActive) && !isDealShared ? (
          <InviteReceivingTeamFormik
            shouldAccessBeGranted={isGrantAccessModalActive}
            sendDocumentToReceiverHandler={sendDocumentButtonHandler}
            renderSecondaryAction={() => (
              <Button
                className={classNames(
                  `btn btn-${cancelButtonHandler?.theme}`,
                  'lease-document-wizard-cta-stack__button'
                )}
                onClick={cancelButtonHandler.onClick}
              >
                {cancelButtonHandler.label}
              </Button>
            )}
            // @ts-ignore */
            bodyContainer={Modal.Body}
            // @ts-ignore */
            actionsContainer={Modal.Footer}
            teamId={props.teamId}
          ></InviteReceivingTeamFormik>
        ) : (
          <OptionalMessageInputRef ref={props.optionalMessageInputRef} />
        )}
      </div>
    </LeaseDocumentWizardModal>
  );
}

LeaseDocumentWizardActionModal.defaultProps = {
  handler: () => {},
};
