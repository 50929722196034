export class UsersIntegration {
  constructor(editor, user, userList) {
    this.editor = editor;
    this.user = user;
    this.userList = userList;
  }
  init() {
    const usersPlugin = this.editor.plugins.get('Users');
    // clear initial users
    usersPlugin.users.clear();
    // load the users data
    for (const user of this.userList) {
      usersPlugin.addUser(user);
    }
    // set current user
    if (!usersPlugin.me) {
      usersPlugin.defineMe(this.user.id);
    }
  }
}
