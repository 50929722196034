import { 
  ToggleManageDealParticipantsCollapsedAction, 
  ToggleSubNavCollapsedAction,
  ToggleSidenavPlaceholdersCollapsedAction ,
  SetActiveSidenavPlaceholderAction
} from './navigation.actions';

export type NavigationStateType = {
  subNavCollapsed: boolean;
  manageDealParticipantsCollapsed: boolean;
  sidenavPlaceholdersCollapsed: boolean;
  activeSidenavPlaceholder: string | null;
};

export const initialState: NavigationStateType = {
  subNavCollapsed: false,
  manageDealParticipantsCollapsed: true,
  sidenavPlaceholdersCollapsed: true,
  activeSidenavPlaceholder: null,
};

const reducer = (
  state: NavigationStateType = initialState,
  action: ToggleSubNavCollapsedAction | ToggleManageDealParticipantsCollapsedAction | ToggleSidenavPlaceholdersCollapsedAction | SetActiveSidenavPlaceholderAction,
): NavigationStateType => {
  switch (action.type) {
    case 'toggleSubNavCollapsed':
      return {
        ...state,
        subNavCollapsed: action.payload,
      };

    case 'toggleManageDealParticipantsCollapsed':
      return {
        ...state,
        manageDealParticipantsCollapsed: action.payload,
      };

    case 'toggleSidenavPlaceholdersCollapsed':
      return {
        ...state,
        sidenavPlaceholdersCollapsed: action.payload,
      };
    case 'setActiveSidenavPlaceholder':
      return {
        ...state,
        activeSidenavPlaceholder: action.activeUuid,
      };

    default:
      return state;
  }
};

export { reducer as navigationReducer };
