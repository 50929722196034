import { SetDocumentApprovalRequestAction } from '../actions/lease-document.actions';
import { LeaseDocumentStateType } from '../lease-document.reducer';

export const handleSetDocumentApprovalRequest = (
  state: LeaseDocumentStateType,
  action: SetDocumentApprovalRequestAction
): LeaseDocumentStateType => ({
  ...state,
  approvalRequest: action.approvalRequest,
});
