import React, { useEffect } from 'react';
import { get as getLatestTeamId } from '../utils/latest-team-id';
import { paths, fillPlaceholders, useNavigate } from '../routing';
import { useAuth } from '../auth';
import LoadingSpinner from '../components/loading-spinner';
interface LandingPageProps {
  token?: string;
}

const Landing = ({ token }: LandingPageProps) => {
  const [{ authLoading, queryLoading, loggedIn, user }] = useAuth();
  const navigate = useNavigate();
  const loading = authLoading || queryLoading;

  useEffect(() => {
    if (!loading && !loggedIn) {
      return navigate(paths.auth.login);
    }
  }, [loading, loggedIn, navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const goToTeamDeals = (id: string) => {
    setTimeout(() => {
      const urlToNavigate = token
        ? fillPlaceholders(paths.deals.landingWithToken, { teamId: id, dealToken: token })
        : fillPlaceholders(paths.deals.landing, { teamId: id });

      navigate(urlToNavigate);
    }, 0);
  };

  const latestTeamId = getLatestTeamId(user?.id);
  const userTeams = user?.allTeams || [];

  if (latestTeamId && userTeams.some(({ id }: { id: string }) => id === latestTeamId)) {
    goToTeamDeals(latestTeamId);
    return null;
  }

  if (userTeams.length) {
    goToTeamDeals(userTeams[0].id);
    return null;
  } else if (loggedIn) {
    navigate(paths.noTeam);
  }

  return null;
};

export default Landing;
