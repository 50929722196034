import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LeaseDocumentWizardModal } from '../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import styles from './styles.module.scss';
interface SendDocumentForSigningProps {
  onHide: () => void;
  show: boolean;
  onConfirm: () => void;
  isLoading: boolean;
  additionalMessageInputRef: React.Ref<HTMLTextAreaElement>;
}

const SendDocumentForSigning = ({
  onHide,
  show,
  onConfirm,
  isLoading,
  additionalMessageInputRef,
}: SendDocumentForSigningProps) => {
  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onHide,
      label: 'Cancel',
      theme: 'outline-secondary',
    },
    {
      onClick: onConfirm,
      label: 'Send',
      theme: 'secondary',
      disabled: isLoading,
      isLoading: isLoading,
      className: styles['send-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={styles.title}
      size="lg"
      show={show}
      onHide={onHide}
      title={<FormattedMessage id="lease-document-wizard-modal.send-for-signing" defaultMessage="Send for Signing" />}
      modalCTAs={modalCTAs}
    >
      <div className={styles.main}>
        Access for all users will become read-only when this document is sent for signing. All signer emails provided
        will be notified to review and sign the document.
      </div>
    </LeaseDocumentWizardModal>
  );
};

export default SendDocumentForSigning;
