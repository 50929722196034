import { ApolloClient } from '@apollo/client';
import { Revision } from '../../ckeditor/revisionHistoryAdapter/types';
import { graphql } from '../../../gql';

const query = graphql(/* GraphQL */`
  query GetLeaseTemplateRevisionsByDocumentId($offerId: ID!) {
    getDocumentTemplateById(documentTemplateId: $offerId) {
      id
      documentRevisions {
        attributes
        revisionId
        authorsIds
        toVersion
        fromVersion
        createdAt
        updatedAt
        name
      }
    }
  }
`);

export const getLeaseTemplateRevisions = (documentId: string) => (
  client: ApolloClient<object>
): Promise<any> => {
  const variables = { offerId: documentId };

  return client.query({ query, variables }).then(({ data }: Record<any, any>) => {
    const revisionsResponse = data.getDocumentTemplateById.documentRevisions;

    return revisionsResponse.map((revision: any) => {
      const { revisionId } = revision;

      return {
        id: revisionId,
        ...revision,
      } as Revision;
    });
  });
};
