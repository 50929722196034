import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';
import { CommentInputType } from "../../../gql/graphql";

export type CreateTemplateComment = {
  templateId: string;
  comment: CommentInputType;
};

const mutation = graphql(/* GraphQL */`
  mutation CreateTemplateComment($templateId: ID!, $comment: CommentInputType!) {
    createTemplateComment(templateId: $templateId, comment: $comment) {
      ...DocumentCommentFields
    }
  }
`);

export const createTemplateComment = (options: CreateTemplateComment) => (
  client: ApolloClient<object>
): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const createTemplateCommentWithResult = (options: CreateTemplateComment) => (
  client: ApolloClient<object>
): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.createTemplateComment);
  });
};
