import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';
import { LeaseDocumentWizardHeaderContainerProps } from './lease-document-wizard-header.types';
import { LeaseDocumentWizardHeader } from './LeaseDocumentWizardHeader';
import {
  selectDocumentApprovalRequest,
  selectDocumentSessionActivities,
  selectDocumentSigners,
  selectIsDocumentEditable,
  selectIsDocumentTitleEditable,
  selectIsLeaseEditorLoaded,
  selectLeaseDocumentEversignPDFUrl,
  selectLeaseDocumentStatus,
  selectLeaseDocumentTitle,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import { useDocumentRole } from '../../../shared/hooks/useDocumentRole/useDocumentRole';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import { downloadPdfHelper } from '../../document-table/helpers/downloadPdfHelper';
import classNames from 'classnames';
import LeaseDocumentWizardHeaderDownloadSignedPDFButton from './lease-document-wizard-header-download-signed-PDF-button/LeaseDocumentWizardHeaderDownloadSignedPDFButton';
import { SendToExternalSigning } from './components/headerActions/sendToExternalSigning';
import { SendSigningReminder } from './components/headerActions/sendSigningReminder';
import { CancelExternalSigning } from './components/headerActions/cancelExternalSigning';
import { CancelPreparingToSign } from './components/headerActions/cancelPreparingToSign';
import {
  DocumentSourceEnum,
  givePossessionSuccessMessage,
  successSendDocumentMessage,
} from '../lease-editor/constants/EditorConstats';
import RequestReviewContainer from './components/headerActions/requestReview/RequestReviewContainer';
import { queriesV2 } from '../../../api';
import { LeaseLoadingSpinner } from '../lease-loading-spinner/LeaseLoadingSpinner';
import {
  ApprovalRequestStatus,
  ApprovalRequestThreshold,
} from './components/headerActions/requestReview/RequestInternalApprovalModalConstants';
import { useAuth } from '../../../auth';
import HandleInternalApprovalActionsContainer from './components/headerActions/requestReview/HandleInternalApprovalActions';
import { InternalApprovalBadgeContainer } from './components/headerActions/requestReview/InternalApprovalBadgeContainer';
import { SigningProcessBadgeContainer } from './components/headerActions/sendToPreparingToSignInternal/signingProcessBadgeContainer';
import { useLeaseDocumentTeamPermissions } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { givePossessionToCounterparty } from '../../../utils/api/give-possession-to-counterparty';
import { saveDocumentVersionOnPossessionPass } from './lease-document-wizard-header.utils';
import { resetSessionActivities } from '../../../store/lease-document/actions/lease-document.actions';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routing';
import { selectCurrentDeal } from '../../../store/deals/deals.selectors';
import { useNotifications } from '../../../notifications';
import { useIntl } from 'react-intl';

export const LeaseDocumentWizardHeaderContainer = ({
  onClose,
  title,
  titleBadge,
  canEdit,
  permissions,
  onSaveTitle,
  lastSaved,
  canShare,
  documentId,
  onSave,
  isDocumentLocked = false,
  dealId,
  teamId,
  documentSource = DocumentSourceEnum.APP,
  canRequestInternalApproval,
}: LeaseDocumentWizardHeaderContainerProps) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();
  const { formatMessage } = useIntl();
  const [{ user }] = useAuth();
  const { isDocumentCreator, hasCurrentTeamPossession, isDocumentRoleLoading } = useDocumentRole(documentId);
  const isEditorLoadedState = useSelector(selectIsLeaseEditorLoaded);
  const approvalRequest = useSelector(selectDocumentApprovalRequest);
  const signers = useSelector(selectDocumentSigners);
  const isDocumentEditable = useSelector(selectIsDocumentEditable);
  const sessionActivities = useSelector(selectDocumentSessionActivities);
  const isDocumentTitleEditable = useSelector(selectIsDocumentTitleEditable);
  const isEditorLoaded = documentSource === DocumentSourceEnum.APP ? isEditorLoadedState : true;
  const eversignPdfUrl = useSelector(selectLeaseDocumentEversignPDFUrl);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const documentTitle = useSelector(selectLeaseDocumentTitle) || 'Document';
  const hasEditPermission = !!permissions?.hasEditPermission;
  const isDraft = documentStatus === DocumentStatusEnum.DRAFT;
  const isSigning = documentStatus === DocumentStatusEnum.SIGNING;
  const isReviewing = documentStatus === DocumentStatusEnum.REVIEWING;
  const isUploaded = documentStatus === DocumentStatusEnum.UPLOADED;
  const isExecuted = documentStatus === DocumentStatusEnum.EXECUTED;
  const isShared = documentStatus === DocumentStatusEnum.SHARED;
  const canBeEditorModeChanged = isDraft || isReviewing;
  const currentDeal = useSelector(selectCurrentDeal);
  const isDealShared = !!currentDeal?.receiverEmail;

  const isPreparingToSign = documentStatus === DocumentStatusEnum.PREPARING_TO_SIGN;
  const shouldShowCancelPreparingToSign =
    isPreparingToSign && isDocumentCreator && hasEditPermission && !isDocumentLocked;
  const shouldShowSendToExternalSigning =
    isPreparingToSign && isDocumentCreator && hasEditPermission && !isDocumentLocked;
  const shouldShowInternalSendForSigning =
    isDocumentCreator &&
    hasEditPermission &&
    (isDraft || isReviewing || isUploaded || isShared) &&
    hasCurrentTeamPossession &&
    isEditorLoaded;
  const { hasInviteSendingPermission } = useLeaseDocumentTeamPermissions({ teamId, apolloClient });
  const dealUrl = paths.deals.detail.replace(':teamId', teamId as string).replace(':dealId', dealId);
  const { possessionTeamName, userRole } = useDocumentRole(documentId);

  const handleSendDocumentMutation = useCallback(async () => {
    const isAppDocument = documentSource === DocumentSourceEnum.APP;

    if (isAppDocument) {
      onSave && (await onSave());
      await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName });
    }

    const additionalMessage = '';

    givePossessionToCounterparty(
      documentId,
      additionalMessage,
      sessionActivities
    )(apolloClient)
      .then(() => {
        dispatch(resetSessionActivities());
        navigate(dealUrl);
        isDealShared &&
          notifySuccess({
            message: formatMessage(
              documentStatus === DocumentStatusEnum.DRAFT ? successSendDocumentMessage : givePossessionSuccessMessage
            ),
          });
      })
      .catch(error => {
        if (isAppDocument) {
          saveDocumentVersionOnPossessionPass({ success: false });
        }

        notifyError({ message: error.message });
      });
  }, [
    apolloClient,
    dealUrl,
    documentId,
    documentSource,
    documentStatus,
    formatMessage,
    onSave,
    notifyError,
    notifySuccess,
    possessionTeamName,
    givePossessionToCounterparty,
    isDealShared,
    sessionActivities,
    dispatch,
    navigate,
  ]);

  // const pendingSamePartySuggestions = useCallback(() => {
  //   if (window.editor?.state === 'destroyed') return;
  //
  //   const suggestions =
  //     window.editor &&
  //     window.editor.plugins
  //       .get('TrackChanges')
  //       .getSuggestions()
  //       .filter((item: any) => item.isInContent);
  //
  //   console.log(suggestions)
  //
  //   const counterpartySuggestions = suggestions?.filter((suggestion: any) => {
  //     return suggestion.attributes.authorRole && suggestion.attributes.authorRole !== userRole;
  //   });
  //
  //   return counterpartySuggestions?.length;
  // }, [userRole]);

  // console.log(pendingSamePartySuggestions())

  const shouldShowSignButton = () => {
    return !isDocumentCreator && hasEditPermission && isPreparingToSign;
  };

  const shouldShowDownloadPDFButton = isExecuted;

  const isApprovalRequestPending = approvalRequest?.status === ApprovalRequestStatus.PENDING;
  const isApprovalRequestApproved = approvalRequest?.status === ApprovalRequestStatus.APPROVED;

  const activeReview = approvalRequest?.reviews
    .filter((request: any) => {
      return !request.approvedAt && !request.rejectedAt;
    })
    .find((review: { reviewer: { id: string | number } }) => review?.reviewer?.id === user.id);

  const activeReviewId = activeReview?.id;

  const { loading: dealTeamsQueryLoading, data: dealTeamsData } = useQuery(queriesV2.getDealTeamsByTeamId, {
    variables: {
      dealId,
    },
  });

  const { loading: documentTeamsAdminInfoLoading, data: documentTeamsAdminData  } = useQuery(queriesV2.getDocumentById, {
    variables: {
      documentId,
    },
  });


  const shouldShowCancelSigningButton = () => {
    if (!isDocumentCreator) return false;

    return hasEditPermission && isDocumentCreator && isSigning;
  };

  const shouldShowSendReminderButton = () => {
    return shouldShowCancelSigningButton() && signers?.filter(signer => !signer?.signedAt)?.length;
  };

  if (isDocumentRoleLoading || dealTeamsQueryLoading) {
    return <LeaseLoadingSpinner />;
  }

  const shouldShowDocumentActionsForReceiver = !isDocumentCreator && hasCurrentTeamPossession && isReviewing;
  const shouldShowSendDocumentButton = hasCurrentTeamPossession && isDocumentCreator && canShare;

  const handleDownloadSignedPDF = () => {
    downloadPdfHelper(eversignPdfUrl, documentTitle);
  };
  const shouldShowSendButton =
    isEditorLoaded &&
    (shouldShowSendDocumentButton || (shouldShowDocumentActionsForReceiver && canShare)) &&
    (!isApprovalRequestApproved || (isApprovalRequestApproved && !isDocumentEditable));

  const shouldShowRequestInternalApprovalButton =
    isEditorLoaded && canRequestInternalApproval && hasCurrentTeamPossession;

  const shouldShowSendForSigning = isEditorLoaded && shouldShowDocumentActionsForReceiver && canEdit;

  const getHeaderActions = () => (
    <>
      {(shouldShowSendButton || shouldShowRequestInternalApprovalButton || shouldShowInternalSendForSigning) && (
        <RequestReviewContainer
          dealTeamsData={dealTeamsData}
          documentTeamsAdminData={documentTeamsAdminData}
          documentId={documentId}
          dealId={dealId}
          handleSaveDocument={onSave}
          canEdit={canEdit}
          canShare={canShare}
          teamId={teamId}
          isDocumentEditable={isDocumentEditable}
          isDocumentInDraft={isDraft || isUploaded || isShared}
          isDocumentCreator={isDocumentCreator}
          isDocumentShared={isShared}
          shouldShowInternalSendForSigning={!!shouldShowInternalSendForSigning}
          shouldShowSendForSigning={!!shouldShowSendForSigning}
        />
      )}
      {shouldShowSendReminderButton() && (
        <SendSigningReminder
          dealId={dealId}
          teamId={teamId}
          className={classNames({
            'lease-document-wizard-header__edit-button_margin': shouldShowCancelSigningButton() && shouldShowSignButton,
          })}
        />
      )}
      {shouldShowCancelSigningButton() && (
        <CancelExternalSigning
          dealId={dealId}
          teamId={teamId}
          className={classNames({
            'lease-document-wizard-header__edit-button_margin': shouldShowCancelSigningButton() && shouldShowSignButton,
          })}
        />
      )}
      {shouldShowDownloadPDFButton && (
        <LeaseDocumentWizardHeaderDownloadSignedPDFButton onClick={handleDownloadSignedPDF} />
      )}
      {shouldShowCancelPreparingToSign && <CancelPreparingToSign />}
      {shouldShowSendToExternalSigning && (
        <SendToExternalSigning documentId={documentId} dealId={dealId} teamId={teamId} />
      )}
    </>
  );

  const internalApprovalBadge = () => (
    <InternalApprovalBadgeContainer
      documentId={documentId}
      dealId={dealId}
      requestId={approvalRequest!.id}
      teamId={teamId}
      hasUserEditPermission={hasEditPermission}
      approvalRequestedReviews={approvalRequest?.reviews}
      isOneConfirmationRequired={approvalRequest?.threshold === ApprovalRequestThreshold.ONE}
    />
  );

  const signingProcessBadge = () => <SigningProcessBadgeContainer signers={signers} />;

  const getHeaderActionsForDocumentWithPendingInternalApproval = () => {
    return (
      <>
        {activeReviewId && (
          <HandleInternalApprovalActionsContainer
            dealTeamsData={dealTeamsData}
            documentId={documentId}
            dealId={dealId}
            reviewId={activeReviewId}
            teamId={teamId}
          />
        )}
      </>
    );
  };

  return (
    <LeaseDocumentWizardHeader
      shouldShowEditorModeSwitcherActive={canBeEditorModeChanged}
      isUploaded={isUploaded}
      onClose={onClose}
      title={title}
      titleBadge={isApprovalRequestPending ? internalApprovalBadge() : isSigning ? signingProcessBadge() : titleBadge}
      onSaveTitle={onSaveTitle}
      canEdit={canEdit && isDocumentTitleEditable}
      hasUserEditPermission={hasEditPermission}
      lastSaved={lastSaved}
      isReviewing={isReviewing}
      hasInviteSendingPermission={hasInviteSendingPermission}
      dealId={dealId}
      teamId={teamId}
      documentId={documentId}
      actions={isApprovalRequestPending ? getHeaderActionsForDocumentWithPendingInternalApproval() : getHeaderActions()}
      dealTeamsData={dealTeamsData}
      onSave={onSave}
      canShare={canShare}
      shouldShowInternalSendForSigning={shouldShowInternalSendForSigning}
      shouldShowSendForSigning={shouldShowSendForSigning}
    />
  );
};
