import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { graphql } from '../../../gql';

enum DocumentUserRoles {
  CREATOR = 'creator',
  RECEIVER = 'receiver',
}

export const GET_DOCUMENT_ROLE_BY_DOCUMENT_ID = graphql(/* GraphQL */`
  query GetDocumentRoleByDocumentId($documentId: ID!) {
    getDocumentById(id: $documentId) {
      id
      currentUserRole
      currentTeamPossession
      possessionTeam {
        id
        name
      }
    }
  }
`);

export const useDocumentRole = (documentId: string | null) => {
  const { data: documentRoleData, loading: isDocumentRoleLoading, error: documentRoleError } = useQuery(
    GET_DOCUMENT_ROLE_BY_DOCUMENT_ID,
    {
      variables: { documentId: documentId! },
      skip: !documentId,
    }
  );

  return useMemo(
    () => ({
      isDocumentCreator: documentRoleData
        ? documentRoleData.getDocumentById.currentUserRole === DocumentUserRoles.CREATOR
        : false,
      hasCurrentTeamPossession: documentRoleData ? documentRoleData.getDocumentById.currentTeamPossession : false,
      possessionTeamName: documentRoleData && documentRoleData.getDocumentById.possessionTeam?.name,
      isDocumentRoleLoading,
      documentRoleError,
      userRole: documentRoleData ? (documentRoleData.getDocumentById.currentUserRole as DocumentUserRoles) : null,
    }),
    [documentRoleData, documentRoleError, isDocumentRoleLoading]
  );
};
