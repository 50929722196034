import React, { ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import { Dropdown } from 'react-bootstrap';
import { queries, mutations, queriesV2 } from '../../api';
import { useNotifications } from '../../notifications';
import { useTeamInfo } from '../../team-id-context';
import Toggle from './Toggle';
import TeamMembersDropdown from './TeamMembersDropdown';
import styles from './style.module.scss';

interface SelectTeamMembersProps {
  dealId: string;
  teamMembers: any[];
  dealMembers: any[];
  viewPermission: { id: string };
}

const SelectTeamMembers = ({ dealId, teamMembers = [], dealMembers, viewPermission }: SelectTeamMembersProps) => {
  const { teamId, adminUser } = useTeamInfo();
  const [inviteNewTeamMemberToDeal] = useMutation(mutations.inviteNewTeamMembersByEmail);
  const [removeTeamMemberFromDeal] = useMutation(mutations.removeTeamMemberFromDeal);
  const [, { error: notifyError }] = useNotifications();

  const teamMembersIds = teamMembers?.map(member => member?.user.id);
  const dealMembersIds = dealMembers.filter(({ id }) => teamMembersIds?.includes(id)).map(({ id }) => id);

  return (
    <Dropdown>
      <Toggle id={`dropdown-select-deals-t${teamId}-u${dealId}`} selectedCount={dealMembersIds.length} />

      <Dropdown.Menu
        className={styles.teamMembersDropdown}
        as={TeamMembersDropdown}
        dropdownId={`dropdown-select-teamMembers-t${teamId}-u${dealId}`}
        memberDisabled={(team: { user: { id: string } }) => {
          const isAdminUser = adminUser.id === team.user.id;
          return isAdminUser;
        }}
        dealChecked={(team: { user: { id: string } }) => {
          return dealMembersIds?.includes(team.user.id);
        }}
        dealValue={(team: { user: { id: string } }) => team.user.id}
        onDealChange={async (event: ChangeEvent<HTMLInputElement>) => {
          const checkbox = event.currentTarget;
          const userId = event.currentTarget.value;
          const refetchQueries = [
            {
              query: queries.getDealsForTeam,
              variables: {
                teamId,
              },
            },
            {
              query: queries.getDealForTeam,
              variables: {
                dealId,
              },
            },
            {
              query: queriesV2.getDealTeamsByTeamId,
              variables: {
                dealId,
              },
            },
          ];

          try {
            if (checkbox.checked) {
              const email = teamMembers
                .filter(member => member.user.id === userId)
                .map(filteredMember => filteredMember.user.email)[0]
              const userPermissions = [{
                email,
                permissionIds: [viewPermission.id]
              }]
              await inviteNewTeamMemberToDeal({
                variables: {
                  teamId,
                  userId,
                  userPermissions,
                  defaultDeals: [dealId],
                  emails: [email]
                },
                refetchQueries,
              });
            } else {
              await removeTeamMemberFromDeal({
                variables: {
                  userId,
                  dealId,
                },
                refetchQueries,
              });
            }
          } catch (error) {
            notifyError({ message: error });
          }
        }}
      >
        {teamMembers}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectTeamMembers;
