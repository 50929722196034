import React, { useState } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as ChevronUpIcon } from '../../../../../shared/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../../shared/icons/chevron-down.svg';
import { Button } from 'react-bootstrap';
import Tooltip from '../../../../tooltip';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PlaceholdersIcon } from '../../../../../shared/icons/placeholders.svg'; // Add this import statement
import { InsertedFieldsTooltip } from '../../../../shared/insertFieldsTooltip';

interface PlaceholderItemProps {
  id: string;
  uuid: string;
  title?: string | null;
  isRequired: boolean;
  isActive?: boolean;
  onClick: (uuid: string) => void;
}

const PlaceholderItem: React.FC<PlaceholderItemProps> = ({ id, uuid, title, isRequired, isActive = false, onClick }) => {
  const tooltipTitle = title || "Placeholder";

  const tooltipChildren = <span title={title || ''}>{title}</span>

  return (
    <div key={id} className={`${styles.placeholderItemContainer} ${isActive ? styles.placeholderItemContainer__active : ''}`} onClick={() => onClick(uuid)}>
      <div>
        <div className={styles.placeholderItemContainer__title}>
          <InsertedFieldsTooltip title={tooltipTitle} children={tooltipChildren} placement="bottom" />
        </div>
      </div>
      
      <div className={styles.placeholderItemContainer__requirement}>{isRequired ? 'Required' : 'Optional'}</div>
    </div>
  );
};

export default PlaceholderItem;