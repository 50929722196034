import React, { useContext } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../../../../shared/icons/info-icon.svg';
import { ReactComponent as AlertDangerIcon } from '../../../../../shared/icons/alert-danger-icon.svg';
import ErrorMessage from '../../../../error-message';
import EditPermissionsList from '../../../../editPermissionsList';
import { RemoveTeamMemberButton } from '../../../removeTeamMemberButton';
import { graphqlErrorsToUiErrors } from '../../../../../api';
import { SelectDeals } from '../../../../selectDeals';
import { A, paths } from '../../../../../routing';
import { TeamIdContext } from '../../../../../team-id-context';
import { useAuth } from '../../../../../auth';
import { useDealId } from '../../../../../deal-id-context';
import { TitleFormattedMessage } from '../../../../titleFormattedMessage';

interface UserEntry {
  original: {
    permissions: any[];
    id: any;
    isAdmin: boolean;
    invitationId: string | null;
    isWhitelisted: boolean;
    dealIds: any;
    email: string;
  };
}

export interface TeamMemberRowProps {
  handleResendInvitation: (invitationId: string, email: string) => void;
  handleCancelInvitation: (invitationId: string, email: string) => void;
  userRow: UserEntry;
  setUserToRemove: (user: any) => void;
  setUserToRemoveFromDeal: (user: any) => void;
  shouldShowRemoveTeamMemberFromTeamButton: boolean;
  shouldShowRemoveTeamMemberFromDealButton: boolean;
  shouldShowDealsSelector: boolean;
  isTableReadOnly: boolean;
  dealsData: any;
  dealsGraphqlError: any;
  whitelistPermissionId: string;
}

const TeamMemberRow = ({
  handleResendInvitation,
  handleCancelInvitation,
  userRow,
  setUserToRemove,
  setUserToRemoveFromDeal,
  shouldShowRemoveTeamMemberFromTeamButton,
  shouldShowRemoveTeamMemberFromDealButton,
  shouldShowDealsSelector,
  isTableReadOnly,
  dealsData,
  dealsGraphqlError,
  whitelistPermissionId,
}: TeamMemberRowProps) => {
  const { original: userRowData } = userRow;
  const [{ user }] = useAuth();

  const isRowUserCurrent = user.id === userRowData.id;
  const isUserAdminOrHasInvitation = userRowData.isAdmin || !!userRowData.invitationId;
  const teamId = useContext(TeamIdContext);
  const dealId = useDealId();

  const isInvitation = !!userRowData.invitationId;

  const handleRemoveTeamMemberFromTeam = () => {
    setUserToRemove(userRowData);
  };

  const onCancelInvitationClick = () => userRowData.invitationId && handleCancelInvitation(userRowData.invitationId, userRowData.email);

  const handleRemoveTeamMemberFromDeal = () => {
    setUserToRemoveFromDeal(userRowData);
  };

  const onResendInvitationClick = () => userRowData.invitationId &&  handleResendInvitation(userRowData.invitationId, userRowData.email);

  const renderInvitationLayout = () => (
    <Alert variant="warning">
      <h6 className="mb-1" data-testid="invite-actions-header">
        <InfoIcon aria-hidden="true" className="mr-2 text-danger" />
        <FormattedMessage id="title.invitation-pending" defaultMessage="Invitation pending" />
      </h6>
      <p className="mb-0">
        <FormattedMessage
          id="phrase.waiting-on-team-member-invitation-acceptance"
          defaultMessage="Waiting on team member to accept their invitation."
        />
        <Button data-testid="resend-invite-button" variant="link" onClick={onResendInvitationClick}>
          <TitleFormattedMessage id="cta.resend-invitation" defaultMessage="Resend invitation" />
        </Button>
        <Button data-testid="cancel-invite-button" variant="link" onClick={onCancelInvitationClick}>
          <TitleFormattedMessage id="cta.cancel-invitation" defaultMessage="Cancel invitation" />
        </Button>
      </p>
    </Alert>
  );

  const renderMemberLayout = () => dealId && (
    <Alert variant="warning">
      <h6 className="mb-1">
        <AlertDangerIcon width={20} height={20} aria-hidden="true" className="mr-2 text-danger" />
        <FormattedMessage id="title.member-section" defaultMessage="Changes made to the user permissions applies to all deals that they have access to." />
      </h6>
    </Alert>
  )

  const renderDealsSelector = () => (
    <>
      <h3 data-testid="deals-select-header">
        <FormattedMessage id="title.deals" defaultMessage="Deals" />
      </h3>
      <p>
        <FormattedMessage
          id="phrase.select-team-member-deals"
          defaultMessage="Select the deals this team member can work on."
        />
      </p>
      {dealsData ? (
        <SelectDeals
          dealsForTeam={dealsData.dealsForTeam}
          readOnly={isUserAdminOrHasInvitation}
          whitelistPermissionId={whitelistPermissionId}
          userRowData={userRowData}
        />
      ) : (
        // @ts-ignore
        <ErrorMessage>{graphqlErrorsToUiErrors(dealsGraphqlError)._general}</ErrorMessage>
      )}
    </>
  );

  const renderMessage = () => {
    if (isTableReadOnly)
      return (
        <FormattedMessage
          id="phrase.view-permissions-adjust-later"
          defaultMessage="These are your team member’s current permissions. You can adjust these later in your {team_settings}."
          values={{
            team_settings: (
              <A href={paths.team} hrefParams={{ teamId }}>
                <FormattedMessage id="team_settings" defaultMessage="team settings" />
              </A>
            ),
          }}
        />
      );

    return (
      <FormattedMessage
        id="phrase.view-or-change-team-members-permissions"
        defaultMessage="View or change this team member’s permissions"
      />
    );
  };

  return (
    <div className="pl-5 pt-3 pb-3 pr-5">
      {isInvitation ? renderInvitationLayout() : renderMemberLayout()}
      <h3 data-testid="team-member-row_header">
        <FormattedMessage id="title.permissions" defaultMessage="Permissions" />
      </h3>
      <p>{renderMessage()}</p>
      <EditPermissionsList
        data-testid="edit-permissions-list"
        permissions={userRowData.permissions}
        dealIds={userRowData.dealIds}
        userId={userRowData.id}
        readOnly={isTableReadOnly || userRowData.isAdmin || isRowUserCurrent}
        isAdminUser={userRowData.isAdmin}
        invitationId={userRowData.invitationId}
        isYou={user.id === userRowData.id}
      />
      {shouldShowDealsSelector && renderDealsSelector()}
      {shouldShowRemoveTeamMemberFromTeamButton && (
        <RemoveTeamMemberButton data-testid="remove-team-member-button" onClick={handleRemoveTeamMemberFromTeam} shouldBeRemovedFromTeam />
      )}
      {shouldShowRemoveTeamMemberFromDealButton
        && <RemoveTeamMemberButton data-testid="remove-team-member-from-team-button" onClick={handleRemoveTeamMemberFromDeal} />}
    </div>
  );
};

export default TeamMemberRow;
