import ReactDOMServer from 'react-dom/server';
import React, { ReactElement } from 'react';

export const generateBase64StringFromSVGComponent = (
  Component: (...args: any[]) => ReactElement,
  componentProps?: { [key: string]: any }
) => {
  return (
    'data:image/svg+xml;base64,' +
    btoa(ReactDOMServer.renderToStaticMarkup(React.createElement(Component, componentProps)))
  );
};
