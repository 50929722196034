import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import { usePartyIds } from '../api';
import { RegisterInvitedTeamMemberData, useAuth } from '../auth';
import { useNotifications } from '../notifications';
import { paths, useNavigate } from '../routing';

import ErrorMessage from '../components/error-message';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import { Header as TermsHeader, Body as TermsBody } from '../components/terms-and-conditions';

import { api as apiErrorMessages } from '../error-messages';
import LoadingSpinner from '../components/loading-spinner';
import { TitleFormattedMessage } from '../components/titleFormattedMessage';
import styles from './styles.module.scss';

interface RegisterFormProps {
  errors: { [key: string]: string };
  isSubmitting?: boolean;
  isValid?: boolean;
  setFieldValue: (fieldName: string, fieldValue: string | boolean) => void;
}

const RegisterInvitedTeamMemberForm = ({
  errors,
  isSubmitting,
  isValid,
  setFieldValue,
}: RegisterFormProps) => {
  const { formatMessage } = useIntl();

  // Control the terms&conditions modal
  const [showTerms, setShowTerms] = useState(false);

  return (
    <Form noValidate>
      {errors._general && (
        <Alert variant="danger">
          <ErrorMessage>{errors._general}</ErrorMessage>
        </Alert>
      )}

      <TextField
        id="registerForm.firstName"
        name="firstName"
        type="text"
        label={<FormattedMessage id="form.first-name.label" defaultMessage="First name" />}
        autoComplete="name"
      />

      <TextField
        id="registerForm.lastName"
        name="lastName"
        type="text"
        label={<FormattedMessage id="form.last-name.label" defaultMessage="Last name" />}
        autoComplete="name"
      />

      <div className="text-center mb-2">
        <SubmitButton
          label={
            <FormattedMessage id="cta.register" defaultMessage={'Continue'} />
          }
          disabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
          data-testid="form-submit-button"
          className={styles.inviteContinueButton}
        />
      </div>

      <div className="text-center">By clicking continue you agree to our <Button variant="link" className="p-0 align-baseline"
                                                         onClick={() => setShowTerms(true)}>
        {<a>Terms &amp; Conditions</a>}
      </Button>
      </div>

      <Modal scrollable show={showTerms} onHide={() => setShowTerms(false)} size="lg">
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title>
            <TermsHeader />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsBody />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-secondary" onClick={() => setShowTerms(false)}>
            <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShowTerms(false);
              setFieldValue('acceptTerms', true);
            }}
          >
            <FormattedMessage id="cta.accept" defaultMessage="Accept" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  token: yup.string().required(),
});

interface RegisterFormikProps {
  token: string;
  invitation: any;
}

const RegisterInvitedTeamMemberFormik = ({ token, invitation }: RegisterFormikProps) => {
  const [, { registerInvitedTeamMember }] = useAuth();
  const navigate = useNavigate();
  const [, { warning: notifyWarning }] = useNotifications();
  const { loading: partyIdsLoading } = usePartyIds();

  const onSubmit = async (values: RegisterInvitedTeamMemberData, actions: any) => {
    const { token, firstName, lastName } = values
    const { success, errors } = await registerInvitedTeamMember({
      firstName,
      lastName,
      token
    })
    if (success) {
      const redirectTo = '/' + invitation?.onboarding?.redirectTo || paths.home;
      navigate(redirectTo);
    } else {
      if (errors.id === apiErrorMessages.existingUser.id) {
        // Duplicate email address; notify user and navigate to login page
        navigate(paths.auth.login);
        notifyWarning({
        message: {
            id: 'phrase.register.email-exists-login',
            defaultMessage:
            'This email address is already associated with an account. Log in, or reset your password if you forgot it.',
        },
        });
      } else {
        actions.setErrors(errors);
      }
    }
    };

  if (partyIdsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        token
      }}
    >
      {formikProps => (
        // @ts-ignore
        <RegisterInvitedTeamMemberForm
          {...formikProps}
        />
      )}
    </Formik>
  );
};

export default RegisterInvitedTeamMemberFormik;
