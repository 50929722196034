import { createSelector } from 'reselect';
import { RootReducer } from '../store.types';
import { NavigationStateType } from './navigation.reducer';

export const selectNavigationRoot = (state: RootReducer): NavigationStateType => state.navigation;

export const selectSubNavCollapsed = createSelector(selectNavigationRoot, state => state.subNavCollapsed);

export const selectManageDealParticipantsCollapsed = createSelector(
  selectNavigationRoot,
  state => state.manageDealParticipantsCollapsed
);

export const selectSidenavPlaceholdersCollapsed = createSelector(
  selectNavigationRoot,
  state => state.sidenavPlaceholdersCollapsed
);

export const selectActiveSidenavPlaceholder = createSelector(
  selectNavigationRoot,
  state => state.activeSidenavPlaceholder
);
