import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils';

import { token } from '../auth';

const URL = `${process.env.REACT_APP_API}/graphql`;
const httpLink = createHttpLink({ uri: URL });

const authLink = setContext(async (_, { headers: existingHeaders }) => {
  const headers = {
    ...existingHeaders,
    Accept: 'application/json',
  };

  if (token.get()) {
    headers.Authorization = `Bearer ${token.get()}`;
  }

  return { headers };
});

const errorHandler = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // This method must return void or an Observable. If a Promise is returned
  // (like if we declare this to be async) it will expect it to resolve to
  // something Apollo-specific.

  if (networkError) {
    // Catch cases where the user's auth token has been revoked, and the refresh
    // has failed; our only option is to get them to log in again
    if ((networkError as ServerError).statusCode === 401) {
      // This client.__authMethods object comes from a hack in app.tsx,
      // documented there
      // @ts-ignore
      if (client.__authMethods) {
        // @ts-ignore
        client.__authMethods.refreshToken().then(({ success }: { success: boolean }) => {
          if (success) {
            forward(operation);
            return;
          }
          // @ts-ignore
          client.__authMethods.logout({ skipApi: true });
        });
        return;
      } else {
        console.warn(
          "Got a 401 on GraphQL query. Wanted to refresh token or log user out but don't (yet) have access to the auth methods. Ignoring."
        );
      }
      return;
    }
    console.error(`[Network error]: ${networkError}`); // May need to handle these differently, just report them for now.
  }

  // From the Apollo docs:
  // GraphQL Errors: errors in the GraphQL results that can appear alongside successful data
  // Server Errors: server internal errors that prevent a successful response from being formed
  // Transaction Errors: errors inside transaction actions like update on mutations
  // UI Errors: errors that occur in your component code
  // Apollo Client Errors: internal errors within the core or corresponding libraries
  if (graphQLErrors) {
    for (const { message, locations, path } of graphQLErrors) {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    }
  }
});

const client = new ApolloClient({
  link: errorHandler.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  connectToDevTools: true,
});

export default client;
