import React, { useCallback } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useTeamPermissions } from '../api';
import { DocumentSourceEnum } from '../components/lease-wizard/lease-editor/constants/EditorConstats';
import { useNotifications } from '../notifications';
import { DocumentActionType, InviteReceivingTeamFormikProps, PermissionProps } from '../consts/forms-types';
import { SendDealErrorMessage } from '../consts/forms-constants';
import InviteReceivingTeamForm from './InviteReceivingTeamForm';
import { useLeaseDocumentWizardSuccessNotifier } from '../pages/lease-document-wizard/shared/hooks/use-lease-document-wizard-success-notifier/use-lease-document-wizard-success-notifier.hook';
import { sendDealToReceiver } from '../utils/api/send-deal-to-receiver/send-deal-to-receiver';
import { Permissions } from '../shared/constants/permissions';
import { selectCurrentDeal, selectDealId } from '../store/deals/deals.selectors';
import { setDeal } from '../store/deals/deals.actions';
import { selectDocumentSource, selectLeaseDocumentId, } from '../store/lease-document/selectors/lease-document.selectors';
import { fillPlaceholders, paths } from '../routing';
import { useLeaseDocumentSave } from '../pages/lease-document-wizard/shared/hooks/use-lease-document-save/use-lease-document-save.hook';
import { updateDocument } from '../utils/api/update-lease-document-offer/update-document';
import { saveDocumentVersionOnPossessionPass } from '../components/lease-wizard/lease-document-wizard-header/lease-document-wizard-header.utils';
import { useDocumentRole } from '../shared/hooks/useDocumentRole/useDocumentRole';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  additionalMessage: yup.string(),
});

const InviteReceivingTeamFormik = ({
  bodyContainer,
  actionsContainer,
  renderSecondaryAction,
  sendDocumentToReceiverHandler,
  onSuccess,
  shouldAccessBeGranted,
  teamId,
  sendWithDocumentId = true,
  shareDocument = true,
  refreshCounterpartyData,

}: InviteReceivingTeamFormikProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dealId = useSelector(selectDealId);
  const documentId = useSelector(selectLeaseDocumentId) as string;
  const currentDeal = useSelector(selectCurrentDeal);
  const documentSource = useSelector(selectDocumentSource);
  const isDocumentUploaded = documentSource === DocumentSourceEnum.IMPORTED;
  const { possessionTeamName } = useDocumentRole(documentId);
  const { teamPermissions } = useTeamPermissions();
  const isEditorReady = window.editor?.state === 'ready';
  //@ts-ignore TODO useNotifications to ts
  const [, { error: notifyError }] = useNotifications();
  const apolloClient = useApolloClient();
  const notifySuccess = useLeaseDocumentWizardSuccessNotifier();
  const shouldDocumentBeShared = !!sendDocumentToReceiverHandler;
  const defaultPermissions = [];
  const viewPermissionId = teamPermissions?.find(({ key }: PermissionProps) => key === Permissions.View)?.id;
  const whitelistPermissionId = teamPermissions?.find(({ key }: PermissionProps) => key === Permissions.Whitelist)?.id;

  const handleSaveError = useCallback(() => {
    notifyError(
      intl.formatMessage({
        id: 'shared-offer-page.save-error',
        defaultMessage: 'Unable to save the Document',
      })
    );
  }, [notifyError, intl]);

  const handleSave = useLeaseDocumentSave({
    id: documentId,
    save: updateDocument,
    onError: handleSaveError,
    apolloClient,
  });

  const handleSubmitAsync = async (values: any) => {
    if (documentId && isEditorReady && !shouldAccessBeGranted) {
      await handleSave;
      shouldDocumentBeShared && await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName, sentForExternalReview: true });
      await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName });
    }

    const successShareDeal = intl.formatMessage({
      id: 'phrase.invitation_share.success',
      defaultMessage: `Success! Your deal has been shared with ${values.email}`,
    });

    const successMessageDealAndDocumentShare = intl.formatMessage({
      id: 'phrase.invitation_and_document_share.success',
      defaultMessage: 'Success! Your deal has been shared and the document has been sent for counterparty review.',
    });

    const successMessageDealAndDocumentGrantAccess = intl.formatMessage({
      id: 'phrase.invitation_send_and_document_grant_access.success',
      defaultMessage: 'Success! Your deal and document have been shared.',
    });

    const sendDealVariables = {
      dealId,
      receiverEmail: values.email,
      additionalMessage: values.additionalMessage,
      documentAction: shouldAccessBeGranted ? DocumentActionType.SHARE : DocumentActionType.REVIEW,
    };

    setTimeout(async () => {
      sendDealToReceiver(
        shouldAccessBeGranted
        || (isDocumentUploaded && documentId && sendWithDocumentId)
        || (documentId && isEditorReady && sendWithDocumentId)
          ? { ...sendDealVariables, documentId }
          : sendDealVariables
      )(apolloClient)
        .then(async data => {
          if (currentDeal) {
            const updatedDeal = { ...currentDeal, receiverTeam: data, receiverEmail: values.email };
            dispatch(setDeal(updatedDeal));
          }

          (documentId && shareDocument) &&
          (await notifySuccess(
            shouldAccessBeGranted ? successMessageDealAndDocumentGrantAccess : successMessageDealAndDocumentShare
          ));

          (!documentId || !shareDocument) && (await notifySuccess(successShareDeal));

          if (teamId && sendWithDocumentId) {
            const dealDeatilsUrl = fillPlaceholders(paths.deals.detail, {
              teamId,
              dealId,
            });
            navigate(dealDeatilsUrl);
          }
          await onSuccess?.();
        })
        .catch(error => {
          saveDocumentVersionOnPossessionPass({ success: false });
          notifyError({ id: 'deal.send-deal.error', message: error.message || SendDealErrorMessage.SEND_DEAL });
        });
    }, shouldDocumentBeShared ? 2000 : 0);
    refreshCounterpartyData();
  };

  const onSubmit = (values: any) => {
    const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

    if (!documentPendingActionsPlugin?.hasAny || !documentId) {
      return handleSubmitAsync(values);
    }

    documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
      if (!documentPendingActionsPlugin.hasAny) {
        event.off();
        event.stop();
        return handleSubmitAsync(values);
      }
    });
  };

  if (viewPermissionId) defaultPermissions.push(viewPermissionId);
  if (whitelistPermissionId) defaultPermissions.push(whitelistPermissionId);

  return (
    <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{ email: '' }}>
      {formikProps => (
        <InviteReceivingTeamForm
          {...formikProps}
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
          shouldDocumentBeShared={shouldDocumentBeShared}
          shouldAccessBeGranted={shouldAccessBeGranted}
        />
      )}
    </Formik>
  );
};

export default InviteReceivingTeamFormik;
