import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { ContentHeader } from '../../components/private-layout';
import {
  EXPIRY_DATE_CHANGE_MESSAGE,
  HandleRequestNotification,
  OrgatizationMembership,
  OrgatizationMembershipType,
  RequestStatuses,
  USER_PAID_SUBSCRIPTION_MESSAGE,
} from './constants';
import { Table } from '../../components/table';
import { useNotifications } from '../../notifications';
import SearchInput from '../../components/search-input';
import { DropdownBody, DropdownItem, DropdownToggle } from '../../components/dropdown';
import { Checkbox } from '../../components/checkbox-or-radio-field';
import slugify from '../../utils/slugify';
import { useTeamInfo } from '../../team-id-context';
import { getOrganizationByTeam } from '../../api/queries';
import LoadingSpinner from '../../components/loading-spinner';
import { mutations } from '../../api';
import { ReactComponent as ApproveIcon } from '../../shared/icons/approve-icon.svg';
import { ReactComponent as RejectIcon } from '../../shared/icons/remove-icon.svg';
import { ReactComponent as EditIcon } from '../../shared/icons/edit.svg';
import { useLeaseDocumentTeamPermissions } from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import Tooltip from '../../components/tooltip';
import EditExpiryDateModal from './modals/EditExpiryDateModal';
import { ContentBody } from '../../components/private-layout/ContentBody';
import styles from './styles.module.scss';

const defaultTypeOptions = new Set<string>(['team', 'user']);
const defaultTemplateStatusOptions = new Set<string>([RequestStatuses.ACTIVE, RequestStatuses.PENDING]);

const requestTypeMessages = {
  team: { id: 'request-type.agreement', defaultMessage: OrgatizationMembership.team },
  user: { id: 'request-type.clause', defaultMessage: OrgatizationMembership.user },
};

const requestStatusMessages = {
  approved: { id: 'request-status.active', defaultMessage: 'Active' },
  pending: { id: 'request-status.pending', defaultMessage: 'Pending' },
  rejected: { id: 'request-status.rejected', defaultMessage: 'Rejected' },
  expired: { id: 'request-status.expired', defaultMessage: 'Expired' },
  paid: { id: 'request-status.paid', defaultMessage: 'Paid' },
};

const requestTypeOptions = [
  { value: 'team', label: requestTypeMessages.team },
  { value: 'user', label: requestTypeMessages.user },
];

const statusOptions = [
  { value: RequestStatuses.ACTIVE, label: requestStatusMessages.approved },
  { value: RequestStatuses.PENDING, label: requestStatusMessages.pending },
  { value: RequestStatuses.REJECTED, label: requestStatusMessages.rejected },
  { value: RequestStatuses.EXPIRED, label: requestStatusMessages.expired },
  { value: RequestStatuses.PAID, label: requestStatusMessages.paid },
];

const ExpiryCell = ({
  value,
  status,
  organizationRequestId,
}: {
  value: string;
  organizationRequestId: string;
  status: string;
}) => {
  const [isEdit, setEdit] = useState(false);

  return (
    <div className="d-flex justify-content-between">
      {isEdit && (
        <EditExpiryDateModal
          showModal={isEdit}
          onModalHide={() => setEdit(false)}
          date={value}
          organizationRequestId={organizationRequestId}
        />
      )}
      <span>{value && dayjs(value).format('ll')}</span>
      {status !== RequestStatuses.PAID && (
        <Button variant="light" onClick={() => setEdit(true)} className={classNames(styles['edit-btn'], 'p-0')}>
          <EditIcon />
        </Button>
      )}
    </div>
  );
};

const Requests = () => {
  const { formatMessage } = useIntl();
  const [, { success: notifySuccess = () => {}, error: notifyError }] = useNotifications();
  const [filterText, setFilterText] = useState('');
  const { teamId } = useTeamInfo();
  const apolloClient = useApolloClient();
  const [filterRequestTypes, setFilterRequestTypes] = useState(new Set(defaultTypeOptions));
  const [filterStatuses, setFilterStatuses] = useState(new Set(defaultTemplateStatusOptions));
  const refetchOrgsQuery = {
    refetchQueries: [
      {
        query: getOrganizationByTeam,
        variables: {
          teamId,
        },
      },
    ],
  };

  const [approveOrganizationRequest] = useMutation(mutations.approveOrganizationRequest, refetchOrgsQuery);
  const [rejectOrganizationRequest] = useMutation(mutations.rejectOrganizationRequest, refetchOrgsQuery);
  const { hasInviteSendingPermission } = useLeaseDocumentTeamPermissions({ teamId, apolloClient });

  const { loading: isOrganizationsDataLoading, data: organizationsData } = useQuery(getOrganizationByTeam, {
    skip: !teamId,
    variables: { teamId },
  });

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage id="title.deals.title" defaultMessage="Member Name" />,
        accessor: 'memberName',
        Cell: ({ cell: { value } }: any) => {
          return <FormattedMessage id="cta.go-to.details" defaultMessage={value.memberName} />;
        },
      },
      {
        Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Type" />,
        accessor: 'memberType',
        Cell: ({ cell: { value } }: any) => {
          return (
            <span className={styles.uppercasedValue}>
              <FormattedMessage
                id="cta.memberType"
                defaultMessage={OrgatizationMembership[value.toLocaleLowerCase() as OrgatizationMembershipType]}
              />
            </span>
          );
        },
      },
      {
        Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Member ID" />,
        accessor: 'memberId',
      },
      {
        Header: <FormattedMessage id="title.organizations.expiresAt" defaultMessage="Expiry Date" />,
        accessor: 'expiresAt',
        Cell: ({
          row: {
            original: { id, status },
          },
          cell: { value },
        }: any) => {
          return <ExpiryCell value={value} status={status} organizationRequestId={id} />;
        },
      },
      {
        Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Status" />,
        accessor: 'status',
        Cell: ({
          row: {
            values: { status },
          },
        }: any) => {
          return (
            <span className={styles.uppercasedValue}>
              <FormattedMessage id="cta.go-to.details" defaultMessage={status} />
            </span>
          );
        },
      },
      {
        accessor: 'handleAccess',
        Cell: ({
          row: {
            values: { status },
          },
          cell: {
            value,
            row: { original },
          },
        }: any) => {
          if (status === RequestStatuses.EXPIRED) {
            return (
              <div className={'ml-2'}>
                <div className={styles.requestsTooltipContainer}>
                  <Tooltip placement="bottom" testId="organization-template-tooltip">
                    {EXPIRY_DATE_CHANGE_MESSAGE}
                  </Tooltip>
                </div>
              </div>
            );
          }

          if (status === RequestStatuses.PAID) {
            return (
              <div className={'ml-2'}>
                <div className={styles.requestsTooltipContainer}>
                  <Tooltip placement="bottom" testId="organization-template-tooltip">
                    {USER_PAID_SUBSCRIPTION_MESSAGE}
                  </Tooltip>
                </div>
              </div>
            );
          }
          const isRequestActive = status === requestStatusMessages.approved.defaultMessage.toLocaleLowerCase();
          const isRequestRejected = status === requestStatusMessages.rejected.defaultMessage.toLocaleLowerCase();

          const handleApprove = async () => {
            try {
              await approveOrganizationRequest({ variables: { organizationRequestId: value.organizationRequestId } });
              notifySuccess({
                message: {
                  id: 'phrase.approve-org-member.success',
                  defaultMessage: HandleRequestNotification.APPROVED,
                },
              });
            } catch (error) {
              notifyError({
                message: {
                  id: 'document.action.error',
                  defaultMessage: error,
                },
              });
            }
          };

          const handleReject = async () => {
            try {
              await rejectOrganizationRequest({ variables: { organizationRequestId: value.organizationRequestId } });
              notifySuccess({
                message: {
                  id: 'phrase.reject-org-member.success',
                  defaultMessage: HandleRequestNotification.REJECTED,
                },
              });
            } catch (error) {
              notifyError({
                message: {
                  id: 'document.action.error',
                  defaultMessage: error,
                },
              });
            }
          };

          return (
            hasInviteSendingPermission && (
              <div className={styles['handle-request-buttons']}>
                <span>
                  <ApproveIcon
                    className={styles[`icon-${!isRequestActive ? 'approve' : 'disabled'}`]}
                    onClick={!isRequestActive ? handleApprove : () => {}}
                  />
                </span>
                <span>
                  <RejectIcon
                    className={styles[`icon-${!isRequestRejected ? 'reject-delete' : 'disabled'}`]}
                    onClick={!isRequestRejected ? handleReject : () => {}}
                  />
                </span>
              </div>
            )
          );
        },
        disableSortBy: true,
      },
    ],
    [
      notifySuccess,
      notifyError,
      approveOrganizationRequest,
      rejectOrganizationRequest,
      hasInviteSendingPermission,
      organizationsData?.teamById?.organization?.adminTeam.id,
    ]
  );

  const data = useMemo(() => {
    const orgsRequests = organizationsData?.teamById?.organization?.organizationRequests;
    console.log('OrgRequests', orgsRequests);

    return [...(orgsRequests || [])]
      .map((request: any) => ({
        ...request,
        status: request.isPaid ? 'Paid' : request.status,
      }))
      .filter((request: any) => {
        const hasRequestActualTemplateType = filterRequestTypes.has(request.requesterType.toLocaleLowerCase());
        const hasRequestActualStatus = filterStatuses.has(request.status);

        const hasRequestFilteredType = filterRequestTypes.size && filterRequestTypes.size < requestTypeOptions.length;
        const hasRequestFilteredStatus = filterStatuses.size && filterStatuses.size < statusOptions.length;

        if (hasRequestFilteredType && !hasRequestActualTemplateType) {
          return false;
        }

        if (hasRequestFilteredStatus && !hasRequestActualStatus) {
          return false;
        }

        if (filterText) {
          const filterTextLower = filterText.toLocaleLowerCase();
          const hasRequestFilteredText =
            request?.requesterName?.toLocaleLowerCase().includes(filterTextLower) ||
            request?.membershipId?.toLocaleLowerCase().includes(filterTextLower);
          if (!hasRequestFilteredText) return false;
        }

        return true;
      })
      .map((request: any) => ({
        id: request.id,
        memberName: { memberName: request.requesterName, memberId: request.membershipId },
        memberType: request.requesterType.toLocaleLowerCase(),
        memberId: request.membershipId,
        expiresAt: request.expiresAt,
        user: request.user,
        status:
          request.isPaid && request.status !== RequestStatuses.EXPIRED
            ? 'Paid'
            : request.status === 'approved'
            ? 'active'
            : request.status,
        handleAccess: { organizationRequestId: request.id },
      }));
  }, [filterText, filterRequestTypes, filterStatuses, organizationsData]);

  //@ts-ignore
  const requestsTable = useTable({ data, columns }, useSortBy, usePagination);

  if (isOrganizationsDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <section className={styles.settingsContainer}>
      <Helmet title={formatMessage({ id: 'title.requests', defaultMessage: 'Requests' })} />
      <ContentHeader>
        <div className={classNames(styles.settingsContainer__title, 'ml-3')} role={'heading'}>
          <FormattedMessage id="title.requests" defaultMessage="Requests" />
        </div>
        <span />
      </ContentHeader>
      <div className={styles.organizationsWrapper}>
        <ContentBody>
          <Row className="mb-3 mt-4">
            <Col className={'ml-3 mr-3 d-flex justify-content-between'}>
              <div className={'d-flex flex-fill'}>
                <SearchInput
                  className={classNames(styles.searchInboxBox, 'mr-3')}
                  value={filterText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.currentTarget.value)}
                  placeholder={formatMessage({
                    id: 'requests.filter.title.placeholder',
                    defaultMessage: 'Search by Name or Member ID',
                  })}
                  aria-label={formatMessage({
                    id: 'requests.filter.title.label',
                    defaultMessage: 'Name',
                  })}
                />

                <div className={classNames(styles.filterDropdownContainer, 'mr-3')}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-filter-requestType" as={DropdownToggle}>
                      <FormattedMessage
                        id="requests.filter.requestType.label-readout"
                        defaultMessage={`{filterCount, select,
                      0 {Type (all)}
                      1 {Type ({requestType, select,
                        team {Team}
                        user {Individual}
                        other {{requestType}}
                      })}
                      other {{filterCount} types}
                    }`}
                        values={{
                          filterCount:
                            filterRequestTypes.size === requestTypeOptions.length ? 0 : filterRequestTypes.size,
                          requestType: filterRequestTypes.size ? filterRequestTypes.values().next().value : '',
                        }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropdownItem highlightable>
                        <Checkbox
                          checked={filterRequestTypes.size === requestTypeOptions.length}
                          indeterminate={
                            filterRequestTypes.size > 0 && filterRequestTypes.size < requestTypeOptions.length
                          }
                          id="dropdown-filter-templateTypes-_all"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const checkbox = event.currentTarget;
                            if (checkbox.checked) {
                              setFilterRequestTypes(new Set(Object.keys(requestTypeMessages)));
                            } else {
                              setFilterRequestTypes(new Set());
                            }
                          }}
                          label={formatMessage({
                            id: 'requests.filter.requestsTypes.all-templateTypes',
                            defaultMessage: 'All Types',
                          })}
                        />
                      </DropdownItem>
                      <DropdownBody>
                        {requestTypeOptions.map(({ value: requestType, label }) => (
                          <DropdownItem key={requestType} highlightable>
                            <Checkbox
                              key={requestType}
                              value={requestType}
                              checked={filterRequestTypes.has(requestType)}
                              id={`dropdown-filter-templateTypes-${slugify(requestType)}`}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const checkbox = event.currentTarget;
                                const newSet = new Set(filterRequestTypes);
                                if (checkbox.checked) newSet.add(checkbox.value);
                                else newSet.delete(checkbox.value);
                                setFilterRequestTypes(newSet);
                              }}
                              label={<FormattedMessage {...label} />}
                            />
                          </DropdownItem>
                        ))}
                      </DropdownBody>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className={styles.filterDropdownContainer}>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-filter-status" as={DropdownToggle}>
                      <FormattedMessage
                        id="deals.filter.status.label-readout"
                        defaultMessage={`{filterCount, select,
                      0 {Status (any)}
                      1 {Status ({status, select,
                        approved {Active}
                        pending {Pending}
                        rejected {Rejected}
                        expired {Expired}
                        other {{status}}
                      })}
                      other {{filterCount} statuses}
                    }`}
                        values={{
                          filterCount: filterStatuses.size === statusOptions.length ? 0 : filterStatuses.size,
                          status: filterStatuses.size ? filterStatuses.values().next().value : '',
                        }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropdownItem highlightable>
                        <Checkbox
                          checked={filterStatuses.size === statusOptions.length}
                          indeterminate={filterStatuses.size > 0 && filterStatuses.size < statusOptions.length}
                          id="dropdown-filter-statuses-_all"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const checkbox = event.currentTarget;
                            if (checkbox.checked) {
                              setFilterStatuses(new Set(Object.keys(requestStatusMessages)));
                            } else {
                              setFilterStatuses(new Set());
                            }
                          }}
                          label={formatMessage({
                            id: 'requests.filter.status.all-statuses',
                            defaultMessage: 'Any status',
                          })}
                        />
                      </DropdownItem>
                      <DropdownBody>
                        {statusOptions.map(({ value: status, label }) => (
                          <DropdownItem key={status} highlightable>
                            <Checkbox
                              key={status}
                              value={status}
                              checked={filterStatuses.has(status)}
                              id={`dropdown-filter-statuses-${slugify(status)}`}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const checkbox = event.currentTarget;
                                const newSet = new Set(filterStatuses);
                                if (checkbox.checked) newSet.add(checkbox.value);
                                else newSet.delete(checkbox.value);
                                setFilterStatuses(newSet);
                              }}
                              label={<FormattedMessage {...label} />}
                            />
                          </DropdownItem>
                        ))}
                      </DropdownBody>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>

          <Row className={styles.contentTable}>
            <Col className={'mr-3 ml-3'}>
              <div>
                <Table striped isStickyHeader table={requestsTable} />
              </div>
            </Col>
          </Row>
        </ContentBody>
      </div>
    </section>
  );
};

export default Requests;
