import { DealModalActionsEnum } from '../constants/DealConstants';

export const RESEND_MODAL_MESSAGE = 'Another email will be sent to the invited counterparty.';
export const REMOVE_COUNTERPARTY_MODAL_MESSAGE = 'Counterparty and their teammates will lose access to this deal.';
export const CANCEL_INVITE_MODAL_MESSAGE =
  'You will be permitted to invite another counterparty once this invite is cancelled.';

export const determineModalData = (modalType: string | null) => {
  if (!modalType) return {};
  const modalEvents = new Map();
  modalEvents
    .set(DealModalActionsEnum.CANCEL, {
      title: 'Cancel Counterparty Invite',
      submitButtonTitle: 'Cancel Invite',
      cancelButtonTitle: 'Close',
      showAdditionalMessageField: true,
      message: CANCEL_INVITE_MODAL_MESSAGE,
    })
    .set(DealModalActionsEnum.RESEND, {
      title: 'Resend Counterparty Invite',
      submitButtonTitle: 'Resend',
      showAdditionalMessageField: false,
      message: RESEND_MODAL_MESSAGE,
    })
    .set(DealModalActionsEnum.REMOVE_COUNTERPARTY, {
      title: 'Remove Counterparty',
      submitButtonTitle: 'Remove Access',
      showAdditionalMessageField: true,
      message: REMOVE_COUNTERPARTY_MODAL_MESSAGE,
    });
  return modalEvents.get(modalType);
};

export const fuzzyInputSearch = ({ filteredText, searchValue }: { filteredText: string; searchValue: string }) => {
  let templateName = searchValue.toLocaleLowerCase();
  let searchInput = filteredText.toLocaleLowerCase();

  const searchValues = searchInput.split(' ');
  let searchResults: string[] = [];
  searchValues.forEach(obj => {
    if (templateName.includes(obj)) {
      searchResults.push(templateName);
    }
  });

  return searchResults.length === searchValues.length;
};
