import React from 'react';
import { Button } from 'react-bootstrap';
import { SendDocumentButtonMessage } from '../../../../../document-table/DocumentTableConstants';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';

interface SendProps {
  disabled: boolean;
  onClick: () => void;
}

const Send = ({ disabled, onClick }: SendProps) => {
  return (
    <Button className={'send-button mr-2'} disabled={disabled} onClick={onClick} variant={'primary'}>
      <TitleFormattedMessage
        id="lease-document-wizard-header.action.share-draft"
        defaultMessage={SendDocumentButtonMessage.SEND_FOR_REVIEW}
        data-testid="lease-document-wizard-header-share-draft-action"
      />
    </Button>
  );
};

export default Send;
