import { DealType } from './deals.types';
import { DealAction } from './deals.actions';

export type DealsStateType = {
  deal: DealType | null;
};

const initialState: DealsStateType = {
  deal: null,
};

const reducer = (state: DealsStateType = initialState, action: DealAction): DealsStateType => {
  switch (action.type) {
    case 'setDeal':
      return {
        ...state,
        deal: action.deal,
      };

    default:
      return state;
  }
};

export { initialState as initialDealsState };

export { reducer as dealsReducer };
