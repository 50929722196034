import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';

export const GET_DOCUMENT_REVISION_BY_EXTERNAL_ID =  graphql(/* GraphQL */`
  query GetDocumentRevisionByExternalId($externalId: ID!, $documentId: ID!, $documentType: String!) {
    getDocumentRevisionByExternalId(externalId: $externalId, documentId: $documentId, documentType: $documentType) {
      id
      revisionId
      authorsIds
      authors { id }
      creator { id }
      data
      diffData
      attributes
      fromVersion
      toVersion
      createdAt
      name
    }
  }
`);

/* Questions:
- What is the data in this case?
 */
export const getDocumentRevisionById = (externalId: string, documentId: string, documentType: string) => (
  client: ApolloClient<object>
): Promise<any> => {
  const variables = { externalId, documentId, documentType };

  return client
    .query({ query: GET_DOCUMENT_REVISION_BY_EXTERNAL_ID, variables })
    .then(({ data }: Record<any, any>) => {
      let {id: oldId, revisionId: id, ...rest} = data.getDocumentRevisionByExternalId;
      return { id, ...rest };
    });
};

