import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LeaseDocumentWizardModal } from '../../../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import { approveDocumentReview, rejectDocumentReview } from '../../../../../../api/mutations';
import { getDocumentById } from '../../../../../../api/queries_v2';
import { useNotifications } from '../../../../../../notifications';
import { fillPlaceholders, paths } from '../../../../../../routing';
import { OptionalMessageInputRef } from '../../../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/OptionalMessageInputRef';
import {
  ApprovalRequestActions,
  confirmApprovalSuccessMessage,
  rejectApprovalSuccessMessage,
} from './RequestInternalApprovalModalConstants';
import styles from './RequestReview.module.scss';

interface Props {
  onHide: () => void;
  show: boolean;
  documentId: string;
  requestApprovalAction: ApprovalRequestActions | null;
  dealId: string;
  teamId: string;
  reviewId: string;
}

const HandleInternalApprovalActionsModal = ({
  onHide,
  show,
  documentId,
  requestApprovalAction,
  dealId,
  reviewId,
  teamId,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const approveModal = requestApprovalAction === ApprovalRequestActions.APPROVE;
  const dealDeatilsUrl = fillPlaceholders(paths.deals.detail, {
    teamId,
    dealId,
  });
  const [rejectDocumentReviewMutation] = useMutation(rejectDocumentReview, {
    refetchQueries: [
      {
        query: getDocumentById,
        variables: {
          documentId,
        },
      },
    ],
  });
  const [approveDocumentReviewMutation] = useMutation(approveDocumentReview, {
    refetchQueries: [
      {
        query: getDocumentById,
        variables: {
          documentId,
        },
      },
    ],
  });

  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const handleApprove = useCallback(async () => {
    setIsLoading(true);

    const additionalMessage = additionalMessageInputRef.current?.value;

    try {
      await approveDocumentReviewMutation({
        variables: {
          reviewId,
          reason: additionalMessage,
        },
      });

      navigate(dealDeatilsUrl);

      notifySuccess({
        message: formatMessage(confirmApprovalSuccessMessage),
      });
    } catch (error) {
      //@ts-ignore
      notifyError({ message: error.message });
    } finally {
      setIsLoading(false);
    }
  }, [approveDocumentReviewMutation, notifyError, reviewId, notifySuccess, dealDeatilsUrl, formatMessage, navigate]);

  const handleReject = useCallback(async () => {
    setIsLoading(true);
    const additionalMessage = additionalMessageInputRef.current?.value;

    try {
      await rejectDocumentReviewMutation({
        variables: {
          reviewId,
          reason: additionalMessage,
        },
      });
      navigate(dealDeatilsUrl);
      notifySuccess({
        message: formatMessage(rejectApprovalSuccessMessage),
      });
    } catch (error) {
      //@ts-ignore
      notifyError({ message: error.message });
    } finally {
      setIsLoading(false);
    }
  }, [
    rejectDocumentReviewMutation,
    notifyError,
    reviewId,
    dealDeatilsUrl,
    notifySuccess,
    formatMessage,
    navigate,
  ]);

  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onHide,
      label: 'Cancel',
      theme: 'outline-secondary',
    },
    {
      onClick: approveModal ? handleApprove : handleReject,
      label: approveModal ? ApprovalRequestActions.APPROVE : ApprovalRequestActions.REJECT,
      theme: 'secondary',
      isLoading,
      disabled: isLoading,
      className: styles['action-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={styles.title}
      size="lg"
      show={show}
      onHide={onHide}
      title={
        <FormattedMessage
          id="lease-document-wizard-modal.handle_internal_approval"
          defaultMessage={`${approveModal ? ApprovalRequestActions.APPROVE : ApprovalRequestActions.REJECT} Request`}
        />
      }
      modalCTAs={modalCTAs}
    >
      <div className={styles.main}>
        {approveModal
          ? 'Document will remain read-only after approval'
          : 'This will reject the entire approval request'}
      </div>
      <OptionalMessageInputRef ref={additionalMessageInputRef} />
    </LeaseDocumentWizardModal>
  );
};

export default HandleInternalApprovalActionsModal;
