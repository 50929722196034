import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentGeneratedDocumentPdfUrlAction } from '../actions/lease-document.actions';

export const handleSetDocumentGeneratedPdfUrl = (
  state: LeaseDocumentStateType,
  action: SetDocumentGeneratedDocumentPdfUrlAction
): LeaseDocumentStateType => {
  return {
    ...state,
    generatedDocumentPdfUrl: action.url,
  };
};
