import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentEversignPdfUrlAction } from '../actions/lease-document.actions';

export const handleSetDocumentEversingUrlPdf = (
  state: LeaseDocumentStateType,
  action: SetDocumentEversignPdfUrlAction
): LeaseDocumentStateType => {
  return {
    ...state,
    eversignPdfUrl: action.eversignPdfUrl,
  };
};
