import { LeaseDocumentStateType } from '../lease-document.reducer'
import { SetLeaseDocumentIsPendingAction } from '../actions/lease-document.actions'

export const handleSetLeaseDocumentIsPending = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentIsPendingAction
): LeaseDocumentStateType => ({
  ...state,
  isPending: action.isPending,
})
