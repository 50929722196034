import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DocumentStatusEnum } from '../../../../../../shared/constants/leaseDocument';
import {
  selectDocumentEditorSuggestions,
  selectLeaseDocumentStatus,
} from '../../../../../../store/lease-document/selectors/lease-document.selectors';
import { selectCurrentDeal } from '../../../../../../store/deals/deals.selectors';
import { shareDocumentWithReceiver } from '../../../../../../utils/api/grant-document-access';
import {
  givePossessionSuccessMessage,
  ShareWithUnresolvedSuggestionsWarning,
  successSendDocumentMessage,
} from '../../../../lease-editor/constants/EditorConstats';
import { useDocumentRole } from '../../../../../../shared/hooks/useDocumentRole/useDocumentRole';
import { useNotifications } from '../../../../../../notifications';
import { paths } from '../../../../../../routing';
import { selectIsGrantAccessModalOpen } from '../../../../../../store/modals/modals.selectors';
import GrantAccessButton from './grantAccessButton';
import GrantAccessModal from './grantAccessModal';

interface Props {
  teamId: string;
  documentId: string;
  dealId: string;
}

const GrantAccess = ({ documentId, dealId, teamId }: Props) => {
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();
  const isGrantAccessModalOpen = useSelector(selectIsGrantAccessModalOpen);
  const [showModal, setShowModal] = useState(isGrantAccessModalOpen);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const optionalMessageInputRef = React.useRef<HTMLTextAreaElement>(null);
  const { formatMessage } = useIntl();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const currentDeal = useSelector(selectCurrentDeal);
  const isDealShared = !!currentDeal?.receiverEmail;
  const { userRole } = useDocumentRole(documentId);
  const dealUrl = paths.deals.detail.replace(':teamId', teamId as string).replace(':dealId', dealId);
  useSelector(selectDocumentEditorSuggestions); // using to subscribe to store updates for editor suggestions

  const handleSendDocumentMutation = useCallback(async () => {
    const additionalMessage = optionalMessageInputRef.current?.value;
    await shareDocumentWithReceiver(
      documentId,
      additionalMessage
    )(apolloClient)
      .then(() => {
        navigate(dealUrl);

        const successGrantAccessMessageWithEmail = {
          id: successSendDocumentMessage.id,
          defaultMessage: successSendDocumentMessage.defaultMessage,
        };
        isDealShared &&
          notifySuccess({
            message: formatMessage(
              documentStatus === DocumentStatusEnum.UPLOADED
                ? successGrantAccessMessageWithEmail
                : givePossessionSuccessMessage
            ),
          });
      })
      .catch(error => {
        notifyError({ message: error.message });
      });
  }, [
    apolloClient,
    dealUrl,
    documentId,
    documentStatus,
    formatMessage,
    notifyError,
    notifySuccess,
    isDealShared,
    navigate,
  ]);

  const handleSendDocument = useCallback(async () => {
    setIsLoading(true);
    return await handleSendDocumentMutation();
  }, [handleSendDocumentMutation]);

  const pendingSamePartySuggestions = () => {
    if (window.editor?.state === 'destroyed') return;

    const suggestions =
      window.editor &&
      window.editor.plugins
        .get('TrackChanges')
        .getSuggestions()
        .filter((item: any) => item.isInContent);

    const counterpartySuggestions = suggestions?.filter((suggestion: any) => {
      return suggestion.attributes.authorRole && suggestion.attributes.authorRole !== userRole;
    });

    return counterpartySuggestions?.length;
  };

  const getPendingSuggestionsAlert = () => {
    const suggestionsNumber = pendingSamePartySuggestions();

    if (!suggestionsNumber) return null;

    return {
      text: ShareWithUnresolvedSuggestionsWarning.text.replace('{num}', `${suggestionsNumber}`),
      title: ShareWithUnresolvedSuggestionsWarning.title,
    };
  };

  return (
    <>
      <GrantAccessButton onClick={() => setShowModal(true)} />
      <GrantAccessModal
        show={showModal}
        onHide={() => setShowModal(false)}
        isDealShared={isDealShared}
        handler={handleSendDocument}
        isLoading={isLoading}
        suggestionsAlert={getPendingSuggestionsAlert?.()}
        optionalMessageInputRef={optionalMessageInputRef}
        teamId={teamId}
      />
    </>
  );
};

export default GrantAccess;
