import React from 'react';
import className from 'classnames';
import { useLocation } from 'react-router-dom';

import { A, fillPlaceholders } from '../../routing';
import styles from '../private-layout/styles.module.scss';

interface ItemProps {
  children: React.ReactNode;
  pathRegex?: RegExp;
  href: string;
  hrefParams?: { [key: string]: any };
  qa?: { [key: string]: any };
  icon: React.FC<{ height: string; width: string; className: string }>;
}

export const Item: React.FC<ItemProps> = ({
  icon: Icon,
  children,
  pathRegex = null,
  href,
  hrefParams = {},
  qa = {},
}) => {
  const { pathname: path } = useLocation();

  const isCurrent = pathRegex?.test(path) ?? path === fillPlaceholders(href, hrefParams);

  return (
    <A
      data-testid={qa}
      className={className(styles.mobileMenu__item, { [styles.isCurrent]: isCurrent })}
      href={href}
      hrefParams={hrefParams}
    >
      <Icon height="24" width="24" className="ml-1" />
      <div className={styles.mobileMenu__itemLabel}>{children}</div>
    </A>
  );
};
