import { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';

import { queries, usePartyIds } from './api';

export const TeamIdContext = createContext();
TeamIdContext.displayName = 'TeamIdContext';

export function useTeamInfo() {
  const teamId = useContext(TeamIdContext);

  // Get team data
  const { loading: teamLoading, data: teamData } = useQuery(queries.getTeamById, {
    skip: teamId == null,
    variables: {
      teamId,
    },
  });

  // Extract party ID from team data
  const partyId = teamData?.teamById.party.id;
  // Extract name from team data
  const teamUUID = teamData?.teamById.uuid;
  const teamName = teamData?.teamById.name;
  const adminUser = teamData?.teamById.adminUser;

  // Get party IDs
  const { loading: partyIdsLoading, partyIds } = usePartyIds();

  return {
    teamId,
    teamUUID,
    loading: teamLoading || partyIdsLoading,
    partyId,
    teamName,
    adminUser,
  };
}
