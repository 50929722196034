import { ApolloClient } from '@apollo/client';
import { DocumentPayload } from './lease-document-interface.types';
import { graphql } from '../../gql';

const query = graphql(/* GraphQL */ `
  query GetTemplateById($templateId: ID!) {
    getDocumentTemplateById(documentTemplateId: $templateId) {
      id
      country
      state
      name
      description
      dealType
      customTemplate
      updatedAt
      template
      source
      importedAssetUrl
      originalImportedAssetUrl
      importedAssetContentType
      team {
        id
        organization {
          name
        }
      }
      editorConfiguration
      templateType
    }
  }
`);

export const getDocumentTemplateById = (templateId: string | number) => (
  client: ApolloClient<object>
): Promise<DocumentPayload> => {
  const variables = { templateId: templateId as string };

  return client.query({ query, variables }).then(({ data }: Record<any, any>) => data.getDocumentTemplateById);
};

export const getDocumentByIdOfferQuery = query;
