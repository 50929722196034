import React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './styles.module.scss'

const SkipLink = props => (
  <a className={styles.root} {...props}>
    <FormattedMessage id="cta.go-to.main-content" defaultMessage="Skip to main content" />
  </a>
)

export default SkipLink
