import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseDocumentCloudDocumentVersionAction } from '../actions/lease-document.actions';

export const handleSetLeaseDocumentCloudVersion = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentCloudDocumentVersionAction
) => ({
  ...state,
  cloudDocumentVersion: action.documentVersion,
});
