import { LeaseDocumentStateType } from '../lease-document.reducer'
import { SetLeaseDocumentIdAction } from '../actions/lease-document.actions'

export const handleSetLeaseDocumentId = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentIdAction
): LeaseDocumentStateType => ({
  ...state,
  id: action.id,
})
