import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ReactComponent as DragHandle } from '../../../../../../../../../shared/icons/drag.svg';

interface PillProps {
  title: string;
  icon: any;
  onDragStart: (...args: any[]) => void;
  onDragEnd: (...args: any[]) => void;
  className?: string;
  disabled: boolean;
}

const Pill = ({ title, icon: Icon, className, onDragStart, onDragEnd, disabled }: PillProps) => {
  return (
    <Button
      className={classNames(styles.wrapper, className, { [styles['wrapper-disabled']]: disabled })}
      draggable
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      disabled={disabled}
    >
      <Icon className={styles.icon} />
      <DragHandle className={styles['drag-handle']} />
      {title}
    </Button>
  );
};

export default Pill;
