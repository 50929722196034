import React, { useEffect, useState } from 'react';
import SendToPreparingToSignInternal from './sendToPreparingToSignInternal';
import SendToPreparingToSignInternalModal from './sendToPreparingToSignInternalModal';
import { useSelector } from 'react-redux';
import {
  selectDocumentEditorSuggestions,
  selectDocumentIsEligibleSigning,
} from '../../../../../../store/lease-document/selectors/lease-document.selectors';

import PlaceholderWarningModal from '../../../../lease-document-wizard-modal/custom-modals/placeholderWarningModal/placeholderWarningModal';

interface Props {
  dealId: string;
  teamId: string;
  documentId: string;
  isOpen: boolean;
  handleSaveDocument?: (() => Promise<void>) | undefined;
  placeholdersToComplete?: number;
}

// TODO: Build the new modal to warn users about if they can send the the doc for signing without notification
const SendToPreparingToSignInternalContainer = ({
  dealId,
  documentId,
  teamId,
  isOpen,
  handleSaveDocument,
  placeholdersToComplete = 0,
}: Props) => {

  const isModalInitiallyOpen = isOpen && placeholdersToComplete === 0;

  const isWarningModalInitiallyOpen = isOpen && placeholdersToComplete !== 0;

  const [showModal, setShowModal] = useState(isModalInitiallyOpen);

  const [showWarningModal, setShowWarningModal] = useState(isWarningModalInitiallyOpen);

  const editorSuggestions = useSelector(selectDocumentEditorSuggestions);

  const isEligible = useSelector(selectDocumentIsEligibleSigning);

  const isDisabled = editorSuggestions.length !== 0 || !isEligible;

  const [confirmSend, setConfirmSend] = useState(false)

  const handleWarningModalClose = () => {
    setShowWarningModal(false);
    setShowModal(false);
  }

  return (
    <>
      <SendToPreparingToSignInternal
        disabled={isDisabled}
        onClick={() => placeholdersToComplete < 1 ? setShowModal(true) : setShowWarningModal(true)}
        showReturnForReviewTooltip={!!editorSuggestions.length}
      />
      <PlaceholderWarningModal
        isOpen={showWarningModal}
        placeholders={placeholdersToComplete}
        onContinue={() => {
          setShowWarningModal(false);
          setShowModal(true);
        }}
        onReview={handleWarningModalClose}
        onHide={handleWarningModalClose}
      />
      <SendToPreparingToSignInternalModal
        documentId={documentId}
        dealId={dealId}
        teamId={teamId}
        onHide={() => setShowModal(false)}
        show={showModal}
        handleSaveDocument={handleSaveDocument}
      />
    </>
  );
};

export default SendToPreparingToSignInternalContainer;
