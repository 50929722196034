import React from 'react';
import { ReactComponent as DangerIcon } from '../../shared/icons/exclamation-triangle.svg';
import styles from './styles.module.scss';

const SignErrorPage = () => (
  <div className={styles.wrapper}>
    <DangerIcon className={styles.icon} />
    <h1 className={styles.title}>Cannot Sign Document</h1>
    <p className={styles.text}>
      Sorry, this document cannot be signed. Potential reasons: Document has already been completed, or document has
      been deleted, cancelled or has expired.
    </p>
  </div>
);

export default SignErrorPage;
