import React from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'react-bootstrap';
import { LeaseDocumentWizardModal } from '../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { OptionalMessageInputRef } from '../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/OptionalMessageInputRef';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';
import { DocumentSignerWithSignedAt } from '../../../../store/lease-document/actions/set-lease-document-signers.action';
import styles from './styles.module.scss';

interface LeaseDocumentWizardConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  handleConfirm: () => void;
  isSending: boolean;
  nextSigner?: DocumentSignerWithSignedAt | null;
  optionalMessageInputRef?: React.Ref<HTMLTextAreaElement>;
}

const SEND_REMINDER_MODAL = 'Would you like to send a reminder to the next signer to complete the document signing?';

export const SendReminderConfirmationModal = ({
  show,
  nextSigner,
  onHide,
  handleConfirm,
  isSending,
  optionalMessageInputRef,
}: LeaseDocumentWizardConfirmationModalProps) => (
  <LeaseDocumentWizardModal
    size="lg"
    show={show}
    onHide={onHide}
    titleClassName={styles.title}
    title={
      <FormattedMessage
        id="lease-document-wizard-modal.unsign-confirmation-modal-title"
        defaultMessage="Send Reminder to Signers"
      />
    }
    modalCTAs={[]}
  >
    <section className={classNames('lease-document-wizard-unsign-confirmation-modal', styles.wrapper)}>
      <FormattedMessage
        id="lease-document-wizard-modal.lease-confirmation-content"
        defaultMessage={SEND_REMINDER_MODAL}
      />
    </section>
    {nextSigner && (
      <section className={classNames('lease-document-wizard-unsign-confirmation-modal', styles.emailWrapper)}>
        <FormattedMessage
          id="lease-document-wizard-modal.lease-confirmation-content"
          defaultMessage={`Next Signer: ${nextSigner.email}`}
        />
      </section>
    )}
    <OptionalMessageInputRef ref={optionalMessageInputRef} />
    <div className={classNames('d-flex justify-content-end', styles.buttonsWrapper)}>
      <Button variant="outline-warning" className="mx-2" onClick={onHide}>
        <TitleFormattedMessage
          id="lease-document-wizard-modal.lease-confirmation-button-cancel"
          defaultMessage="Close"
        />
      </Button>
      <Button
        variant="warning"
        className={classNames('mx-2', styles['edit-button'])}
        onClick={handleConfirm}
        disabled={isSending}
      >
        {isSending && <Spinner animation="border" size="sm" />}
        <TitleFormattedMessage
          id="lease-document-wizard-modal.lease-confirmation-button-remove-signature"
          defaultMessage="Confirm"
        />
      </Button>
    </div>
  </LeaseDocumentWizardModal>
);
