import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import DecoupledEditor from '@simplelease/ckeditor5-build-decoupled-document';
import { useNavigate } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { TemplateEditor } from './TemplateEditor';
import { mutations, queries } from '../../../api';
import { TemplateEditorContainerPropTypes, EditorType } from './utils/lease-editor.types';
import { useAuth } from '../../../auth';
import { useLeaseDocumentWizardErrorNotifier } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-wizard-error-notifier/use-lease-document-wizard-error-notifier.hook';
import { useCommentContext, useCommentThreadRendered } from '../../../contexts/commentContext';
import LoadingSpinner from '../../loading-spinner';
import { LeaseEditorErrors } from './constants/EditorConstats';
import { DocumentTypesEnum } from '../../../utils/ckeditor/trackChangesAdapter/types';
import { useSuggestionsHandling } from '../../../shared/hooks/useSuggestionsHandling';
import './lease-editor.scss';
import { useRevisionHistory } from '../../../shared/hooks/useRevisionHistory';
import { useNotifications } from '../../../notifications';
import { LeaseDocumentComment } from '../lease-document.types';
import { useEditorConfigFunctions } from "./utils/editorConfigFunctions";
import { useSelector } from 'react-redux';
import {
  selectLeaseDocumentCloudVersion,
  selectLeaseDocumentStatus, selectLeaseDocumentTemplate,
} from '../../../store/lease-document/selectors/lease-document.selectors';

declare global {
  interface Window {
    editor: EditorType;
    commentsContext: LeaseDocumentComment[];
  }
}

export const TemplateEditorContainer = ({
  leaseTemplate,
  save,
  documentId,
  canEdit,
  canComment,
  teamId,
  permissions,
  isRestrictedEditingEnabled,
  templateTitle,
  isOrganizationTemplate,
  organizationName,
}: TemplateEditorContainerPropTypes) => {
  const { getDecoupledEditor } = DecoupledEditor;
  const commentContext = useCommentContext();
  const updateThreadRendered = useCommentThreadRendered();
  const updateThreadRenderedContext = (threadId: string, contextComments: Object[]) =>
    updateThreadRendered(threadId, contextComments);
  // @ts-ignore - user: { fullName: string, email: string }
  const [{ user }] = useAuth();
  const [, { hide: hideNotifications }] = useNotifications();
  const userId = user.id;
  const notifyError = useLeaseDocumentWizardErrorNotifier();
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const template = useSelector(selectLeaseDocumentTemplate);
  const isLockedRef = useRef<boolean>(false);
  const [isTemplateLocked, setIsTemplateLocked] = useState(false);
  const [lockDocumentTemplate, { loading: isLockTemplateLoading }] = useMutation(mutations.lockDocumentTemplate);
  const [unlockDocumentTemplate] = useMutation(mutations.unlockDocumentTemplate);
  const {
    createSuggestion,
    getSuggestionById,
    deleteSuggestionById,
    acceptSuggestionById,
    rejectSuggestionById,
    pendSuggestionById,
  } = useSuggestionsHandling(
    documentId,
    DocumentTypesEnum.TEMPLATE
  );
  const { getDocumentRevisions, updateDocumentRevisions } = useRevisionHistory(documentId, DocumentTypesEnum.TEMPLATE);
  const {
    addCommentThread,
    getCommentThread,
    updateCommentThread,
    postComment,
    removeComment,
    removeCommentThread,
    updateComment,
    resolveCommentThread,
    reopenCommentThread,
  } = useEditorConfigFunctions({ type: "TEMPLATE", documentId: documentId as string })
  const handleUnlock = useCallback(() => {
    if (isLockedRef.current) {
      unlockDocumentTemplate({ variables: { documentId, userId } });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    !isOrganizationTemplate &&
      lockDocumentTemplate({ variables: { documentId, userId } })
        .then(() => {
          isLockedRef.current = true;
        })
        .catch((err: any) => {
          setIsTemplateLocked(true);
          notifyError({ message: LeaseEditorErrors.TEMPLATE_IS_LOCKED.defaultMessage, pinned: true });
        });

    return () => {
      if (isLockedRef.current) {
        !isOrganizationTemplate && unlockDocumentTemplate({ variables: { documentId, userId } });
      }
      hideNotifications();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnlock);

    return () => {
      window.removeEventListener('beforeunload', handleUnlock);
    };
  }, []); // eslint-disable-line

  const { loading: isTeamQueryLoading, data: teamData, error: teamGraphqlError } = useQuery(queries.getTeamById, {
    skip: teamId == null,
    variables: {
      teamId,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  const cloudVersion = useSelector(selectLeaseDocumentCloudVersion);
  const saveData = useCallback(
    document => {
      return save({
        body: document,
        cloudDocumentVersion: Number(cloudVersion) + 1,
      });
    },
    [cloudVersion, save]
  );

  const userList = useMemo(() => {
    if (isTeamQueryLoading || !teamData || teamGraphqlError) return [];

    const {
      teamById: { teamMembers, timelineMembers },
    } = teamData;

    const currentTeamMemberIds = teamMembers.map(({ user }: any) => user.id);

    const timelineTeamMembers = timelineMembers.map(({ id, fullName, email }: any) => ({ 
      id, name: currentTeamMemberIds.includes(id) ? fullName : `${fullName} *removed*`, email 
    }));

    return timelineTeamMembers;
  }, [isTeamQueryLoading, teamData, teamGraphqlError]);

  if (isTeamQueryLoading || isLockTemplateLoading) {
    return <LoadingSpinner />;
  }

  if (!user || !teamData) {
    return null;
  }

  return (
    <>
      <TemplateEditor
        editor={getDecoupledEditor({
          context: commentContext,
          threadRendered: updateThreadRenderedContext,
          permissions,
          isCreator: true,
          isShared: false,
          userDocumentRole: 'creator',
          documentStatus,
          documentType: 'template',
        })}
        user={user}
        addCommentThread={addCommentThread}
        postComment={postComment}
        updateComment={updateComment}
        updateCommentThread={updateCommentThread}
        resolveCommentThread={resolveCommentThread}
        reopenCommentThread={reopenCommentThread}
        removeComment={removeComment}
        removeCommentThread={removeCommentThread}
        getCommentThread={getCommentThread}
        leaseTemplate={leaseTemplate}
        templateTitle={templateTitle}
        documentId={documentId}
        userList={userList}
        canEdit={!isOrganizationTemplate ? canEdit : false}
        canComment={canComment}
        permissions={permissions}
        notifyError={notifyError}
        saveData={!isOrganizationTemplate ? saveData : () => {}}
        teamId={teamId}
        createSuggestion={createSuggestion}
        getSuggestionById={getSuggestionById}
        deleteSuggestionById={deleteSuggestionById}
        acceptSuggestionById={acceptSuggestionById}
        rejectSuggestionById={rejectSuggestionById}
        pendSuggestionById={pendSuggestionById}
        save={save}
        getDocumentRevisions={
          !isOrganizationTemplate
            ? getDocumentRevisions
            : () => {
                return [];
              }
        }
        createDocumentRevision={!isOrganizationTemplate ? updateDocumentRevisions : () => {}}
        isTemplateLocked={isTemplateLocked}
        isRestrictedEditingEnabled={isRestrictedEditingEnabled}
        isOrganizationTemplate={isOrganizationTemplate}
      />
    </>
  );
};
