import { ApolloClient } from '@apollo/client';
import { graphql } from '../../gql';

export const remindCurrentSigner = ({
  documentId,
  additionalMessage,
}: {
  documentId: string;
  additionalMessage?: string;
}) => (client: ApolloClient<object>): Promise<boolean> => {
  const mutation = graphql(/* GraphQL */ `
    mutation RemindCurrentSigner($documentId: ID!, $additionalMessage: String) {
      remindCurrentSigner(documentId: $documentId, additionalMessage: $additionalMessage)
    }
  `);

  return client
    .mutate({
      mutation,
      variables: { documentId, additionalMessage },
    })
    .then(() => true);
};
