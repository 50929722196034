import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TitleFormattedMessage } from '../../../../titleFormattedMessage';

interface TimeoutModalProps {
  onClose: () => void;
  onTimeOut: () => void;
}

const TIMEOUT_SECONDS = 10;
const INTERVAL_MILLISECONDS = 1000;

const TimeoutModal = ({ onClose, onTimeOut }: TimeoutModalProps) => {
  const { formatMessage } = useIntl();
  const [secondsLeft, setSecondsLeft] = useState<number>(TIMEOUT_SECONDS);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    interval = setInterval(() => {
      if (secondsLeft > 1) {
        setSecondsLeft(seconds => {
          return seconds - 1;
        });
        return;
      }

      onTimeOut();
    }, INTERVAL_MILLISECONDS);

    return () => {
      interval && clearInterval(interval);
    };
  }, [onTimeOut, secondsLeft]);

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
        <Modal.Title>
          <header>Are you still here?</header>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        We have detected that you have been inactive for 10 minutes. To allow others to access the editor, you will be
        directed out of the document editor in {secondsLeft} seconds.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          <TitleFormattedMessage id="cta.cancel" defaultMessage="I'm here" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimeoutModal;
