import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  Dispatch,
  ExoticComponent,
  Fragment,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DocumentNode, useMutation, useQuery } from '@apollo/client';
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { Form, Formik, FormikErrors } from 'formik';
import ErrorMessage from '../../../components/error-message';
import SubmitButton from '../../../components/submit-button';
import { useTeamInfo } from '../../../team-id-context';
import LoadingSpinner from '../../../components/loading-spinner';
import {
  MEMBERSHIP_TYPE_MESSAGE,
  ORGANIZATION_MEMBER_ONLY_MESSAGE,
  OrganizationType,
  OrgatizationMembership,
  RequestMembershipOrganization,
  RequestMembershipType,
} from '../constants';
import { MembershipTypeField, SelectOrganizationField } from './AddOrganizationModalFields';
import Tooltip, { ClickableTooltip } from '../../../components/tooltip';
import { mutations, queries } from '../../../api';
import { TextField } from '../../../components/fields';
import { getOrganizationById, organizationsAccessByUser } from '../../../api/queries';
import { useNotifications } from '../../../notifications';
import { CheckboxField } from '../../../components/checkbox-or-radio-field';
import { GetOrganizationsQuery, Organization } from '../../../gql/graphql';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { TitleFormattedMessage } from '../../../components/titleFormattedMessage';
import styles from '../styles.module.scss';
import Toggle from '../../../components/toggle';
import { LoadingIndicator } from 'react-select/dist/declarations/src/components/indicators';

interface Props {
  showAddOrganizationModal: boolean;
  isAdmin: boolean;
  userId: string | number;
  onModalHide: () => void;
  setShowTermsOfUseModal: Dispatch<SetStateAction<boolean>>;
  setOrgRequestSelectedId: Dispatch<SetStateAction<string>>;
  organizationsAccessByUserData: OrganizationType[];
  orgRequestSelectedId: string;
}

interface CreateOrganizationVariables {
  organizationId: string;
  requesterId: string;
  requesterType: string;
  membershipId?: string | undefined;
  acceptTerms?: boolean;
}

interface RequestAccessFormProps {
  bodyContainer: ExoticComponent<{ children?: ReactNode }>;
  actionsContainer: ExoticComponent<{ children?: ReactNode }>;
  renderSecondaryAction: () => ReactNode;
  onSuccess?: () => void;
  errors: FormikErrors<any>;
  values: { [key: string]: string | boolean | undefined };
  isSubmitting: boolean;
  isValid: boolean;
  setFieldValue: any;
  isAdmin: boolean;
  userId: string | number;
  organizations: { id: string; name: string; termsOfUseRequired: boolean; tooltip?: string | null | undefined }[];
  setShowTermsOfUseModal: Dispatch<SetStateAction<boolean>>;
  setOrgRequestSelectedId: Dispatch<SetStateAction<string>>;
  orgRequestSelectedId: string;
  teamRequestsAllowed: boolean;
  membersOnly: boolean;
  selectedOrgDataLoading: boolean;
}

const RequestAccessForm = ({
  bodyContainer: BodyContainer = Fragment,
  actionsContainer: ActionsContainer = Fragment,
  renderSecondaryAction,
  errors,
  values,
  isSubmitting,
  isValid,
  setFieldValue,
  isAdmin,
  organizations,
  setShowTermsOfUseModal,
  setOrgRequestSelectedId,
  teamRequestsAllowed,
  membersOnly,
  selectedOrgDataLoading
}: RequestAccessFormProps) => {
  const onMembershipTypeChange = useCallback(() => {
    if (values.membershipType === OrgatizationMembership.team) {
      values.membershipId = undefined;
    }
  }, [values]);

  const onOrganizationIdChange = useCallback(() => {
    values.membershipId = undefined;
    values.membershipType = '';
    values.acceptTerms = false;
  }, [values]);

  const [requireTermsField, setRequireTermsField] = useState<{ required: boolean; tooltip: string }>({
    required: false,
    tooltip: '',
  });
  const [existingMember, setExistingMember] = useState(false);

  useEffect(() => {
    const currentOrg = organizations.filter(org => {
      return org.id === values.organizationID;
    });
    setFieldValue('acceptTerms', false);
    setRequireTermsField({
      required: currentOrg[0]?.termsOfUseRequired || false,
      tooltip: (currentOrg[0]?.tooltip as string) || '',
    });
  }, [values.organizationID, organizations, setFieldValue]);

  useEffect(() => {
    if (membersOnly) {
      setExistingMember(true);
    }
  }, [membersOnly]);

  useEffect(() => {
    setOrgRequestSelectedId(values.organizationID as string);
  }, [values.organizationID, setOrgRequestSelectedId]);

  const onExistingMemberChange = () => {
    if (!membersOnly) {
      setExistingMember(!existingMember);
    }
  };

  useEffect(() => {
    values.existingMember = existingMember ? 'true' : 'false';
  }, [existingMember, values]);

  const isSubmitDisabled = () => {
    if (requireTermsField.required) {
      return isSubmitting || !isValid || !values.organizationID || !values.acceptTerms
    }
    return isSubmitting || !isValid || !values.organizationID
  }

  const renderFormFields = () => {
    if (selectedOrgDataLoading) {
      return <Row>
        <LoadingSpinner />
      </Row>
    }
    return (
      <>
        {!membersOnly && values.organizationID && (
          <Row className="mb-3">
            <Col lg={12}>
              <div className="align-items-center d-flex">
                <label className="text-muted font-weight-bold form-label mr-2">
                  <FormattedMessage id="requestOrganizationAccess.existingMember" defaultMessage="Existing member?" />
                </label>
                <Toggle
                  checked={membersOnly ? true : existingMember}
                  onChange={onExistingMemberChange}
                  isCustomDisabledState={membersOnly}
                />
                {membersOnly && (
                  <div className={'ml-1'}>
                    <div className={styles.requestsTooltipContainer}>
                      <Tooltip placement="bottom" testId="organization-template-tooltip">
                        {ORGANIZATION_MEMBER_ONLY_MESSAGE}
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
        {isAdmin && teamRequestsAllowed && (
          <Row>
            <Col lg={12}>
              <MembershipTypeField
                id="requestMembership.membershipType"
                name="membershipType"
                onChange={onMembershipTypeChange()}
                label={
                  <>
                    <FormattedMessage
                      id={RequestMembershipType.id}
                      defaultMessage={RequestMembershipType.defaultMessage}
                    />
                    <Tooltip placement="right" testId="insert-signers-tooltip">
                      {MEMBERSHIP_TYPE_MESSAGE}
                    </Tooltip>
                  </>
                }
                required
                placeholder="Select"
              />
            </Col>
          </Row>
        )}
        {existingMember && (
          <Row>
            <Col lg={12}>
              <TextField
                //@ts-ignore TODO update TextField to ts
                id="requestMembership.membershipId"
                name="membershipId"
                type="text"
                disabled={values.membershipType === OrgatizationMembership.team}
                label={
                  <div className="align-items-center">
                    <FormattedMessage id="requestOrganizationAccess.membershipId" defaultMessage="Membership ID" />
                    {requireTermsField.tooltip !== '' && (
                      <ClickableTooltip content={parse(sanitizeHtml(requireTermsField.tooltip))}>
                        <span className={styles.membershipIdTooltip}>(what's this?)</span>
                      </ClickableTooltip>
                    )}
                  </div>
                }
              />
            </Col>
          </Row>
        )}
        {requireTermsField.required && (
          <Row>
            <Col lg={12}>
              <CheckboxField
                id="registerForm.acceptTerms"
                // defaultValue={false}
                name="acceptTerms"
                className="body-text accept-terms"
                label={
                  <>
                    <FormattedMessage
                      id="form.accept-terms.label"
                      defaultMessage="Agree to <a>Terms of use</a>"
                      values={{
                        a: (msg: string) => (
                          <Button
                            variant="link"
                            className="p-0 align-baseline text-decoration-none"
                            onClick={() => setShowTermsOfUseModal(true)}
                          >
                            {msg}
                          </Button>
                        ),
                      }}
                    />
                  </>
                }
              />
            </Col>
          </Row>
        )}
      </>
    )
  }

  return (
    <Form noValidate>
      <div className={styles.modalBody}>
        <BodyContainer>
          {errors._general && (
            <Alert variant="danger">
              <ErrorMessage>{errors._general}</ErrorMessage>
            </Alert>
          )}

          <Row>
            <Col lg={12}>
              <SelectOrganizationField
                id="requestMembership.name"
                name="organizationID"
                label={
                  <FormattedMessage
                    id={RequestMembershipOrganization.id}
                    defaultMessage={RequestMembershipOrganization.defaultMessage}
                  />
                }
                required
                onChange={onOrganizationIdChange}
                organizations={organizations}
                placeholder="Select organization..."
              />
            </Col>
          </Row>
          {renderFormFields()}
        </BodyContainer>
      </div>
      <div className={styles.buttonsContainer__addDeal}>
        <ActionsContainer>
          {renderSecondaryAction && renderSecondaryAction()}
          <SubmitButton
            label={
              <FormattedMessage
                id="id.create-deal"
                defaultMessage={
                  existingMember || membersOnly || !values.organizationID ? 'Request Access' : 'Purchase Access'
                }
              />
            }
            disabled={isSubmitDisabled()}
            isSubmitting={isSubmitting}
          />
        </ActionsContainer>
      </div>
    </Form>
  );
};

const RequestAccessFormik = ({
  bodyContainer,
  actionsContainer,
  renderSecondaryAction,
  isAdmin,
  onSuccess,
  userId,
  setShowTermsOfUseModal,
  setOrgRequestSelectedId,
  orgRequestSelectedId,
}: RequestAccessFormProps) => {
  const schema = (bypassTermsOfUse: boolean, teamRequestsAllowed: boolean) =>
    yup.object().shape({
      teamId: yup.string().required(),
      organizationID: yup
        .string()
        .trim()
        .required(),
      existingMember: yup.string().optional(),
      acceptTerms: yup.boolean().test('acceptTerms', 'Acceptance of terms is required', function(value) {
        return bypassTermsOfUse || (!bypassTermsOfUse && value === true);
      }),
      membershipType: yup.string().test('membershipType', 'MembershipType is required', value => {
        return (teamRequestsAllowed && value && value !== '') || !teamRequestsAllowed;
      }),
      membershipId: yup
        .string()
        .when(['membershipType', 'existingMember'], ([membershipType, existingMember], field) =>
         (membershipType === 'User' && existingMember === 'true') || (!teamRequestsAllowed && existingMember === 'true')
            ? field.trim().required()
            : field.trim()
        ),
    });
  const { loading: teamInfoLoading, teamId } = useTeamInfo();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();
  const [createOrganizationRequest] = useMutation(mutations.createOrganizationRequest, {
    refetchQueries: [
      {
        query: organizationsAccessByUser,
      },
    ],
  });

  const {
    data: organizationsData,
    loading: isOrganizationsDataLoading,
  }: { data: GetOrganizationsQuery | undefined; loading: boolean } = useQuery(queries.getOrganizations as DocumentNode);

  const availableOrganizations = organizationsData?.organizations?.filter(org => org.adminTeam.id !== teamId) || [];

  const [currentOrg, setCurrentOrg] = useState<Partial<Organization> | undefined>();

  const { data: selectedOrgData, loading: selectedOrgDataLoading} = useQuery(queries.getOrganizationById, {
    variables: { organizationId: orgRequestSelectedId },
    skip: !orgRequestSelectedId,
  });

  useEffect(() => {
    if (orgRequestSelectedId && !selectedOrgDataLoading) {
      setCurrentOrg(selectedOrgData?.getOrganizationById as Organization);
    }
  }, [availableOrganizations, orgRequestSelectedId, selectedOrgData?.getOrganizationById, selectedOrgDataLoading]);

  const onSubmit = useCallback(
    async (values: any) => {
      if (values.existingMember === 'false') {
        if (!currentOrg?.paymentLinkUrl || !currentOrg) {
          return;
        }

        window.location.href = currentOrg.paymentLinkUrl;
        onSuccess?.();
      } else {
        const teamRequestsAllowed = !!currentOrg?.teamRequestsAllowed;
        const requesterType: string = teamRequestsAllowed ? values.membershipType : 'User';
        try {
          const variables: CreateOrganizationVariables = {
            organizationId: values.organizationID,
            requesterId: values.membershipType === OrgatizationMembership.team ? teamId : userId,
            requesterType: requesterType,
          };

          if (requesterType === 'User') {
            variables.membershipId = values.membershipId;
          }

          await createOrganizationRequest({
            variables,
          }).then(() => {
            notifySuccess({
              message: {
                id: 'org-request-success-toast',
                defaultMessage: 'Request sent! You will be notified via email once it has been reviewed',
              },
            });
          });
          onSuccess?.();
        } catch (error) {
          if (error instanceof Error) {
            notifyError({ message: error.message });
          }
        }
      }
    },
    [
      onSuccess,
      teamId,
      userId,
      createOrganizationRequest,
      notifyError,
      notifySuccess,
      currentOrg?.teamRequestsAllowed,
      availableOrganizations,
    ]
  );

  if (teamInfoLoading || isOrganizationsDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      validationSchema={schema(!currentOrg?.termsOfUseRequired, !!currentOrg?.teamRequestsAllowed)}
      onSubmit={onSubmit}
      validateOnChange
      validateOnBlur
      initialValues={{
        teamId,
        organizationID: '',
        membershipType: isAdmin ? '' : 'User',
        membershipId: '',
        acceptTerms: false,
      }}
    >
      {formikProps => (
        <RequestAccessForm
          {...formikProps}
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
          isAdmin={isAdmin}
          userId={userId}
          organizations={availableOrganizations}
          setShowTermsOfUseModal={setShowTermsOfUseModal}
          setOrgRequestSelectedId={setOrgRequestSelectedId}
          orgRequestSelectedId={orgRequestSelectedId}
          membersOnly={!!currentOrg?.membersOnly}
          teamRequestsAllowed={!!currentOrg?.teamRequestsAllowed}
          selectedOrgDataLoading={selectedOrgDataLoading}
        />
      )}
    </Formik>
  );
};

const AddOrganizationModal = ({
  showAddOrganizationModal,
  onModalHide,
  isAdmin,
  userId,
  organizationsAccessByUserData,
  setShowTermsOfUseModal,
  setOrgRequestSelectedId,
  orgRequestSelectedId,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal show={showAddOrganizationModal} onHide={onModalHide} size="sm">
      <div className={styles.addOrganizationModal}>
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title className="mb-2">
            <header>
              <FormattedMessage id="title.add-team-member" defaultMessage="Add Organization" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <RequestAccessFormik
          // @ts-ignore
          bodyContainer={Modal.Body}
          // @ts-ignore
          actionsContainer={Modal.Footer}
          renderSecondaryAction={() => (
            <Button variant="link" className="text-secondary" onClick={onModalHide}>
              <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
            </Button>
          )}
          isAdmin={isAdmin}
          onSuccess={onModalHide}
          userId={userId}
          organizationsAccessByUserData={organizationsAccessByUserData}
          setShowTermsOfUseModal={setShowTermsOfUseModal}
          setOrgRequestSelectedId={setOrgRequestSelectedId}
          orgRequestSelectedId={orgRequestSelectedId}
        />
      </div>
    </Modal>
  );
};

export default AddOrganizationModal;
