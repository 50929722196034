import React, { useCallback } from 'react';
import SignersSidebar from './signersSidebar';
import { nanoid } from 'nanoid';
import update from 'immutability-helper';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { mutations } from '../../../../../api';
import { useSelector } from 'react-redux';
import {
  selectLeaseDocumentId,
  selectLeaseDocumentStatus,
} from '../../../../../store/lease-document/selectors/lease-document.selectors';
import { orderBy } from 'lodash';
import LoadingSpinner from '../../../../loading-spinner';
import { useNotifications } from '../../../../../notifications';
import { getDocumentRecipients, getDocumentSigners } from '../../../../../api/queries_v2';
import { useDocumentRole } from '../../../../../shared/hooks/useDocumentRole/useDocumentRole';
import { DocumentPermissions } from '../../../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { DocumentStatusEnum } from '../../../../../shared/constants/leaseDocument';
import { updateDocumentCopyRecipientsList } from '../../../../../utils/api/update-document-copy-recipients-list/update-document-copy-recipients-list';

export interface Signer {
  id: string;
  order: number;
  email: string;
}

interface SignerSidebarContainerProps {
  permissions: DocumentPermissions;
  addField?: (...args: any[]) => void;
  updateAnnotations?: (...args: any[]) => void;
  deleteAnnotations?: (signerId: string) => void;
  shouldShowInsertionUi?: boolean;
  isDocumentLocked?: boolean;
  viewer?: HTMLDivElement | null;
}

const SignersSidebarContainer = ({
  permissions,
  addField,
  updateAnnotations,
  deleteAnnotations,
  shouldShowInsertionUi = false,
  isDocumentLocked = false,
  viewer,
}: SignerSidebarContainerProps) => {
  const documentId = useSelector(selectLeaseDocumentId);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const apolloClient = useApolloClient();
  const [, { error: notifyError }] = useNotifications();
  const { isDocumentCreator, isDocumentRoleLoading } = useDocumentRole(documentId);
  const { data, loading: isSignersLoading, error } = useQuery(getDocumentSigners, {
    variables: { documentId: documentId! },
    skip: !documentId,
  });
  const { data: recipientsData, loading: isRecipientsDataLoading, error: recipientsDataError } = useQuery(
    getDocumentRecipients,
    {
      variables: { documentId: documentId! },
      skip: !documentId,
    }
  );
  const [createSigner, { loading: isCreatingSigner }] = useMutation(mutations.createDocumentSigner, {
    refetchQueries: [getDocumentSigners],
    update: (cache, { data }) => {
      const createSigner = data!.createSigner;
      cache.modify({
        fields: {
          getDocumentById(documentRef) {
            const newSignerRef = cache.writeFragment({
              data: createSigner,
              fragment: gql`
                fragment NewSigner on Signer {
                  id
                  email
                  order
                }
              `,
            });

            cache.modify({
              id: documentRef.__ref,
              fields: {
                signers(signers = []) {
                  return [...signers, newSignerRef];
                },
              },
            });

            return documentRef;
          },
        },
      });
    },
  });
  const [updateSigner, { loading: isUpdatingSigner }] = useMutation(mutations.updateDocumentSigner);
  const [deleteSigner, { loading: isDeletingSigner }] = useMutation(mutations.deleteDocumentSigner, {
    update: (cache, { data }) => {
      const deleteSigner = data!.deleteSigner;
      cache.modify({
        fields: {
          getDocumentById(documentRef) {
            cache.modify({
              id: documentRef.__ref,
              fields: {
                signers(signersRefs = [], { readField }) {
                  const UpdatedSignerFragment = gql`
                    fragment UpdatedSigner on Signer {
                      order
                    }
                  `;

                  return signersRefs.reduce((acc: any[], ref: any) => {
                    if (readField('id', ref) === deleteSigner!.id) {
                      cache.evict({
                        id: cache.identify(ref),
                      });
                      return acc;
                    }

                    const refOrder: number = readField('order', ref) as number;
                    cache.writeFragment({
                      id: cache.identify(ref),
                      fragment: UpdatedSignerFragment,
                      data: {
                        order: refOrder > deleteSigner!.order ? refOrder - 1 : refOrder,
                      },
                    });

                    return [...acc, ref];
                  }, []);
                },
              },
            });

            return documentRef;
          },
        },
      });
    },
  });

  const [bulkUpdateSigners] = useMutation(mutations.bulkUpdateSigners);
  const isDraftOrReviewing = [
    DocumentStatusEnum.DRAFT,
    DocumentStatusEnum.REVIEWING,
    DocumentStatusEnum.PREPARING_TO_SIGN,
  ].includes(documentStatus as DocumentStatusEnum);
  const isUpdatingDisabled = !isDocumentCreator || !isDraftOrReviewing || !permissions.hasEditPermission;

  const signers = data?.getDocumentById.signers || [];
  const recipients = recipientsData?.getDocumentById.ccRecipients || [];

  const handleAddSigner = useCallback(
    async (email: string) => {
      try {
        if (isUpdatingDisabled) return;

        await createSigner({
          variables: {
            documentId: documentId!,
            email: email.trim(),
            order: signers.length,
          },
          optimisticResponse: {
            createSigner: {
              __typename: 'Signer',
              id: nanoid(),
              order: signers.length,
              email,
              /*  embeddedSigningUrl: null,*/
              /*   signedAt: null,*/
            },
          },
        });
      } catch (err) {
        notifyError({ message: 'Error creating signer' });
      }
    },
    [createSigner, documentId, isUpdatingDisabled, notifyError, signers.length]
  );

  const handleUpdateRecipientsList = useCallback(
    async (recipients: string[]) => {
      try {
        await updateDocumentCopyRecipientsList({ documentId: documentId!, recipients })(apolloClient);
      } catch (err) {
        notifyError({ message: 'Error updating recepients list' });
      }
    },
    [notifyError, apolloClient, documentId]
  );
  const handleDeleteSigner = useCallback(
    async (signerId: string, signerOrder: number) => {
      try {
        if (isUpdatingDisabled) return;

        await deleteSigner({
          variables: { signerId },
          optimisticResponse: {
            deleteSigner: {
              id: signerId,
              order: signerOrder,
              __typename: 'Signer',
            },
          },
        });

        if (deleteAnnotations) {
          deleteAnnotations(signerId);
        }
      } catch (err) {
        notifyError({ message: 'Error deleting signer' });
      }
    },
    [deleteAnnotations, deleteSigner, isUpdatingDisabled, notifyError]
  );

  const handleMoveItem = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      try {
        if (isUpdatingDisabled) return;

        const orderedByOrderNumberSigners = orderBy(signers, 'order');
        const updatedSigners = update(orderedByOrderNumberSigners, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, orderedByOrderNumberSigners[dragIndex] as Signer],
          ],
        }).map((item: any, index) => ({ ...item, order: index }));

        await bulkUpdateSigners({
          variables: {
            documentId: documentId!,
            signers: updatedSigners.map(({ __typename, signedAt, ...rest }: any) => ({ ...rest })),
          },
          optimisticResponse: {
            bulkUpdateSigners: updatedSigners,
          },
          update: cache => {
            cache.writeQuery({
              query: getDocumentSigners,
              variables: { documentId: documentId! },
              data: { getDocumentById: { ...data!.getDocumentById, signers: updatedSigners } },
            });
          },
        });
      } catch (err) {
        notifyError({ message: 'Error moving signer' });
      }
    },
    [bulkUpdateSigners, documentId, isUpdatingDisabled, notifyError, signers] // eslint-disable-line
  );

  const handleUpdateSigner = useCallback(
    async (signerId: string, email: string, order: number) => {
      try {
        if (isUpdatingDisabled) return;

        await updateSigner({
          variables: { signerId, email },
        });

        if (updateAnnotations) {
          updateAnnotations(signerId, email);
        }
      } catch {
        notifyError({ message: 'Update signer error' });
      }
    },
    [isUpdatingDisabled, notifyError, updateAnnotations, updateSigner]
  );

  if (error) {
    notifyError('Could not load signers');
  }

  if (recipientsDataError) {
    notifyError('Could not load recipients');
  }

  if (isSignersLoading || isDocumentRoleLoading || isRecipientsDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <SignersSidebar
      addSigner={handleAddSigner}
      updateRecepientsList={handleUpdateRecipientsList}
      signers={signers}
      recipients={recipients}
      moveItem={handleMoveItem}
      updateSigner={handleUpdateSigner}
      deleteSigner={handleDeleteSigner}
      isCreatingSigner={isCreatingSigner}
      isDeletingSigner={isDeletingSigner}
      isUpdatingSigner={isUpdatingSigner}
      isDisabled={isUpdatingDisabled || isDocumentLocked}
      addField={addField}
      shouldShowInsertionUi={shouldShowInsertionUi}
      viewer={viewer}
    />
  );
};

export default SignersSidebarContainer;
