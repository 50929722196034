import { TemplateType } from '../../consts/forms-types';
import Controls from '../../pages/templates/Controls';
import { formatDate } from '../../utils/date';
import Tooltip from '../tooltip';
import styles from './styles.module.scss';

export interface TemplateData {
  name: string;
  status: string;
  updatedAt: string;
  template: {
    id: string;
    fromOrganization: boolean | null;
    organizationName?: string | null;
  };
}

interface MobileTemplateItemProps {
  template: TemplateData;
  isAdmin: boolean;
  canEdit: boolean;
  teamId: string;
  selectedTemplate: any;
  templateIsUpdated: () => void;
  onClick?: () => void;
}

export const MobileTemplateItem: React.FC<MobileTemplateItemProps> = ({
  template,
  isAdmin,
  canEdit,
  teamId,
  templateIsUpdated,
  selectedTemplate,
  onClick,
}) => {
  return (
    <div className={styles.dealItem} onClick={onClick}>
      <div className={styles.dealItem__header}>
        <div className={styles.dealItem__title}>{template.name}</div>

        <div
          onClick={evt => {
            evt.stopPropagation();
          }}
          className={styles.actionsTableColumn}
        >
          {!template.template.fromOrganization ? (
            <Controls
              status={template.status || ''}
              editPermission={isAdmin || canEdit}
              selectedTemplateData={selectedTemplate}
              teamId={teamId}
              onSuccess={templateIsUpdated}
              templateType={TemplateType.DOCUMENT}
            />
          ) : (
            <div className={styles.templatesTooltipContainer}>
              <Tooltip placement="bottom" testId="organization-template-tooltip">
                This Template is available due to your active membership in {template.template.organizationName}, it
                cannot be edited.
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className={styles.dealItem__footer}>
        <div className={styles.dealItem__creator}>{template.status}</div>
        <div className={styles.dealItem__updatedAt}>{formatDate(template.updatedAt)}</div>
      </div>
    </div>
  );
};
