import React, { ReactNode } from 'react';
import classNames from 'classnames';
import SkipLink from '../skip-link';
import Header from './header';
import Nav from './nav';
import styles from './styles.module.scss';

interface PublicLayoutProps {
  children: ReactNode;
  mainContentClassName?: string;
  confirmationToken?: string;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children, mainContentClassName = '', confirmationToken }) => (
  <div className={styles.root}>
    <SkipLink href="#main-content" />
    <div className={classNames(styles.left, 'p-5')}>
      <Header />
    </div>
    <div className={classNames(styles.right, 'p-5')}>
      <div className={styles.mobileHeader}>
        <Header />
      </div>
      {!confirmationToken ? <Nav /> : <div className="mt-4" />}
      <main id="main-content" className={classNames(styles.main, mainContentClassName)}>
        {children}
      </main>
    </div>
  </div>
);

export default PublicLayout;
