import { DEFAULT_AVATAR_SIZE } from '../constants';

export const getGravatarUrl = (emailHash: string, size = DEFAULT_AVATAR_SIZE) => {
  return `https://www.gravatar.com/avatar/${emailHash}?d=blank&s=${size}`;
};

export const getInitials = (fullName: string, email: string) => {
  return (fullName?.length ? fullName : email || '')
    .trim()
    .split(/\s+/)
    .slice(0, 2)
    .map(s => s.substring(0, 1))
    .join('');
};

export const getBackgroundColor = (emailHash: string) => {
  const hue = parseInt(emailHash, 16) / 0xffffffffffffffffffffffffffffffff;
  return `hsl(${hue}turn, 40%, 75%)`;
};

export const getFontSize = (fullName: string, email: string) => {
  const initials = getInitials(fullName, email);
  return `${Math.round(100 * (2 / Math.max(2, initials.length)))}%`;
};
