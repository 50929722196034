import React, { ChangeEvent, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as EditIcon } from '../../../../../../../shared/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../shared/icons/trashcan-icon.svg';
import { ReactComponent as DragIcon } from '../../../../../../../shared/icons/drag.svg';
import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import classNames from 'classnames';
import { FormControl, InputGroup } from 'react-bootstrap';
import { ReactComponent as ApproveIcon } from '../../../../../../../shared/icons/checkmark-icon.cc.svg';
import { ReactComponent as RejectIcon } from '../../../../../../../shared/icons/reject.svg';
import * as yup from 'yup';
import { EMAIL_VALIDATION_REGEX } from '../../../../../../../shared/constants/emailValidation';
import { SIGNER_ITEM_BUTTON_NAME } from './constants';

interface SignerItemProps {
  id: string;
  order: number;
  email: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  updateSigner: (id: string, email: string, order: number) => Promise<void>;
  isDeletingSigner: boolean;
  deleteSigner: (signerId: string, signerOrder: number) => Promise<void>;
  isUpdatingSigner: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const emailValidationSchema = yup
  .string()
  .required()
  .matches(EMAIL_VALIDATION_REGEX, 'Please provide correct email');

const SignerItem = ({
  id,
  index,
  email,
  moveCard,
  order,
  updateSigner,
  isDeletingSigner,
  deleteSigner,
  isUpdatingSigner,
}: SignerItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [emailValue, setEmailValue] = useState(email);
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'signer',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'signer',
    item: () => {
      return { id, email, index, order };
    },
    collect: (monitor: { isDragging: (...args: any[]) => boolean }) => {
      return { isDragging: monitor.isDragging() };
    },
  });

  drag(drop(ref));

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles['wrapper-dragging']]: isDragging,
        [styles['wrapper-deleting']]: isDeleting,
      })}
      ref={ref}
      data-email={email}
      data-id={id}
      data-order={order}
    >
      <div className={classNames(styles['content'], { [styles['content-deleting']]: isDeleting })}>
        <span className={styles.order}>{order + 1}</span>
        <span className={styles['drag-handle']}>
          <DragIcon className={styles['icon-drag']} />
        </span>
        {!isEditing && <span className={styles['email-text']}>{email}</span>}
        {isEditing && (
          <InputGroup>
            <FormControl
              type="text"
              value={emailValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailValue(e.currentTarget.value)}
              autoFocus
              isInvalid={isEmailValid === false}
            />
          </InputGroup>
        )}
        <span className={styles.controls}>
          <button
            className={classNames(styles.button, { 'd-none': isEditing })}
            onClick={e => {
              Object.defineProperty(e, 'hello', { value: 'world' });
              setIsEditing(true);
            }}
            disabled={isDeletingSigner}
            name={SIGNER_ITEM_BUTTON_NAME}
          >
            <EditIcon className={styles['icon-edit']} />
          </button>
          <button
            className={classNames(styles.button, { 'd-none': !isEditing })}
            name={SIGNER_ITEM_BUTTON_NAME}
            onClick={async () => {
              const isValid = await emailValidationSchema.isValid(emailValue);
              setIsEmailValid(isValid);
              if (!isValid) return;
              await updateSigner(id, emailValue, order);
              setIsEditing(false);
              setIsEmailValid(null);
            }}
            disabled={isDeletingSigner || isUpdatingSigner}
          >
            <ApproveIcon className={styles['icon-approve']} />
          </button>
          <button
            className={styles.button}
            name={SIGNER_ITEM_BUTTON_NAME}
            disabled={isEditing || isDeletingSigner || isUpdatingSigner}
            onClick={e => {
              setIsDeleting(true);
            }}
          >
            <TrashIcon className={styles['icon-delete']} />
          </button>
        </span>
      </div>
      <div className={classNames(styles['delete'], { 'd-none': !isDeleting })}>
        <span className={styles['delete-title']}>Delete signer?</span>
        <span className={styles['delete-buttons']}>
          <button
            onClick={async e => {
              await deleteSigner(id, order);
            }}
            disabled={isDeletingSigner}
            className={styles.button}
            name={SIGNER_ITEM_BUTTON_NAME}
          >
            <ApproveIcon className={styles['icon-approve']} />
          </button>
          <button
            className={styles.button}
            disabled={isDeletingSigner}
            onClick={e => {
              setIsDeleting(false);
            }}
            name={SIGNER_ITEM_BUTTON_NAME}
          >
            <RejectIcon className={styles['icon-reject-delete']} />
          </button>
        </span>
      </div>
    </div>
  );
};

export default SignerItem;
