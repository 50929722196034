import React, { useState, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { queries, usePartyIds } from '../../api';
import { getTeamById } from '../../api/queries';
import { useAuth } from '../../auth';
import { useUserRestrictions } from '../../auth/user-restrictions';
import { A, paths } from '../../routing';
import { useTeamInfo } from '../../team-id-context';
import { get as getLatestTeamId, set } from '../../utils/latest-team-id';
import CreateNewTeamModal from '../shared/modals/createNewTeamModal/CreateNewTeamModal';
import { ReactComponent as WorkspacesIcon } from '../../shared/icons/workspaces_icon.svg';
import { ReactComponent as ChevronDownIcon } from '../../shared/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../shared/icons/chevron-up.svg';
import { ReactComponent as CheckIcon } from '../../shared/icons/checkmark-icon.cc.svg';
import { SubscriptionRequiredModal } from '../../pages';
import styles from '../private-layout/styles.module.scss';

const CurrentWorkspaceIndicatorMobile = () => {
  const [{ authLoading: isAuthLoading, user }] = useAuth();
  const { canCreateTeam } = useUserRestrictions();
  const teamId = useTeamInfo().teamId ?? getLatestTeamId(user.id);
  const [showAddNewTeamModal, setShowAddNewTeamModal] = useState(false);
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(teamId);
  const [refetchUserRestrictions] = useLazyQuery(queries.getRestrictionsByCurrentUser, {
    fetchPolicy: 'network-only',
    variables: {
      teamId,
    },
  });

  const { loading: isPartyIdsLoading, partyIds } = usePartyIds();
  const { loading: isTeamLoading, data: teamData } = useQuery(getTeamById, {
    skip: !teamId,
    variables: { teamId },
  });

  const onAddTeamClick = () => (canCreateTeam ? setShowAddNewTeamModal(true) : setSubscriptionRequiredModal(true));

  const loading = isAuthLoading || isPartyIdsLoading || isTeamLoading;

  const [menuOpen, setMenuOpen] = useState(false);

  const userTeams = user.allTeams;
  const locationPaths: { [key: string]: any } = {
    deals: paths.deals.landing,
    templates: paths.templates.landing,
    account: paths.account,
    organizations: paths.settings.organizations,
    requests: paths.settings.requests,
    team: paths.team,
  };

  const userLocationToRedirect = () => {
    const currentPath = window.location.pathname;

    for (const [key, path] of Object.entries(locationPaths)) {
      if (currentPath.includes(key)) {
        return path;
      }
    }

    return paths.team;
  };

  const handleOrgChange = (id: string) => {
    set(user.id, id);
    setSelectedWorkspaceId(id);
    refetchUserRestrictions();
  };

  return (
    <>
      <div className={styles.mobileWorkspace}>
        <div className={styles.mobileWorkspace__toggle} onClick={() => setMenuOpen(!menuOpen)}>
          <div className="d-flex align-items-center">
            <span>
              <WorkspacesIcon height="30" className={styles.logoIcon} />
            </span>
            {!loading && partyIds != null && (
              <div className="ml-3">
                <div className="small text-muted">Workspace</div>
                <div className={styles.mobileWorkspace__name}>{teamData?.teamById.name}</div>
              </div>
            )}
          </div>
          <div>
            {!menuOpen ? (
              <ChevronDownIcon width="16" height="16" className={styles.logoIcon} />
            ) : (
              <ChevronUpIcon width="16" height="16" className={styles.logoIcon} />
            )}
          </div>
        </div>
        {menuOpen && (
          <div className={styles.mobileWorkspace__dropdown}>
            {teamData && (
              <>
                {userTeams.map(({ id, name }: { id: string; name: string }) =>
                  !window.location.pathname.includes('account') ? (
                    <A
                      key={id}
                      href={userLocationToRedirect()}
                      hrefParams={{ teamId: id }}
                      className={classNames(
                        styles.mobileWorkspace__option,
                        id === teamId ? styles.mobileWorkspace__option__active : ''
                      )}
                    >
                      <div className={styles.mobileWorkspace__option__name}>{name}</div>
                      {id === teamId && <CheckIcon width="16" height="16" className={styles.logoIcon} />}
                    </A>
                  ) : (
                    <div
                      key={id}
                      onClick={() => handleOrgChange(id)}
                      className={classNames(
                        styles.mobileWorkspace__option,
                        id === selectedWorkspaceId ? styles.mobileWorkspace__option__active : ''
                      )}
                    >
                      <div className={styles.mobileWorkspace__option__name}>{name}</div>
                      {id === selectedWorkspaceId && <CheckIcon width="16" height="16" className={styles.logoIcon} />}
                    </div>
                  )
                )}
                <button key="addNEwTeam.button" className={styles.mobileWorkspace__new} onClick={onAddTeamClick}>
                  + New Workspace
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <CreateNewTeamModal show={showAddNewTeamModal} handleHide={() => setShowAddNewTeamModal(false)} />
      <SubscriptionRequiredModal
        isOpen={showSubscriptionRequiredModal}
        onClose={() => setSubscriptionRequiredModal(false)}
      />
    </>
  );
};

export default CurrentWorkspaceIndicatorMobile;
