import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client';
import WebViewer, { Core } from '@pdftron/webviewer';
import { useSelector } from 'react-redux';
import { CustomAnnotationPopup } from '../../signing/components';
import { disableFormFieldsInDocument, disableUiFeatures, fixPdfDocumentRotation, reorderPdfPages } from '../../signing/helpers';
import { getExtensionByContentType } from './helpers';
import { ImportedDocumentSupportedContentTypes } from '../../../components/shared/modals/fileUploadModal/types';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import IdleTimer from 'react-idle-timer';
import { IDLE_TIMER_TIMEOUT_MILLISECONDS } from '../../../components/lease-wizard/lease-editor/LeaseEditor';
import TimeoutModal from '../../../components/lease-wizard/lease-document-wizard-modal/custom-modals/timeoutModal/TimeoutModal';
import styles from '../../signing/styles.module.scss';
import { setNecessaryHeaderItems } from '../helpers/headerToobarHandlers';
import { AnnotationType } from '../helpers/constants';
import { selectIsReceiverCollaborationEnabled } from '../../../store/lease-document/selectors/lease-document.selectors';

import {
  ReplaceImportedDocumentMutation,
  ReplaceImportedDocumentMutationVariables
} from '../../../gql/graphql';
import { useTeamInfo } from "../../../team-id-context";
import { mutations } from "../../../api";
import { useAuth } from '../../../auth';

interface Props {
  isDocumentCreator: boolean;
  document: {
    url: string;
    contentType: ImportedDocumentSupportedContentTypes;
    status: string;
    id: string;
    title: string;
  };
  hasUserEditPermission: boolean;
  hasUserCommentPermission: boolean;
  saveAnnotations: (options: MutationFunctionOptions) => Promise<any>;
  saveFormFields: (options: MutationFunctionOptions) => Promise<any>;
  saveSignaturesLayer: (options: MutationFunctionOptions) => Promise<any>;
  toggleReceiverCollaborationForDocument?: (options: MutationFunctionOptions) => Promise<FetchResult<boolean>>;
  annotationsStringUrl: string | null;
  formFieldsUrl: string | null;
  userData: { id: string | number; fullName: string };
  isDocumentLocked: boolean;
  navigateToDealDetails: () => void;
  isDocumentEditable?: boolean;
  isDocumentInReview?: boolean;
}

const UploadedDocumentsViewer = ({
  isDocumentCreator,
  document: viewerDocument,
  hasUserEditPermission,
  hasUserCommentPermission,
  saveAnnotations,
  saveFormFields,
  saveSignaturesLayer,
  annotationsStringUrl,
  formFieldsUrl,
  userData,
  isDocumentLocked,
  navigateToDealDetails,
  isDocumentEditable,
  toggleReceiverCollaborationForDocument,
  isDocumentInReview,
}: Props) => {
  const [{ user }] = useAuth();
  const viewer = useRef<HTMLDivElement>(null);
  const idleTimerRef = useRef<IdleTimer>(null);
  const currentUserName = `${userData.fullName}:id${userData.id}`;
  const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false);
  const [customSettingsApplied, setCustomSettingsApplied] = useState<boolean>(false);
  const isReceiverCollaborationEnabled = useSelector(selectIsReceiverCollaborationEnabled);
  const { teamId } = useTeamInfo();
  const [replaceImportDocument] = useMutation<
    ReplaceImportedDocumentMutation,
    ReplaceImportedDocumentMutationVariables
  >(mutations.replaceImportDocument);

  const isSigningProcessStarted = [DocumentStatusEnum.PREPARING_TO_SIGN, DocumentStatusEnum.SIGNING].includes(
    viewerDocument.status as DocumentStatusEnum
  );

  const isDocumentExecuted = [DocumentStatusEnum.EXECUTED].includes(viewerDocument.status as DocumentStatusEnum);

  const isDocumentSigning = [DocumentStatusEnum.SIGNING].includes(viewerDocument.status as DocumentStatusEnum);

  const isDocumentBeforePreparingToSign = [
    DocumentStatusEnum.DRAFT, 
    DocumentStatusEnum.REVIEWING, 
    DocumentStatusEnum.UPLOADED, 
    DocumentStatusEnum.SHARED
  ].includes(viewerDocument.status as DocumentStatusEnum);

  const isUserPremium = user.subscriptionAmount > 0;

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer',
        initialDoc: viewerDocument.url,
        licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
        extension: getExtensionByContentType(viewerDocument.contentType),
        fullAPI: true,
        annotationUser: userData.id.toString(),
        filename: viewerDocument.title,
        css: `${process.env.PUBLIC_URL}/apryseStyles.css`,
      },
      viewer.current as HTMLDivElement
    ).then(async instance => {

      instance.UI.disableElements(['settingsButton']);

      const {
        Core: { documentViewer, Annotations, DisplayMode, DisplayModes },
      } = instance;
      const annotationManager = documentViewer.getAnnotationManager();

      const shouldAnnotsReadOnlyBeEnabled =
        (!isDocumentCreator && !isReceiverCollaborationEnabled) ||
        !hasUserEditPermission ||
        isSigningProcessStarted ||
        !isDocumentEditable ||
        isDocumentExecuted;

      if (!hasUserCommentPermission || isDocumentLocked || isSigningProcessStarted || isDocumentExecuted) {
        annotationManager.enableReadOnlyMode();
      }

      if (!hasUserCommentPermission || isSigningProcessStarted || isDocumentExecuted) {
        instance.UI.disableReplyForAnnotations(() => true);
      }

      instance.UI.setAnnotationContentOverlayHandler(annotation => {
        if (annotation.ToolName !== 'AnnotationCreateSticky') return <CustomAnnotationPopup annotation={annotation} />;
      });

      instance.UI.setAnnotationContentOverlayHandler(annotation => {
        if (annotation.Subject === AnnotationType.STRIKEOUT || annotation.Subject === AnnotationType.FREE_TEXT) {
          return;
        }
      });

      annotationManager.setCurrentUser(currentUserName);

      annotationManager.setAnnotationDisplayAuthorMap(userId => {
        const userName = userId?.split(':id');
        return userName?.[0];
      });

      annotationManager.setPermissionCheckCallback((author, annotation) => {
        if (annotation.Subject === AnnotationType.STRIKEOUT || annotation.Subject === AnnotationType.FREE_TEXT) {
          return hasUserEditPermission && isDocumentCreator;
        }

        if (annotation.Subject === 'Note' && author) {
          const authorData = author.split(':id');
          const authorId = authorData[authorData?.length - 1];

          return authorId === userData.id;
        }

        return true;
      });

      annotationManager.disableRedaction();
      annotationManager.disableDraggingAcrossPages();

      setNecessaryHeaderItems({
        instance,
        isSigningProcessStarted,
        isDocumentExecuted,
        isDocumentCreator,
        hasUserEditPermission,
        hasUserCommentPermission,
        documentId: viewerDocument.id,
        saveFormFields,
        toggleReceiverCollaborationForDocument,
        isReceiverCollaborationEnabled,
        isDocumentInReview,
      });

      const allowReordering = hasUserEditPermission && isDocumentCreator && isDocumentBeforePreparingToSign && isUserPremium;

      disableUiFeatures(instance, allowReordering);

      instance.UI.setCustomNoteFilter(
        annot =>
          !(annot instanceof instance.Core.Annotations.TextHighlightAnnotation) &&
          !(annot instanceof instance.Core.Annotations.StampAnnotation) &&
          !!annot.Author
      );

      annotationManager.addEventListener('fieldChanged', async (...args) => {
        const strikeoutAnnotList = annotationManager
          .getAnnotationsList()
          .filter(annot => annot.Subject === AnnotationType.STRIKEOUT);

        const freeTextAnnotList = annotationManager
          .getAnnotationsList()
          .filter(annot => annot.Subject === AnnotationType.FREE_TEXT);

        const fieldsString = await annotationManager.exportAnnotations({
          links: false,
          widgets: true,
          annotList: [...strikeoutAnnotList, ...freeTextAnnotList],
          fields: true,
        });

        if (isDocumentLocked) {
          return;
        }

        await saveFormFields({
          variables: { documentId: viewerDocument.id, xfdfString: fieldsString },
        });
      });

      annotationManager.addEventListener('annotationChanged', async (annots, action, info) => {
        if (info.imported) {
          annots.forEach(async (annot: Core.Annotations.HTMLAnnotation) => {
            if (annot instanceof Annotations.SignatureWidgetAnnotation) {
              annot.hidden = true;
            }

            if (annot.Subject === 'Stamp' || !annot.Author) {
              annot.Listable = false;
            }

            if (
              isDocumentExecuted &&
              (annot instanceof instance.Core.Annotations.TextStrikeoutAnnotation ||
                annot instanceof instance.Core.Annotations.FreeTextAnnotation)
            ) {
              annot.Opacity = 0;
            }

            if (isDocumentExecuted && annot instanceof instance.Core.Annotations.WidgetAnnotation) {
              annot.Hidden = true;
            }
          });

          if (shouldAnnotsReadOnlyBeEnabled) {
            disableFormFieldsInDocument(instance);
          }

          return;
        }

        const formFieldCreationManagerMode = instance.Core.annotationManager.getFormFieldCreationManager();
        if (action === 'add' || action === 'modify') {
          const formFieldPlaceHolders = annots.filter((annotation: Core.Annotations.HTMLAnnotation) =>
            annotation.isFormFieldPlaceholder()
          );

          const checkboxes = formFieldPlaceHolders.filter(
            (annotation: Core.Annotations.HTMLAnnotation) =>
              annotation.getFormFieldPlaceHolderType() === 'CheckBoxFormField'
          );

          checkboxes.forEach((checkbox: Core.Annotations.MarkupAnnotation) => {
            checkbox.FillColor = new Annotations.Color(222, 226, 255);
            checkbox.StrokeColor = new Annotations.Color(0, 0, 0, 1);
          });

          const textFields = formFieldPlaceHolders.filter(
            (annotation: Core.Annotations.HTMLAnnotation) =>
              annotation.getFormFieldPlaceHolderType() === 'TextFormField'
          );

          textFields.forEach((textFields: Core.Annotations.MarkupAnnotation) => {
            textFields.FillColor = new Annotations.Color(222, 226, 255);
            textFields.StrokeColor = new Annotations.Color(222, 226, 255);
          });

          const textWidgets = annots.filter(
            (annotation: Core.Annotations.Annotation) => annotation instanceof Annotations.TextWidgetAnnotation
          );

          textWidgets.forEach((textWidget: Core.Annotations.HTMLAnnotation) => {
            textWidget.backgroundColor = new Annotations.Color(0, 0, 0, 0);

            textWidget.border = new Annotations.Border({
              color: new Annotations.Color(211, 211, 211),
              width: 1,
            });
          });
        }

        //@ts-ignore
        if (!!formFieldCreationManagerMode.isInFormFieldCreationMode()) {
          return;
        }

        annots.forEach(async (annot: Core.Annotations.Annotation) => {
          if (annot instanceof instance.Core.Annotations.StickyAnnotation) {
            const xfdfString = await annotationManager.exportAnnotations({
              links: false,
              widgets: false,
              fields: false,
            });
            if (isDocumentLocked) {
              return;
            }

            await saveAnnotations({
              variables: { documentId: viewerDocument.id, xfdfString: xfdfString },
            });
          }

          if (
            annot instanceof instance.Core.Annotations.TextStrikeoutAnnotation ||
            annot instanceof instance.Core.Annotations.FreeTextAnnotation
          ) {
            const strikeoutAnnotList = annotationManager
              .getAnnotationsList()
              .filter(annot => annot.Subject === AnnotationType.STRIKEOUT);

            const freetextAnnotList = annotationManager
              .getAnnotationsList()
              .filter(annot => annot.Subject === AnnotationType.FREE_TEXT);

            const noteAnnotList = annotationManager
              .getAnnotationsList()
              .filter(annot => annot.Subject === AnnotationType.NOTE);

            const fieldsString = await annotationManager.exportAnnotations({
              links: false,
              widgets: true,
              annotList: [...strikeoutAnnotList, ...freetextAnnotList],
              fields: true,
            });

            const annotationsString = await annotationManager.exportAnnotations({
              links: false,
              annotList: [...noteAnnotList],
              widgets: false,
              fields: false,
            });

            if (isDocumentLocked) {
              return;
            }

            await saveFormFields({
              variables: { documentId: viewerDocument.id, xfdfString: fieldsString },
            });

            await saveAnnotations({
              variables: { documentId: viewerDocument.id, xfdfString: annotationsString },
            });
          }
        });
      });

      instance.UI.setToolMode('TextSelect');

      annotationManager.addEventListener('annotationSelected', (annotation, action) => {
        const formFieldCreationManagerMode = instance.Core.annotationManager.getFormFieldCreationManager();

        //@ts-ignore
        if (!!formFieldCreationManagerMode.isInFormFieldCreationMode()) {
          instance.UI.disableElements(['annotationStyleEditButton']);
          return;
        } else {
          instance.UI.enableElements(['annotationStyleEditButton']);
        }

        if (action === 'selected') {
          instance.UI.openElements(['notesPanel']);
        }
      });

      documentViewer.addEventListener(instance.UI.Events.DOCUMENT_LOADED, async () => {

        const displayModeManager = documentViewer.getDisplayModeManager();

        displayModeManager.setDisplayMode(new DisplayMode(documentViewer, DisplayModes.Single));

        displayModeManager.setDisplayMode(new DisplayMode(documentViewer, DisplayModes.Continuous));

      });

      documentViewer.addEventListener('pagesUpdated', async (e) => {
        const movedPagesCount = Object.keys(e.moved).length;

        const currentDocument = documentViewer.getDocument();

        if (movedPagesCount && currentDocument.arePagesAltered()) {
          console.log('Pages were moved');
          await reorderPdfPages({
            instance, 
            document: viewerDocument, 
            teamId: teamId,
            replaceImportDocument,
            licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY || '',
            saveAnnotations,
            saveFormFields,
            saveSignaturesLayer,
          });
        }
      });

      await fixPdfDocumentRotation({
        instance,
        isDocumentSigning,
        annotationsStringUrl,
        formFieldsUrl,
        document: viewerDocument,
        teamId,
        replaceImportDocument,
      })

      for (const hotkey in instance.UI.hotkeys.Keys) {
        instance.UI.hotkeys.off(hotkey);
      }

      setCustomSettingsApplied(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- should be called only once after mount

  const handleIdle = () => {
    idleTimerRef?.current?.reset();

    setShowTimeoutModal(true);
  };

  const handleTimeoutModalClose = () => {
    setShowTimeoutModal(false);
    idleTimerRef?.current?.reset();
  };

  return (
    <>
      <div
        className={classNames(styles.webviewer, styles['webviewer_view-uploaded'], {
          [styles['webviewer-receiver']]: !isDocumentCreator,
          [styles['webviewer-display']]: customSettingsApplied,
        })}
        ref={viewer}
      />
      <IdleTimer
        key="idleTimer"
        ref={idleTimerRef as any}
        startOnMount
        element={document}
        onIdle={handleIdle}
        timeout={IDLE_TIMER_TIMEOUT_MILLISECONDS}
      />
      {showTimeoutModal && <TimeoutModal onClose={handleTimeoutModalClose} onTimeOut={navigateToDealDetails} />}
    </>
  );
};

export default UploadedDocumentsViewer;
