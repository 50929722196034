import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import { fillPlaceholders, useNavigate, paths } from '../routing';
import ErrorMessage from '../components/error-message';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import styles from '../pages/deals/lease-template-modal/styles.module.scss';
import { createDocument } from '../utils/api/create-document-from-lease-document-template/create-document-from-lease-document-template';
import { useApolloClient } from '@apollo/client';
import { useNotifications } from '../notifications';
import { dispatchLeaseDocumentResponseToStore } from '../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { useDispatch } from 'react-redux';

interface TemplateInputFormProps {
  errors: any;
  values: any;
  isSubmitting: boolean;
  isValid: boolean;
}

const TemplateInputForm = ({ errors, values, isSubmitting, isValid }: TemplateInputFormProps) => (
  <Form noValidate className={styles.templateInputForm}>
    {errors._general && (
      <Alert variant="danger">
        <ErrorMessage>{errors._general}</ErrorMessage>
      </Alert>
    )}
    <div className={styles.templateInputForm__input}>
      <TextField
        //@ts-ignore TODO update TextField to ts
        id="TemplateInputForm.title"
        name="title"
        type="title"
        label={false}
      />
    </div>

    <SubmitButton
      /*
      // @ts-ignore*/
      label={<FormattedMessage id="cta.create-document" defaultMessage="Create Document" />}
      disabled={isSubmitting || !isValid}
      isSubmitting={isSubmitting}
      data-testid="create-document-button"
    />
  </Form>
);

const schema = yup.object().shape({
  title: yup.string().required(),
});

const TemplateInputFormik = ({
  dealId,
  templateId = null,
  onClose,
  isCreatingLeaseDraft,
  teamId,
  templateSourceTeamId,
  templateSourceTeamName,
}: any) => {
  const apolloClient = useApolloClient();
  const [, { error: notifyError }] = useNotifications();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    isCreatingLeaseDraft();
    createDocument({ dealId, templateId, title: values.title })(apolloClient)
      .then(response => {
        const navigateUrl = fillPlaceholders(paths.documentPath, { dealId, teamId, documentId: response.id });
        //@ts-ignore
        dispatchLeaseDocumentResponseToStore(dispatch, response);
        navigate(navigateUrl);
      })
      .catch(error => {
        notifyError({
          message: formatMessage({
            id: 'lease-document-wizard.create-offer.error',
            defaultMessage: 'Unable to create a Document',
          }),
        });
      })
      .finally(() => {
        onClose();
      });
  };
  return (
    <Formik validationSchema={schema} onSubmit={onSubmit} component={TemplateInputForm} initialValues={{ title: '' }} />
  );
};

export default TemplateInputFormik;
