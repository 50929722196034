import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import { queries, mutations, useTeamPermissions, graphqlErrorsToUiErrors } from '../../api';
import { useNotifications } from '../../notifications';
import { useTeamInfo } from '../../team-id-context';
import ErrorMessage from '../../components/error-message';
import LoadingSpinner from '../../components/loading-spinner';
import { Permissions } from '../../shared/constants/permissions';
import { useDealId } from "../../deal-id-context";
import AddDealTeamMemberForm, { UserPermissionsApiData, UserPermissionsType } from "./AddDealTeamMemberForm";
import { getTeamById } from "../../api/queries";
import { EMAIL_VALIDATION_REGEX } from "../../shared/constants/emailValidation";

const schema = yup.object().shape({
  teamId: yup.string().required(),
  defaultPermissions: yup
    .array()
    .of(yup.string())
    .ensure()
    .required(),
  defaultDeals: yup.array().of(yup.string()),
  additionalMessage: yup.string(),
});

interface AddTeamMemberFormikProps {
  bodyContainer?: any;
  actionsContainer?: any;
  renderSecondaryAction?: (...args: any[]) => any;
  onSuccess?: (...args: any[]) => any;
}

export interface InviteMutationType {
  userPermissions: UserPermissionsApiData[],
  emails: string[],
  defaultDeals: string[],
  teamId: string,
  userId?: string;
  additionalMessage?: string
}

export enum EMAIL_VALIDATION_ERRORS {
  existing,
  pending,
  orgMember,
  none
}

const AddDealTeamMemberFormContainer = ({
  bodyContainer,
  actionsContainer,
  renderSecondaryAction,
  onSuccess,
}: AddTeamMemberFormikProps) => {
  const [invitationEmails, setInvitationEmails] = useState<string[]>([]);
  const [userPermissions, setUserPermissions] = useState<UserPermissionsType[]>([]);
  const [emailValidationError, setEmailValidationError] = useState<EMAIL_VALIDATION_ERRORS>(EMAIL_VALIDATION_ERRORS.none)
  const { loading: teamInfoLoading, teamId } = useTeamInfo();
  const { loading: permissionsLoading, teamPermissions } = useTeamPermissions();
  const [, { error: notifyError }] = useNotifications();
  const [inviteNewTeamMember] = useMutation(mutations.inviteNewTeamMembersByEmail);
  const dealId = useDealId();

  const { loading: isTeamLoading, data: teamData, error: teamGraphqlError } = useQuery(getTeamById, {
    skip: !teamId,
    variables: {
      teamId,
    },
  });

  const { loading: dealsLoading, data: dealsData, error: dealsGraphqlError } = useQuery(queries.getDealsForTeam, {
    variables: {
      teamId,
    },
  });

  const loading = teamInfoLoading || permissionsLoading || dealsLoading || isTeamLoading;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!dealsData) {
    const errors = graphqlErrorsToUiErrors(dealsGraphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  if (!teamData) {
    const errors = graphqlErrorsToUiErrors(teamGraphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const validateEmail = (email: string) => {
    const orgMembers = teamData.teamById.teamMembers.map(((member: any) => member.user.email))
    const dealMembers = teamData.teamById.teamMembers.filter(((member: any) => {
      let inDeal = false
      member.user.deals.forEach((deal: any) => {
        if (deal.id === dealId) {
          inDeal = true
        }
      })
      return inDeal
    })).map(((member: any) => member.user.email))
    const pendingInviteEmails = teamData.teamById.pendingInvitations.map(((user: { email: string; }) => user.email))
    const validEmail = EMAIL_VALIDATION_REGEX.test(String(email).toLowerCase())
    orgMembers.includes(email) && setEmailValidationError(EMAIL_VALIDATION_ERRORS.orgMember)
    dealMembers.includes(email) && setEmailValidationError(EMAIL_VALIDATION_ERRORS.existing)
    pendingInviteEmails.includes(email) && setEmailValidationError(EMAIL_VALIDATION_ERRORS.pending)
    !(orgMembers.includes(email) || pendingInviteEmails.includes(email) || dealMembers.includes(email)) && setEmailValidationError(EMAIL_VALIDATION_ERRORS.none)
    return !(orgMembers.includes(email) || pendingInviteEmails.includes(email) || dealMembers.includes(email)) && validEmail
  }

  const viewPermissionId = teamPermissions?.find(({key}) => key === Permissions.View)?.id;
  const whitelistPermissionId = teamPermissions?.find(({key}) => key === Permissions.Whitelist)
    ?.id;

  const defaultPermissions = [];
  if (viewPermissionId != null) defaultPermissions.push(viewPermissionId);
  if (whitelistPermissionId != null) defaultPermissions.push(whitelistPermissionId);

  const onSubmit = async (values: { [key: string]: any }) => {
    const apiUserPermissions: UserPermissionsApiData[] = []
    userPermissions.forEach(item => {
      apiUserPermissions.push({
        email: item.email,
        permissionIds: Array.from(item.permissionIds)
      })
    })
    const data: InviteMutationType = {
      userPermissions: apiUserPermissions,
      emails: invitationEmails,
      defaultDeals: [dealId],
      teamId,
      additionalMessage: values.additionalMessage
    }

    try {
      await inviteNewTeamMember({ variables: data });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      notifyError({ message: error });
    }
  };

  const getDefaultDeals = () => {
    return dealsData?.dealsForTeam.map(({ id }: { id: any }) => id);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        teamId,
        defaultPermissions,
        defaultDeals: getDefaultDeals(),
      }}
    >
      {formikProps => (
        <AddDealTeamMemberForm
          {...formikProps}
          // @ts-ignore
          withDeals
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
          invitationEmails={invitationEmails}
          setInvitationEmails={setInvitationEmails}
          userPermissions={userPermissions}
          setUserPermissions={setUserPermissions}
          validateEmail={validateEmail}
          emailValidationError={emailValidationError}
          allPermissions={teamPermissions}
        />
      )}
    </Formik>
  );
};

export default AddDealTeamMemberFormContainer;
