import React from 'react';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';

interface Props {
  onClick: () => void;
}

const CancelPreparingToSign = ({ onClick }: Props) => {
  return (
    <Button onClick={onClick} variant="outline-primary" className="mr-2">
      <TitleFormattedMessage id="cta.edit-document" defaultMessage="Edit Document" />
    </Button>
  );
};

export default CancelPreparingToSign;
