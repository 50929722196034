import { LeaseDocumentStateType } from '../lease-document.reducer'
import { SetLeaseDocumentStatusAction } from '../actions/lease-document.actions'

export const handleSetLeaseDocumentStatus = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentStatusAction
): LeaseDocumentStateType => ({
  ...state,
  status: action.status,
})
