import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNotifications } from '../../../../../notifications';

/**
 * Generic error handler that shows an error notification banner with the provided string
 */
export const useIntlFormatMessage = () => {
  const [, { error: notifyError }] = useNotifications();
  const intl = useIntl();

  return useCallback(
    (message: { id: string; defaultMessage: string }) => {
      notifyError(
        intl.formatMessage({
          id: message.id,
          defaultMessage: message.defaultMessage,
        })
      );
    },
    [notifyError, intl]
  );
};
