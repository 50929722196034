import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth';
import { paths } from '../../../routing';

/**
 * Interface for the properties required by the useAuthTokenRedirect hook.
 * @interface
 * @property {string | undefined} token - The authentication token.
 * @property {string | undefined} teamId - The ID of the team.
 * @property {string | undefined} dealId - The ID of the deal.
 * @property {string | undefined} documentId - The ID of the document.
 */
interface AuthTokenRedirectProps {
  token: string | undefined;
  teamId?: string | undefined;
  dealId?: string | undefined;
  documentId?: string | undefined;
  redirectTo?: string | undefined;
}

/**
 * Custom hook that checks an authentication token and navigates to a specific route based on the token's validity.
 * @function
 * @param {Object} props - The properties required by the hook.
 * @param {string | undefined} props.token - The authentication token.
 * @param {string | undefined} props.teamId - The ID of the team.
 * @param {string | undefined} props.dealId - The ID of the deal.
 * @param {string | undefined} props.documentId - The ID of the document.
 * @param {string | undefined} props.redirectTo - The redirectToLink is we have it.
 * @returns {Object} An object containing the state of the token loading process.
 * @returns {boolean} return.isLoadingToken - A boolean indicating whether the token is being checked.
 * @returns {boolean} return.expiredToken - A boolean indicating whether the token is valid but expired.
 */
export const useAuthTokenRedirect = ({ token, teamId, dealId, documentId, redirectTo }: AuthTokenRedirectProps) => {
  const [, { loginByToken }] = useAuth();
  const pathsParams = useLocation();
  const navigate = useNavigate();
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [expiredToken, setExpiredToken] = useState(false);

  /**
   * Constructs the navigation link based on the provided IDs and the current pathname.
   * @function
   * @returns {string} The constructed navigation link.
   */
  const createTokenNavRedirect = (): string => {
    if (redirectTo) {
      return '/' + redirectTo;
    }
    if (pathsParams.pathname.includes('/organizations')) {
      return '/settings/' + teamId + '/organizations';
    }
    if (pathsParams.pathname.includes('/requests')) {
      return '/settings/' + teamId + '/requests';
    }
    let baseLink = teamId ? `/teams/${teamId}/deals` : '/';

    baseLink += dealId ? `/${dealId}` : '';
    baseLink += documentId ? `/documents/${documentId}` : '';

    return pathsParams.pathname.includes('/sign') ? `${baseLink}/sign` : baseLink;
  };

  /**
   * Checks the authentication token and navigates to a specific route based on the token's validity.
   * @function
   */
  useEffect(() => {
    const checkAuthToken = async () => {
      if (token) {
        setIsLoadingToken(true);
        const { success, errors } = await loginByToken({ token });
        if (success) {
          navigate(createTokenNavRedirect());
        }
        if (errors) {
          setExpiredToken(true);
        }
        setIsLoadingToken(false);
      }
    };
    checkAuthToken();
  }, [token]);

  return { isLoadingToken, expiredToken };
};
