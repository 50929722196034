import React from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import SearchSelect from 'react-select';
import c from 'classnames';
import get from 'lodash/get';

import { BaseField, BaseFieldWrap } from './base';
import styles from './styles.module.scss';

const Select = ({ className, ...props }) => {
  return <Field {...props} as="select" className={c('custom-select', className)} />;
};

export const InlineSelectField = React.forwardRef(
  ({ options = [], name, required, placeholder = '', ...props }, ref) => {
    const { formatMessage } = useIntl();
    const context = useFormikContext();
    const currentValue = get(context.values, name);
    return (
      <BaseField ref={ref} as={Select} name={name} required={required} {...props} className={styles.customSelect}>
        <option value="">{placeholder}</option>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label ? formatMessage(label) : ''}
          </option>
        ))}
      </BaseField>
    );
  }
);

export const SelectField = React.forwardRef(
  ({ before, afterField, after, wrapperElement, id, label, name, text, help, options, ...rest }, ref) => {
    return (
      <BaseFieldWrap {...{ before, afterField, after, id, label, name, text, help, wrapperElement }}>
        <InlineSelectField ref={ref} id={id} name={name} options={options} {...rest} />
      </BaseFieldWrap>
    );
  }
);

const SearchableSelect = ({ options, field, form, ...rest }) => {
  const customStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: 'lightgray',
      boxShadow: null,
      '&:hover': {
        borderColor: 'lightgray',
      },
    }),
  };

  const handleChange = selectedOption => {
    form.setValue(selectedOption.value);
  };

  const selectedOption = options.find(option => option.value === field.value);

  return (
    <SearchSelect styles={customStyles} options={options} onChange={handleChange} value={selectedOption} is {...rest} />
  );
};

export const SearchableSelectField = React.forwardRef(
  ({ before, afterField, after, wrapperElement, id, label, name, text, help, options, ...rest }, ref) => {
    const [field, , helpers] = useField(name);
    const { touched, errors } = useFormikContext();
    const error = get(errors, name);
    const touch = get(touched, name);

    return (
      <BaseFieldWrap {...{ before, afterField, after, id, label, name, text, help, wrapperElement }}>
        <SearchableSelect
          ref={ref}
          id={id}
          name={name}
          options={options}
          field={field}
          form={{ touched: touch, errors: error, ...helpers }}
          {...rest}
        />
      </BaseFieldWrap>
    );
  }
);
