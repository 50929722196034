import SuggestionThreadView from "@ckeditor/ckeditor5-track-changes/src/ui/view/suggestionthreadview";
import IconView from "@ckeditor/ckeditor5-ui/src/icon/iconview";
import helpIcon from "../../shared/icons/help-icon.svg";

export const getCustomSuggestionThreadView = (
  isCreator,
  hasApprovePermission,
  userDocumentRole,
  documentStatus
) => {
  class CustomSuggestionThreadView extends SuggestionThreadView {
    constructor(...args) {
      super(...args);
    }

    static get requires() {
      return ["TrackChanges"];
    }

    _createActionsDropdown() {
      const iconView = new IconView();

      iconView.set({
        tooltip: true,
      });

      iconView.content = helpIcon;

      iconView.extendTemplate({
        attributes: {
          class: "help-icon",
        },
      });

      return iconView;
    }

    _create() {
      const iconView = new IconView();

      iconView.set({
        tooltip: true,
      });

      iconView.content = helpIcon;

      iconView.extendTemplate({
        attributes: {
          class: "help-icon",
        },
      });

      return iconView;
    }

    // Overwrite the method to provide a custom template.
    getTemplate() {
      const templateDefinition = super.getTemplate();
      const authorRole =
        this._model.attributes && this._model.attributes.authorRole;
      const isSuggestionMadeByTheSameParty =
        !authorRole || userDocumentRole === authorRole;
      const isReviewing = documentStatus === "reviewing";

      templateDefinition.children[0].children[1].children[1].children[1].label =
        "Reject suggestion";

      templateDefinition.children[2].commentInputView.cancelButtonView.set({
        icon: null,
        withText: true,
        text: "Cancel",
      });

      templateDefinition.children[2].commentInputView.submitButtonView.set({
        icon: null,
        withText: true,
        label: "Comment",
      });
      // if no author role - then the suggestion is created bu this user during this session
      // after page refreshing the role will be set correctly
      // @TODO find a way to provide author role upon suggestion creating

      if (
        !hasApprovePermission ||
        (isReviewing && isSuggestionMadeByTheSameParty)
      ) {
        // remove all buttons from suggestion box
        templateDefinition.children[0].children[1].children[1].children = [];
      }

      if (
        isReviewing &&
        isSuggestionMadeByTheSameParty &&
        hasApprovePermission
      ) {
        templateDefinition.children.unshift({
          tag: "div",
          attributes: {
            class: "tooltip-box",
            "data-cke-tooltip-text": "Your counterparty must",
            "data-cke-tooltip-position": "w",
            "data-cke-tooltip-class": "suggestion-tooltip",
          },
          children: [this._createActionsDropdown()],
        });
      }
      return templateDefinition;
    }
  }

  return CustomSuggestionThreadView;
};

export default getCustomSuggestionThreadView;
