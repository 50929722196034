export interface CreateSuggestionData {
  data: any;
  id: string;
  originalSuggestionId: string | null;
  type: string;
}

export enum DocumentTypesEnum {
  TEMPLATE = 'DocumentTemplate',
  DOCUMENT = 'Document',
}

export enum SuggestionStatusesEnum {
  OPEN = 'open',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CREATED = 'created',
}

export interface CreateSuggestionPayload {
  type: string;
  data: { [key: string]: any };
  attributes: { [key: string]: any };
  originalSuggestionId: string | null;
  externalId: string;
}

export interface CreateSuggestionResponse {
  createdAt: string;
  authorRole: 'creator' | 'receiver' | 'lost';
}

export interface GetSuggestionByIdResponse {
  attributes: { [key: string]: any };
  author: {
    id: string;
  };
  authorRole: 'creator' | 'receiver' | 'lost';
  createdAt: string;
  data: { [key: string]: any };
  externalId: string;
  type: string;
}

export interface TrackChangesConfig {
  createSuggestion: (data: CreateSuggestionPayload) => Promise<CreateSuggestionResponse>;
  getSuggestionById: (suggestionId: string) => Promise<GetSuggestionByIdResponse & { hasComments: boolean }>;
  deleteSuggestionById: (suggestionId: string) => Promise<void>;
  acceptSuggestionById: (suggestionId: string, suggestionData: string) => Promise<void>;
  rejectSuggestionById: (suggestionId: string, suggestionData: string) => Promise<void>;
  pendSuggestionById: (suggestionId: string) => Promise<void>;
  saveSessionActivity: (activity: string) => void;
}
