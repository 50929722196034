import * as yup from 'yup';
import without from 'lodash/without';

import * as errorMessages from './error-messages';
import getPasswordStrength from './utils/getPasswordStrength';

/* eslint-disable no-template-curly-in-string */
// Template strings for yup have placeholders which look like those from
// `template literals`, and since they're regular strings they trip an eslint
// rule.

// For why the error messages are JSON-stringified, see the ErrorMessage
// component.

// Set default error messages
yup.setLocale({
  string: {
    default: JSON.stringify(errorMessages.string.default),
    email: JSON.stringify({ ...errorMessages.string.email, values: { path: '${path}' } }),
    min: JSON.stringify({
      ...errorMessages.string.min,
      values: { path: '${path}', min: '${min}' },
    }),
  },
  mixed: {
    required: JSON.stringify({
      ...errorMessages.mixed.required,
      values: { path: '${path}' },
    }),
    notType: JSON.stringify({
      ...errorMessages.mixed.notType,
      values: { path: '${path}', type: '${type}' },
    }),
  },
});

// Add custom validations

/**
 * Validate that one string is the same as the string in another field
 */
yup.addMethod(yup.string, 'equalTo', (ref, msg) =>
  yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message:
      msg ??
      JSON.stringify({
        ...errorMessages.string.equalTo,
        values: { path: '${path}', reference: '${reference}' },
      }),
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  })
);

/**
 * Validate that a password has a minimum strength
 */
yup.addMethod(yup.string, 'minPasswordStrength', (min, msg) =>
  yup.mixed().test({
    name: 'minPasswordStrength',
    exclusive: false,
    message:
      msg ??
      JSON.stringify({
        ...errorMessages.string.minPasswordStrength,
        values: { min: '${min}' },
      }),
    params: { min },
    test: async value => (await getPasswordStrength(value ?? '')) >= min,
  })
);

/**
 * Validate that a checkbox is checked
 */
yup.addMethod(yup.boolean, 'checked', msg =>
  yup.mixed().test({
    name: 'checked',
    exclusive: false,
    message:
      msg ??
      JSON.stringify({
        ...errorMessages.boolean.checked,
        values: { path: '${path}' },
      }),
    test: function(value) {
      return value === true;
    },
  })
);

/*
 * Currently the yup number field does not allow empty strings, they come through as NaN.
 * Formik stores all empty values as '', so we need a different validator to allow them to pass validation
 * Yup is working on a fix, but it hasn't seen updates in awhile. See this issue for the status: https://github.com/jquense/yup/issues/298
 */
const parseNumber = value =>
  value === null || (typeof value === 'string' && value.trim() === '') ? null : parseFloat(value);
yup.addMethod(yup.mixed, 'numberAllowEmpty', msg =>
  yup
    .mixed()
    .transform(parseNumber)
    .test({
      name: 'numberAllowEmpty',
      exclusive: false,
      message:
        msg ??
        JSON.stringify({
          ...errorMessages.mixed.notType,
          values: { type: 'number' },
        }),
      test: value => value == null || !isNaN(value),
    })
);

/*
 * A method for attaching required() validators that only take effect when the other items are empty.
 * May need to be improved later to be more extendable, but this solves the simple case for now.
 *
 * Idea pulled from:
 * https://runkit.com/anber/yup---at-least-one
 */
yup.addMethod(yup.object, 'atLeastOneRequired', function atLeastOneRequired(list, message) {
  return this.shape(
    list.reduce(
      (acc, field) => ({
        ...acc,
        [field]: this.fields[field].when(without(list, field), {
          is: (...values) => !values.some(item => item),
          then: this.fields[field].required(message),
        }),
      }),
      {}
    ),
    list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map(i => [item, i])], [])
  );
});
