import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetInteractionsFeedTokenAction } from '../actions/lease-document.actions';

export const handleSetInteractionsFeedToken = (
  state: LeaseDocumentStateType,
  action: SetInteractionsFeedTokenAction
): LeaseDocumentStateType => ({
  ...state,
  interactionsFeedToken: action.interactionsFeedToken,
});
