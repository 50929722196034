import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { paths, A } from '../../routing';
import { graphqlErrorsToUiErrors, queries } from '../../api';
import ResetPasswordForm from '../../forms/resetPasswordForm';
import PublicLayout from '../../components/public-layout';
import ErrorMessage from '../../components/error-message';
import LoadingSpinner from '../../components/loading-spinner';

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();

  const resetPasswordToken = searchParams.get('reset_password_token');

  const { loading, data: tokenData, error: graphqlError } = useQuery(queries.getResetExpiryByToken, {
    variables: {
      resetToken: resetPasswordToken!,
    },
  });

  if (loading) {
    return (
      <Wrapper>
        <LoadingSpinner />
      </Wrapper>
    );
  }

  const tryAgain = (
    <p>
      <FormattedMessage
        id="phrase.reset-password.try-again"
        defaultMessage="To try again, <a>restart the password reset procedure</a>."
        values={{
          a: (msg: React.ReactNode) => (
            <A href={paths.auth.forgotPassword} hrefParams={{}} data-testid="forgot-password">
              {msg}
            </A>
          ),
        }}
      />
    </p>
  );

  if (graphqlError) {
    let error = graphqlErrorsToUiErrors(graphqlError)._general;
    if (error.id === 'api.error.not-found') {
      error = {
        id: 'phrase.reset-password.token-invalid',
        defaultMessage: 'This password reset link is invalid.',
      };
    }
    return (
      <Wrapper>
        <p className="lead">
          <ErrorMessage>{error}</ErrorMessage>
        </p>
        {tryAgain}
      </Wrapper>
    );
  }

  const expiry = new Date(tokenData!.resetExpiryByToken!.expiresAt);
  const expired = expiry.getTime() < Date.now();

  if (expired || !resetPasswordToken) {
    return (
      <Wrapper>
        <p className="lead">
          <FormattedMessage
            id="phrase.reset-password.token-expired"
            defaultMessage="This password reset link expired or was used on {expiry, date, medium} at {expiry, time, short}."
            values={{ expiry }}
          />
        </p>
        {tryAgain}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <p className="lead">
        <FormattedMessage id="phrase.reset-password.instructions" defaultMessage="Enter a new password below." />
      </p>
      <p className="text-muted">
        <FormattedMessage
          id="phrase.reset-password.token-expires-at"
          defaultMessage="This password reset link expires on {expiry, date, medium} at {expiry, time, short}."
          values={{ expiry }}
        />
      </p>
      <ResetPasswordForm resetPasswordToken={resetPasswordToken} />
    </Wrapper>
  );
};

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const { formatMessage } = useIntl();
  return (
    <PublicLayout>
      <Helmet title={formatMessage({ id: 'title.reset-password', defaultMessage: 'Create new password' })} />
      <h1>
        <FormattedMessage id="title.reset-password" defaultMessage="Create new password" />
      </h1>
      {children}
    </PublicLayout>
  );
};

export default ResetPassword;
