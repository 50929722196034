import { ApolloClient } from '@apollo/client';
import { getDealById } from '../../../api/queries';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  success: boolean;
};

type Props = {
  documentId: string;
  dealId: string | number;
  viewerIds: string[];
};

export function createDocumentViewRequest({ documentId,  viewerIds, dealId }: Props) {
  return function(client: ApolloClient<object>): Promise<ResponsePayloadType> {
    const variables = {
      documentId,
      viewerIds,
      confirm: true
    };

    const mutation = graphql(/* GraphQL */`
      mutation createDocumentViewRequest($documentId: ID!, $viewerIds: [ID!]!, $confirm: Boolean) {
        createDocumentViewRequest(
          documentId: $documentId
          viewerIds: $viewerIds
          confirm: $confirm
        ) {
          document {
            id
          }
        }
      }
    `);

    const refetchQueries = [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.createDocumentViewRequest);
  };
}
