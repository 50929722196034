import React, { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DocumentPage from './DocumentPage';
import { useDispatch as reduxUseDispatch, useSelector } from 'react-redux';
import { useIntl as reactIntlUseIntl } from 'react-intl';
import { ApolloClient, useApolloClient as apolloUseApolloClient } from '@apollo/client';
import { getDocumentById as getDocumentByIdQuery } from '../../../utils/api/get-document-by-id';
import {
  selectDocumentApprovalRequest,
  selectDocumentImportedAssetContentType,
  selectDocumentSource,
  selectIsDocumentEditable,
  selectLeaseDocument,
  selectLeaseDocumentIsPending,
  selectLeaseDocumentLastUpdated,
  selectLeaseDocumentReceiverTeam,
  selectLeaseDocumentStatus,
  selectLeaseDocumentTitle,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import { useLeaseDocumentWizardErrorNotifier } from '../shared/hooks/use-lease-document-wizard-error-notifier/use-lease-document-wizard-error-notifier.hook';
import {
  dispatchEraseLeaseDocumentValuesFromStore,
  dispatchLeaseDocumentResponseToStore,
} from '../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { setDeal } from '../../../store/deals/deals.actions';
import { getTeamsForUser } from '../../../utils/api/get-teams-for-user/get-teams-for-user';
import { setAllTeams, setOwnedTeams } from '../../../store/permissions/permissions.actions';
import { useLeaseDocumentTeamPermissions } from '../shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { useDocumentRole } from '../../../shared/hooks/useDocumentRole/useDocumentRole';
import { fillPlaceholders, paths } from '../../../routing';
import { useLeaseDocumentSave } from '../shared/hooks/use-lease-document-save/use-lease-document-save.hook';
import { updateDocument } from '../../../utils/api/update-lease-document-offer/update-document';
import { useLeaseDocumentTitleSave } from '../shared/hooks/use-lease-document-title-save/use-lease-document-title-save.hook';
import { resetLeaseDocumentState } from '../../../store/lease-document/actions/lease-document.actions';
import LoadingSpinner from '../../../components/loading-spinner';
import { closeDocumentModals } from '../../../store/modals/modals.actions';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import { DocumentPayload } from '../../../utils/api/lease-document-interface.types';
import { ApprovalRequestStatus } from '../../../components/lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/RequestInternalApprovalModalConstants';

type DocumentPageContainerProps = {
  useDispatch?: (...args: any[]) => any;
  useApolloClient?: (...args: any[]) => any;
  useIntl?: (...args: any[]) => any;
  getDocumentById?: (documentId: string | number) => (client: ApolloClient<object>) => Promise<DocumentPayload>;
};

const DocumentPageContainer = ({
  useDispatch = reduxUseDispatch,
  useIntl = reactIntlUseIntl,
  useApolloClient = apolloUseApolloClient,
  getDocumentById = getDocumentByIdQuery,
}: DocumentPageContainerProps) => {
  const apolloClient = useApolloClient();
  const reactIntl = useIntl();
  const navigate = useNavigate();
  const { documentId: offerId, teamId, dealId } = useParams();
  const dispatch = useDispatch();
  const document = useSelector(selectLeaseDocument);
  const documentTitle = useSelector(selectLeaseDocumentTitle);
  const isPending = useSelector(selectLeaseDocumentIsPending);
  const lastUpdated = useSelector(selectLeaseDocumentLastUpdated);
  const notifyError = useLeaseDocumentWizardErrorNotifier();
  const receiverTeamId = useSelector(selectLeaseDocumentReceiverTeam);
  const documentStatus = useSelector(selectLeaseDocumentStatus);

  useEffect(
    function cleanUpOnMount() {
      dispatchEraseLeaseDocumentValuesFromStore(dispatch);
    },
    [dispatch]
  );

  const handleError = useCallback(() => {
    notifyError(
      reactIntl.formatMessage({
        id: 'lease-document-wizard.fetch-error',
        defaultMessage: 'Unable to fetch the Document',
      })
    );
  }, [notifyError, reactIntl]);

  /**
   * Load document
   * @TODO: Refactor so that this isn't just a copy-paste-modify of the same code
   * in LeaseDocumentWizardOfferPage
   */
  const handleOfferLoadError = useCallback(
    errorCode => {
      if (errorCode === 'unauthorized-query-access') {
        navigate('/');
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (document !== null) {
      return;
    }

    if (!offerId) {
      return;
    }

    getDocumentById(offerId)(apolloClient)
      .then(response => {
        dispatch(setDeal(response.deal));
        // @ts-ignore
        dispatchLeaseDocumentResponseToStore(dispatch, response);
      })
      .catch(err => {
        if (typeof handleOfferLoadError === 'function') {
          handleOfferLoadError(err);
        }

        handleError();
      });
  }, [apolloClient, dispatch, document, getDocumentById, handleError, offerId, handleOfferLoadError]);

  useEffect(
    function fetchTeamsForUserOnMount() {
      getTeamsForUser()(apolloClient).then(({ ownedTeams, allTeams }) => {
        dispatch(setOwnedTeams(ownedTeams));
        dispatch(setAllTeams(allTeams));
      });
    },
    [apolloClient, dispatch]
  );
  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });
  const canInvite = permissions.hasInviteSendingPermission;
  const canEdit = permissions.hasEditPermission;
  const canComment = permissions.hasCommentPermission;
  const { isDocumentCreator, hasCurrentTeamPossession, isDocumentRoleLoading } = useDocumentRole(offerId!);
  const onClosePath = paths.deals.detail;
  const onCloseParams: { [key: string]: string } | {} = document && teamId ? { teamId, dealId } : {};
  const onCloseUrl = document && teamId ? fillPlaceholders(onClosePath, onCloseParams) : '';
  const isInEditableState =
    hasCurrentTeamPossession &&
    (documentStatus === DocumentStatusEnum.DRAFT ||
      documentStatus === DocumentStatusEnum.SHARED ||
      documentStatus === DocumentStatusEnum.REVIEWING ||
      documentStatus === DocumentStatusEnum.UPLOADED);
  const canEditDocument = permissions.hasEditPermission && isInEditableState;
  const canCommentDocument = permissions.hasCommentPermission;
  const documentSource = useSelector(selectDocumentSource);
  const docAssetType = useSelector(selectDocumentImportedAssetContentType);
  const isDocXDocument = docAssetType !== 'application/pdf';
  const isDocumentEditable = useSelector(selectIsDocumentEditable);
  const approvalRequest = useSelector(selectDocumentApprovalRequest);
  const isApprovalRequestPending = approvalRequest?.status === ApprovalRequestStatus.PENDING;

  const handleSaveError = useCallback(() => {
    notifyError(
      reactIntl.formatMessage({
        id: 'shared-offer-page.save-error',
        defaultMessage: 'Unable to save the Document',
      })
    );
  }, [notifyError, reactIntl]);

  const handleSave = useLeaseDocumentSave({
    id: offerId!,
    save: updateDocument,
    onError: handleSaveError,
    apolloClient,
  });

  const saveTitle = useLeaseDocumentTitleSave({
    id: offerId!,
    save: updateDocument,
    onError: handleSaveError,
    apolloClient,
    dispatch,
  });

  const handleTitleSave = useCallback(
    title => {
      if (isPending) {
        return;
      }

      saveTitle(title);
    },
    [isPending, saveTitle]
  );

  const handleLeaseWizardClose = useCallback(() => {
    navigate(onCloseUrl);
  }, [navigate, onCloseUrl]);

  const handleClose = () => {
    handleLeaseWizardClose();
    dispatch(resetLeaseDocumentState());
    dispatch(closeDocumentModals());
  };

  if (!document || !teamId || isDocumentRoleLoading || !documentSource || !dealId || !offerId) {
    return <LoadingSpinner />;
  }

  return (
    <DocumentPage
      canEdit={canEditDocument!}
      canComment={canCommentDocument}
      onSave={
        isInEditableState &&
        hasCurrentTeamPossession &&
        (canEdit || canComment) &&
        isDocumentEditable &&
        !isApprovalRequestPending
          ? handleSave
          : () => {}
      }
      lastUpdated={lastUpdated}
      documentTitle={documentTitle || ''}
      onTitleSave={handleTitleSave}
      isDocumentCreator={isDocumentCreator}
      hasCurrentTeamPossession={hasCurrentTeamPossession!}
      document={document}
      documentId={offerId}
      teamId={teamId}
      dealId={dealId}
      canInvite={canInvite}
      permissions={permissions}
      isShared={!!receiverTeamId}
      documentStatus={(documentStatus as DocumentStatusEnum) || DocumentStatusEnum.DRAFT}
      handleClose={handleClose}
      documentSource={documentSource}
      isDocXDocument={isDocXDocument}
    />
  );
};

export default DocumentPageContainer;
