import React from 'react';
import DecoupledEditor from '@simplelease/ckeditor5-build-decoupled-document';
import { EditorType } from './utils/lease-editor.types';
import { HeaderFooterEditor } from './HeaderFooterEditor';
import { LeaseDocumentComment, LeaseDocumentTemplateType, LeaseDocumentType } from '../lease-document.types';

declare global {
  interface Window {
    editor: EditorType;
    commentsContext: LeaseDocumentComment[];
  }
}
type HeaderFooterEditorContainerProps = {
  document: LeaseDocumentType | LeaseDocumentTemplateType;
  documentId: string;
  isDocumentHeaderEditing: boolean;
  isFromTemplateEditor?: boolean;
  lockHeaderFooterEditor?: boolean;
};

export const HeaderFooterEditorContainer = ({
  documentId,
  document,
  isDocumentHeaderEditing,
  isFromTemplateEditor,
  lockHeaderFooterEditor,
}: HeaderFooterEditorContainerProps) => {
  const { getDecoupledEditor } = DecoupledEditor;

  const permissions = {
    hasApprovePermission: true,
    hasCommentPermission: true,
    hasEditPermission: true,
    hasInviteSendingPermission: true,
    hasViewPermission: true,
    isAdmin: true,
  };
  return (
    <>
      <HeaderFooterEditor
        editor={getDecoupledEditor({
          context: null,
          threadRendered: null,
          isCreator: true,
          isShared: false,
          userDocumentRole: 'creator',
          documentStatus: null,
          permissions,
          enableRestrictedEditingMode: lockHeaderFooterEditor && !isFromTemplateEditor,
          documentType: 'header',
        })}
        documentId={documentId}
        permissions={permissions}
        isDocumentHeaderEditing={isDocumentHeaderEditing}
        documentHTML={isDocumentHeaderEditing ? document.header || '' : document.footer || ''}
        isFromTemplateEditor={isFromTemplateEditor}
        lockHeaderFooterEditor={lockHeaderFooterEditor}
      />
    </>
  );
};
