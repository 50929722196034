
import Command from '@ckeditor/ckeditor5-core/src/command';

export default class PrivateThreadCommand extends Command {
	execute() {

	}

	refresh() {

	}
}
