import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmActionModal from '../../../confirm-action-modal';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface RemoveTeamMemberConfirmationModalProps {
  onHide: () => void;
  onClick: () => void;
  userToRemove: any;
}

const RemoveTeamMemberConfirmationModal = ({
  onHide,
  onClick,
  userToRemove,
}: RemoveTeamMemberConfirmationModalProps) => {
  const message = useMemo(() => {
    return (
      userToRemove && (
        <FormattedMessage
          id="phrase.confirm-removing-team-member"
          defaultMessage="Are you sure you would like to remove {fullName} from this workspace?"
          values={userToRemove}
        />
      )
    );
  }, [userToRemove]);

  return (
    <ConfirmActionModal
      show={!!userToRemove}
      onHide={onHide}
      onClick={onClick}
      title={<FormattedMessage id="title.remove-team-member" defaultMessage="Remove team member?" />}
      message={message}
      action={<TitleFormattedMessage id="cta.remove" defaultMessage="Remove" />}
    />
  );
};

export default RemoveTeamMemberConfirmationModal;
