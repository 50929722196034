import { useCallback } from 'react';
import { useNotifications } from '../../../../../notifications';

/**
 * Generic error handler that shows an error notification banner with the provided string
 */
export const useLeaseDocumentWizardErrorNotifier = () => {
  // @ts-ignore - legacy notifications
  const [, { error: notifyError }] = useNotifications();

  return useCallback(
    ({ message, pinned = false }: { message: string; pinned?: boolean }) => {
      notifyError({ message, pinned });
    },
    [notifyError]
  );
};
