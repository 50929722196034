import React from 'react';
import arrayFirst from 'lodash/first';
import arrayDrop from 'lodash/drop';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './styles.module.scss';
import CurrentWorkspaceIndicator from './current-user-indicator';
import { ReactComponent as HamIcon } from '../../shared/icons/hamburger.svg';
import { MobileMenu } from '../mobile-layout/mobile-menu';

const ContentHeader = ({ children, noMore = false }) => {
  const titleElement = arrayFirst(children);
  const otherElements = arrayDrop(children);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={!!titleElement ? styles.contentHeader__withSubheader : styles.contentHeader}>
      <Container fluid>
        <Row className="flex-nowrap">
          <Col md={6} lg={8} className={classNames('align-self-center', styles.headerTitle)}>
            {titleElement ?? children}
          </Col>
          <Col className={styles.indicatorContainter}>
            <CurrentWorkspaceIndicator />
          </Col>
          {!noMore && (
            <Col className={styles.moreContainer}>
              <HamIcon width={32} height={32} onClick={onToggleMenu} className="mr-3" />
            </Col>
          )}
        </Row>
      </Container>
      {otherElements ? otherElements : <></>}
      <MobileMenu isOpen={isMenuOpen} onClose={onToggleMenu} />
    </header>
  );
};

export default ContentHeader;
