import { ApolloClient } from '@apollo/client';
import { getCurrentUser, getDealsForTeam } from '../../../api/queries';
import { getCurrentUserTeamsWhereUserHasEditPermissions } from '../../../api/queries_v2';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  receiverTeam: { id: string; name: string; uuid: string; adminUser: { id: string; fullName: string; email: string } };
  invitation: { onboarding: { redirectTo: string } };
};

export const OnboardTeamForSharedDeal = graphql(/* GraphQL */ `
  mutation OnboardTeamForSharedDeal($dealId: ID!, $teamId: ID, $teamName: String, $userId: ID!) {
    onboardTeamForSharedDeal(dealId: $dealId, teamId: $teamId, teamName: $teamName) {
      receiverTeam {
        id
        name
        uuid
        adminUser {
          id
          fullName
          email
        }
      }
      invitation(userId: $userId) {
        onboarding {
          redirectTo
        }
      }
    }
  }
`);

export const onboardTeamForSharedDeal = (dealId: string, teamId: string, userId: string, teamName?: string) => {
  return (client: ApolloClient<object>): Promise<ResponsePayloadType> => {
    const variables = {
      dealId,
      teamId,
      teamName,
      userId,
    };

    const refetchQueries = [
      {
        query: getCurrentUser,
      },
      {
        query: getDealsForTeam,
        variables: {
          teamId,
        },
      },
      {
        query: getCurrentUserTeamsWhereUserHasEditPermissions,
      },
    ];

    return client
      .mutate({ mutation: OnboardTeamForSharedDeal, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.onboardTeamForSharedDeal);
  };
};
