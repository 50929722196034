import { ApolloClient } from '@apollo/client';
import { getDealById } from '../../../api/queries';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  success: boolean;
};

type Props = {
  documentId: string;
  dealId: string | number;
  teamId: string;
  threshold: string;
  userIds: string[];
};

export function createDocumentApprovalRequest({ documentId, threshold, userIds, dealId, teamId }: Props) {
  return function(client: ApolloClient<object>): Promise<ResponsePayloadType> {
    const variables = {
      documentId,
      threshold,
      userIds,
      teamId,
      confirm: true,
    };

    const mutation = graphql(/* GraphQL */`
      mutation createDocumentApprovalRequest($documentId: ID!, $threshold: String!, $userIds: [ID!]!, $teamId: ID!, $confirm: Boolean) {
        createDocumentApprovalRequest(
          documentId: $documentId
          threshold: $threshold
          userIds: $userIds
          teamId: $teamId
          confirm: $confirm
        ) {
          document {
            id
          }
        }
      }
    `);

    const refetchQueries = [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.createDocumentApprovalRequest);
  };
}
