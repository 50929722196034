import objectToFormData from '../utils/object-to-form-data';
import { token } from '../auth';
import { api, generic as genericErrorMessage } from '../error-messages';

import { apiErrorsToUiErrors } from '.';

const URL = process.env.REACT_APP_API;
export const ENDPOINTS = {
  login: () => `${URL}/users/sign_in`,
  logout: () => `${URL}/users/sign_out`,
  register: () => `${URL}/users`,
  registerToken: () => `${URL}/users/token`,
  registerWithEmailVerified: () => `${URL}/users/verify`,
  confirmEmail: (confirmationToken: string) => `${URL}/users/confirmation?confirmation_token=${confirmationToken}`,
  resendConfirmationEmail: () => `${URL}/users/confirmation`,
  sendForgotPasswordEmail: () => `${URL}/users/password`,
  resetPassword: () => `${URL}/users/password`,
  refreshToken: () => `${URL}/users/refresh`,
};

/**
 * Handle an unexpected error
 *
 * @function handleGenericError
 * @param {Error} error - The error which was caught
 * @returns {Object} - Error response
 */
function handleGenericError(error: any) {
  console.error(error);
  return {
    success: false,
    errors: [genericErrorMessage],
  };
}

/**
 * Log a user in with credentials
 *
 * @function login
 * @async
 * @param {Object} data
 * @param {string} data.email - Email address
 * @param {string} data.password - Password
 * @returns {Promise<Object>} - Response with success key plus user data and
 *                              token
 */
export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{
  success: boolean;
  errors?: { [key: string]: any; _general?: string };
  user?: Object;
  token?: string;
}> => {
  try {
    const response = await fetch(ENDPOINTS.login(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email,
          password,
        }
      }),
      credentials: 'include', // Allow refresh token cookie to be set
    });
    const { success, errors, user } = await response.json();

    if (success === false) {
      return { success: false, errors: apiErrorsToUiErrors(errors) };
    }

    return {
      success: true,
      user,
      token: response.headers.get('Authorization')?.replace(/^bearer\s+/i, ''),
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Log a user in with credentials
 *
 * @function loginByToken
 * @async
 * @param {Object} data
 * @param {string} data.token - Token
 * @returns {Promise<Object>} - Response with success key plus user data and
 *                              token
 */
export const loginByToken = async ({ token }: { token: string; }): Promise<{
  success: boolean;
  errors?: { [key: string]: any; _general?: string };
  user?: Object;
  token?: string;
}> => {
  try {
    const response = await fetch(ENDPOINTS.login(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          token
        }
      }),
      credentials: 'include', // Allow refresh token cookie to be set
    });
    const { success, errors, user } = await response.json();

    if (success === false) {
      return { success: false, errors: apiErrorsToUiErrors(errors) };
    }

    return {
      success: true,
      user,
      token: response.headers.get('Authorization')?.replace(/^bearer\s+/i, ''),
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Log a user out
 *
 * @function logout
 * @async
 * @param {Object} userAuthToken
 * @returns {Promise<Object>} - Response with success key
 */
export const logout = async () => {
  try {
    const response = await fetch(ENDPOINTS.logout(), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token.get()}`,
      },
      credentials: 'include', // Allow refresh token cookie to be deleted
    });
    return { success: response.ok };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Register a user
 *
 * @function register
 * @async
 * @param {Object} data
 * @param {string} data.email - Email address
 * @param {string} data.password - Password
 * @param {string} data.fullName - Full name
 * @param {string} data.companyName - Company name
 * @param {string} data.invitationToken - Invitation token
 * @returns {Promise<Object>} - Response with success key plus user data and
 *                              token
 */
interface RegisterData {
  email?: string;
  password: string;
  fullName: string;
  companyName: string;
  invitationToken?: string;
  confirmationToken?: string;
}

interface RegisterInvitedCounterpartyData {
  firstName: string;
  lastName: string;
  companyName: string;
  token: string;
}

interface RegisterInvitedTeamMemberData {
  firstName: string;
  lastName: string;
  token: string;
}

export const registerInvitedTeamMember = async ({ firstName, lastName, token }: RegisterInvitedTeamMemberData) => {
  const data: {
    user: {
      first_name: string;
      last_name: string;
      token: string;
    }
  } = {
    user: {
      first_name: firstName,
      last_name: lastName,
      token
    }
  };

  try {
    const response = await fetch(ENDPOINTS.registerToken(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include', // Allow refresh token cookie to be set
    });
    const { success, errors, user } = await response.json();

    if (errors?.[0] === api.existingUser.defaultMessage) {
      return { success: false, errors: api.existingUser };
    }

    if (success === false) {
      return { success: false, errors: apiErrorsToUiErrors(errors) };
    }

    return {
      success: true,
      user,
      token: response.headers.get('Authorization')?.replace(/^bearer\s+/i, ''),
    };
  } catch (error) {
    return handleGenericError(error);
  }
}

export const registerInvitedCounterparty = async ({ firstName, lastName, companyName, token }: RegisterInvitedCounterpartyData) => {
  const data: {
    user: {
      first_name: string;
      last_name: string;
      company_name: string;
      token: string;
    }
  } = {
    user: {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      token
    }
  };

  try {
    const response = await fetch(ENDPOINTS.registerToken(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include', // Allow refresh token cookie to be set
    });
    const { success, errors, user } = await response.json();

    if (errors?.[0] === api.existingUser.defaultMessage) {
      return { success: false, errors: api.existingUser };
    }

    if (success === false) {
      return { success: false, errors: apiErrorsToUiErrors(errors) };
    }

    return {
      success: true,
      user,
      token: response.headers.get('Authorization')?.replace(/^bearer\s+/i, ''),
    };
  } catch (error) {
    return handleGenericError(error);
  }
}

export const register = async ({ email, password, fullName, companyName, invitationToken }: RegisterData) => {
  const data: { [key: string]: string } = {
    'user[password]': password,
    'user[full_name]': fullName,
    'user[company_name]': companyName,
  };
  if (email) data['user[email]'] = email;
  if (invitationToken) data.invitation_token = invitationToken;

  try {
    const response = await fetch(ENDPOINTS.register(), {
      method: 'POST',
      body: objectToFormData(data),
      credentials: 'include', // Allow refresh token cookie to be set
    });
    const { success, errors, user } = await response.json();

    if (errors?.[0] === api.existingUser.defaultMessage) {
      return { success: false, errors: api.existingUser };
    }

    if (success === false) {
      return { success: false, errors: apiErrorsToUiErrors(errors) };
    }

    return {
      success: true,
      user,
      token: response.headers.get('Authorization')?.replace(/^bearer\s+/i, ''),
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const registerWithEmailVerified = async ({
  password,
  fullName,
  companyName,
  confirmationToken,
}: RegisterData) => {
  const data: {
    user: {
      password: string;
      full_name: string;
      company_name: string;
      token?: string;
    }
  } = {
    user: {
      password,
      full_name: fullName,
      company_name: companyName
    }
  };

  if (confirmationToken) data.user.token = confirmationToken;

  try {
    const response = await fetch(ENDPOINTS.registerWithEmailVerified(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    });
    const { errors, user } = await response.json();

    if (errors?.[0] === api.existingUser.defaultMessage) {
      return { success: false, errors: api.existingUser };
    }

    if (!!errors?.[0]) {
      return { success: false, errors: apiErrorsToUiErrors(errors) };
    }

    return {
      success: true,
      user,
      token: response.headers.get('Authorization')?.replace(/^bearer\s+/i, ''),
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

export const signupWithEmailConfirmation = async ({ email }: { email: string }) => {
  const data: { [key: string]: string } = {
    'user[email]': email,
  };
  try {
    const response = await fetch(ENDPOINTS.register(), {
      method: 'POST',
      body: objectToFormData(data),
      credentials: 'include', // Allow refresh token cookie to be set
    });

    const { success, errors, code } = await response.json();
    if (errors?.[0] === api.existingUser.defaultMessage) {
      return { success: false, errors: api.existingUser };
    }

    if (success === false) {
      return { success: false, errors: apiErrorsToUiErrors(errors), code };
    }

    return {
      success: true,
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Confirm user's email address
 *
 * @function confirmEmail
 * @async
 * @param {Object} data
 * @param {string} data.confirmationToken - Confirmation token
 * @returns {Promise<Object>} - Response with success key
 */
export const confirmEmail = async ({ confirmationToken }: { confirmationToken: string }) => {
  try {
    const response = await fetch(ENDPOINTS.confirmEmail(confirmationToken));
    // TODO: handle error messages once SL-216 is finished
    return { success: response.ok };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Request for confirmation email to be sent again
 *
 * @function resendConfirmationEmail
 * @async
 * @param {Object} data
 * @param {string} data.email - Email address
 * @returns {Promise<Object>} - Response with success key
 */
export const resendConfirmationEmail = async ({ email }: { email: string }) => {
  try {
    const response = await fetch(ENDPOINTS.resendConfirmationEmail(), {
      method: 'POST',
      body: objectToFormData({ 'user[email]': email }),
    });
    if (response.ok) {
      return { success: true };
    }
    const { errors } = await response.json();
    return { success: false, errors: apiErrorsToUiErrors(errors) };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Attempt to refresh the access token
 *
 * This relies on a cookie existing for the API domain containing a refresh
 * token.
 *
 * @function refreshToken
 * @async
 * @returns {Promise<Object>} - Response with success key plus user data and
 *                              token
 */
export const refreshToken = async () => {
  try {
    const response = await fetch(ENDPOINTS.refreshToken(), {
      method: 'POST',
      credentials: 'include', // Ensure cookie is sent
    });
    const { errors, user } = await response.json();

    if (response.ok) {
      return {
        success: true,
        user,
        token: response.headers.get('Authorization')?.replace(/^bearer\s+/i, ''),
      };
    }
    return {
      success: false,
      errors: apiErrorsToUiErrors(errors),
    };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Request a password reset email
 *
 * @function sendForgotPasswordEmail
 * @async
 * @param {Object} data
 * @param {string} data.email - Email address
 * @returns {Promise<Object>} - Response with success key
 */
export const sendForgotPasswordEmail = async ({ email }: { email: string }) => {
  try {
    const response = await fetch(ENDPOINTS.sendForgotPasswordEmail(), {
      method: 'POST',
      body: objectToFormData({ 'user[email]': email }),
    });

    if (response.ok) {
      return {
        success: true,
      };
    }
    const { errors } = await response.json();
    return { success: false, errors: apiErrorsToUiErrors(errors) };
  } catch (error) {
    return handleGenericError(error);
  }
};

/**
 * Reset a user's password via a password reset token
 *
 * @function resetPassword
 * @async
 * @param {Object} data
 * @param {string} data.resetPasswordToken - Password reset token
 * @param {string} data.password - New password
 * @returns {Promise<Object>} - Response with success key
 */
export const resetPassword = async ({
  resetPasswordToken,
  password,
}: {
  resetPasswordToken: string;
  password: string;
}) => {
  try {
    const response = await fetch(ENDPOINTS.resetPassword(), {
      method: 'PUT',
      body: objectToFormData({
        'user[reset_password_token]': resetPasswordToken,
        'user[password]': password,
        'user[password_confirmation]': password,
      }),
    });

    if (response.ok) {
      return {
        success: true,
      };
    }
    const { errors } = await response.json();
    return { success: false, errors: apiErrorsToUiErrors(errors, ['resetPasswordToken']) };
  } catch (error) {
    return handleGenericError(error);
  }
};
