import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import AddTeamMemberToDealForm from '../../../forms/addUserToDealTeam/AddDealTeamMemberFormContainer';
import { TitleFormattedMessage } from '../../../components/titleFormattedMessage';

const InviteNewTeamMemberModal = ({ showModal, hideModal }: { showModal: boolean; hideModal: () => void }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal show={showModal} onHide={() => hideModal()} size="lg">
      <Modal.Header
        closeButton
        closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
      >
        <Modal.Title>
          <header>
            <FormattedMessage id="title.add-team-member" defaultMessage="Invite people to your deal team" />
          </header>
        </Modal.Title>
      </Modal.Header>
      <AddTeamMemberToDealForm
        bodyContainer={Modal.Body}
        actionsContainer={Modal.Footer}
        renderSecondaryAction={() => (
          <Button variant="link" className="text-secondary" onClick={() => hideModal()}>
            <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
          </Button>
        )}
        onSuccess={() => hideModal()}
      />
    </Modal>
  );
};

export default InviteNewTeamMemberModal;
