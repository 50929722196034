import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApolloClient, useQuery } from '@apollo/client';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { queries } from '../../api';
import { ContentHeader } from '../../components/private-layout';
import LoadingSpinner from '../../components/loading-spinner';
import { useTeamInfo } from '../../team-id-context';
import { useDealId } from '../../deal-id-context';
import InviteReceivingTeamFormik from '../../forms/InviteReceivingTeamFormContainer';
import { setActiveModal } from '../../store/modals/modals.actions';
import { selectActiveModal } from '../../store/modals/modals.selectors';
import { DealModalActionsEnum } from './constants/DealConstants';
import { determineModalData } from './helpers/determineDealsActionModalMessage';
import { cancelReceiverTeamDealInvite } from '../../utils/api/cancel_receiver_team_deal_invite/cancel_receiver_team_deal_invite';
import { useNotifications } from '../../notifications';
import { resendDealInviteEmail } from '../../utils/api/resend_deal_invite_email/resend_deal_invite_email';
import { removeReceiverTeamFromDeal } from '../../utils/api/remove_receiver_from_deal/remove_receiver_from_deal';
import { LeaseDealActionModal } from '../../components/lease-wizard/lease-document-wizard-modal/custom-modals/lease-deal-action-modal/LeaseDealActionModal';
import { ResendDealInviteMessage } from '../../components/document-table/DocumentTableConstants';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';
import styles from '../deals/styles.module.scss';

interface HeaderProps {
  titleComponent: ReactNode;
  children?: ReactNode;
  noMore?: boolean;
  refetchDealData: () => Promise<void>;
}

const Header = ({ titleComponent, children, noMore, refetchDealData }: HeaderProps) => {
  const dispatch = useDispatch();
  const { teamId } = useTeamInfo();
  const [showInviteCounterpartyModal, setShowInviteCounterpartyModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const { formatMessage } = useIntl();
  const apolloClient = useApolloClient();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();
  const dealId = useDealId();
  const activeModal = useSelector(selectActiveModal);

  const canShowModal = [
    DealModalActionsEnum.RESEND,
    DealModalActionsEnum.CANCEL,
    DealModalActionsEnum.REMOVE_COUNTERPARTY,
  ].includes(activeModal as DealModalActionsEnum);

  const { loading: isDealDataLoading, data: dealData } = useQuery(queries.getDealById, {
    skip: !teamId || !dealId,
    variables: {
      dealId,
    },
  });

  const counterpartyInvitedEmail = dealData?.getDealById?.receiverEmail!;

  const handleFailureResponse = (error: any) => {
    notifyError({
      message: {
        id: 'offers.terminate-lease-offer.error',
        defaultMessage: error || 'Something went wrong.',
      },
    });
  };

  const handleCancel = () => {
    setLoading(true);
    const additionalMessage = additionalMessageInputRef?.current?.value;
    cancelReceiverTeamDealInvite({ dealId, additionalMessage })(apolloClient)
      .then(() => {
        dispatch(setActiveModal(null));
        setLoading(false);
      })
      .catch(error => {
        handleFailureResponse(error);
      })
      .finally(async () => {
        dispatch(setActiveModal(null));
        setLoading(false);
        await refetchDealData()
      });
  };

  const handleResend = () => {
    setLoading(true);
    resendDealInviteEmail(
      dealId,
      counterpartyInvitedEmail
    )(apolloClient)
      .then(() => {
        dispatch(setActiveModal(null));
        setLoading(false);
        notifySuccess({
          message: {
            id: 'phrase.resend-invitation.success',
            defaultMessage: ResendDealInviteMessage.SUCCESS,
          },
        });
      })
      .catch(error => {
        handleFailureResponse(error);
      })
      .finally(() => {
        dispatch(setActiveModal(null));
        setLoading(false);
      });
  };

  const handleRemoveCounterparty = () => {
    setLoading(true);
    const additionalMessage = additionalMessageInputRef?.current?.value;
    removeReceiverTeamFromDeal({ dealId, additionalMessage })(apolloClient)
      .then(() => {
        dispatch(setActiveModal(null));
        setLoading(false);
      })
      .catch(error => {
        handleFailureResponse(error.message);
      })
      .finally(async () => {
        dispatch(setActiveModal(null));
        setLoading(false);
        await refetchDealData()
      });
  };

  const modalHandler: Record<string, () => void> = {
    cancel: handleCancel,
    resend: handleResend,
    removeCounterparty: handleRemoveCounterparty,
  };

  const onModalHide = () => {
    dispatch(setActiveModal(null));
  };

  const determineHandler = () => {
    if (!modalType) return;

    return modalHandler[modalType];
  };

  const modalType = activeModal ? activeModal.split('_').pop() : null;
  
  const invitationIsSent = useCallback(() => {
    setShowInviteCounterpartyModal(false);
  }, []);

  if (isDealDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ContentHeader noMore={noMore}>
      <header className="font-weight-bold">{titleComponent}</header>
      <Container className="ml-3" fluid>
        <Row>
          <Col>
            <div>{children}</div>
          </Col>
        </Row>
        <Row className="align-items-end"></Row>
        <Row className="align-items-end">
          <Col>
            <Modal show={showInviteCounterpartyModal} onHide={() => setShowInviteCounterpartyModal(false)} size="lg">
              <div>
                <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
                  <Modal.Title>
                    <header className={styles.invitationModal__title}>
                      <FormattedMessage id="title.add-team-member" defaultMessage="Invite Counterparty to the Deal" />
                    </header>
                  </Modal.Title>
                </Modal.Header>
                <InviteReceivingTeamFormik
                  /*
                  // @ts-ignore */
                  bodyContainer={Modal.Body}
                  /*
                  // @ts-ignore */
                  actionsContainer={Modal.Footer}
                  renderSecondaryAction={() => (
                    <Button
                      variant="link"
                      className="text-secondary"
                      onClick={() => setShowInviteCounterpartyModal(false)}
                    >
                      <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
                    </Button>
                  )}
                  onSuccess={invitationIsSent}
                  teamId={teamId}
                />
              </div>
            </Modal>
            {activeModal && canShowModal && (
              <LeaseDealActionModal
                show={canShowModal}
                onHide={onModalHide}
                isLoading={isLoading}
                handler={determineHandler()}
                title={determineModalData(activeModal).title}
                submitButtonTitle={determineModalData(activeModal).submitButtonTitle}
                cancelButtonTitle={determineModalData(activeModal).cancelButtonTitle}
              >
                {determineModalData(activeModal).message}
                {determineModalData(activeModal).showAdditionalMessageField && (
                  <div className={styles.dealModalInputWrapper}>
                    <textarea
                      className={styles.dealModalInputWrapper__input}
                      ref={additionalMessageInputRef}
                      placeholder="Message (Optional)"
                    />
                  </div>
                )}
              </LeaseDealActionModal>
            )}
          </Col>
        </Row>
      </Container>
    </ContentHeader>
  );
};

export default Header;
