import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';
import { CommentInputType } from "../../../gql/graphql";

export type CreateComment = {
  documentId: string;
  comment: CommentInputType
};

const mutation = graphql(/* GraphQL */`
  mutation CreateDocumentComment($documentId: ID!, $comment: CommentInputType!) {
    createDocumentComment(documentId: $documentId, comment: $comment) {
      ...DocumentCommentFields
    }
  }
`);

export const createDocumentComment = (options: CreateComment) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const createDocumentCommentWithResult = (options: CreateComment) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.createDocumentComment);
  });
};
