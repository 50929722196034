import React, { ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { TitleFormattedMessage } from './titleFormattedMessage';

interface ConfirmActionModalProps {
  show: boolean;
  onHide: () => void;
  onClick: () => void;
  title: ReactElement;
  message: ReactElement | string;
  action: ReactElement;
}

const ConfirmActionModal = ({ show, onHide, onClick, title, message, action }: ConfirmActionModalProps) => {
  const { formatMessage } = useIntl();
  return (
    <Modal show={show} onHide={onHide} className="confirmation-action-modal-custom">
      <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
        <Modal.Title>
          <header>{title}</header>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="text-secondary" onClick={onHide}>
          <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="secondary" onClick={onClick}>
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmActionModal;
