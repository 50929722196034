import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Command from '@ckeditor/ckeditor5-core/src/command';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import toMap from '@ckeditor/ckeditor5-utils/src/tomap';
import Icon from './checkbox_icon.svg';

import './styles.css';

class InsertInlineCheckboxCommand extends Command {
  execute() {

    this.editor.model.change(writer => {
      const selection = this.editor.model.document.selection;

      const checkbox = writer.createElement( 'checkbox', {
        ...Object.fromEntries( selection.getAttributes() ),
        checked: false,
      });

      this.editor.model.insertContent(checkbox);

      writer.setSelection(checkbox, 'after');
    });
  }
  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    this.isEnabled = model.schema.checkChild(selection.focus.parent, 'checkbox');
  }
}

class ToggleInlineCheckboxCommand extends Command {
  execute() {
    this.editor.model.change(writer => {
      const selection = this.editor.model.document.selection;

      const selectedElement = selection.getSelectedElement();
    
      if (selectedElement && selectedElement.is('element', 'checkbox')) {

        const isChecked = selectedElement.getAttribute('checked') || false;

        const newCheckbox = writer.createElement( 'checkbox', {
          ...Object.fromEntries( selection.getAttributes() ),
          checked: !isChecked,
        });

        this.editor.model.deleteContent(selection);

        this.editor.model.insertContent(newCheckbox, selection);

        writer.setSelection(newCheckbox, 'after');
      }


    });
  }
  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const selectedElement = selection.getSelectedElement();
    this.isEnabled = selectedElement !== null && selectedElement.is('element', 'checkbox');
  }
}

class InlineCheckboxEditing extends Plugin {
  init() {
    const editor = this.editor;
    const model = editor.model;
    const conversion = editor.conversion;

    // Define the 'checkbox' model element.
    model.schema.register('checkbox', {
      inheritAllFrom: '$inlineObject',
      allowAttributes: ['checked', 'data-checked']
    });

    // Define converters for the 'checkbox' model element.
    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: 'checkbox'
      },
      model: (viewElement, { writer }) => {
        const textNode = viewElement.getChild(0);

        let isChecked = false;

        if (textNode && textNode.is('text')) {
          isChecked = textNode.data === String.fromCodePoint(0x2611);
        }

        return writer.createElement('checkbox', { checked: isChecked });
      }
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'checkbox',
      view: (modelElement, { writer }) => {
        const isChecked = modelElement.getAttribute('checked');

        const span = writer.createContainerElement('span', { class: 'checkbox'} );

        const textNode = writer.createText(String.fromCodePoint(isChecked ? 0x2611 : 0x2610));

        writer.insert(writer.createPositionAt(span, 0), textNode);

        return span;
      }
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'checkbox',
      view: (modelElement, { writer }) => {
        const isChecked = modelElement.getAttribute('checked');

        const span = writer.createContainerElement('span', { class: 'checkbox'} );

        const textNode = writer.createText(String.fromCodePoint(isChecked ? 0x2611 : 0x2610));

        writer.insert(writer.createPositionAt(span, 0), textNode);

        return toWidget(span, writer);
      }
    });

    conversion.for('editingDowncast').attributeToAttribute({
      model: {
        name: 'checkbox',
        key: 'checked'
      },
      view: modelAttributeValue => {
        return {
          key: 'data-checked',
          value: modelAttributeValue ? 'true' : 'false'
        };
      },
      converterPriority: 'low'
    });


    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( 'checkbox' ) )
  );

    editor.commands.add('insertInlineCheckbox', new InsertInlineCheckboxCommand(editor));

    editor.commands.add('toggleInlineCheckbox', new ToggleInlineCheckboxCommand(editor));

    editor.editing.view.document.on('click', (evt, data) => {
      const modelDocument = this.editor.model.document;
      const modelSelection = modelDocument.selection;

      // Get the clicked view element.
      const viewElement = data.target;

      // Convert the view element to a model element.
      const modelElement = this.editor.editing.mapper.toModelElement(viewElement);

      // If the clicked element is a checkbox, execute the 'checkInlineCheckbox' command.
      if (modelElement && modelElement.is('element', 'checkbox')) {

        // if the parent already is suggested input, then do nothing until suggestion is actioned
        if (!viewElement.hasAttribute('data-suggestion')) {
          editor.execute('toggleInlineCheckbox');
        }
        else {
          data.preventDefault();
          evt.stop();
        }
      }
    });

    const trackChangesEditing = editor.plugins.get('TrackChangesEditing');

    trackChangesEditing.enableCommand("insertInlineCheckbox");

    trackChangesEditing.enableCommand("toggleInlineCheckbox");
  }
}

class InlineCheckboxUI extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add('insertInlineCheckbox', locale => {
      const command = editor.commands.get('insertInlineCheckbox');
      const buttonView = new ButtonView(locale);
      const t = this.editor.locale.t

      buttonView.set({
        label: 'Insert Checkbox',
        icon: Icon,
        tooltip: true,
        withText: false
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => {
        editor.execute('insertInlineCheckbox');

        editor.editing.view.focus();
      });

      return buttonView;
    });
  }
}

export default class InlineCheckbox extends Plugin {
  static get requires() {
    return [InlineCheckboxEditing, InlineCheckboxUI];
  }

  static get pluginName() {
    return 'inlineCheckbox';
  }
}
