import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownBody, DropdownToggle } from '../../../../../../../../dropdown';
import styles from './styles.module.scss';

interface SignerSelectProps {
  onSelect: (email: string | null) => void;
  selectedSigner: { email: string; id: string } | undefined;
  signers: { email: string; id: string }[];
}

const SignerSelect = ({ signers, onSelect, selectedSigner }: SignerSelectProps) => {
  return (
    <>
      <Dropdown onSelect={onSelect} className={styles.dropdown}>
        <Dropdown.Toggle id="dropdown-insert-fields" as={DropdownToggle} className={styles.dropdown}>
          {selectedSigner?.email || 'Select signer'}{' '}
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.dropdown}>
          <DropdownBody>
            {signers.map(({ email, id }) => (
              <Dropdown.Item key={id} eventKey={id}>
                {email}
              </Dropdown.Item>
            ))}
          </DropdownBody>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SignerSelect;
