import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { queries, mutations, useTeamPermissions, graphqlErrorsToUiErrors } from '../api';
import { useNotifications } from '../notifications';
import { useTeamInfo } from '../team-id-context';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import ErrorMessage from '../components/error-message';
import { EditPermissionsFormFragment } from '../components/editPermissionsList/editPermissionsFormFragment';
import { SelectDealsFormFragment } from '../components/selectDeals/components';
import LoadingSpinner from '../components/loading-spinner';
import { Permissions } from '../shared/constants/permissions';
import { useAuth } from '../auth';
import { InviteMutationType } from './addUserToDealTeam/AddDealTeamMemberFormContainer';

interface AddTeamMemberFormProps {
  bodyContainer?: any;
  actionsContainer?: any;
  renderSecondaryAction: any;
  errors: { [key: string]: any };
  isSubmitting: boolean;
  isValid: boolean;
}

const AddTeamMemberForm = ({
  bodyContainer: BodyContainer = React.Fragment,
  actionsContainer: ActionsContainer = React.Fragment,
  renderSecondaryAction,
  errors,
  isSubmitting,
  isValid,
}: AddTeamMemberFormProps) => (
  <Form noValidate>
    <BodyContainer>
      {errors._general && (
        <Alert variant="danger">
          <ErrorMessage>{errors._general}</ErrorMessage>
        </Alert>
      )}

      <h3>
        <FormattedMessage id="title.email-address" defaultMessage="Email address" />
      </h3>
      <span>
        <FormattedMessage
          id="phrase.new-team-member-will-receive-activation-email"
          defaultMessage="Your new team member will receive an email to activate their account."
        />
      </span>
      <Row>
        <Col className="mb-1" lg={7}>
          <TextField id="addTeamMemberForm.email" name="email" type="email" />
        </Col>
      </Row>
      <h3>
        <FormattedMessage id="title.permissions" defaultMessage="Permissions" />
      </h3>
      <p>
        <FormattedMessage
          id="phrase.view-or-change-team-members-permissions"
          defaultMessage="View or change this team member’s permissions"
        />
      </p>
      <EditPermissionsFormFragment userPermissions={{}} />

      <h3>
        <FormattedMessage id="title.deals" defaultMessage="Deals" />
      </h3>
      <p>
        <FormattedMessage
          id="phrase.select-deals-for-team-member"
          defaultMessage="Select the deals this team member can work on. By default no deals are selected."
        />
      </p>
      <SelectDealsFormFragment />
    </BodyContainer>

    <ActionsContainer>
      {renderSecondaryAction && renderSecondaryAction()}
      <SubmitButton
        label={<FormattedMessage id="cta.send-invitation-email" defaultMessage="Send invitation email" />}
        disabled={isSubmitting || !isValid}
        isSubmitting={isSubmitting}
      />
    </ActionsContainer>
  </Form>
);

const schema = yup.object().shape({
  teamId: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  defaultPermissions: yup
    .array()
    .of(yup.string())
    .ensure()
    .required(),
  defaultDeals: yup.array().of(yup.string()),
});

interface AddTeamMemberFormikProps {
  bodyContainer?: any;
  actionsContainer?: any;
  renderSecondaryAction?: (...args: any[]) => any;
  onSuccess?: (...args: any[]) => any;
}

const AddTeamMemberFormik = ({
  bodyContainer,
  actionsContainer,
  renderSecondaryAction,
  onSuccess,
}: AddTeamMemberFormikProps) => {
  const { loading: teamInfoLoading, teamId } = useTeamInfo();
  const { loading: permissionsLoading, teamPermissions } = useTeamPermissions();
  const [, { error: notifyError }] = useNotifications();
  const [inviteNewTeamMember] = useMutation(mutations.inviteNewTeamMembersByEmail);
  const [{ user }] = useAuth();

  const { loading: dealsLoading, data: dealsData, error: dealsGraphqlError } = useQuery(queries.getDealsForTeam, {
    variables: {
      teamId,
    },
  });

  const loading = teamInfoLoading || permissionsLoading || dealsLoading;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!dealsData) {
    const errors = graphqlErrorsToUiErrors(dealsGraphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const viewPermissionId = teamPermissions?.find(({ key }) => key === Permissions.View)?.id;
  const whitelistPermissionId = teamPermissions?.find(({ key }) => key === Permissions.Whitelist)?.id;

  const defaultPermissions = [];
  if (viewPermissionId != null) defaultPermissions.push(viewPermissionId);

  const onSubmit = async (values: { [key: string]: any }) => {
    const { email, teamId, defaultDeals, defaultPermissions } = values;
    const variables: InviteMutationType = {
      emails: [email],
      teamId,
      defaultDeals,
      userId: user.id,
      userPermissions: [
        {
          email: email,
          permissionIds: defaultPermissions,
        },
      ],
    };

    try {
      await inviteNewTeamMember({ variables });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      notifyError({ message: error.message });
    }
  };

  const getDefaultDeals = () => {
    return dealsData?.dealsForTeam.map(({ id }: { id: any }) => id);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        teamId,
        email: '',
        defaultPermissions,
        defaultDeals: getDefaultDeals(),
      }}
    >
      {formikProps => (
        <AddTeamMemberForm
          {...formikProps}
          // @ts-ignore
          withDeals
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
        />
      )}
    </Formik>
  );
};

export default AddTeamMemberFormik;
