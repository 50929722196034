import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { TeamMember } from '.';
import { ReactComponent as ChevronUpIcon } from '../../../../shared/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../shared/icons/chevron-down.svg';
import Avatar from '../../../avatar';
import styles from './styles.module.scss';
import { useAuth } from '../../../../auth';
import { useContext, useState } from 'react';
import PermissionIcon from '../../../permission-icon';
import EditPermissionsList from '../../../editPermissionsList';
import { A, paths } from '../../../../routing';
import { TeamIdContext } from '../../../../team-id-context';
import { SelectDeals } from '../../../selectDeals';
import ErrorMessage from '../../../error-message';
import classNames from 'classnames';

interface TeamMemberItemProps {
  member: TeamMember;
  showDealMembersOnly: boolean;
  isReadOnly: boolean;
  dealsData: any;
  whitelistPermissionId?: string;
  shouldShowDealsSelector: boolean;
}

const TeamMemberItem: React.FC<TeamMemberItemProps> = ({
  member,
  showDealMembersOnly,
  dealsData,
  isReadOnly,
  whitelistPermissionId,
  shouldShowDealsSelector,
}) => {
  // @ts-ignore
  const [{ user }] = useAuth();
  const [isOpen, setOpen] = useState(false);
  const teamId = useContext(TeamIdContext);

  const renderMessage = () => {
    if (isReadOnly)
      return (
        <FormattedMessage
          id="phrase.view-permissions-adjust-later"
          defaultMessage="These are your team member’s current permissions. You can adjust these later in your {team_settings}."
          values={{
            team_settings: (
              <A href={paths.team} hrefParams={{ teamId }}>
                <FormattedMessage id="team_settings" defaultMessage="team settings" />
              </A>
            ),
          }}
        />
      );

    return (
      <FormattedMessage
        id="phrase.view-or-change-team-members-permissions"
        defaultMessage="View or change this team member’s permissions"
      />
    );
  };

  const renderDealsSelector = () => (
    <>
      <h3 data-testid="deals-select-header">
        <FormattedMessage id="title.deals" defaultMessage="Deals" />
      </h3>
      <p>
        <FormattedMessage
          id="phrase.select-team-member-deals"
          defaultMessage="Select the deals this team member can work on."
        />
      </p>
      {dealsData ? (
        <SelectDeals
          dealsForTeam={dealsData.dealsForTeam}
          readOnly={member.isAdmin || !!member.invitationId}
          whitelistPermissionId={whitelistPermissionId || ''}
          userRowData={member}
        />
      ) : (
        // @ts-ignore
        <ErrorMessage>{graphqlErrorsToUiErrors(dealsGraphqlError)._general}</ErrorMessage>
      )}
    </>
  );

  return (
    <div className={classNames(styles.teamMemberItem, styles.teamMemberItemFull)}>
      <div className={styles.teamMemberItem__header} onClick={() => setOpen(!isOpen)}>
        <div className={styles.teamMemberItem__header__info}>
          <Avatar email={member.email} fullName={member.fullName} className="mr-3" />
          <span
            className={`${showDealMembersOnly && 'text-truncate'}`}
            style={{ width: `${showDealMembersOnly && '200px'}` }}
          >
            {member.fullName}
            {member.id === user.id && (
              <>
                {' '}
                <FormattedMessage id="phrase.hint.fullName" defaultMessage="(you)" />
              </>
            )}
          </span>
        </div>
        <div className={styles.teamMemberItem__header__actions}>
          {isOpen ? <ChevronUpIcon width={16} height={16} /> : <ChevronDownIcon width={16} height={16} />}
        </div>
      </div>
      <div className={styles.teamMemberItem__body}>
        <div className={styles.teamMemberItem__info}>
          <span className={styles.teamMemberItem__label}>Email: </span>
          <span className={styles.teamMemberItem__details}>{member.email}</span>
        </div>
        <div className={styles.teamMemberItem__info}>
          <span className={styles.teamMemberItem__label}>Permissions: </span>
          <div className={classnames(styles.teamMemberItem__details, 'd-flex')}>
            {member.permissions.map((permission: any, index: number) => (
              <div key={`${member.id}-${permission.key}-${index}`}>
                <PermissionIcon permission={permission} className="mr-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        {isOpen && (
          <div className={styles.teamMemberItem__expanded}>
            <h3 data-testid="team-member-row_header">
              <FormattedMessage id="title.permissions" defaultMessage="Permissions" />
            </h3>
            {renderMessage()}
            <EditPermissionsList
              data-testid="edit-permissions-list"
              permissions={member.permissions}
              dealIds={member.dealIds}
              userId={member.id}
              readOnly={!!member.isAdmin}
              isAdminUser={!!member.isAdmin}
              invitationId={member.invitationId}
              isYou={user.id === member.id}
            />
            {shouldShowDealsSelector && renderDealsSelector()}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMemberItem;
