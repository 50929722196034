import { useQuery } from '@apollo/client';

import { queriesV2 } from '.';

export default function useTeamPermissions() {
  const { loading: permissionsLoading, data: permissionsData, error: permissionsError } = useQuery(
    queriesV2.getPermissions
  );

  return {
    loading: permissionsLoading,
    teamPermissions: permissionsData?.permissions,
    error: permissionsError,
  };
}
