import { SetIsReceiverCollaborationEnabledAction } from '../actions/lease-document.actions';
import { LeaseDocumentStateType } from '../lease-document.reducer';

export const handleSetIsReceiverCollaborationEnabled = (
  state: LeaseDocumentStateType,
  action: SetIsReceiverCollaborationEnabledAction
): LeaseDocumentStateType => ({
  ...state,
  receiverCollaborationEnabled: action.receiverCollaborationEnabled,
});
