import React, { ReactNode, useEffect, useState } from 'react';
import { LeaseDocumentWizardHeaderTitle } from './lease-document-wizard-header-title/LeaseDocumentWizardHeaderTitle';
import { ReactComponent as CloseIcon } from '../../../shared/icons/close-icon.svg';
import './lease-document-wizard-header.scss';
import { DocumentRevisionSwitcher } from './document-revision-swithcer/DocumentRevisionSwitcher';
import PendingReviewWarningModal from './modals/PendingReviewWarningModal';
import { useDocumentRole } from '../../../shared/hooks/useDocumentRole/useDocumentRole';

interface DocumentHeaderProps {
  onClose?: () => void;
  title: string;
  titleBadge: ReactNode;
  canEdit: boolean;
  isUploaded: boolean;
  isReviewing: boolean;
  hasInviteSendingPermission: boolean;
  shouldShowEditorModeSwitcherActive: boolean;
  hasUserEditPermission: boolean;
  onSaveTitle?: (value: string) => void;
  lastSaved: ReactNode;
  actions: ReactNode;
  dealId: string;
  teamId: string;
  documentId: string;
  dealTeamsData: any;
  onSave: (() => Promise<void>) | undefined;
  canShare: boolean;
  shouldShowInternalSendForSigning: boolean | null | undefined;
  shouldShowSendForSigning: boolean | null | undefined;
}

export const LeaseDocumentWizardHeader = ({
  onClose,
  title,
  titleBadge,
  canEdit,
  onSaveTitle,
  isReviewing,
  hasInviteSendingPermission,
  lastSaved,
  actions,
  isUploaded,
  hasUserEditPermission,
  dealId,
  teamId,
  documentId,
  dealTeamsData,
  onSave,
  canShare,
  shouldShowInternalSendForSigning,
  shouldShowSendForSigning,
}: DocumentHeaderProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isDocumentCreator } = useDocumentRole(documentId);

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');
      if (documentPendingActionsPlugin?.hasAny) {
        event.preventDefault();
        if (isReviewing && hasInviteSendingPermission && !isDocumentCreator) {
          setModalOpen(true);
        }
      }
    };
    const handleBeforePopstate = (event: Event) => {
      const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');
      if (documentPendingActionsPlugin?.hasAny) {
        window.history.pushState(null, document.title, window.location.href);
        if (isReviewing && hasInviteSendingPermission && !isDocumentCreator) {
          setModalOpen(true);
        }
      } else {
        window.removeEventListener('popstate', handleBeforePopstate);
        window.history.back();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBeforePopstate);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleCloseClick = () => {
    if (isReviewing && hasInviteSendingPermission && !isDocumentCreator) {
      setModalOpen(true);
    } else {
      onClose && onClose();
    }
  };

  const isEditorActive = window.editor?.state === 'ready';

  return (
    <header className="lease-document-wizard-header" data-testid="document-header">
      <nav className="lease-document-wizard-header__actions">
        <div className="col-lg-8 p-0 lease-document-header__document-title-container">
          {onClose && (
            <button
              type="button"
              onClick={handleCloseClick}
              className="lease-document-wizard-header__main-close"
              data-testid="document-header__close-cta"
            >
              <CloseIcon className="lease-document-wizard-header__main-close-icon" />
            </button>
          )}
          {!isUploaded && isEditorActive && hasUserEditPermission && <DocumentRevisionSwitcher />}
          <LeaseDocumentWizardHeaderTitle
            canEdit={canEdit}
            title={title}
            titleBadge={titleBadge}
            onSaveTitle={onSaveTitle}
          />
        </div>
        <div className="col-lg-4 p-0 lease-document-header__document-action-container">
          <div className="lease-document-header__last-updated small">Last Saved:&nbsp;{lastSaved}</div>
          <div className="pr-1 lease-document-header__edit-actions">{actions}</div>
        </div>
      </nav>
      <PendingReviewWarningModal
        showModal={modalOpen}
        hideModal={setModalOpen}
        onCloseDocument={onClose}
        canEdit={canEdit}
        documentId={documentId}
        teamId={teamId}
        dealId={dealId}
        dealTeamsData={dealTeamsData}
        onSave={onSave}
        canShare={canShare}
        shouldShowInternalSendForSigning={shouldShowInternalSendForSigning}
        shouldShowSendForSigning={shouldShowSendForSigning}
      />
    </header>
  );
};
