import { FormattedMessage } from 'react-intl';
import StatusDropdown, { DealStatus } from '../statusDropdown/statusDropdown';
import { DealUserRoles } from '../../../../shared/hooks/useDealRoles/useDealRoles';
import { dealStatusesForStatusDropdown } from '../../constants/DealConstants';
import {Row as RowTable, TableOptions} from 'react-table';
import dayjs from "dayjs";
import React from "react";
import DealNotificationToggle from '../dealNotification/DealNotificationToggle';

type ColumnsCellType = {
  cell: {
    value: DealStatus;
    row: {
      original: { currentUserRole: DealUserRoles, id: string };
    };
    columns: TableOptions<{ currentUserRole: DealUserRoles }>;
  };
};

type Props = {
  canEdit: boolean;
  handleUpdateDealDetails: (sealId: string, newStatus: DealStatus) => void;
  userDealNotifications: any;
  refetchUser: any;
};

export const dealTableColumns = ({ canEdit, handleUpdateDealDetails, userDealNotifications, refetchUser }: Props) => [
  {
    Header: <FormattedMessage id="title.deals.title" defaultMessage="Name" />,
    accessor: 'title',
  },
  {
    Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Deal Creator" />,
    accessor: 'creator',
  },
  {
    Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Deal Status" />,
    accessor: 'status',
    width: 70,
    Cell: ({
      cell: {
        value: status,
        row: {
          original: { currentUserRole, id },
        },
      },
    }: ColumnsCellType) => {
      if (currentUserRole === DealUserRoles.CREATOR && !!canEdit) {
        return (
          <StatusDropdown
            currentStatus={status}
            onStatusChange={(newStatus: DealStatus) => {
              handleUpdateDealDetails(id, newStatus);
            }}
          />
        );
      }

      return (
        <FormattedMessage
          id="phrase.deal-status-readout"
          defaultMessage={dealStatusesForStatusDropdown}
          values={{ status }}
        />
      );
    },
  },
  {
    Header: <FormattedMessage id="title.deals.last-updated" defaultMessage="Last Updated" />,
    accessor: 'updatedAt',
    width: 70,
    sortType: (a: RowTable<{values: {updatedAt: string}}>, b: RowTable<{values: {updatedAt: string}}>) => {
      return new Date(b.values.updatedAt).getTime() - new Date(a.values.updatedAt).getTime();
    },
    Cell: ({ cell: { value } }: any) => {
      return <span className="text-nowrap">{value && dayjs(value).format('LL')}</span>;
    },
  },
  {
    accessor: 'notify',
    width: 10,
    Cell: ({ cell: { value } }: any) => {
      return <div className="align-items-end flex">
        <DealNotificationToggle dealId={value} userDealNotifications={userDealNotifications} refetchUser={refetchUser} />
      </div>;
    },
  },
];
