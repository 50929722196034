import React from 'react';
import className from 'classnames';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ChevronDownIcon } from '../../shared/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../shared/icons/chevron-up.svg';

import { fillPlaceholders } from '../../routing';
import styles from '../private-layout/styles.module.scss';

interface SettingsItemProps {
  children: React.ReactNode;
  pathRegex?: RegExp;
  href: string;
  hrefParams?: { [key: string]: any };
  qa?: { [key: string]: any };
  icon: React.FC<{ height: string; width: string; className: string }>;
  onClick?: () => void;
  showSubnav?: boolean;
}

export const SettingsItem: React.FC<SettingsItemProps> = ({
  icon: Icon,
  children,
  pathRegex = null,
  href,
  hrefParams = {},
  qa = {},
  onClick,
  showSubnav,
}) => {
  const { pathname: path } = useLocation();

  const isCurrent = pathRegex?.test(path) ?? path === fillPlaceholders(href, hrefParams);

  return (
    <span
      data-testid={qa}
      className={className(styles.mobileMenu__settingsItem, { [styles.isCurrent]: isCurrent || showSubnav })}
      onClick={onClick}
    >
      <div className="d-flex">
        <Icon height="24" width="24" className="ml-1 mr-3" />
        <div>{children}</div>
      </div>
      {showSubnav || isCurrent ? <ChevronUpIcon width="16" height="16" /> : <ChevronDownIcon width="16" height="16" />}
    </span>
  );
};
