import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import useRoute from '../../routing/useRoute';
import { paths } from '../../routing';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './styles.module.scss';

const Nav = () => {
  const currentLocation = useRoute().path;

  return (
    <nav className={styles.nav}>
    </nav>
  );
};

export default Nav;
