import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import { ButtonView } from "@ckeditor/ckeditor5-ui";

export default class InsertNumberedListUI extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add("insertNumberedList", () => {
      const button = new ButtonView();

      button.label = "Custom List";
      button.tooltip = true;
      button.withText = true;

      this.listenTo(button, "execute", () => {
        editor.execute("listStyle", { type: "decimal-leading-zero" });
      });
      return button;
    });
  }
}
