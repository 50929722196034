import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseDocumentPossessionAction } from '../actions/lease-document.actions';

export const handleSetLeaseDocumentPossession = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentPossessionAction
): LeaseDocumentStateType => ({
  ...state,
  currentTeamPossession: action.currentTeamPossession,
});
