import React from 'react';
import styles from '../styles.module.scss'
import classNames from "classnames";
import { useAuth } from "../../../../auth";
import { ParsedTeamMember } from "../../../teamMembersList/components/teamMembersTable/helpers";

interface DealTeamInfoItemProps {
  teamMember: ParsedTeamMember,
  teamId: string,
}

const DealTeamInfoItem = ({ teamMember, teamId }: DealTeamInfoItemProps) => {
  const [{ user }] = useAuth();
  const isYou = user.id === teamMember.id;

  return (
    <>
      <div className={classNames(styles.teamMemberContainer)} key={teamMember.id}>
        <div className={styles.dealNameInfoAndDelete}>
          <div className="flex-grow-1">
            {!teamMember.invitationId && <div className={styles.nameAndDeleteContainer}>
              <h4 className={styles.teamMemberName}>{teamMember.fullName} {isYou && '(you)'}</h4>
            </div>
            }
            <div className={styles.teamMemberEmail}>{teamMember.email}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealTeamInfoItem;
