export enum AddNewTemplateName {
  id = 'addNewTemplate.name',
  defaultMessage = 'Template Name',
}

export enum AddNewClauseName {
  id = 'addNewClause.name',
  defaultMessage = 'Clause Name',
}

export enum AddNewDealName {
  id = 'addNewDeal.name',
  defaultMessage = 'Deal Name',
}

export enum AddNewTemplateCountry {
  id = 'addNewTemplate.country',
  defaultMessage = 'Country',
}

export enum AddNewTemplateProvince {
  id = 'addNewTemplate.state',
  defaultMessage = 'Province / State',
}

export enum EditClauseOnSave {
  id = 'cta.save-clause',
  defaultMessage = 'Save Clause',
}

export enum EditTemplateOnSave {
  id = 'cta.save-template',
  defaultMessage = 'Save Template',
}

export enum CreateNewTemplateOnSave {
  id = 'cta.create-template',
  defaultMessage = 'Create Template',
}

export enum CreateNewClauseOnSave {
  id = 'cta.create-clause',
  defaultMessage = 'Create Clause',
}

export enum AddNewTemplateRestrictedEditing {
  id = 'addNewTemplate.restricted_editing',
  defaultMessage = 'Restricted Editing',
  TOOLTIP_MESSAGE = 'Restricted editing allows you to define which parts of the template can be editable when used for a document.',
}

export enum TemplateErrorMessage {
  CREATE_TEMPLATE = 'There was an error while creating the Template. Please try again',
}

export enum EditExpiryDateErrorMessage {
  EDIT_EXPIRY_DATE = 'There was en error while editing the expiry date. Please try again',
}

export enum CreateDealErrorMessage {
  CREATE_DEAL = 'There was en error while creating the deal. Please try again',
}

export enum SendDealErrorMessage {
  SEND_DEAL = 'There was an error inviting Team to Deal. Please try again',
}

export enum AcceptInvitationErrorMessage {
  SAME_TEAM_SERVER_MESSAGE = 'The receiver team cannot be the same as the creator team.',
  SAME_TEAM_MESSAGE = 'You cannot assign the selected team to this deal as it is the team which created the deal. Select another team or create a new one for this deal.',
}

export enum CreateTeamErrorMessage {
  CREATE_TEAM = 'There was an error creating a Team. Please try again',
}

export enum SendDealNoAccessErrorMessage {
  SEND_DEAL = 'You do not have access to this feature. Please contact Propdocs.',
}

export enum SendForSigningSuccessMessage {
  SEND_FOR_SIGNING = 'Document has been sent for signing.',
}

export enum InviteTeamMember {
  FOR_DEAL = 'Invite a new member to your team for this deal',
  FOR_DEAL_DESCRIPTION = 'Type in the email addresses of new team members you want to invite. They will receive an invite to your team and this deal.',
  UPDATE_ACCESS = 'Update existing team members access',
}

export const DEFAULT_OPTION_ALL = 'all';

export const DEFAULT_OPTION_ALL_TYPES = 'all_types';

export const COMING_SOON_LABEL = 'Coming Soon!';

export const REQUIRED_LABEL = '*Required';

export const NO_TEAM_MESSAGE = 'This user does not belong to any workspaces in Propdocs.';
