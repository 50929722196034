import React, { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import { Dropdown, DropdownBody } from '../dropdown';
import { DropdownActions } from './DocumentTableConstants';
import { ReactComponent as DotsIcon } from '../../shared/icons/dotsRoundIcon.svg';
import styles from './DocumentTable.module.scss';

interface DocumentActionsProps {
  allDropDownActions: DropdownActions;
}

type Props = {
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children: ReactNode;
};

const CustomToggle = React.forwardRef<any, Props>(({ children, onClick }, ref) => (
  <a
    data-testid="dropdown-document-actions"
    className={styles.dottedDropdown}
    href=""
    ref={ref}
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <DotsIcon />
  </a>
));

const DocumentActionsDropdown = ({ allDropDownActions }: DocumentActionsProps) => {
  const getAvailableDropdownActions = (allDropDownActions: DropdownActions) => {
    return allDropDownActions
      .filter(action => action.isAvailable)
      .map(action => (
        <Dropdown.Item
          as={Button}
          key={action.id}
          variant="link"
          onClick={action.onClick}
          data-testid={action.testId}
          disabled={!!action.disabled}
        >
          {action.text}
        </Dropdown.Item>
      ));
  };

  return (
    <Dropdown className={styles.documentDropdown}>
      <Dropdown.Toggle id="dropdown-deal-actions" as={CustomToggle} />
      <Dropdown.Menu>
        <DropdownBody>{getAvailableDropdownActions(allDropDownActions)}</DropdownBody>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DocumentActionsDropdown;
