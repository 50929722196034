import { useCallback } from 'react';
import { createDocumentCommentsWithResult } from "../../../../utils/api/document-comments/create-comments";
import { useApolloClient } from "@apollo/client";
import { TemplateEditorErrors } from "../constants/EditorConstats";
import {
  useIntlFormatMessage
} from "../../../../pages/lease-document-wizard/shared/hooks/use-intl-format-message/use-intl-format-message.hook";
import { CommentInputType } from "../../../../gql/graphql";
import { createDocumentCommentWithResult } from "../../../../utils/api/document-comments/create-comment";
import { updateDocumentCommentsWithResult } from "../../../../utils/api/document-comments/update-comments";
import { updateDocumentComment } from "../../../../utils/api/document-comments/update-comment";
import { resolveDocumentCommentsWithResult } from "../../../../utils/api/document-comments/resolve-comments";
import { reopenDocumentComments } from "../../../../utils/api/document-comments/reopen-comments";
import { removeDocumentCommentWithResult } from "../../../../utils/api/document-comments/remove-comment";
import { removeDocumentComments } from "../../../../utils/api/document-comments/remove-comments";
import { useUpdateComments } from "../../../../contexts/commentContext";
import { createTemplateCommentsWithResult } from "../../../../utils/api/template-comments/create-comments";
import {
  getCommentsForLeaseDocumentSection
} from "../../../../utils/api/get-comments-for-lease-document-section/get-comments-for-lease-document-section";
import {
  getCommentsForTemplateSection
} from "../../../../utils/api/get-comments-for-template/get-comments-for-template";
import { updateTemplateCommentsWithResult } from "../../../../utils/api/template-comments/update-comments";
import { createTemplateCommentWithResult } from "../../../../utils/api/template-comments/create-comment";
import { removeTemplateCommentWithResult } from "../../../../utils/api/template-comments/remove-comment";
import { removeTemplateComments } from "../../../../utils/api/template-comments/remove-comments";
import { updateTemplateComment } from "../../../../utils/api/template-comments/update-comment";
import { resolveTemplateCommentsWithResult } from "../../../../utils/api/template-comments/resolve-comments";
import { reopenTemplateComments } from "../../../../utils/api/template-comments/reopen-comments";

interface Props {
  type: "TEMPLATE" | "DOCUMENT",
  documentId: string,
}
export const useEditorConfigFunctions = ({ type, documentId }: Props) => {
  const apolloClient = useApolloClient();
  const intlFormatMessage = useIntlFormatMessage();
  const updateComments = useUpdateComments();

  const handleCommentThreadCreationError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_THREAD_CREATE);
  }, [intlFormatMessage]);

  const handleCommentCreationError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_CREATE);
  }, [intlFormatMessage]);

  const handleCommentUpdateError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_UPDATE);
  }, [intlFormatMessage]);

  const handleCommentThreadResolveError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_THREAD_RESOLVE);
  }, [intlFormatMessage]);

  const handleCommentThreadUpdateError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_THREAD_UPDATE);
  }, [intlFormatMessage]);

  const handleReopenCommentThreadError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_REOPEN);
  }, [intlFormatMessage]);

  const handleRemoveCommentError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_REMOVE);
  }, [intlFormatMessage]);

  const handleRemoveCommentThreadError = useCallback(() => {
    intlFormatMessage(TemplateEditorErrors.COMMENT_THREAD_REMOVE);
  }, [intlFormatMessage]);

  const addCommentThread = useCallback(
    async ({
             threadId,
             attributes,
             comments,
             resolvedAt,
             resolvedBy,
             unlinkedAt,
           }
    ) => {
      if (!documentId) {
        return false
      }
      switch (type) {
        case "TEMPLATE":
          return await createTemplateCommentsWithResult({
            templateId: documentId.toString(),
            threadId,
            attributes,
            comments,
            resolvedAt,
            resolvedBy,
            unlinkedAt
          })(apolloClient)
            .then((data: any) => {
              if (!data) {
                handleCommentThreadCreationError();
              }
              updateComments(data.comments);
              return data;
            })
            .catch((error) => {
              console.log('error', error);
              handleCommentThreadCreationError();
            })
        case "DOCUMENT":
          return await createDocumentCommentsWithResult({
            documentId: documentId.toString(),
            threadId,
            attributes,
            comments,
            resolvedAt,
            resolvedBy,
            unlinkedAt
          })(apolloClient)
            .then((data: any) => {
              if (!data) {
                handleCommentThreadCreationError();
              }
              updateComments(data.comments);
              return data;
            })
            .catch((error) => {
              console.log('error', error);
              handleCommentThreadCreationError();
            })
      }
    }, [apolloClient, documentId, handleCommentThreadCreationError, type, updateComments]
  );

  const getCommentThread = useCallback(
    async (threadId: string) => {
      if (!documentId || !threadId) {
        return false;
      }

      // @TODO: Add some caching?
      // Maybe preload all the existing comments, and only do this fetch if the requested threadId isn't already loaded?
      // (That would also require removing the existing on from the cache if its content is updated, so it can be refetched...)

      switch (type) {
        case "TEMPLATE":
          return getCommentsForTemplateSection(
            documentId.toString(),
            threadId
          )(apolloClient).then((data: any) => {

            const CKEComments = data.comments.map((comment: any) => {
              return {
                commentId: comment.commentId,
                authorId: comment.authorId,
                createdAt: comment.createdAt,
                content: comment.content,
                attributes: comment.attributes,
                threadId: comment.threadId,
              }
            })

            updateComments(data.comments)

            return {
              ...data,
              comments: CKEComments,
            }
          })
            .catch((error: any) => {
              console.log(error)
            });

        case "DOCUMENT":
          return await getCommentsForLeaseDocumentSection(
            documentId.toString(),
            threadId,
          )(apolloClient).then((data: any) => {

            const CKEComments = data.comments.map((comment: any) => {
              return {
                commentId: comment.commentId,
                authorId: comment.authorId,
                createdAt: comment.createdAt,
                content: comment.content,
                attributes: comment.attributes,
                threadId: comment.threadId,
              }
            })

            updateComments(data.comments)

            return Promise.resolve({
              ...data,
              comments: CKEComments,
            })
          })
            .catch((error) => {
              console.log(error)
            });
      }
    },
    [apolloClient, documentId, type, updateComments]
  );

  const updateCommentThread: any = useCallback(
    async (threadId: string, comments: CommentInputType[], unlinkedAt: string) => {
      if (!documentId || !threadId || !comments) {
        return false;
      }

      switch (type) {
        case "TEMPLATE":
          return await updateTemplateCommentsWithResult({
            templateId: documentId,
            threadId,
            comments,
            unlinkedAt
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleCommentThreadUpdateError();
              }
              return success;
            })
            .catch(() => {
              handleCommentThreadUpdateError();
            });
        case "DOCUMENT":
          return await updateDocumentCommentsWithResult({
            documentId,
            threadId,
            comments,
            unlinkedAt
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleCommentThreadUpdateError();
              }
              return success;
            })
            .catch(() => {
              handleCommentThreadUpdateError();
            });
      }
    }, [apolloClient, documentId, handleCommentThreadUpdateError, type]
  );

  const postComment: any = useCallback(
    (comment: CommentInputType) => {
      if (!comment || ! documentId) {
        return
      }

      switch (type) {
        case "TEMPLATE":
          return createTemplateCommentWithResult({
            templateId: documentId,
            comment,
          })(apolloClient)
            .then((resultData: { [key: string]: any } | boolean) => {
              if (!resultData) {
                handleCommentCreationError();
              }
              if (typeof resultData === 'object') {
                resultData.visibility = 'public';
              }

              updateComments([resultData]);

              return Promise.resolve(resultData);
            })
            .catch((error) => {
              console.log(error)
              handleCommentCreationError();
            });
        case "DOCUMENT":
          return createDocumentCommentWithResult({
            documentId,
            comment,
          })(apolloClient)
            .then((resultData: { [key: string]: any } | boolean) => {
              if (!resultData) {
                handleCommentCreationError();
              }
              if (typeof resultData === 'object') {
                resultData.visibility = 'public';
              }

              updateComments([resultData]);

              return Promise.resolve(resultData);
            })
            .catch((error) => {
              console.log(error)
              handleCommentCreationError();
            });
      }
    },
    [apolloClient, documentId, handleCommentCreationError, type, updateComments]
  );

  const removeComment: any = useCallback(
    (commentId: string, threadId: string) => {
      if (!threadId || !documentId || !commentId) {
        return false;
      }

      switch (type) {
        case "TEMPLATE":
          return removeTemplateCommentWithResult({
            threadId,
            templateId: documentId,
            commentId,
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleRemoveCommentError();
              }
              return Promise.resolve();
            })
            .catch(() => {
              handleRemoveCommentError();
            });
        case "DOCUMENT":
          return removeDocumentCommentWithResult({
            threadId,
            documentId,
            commentId,
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleRemoveCommentError();
              }
              return Promise.resolve();
            })
            .catch(() => {
              handleRemoveCommentError();
            });
      }
    }, [apolloClient, documentId, handleRemoveCommentError, type]
  );

  const removeCommentThread: any = useCallback(
    (threadId: string) => {
      if (!threadId || !documentId) {
        return false;
      }

      switch (type) {
        case "TEMPLATE":
          return removeTemplateComments({
            threadId,
            templateId: documentId
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleRemoveCommentThreadError();
              }
              return Promise.resolve(success);
            })
            .catch((error) => {
              console.log(error);
              handleRemoveCommentThreadError();
            });
        case "DOCUMENT":
          return removeDocumentComments({
            threadId,
            documentId
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleRemoveCommentThreadError();
              }
              return Promise.resolve(success);
            })
            .catch((error) => {
              console.log(error);
              handleRemoveCommentThreadError();
            });
      }
    }, [apolloClient, documentId, handleRemoveCommentThreadError, type]
  );

  const updateComment: any = useCallback(
    (comment: CommentInputType) => {
      if (!documentId || !comment) {
        return Promise.resolve();
      }

      switch (type) {
        case "TEMPLATE":
          return updateTemplateComment({
            templateId: documentId,
            comment,
          })(apolloClient)
            .then((resultData: any) => {
              if (!resultData) {
                handleCommentUpdateError();
              }
              return Promise.resolve(resultData);
            })
            .catch(() => {
              handleCommentUpdateError();
            });
        case "DOCUMENT":
          return updateDocumentComment({
            documentId,
            comment,
          })(apolloClient)
            .then((resultData: any) => {
              if (!resultData) {
                handleCommentUpdateError();
              }
              return Promise.resolve(resultData);
            })
            .catch(() => {
              handleCommentUpdateError();
            });
      }
    },
    [apolloClient, documentId, handleCommentUpdateError, type]
  );

  const resolveCommentThread: any = useCallback(
    (threadId: string) => {
      if (!threadId || !documentId) {
        return false;
      }

      switch (type) {
        case "TEMPLATE":
          return resolveTemplateCommentsWithResult({
            threadId,
            templateId: documentId,
          })(apolloClient)
            .then((data: any) => {
              if (!data) {
                handleCommentThreadResolveError();
              }
              return Promise.resolve({
                threadId: data.threadId,
                resolvedAt: data.resolvedAt,
                resolvedBy: data.resolvedBy,
              });
            })
            .catch((error) => {
              console.log(error)
              handleCommentThreadResolveError();
            });
        case "DOCUMENT":
          return resolveDocumentCommentsWithResult({
            threadId,
            documentId,
          })(apolloClient)
            .then((data: any) => {
              if (!data) {
                handleCommentThreadResolveError();
              }
              return Promise.resolve({
                threadId: data.threadId,
                resolvedAt: data.resolvedAt,
                resolvedBy: data.resolvedBy,
              });
            })
            .catch((error) => {
              console.log(error)
              handleCommentThreadResolveError();
            });
      }
    },
    [apolloClient, documentId, handleCommentThreadResolveError, type]
  );

  const reopenCommentThread: any = useCallback(
    (threadId: string) => {
      if (!documentId || !threadId) {
        return false;
      }

      switch (type) {
        case "TEMPLATE":
          return reopenTemplateComments({
            templateId: documentId,
            threadId,
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleReopenCommentThreadError();
              }
              return Promise.resolve(success);
            })
            .catch(() => {
              handleReopenCommentThreadError();
            });
        case "DOCUMENT":
          return reopenDocumentComments({
            documentId,
            threadId,
          })(apolloClient)
            .then((success: any) => {
              if (!success) {
                handleReopenCommentThreadError();
              }
              return Promise.resolve(success);
            })
            .catch(() => {
              handleReopenCommentThreadError();
            });
      }
    }, [apolloClient, documentId, handleReopenCommentThreadError, type]
  );

  return {
    getCommentThread,
    updateCommentThread,
    addCommentThread,
    postComment,
    updateComment,
    resolveCommentThread,
    reopenCommentThread,
    removeComment,
    removeCommentThread
  };
};
