import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import icon from "./icon.svg";

export default class PublicThreadUI extends Plugin {
    static get requires() {
        return ['Comments']
    }

  init() {
    const editor = this.editor;
    const commentsRepository = editor.plugins.get("CommentsRepository");

    editor.ui.componentFactory.add("publicComment", (locale) => {
      const command = editor.commands.get("addCommentThread");

      const togglePrivateThread = editor.commands.get("togglePrivateThread");
      const buttonView = new ButtonView(locale);

      buttonView.set({
        icon,
        withText: false,
        tooltip: () => "Comment",
      });

      buttonView.bind("isEnabled").to(command, "isEnabled");

      this.listenTo(buttonView, "execute", () => {
        togglePrivateThread.set("isEnabled", false);
        this.editor.execute("addCommentThread", {
          privateThread: (togglePrivateThread.isEnabled = false),
        });
      });

      return buttonView;
    });
  }
}
