import React from 'react';
import className from 'classnames';
import styles from './styles.module.scss';

interface Props {
  isBlue: boolean;
  isFullHeight: boolean;
  children: any;
  title?: string;
  width: string;
}

const DEFAULT_CARD_BOOTSTRAP_WIDTH = '4';

export const Card = ({
  isBlue = false,
  isFullHeight = false,
  children,
  title,
  width = DEFAULT_CARD_BOOTSTRAP_WIDTH,
}: Props) => (
  <div className={`col-lg-${width}`}>
    <div className={className(styles.card, { [styles.cardBlue]: isBlue }, { [styles.cardFullHeight]: isFullHeight })}>
      {title && <h4 className={styles.cardTitle}>{title}</h4>}
      <div className={styles.cardContent}>{children}</div>
    </div>
  </div>
);

export default Card;
