import { TeamWithParty, TeamPermission } from './permissions.reducer'

/**
 * teamPermissions
 */
type SetTeamPermissionsActionType = 'setTeamPermissions'
export type SetTeamPermissionsAction = {
  type: SetTeamPermissionsActionType
  teamPermissions: TeamPermission[]
}

export const setTeamPermissions = (teamPermissions: TeamPermission[]): SetTeamPermissionsAction => ({
  type: 'setTeamPermissions',
  teamPermissions,
})

/**
 * ownedTeams
 */
type SetOwnedTeamsActionType = 'setOwnedTeams'
export type SetOwnedTeamsAction = {
  type: SetOwnedTeamsActionType
  ownedTeams: string[]
}

export const setOwnedTeams = (ownedTeams: string[]): SetOwnedTeamsAction => ({
  type: 'setOwnedTeams',
  ownedTeams,
})

/**
 * allTeams
 */
type SetAllTeamsActionType = 'setAllTeams'
export type SetAllTeamsAction = {
  type: SetAllTeamsActionType
  allTeams: TeamWithParty[]
}

export const setAllTeams = (allTeams: TeamWithParty[]): SetAllTeamsAction => ({
  type: 'setAllTeams',
  allTeams,
})

export type PermissionsActions = SetTeamPermissionsAction | SetOwnedTeamsAction | SetAllTeamsAction
