import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UploadedDocumentsViewer from './UploadedDocumentsViewer';
import { useDocumentRole } from '../../../shared/hooks/useDocumentRole/useDocumentRole';
import {
  selectDocumentImportedAssetContentType,
  selectDocumentImportedAssetUrl,
  selectDocumentOriginalImportedAssetUrl,
  selectIsDocumentEditable,
  selectLeaseDocumentEversignPDFUrl,
  selectLeaseDocumentStatus,
  selectLeaseDocumentTitle,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import LoadingSpinner from '../../../components/loading-spinner';
import { DocumentPermissions } from '../../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { useMutation, useQuery } from '@apollo/client';
import { mutations, queriesV2 } from '../../../api';
import { setLeaseDocumentLastUpdated } from '../../../store/lease-document/actions/lease-document.actions';
import { GET_XFDF_ANNOTATIONS_URL } from '../../signing/prepareToSigningContainer';
import { getCurrentUsername } from '../../../api/queries';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import { useAuth } from '../../../auth';
import { useLeaseDocumentWizardErrorNotifier } from '../../lease-document-wizard/shared/hooks/use-lease-document-wizard-error-notifier/use-lease-document-wizard-error-notifier.hook';
import { LeaseEditorErrors } from '../../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { selectDealId } from '../../../store/deals/deals.selectors';
import { fillPlaceholders, paths } from '../../../routing';
interface Props {
  documentId: string;
  teamId: string;
  userPermissions: DocumentPermissions;
  hasCurrentTeamPossession: boolean;
  isDocumentInReview: boolean;
}

const UploadedDocumentsViewerContainer = ({ documentId, userPermissions, hasCurrentTeamPossession, teamId, isDocumentInReview }: Props) => {
  const { isDocumentCreator } = useDocumentRole(documentId);
  const [{ user }] = useAuth();
  const navigate = useNavigate();
  const isLockedRef = useRef<boolean>(false);
  const [isDocumentLocked, setIsDocumentLocked] = useState(false);
  const userId = user.id;
  const dealId = useSelector(selectDealId);

  const notifyError = useLeaseDocumentWizardErrorNotifier();
  const documentUrl = useSelector(selectDocumentImportedAssetUrl);

  const originalDocumentUrl = useSelector(selectDocumentOriginalImportedAssetUrl);

  const documentTitle = useSelector(selectLeaseDocumentTitle);
  const eversignPDFUrl = (useSelector(selectLeaseDocumentEversignPDFUrl) as string) || (documentUrl as string);

  const documentContentType = useSelector(selectDocumentImportedAssetContentType);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const isDocumentEditable = useSelector(selectIsDocumentEditable);
  const isDocumentExecuted = [DocumentStatusEnum.EXECUTED].includes(documentStatus as DocumentStatusEnum);

  const [lockDocument, { loading: isLockLeaseDocumentLoading }] = useMutation(mutations.lockDocument);
  const [unlockDocument] = useMutation(mutations.unlockDocument);
  const [toggleReceiverCollaborationForDocument] = useMutation(mutations.toggleReceiverCollaborationForDocument);

  const isDocumentPreparingToSignOrSigning =
    documentStatus === DocumentStatusEnum.PREPARING_TO_SIGN || documentStatus === DocumentStatusEnum.SIGNING;

  const dispatch = useDispatch();
  const { data: annotationsData, loading: annotationsDataLoading } = useQuery(GET_XFDF_ANNOTATIONS_URL, {
    variables: {
      documentId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: userData, loading: isUserDataLoading } = useQuery(getCurrentUsername, {
    fetchPolicy: 'network-only',
  });
  const [saveAnnotationsXfDfLayer] = useMutation(mutations.saveAnnotationsXfDfLayer, {
    onCompleted: ({ saveSignaturesLayerForDocument }) => {
      dispatch(setLeaseDocumentLastUpdated(saveSignaturesLayerForDocument!.lastUpdated));
    },
  });

  const [saveCommentsLayerForDocument] = useMutation(mutations.saveCommentsLayerForDocument, {
    onCompleted: ({ saveCommentsLayerForDocument }) => {
      dispatch(setLeaseDocumentLastUpdated(saveCommentsLayerForDocument!.lastUpdated));
    },
  });

  const [saveInputLayerForDocument] = useMutation(mutations.saveInputLayerForDocument, {
    onCompleted: ({ saveInputLayerForDocument }) => {
      dispatch(setLeaseDocumentLastUpdated(saveInputLayerForDocument!.lastUpdated));
    },
  });

  const navigateToDealDetails = useCallback(() => {
    const url = fillPlaceholders(paths.deals.detail, { teamId, dealId });
    navigate(url);
  }, [dealId, teamId, navigate]);

  const handleUnlock = useCallback(() => {
    if (isLockedRef.current) {
      unlockDocument({ variables: { documentId, userId, sessionActivities: [] } });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isDocumentEditable) {
      lockDocument({ variables: { documentId, userId } })
        .then(() => {
          isLockedRef.current = true;
        })
        .catch((err: any) => {
          if (hasCurrentTeamPossession) {
            setIsDocumentLocked(true);
            !isDocumentExecuted && notifyError({ message: LeaseEditorErrors.IS_LOCKED.defaultMessage, pinned: true });
          }
        });
    }

    return () => {
      if (isLockedRef.current && isDocumentEditable) {
        unlockDocument({
          variables: { documentId, userId, sessionActivities: [] },
          refetchQueries: [
            {
              query: queriesV2.getDocumentsByDeal,
              variables: {
                dealId,
              },
            },
          ],
        });
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnlock);

    return () => {
      window.removeEventListener('beforeunload', handleUnlock);
    };
  }, []); // eslint-disable-line

  if (
    !documentUrl ||
    !originalDocumentUrl ||
    !documentContentType ||
    !documentStatus ||
    annotationsDataLoading ||
    isUserDataLoading ||
    isLockLeaseDocumentLoading
  )
    return <LoadingSpinner />;

  const commentsData = annotationsData?.getDocumentById.commentsXfdfUrl;
  const signaturesData = annotationsData?.getDocumentById.signaturesXfdfUrl;
  const formFieldsData = annotationsData?.getDocumentById.inputXfdfUrl;
  return (
    <UploadedDocumentsViewer
      isDocumentCreator={isDocumentCreator}
      document={{
        url: !isDocumentExecuted ? originalDocumentUrl : eversignPDFUrl,
        contentType: documentContentType,
        status: documentStatus,
        id: documentId,
        title: documentTitle || 'Document',
      }}
      hasUserEditPermission={userPermissions.hasEditPermission}
      hasUserCommentPermission={userPermissions.hasCommentPermission}
      saveAnnotations={isDocumentPreparingToSignOrSigning ? saveAnnotationsXfDfLayer as any : saveCommentsLayerForDocument as any}
      saveFormFields={saveInputLayerForDocument as any}
      saveSignaturesLayer={saveAnnotationsXfDfLayer as any}
      toggleReceiverCollaborationForDocument={toggleReceiverCollaborationForDocument as any}
      annotationsStringUrl={(isDocumentPreparingToSignOrSigning ? signaturesData : commentsData) || null}
      formFieldsUrl={formFieldsData!}
      userData={userData!.currentUser!}
      isDocumentLocked={isDocumentLocked}
      navigateToDealDetails={navigateToDealDetails}
      isDocumentEditable={isDocumentEditable}
      isDocumentInReview={isDocumentInReview}
    />
  );
};

export default UploadedDocumentsViewerContainer;
