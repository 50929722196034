import React from 'react';
import { Col, Modal, ModalBody, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { RadioField } from '../../../checkbox-or-radio-field';
import { Formik, Form } from 'formik';
import { InlineSelectField, InlineTextField } from '../../../fields';
import LoadingSpinner from '../../../loading-spinner';
import { FormikValuesEnum } from './UserHasUnassignedDealsModalContainer';
import { useUserRestrictions } from '../../../../auth/user-restrictions';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface UserHasUnassignedDealsModalProps {
  handleHide: () => void;
  showSubscriptionRequiredModal: () => void;
  isLoading: boolean;
  dealTitle: string;
  onSubmit: (values: any) => void;
  existingTeams: { label: { defaultMessage: string; id: string }; value: string }[];
}

const UserHasUnassignedDealsModal = ({
  handleHide,
  isLoading,
  onSubmit,
  existingTeams,
  dealTitle,
  showSubscriptionRequiredModal,
}: UserHasUnassignedDealsModalProps) => {
  const validateSelectedField = (value: any) => !value.trim();
  const { canCreateTeam } = useUserRestrictions();
  const renderModalContent = () => (
    <Formik
      initialValues={{ assignDealTeam: FormikValuesEnum.assignExistingDealTeam, newTeamName: '', existingTeamId: '' }}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, resetForm }) => {
        const isExistingTeamSelected = values.assignDealTeam === FormikValuesEnum.assignExistingDealTeam;
        const onNewWorkspaceClick = () => {
          return (event: React.MouseEvent) => {
            if (!canCreateTeam) {
              event.stopPropagation();
              event.preventDefault();
              showSubscriptionRequiredModal();
            }
          };
        };

        return (
          <>
            <Modal.Header className={styles.header}>
              <Modal.Body className={styles.title}>A deal for {dealTitle} has been shared with you!</Modal.Body>
            </Modal.Header>
            <Modal.Body>
              <p className="pl-3">Select an existing workspace to associate this deal with or create a new one</p>
              <Form noValidate className="pl-5">
                <Row className="align-items-center mb-1">
                  <Col xs={3}>
                    <RadioField
                      id="assignDealForm.team.existing"
                      name="assignDealTeam"
                      value="existing"
                      label={
                        <FormattedMessage id="assignDealForm.team.existing.label" defaultMessage="Existing Workspace" />
                      }
                      hidefeedback="true"
                      wrapperClassName="my-0"
                      onClick={resetForm}
                    />
                  </Col>
                  <Col xs={5}>
                    <InlineSelectField
                      // @ts-ignore
                      id="assignDealForm.team.existing"
                      name="existingTeamId"
                      options={existingTeams}
                      disabled={!isExistingTeamSelected}
                      placeholder="Select"
                      required
                      validate={isExistingTeamSelected && validateSelectedField}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} onClick={onNewWorkspaceClick()}>
                    <RadioField
                      id="assignDealForm.team.new"
                      name="assignDealTeam"
                      value="new"
                      label={
                        <FormattedMessage id="assignDealForm.team.new.label" defaultMessage="New Workspace Name" />
                      }
                      hidefeedback="true"
                      onClick={resetForm}
                    />
                  </Col>
                  <Col xs={5}>
                    <InlineTextField
                      // @ts-ignore
                      id="assignDealForm.newTeamName"
                      data-testid="assignDealForm.newTeamName"
                      name="newTeamName"
                      disabled={isExistingTeamSelected}
                      validate={!isExistingTeamSelected && validateSelectedField}
                      className={isExistingTeamSelected && styles.noValidate}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleSubmit()}>
                <TitleFormattedMessage id="cta.accept-invitation" defaultMessage="Accept Invitation" />
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    </Formik>
  );

  const renderModalLoading = () => (
    <ModalBody className={styles['body_loading']}>
      <LoadingSpinner />
    </ModalBody>
  );

  return (
    <Modal show onHide={handleHide} centered dialogClassName={styles.wrapper}>
      {isLoading && renderModalLoading()}
      {!isLoading && renderModalContent()}
    </Modal>
  );
};

export default UserHasUnassignedDealsModal;
