import React, { ExoticComponent, Fragment, ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import ErrorMessage from '../components/error-message';
import styles from '../pages/deals/styles.module.scss';

interface AddNewTeamFormProps {
  bodyContainer: ExoticComponent<{ children?: ReactNode }>;
  actionsContainer: ExoticComponent<{ children?: ReactNode }>;
  renderSecondaryAction: any;
  errors: any;
  isSubmitting: boolean;
  isValid: boolean;
}

const AddNewTeamForm = ({
  bodyContainer: BodyContainer = Fragment,
  actionsContainer: ActionsContainer = Fragment,
  renderSecondaryAction,
  errors,
  isSubmitting,
  isValid,
}: AddNewTeamFormProps) => {
  return (
    <Form noValidate>
      <div className={styles.modalBody}>
        <BodyContainer>
          <div className="mt-4">
            This new workspace will be associated to your account, and you will be designated as the admin on creation.
            <br />
          </div>
        </BodyContainer>
        <BodyContainer>
          {errors._general && (
            <Alert variant="danger">
              <ErrorMessage>{errors._general}</ErrorMessage>
            </Alert>
          )}

          <Row noGutters className="justify-content-center flex-nowrap">
            <Col>
              <TextField
                //@ts-ignore TODO update TextField to ts
                id="addNewTeamForm.teamName"
                name="teamName"
                type="teamName"
                label={<FormattedMessage id="addNewTeamForm.teamName" defaultMessage="Workspace Name" />}
              />
            </Col>
          </Row>
        </BodyContainer>
        <ActionsContainer>
          <Row noGutters className="align-items-end">
            <Col>
              {renderSecondaryAction && renderSecondaryAction()}
              <SubmitButton
                label={<FormattedMessage id="cta.send-deal" defaultMessage="Create Workspace" />}
                disabled={isSubmitting || !isValid}
                isSubmitting={isSubmitting}
              />
            </Col>
          </Row>
        </ActionsContainer>
      </div>
    </Form>
  );
};

export default AddNewTeamForm;
