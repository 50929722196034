import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import getCreatorPermissionsEditing from "./creatorPermissionsEditing";

export const getCreatorPermissions = ({isCreator, permissions, isShared, documentStatus}) => {
	class CreatorPermissions extends Plugin {
		static get requires() {
			return [ getCreatorPermissionsEditing(isCreator, permissions, isShared, documentStatus) ];
		}
	}

	return CreatorPermissions
}

export default getCreatorPermissions;
