/**
 * Slugify a string
 *
 * @function slugify
 * @param {string} input - String to slugify
 * @returns {string} - Lowercase slug with hyphen separators if necessary
 */
export default function slugify(input) {
  return input
    .trim()
    .toLocaleLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
}
