import React from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'react-bootstrap';
import { LeaseDocumentWizardModal } from '../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { OptionalMessageInputRef } from '../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/OptionalMessageInputRef';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';
import styles from './styles.module.scss';

interface LeaseDocumentWizardConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  handleEdit: () => void;
  isUnsignPending: boolean;
  optionalMessageInputRef?: React.Ref<HTMLTextAreaElement>;
}

const REMOVE_SIGNATURES_MODAL =
  'If you cancel the signing session all signatures will be removed. Removing signatures is irreversible and will require signers to re-sign when the document is sent back for signing';

export const UnsignDocumentConfirmationModal = ({
  show,
  onHide,
  handleEdit,
  isUnsignPending,
  optionalMessageInputRef,
}: LeaseDocumentWizardConfirmationModalProps) => (
  <LeaseDocumentWizardModal
    size="lg"
    show={show}
    onHide={onHide}
    titleClassName={styles.title}
    title={
      <FormattedMessage
        id="lease-document-wizard-modal.unsign-confirmation-modal-title"
        defaultMessage="Cancel Signing"
      />
    }
    modalCTAs={[]}
  >
    <section className={classNames('lease-document-wizard-unsign-confirmation-modal', styles.wrapper)}>
      <FormattedMessage
        id="lease-document-wizard-modal.lease-confirmation-content"
        defaultMessage={REMOVE_SIGNATURES_MODAL}
      />
    </section>
    <OptionalMessageInputRef ref={optionalMessageInputRef} />
    <div className={classNames('d-flex justify-content-end', styles.buttonsWrapper)}>
      <Button variant="outline-warning" className="mx-2" onClick={onHide}>
        <TitleFormattedMessage
          id="lease-document-wizard-modal.lease-confirmation-button-cancel"
          defaultMessage="Close"
        />
      </Button>
      <Button
        variant="warning"
        className={classNames('mx-2', styles['edit-button'])}
        onClick={handleEdit}
        disabled={isUnsignPending}
      >
        {isUnsignPending && <Spinner animation="border" size="sm" />}
        <TitleFormattedMessage
          id="lease-document-wizard-modal.lease-confirmation-button-remove-signature"
          defaultMessage="Confirm"
        />
      </Button>
    </div>
  </LeaseDocumentWizardModal>
);
