import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';

export const GET_DOCUMENT_SUGGESTION_BY_EXTERNAL_ID =  graphql(/* GraphQL */`
  query GetDocumentSuggestionByExternalId($documentSuggestionId: ID!) {
    getDocumentSuggestionByExternalId(documentSuggestionExternalId: $documentSuggestionId) {
      id
      externalId
      attributes
      data
      type
      createdAt
      authorRole
      status
      author {
        id
        email
      }
    }
  }
`);

export const getDocumentSuggestionById = (documentSuggestionId: string) => (
  client: ApolloClient<object>
): Promise<any> => {
  const variables = { documentSuggestionId };

  return client
    .query({ query: GET_DOCUMENT_SUGGESTION_BY_EXTERNAL_ID, variables })
    .then(({ data }: Record<any, any>) => {
      return data.getDocumentSuggestionByExternalId;
    });
};
