import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../components/modal/PropdocsModal';
import styles from './styles.module.scss';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { getDealsForTeam } from '../../../api/queries';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InlineSelectField, InlineTextField, TextField } from '../../../components/fields';
import SubmitButton from '../../../components/submit-button';
import { RadioField } from '../../../components/checkbox-or-radio-field';
import { useMutation, useQuery } from '@apollo/client';
import { createExpressDocumentFromTemplate } from '../../../api/mutations';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../../../components/titleFormattedMessage';

interface CreateExpressTemplateDocumentProps {
  isOpen: boolean;
  onClose: () => void;
  templateId: string;
  templateName: string;
  teamId: string;
}

const schema = yup.object({
  existingDealId: yup.string().nullable(),
  newDealName: yup.string().nullable(),
  assignDeal: yup.string(),
  documentName: yup.string().required('documentName is required'),
}).test(
  'either-or',
  'You must select an existing deal or add a title for a new deal',
  (obj: any) => {
    const { dealId, newDealName, documentName } = obj;
    // Check that exactly one of the fields is filled
    return documentName === '' && ((dealId && !newDealName) || (!dealId && newDealName));
  }
);

enum FormikValuesEnum {
  assignExistingDeal = 'existing',
  assignNewDeal = 'new',
}

const CreateExpressTemplateDocument = ({ isOpen, onClose, templateName, teamId, templateId }: CreateExpressTemplateDocumentProps) => {
  const navigate = useNavigate();

  const { loading: dealsDataLoading, data: dealsData, error: graphqlError } = useQuery(getDealsForTeam, {
    variables: {
      teamId,
    },
  });

  const [triggerCreateExpressDocument] = useMutation(createExpressDocumentFromTemplate)

  const onSubmit = (values: any) => {
    const variables = values.assignDeal === FormikValuesEnum.assignNewDeal
      ? {
        teamId,
        dealTitle: values.newDealName,
        templateId,
        documentTitle: values.documentName
      }
      : {
        teamId,
        dealId: values.existingDealId,
        templateId,
        documentTitle: values.documentName
      }
    triggerCreateExpressDocument({
      variables
    }).then(({ data }) => {
      if (data) {
        const routeRedirect = `teams/${teamId}/deals/${data.createExpressDocumentFromTemplate?.deal.id}/documents/${data.createExpressDocumentFromTemplate?.id}`
        navigate(`/${routeRedirect}`);
      }
    })
  }

  const validateSelectedField = (value: any) => !value?.trim();

  const getSelectDealDropdownOptions = () =>
    dealsData.dealsForTeam.map((deal: any) => ({
      label: {
        id: `assignDeal.teamOption.${deal.id}`,
        defaultMessage: deal.title,
      },
      value: deal.id,
    }));

  return (
    <Modal
      show={isOpen}
      setShow={onClose}
      size="md"
      title={<FormattedMessage id="title.first-lease-template" defaultMessage="Create a Document from Template" />}
    >
      <>
        <p className={styles.createExpressDocumentModal_subheader}><b>Template name: </b>{templateName}</p>
        <div className={styles.createExpressDocumentModal}>
          <Formik validationSchema={schema} onSubmit={onSubmit} isInitialValid={false} initialValues={{
            assignDeal: FormikValuesEnum.assignExistingDeal,
            existingDealId: null,
            newDealName: null,
            documentName: '',
          }}>
            {({
                errors,
                isSubmitting,
                isValid,
                setFieldValue,
                values,
              }) => {
              const isExistingDealSelected = values.assignDeal === FormikValuesEnum.assignExistingDeal;

              return (
                <Form noValidate>
                  <div className={styles.modalBody}>
                    <div>
                      <Row noGutters className="justify-content-center flex-nowrap">
                        <Col>
                          <TextField
                            id="createExpressTemplateDocument.documentName"
                            name="documentName"
                            type="documentName"
                            label={<FormattedMessage id="createExpressTemplateDocument.documentName"
                                                     defaultMessage="Document Name" />}
                          />
                        </Col>
                      </Row>
                      <Row noGutters className="justify-content-center flex-nowrap">
                        <Col>
                          Choose an existing deal or create a new deal to add your document.
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className="align-items-center mb-1 mt-3 w-100">
                        <Col xs={4}>
                          <RadioField
                            id="createExpressDocument.team.existing"
                            name="assignDeal"
                            value="existing"
                            label={
                              <FormattedMessage id="createExpressDocument.team.existing.label"
                                                defaultMessage="Existing Deal" />
                            }
                            hidefeedback="true"
                            wrapperClassName="my-0"
                            onClick={async () => {
                              await setFieldValue('assignDeal', FormikValuesEnum.assignExistingDeal);
                              await setFieldValue('newDealName', null);
                            }}
                          />
                        </Col>
                        <Col xs={8}>
                          <InlineSelectField
                            // @ts-ignore
                            id="createExpressDocument.deal.existing"
                            name="existingDealId"
                            options={getSelectDealDropdownOptions()}
                            disabled={!isExistingDealSelected}
                            placeholder="Select"
                            validate={isExistingDealSelected && validateSelectedField}
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-center mb-1 w-100">
                        <Col xs={4}>
                          <RadioField
                            id="createExpressDocument.deal.new"
                            name="assignDeal"
                            value="new"
                            label={
                              <FormattedMessage id="createExpressDocument.team.new.label"
                                                defaultMessage="New Deal" />
                            }
                            hidefeedback="true"
                            wrapperClassName="my-0"
                            onClick={async () => {
                              await setFieldValue('assignDeal', FormikValuesEnum.assignNewDeal);
                              await setFieldValue('existingDealId', null);
                            }}
                          />
                        </Col>
                        <Col xs={8} >
                          <InlineTextField
                            // @ts-ignore
                            id="createExpressDocument.newDealName"
                            data-testid="createExpressDocument.newDealName"
                            name="newDealName"
                            disabled={isExistingDealSelected}
                            validate={!isExistingDealSelected && validateSelectedField}
                            // className={isExistingDealSelected && styles.noValidate}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className={styles.createExpressDocumentModal_submitButton}>
                      <Row noGutters>
                        <Col>
                          <Button className="mr-3" variant="link-secondary" onClick={onClose} data-testid="cancel-change-password">
                            <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
                          </Button>
                          <SubmitButton
                            label={<FormattedMessage id="cta.create-document" defaultMessage="Create Document" />}
                            disabled={isSubmitting || !isValid}
                            isSubmitting={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </>
    </Modal>
  );
};

export default CreateExpressTemplateDocument;
