export function customCommentMentionRenderer(item: any) {
  const itemElement = document.createElement('span');

  itemElement.classList.add('custom-item');
  itemElement.id = `mention-list-item-id-${item.userId}`;
  itemElement.textContent = `${item.name}`;

  const usernameElement = document.createElement('span');
  usernameElement.classList.add('custom-item-username');
  usernameElement.textContent = ` (${item.email})`;
  itemElement.appendChild(usernameElement);

  return itemElement;
}

export function getFeedItems(queryText: any, userList: any, currentUserTeamMembersList?: any) {
  const activeThread = window.editor.plugins.get('CommentsRepository').activeCommentThread;
  const isPrivatePluginEnabled = window.editor.commands.get('togglePrivateThread').isEnabled;
  const isPrivate = window.commentsContext
    .filter((comment: any) => comment.threadId === activeThread?.id)
    .some((comment: any) => comment.isPublic === false);

  const showCurrentUserList =
    (isPrivate && currentUserTeamMembersList) || (isPrivatePluginEnabled && !activeThread.comments._items.length);

  const usersToDisplay = (showCurrentUserList ? currentUserTeamMembersList : userList)
    .map((user: any) => ({
      // This is the value that will be inserted into the cke mention node.
      id: `@${user.name}`,
      userId: user.id,
      name: user.name,
      email: user.email,
    }))
    // Filter out the full list of all items to only those matching the query text.
    .filter(isUserMatching)
    // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
    .slice(0, 10);

  function isUserMatching(user: any) {
    const searchString = queryText.toLowerCase();

    return user.id.toLowerCase().includes(searchString) || user.name.toLowerCase().includes(searchString);
  }

  return usersToDisplay;
}

export function createCommentThreads(comments: any) {
  let threads = comments.map((comment: any) => {
    const newCommentThread: any = {
      comments: [],
    };
    newCommentThread.isPublic = comment.isPublic;
    newCommentThread.threadId = comment.threadId;
    return newCommentThread;
  });

  threads = threads.filter(filterByUniqueThread);
  for (const thread of threads) {
    for (const comment of comments) {
      if (comment.threadId === thread.threadId) {
        thread.comments.push({
          visibility: comment.visibility,
          commentId: comment.id,
          authorId: comment.author.id,
          content: comment.text,
          createdAt: new Date(comment.date),
        });
      }
    }
  }
  return threads;
}

function filterByUniqueThread(thread: any, idx: any, self: any) {
  return (
    idx ===
    self.findIndex((t: any) => {
      return t.threadId === thread.threadId;
    })
  );
}
