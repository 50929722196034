import { get as getLatestTeamId } from '../../utils/latest-team-id';
import { fillPlaceholders, useNavigate, paths } from '../../routing';
import LoadingSpinner from '../loading-spinner';
import { useAuth } from '../../auth';

const SettingsLanding = () => {
  const [{ authLoading, queryLoading, loggedIn, user }] = useAuth();
  const navigate = useNavigate();
  const loading = authLoading || queryLoading;
  if (loading) {
    return <LoadingSpinner />;
  }

  if (!loggedIn) {
    navigate(paths.auth.login);
  }
  const latestTeamId = getLatestTeamId(user.id);
  const userTeams = user?.allTeams || [];

  const goToOrganizations = (id: string) => {
    setTimeout(() => {
      const urlToNavigate = fillPlaceholders(paths.settings.organizations, { teamId: id });
      navigate(urlToNavigate);
    }, 0);
  };

  if (latestTeamId && userTeams.some(({ id }: { id: string }) => id === latestTeamId)) {
    goToOrganizations(latestTeamId);
    return null;
  }

  return <></>;
};

export default SettingsLanding;
