import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TemplateType } from '../../consts/forms-types';
import { startCase } from 'lodash';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';

type ArchiveModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpdateArchived: () => void;
  templateType: TemplateType | undefined;
};

const ArchiveModal = ({ isOpen, onClose, onUpdateArchived, templateType }: ArchiveModalProps) => {
  const { formatMessage } = useIntl();
  const templateTypeString = (uppercase: boolean = false) => {
    const type = templateType === TemplateType.CLAUSE ? 'clause' : 'template';
    return uppercase ? startCase(type) : type;
  }

  const modalContent = templateType === TemplateType.CLAUSE
    ? 'Archiving a clause means it becomes inactive, you can no longer insert this clause in to a document.'
    : 'Archiving a template means it becomes inactive, you can no longer create documents using this template.'

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header
        closeButton
        closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
        onClick={onClose}
      >
        <Modal.Title>
          <header>
            <FormattedMessage id="title.archive-template" defaultMessage={`Archive ${templateTypeString(true)}`} />
          </header>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage
            id="phrase.archive-template"
            defaultMessage={modalContent}
          />
        </p>
        <p>
          <FormattedMessage
            id="phrase.archive-template-reversal"
            defaultMessage="Archiving can be reversed at a later date."
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="text-secondary" onClick={onClose}>
          <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            onUpdateArchived();
          }}
        >
          <TitleFormattedMessage id="cta.archiveDeal" defaultMessage={`Archive ${templateTypeString(true)}`} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveModal;
