import React from 'react';
import { CommentContextProvider } from '../../../contexts/commentContext';
import { DocumentPermissions } from '../shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { TemplateEditorContainer } from '../../../components/lease-wizard/lease-editor/TemplateEditorContainer';
import { TemplatePageHeader } from './TemplatePageHeader';
import { LeaseDocumentWizardHeaderLastSaved } from '../../../components/lease-wizard/lease-document-wizard-header/lease-document-wizard-header-lastsaved/LeaseDocumentWizardHeaderLastSaved';
import { DocumentSourceEnum } from '../../../components/lease-wizard/lease-editor/constants/EditorConstats';
import AddHeaderAndFooterModalContainer from '../../deals/modals/AddHeaderAndFooterModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsHeaderFooterEditorModalOpen } from '../../../store/modals/modals.selectors';
import { setShowHeaderFooterEditorModal } from '../../../store/modals/modals.actions';
import UploadedTemplatesViewerContainer from '../../uploadedWebViewer/uploadedTemplatesViewer/UploadedTemplatesViewerContainer';
import { TemplateType } from '../../../consts/forms-types';
import { getTemplateType } from '../../../shared/templateType';
import {
  DocumentSubheaderContainer
} from '../../../components/lease-wizard/lease-document-wizard-header/DocumentSubheaderContainer';
import { LeaseDocumentTemplateType } from '../../../components/lease-wizard/lease-document.types';
import TemplateEditorSidenav
  from '../../../components/lease-wizard/documentActivitiesAndTemplatesNavigation/templateEditorSidenav';

export type TemplatePageProps = {
  canEdit: boolean;
  canComment: boolean;
  onSave: any;
  documentId: string;
  templateId: string;
  teamId: any;
  permissions: DocumentPermissions;
  onTitleSave: (title: string) => void;
  onTemplateClose: () => void;
  documentTemplateData: any;
  isOrganizationTemplate?: boolean;
  template: LeaseDocumentTemplateType;
  templateTitle: string;
  lastUpdated: string;
  isTemplateLoaded: boolean;
};

export const TemplatePage = ({
  canEdit,
  canComment,
  onSave,
  documentId,
  teamId,
  permissions,
  onTitleSave,
  onTemplateClose,
  documentTemplateData,
  isOrganizationTemplate,
  templateId,
  template,
  templateTitle,
  lastUpdated,
  isTemplateLoaded,
}: TemplatePageProps) => {
  const dispatch = useDispatch();
  const isTemplateUploaded = documentTemplateData.source === DocumentSourceEnum.IMPORTED;
  const isTemplateApp = documentTemplateData.source === DocumentSourceEnum.APP;
  const isHeaderFooterEditorModalOpen = useSelector(selectIsHeaderFooterEditorModalOpen);

  const handleModalClose = () => {
    dispatch(setShowHeaderFooterEditorModal(false));
  };

  return (
    <div className="lease-document-wizard-page">
      <div>
        {isTemplateLoaded && (
          <TemplateEditorSidenav
            isCollapsible
            onClose={onTemplateClose}
          />
        )}
      </div>
      <div className="lease-document-wizard-page__full-size-layout">
        <div className="lease-document-wizard-page__header-layout">
          <TemplatePageHeader
            canEdit={!isOrganizationTemplate ? canEdit : false}
            isUploaded={isTemplateUploaded}
            title={templateTitle}
            onClose={onTemplateClose}
            onSaveTitle={onTitleSave}
            lastSaved={
              <LeaseDocumentWizardHeaderLastSaved datetime={lastUpdated} intervalInSecond={60} />
            }
            templateTitle={templateTitle || 'Template'}
            templateType={
              documentTemplateData?.templateType === TemplateType.CLAUSE
                ? TemplateType.CLAUSE
                : getTemplateType(documentTemplateData)
            }
            teamId={teamId}
            templateId={templateId}
            teamEditPermissions={permissions.hasEditPermission}
          />
          {isOrganizationTemplate && (
            <DocumentSubheaderContainer
              documentId={documentId}
              teamId={teamId}
              organizationName={isOrganizationTemplate && documentTemplateData.team?.organization?.name}
              orgTemplateSubheader
              isClauseTemplate={documentTemplateData?.templateType === TemplateType.CLAUSE}
            />
          )}
        </div>

        <div className="lease-document-wizard-page__full-size-layout">
          {documentId && isTemplateApp && (
            <CommentContextProvider InitialComments={[]}>
              <TemplateEditorContainer
                documentId={documentId}
                save={onSave}
                leaseTemplate={template || {}}
                templateTitle={templateTitle || 'Template'}
                teamId={teamId}
                canEdit={!isOrganizationTemplate ? canEdit : false}
                canComment={canComment}
                permissions={permissions}
                isRestrictedEditingEnabled={documentTemplateData.editorConfiguration?.restricted_editing}
                isOrganizationTemplate={isOrganizationTemplate}
                organizationName={isOrganizationTemplate && documentTemplateData.team?.organization?.name}
              />
            </CommentContextProvider>
          )}
          {documentId && isTemplateUploaded && (
            <UploadedTemplatesViewerContainer
              templateId={documentId}
              teamId={teamId}
              userPermissions={permissions}
              templateTitle={templateTitle}
              isOrganizationTemplate={isOrganizationTemplate}
              organizationName={isOrganizationTemplate && documentTemplateData.team?.organization?.name}
            />
          )}
          <AddHeaderAndFooterModalContainer
            isOpen={isHeaderFooterEditorModalOpen}
            onClose={handleModalClose}
            documentId={documentId}
            teamId={teamId}
            editorConfiguration={documentTemplateData.editorConfiguration}
            document={template}
            isFromTemplateEditor
          />
        </div>
      </div>
    </div>
  );
};
