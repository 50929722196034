import React, { useCallback, useMemo, useRef, useState } from 'react';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useApolloClient as apolloUseApolloClient, useMutation, useQuery } from '@apollo/client';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../loading-spinner';
import { MentionCustomization } from './utils/mention-customization';
import { mutations, queriesV2 } from '../../../api';
import PropdocsTemplateImageUploadAdapter from '../../../utils/cke-template-image-uploader';
import { EditorType } from './utils/lease-editor.types';
import CommentsAdapter from '../../../utils/cke-comments-adapter';
import { UsersIntegration } from '../../../utils/cke-users-integration';
import { customCommentMentionRenderer, getFeedItems } from './comments/lease-editor-comments';
import { DocumentPermissions } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { exportPdfConfig, listProperties, MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR } from './constants/EditorConstats';
import TimeoutModal from '../lease-document-wizard-modal/custom-modals/timeoutModal/TimeoutModal';
import { TrackChangesIntegration } from '../../../utils/ckeditor/trackChangesAdapter';
import {
  CreateSuggestionPayload,
  DocumentTypesEnum,
} from '../../../utils/ckeditor/trackChangesAdapter/types';
import { InsertedFieldsTooltip } from '../../shared/insertFieldsTooltip';
import RevisionHistoryAdapter from '../../../utils/ckeditor/revisionHistoryAdapter';
import {
  ALIGNMENT_CONFIG,
  FONT_BG_COLOR_CONFIG,
  FONT_COLOR_CONFIG,
  FONT_FAMILY_CONFIG,
  FONT_SIZE_CONFIG,
  HIGHLIGHT_CONFIG,
  PAGINATION_CONFIG,
  TABLE_CONFIG,
} from '../../../utils/ckeditor/config/constants';
import { preventEditorEnabling, updateListsDropdownValue } from '../../../utils/ckeditor/utils';
import './lease-editor.scss';
import {
  addSessionActivity,
  setDocumentCompareModeEnabled,
  setLeaseEditorIsLoaded,
} from '../../../store/lease-document/actions/lease-document.actions';
import {
  handleInlineNegativeTextIndent,
  handleLeftMarginRemovalFromFigure,
  handleLetterSpacingRemovalOnImportWord,
  handleLineHeightRemovalOnImportWord,
  handleListStylingOnImportWord,
  handleMarginStylesRemovalOnImportWord,
  handleNegativeMarginRemovalOnImportWord,
  handleSaveRevision,
} from './constants/helpers';
import { setShowHeaderFooterEditorModal } from '../../../store/modals/modals.actions';
import { LeaseDocumentComment } from '../lease-document.types';
import useEditorSetupData from './hooks/useEditorSetupData';
import { selectLeaseDocumentCloudVersion } from '../../../store/lease-document/selectors/lease-document.selectors';
import createPlaceholderAdapter from '../../../utils/ckeditor/placeholderAdapter/placeholderAdapter';

declare global {
  interface Window {
    editor: EditorType;
    commentsContext: LeaseDocumentComment[];
  }
}

const IDLE_TIMER_TIMEOUT_MILLISECONDS = 1000 * 60 * 10;

type TemplateEditorPropTypes = {
  editor: any;
  user: any;
  leaseTemplate: Record<string, any>;
  documentId?: string;
  userList: any;
  addCommentThread: any;
  postComment: any;
  updateComment: any;
  updateCommentThread: any;
  resolveCommentThread: any;
  reopenCommentThread: any;
  removeComment: any;
  removeCommentThread: any;
  getCommentThread: any;
  permissions: DocumentPermissions;
  canEdit: boolean;
  canComment: boolean;
  isTemplateLocked: boolean;
  notifyError: ({ message, pinned }: { message: string; pinned: boolean }) => void;
  saveData: (document: any) => void;
  createSuggestion: (data: CreateSuggestionPayload) => Promise<any>;
  getSuggestionById: (suggestionId: string) => Promise<any>;
  deleteSuggestionById: (suggestionId: string) => Promise<any>;
  acceptSuggestionById: (suggestionId: string, suggestionData: string) => Promise<any>;
  rejectSuggestionById: (suggestionId: string, suggestionData: string) => Promise<any>;
  pendSuggestionById: (suggestionId: string) => Promise<any>;
  teamId: string | null;
  save: any;
  getDocumentRevisions: any;
  createDocumentRevision: any;
  isRestrictedEditingEnabled: boolean;
  templateTitle: string;
  isOrganizationTemplate?: boolean;
};

export const TemplateEditor = ({
  editor,
  user,
  addCommentThread,
  postComment,
  updateComment,
  updateCommentThread,
  resolveCommentThread,
  reopenCommentThread,
  removeComment,
  removeCommentThread,
  getCommentThread,
  leaseTemplate,
  documentId,
  userList,
  notifyError,
  permissions,
  createSuggestion,
  getSuggestionById,
  deleteSuggestionById,
  acceptSuggestionById,
  rejectSuggestionById,
  pendSuggestionById,
  teamId,
  save,
  getDocumentRevisions,
  createDocumentRevision,
  canEdit,
  canComment,
  isTemplateLocked,
  isRestrictedEditingEnabled,
  templateTitle,
  isOrganizationTemplate,
}: TemplateEditorPropTypes) => {
  const toolbarElem = useRef<HTMLDivElement>(null);
  const revisionToolbarElem = useRef<HTMLDivElement>(null);
  const sidebarNode = useRef<HTMLDivElement>(document.createElement('div'));
  const apolloClient = apolloUseApolloClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idleTimerRef = useRef<IdleTimer>(null);
  const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false);

  const [createDocumentPlaceholder] = useMutation(mutations.createDocumentPlaceholder);
  const [updateDocumentPlaceholder] = useMutation(mutations.updateDocumentPlaceholder);

  const {
    loading: placeholderCompletenessLoading,
    data
  } = useQuery(queriesV2.getDocumentPlaceholderCompleteness, {
    skip: !documentId,
    variables: {
      documentId: String(documentId),
      documentType: 'DocumentTemplate'
    },
  })

  const {
    isLayoutReady,
    setCkeInstance,
    ckeInstance,
  } = useEditorSetupData({ resize: false, documentId, documentType: 'document', apolloClient });

  const navigateToDealDetails = useCallback(() => {
    const route = `/teams/${teamId}/templates`;

    navigate(route);
  }, [teamId, navigate]);

  const customUploadAdapter = useCallback(
    function(editor: EditorType) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return new PropdocsTemplateImageUploadAdapter(loader, apolloClient, documentId, notifyError);
      };
    },
    [apolloClient, documentId, notifyError]
  );

  const usersPluginIntegration = useCallback(
    function(editor: EditorType) {
      return new UsersIntegration(editor, user, userList);
    },
    [user, userList]
  );

  const MentionPlugin = useMemo(() => {
    return editor.builtinPlugins.find((plugin: any) => plugin.pluginName === 'Mention');
  }, [editor.builtinPlugins]);

  const handleAutoSave = useCallback(
    (editor: EditorType) => {
      if (permissions.hasEditPermission || permissions.hasCommentPermission) {
        const revisionTracker = editor.plugins.get('RevisionTracker');
        return revisionTracker.update();
      }
    },
    [permissions.hasCommentPermission, permissions.hasEditPermission]
  );

  const saveSessionActivity = useCallback(
    (activity: string) => {
      dispatch(addSessionActivity(activity));
    },
    [dispatch]
  );

  const tokenUrlCallback = useCallback(() => {
    if (!documentId) {
      return;
    }
    const queryVariables = { documentTemplateId: documentId };
    const queryPromise = apolloClient.query({
      query: queriesV2.getCkeditorTemplateToken,
      variables: queryVariables,
      fetchPolicy: 'no-cache',
    });

    return queryPromise.then(({ data }: Record<any, any>) => data.getCkeditorTemplateToken);
  }, [apolloClient, documentId]);

  const hasOnlyCommentsPermission = !permissions.hasEditPermission && permissions.hasCommentPermission;
  const hasUserCommentsPermission = permissions.hasCommentPermission;

  const formattingToolbarOptions = [
    'heading',
    'Bold',
    'Italic',
    'Underline',
    'Highlight',
    'FontSize',
    'FontFamily',
    'FontColor',
    'fontBackgroundColor',
    'Indent',
    'Outdent',
    'alignment',
    'numberedList',
    'bulletedList',
  ];

  const toolbar = isOrganizationTemplate && !canEdit ? [] : [
      'revisionHistory',
      '|',
      'Undo',
      'Redo',
      'ExportPdf',
      'importWord',
      'editHeaderFooter',
      '|',
      'pageNavigation',
      '|',
      'formattingOptions',
      '|',
      {
        label: 'Insert',
        icon: false,
        items: [
          //'publicComment',
          'imageUpload',
          'insertTable',
          'pageBreak',
          'insertInlineCheckbox',
          !isRestrictedEditingEnabled && 'placeholder',
        ],
      },
      // TODO: Re-enable when it is fixed
      // 'commentsArchive',
      '|',
      'findAndReplace',
      isRestrictedEditingEnabled && 'restrictedEditingException',
    ];

  const cloudDocumentVersion = useSelector(selectLeaseDocumentCloudVersion);

  const config = {
    licenseKey: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
    default_styles: true,
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
      disallow: [
        {
          name: 'script',
        },
        {
          attributes: [
            {
              key: /^on(.*)/i,
              value: true,
            },
            {
              key: /.*/,
              value: {
                lastIndex: 0,
                dotAll: false,
                flags: 'i',
                global: false,
                hasIndices: false,
                ignoreCase: true,
                multiline: false,
                source: '(\\b)(on\\S+)(\\s*)=|javascript:|(<\\s*)(\\/*)script',
                sticky: false,
                unicode: false,
              },
            },
            {
              key: /.*/,
              value: {
                lastIndex: 0,
                dotAll: false,
                flags: 'i',
                global: false,
                hasIndices: false,
                ignoreCase: true,
                multiline: false,
                source: 'data:(?!image\\/(png|jpeg|gif|webp))',
                sticky: false,
                unicode: false,
              },
            },
          ],
        },
      ],
    },
    cloudServices: {
      tokenUrl: tokenUrlCallback,
      uploadUrl: process.env.REACT_APP_CKEDITOR_IMAGE_UPLOAD_URL,
      webSocketUrl: process.env.REACT_APP_CKEDITOR_WEBSOCKET_URL,
      documentId: documentId,
    },
    toolbar,
    formattingOptions: [
      'heading',
      'Bold',
      'Italic',
      'Underline',
      'Highlight',
      'FontSize',
      'FontFamily',
      'FontColor',
      'fontBackgroundColor',
      'Indent',
      'Outdent',
      'alignment',
      'numberedList',
      'bulletedList',
    ],
    balloonToolbar: [
      isRestrictedEditingEnabled && 'restrictedEditingException',
      //hasUserCommentsPermission && 'publicComment',
      !isRestrictedEditingEnabled && 'placeholder',
    ],
    highlight: HIGHLIGHT_CONFIG,
    fontSize: FONT_SIZE_CONFIG,
    fontFamily: FONT_FAMILY_CONFIG,
    fontColor: FONT_COLOR_CONFIG,
    fontBackgroundConfig: FONT_BG_COLOR_CONFIG,
    extraPlugins: [
      customUploadAdapter,
      usersPluginIntegration,
      //CommentsAdapter,
      TrackChangesIntegration,
      RevisionHistoryAdapter,
      createPlaceholderAdapter(
        teamId,
        documentId,
        createDocumentPlaceholder,
        updateDocumentPlaceholder,
      ),
    ],
    removePlugins: [
      'EasyImage',
      'ImageCaption',
      'List',
      'TodoListEditing',
      'TodoList',
      'Base64UploadAdapter',
    ],
    pagination: PAGINATION_CONFIG,
    importWord: {
      defaultStyles: true,
      tokenUrl: tokenUrlCallback,
    },
    autosave: !isOrganizationTemplate
      ? {
        waitingTime: 5000,
        save: canEdit || canComment ? handleAutoSave : () => {},
      }
      : null,
    // commentsOnly: hasOnlyCommentsPermission,
    // comments: {
    //   editorConfig: {
    //     extraPlugins: [MentionPlugin, MentionCustomization],
    //     mention: {
    //       feeds: [
    //         {
    //           marker: '@',
    //           feed: (queryText: any) => getFeedItems(queryText, userList),
    //           itemRenderer: customCommentMentionRenderer,
    //         },
    //       ],
    //     },
    //   },
    // },
    sidebar: {
      container: sidebarNode.current,
      preventScrollOutOfView: true,
    },
    editHeaderFooter: {
      callHeaderFooterEditorModal: () => {
        dispatch(setShowHeaderFooterEditorModal(true));
      },
    },
    toolTip: {
      renderToolTipClassName: 'tooltip',
      toolTipRenderer: (domElement: HTMLElement, children: any, title: string) => {
        const WrappedComponent = () => (
          <InsertedFieldsTooltip title={title} style={{ marginBottom: '5px' }}>
            <span>{children}</span>
          </InsertedFieldsTooltip>
        );

        ReactDOM.render(<WrappedComponent />, domElement);
      },
    },
    table: TABLE_CONFIG,
    alignment: ALIGNMENT_CONFIG,
    // slCommentsAdapter: {
    //   postComment,
    //   addCommentThread,
    //   getCommentThread,
    //   removeComment,
    //   removeCommentThread,
    //   reopenCommentThread,
    //   resolveCommentThread,
    //   updateComment,
    //   updateCommentThread,
    //   saveSessionActivity,
    // },
    suggestionsAdapter: {
      createSuggestion,
      getSuggestionById,
      deleteSuggestionById,
      acceptSuggestionById,
      rejectSuggestionById,
      pendSuggestionById,
      saveSessionActivity,
    },
    exportPdf: exportPdfConfig(templateTitle, leaseTemplate.header ?? '', leaseTemplate.footer ?? ''),
    list: listProperties,
    revisionHistory: {
      editorContainer: document.querySelector('#editor-container'),
      viewerContainer: document.querySelector('#revision-viewer-container'),
      viewerEditorElement: document.querySelector('#revision-viewer-editor'),
      viewerSidebarContainer: document.querySelector('#revision-viewer-sidebar'),
      cloudDocumentVersion,
      save,
      createDocumentRevision,
      documentId,
      canEdit,
      canComment,
      documentType: DocumentTypesEnum.TEMPLATE,
      getDocumentRevisions,
      showRevisionViewerCallback: (revisionConfig: any) => {
        revisionConfig.toolbar = permissions.hasEditPermission
          ? ['exitToEditing', 'restoreRevision', 'changesNavigation']
          : ['exitToEditing', 'changesNavigation'];

        const editorContainer = revisionConfig.revisionHistory.editorContainer;
        const viewerContainer = revisionConfig.revisionHistory.viewerContainer;
        const viewerElement = revisionConfig.revisionHistory.viewerEditorElement;

        return editor.create(viewerElement, revisionConfig).then((createdEditor: any) => {
          viewerContainer.style.display = 'block';
          editorContainer.style.display = 'none';
          if (revisionToolbarElem.current) {
            revisionToolbarElem.current.innerText = '';
          }
          revisionToolbarElem.current?.appendChild(createdEditor.ui.view.toolbar.element);

          return createdEditor;
        });
      },
      revisionActions: permissions.hasEditPermission
        ? ['compareAgainstSelected', 'restoreRevision', 'nameRevision']
        : ['compareAgainstSelected'],
    },
  };

  const isEditorDisabled = () => {
    return (isTemplateLocked)
      || (isOrganizationTemplate)
      || (!permissions.hasEditPermission && !permissions.hasCommentPermission);
  };

  // Timeout Handlers
  const handleIdle = () => {
    idleTimerRef?.current?.reset();
    setShowTimeoutModal(true);
  };

  const handleTimeoutModalClose = () => {
    setShowTimeoutModal(false);
    idleTimerRef?.current?.reset();
  };

  const disableToolbarButtonForUsersWithoutEditPermission = useCallback(
    (editor: EditorType) => {
      if (
        (permissions.hasEditPermission && !isOrganizationTemplate)
        || (isOrganizationTemplate && !canEdit)
      ) return;

      editor.ui.view.toolbar.items.find(
        (item: any) => item.buttonView && item.buttonView?.label?.startsWith('Revision')
      ).isEnabled = false;

      const editHeaderFooterButton = editor.ui.view.toolbar?.items?._items?.find(
        (item: any) => item.label && item.label.startsWith('Header')
      );

      if (editHeaderFooterButton) {
        editHeaderFooterButton.isEnabled = false;
      }
    },
    [permissions.hasEditPermission, isOrganizationTemplate, canEdit]
  );

  return (
    <>
      <div className="editors-holder">
        <div className="lease-editor" id="editor-container">
          <div className="lease-editor__toolbar" ref={toolbarElem} />
          <div className="lease-editor__editable-container">
            <div id="Dynamic" />
            {!ckeInstance && <LoadingSpinner className="spinner__document-preparing" />}
            <div id="Editor" className={classNames({ 'd-none': !ckeInstance })}>
              {isLayoutReady && (
                <CKEditor
                  editor={editor}
                  config={config}
                  data={leaseTemplate.body}
                  disabled={isEditorDisabled()}
                  onReady={(editor: EditorType) => {
                    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                      CKEditorInspector.attach(editor);
                    }
                    isEditorDisabled() && preventEditorEnabling(editor);

                    setCkeInstance(editor);
                    window.editor = editor;
                    editor.commands.get('trackChanges').forceDisabled('forEveryone');

                    if (toolbarElem.current) {
                      toolbarElem.current.innerText = '';
                    }
                    toolbarElem.current?.appendChild(editor.ui.view.toolbar.element);

                    // Sidebar
                    const annotationsUIs = editor.plugins.get('AnnotationsUIs');
                    const sideBarNode = document.getElementById('Sidebar');

                    annotationsUIs.switchTo(
                      window.innerWidth < MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR ? 'narrowSidebar' : 'wideSidebar'
                    );
                    sideBarNode?.appendChild(sidebarNode.current);

                    const fontFamily = editor.commands.get('fontFamily');
                    fontFamily.execute({ value: 'Arial, Helvetica, sans-serif' });

                    const numberedListDropdown = document.getElementsByClassName('ck-splitbutton__arrow');
                    const listArrowItem = Array.from(numberedListDropdown).find((item: Element) => {
                      if (item instanceof HTMLElement) {
                        return item.dataset?.ckeTooltipText === 'Numbered List';
                      } else return false;
                    });

                    listArrowItem?.addEventListener(
                      'click',
                      () => {
                        updateListsDropdownValue();
                      },
                      { once: true }
                    );

                    const dropdownButtonsCollection = document.getElementsByClassName('ck-dropdown__button');
                    const hideBalloonToolbarOnDropdownClick = () => {
                      const visibleBalloon = document.getElementsByClassName('ck-balloon-panel_visible');
                      Array.from(visibleBalloon).forEach((elem: Element) => {
                        elem.classList.remove('ck-balloon-panel_visible');
                      });
                    };
                    Array.from(dropdownButtonsCollection).forEach((item: Element) => {
                      if (item instanceof HTMLElement) {
                        item?.addEventListener('click', hideBalloonToolbarOnDropdownClick);
                      } else return false;
                    });

                    dispatch(setLeaseEditorIsLoaded(true));

                    handleSaveRevision(editor);

                    disableToolbarButtonForUsersWithoutEditPermission(editor);

                    editor.ui.update();

                    editor.focus();

                    editor.on('change', () => {
                      if (!editor._readOnlyLocks.size) {
                        dispatch(setDocumentCompareModeEnabled(false));
                      }
                      disableToolbarButtonForUsersWithoutEditPermission(editor);
                    });

                    editor.commands.get('importWord').on('dataInsert', (event: any, data: any) => {
                      handleLeftMarginRemovalFromFigure(data);
                      handleListStylingOnImportWord(data);
                      handleLineHeightRemovalOnImportWord(data);
                      handleLetterSpacingRemovalOnImportWord(data);
                      handleMarginStylesRemovalOnImportWord(data);
                      handleNegativeMarginRemovalOnImportWord(data);
                      handleInlineNegativeTextIndent(data);
                    });

                    editor.on('destroy', () => {
                      const editorWrapper = document.querySelector('.ck-body-wrapper');
                      const editorWrapperParent = editorWrapper?.parentNode;
                      editorWrapperParent?.removeChild(editorWrapper as Element);
                    });
                  }}
                  onError={(error: any) => {
                    console.error('ERROR: ', error);
                  }}
                />
              )}
            </div>
            <div id="Sidebar" className="lease-editor__sidebar ck-content wide sidebar-container" />
          </div>
        </div>
        <div id="revision-viewer-container" className="revision-viewer__container">
          <div className="revision-viewer__toolbar" ref={revisionToolbarElem} />
          <div className="editor-container">
            <div id="revision-viewer-editor" className="revision-viewer__editor" />
            <div className="sidebar-container revision-viewer__sidebar" id="revision-viewer-sidebar" />
          </div>
        </div>
      </div>
      <IdleTimer
        key="idleTimer"
        ref={idleTimerRef as any}
        startOnMount
        element={document}
        onIdle={handleIdle}
        timeout={IDLE_TIMER_TIMEOUT_MILLISECONDS}
      />
      {showTimeoutModal && <TimeoutModal onClose={handleTimeoutModalClose} onTimeOut={navigateToDealDetails} />}
    </>
  );
};
