import { ImportedDocumentSupportedContentTypes } from '../../shared/modals/fileUploadModal/types';
import { getExtensionByContentType } from '../../../pages/uploadedWebViewer/uploadedViewer/helpers';

export function downloadPdfHelper(
  fileURL: any,
  fileName: string,
  contentType: ImportedDocumentSupportedContentTypes = 'application/pdf'
) {
  fetch(fileURL, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
    },
  })
    .then(response => response.blob())
    .then(blob => {
      const extension = getExtensionByContentType(contentType);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
}
