import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import Tooltip from '../../../../../tooltip';
import {
  INTERNAL_PREPARE_FOR_SIGNING_TOOLTIP,
  SHOULD_RETURN_FOR_RECEIVER_REVIEW_TOOLTIP,
} from '../../../../../document-table/helpers/messageConstants';
import styles from '../requestReview/RequestReview.module.scss';

interface Props {
  onClick: () => void;
  disabled: boolean;
  showReturnForReviewTooltip: boolean;
}

// TODO Add the extra modal load in this file for signing
const SendToPreparingToSignInternal = ({ onClick, disabled, showReturnForReviewTooltip }: Props) => {
  return (
    <Dropdown.Item as={Button} className={styles.signInButton} variant="link" disabled={disabled} onClick={onClick}>
      Signing
      {disabled && showReturnForReviewTooltip && (
        <Tooltip testId="prepare-for-signing-cta" placement="bottom">
          {INTERNAL_PREPARE_FOR_SIGNING_TOOLTIP}
        </Tooltip>
      )}
      {disabled && !showReturnForReviewTooltip && (
        <Tooltip testId="prepare-for-signing-cta" placement="bottom">
          {SHOULD_RETURN_FOR_RECEIVER_REVIEW_TOOLTIP}
        </Tooltip>
      )}
    </Dropdown.Item>
  );
};

export default SendToPreparingToSignInternal;
