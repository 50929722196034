import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentImportedAssetUrlAction } from '../actions/lease-document.actions';

export const handleSetDocumentImportedAssetUrl = (
  state: LeaseDocumentStateType,
  action: SetDocumentImportedAssetUrlAction
): LeaseDocumentStateType => ({
  ...state,
  importedAssetUrl: action.url,
});
