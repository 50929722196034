import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Dropdown } from 'react-bootstrap';
import { DocumentTableEnum } from './DocumentTableConstants';
import { DropdownBody, DropdownToggle, DropdownToggleBlue, DropdownToggleOrange } from '../dropdown';
import { CreateDocumentContainerProps } from './helpers/documentTableTypes';
import styles from './DocumentTable.module.scss';

const CreateDocumentContainer = ({
  hasReceiverEditPermission,
  handleFileUploadModal,
  handleDocFileUploadModal,
  handleTemplateSelectorModal,
  handleCreateBlankDocumentModal,
  hasDealDocuments = false,
}: CreateDocumentContainerProps) => (
  <div className={!hasDealDocuments ? styles.createDocumentContainer : undefined}>
    {!hasDealDocuments && (
      <FormattedMessage id={DocumentTableEnum.CARD_ID} defaultMessage={DocumentTableEnum.NO_DOCUMENTS_MESSAGE} />
    )}
    {hasReceiverEditPermission && (
      <div className={!hasDealDocuments ? styles.createDocumentDropdown : undefined}>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-deal-actions"
            variant={'secondary'}
            as={DropdownToggleOrange}
            className="w-100 justify-content-center"
          >
            <FormattedMessage id="deals.actions" defaultMessage="New Document" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <DropdownBody>
              <Dropdown.Item as={Button} variant="link" onClick={handleTemplateSelectorModal}>
                From Template
              </Dropdown.Item>
              <Dropdown.Item as={Button} variant="link" onClick={handleDocFileUploadModal}>
                Import from Word
              </Dropdown.Item>
              <Dropdown.Item as={Button} variant="link" onClick={handleFileUploadModal}>
                Upload PDF
              </Dropdown.Item>
              <Dropdown.Item as={Button} variant="link" onClick={handleCreateBlankDocumentModal}>
                Blank Document
              </Dropdown.Item>
            </DropdownBody>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )}
  </div>
);

export default CreateDocumentContainer;
