import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Button } from 'react-bootstrap';
import { Table } from '../../../components/table';
import { UserHasUnassignedDealsModal } from '../../../components/shared/modals/userHasUnassignedDeals';
import { TitleFormattedMessage } from '../../../components/titleFormattedMessage';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  showSubscriptionRequiredModal: () => void;
  currentPendingDeals: { [key: string]: any };
  pendingInvites: any;
};

type ColumnsCellType = {
  cell: {
    value: {
      deal: { [key: string]: any };
      relatedInvitation: any;
      id: string;
      token: string;
      email: string;
      status: string;
      senderName: string;
    };
  };
};

const PendingInvitesModal = ({
  isOpen,
  onClose,
  pendingInvites,
  currentPendingDeals,
  showSubscriptionRequiredModal,
}: Props) => {
  const [isAcceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
  const [selectedInvitation, setSelectedInvitation] = useState<{ [key: string]: any } | null>(null);
  const { formatMessage } = useIntl();

  const handleAcceptInvite = (selectedPendingDeal: { [key: string]: any }) => {
    setSelectedInvitation(selectedPendingDeal);
    setAcceptModalOpen(true);
  };

  const onOnboardingSuccess = () => {
    !!(currentPendingDeals.length - 1) ? setAcceptModalOpen(false) : onClose();
  };

  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        Cell: ({ cell: { value } }: ColumnsCellType) => {
          return (
            <>
              <FormattedMessage id="deal-ivitation-modal.accept-invitation" defaultMessage={value.deal.title} />
              <div className="small">
                <FormattedMessage
                  id="deal-ivitation-modal.accept-invitation"
                  defaultMessage={`From: ${value.relatedInvitation?.senderName}`}
                />
              </div>
            </>
          );
        },
        disableSortBy: true,
      },
      {
        accessor: 'inviteButton',
        Cell: ({ cell: { value } }: ColumnsCellType) => {
          return (
            <Button
              //@ts-ignore bootstrap
              variant="primary shadow-none"
              className="mx-2"
              onClick={() => handleAcceptInvite(value)}
            >
              <TitleFormattedMessage id="deal-ivitation-modal.accept-invitation" defaultMessage="Accept" />
            </Button>
          );
        },
        disableSortBy: true,
      },
    ],
    []
  );

  // Filter and mutate data for the table
  const data = React.useMemo(
    () =>
      currentPendingDeals
        ? currentPendingDeals.map((deal: any) => {
            const relatedInvitation = pendingInvites.find((invite: any) => {
              return invite.deals?.find((inviteDeal: any) => inviteDeal.id === deal.id);
            });

            return {
              id: deal.id,
              name: { relatedInvitation, deal },
              inviteButton: { relatedInvitation, deal },
              email: relatedInvitation?.email || '',
            };
          })
        : [],
    [currentPendingDeals, pendingInvites]
  );

  const table = useTable({ data, columns }, useSortBy, usePagination);

  return (
    <Modal show={isOpen} onHide={onClose} centered className={styles.pendingInvitesModal}>
      <div className={styles.pendingInvitesModalContainer}>
        <Modal.Header
          closeButton
          closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
          onClick={onClose}
        >
          <Modal.Title>
            <header className={styles.pendingInvitesModalHeader}>
              <FormattedMessage id="title.archive-template" defaultMessage="Pending Invites" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className={styles.pendingInvitesModalTable} striped table={table} />
        </Modal.Body>
        {isAcceptModalOpen && (
          <UserHasUnassignedDealsModal
            handleHide={() => setAcceptModalOpen(false)}
            invitation={selectedInvitation}
            onSuccess={onOnboardingSuccess}
            showSubscriptionRequiredModal={showSubscriptionRequiredModal}
          />
        )}
      </div>
    </Modal>
  );
};

export default PendingInvitesModal;
