import React, { useCallback, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTeamInfo } from '../../../team-id-context';
import { useApolloClient, useQuery } from '@apollo/client';
import { queries, queriesV2, useTeamPermissions } from '../../../api';
import classNames from 'classnames';
import styles from './styles.module.scss';
import LoadingSpinner from '../../loading-spinner';
import DealTeamInfoItem from './components/dealTeamInfoItem';
import { User } from '../../lease-wizard/lease-editor/LeaseEditorTypes';
import {
  filterTeamMembersToDisplay,
  parseInvitation,
  parseTeamMember,
  SidebarTeamMember,
} from '../../teamMembersList/components/teamMembersTable/helpers';
import DealTeamInviteSection from './components/dealTeamInviteSection';
import { useLeaseDocumentTeamPermissions } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { getDealsForTeam } from '../../../api/queries';
import { useAuth, usePermissions } from '../../../auth';
import { useSelector } from 'react-redux';
import { selectDealId } from '../../../store/deals/deals.selectors';
import SelectTeamMembers from '../../../forms/components/SelectTeamMembers';
import { Permissions } from '../../../shared/constants/permissions';
import { Table } from '../../table';
import { useExpanded, useSortBy, useTable } from 'react-table';
import { useIntl } from 'react-intl';
import Button from 'react-bootstrap/Button';
import { ReactComponent as ChevronUpIcon } from '../../../shared/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../shared/icons/chevron-down.svg';
import RemoveMemberFromDealConfirmationModalContainer from '../../teamMembersList/components/removeMemberFromDealConfirmationModal/RemoveMemberFromDealConfirmationModalContainer';
import DealTeamTableSubRow from './components/dealTeamTableSubRow';
import { Permission } from '../../../gql/graphql';

const DealTeamSection = () => {
  const apolloClient = useApolloClient();
  const [userToRemoveFromDeal, setUserToRemoveFromDeal] = useState<SidebarTeamMember | null>(null);
  const { teamId } = useTeamInfo();
  const dealId = useSelector(selectDealId);
  const [{ user }] = useAuth();
  const { isAdmin, hasPermission } = usePermissions({ teamId });
  const hasInvitePermission = isAdmin || hasPermission(Permissions.Invite);
  const { loading: permissionsLoading, teamPermissions } = useTeamPermissions();
  const { formatMessage } = useIntl();

  const { data: allPermissions, loading: isAllPermissionsLoading } = useQuery(queriesV2.getPermissions);
  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });
  const hasUserInvitePermission = !!permissions?.hasInviteSendingPermission;

  // API Calls
  const { loading: teamQueryLoading, data: teamData } = useQuery(queries.getTeamById, {
    skip: teamId == null,
    variables: {
      teamId,
    },
  });

  const { loading: isDealDataLoading, data: dealTeamsData } = useQuery(queriesV2.getDealTeamsByTeamId, {
    skip: !teamId || !dealId,
    variables: {
      dealId,
      teamId,
    },
  });

  const currentUserIsAdmin = teamData?.teamById?.adminUser?.id === user.id;

  const { loading: isDealsForTeamLoading, data: dealData } = useQuery(getDealsForTeam, {
    skip: (!currentUserIsAdmin && !hasUserInvitePermission) || !hasUserInvitePermission,
    variables: {
      teamId,
    },
  });

  const teamMembers = teamData?.teamById?.teamMembers?.filter(
    (member: { user: User }) => isAdmin || member.user.id !== user.id
  );
  const dealMembers = dealTeamsData?.getDealById?.members;
  const viewPermission = teamPermissions?.find(({ key }: any) => key === Permissions.View);

  const deal = dealData?.dealsForTeam.find((deal: any) => deal.id === dealId);

  const teamMembersData = useMemo(() => {
    if (!teamData || !allPermissions) return [];

    const teamMembersToDisplay: SidebarTeamMember[] = teamData.teamById.teamMembers.map(
      ({ teamPermissions: teamMemberPermissions, user: member }: any) => {
        const isAdmin = member.id === teamData.teamById.adminUser.id;
        const hasAccessToDeal = deal?.dealAccessUsers.includes(member.id);

        const memberPermissions = isAdmin
          ? allPermissions.permissions.map((permission: any) => ({ allowed: true, permission }))
          : teamMemberPermissions;

        return parseTeamMember({ memberPermissions, member, isAdmin, hasAccessToDeal });
      }
    );

    const pendingInvitations: SidebarTeamMember[] = hasUserInvitePermission
      ? teamData.teamById.pendingInvitations
          .map((invitation: { id: string; permissions: any[]; email: string; dealIds: string[] }) => {
            const hasAccessToDeal = !deal ? false : invitation.dealIds.includes(deal.id);
            return parseInvitation({ invitation, hasAccessToDeal });
          })
          .filter((invitation: any) => {
            if (deal) return invitation.hasAccessToDeal;
            return true;
          })
      : [];

    return [...teamMembersToDisplay, ...pendingInvitations].filter(teamMember =>
      filterTeamMembersToDisplay({ teamMember, deal, currentDealId: dealId })
    );
  }, [teamData, allPermissions, deal, dealId, hasUserInvitePermission]);

  const renderDealMemberExpandableSection = (user: any) => {
    return <DealTeamInfoItem teamMember={user} key={user.id} teamId={teamId} />;
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'fullName',
        Cell: ({ row }: any) => {
          return renderDealMemberExpandableSection(row.original);
        },
      },
      {
        accessor: 'permissions',
        Cell: ({ row }: any) => {
          const { original: userRowData } = row;

          return (currentUserIsAdmin || hasUserInvitePermission) && userRowData.id ? (
            <div className="d-flex align-items-center justify-content-between">
              {
                <Button
                  variant="link"
                  {...row.getToggleRowExpandedProps()}
                  title={formatMessage({
                    id: 'cta.more-options.toggle',
                    defaultMessage: 'Toggle more options',
                  })}
                  className="pl-2 pr-2"
                >
                  {row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Button>
              }
            </div>
          ) : null;
        },
      },
    ],
    [currentUserIsAdmin, user.id, hasUserInvitePermission]
  );

  const table = useTable(
    {
      data: teamMembersData,
      // @ts-ignore
      columns,
      autoResetExpanded: false,
      autoResetPage: false,
      getRowId: useCallback(
        (row, relativeIndex, parent) => [...(parent ? [parent.id] : []), row.id ?? relativeIndex].join('.'),
        []
      ),
    },
    useSortBy,
    useExpanded
  );

  const renderRowSubComponent: any = useCallback((row: any) => {
    return <DealTeamTableSubRow row={row} setUserToRemoveFromDeal={setUserToRemoveFromDeal} />;
  }, []);

  const isLoading =
    teamQueryLoading || isAllPermissionsLoading || isDealsForTeamLoading || isDealDataLoading || permissionsLoading;
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container className={classNames('mt-3 p-0 flex-column h-100')}>
      <Container className={classNames(styles.teamListContainer, 'pt-0 pl-0 pr-0 w-100 flex-column')}>
        <div className={styles.inviteContainer}>
          {hasInvitePermission && (
            <div className="mb-3">
              <SelectTeamMembers
                teamMembers={teamMembers}
                dealMembers={dealMembers}
                dealId={dealId}
                viewPermission={viewPermission as Pick<Permission, 'id'>}
              />
            </div>
          )}
          <DealTeamInviteSection hasInvitePermission={hasInvitePermission} />
        </div>
        <div className={hasUserInvitePermission ? styles.teamListItems : styles.teamListItemsNonInvite}>
          <Table
            paginationBelow={false}
            table={table}
            renderRowSubComponent={renderRowSubComponent}
            isSmall
            className={classNames(styles.teamMembersTable)}
          />
        </div>
      </Container>
      <RemoveMemberFromDealConfirmationModalContainer
        userToRemoveFromDeal={userToRemoveFromDeal}
        setUserToRemoveFromDeal={setUserToRemoveFromDeal}
      />
    </Container>
  );
};

export default DealTeamSection;
