import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShareExecutedDocumentModal from './shareExecutedDocumentModal';
import { sendDocumentToReceiver } from '../../../../utils/api/send-document-to-receiver';
import { useApolloClient } from '@apollo/client';
import { paths } from '../../../../routing';
import { useNotifications } from '../../../../notifications';
import { useIntl } from 'react-intl';
import { successSendDocumentMessage } from '../../../lease-wizard/lease-editor/constants/EditorConstats';
import { getErrorMessage } from '../../../../utils/getErrorMessage';

interface ShareExecutedDocumentModalContainerProps {
  show: boolean;
  onHide: () => void;
  documentId: string;
  dealId: string;
  teamId: string;
}

const ShareExecutedDocumentModalContainer = ({
  show,
  onHide,
  documentId,
  dealId,
  teamId,
}: ShareExecutedDocumentModalContainerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();
  const dealUrl = paths.deals.detail.replace(':teamId', teamId).replace(':dealId', dealId);
  const { formatMessage } = useIntl();

  const handleSend = useCallback(async () => {
    try {
      setIsLoading(true);
      await sendDocumentToReceiver(documentId)(apolloClient);
      notifySuccess({
        message: formatMessage(successSendDocumentMessage),
      });
      navigate(dealUrl);
    } catch (error) {
      notifyError({
        message: getErrorMessage(error),
      });
    } finally {
      setIsLoading(false);
      onHide();
    }
  }, [apolloClient, dealUrl, documentId, formatMessage, notifyError, notifySuccess, onHide, navigate]);

  return <ShareExecutedDocumentModal show={show} onHide={onHide} isLoading={isLoading} onConfirm={handleSend} />;
};

export default ShareExecutedDocumentModalContainer;
