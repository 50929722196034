import React, { ReactNode, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import EditExpiryDateForm from './EditExpiryDateForm';
import { useNotifications } from '../notifications';
import { EditExpiryDateErrorMessage } from '../consts/forms-constants';
import { mutations } from '../api';
import { getOrganizationByTeam } from '../api/queries';
import { useTeamInfo } from '../team-id-context';

interface EditExpiryDateFormProps {
  bodyContainer: any;
  actionsContainer: any;
  renderSecondaryAction: () => ReactNode;
  onModalHide: () => void;
  date: string;
  organizationRequestId: string;
}

const schema = yup.object().shape({
  date: yup.date().required(),
});

const EditExpiryDateFormik = ({
  organizationRequestId,
  date,
  bodyContainer,
  actionsContainer,
  renderSecondaryAction,
  onModalHide,
}: EditExpiryDateFormProps) => {
  const [, { error: notifyError }] = useNotifications();
  const { teamId } = useTeamInfo();
  const [updateOrganizationRequestExpiry] = useMutation(mutations.updateOrganizationRequestExpiry, {
    refetchQueries: [
      {
        query: getOrganizationByTeam,
        variables: {
          teamId,
        },
      },
    ],
  });

  const onSubmit = async (values: any) => {
    try {
      await updateOrganizationRequestExpiry({
        variables: { organizationRequestId, expiresAt: values.date },
      });

      onModalHide();
    } catch (error) {
      console.log(error);
      notifyError({ message: EditExpiryDateErrorMessage.EDIT_EXPIRY_DATE });
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        date,
      }}
    >
      {formikProps => (
        <EditExpiryDateForm
          {...formikProps}
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
        />
      )}
    </Formik>
  );
};

export default EditExpiryDateFormik;
