import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import HandleInternalApprovalActionsModal from './HandleInternalApprovalActionsModal';
import { ApprovalRequestActions } from './RequestInternalApprovalModalConstants';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';
import styles from './RequestReview.module.scss';

type HandleInternalApprovalActionsProps = {
  dealTeamsData?: {
    [U: string]: any;
  };
  documentId: string;
  dealId: string;
  reviewId: string;
  teamId: string;
};

const HandleInternalApprovalActionsContainer = ({
  documentId,
  dealId,
  teamId,
  reviewId,
}: HandleInternalApprovalActionsProps) => {
  const [showHandleRequestApprovalModal, setShowHandleRequestApprovalModal] = useState(false);
  const [selectedRequestApprovalAction, setSelectedRequestApprovalAction] = useState<null | ApprovalRequestActions>(
    null
  );

  const setHandleRequestApprovalModalMode = (action: ApprovalRequestActions) => {
    setSelectedRequestApprovalAction(action);
    setShowHandleRequestApprovalModal(true);
  };

  return (
    <div className={styles.requestReviewContainer}>
      <div className={styles.requestReviewDropdown}>
        <div className={styles.handleApprovalRequestButtonsGroup}>
          <Button
            variant="outline-primary"
            onClick={() => setHandleRequestApprovalModalMode(ApprovalRequestActions.REJECT)}
          >
            <TitleFormattedMessage
              id={`cta.cancel_${ApprovalRequestActions.REJECT}`}
              defaultMessage={ApprovalRequestActions.REJECT}
            />
          </Button>
          <Button variant="primary" onClick={() => setHandleRequestApprovalModalMode(ApprovalRequestActions.APPROVE)}>
            <TitleFormattedMessage
              id={`cta.cancel_${ApprovalRequestActions.APPROVE}`}
              defaultMessage={ApprovalRequestActions.APPROVE}
            />
          </Button>
        </div>
      </div>

      <HandleInternalApprovalActionsModal
        documentId={documentId}
        show={showHandleRequestApprovalModal}
        onHide={() => setShowHandleRequestApprovalModal(false)}
        requestApprovalAction={selectedRequestApprovalAction}
        dealId={dealId}
        reviewId={reviewId}
        teamId={teamId}
      />
    </div>
  );
};

export default HandleInternalApprovalActionsContainer;
