import { Permissions } from '../../../../../shared/constants/permissions';
import { TeamPermissionName } from '../../../../../store/permissions/permissions.reducer';

export interface UserTeamPermission {
  key: TeamPermissionName;
  allowed: boolean;
  teamName: string;
  teamId: string;
}

export interface Team {
  id: string;
  name: string;
}

export const getTeamsWhereUserHasEditPermissions = (userTeamPermissions: UserTeamPermission[]): Team[] => {
  return userTeamPermissions.reduce((acc: Team[], permission) => {
    const isEditPermission = permission.key === Permissions.Edit;
    const isTeamAdded = !!acc.find(team => team.id === permission.teamId);

    if (isEditPermission && permission.allowed && !isTeamAdded) {
      const teamName = `${permission.teamName[0].toUpperCase()}${permission.teamName.substring(1)}`;

      acc.push({
        id: permission.teamId,
        name: teamName,
      });
    }

    return acc;
  }, []);
};
