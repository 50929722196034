import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { UNRESOLVED_SUGGESTIONS_TOOLTIP } from '../../../../../document-table/helpers/messageConstants';
import Button from 'react-bootstrap/Button';
import Tooltip from '../../../../../tooltip';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';
import styles from "../requestReview/RequestReview.module.scss";

interface Props {
  disabled: boolean;
  onClick: () => void;
  showUnresolvedTooltip: boolean;
}

const SendForSigning = ({ disabled, onClick, showUnresolvedTooltip }: Props) => {
  return (
    <Dropdown.Item
      as={Button}
      className={styles.signInButton}
      variant="link"
      disabled={disabled}
      onClick={onClick}
    >
      <TitleFormattedMessage
        id="lease-document-wizard-header.action.share-draft"
        defaultMessage={'Signing'}
        data-testid="lease-document-wizard-header-share-draft-action"
      />
      {showUnresolvedTooltip && (
        <span className="ml-1">
          <Tooltip testId="lease-document-tooltip" placement="bottom">
            {UNRESOLVED_SUGGESTIONS_TOOLTIP}
          </Tooltip>
        </span>
      )}
    </Dropdown.Item>
  );
};

export default SendForSigning;
