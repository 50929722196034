import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import { useAuth } from '../auth';
import { paths, fillPlaceholders } from '../routing';

import ErrorMessage from '../components/error-message';
import SubmitButton from '../components/submit-button';
import { TextField } from '../components/fields';

import styles from './styles.module.scss';

interface LoginFormProps {
  errors: { [key: string]: any };
  values: { [key: string]: any };
  isSubmitting: boolean;
  isValid: boolean;
}

const LoginForm = ({ errors, values, isSubmitting, isValid }: LoginFormProps) => (
  <Form noValidate className={styles.formContainer}>
    {errors._general && (
      <Alert variant="danger">
        <ErrorMessage>{errors._general}</ErrorMessage>
      </Alert>
    )}

    <TextField
      id="loginForm.email"
      name="email"
      type="email"
      label={<FormattedMessage id="form.email.label" defaultMessage="Email" />}
      autoComplete="username"
    />

    <TextField
      id="loginForm.password"
      name="password"
      type="password"
      label={<FormattedMessage id="form.password.label" defaultMessage="Password" />}
      autoComplete="password"
    />

    <div className="text-right w-100">
      <SubmitButton
        label={<FormattedMessage id="cta.login" defaultMessage="Sign in" />}
        disabled={isSubmitting || !isValid}
        isSubmitting={isSubmitting}
        data-testid="sign-in-button"
        className="w-100"
      />
      <p className="text-center pt-3">
        <NavLink to={paths.auth.forgotPassword} data-testid="forgot-password">
          <FormattedMessage id="cta.go-to.forgot-pass" defaultMessage="Forgot password" />
        </NavLink>
      </p>
      <div className={styles.dividerContainer}>
        <div className={styles.divider}>
          <FormattedMessage id="cta.sign.or" defaultMessage="OR" />
        </div>
      </div>
      <p className="body-text text-center pt-4">
        <FormattedMessage id="cta.go-to.sign-up-message" defaultMessage="Need an account?" />
        <NavLink to={paths.auth.register} data-testid="sign-up" className="pl-2">
          <FormattedMessage id="cta.go-to.sign-up" defaultMessage="Sign Up" />
        </NavLink>
      </p>
    </div>
  </Form>
);

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required(),
});

const LoginFormik = ({ token }: { token?: string }) => {
  const [, { login }] = useAuth();
  const pathsParams = useLocation();
  const navigate = useNavigate();

  const onSubmit = async (values: { [key: string]: any }, actions: { [key: string]: any }) => {
    // @ts-ignore
    const { success, errors } = await login(values);

    if (success) {
      let navigateUrl = pathsParams?.state?.returnTo ?? paths.home;

      if (token) {
        navigateUrl = fillPlaceholders(paths.homeWithToken, { token });
      }
      navigate(navigateUrl);
    } else {
      actions.setErrors(errors);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      component={LoginForm}
      initialValues={{ email: '', password: '' }}
    />
  );
};

export default LoginFormik;
