import { ApolloClient } from '@apollo/client';
import { DocumentPayload } from './lease-document-interface.types';
import { graphql } from '../../gql';

const query = graphql(/* GraphQL */ `
  query GetDocumentByIdOffer($offerId: ID!) {
    getDocumentById(id: $offerId) {
      id
      approvalRequest {
        id
        status
        threshold
        creator {
          id
        }
        reviewers {
          id
        }
        reviews {
          id
          approvedAt
          rejectedAt
          reviewer {
            id
            fullName
            email
          }
        }
        totalReviews
        totalApproved
      }
      isEditable
      isEligibleForSigningPrep
      isTitleEditable
      interactionsFeedToken
      shouldReturnForReceiverReview
      status
      document
      title
      lastUpdated
      eversignStatus
      eversignPdfUrl
      originalImportedAssetUrl
      receiverCollaborationEnabled
      generatedDocumentPdfUrl
      currentTeamPossession
      documentSuggestions {
        id
        externalId
        status
      }
      receiverTeam {
        id
      }
      team {
        id
      }
      signers {
        id
        order
        email
        embeddedSigningUrl
        token
        signedAt
      }
      deal {
        id
        receiverEmail
        title
      }
      editorConfiguration
      source
      importedAssetUrl
      importedAssetContentType
    }
  }
`);

export const getDocumentById = (offerId: string | number) => (
  client: ApolloClient<object>
): Promise<DocumentPayload> => {
  const variables = { offerId: offerId as string };

  return client.query({ query, variables }).then(({ data }: Record<any, any>) => data.getDocumentById);
};

export const getDocumentByIdOfferQuery = query;