import { Routes } from 'react-router-dom';
import {
  AuthRoutes,
  ClauseRoutes,
  DealRoutes,
  DocumentRoutes,
  HomeRoutes,
  MobileWarningRoute,
  NotFound,
  AccountRoute,
  SettingsRoutes,
  TeamRoutes,
  TemplateRoutes,
} from './routes';

const WebappRouter = () => {
  return (
    <>
      <Routes>
        {AuthRoutes()}
        {HomeRoutes()}
        {DealRoutes()}
        {DocumentRoutes()}
        {TeamRoutes()}
        {TemplateRoutes()}
        {ClauseRoutes()}
        {SettingsRoutes()}
        {MobileWarningRoute()}
        {AccountRoute()}
        {NotFound()}
      </Routes>
    </>
  );
};

export default WebappRouter;
