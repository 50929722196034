import {
  INCORRECT_FILE_FORMAT_ERROR,
  SupportedDocFileTypes,
  SupportedFileTypes,
  SupportedTemplateFileTypes,
} from './constants';

export const getSupportedExtensionsString = (templateUpload?: boolean, isDocxUploadModal?: boolean) => {
  const extensionsString = Object.values(
    isDocxUploadModal ? SupportedDocFileTypes : templateUpload ? SupportedTemplateFileTypes : SupportedFileTypes
  )
    .map(ext => `.${ext}`)
    .join(', ');

  return INCORRECT_FILE_FORMAT_ERROR.replace('{extensions}', extensionsString);
};
