import React, { useState } from 'react';
import CancelPreparingToSign from './cancelPreparingToSign';
import { CancelSigningPreparationModal } from '../../../../modals';

const CancelPreparingToSignContainer = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <CancelPreparingToSign onClick={() => setShowModal(true)} />
      <CancelSigningPreparationModal show={showModal} onHide={() => setShowModal(false)} />
    </>
  );
};

export default CancelPreparingToSignContainer;
