import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentSignersAction } from '../actions/lease-document.actions';

export const handleSetDocumentSigners = (
  state: LeaseDocumentStateType,
  action: SetDocumentSignersAction
): LeaseDocumentStateType => ({
  ...state,
  signers: action.signers,
});
