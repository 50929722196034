import { EditorType } from '../utils/lease-editor.types';

/**
 * Triggers a save revision action within a CKEditor instance by simulating a click event on the
 * 'Save current revision' option in the revision history panel.
 *
 * @param {EditorType} editor - The CKEditor instance on which to perform the save revision action.
 */
export const handleSaveRevision = (editor: EditorType) => {
  const revisionHistoryTool = document?.querySelector("[data-cke-tooltip-text='Revision history']");

  if (revisionHistoryTool) {
    revisionHistoryTool.removeChild(revisionHistoryTool.lastChild as Node);

    revisionHistoryTool.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      const revisionHistoryToolbarItem = editor.ui.view.toolbar.items.find(
        (item: any) => item.buttonView && item.buttonView?.label?.startsWith('Revision')
      );

      revisionHistoryToolbarItem.panelView.children
        .get(0)
        .items.get(0)
        .children.find((item: any) => {
          return item.label === 'Save current revision';
        })
        .element.click();
    });
  }
};

interface ImportedWordDocData {
  html: string;
}

/**
 * Modifies the imported HTML string by changing the style of lists from 'decimal' to 'decimal-leading-zero'.
 *
 * @param {{ html: string }} data - Object containing the HTML string to be modified.
 * @returns {string} The modified HTML string with updated list styles.
 */
export const handleListStylingOnImportWord = (data: ImportedWordDocData) => {
  const parser = new DOMParser();
  const importedHTML = data.html;
  const parsedImportedHTML = parser.parseFromString(importedHTML, 'text/html');
  const decimalLists = parsedImportedHTML.querySelectorAll('[style="list-style-type: decimal"]');
  decimalLists.forEach((listItem: Element) => {
    if (!!listItem.querySelector('[style="list-style-type: decimal"]'))
      listItem.setAttribute('style', 'list-style-type: decimal-leading-zero');
  });

  return (data.html = parsedImportedHTML.documentElement.innerHTML);
};

/**
 * Removes 'line-height' style attributes from HTML tags within the given HTML string.
 *
 * @param {{ html: string }} data - Object containing the HTML string to be modified.
 * @returns {string} The modified HTML string with 'line-height' styles removed.
 */
export const handleLineHeightRemovalOnImportWord = (data: ImportedWordDocData) => {
  return (data.html = data.html.replace(/(<[^>]+) style="([^"]*)line-height: [^";]*;?([^"]*)"/gi, '$1 style="$2$3"'));
};

/**
 * Removes 'letter-spacing' style attributes from HTML tags within the given HTML string.
 *
 * @param {{ html: string }} data - Object containing the HTML string to be modified.
 * @returns {string} The modified HTML string with 'letter-spacing' styles removed.
 */
export const handleLetterSpacingRemovalOnImportWord = (data: ImportedWordDocData) => {
  return (data.html = data.html.replace(/(<[^>]+) style="([^"]*)letter-spacing:[^";]*;?([^"]*)"/gi, '$1 style="$2$3"'));
};

/**
 * Removes 'margin-top' and 'margin-bottom' style attributes from HTML tags within the given HTML string.
 *
 * @param {{ html: string }} data - Object containing the HTML string to be modified.
 * @returns {string} The modified HTML string with 'margin-top' and 'margin-bottom' styles removed.
 */
export const handleMarginStylesRemovalOnImportWord = (data: ImportedWordDocData) => {
  return (data.html = data.html.replace(/(<[^>]+) style="([^"]*)margin-top:[^";]*;?([^"]*)"/gi, '$1 style="$2$3"').replace(/(<[^>]+) style="([^"]*)margin-bottom:[^";]*;?([^"]*)"/gi, '$1 style="$2$3"'));
};

/**
 * Removes negative margin styles from HTML tags within the given HTML string.
 *
 * @param {{ html: string }} data - Object containing the HTML string to be modified.
 * @returns {string} The modified HTML string with negative margin styles removed.
 */
export const handleNegativeMarginRemovalOnImportWord = (data: ImportedWordDocData) => {
  let regex = /margin-(top|right|bottom|left):\s*-\d+(?:\.\d+)?(px|em|%|rem|vh|vw|in|cm|mm|pt|pc|ex|ch|vmin|vmax)?;?/g;
  data.html = data.html.replace(/style="[^"]*"/g, (styleAttr) => {
    return styleAttr.replace(regex, '');
  });
  return data.html;
}

/**
 * Modifies the HTML content within the `data` object by targeting `<figure>` elements
 * and adjusting their `style` attribute. Specifically, it looks to remove `margin-left`
 * styles or replace them with `margin-right:auto;`, depending on the presence of any
 * `margin-right` styles.
 *
 * If a `<figure>` element's style does not already include `margin-right`, any
 * `margin-left` style is replaced with `margin-right:auto;`. If `margin-right`
 * is present, `margin-left` styles are simply removed. The transformation respects
 * the integrity of other style properties on the `<figure>` elements.
 *
 * The function ensures style strings end with a semicolon and handles trimming and
 * rejoining of the style properties to maintain a clean output.
 *
 * @param {{ html: string }} data - An object containing an `html` property. The `html` property is a string representation of HTML content that will be processed by this function.
 * @returns {string} The modified HTML string with updated `<figure>` element styles.
 */
export const handleLeftMarginRemovalFromFigure = (data: ImportedWordDocData) => {
  data.html = data.html.replace(/<figure\b([^>]*)\bstyle="([^"]*)"/gi, (match, preStyle, style) => {
    if (!style.endsWith(';')) {
      style += ';';
    }
    const trimmedString = style.trim();

    const hasMarginRight = trimmedString.includes('margin-right:');

    const styles = trimmedString.split(";")
      .map((property: string) => property.trim() + ";")
      .filter((property: string) => property !== ";")
      .map((s: string) => {
          if (!hasMarginRight && s.startsWith('margin-left:')) {
            return 'margin-right:auto;';
          }
          if (hasMarginRight && s.startsWith('margin-left:')) {
            return '';
          }
          return s;
        }
      )
      .join(' ')
      .trim();

    if (styles.length > 0) {
      return `<figure ${preStyle.trim()} style="${styles}"`;
    } else {
      return `<figure ${preStyle.trim()}`;
    }
  }).replace(/\s+>/g, '>');
  return data.html
}

/**
 * Removes `text-indent` style from inline styles within HTML tags in the given HTML string. This function is
 * specifically designed to target and eliminate text-indent properties with negative values only, to
 * clean up the HTML content for further processing or display.
 *
 * @param {{ html: string }} data - An object that contains the HTML content in which the `text-indent` styles
 * will be removed.
 * @returns {string} The modified HTML string where any `text-indent` styles have been removed from the inline styles.
 *
 * @example
 * // Given a string with a span element having a negative text-indent
 * const data = { html: '<span style="width: 450px; text-indent: -20px;">Content</span>' };
 * const result = handleInlineNegativeTextIndent(data);
 * // Returns '<span style="width: 450px;">Content</span>'
 */
export const handleInlineNegativeTextIndent = (data: ImportedWordDocData) => {
  return (data.html = data.html.replace(/(<[^>]+)\sstyle="([^"]*)"/gi, (match, tagPart, style) => {
    let styles = style.split(';')
      .map((s: string) => s.trim())
      .filter((s: string) => s)
      .filter((s: string) => !s.startsWith('text-indent:') || !/text-indent:\s*-\d/.test(s))
      .join('; ');
    return `${tagPart} style="${styles}${styles ? ';' : ''}"`;
  }));
};
