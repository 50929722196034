import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ContentHeader } from '../components/private-layout';
import { NO_TEAM_MESSAGE } from '../consts/forms-constants';
import CreateNewTeamModal from '../components/shared/modals/createNewTeamModal/CreateNewTeamModal';
import { useAuth } from '../auth';
import { resetLeaseDocumentState } from '../store/lease-document/actions/lease-document.actions';
import { setTeamPermissions } from '../store/permissions/permissions.actions';
import { TitleFormattedMessage } from '../components/titleFormattedMessage';

const NoTeamPage = () => {
  const { formatMessage } = useIntl();
  const [, { logout }] = useAuth();
  const dispatch = useDispatch();
  const [showAddNewTeamModal, setShowAddNewTeamModal] = useState(false);
  const handleLogout = useCallback(
    data => {
      dispatch(setTeamPermissions([]));
      logout(data).then(() => {
        dispatch(resetLeaseDocumentState());
      });
    },
    [logout, dispatch]
  );

  return (
    <section>
      <Helmet title={formatMessage({ id: 'title.noteam', defaultMessage: 'No Team' })} />
      <ContentHeader>
        <h1 className="mb-0">
          <FormattedMessage id="title.noteam" defaultMessage="No Team" />
        </h1>
      </ContentHeader>
      <Col className="m-5">
        <Row>
          <FormattedMessage id="title.noteam" defaultMessage={NO_TEAM_MESSAGE} />
        </Row>
        <Row className="m-4">
          <Button
            className="btn btn-secondary"
            onClick={() => setShowAddNewTeamModal(true)}
            data-testid="button-create-team"
          >
            <TitleFormattedMessage id="deals.add" defaultMessage="Create Workspace" />
          </Button>
        </Row>
        <Row className="m-2">
          <Button className="btn " onClick={handleLogout} data-testid="button-create-team" variant="link">
            <TitleFormattedMessage id="deals.add" defaultMessage="Sign Out" />
          </Button>
        </Row>
      </Col>
      <CreateNewTeamModal show={showAddNewTeamModal} handleHide={() => setShowAddNewTeamModal(false)} />
    </section>
  );
};

export default NoTeamPage;
