import React, { useEffect, useState } from 'react';
import MobileSidebar from '../../mobile-layout/mobile-sidebar';
import { FormattedMessage } from 'react-intl';
import ActivitiesCard from '../../card/ActivitiesCard';
import { useDealId } from '../../../deal-id-context';
import { useQuery } from '@apollo/client';
import { queries, queriesV2 } from '../../../api';

interface ActivitySidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const ActivitySidebar: React.FC<ActivitySidebarProps> = ({ isOpen, onClose }) => {
  const dealId = useDealId();

  const { loading: isDealLoading, data: dealData } = useQuery(queries.getDealById, {
    variables: {
      dealId,
    },
  });

  const { loading: isDealDocumentsDataLoading, data: dealDocumentsData } = useQuery(queriesV2.getDocumentsByDeal, {
    variables: {
      dealId,
    },
  });

  return (
    <MobileSidebar
      header={
        <div data-testid="deal-activity-sidebar" role={'heading'} className={'ml-3'}>
          <FormattedMessage id="title.activity" defaultMessage="Activity" />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <ActivitiesCard
        isSidebar
        dealData={dealData?.getDealById || {}}
        dealDocumentsData={dealDocumentsData?.getDocumentsByDeal}
      />
    </MobileSidebar>
  );
};

export default ActivitySidebar;
