import { ApolloClient } from '@apollo/client';
import { getDealById } from '../../../api/queries';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  success: boolean;
};

export function removeReceiverTeamFromDeal({
  dealId,
  additionalMessage,
}: {
  dealId: string;
  additionalMessage?: string;
}) {
  return function(client: ApolloClient<object>): Promise<ResponsePayloadType> {
    const variables = {
      dealId,
      additionalMessage,
    };

    const mutation = graphql(/* GraphQL */`
      mutation removeReceiverTeamFromDeal($dealId: ID!, $additionalMessage: String) {
        removeReceiverTeamFromDeal(dealId: $dealId, additionalMessage: $additionalMessage) {
          receiverTeam {
            id
          }
        }
      }
    `);

    const refetchQueries = [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.removeReceiverTeamFromDeal.receiverTeam);
  };
}
