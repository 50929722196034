import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { LeaseDocumentWizardModal } from '../../../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import { prepareToSignDocument } from '../../../../../../api/mutations';
import { getDocumentById } from '../../../../../../api/queries_v2';
import { useNotifications } from '../../../../../../notifications';
import { fillPlaceholders, paths } from '../../../../../../routing';
import { DocumentStatusEnum } from '../../../../../../shared/constants/leaseDocument';
import { resetLeaseDocumentState } from '../../../../../../store/lease-document/actions/lease-document.actions';
import { OptionalMessageInputRef } from '../../../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/OptionalMessageInputRef';
import { selectDocumentSource } from '../../../../../../store/lease-document/selectors/lease-document.selectors';
import { DocumentSourceEnum } from '../../../../lease-editor/constants/EditorConstats';
import { saveDocumentVersionOnPossessionPass } from '../../../lease-document-wizard-header.utils';
import { useDocumentRole } from '../../../../../../shared/hooks/useDocumentRole/useDocumentRole';
import styles from './styles.module.scss';

interface Props {
  onHide: () => void;
  show: boolean;
  documentId: string;
  dealId: string;
  teamId: string;
  handleSaveDocument?: (() => Promise<void>) | undefined;
}

const SendToPreparingToSignInternalModal = ({
  onHide,
  show,
  documentId,
  dealId,
  teamId,
  handleSaveDocument,
}: Props) => {
  const documentSource = useSelector(selectDocumentSource);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const { possessionTeamName } = useDocumentRole(documentId);
  const [sendToPreparingToSign] = useMutation(prepareToSignDocument, {
    refetchQueries: [
      {
        query: getDocumentById,
        variables: {
          documentId,
          confirm: true
        },
      },
    ],
  });

  const [, { error: notifyError }] = useNotifications();

  const handleError = useCallback(() => {
    notifyError({
      message: 'Unable to send to preparing to sign.',
    });
  }, [notifyError]);

  const handleSendInternalMutation = useCallback(async () => {
    const isAppDocument = documentSource === DocumentSourceEnum.APP;

    if (isAppDocument) {
      handleSaveDocument && (await handleSaveDocument());
      await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName });
    }

    const additionalMessage = additionalMessageInputRef.current?.value;
    setTimeout(async () => {
      try {
        const { data } = await sendToPreparingToSign({ variables: { documentId, additionalMessage } });
        const document = data?.prepareToSignDocument;

        if (!document) {
          handleError();
          return;
        }

        if (document.status !== DocumentStatusEnum.PREPARING_TO_SIGN) {
          handleError();
          return;
        }

        const signDocumentUrl = fillPlaceholders(paths.Documents.signDocument, {
          documentId,
          teamId,
          dealId,
        });

        dispatch(resetLeaseDocumentState());
        navigate(signDocumentUrl);
      } catch {
        handleError();
        setIsLoading(false);
      } finally {
        onHide();
      }
    }, 1000);
  }, [
    dealId,
    dispatch,
    documentId,
    handleError,
    onHide,
    sendToPreparingToSign,
    teamId,
    documentSource,
    possessionTeamName,
    handleSaveDocument,
    navigate,
  ]);

  const handleSend = useCallback(async () => {
    setIsLoading(true);
    const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

    if (!documentPendingActionsPlugin) {
      return handleSendInternalMutation();
    }

    if (!documentPendingActionsPlugin.hasAny) {
      return handleSendInternalMutation();
    }

    documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
      if (!documentPendingActionsPlugin.hasAny) {
        event.off();
        event.stop();
        return handleSendInternalMutation();
      }
    });
  }, [handleSendInternalMutation]);

  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onHide,
      label: 'Cancel',
      theme: 'outline-secondary',
    },
    {
      onClick: handleSend,
      label: 'Prepare for Signing',
      theme: 'secondary',
      isLoading,
      disabled: isLoading,
      className: styles['cancel-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={styles.title}
      size="lg"
      show={show}
      onHide={onHide}
      title={
        <FormattedMessage
          id="lease-document-wizard-modal.send-for-signing"
          defaultMessage="Prepare Document for Signing"
        />
      }
      modalCTAs={modalCTAs}
    >
      <div className={styles.main}>Add signers, set signing order and insert signature fields to your document.</div>
      <OptionalMessageInputRef ref={additionalMessageInputRef} />
    </LeaseDocumentWizardModal>
  );
};

export default SendToPreparingToSignInternalModal;
