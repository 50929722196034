class CommentsAdapter {
  constructor(editor) {
    this.editor = editor;
  }

  static get requires() {
    return ['Comments'];
  }

  init() {
    const commentsRepositoryPlugin = this.editor.plugins.get('CommentsRepository');
    const config = this.editor.config.get('slCommentsAdapter');
    const privateThreadToggled = this.editor.commands.get('togglePrivateThread');
    const UserPlugin = this.editor.plugins.get('Users');
    const newComments = new Map();

    const currentCommentId = data => {
      const isCommentNew = newComments.get(data.commentId);
      return isCommentNew ? isCommentNew.commentId : data.commentId;
    };

    // Set the adapter on the `CommentsRepository#adapter` property.
    commentsRepositoryPlugin.adapter = {
      addComment(data) {
        let isPublic = !privateThreadToggled.isEnabled; //This is needed because the comment is made on UI before API by CKE

        const comment = {
          ...data,
          isPublic,
          attributes: JSON.stringify(data.attributes),
        }

        return config.postComment(comment, isPublic).then(receivedData => {
          newComments.set(data.commentId, { commentId: receivedData.id });
          config.saveSessionActivity &&
            config.saveSessionActivity(`${isPublic ? 'public_comment' : 'private_comment'}:${data.commentId}:created`);
          this.getCommentThread(data);
          return Promise.resolve({ createdAt: receivedData.createdAt });
        });
      },

      async updateComment(data) {
        const comment = await config.getCommentThread(data.threadId).then(commentThread => {
          return commentThread.comments.find(com => com.commentId === data.commentId);
        });
        return config.updateComment(data)
          .then(result => {
            config.saveSessionActivity &&
            config.saveSessionActivity(`${comment.attributes.isPublic ? 'public_comment' : 'private_comment'}:${comment.commentId}:updated`);
            return Promise.resolve(result);
          });
      },

      async removeComment(data) {
        const comment = await config.getCommentThread(data.threadId).then(commentThread => {
          return commentThread.comments.find(com => com.commentId === data.commentId);
        });
        const commentId = currentCommentId(data);
        return config.removeComment(commentId, data.threadId).then(success => {
          config.saveSessionActivity &&
          config.saveSessionActivity(`${comment.attributes.isPublic ? 'public_comment' : 'private_comment'}:${comment.commentId}:removed`);
          return success;
        });
      },

      getCommentThread(data) {
        const threadId = data.threadId;
        return config.getCommentThread(threadId).then(commentThread => {
          return Promise.resolve(commentThread);
        });
      },

      addCommentThread(data) {
        let isPublic = !privateThreadToggled.isEnabled; //This is needed because the comment is made on UI before API by CKE
        const commentsData = data.comments.map(comment => {
          const {
            attributes,
            commentId,
            content,
          } = comment
          return {
            attributes: JSON.stringify(attributes),
            commentId,
            content,
            isPublic,
            threadId: data.threadId,
          }
        })
        const commentThreadData = {
          threadId: data.threadId,
          documentId: data.documentId,
          attributes: JSON.stringify(data.attributes),
          comments: commentsData,
        }
        return config.addCommentThread(commentThreadData)
          .then((commentThread) => {
            if (commentThread) {
              commentThread.isFromAdapter = true;
            }
            config.saveSessionActivity &&
            config.saveSessionActivity(`${isPublic ? 'public_comment' : 'private_comment'}:${commentThread.comments[0].commentId}:created`);

            return {
              threadId: commentThread.threadId,
              comments: commentThread.comments,
            };
          })
      },

      async updateCommentThread(data) {
        const threadId = data.threadId;
        const commentThread = await config.getCommentThread(threadId).then(commentThread => {
          return commentThread;
        });

        return config.updateCommentThread({
          threadId: data.threadId,
          comments: commentThread.comments,
        })
          .then(() => {
            config.saveSessionActivity &&
            config.saveSessionActivity(`${commentThread.attributes.isPublic ? 'public_comment' : 'private_comment'}:${commentThread.comments[0].commentId}:updated`);
            return Promise.resolve();
          })
      },

      async removeCommentThread(data) {
        const commentThread = await config.getCommentThread(data.threadId).then(commentThread => {
          return commentThread;
        });

        return config.removeCommentThread(data.threadId)
          .then((success) => {
            config.saveSessionActivity &&
            config.saveSessionActivity(`${commentThread.attributes.isPublic ? 'public_comment' : 'private_comment'}:${commentThread.comments[0].commentId}:removed`);
            return Promise.resolve();
          })
      },

      async reopenCommentThread(data) {
        const commentThread = await config.getCommentThread(data.threadId).then(commentThread => {
          return commentThread;
        });

        return config.reopenCommentThread(data.threadId)
          .then((success) => {
            config.saveSessionActivity &&
            config.saveSessionActivity(`${commentThread.attributes.isPublic ? 'public_comment' : 'private_comment'}:${commentThread.comments[0].commentId}:reopened`);
            return Promise.resolve();
          })
      },

      async resolveCommentThread(data) {
        const commentThread = await config.getCommentThread(data.threadId).then(commentThread => {
          return commentThread;
        });

        return config.resolveCommentThread(data.threadId)
          .then((result) => {
            config.saveSessionActivity &&
            config.saveSessionActivity(`${commentThread.attributes.isPublic ? 'public_comment' : 'private_comment'}:${commentThread.comments[0].commentId}:resolved`);

            return Promise.resolve({
              threadId: result.threadId,
              resolvedAt: result.resolvedAt,
              resolvedBy: result.resolvedBy,
            });
          })
      },
    };
  }
}

export default CommentsAdapter;
