import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth';
import { paths } from '.';
import LoadingSpinner from '../components/loading-spinner';
import { TeamIdContext } from '../team-id-context';
import { DealIdContext } from '../deal-id-context';

interface PrivateRouteProps {
  children: React.ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const [{ authLoading, queryLoading, loggedIn, user }] = useAuth() as any;
  const { teamId, dealId } = useParams();
  const navigate = useNavigate();

  const currentPath = useLocation().pathname;

  if (!loggedIn) {
    if (authLoading || (queryLoading && user == null)) {
      return (
        <div>
          <LoadingSpinner />
        </div>
      );
    }

    navigate(paths.auth.login, { state: { returnTo: currentPath } });
    return null;
  }

  return (
    <TeamIdContext.Provider value={teamId}>
      <DealIdContext.Provider value={dealId}>{children}</DealIdContext.Provider>
    </TeamIdContext.Provider>
  );
};

export default PrivateRoute;
