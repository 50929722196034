import React from 'react';
import styles from './styles.module.scss';
import TeamMemberItem from './TeamMemberItem';

interface Permission {
  description: string;
  displayOrder: number;
  id: string;
  key: string;
  name: string;
}

export interface TeamMember {
  id: string;
  companyName: string;
  email: string;
  dealIds: string[];
  fullName: string;
  hasAccessToDeal?: boolean;
  invitationId: string | null;
  isAdmin: boolean;
  isWhitelisted: boolean;
  permissions: any;
}

interface TeamMembersListProps {
  members: TeamMember[];
  showDealMembersOnly: boolean;
  isReadOnly: boolean;
  dealsData: any;
  whitelistPermissionId?: string;
  shouldShowDealsSelector: boolean;
}

const TeamMembersList: React.FC<TeamMembersListProps> = ({
  members,
  showDealMembersOnly,
  dealsData,
  isReadOnly,
  whitelistPermissionId,
  shouldShowDealsSelector,
}) => {
  return (
    <div className={styles.teamMemberList}>
      {members.map(member => (
        <TeamMemberItem
          key={member.id}
          member={member}
          showDealMembersOnly={showDealMembersOnly}
          isReadOnly={isReadOnly}
          dealsData={dealsData}
          whitelistPermissionId={whitelistPermissionId}
          shouldShowDealsSelector={shouldShowDealsSelector}
        />
      ))}
    </div>
  );
};

export default TeamMembersList;
