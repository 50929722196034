import { useCallback } from 'react'
import { useNotifications } from '../../../../../notifications'

export const useLeaseDocumentWizardSuccessNotifier = () => {
  // @ts-ignore - legacy notifications
  const [, { success: notifySuccess }] = useNotifications()

  return useCallback(
    (message: string) => {
      notifySuccess({ message })
    },
    [notifySuccess]
  )
}
