import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../components/modal/PropdocsModal';
import { LeaseTemplateModalComponentProps } from './LeaseTemplateModalContainer';
import styles from './styles.module.scss';

const CreateBlankDocumentModal = ({ isOpen, onClose, modalFilters }: LeaseTemplateModalComponentProps) => {
  return (
    <Modal
      show={isOpen}
      setShow={onClose}
      size="sm"
      title={<FormattedMessage id="title.first-lease-template" defaultMessage="Create Blank Document" />}
    >
      <div className={styles.createBlankDocumentModal}>
        {modalFilters}
      </div>
    </Modal>
  );
};

export default CreateBlankDocumentModal;
