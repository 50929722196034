export enum Permissions {
  View = 'view',
  Comment = 'comment',
  Edit = 'edit',
  Invite = 'invite',
  Approve = 'approve',
  Whitelist = 'whitelist',
}

export type PermissionsKeys = typeof Permissions[keyof typeof Permissions];
