import React from 'react';
import { Document } from '../../gql/graphql';
import { DocumentPermissions } from '../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { DocumentStatusEnum } from '../../shared/constants/leaseDocument';
import { useDocumentRole } from '../../shared/hooks/useDocumentRole/useDocumentRole';
import DocumentActionsDropdownContainer from '../document-table/DocumentActionsDropdownContainer';
import { DocumentInterface } from '../document-table/DocumentTableConstants';
import { DocumentAccessLabelsEnum } from '../document-table/helpers/documentDefinitionHelpers';
import LoadingSpinner from '../loading-spinner';
// import { formatDate } from '../../utils/date';
import styles from './styles.module.scss';

export interface DocData {
  id: string;
  name: string;
  status?: string | null;
  document: Document;
  access: DocumentAccessLabelsEnum;
  onClick?: () => void;
}

interface MobileDocItemProps {
  doc: DocData;
  teamId: string;
  dealId: string;
  isDealShared?: boolean;
  permissions: DocumentPermissions;
  handleEdit: (...params: any) => void;
  onClick?: () => void;
}

export const MobileDocItem: React.FC<MobileDocItemProps> = ({
  doc,
  teamId,
  dealId,
  onClick,
  isDealShared,
  permissions,
  handleEdit,
}) => {
  const documentId = doc.document.id;
  const hasCurrentTeamPossession = doc.document.currentTeamPossession;
  const documentStatus = doc.document.status;
  const { isDocumentCreator, isDocumentRoleLoading } = useDocumentRole(documentId);

  if (isDocumentRoleLoading) {
    return <LoadingSpinner />;
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    handleEdit(documentId, hasCurrentTeamPossession, documentStatus);
  };

  return (
    <div className={styles.docItem} onClick={handleClick}>
      <div className={styles.docItem__header}>
        <div className={styles.docItem__title}>{doc.name}</div>
        <DocumentActionsDropdownContainer
          teamId={teamId}
          dealId={dealId}
          isDealShared={!!isDealShared}
          isDocumentCreator={isDocumentCreator}
          permissions={permissions}
          viewAction={() => handleEdit(documentId, hasCurrentTeamPossession, documentStatus)}
          documentData={doc.document}
        />
      </div>
      <div className={styles.docItem__footer}>
        <div className={styles.docItem__creator}>Access: {doc.access}</div>
        <div className={styles.docItem__updatedAt}>
          Status:{' '}
          <span className="text-capitalize">
            {doc.status === DocumentStatusEnum.PREPARING_TO_SIGN ? 'Preparing To Sign' : doc.status}
          </span>
        </div>
      </div>
    </div>
  );
};
