import snakeToCamel from '../utils/snake-to-camel';
import { api as apiErrorMessages, generic as genericErrorMessage } from '../error-messages';

import * as auth from './auth';
import client from './client';
import * as queries from './queries';
import * as queriesV2 from './queries_v2';
import * as mutations from './mutations';
import usePartyIds from './use-party-ids';
import useTeamPermissions from './use-team-permissions';

/**
 * Turn the API error structure into the UI error structure
 *
 * The API returns an array of error objects. Each of these has a `code` and
 * `message`, and may specify a corresponding `field` and may have other
 * details.
 *
 * Formik only supports one error message per field (and that's probably enough)
 * so we only try to keep one per field; this of course may lose some.
 *
 * We turn each error into an object ready for react-intl.
 *
 * Field names can be included in the hiddenFields parameter in order to move
 * them from field-specific to general.
 *
 * A non-field error, if there are any, will end up in the `_general` key.
 *
 * @function apiErrorsToUiErrors
 * @param {Object[]} apiErrors - Object from API
 * @param {string[]} hiddenFields - Camel-case names of hidden fields whose
 *                                  errors should be moved to form-level errors
 * @returns {Object} - Errors ready for Formik and UI
 */
function apiErrorsToUiErrors(
  apiErrors: { id?: string; field?: string | null; code?: string; message: string }[] = [],
  hiddenFields: string[] = []
) {
  const uiErrors: { [key: string]: any; _general: any } = { _general: null };

  for (const { field, code = '', message } of apiErrors) {
    const camelField = field ? snakeToCamel(field) : null;
    const key = !field || (camelField && hiddenFields.includes(camelField)) ? '_general' : snakeToCamel(field);
    uiErrors[key] = {
      ...(apiErrorMessages[code] ?? {
        id: `form.error.api.${code}`,
        defaultMessage: message,
      }),
      values: {
        ...(apiErrorMessages[code]?.values ?? {}),
        path: camelField,
        // TODO: add parameters from API once they start getting sent
      },
    };
  }
  return uiErrors;
}

/**
 * Turn the GraphQL error structure into the UI error structure
 *
 * @see apiErrorsToUiErrors
 *
 * @function graphqlErrorsToUiErrors
 * @param {Object} errorObject - The error object from GraphQL
 * @returns {Object} - Errors ready for Formik and UI
 */
function graphqlErrorsToUiErrors(errorObject: any) {
  if (!errorObject) {
    return apiErrorsToUiErrors([]);
  }

  if (errorObject.networkError) {
    console.error('Network error:', errorObject.networkError);
    return apiErrorsToUiErrors([
      {
        id: genericErrorMessage.id,
        message: genericErrorMessage.defaultMessage,
      },
    ]);
  }

  if (!errorObject.graphQLErrors) {
    return apiErrorsToUiErrors([]);
  }

  return apiErrorsToUiErrors(errorObject.graphQLErrors);
}

/**
 * Turn the form values we need in the UI, to the format that the api expects
 *
 * Currently, the only difference is that our UI defines empty fields as empty strings, and the api expects null values
 * This is less important with string values, but essential for numbers, which get parsed on the backend, and throw an
 * error with empty strings.
 *
 * @function formValuesToMutationParams
 * @param {Object} values - The form values that are being submitted
 * @returns {Object} - Values ready to be sent in a mutation in a format that the api expects
 */
function formValuesToMutationParams(values: any) {
  return Object.entries(values).reduce((mutationValues: { [key: string]: any }, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      mutationValues[key] = formValuesToMutationParams(value);
    } else {
      mutationValues[key] = value === '' ? null : value;
    }
    return mutationValues;
  }, {});
}

/**
 * Turn the object values that we get from a query to the format that we can use to initialize a form
 *
 * Currently, the only difference is that the api will occasionally return NULL for empty fields,
 * but our controlled inputs require an empty string.
 *
 * @function apiDataToFormValues
 * @param {Object} data - The values from the api to be loaded into the fomr
 * @returns {Object} - Values ready to be initialized in a form
 */
function apiDataToFormValues(data: any) {
  return Object.entries(data).reduce((values: { [key: string]: any }, [key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      values[key] = apiDataToFormValues(value);
    } else {
      values[key] = value === null ? '' : value;
    }
    return values;
  }, {});
}

export {
  apiErrorsToUiErrors,
  graphqlErrorsToUiErrors,
  auth,
  client,
  queries,
  queriesV2,
  mutations,
  usePartyIds,
  useTeamPermissions,
  formValuesToMutationParams,
  apiDataToFormValues,
};
