type ToggleSubNavCollapsedType = 'toggleSubNavCollapsed';
export type ToggleSubNavCollapsedAction = {
  type: ToggleSubNavCollapsedType;
  payload: boolean;
};

export const toggleSubNavCollapsed = (collapsed: boolean): ToggleSubNavCollapsedAction => ({
  type: 'toggleSubNavCollapsed',
  payload: collapsed,
});

type ToggleManageDealParticipantsCollapsedType = 'toggleManageDealParticipantsCollapsed';
export type ToggleManageDealParticipantsCollapsedAction = {
  type: ToggleManageDealParticipantsCollapsedType;
  payload: boolean;
};

export const toggleManageDealParticipantsCollapsed = (
  collapsed: boolean
): ToggleManageDealParticipantsCollapsedAction => ({
  type: 'toggleManageDealParticipantsCollapsed',
  payload: collapsed,
});

type ToggleSidenavPlaceholdersCollapsedType = 'toggleSidenavPlaceholdersCollapsed';
export type ToggleSidenavPlaceholdersCollapsedAction = {
  type: ToggleSidenavPlaceholdersCollapsedType;
  payload: boolean;
};

export const toggleSidenavPlaceholdersCollapsed = (
  collapsed: boolean
): ToggleSidenavPlaceholdersCollapsedAction => ({
  type: 'toggleSidenavPlaceholdersCollapsed',
  payload: collapsed,
});

type SetActiveSidenavPlaceholderType= 'setActiveSidenavPlaceholder';
export type SetActiveSidenavPlaceholderAction = {
  type: SetActiveSidenavPlaceholderType;
  activeUuid: string | null;
};

export const setActiveSidenavPlaceholder = (uuid: string | null): SetActiveSidenavPlaceholderAction => ({
  type: 'setActiveSidenavPlaceholder',
  activeUuid: uuid,
});
