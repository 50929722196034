import React, { useCallback, useEffect, useState } from 'react';
import className from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import { useUserRestrictions } from '../../auth/user-restrictions';
import { useTeamInfo } from '../../team-id-context';
import { usePartyIds, queries } from '../../api';
import { get as getLatestTeamId, set as setLatestTeamId } from '../../utils/latest-team-id';
import Logotext from '../logotext';
import SettingsNav from './settings-subnav';
import { ReactComponent as DealsIcon } from '../../shared/icons/deals-icon.svg';
import { ReactComponent as TeamIcon } from '../../shared/icons/team-icon.svg';
import { ReactComponent as HelpIcon } from '../../shared/icons/help-icon.svg';
import { ReactComponent as SignoutIcon } from '../../shared/icons/sign-out-icon.svg';
import { ReactComponent as TemplateIcon } from '../../shared/icons/icon-template.svg';
import { ReactComponent as ClausesIcon } from '../../shared/icons/icon-clauses.svg';
import { ReactComponent as SettingsIcon } from '../../shared/icons/gear-icon.svg';
import { ReactComponent as ChevronRight } from '../../shared/icons/chevron-right.svg';
import { setTeamPermissions } from '../../store/permissions/permissions.actions';
import { resetLeaseDocumentState } from '../../store/lease-document/actions/lease-document.actions';
import styles from './styles.module.scss';
import { A, fillPlaceholders, paths } from '../../routing';
import { SubscriptionRequiredModal } from '../../pages';

const MainNav = () => {
  const [{ user }, { logout }] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { restrictionsLoading, hasAccessToTemplates, hasAccessToClauses } = useUserRestrictions();
  const [showSubnav, setShowSubnav] = useState(false);
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const teamId = useTeamInfo().teamId ?? getLatestTeamId(user?.id);
  const { loading: partyIdsLoading, partyIds } = usePartyIds();
  const { loading: teamLoading, data: teamData } = useQuery(queries.getTeamById, {
    skip: teamId == null,
    variables: { teamId },
  });

  const loading = partyIdsLoading || teamLoading || restrictionsLoading;
  const partyId = loading ? null : teamData?.teamById.party.id ?? partyIds.tenant;

  useEffect(() => {
    if (teamId == null) return;
    setLatestTeamId(user?.id, teamId);
  }, [teamId, partyId, partyIds, user]);

  const handleLogout = useCallback(
    data => {
      dispatch(setTeamPermissions([]));
      logout(data).then(() => {
        navigate(paths.auth.login);
        dispatch(resetLeaseDocumentState());
      });
    },
    [logout, dispatch, navigate]
  );

  return (
    <div className={styles.nav}>
      <header className={className(styles.header, 'py-3')}>
        <A href={paths.home} aria-label="Home">
          <Logotext isStacked />
        </A>
      </header>
      {teamId ? (
        <Item
          href={paths.deals.landing}
          hrefParams={{ teamId }}
          pathRegex={paths.deals._catchallRegex}
          icon={DealsIcon}
          qa="main-nav-deals"
        >
          <FormattedMessage id="nav.deals" defaultMessage="Deals" />
        </Item>
      ) : (
        <Item href={paths.home} icon={DealsIcon}>
          <FormattedMessage id="nav.deals" defaultMessage="Deals" />
        </Item>
      )}
      <Item href={paths.team} hrefParams={{ teamId }} icon={TeamIcon} qa="main-nav-team">
        <FormattedMessage id="nav.people" defaultMessage="People" />
      </Item>
      {hasAccessToTemplates ? (
        <Item
          href={paths.templates.landing}
          hrefParams={{ teamId }}
          pathRegex={paths.templates._catchallRegex}
          icon={TemplateIcon}
          qa="main-nav-templates"
        >
          <FormattedMessage id="nav.templates" defaultMessage="Templates" />
        </Item>
      ) : (
        <span className={styles.item} onClick={() => setSubscriptionRequiredModal(true)}>
          <TemplateIcon height="25" width="25"/>
          <div className={styles.label}>
            <FormattedMessage id="nav.templates" defaultMessage="Templates" />
          </div>
        </span>
      )}
      {hasAccessToClauses ? (
        <Item
          href={paths.clauses.landing}
          hrefParams={{ teamId }}
          pathRegex={paths.clauses._catchallRegex}
          icon={ClausesIcon}
          qa="main-nav-clauses"
        >
          <FormattedMessage id="nav.clauses" defaultMessage="Clauses" />
        </Item>
      ) : (
        <span className={styles.item} onClick={() => setSubscriptionRequiredModal(true)}>
          <ClausesIcon height="25" width="25"/>
          <div className={styles.label}>
            <FormattedMessage id="nav.clauses" defaultMessage="Clauses" />
          </div>
        </span>
      )}
      <SettingsItem
        pathRegex={paths.settings._catchallRegex}
        href={paths.settings.organizations}
        hrefParams={{ teamId }}
        icon={SettingsIcon}
        qa="main-nav-settings"
        onClick={() => {
          setShowSubnav(true);
        }}
        onMouseEnter={() => {
          setShowSubnav(true);
        }}
        onMouseLeave={() => {
          setShowSubnav(false);
        }}
        showSubnav={showSubnav}
      >
        <span>
          <FormattedMessage id="nav.settings" defaultMessage="Settings" />
        </span>
      </SettingsItem>

      <span
        onMouseEnter={() => {
          setShowSubnav(true);
        }}
        onMouseLeave={() => {
          setShowSubnav(false);
        }}
        className={className(styles.subnavWrapper, { [styles.showSubnav]: showSubnav })}
      >
        <SettingsNav />
      </span>
      {/* The following is a trusted site */
      /* eslint-disable-next-line react/jsx-no-target-blank */}
      <a href="//propdocs.com/support/ " className={styles.item} data-testid="main-nav-help" target="_blank">
        <HelpIcon height="25" width="25"/>
        <div className={styles.label}>
          <FormattedMessage id="nav.help" defaultMessage="Help" />
        </div>
      </a>
      <span onClick={handleLogout} className={className(styles.item, styles.item__signout)}>
        <SignoutIcon height="25" width="25"/>
        <div className={styles.label}>
          <FormattedMessage id="nav.sign_out" defaultMessage="Sign Out" />
        </div>
      </span>
      <SubscriptionRequiredModal
        isOpen={showSubscriptionRequiredModal}
        onClose={() => setSubscriptionRequiredModal(false)}
      />
    </div>
  );
};

export const Item = ({ icon: Icon, children, pathRegex = null, href, hrefParams = {}, qa = {} }) => {
  const { pathname: path } = useLocation();

  const isCurrent = pathRegex?.test(path) ?? path === fillPlaceholders(href, hrefParams);

  return (
    <A
      data-testid={qa}
      className={className(styles.item, { [styles.isCurrent]: isCurrent })}
      href={href}
      hrefParams={hrefParams}
    >
      <Icon height="25" width="25"/>
      <div className={styles.label}>{children}</div>
    </A>
  );
};

export const SettingsItem = ({
  icon: Icon,
  children,
  pathRegex = null,
  href,
  hrefParams = {},
  qa = {},
  onMouseLeave,
  onMouseEnter,
  onClick,
  showSubnav,
}) => {
  const { pathname: path } = useLocation();

  const isCurrent = pathRegex?.test(path) ?? path === fillPlaceholders(href, hrefParams);

  return (
    <span
      data-testid={qa}
      className={className(styles.settingsItem, { [styles.isCurrent]: isCurrent || showSubnav })}
      href={href}
      hrefparams={hrefParams}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <ChevronRight width="25" style={{ visibility: 'hidden' }} />
      <Icon height="25" width="25" />
      <ChevronRight width="25" className={className(styles.arrowRight, { [styles.isCurrent]: isCurrent })} />
      <div className={styles.label}>{children}</div>
    </span>
  );
};

export const SubnavItem = ({ children, pathRegex = null, href, hrefParams = {}, qa = {} }) => {
  const { pathname: path } = useLocation();

  const isCurrent = pathRegex?.test(path) ?? path === fillPlaceholders(href, hrefParams);

  return (
    <A
      data-testid={qa}
      className={className(styles.subnavItem, { [styles.isCurrent]: isCurrent })}
      href={href}
      hrefParams={hrefParams}
    >
      <span className={styles.subnavLabel}>{children}</span>
    </A>
  );
};

export default MainNav;
