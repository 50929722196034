import { gql } from '@apollo/client';

// TODO use https://the-guild.dev/blog/unleash-the-power-of-fragments-with-graphql-codegen
export const TEAM_LIST_DEAL_DATA = gql`
  fragment TeamListDealData on Deal {
    id
    dealType
    dealSizeInSquareFeet
    title
    team {
      id
      name
    }
    dealAccessUsers
    status
    updatedAt
  }
`;

export const TEAM_PERMISSION_DATA = gql`
  fragment TeamPermissionData on TeamPermission {
    id
    allowed
    permission {
      id
      displayOrder
      key
      name
      description
    }
    team {
      id
    }
  }
`;

export const TEAM_DATA = gql`
  fragment TeamData on Team {
    id
    uuid
    name
    party {
      id
    }
    adminUser {
      id
      fullName
      subscriptionName
    }
    timelineMembers {
      id
      fullName
      companyName
      email
    }
    teamMembers {
      id
      teamId
      user {
        id
        fullName
        companyName
        email
        deals {
          id
          title
        }
      }
      teamPermissions {
        ...TeamPermissionData
      }
    }
    pendingInvitations {
      id
      email
      permissions {
        id
        displayOrder
        key
        name
        description
      }
      dealIds
    }
  }

  ${TEAM_PERMISSION_DATA}
`;

export const DEAL_DETAILS_FORM_DATA = gql`
  fragment DealDetailsFormData on Deal {
    id
    dealSizeInSquareFeet
    dealType
    title
    optionalAttributeSet {
      id
      buildingClass
      buildingName
      buildingSizeInSquareFeet
      # isEntireBuilding
      lotSize
      lotSizeUnit
      numberOfFloors
      dealManagement
      taxesInDollars
      taxRateUnit
      unitFloorNumber
    }
  }
`;
