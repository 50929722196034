import {
  DocumentApprovalRequestType,
  DocumentSuggestion, LeaseDocumentTemplateType,
  LeaseDocumentType,
} from '../../components/lease-wizard/lease-document.types';
import { LeaseDocumentAction } from './actions/lease-document.actions';
import { handleSetLeaseDocument, handleSetLeaseDocumentTemplate } from './handlers/handleSetLeaseDocument';
import { handleSetLeaseDocumentId } from './handlers/handleSetLeaseDocumentId';
import { handleSetLeaseDocumentStatus } from './handlers/handleSetLeaseDocumentStatus';
import { handleSetLeaseDocumentName, handleSetLeaseDocumentTitle } from './handlers/handleSetLeaseDocumentTitle';
import { handleSetLeaseDocumentIsEditing } from './handlers/handleSetLeaseDocumentIsEditing';
import { handleSetLeaseDocumentIsPending } from './handlers/handleSetLeaseDocumentIsPending';
import { handleSetLeaseDocumentLastUpdated } from './handlers/setLeaseDocumentLastUpdated';
import { handleSetDocumentEversingUrlPdf } from './handlers/handleSetDocumentEversingUrlPdf';
import { handleSetLeaseDocumentEversignStatus } from './handlers/handleSetLeaseDocumentEversignStatus';
import { handleSetLeaseDocumentCloudVersion } from './handlers/handleSetLeaseDocumentCloudVersion';
import { handleSetLeaseDocumentReceiverTeamId } from './handlers/handleSetLeaseDocumentReceiverTeamId';
import { handleSetLeaseDocumentTeamId } from './handlers/handleSetLeaseDocumentTeamId';
import { handleSetLeaseDocumentPossession } from './handlers/handleSetLeaseDocumentPossession';
import { handleSetLeaseDocumentSuggestions } from './handlers/handleSetLeaseDocumentSuggestions';
import { handleSetLeaseDocumentEditorDisabled } from './handlers/handleSetLeaseDocumentEditorDisabled';
import { handleSetLeaseEditorIsLoaded } from './handlers/handleSetLeaseEditorIsLoaded';
import { handleAddSessionActivity, handleResetSessionActivity } from './handlers/handleAddSessionActivity';
import { handleSetEditorSuggestions } from './handlers/handleSetEditorSuggestions';
import { handleSetDocumentShouldReturnForReceiverReview } from './handlers/handleSetDocumentShouldReturnForReceiverReview';
import { handleSetDocumentRestrictedEditingMode } from './handlers/handleSetDocumentRestrictedEditingMode';
import { DocumentSourceEnum } from '../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { handleSetDocumentSource } from './handlers/handleSetDocumentSource';
import { handleSetDocumentImportedAssetUrl } from './handlers/handleSetDocumentImportedAssetUrl';
import { handleSetDocumentImportedAssetContentType } from './handlers/handleSetDocumentImportedAssetContentType';
import { ImportedDocumentSupportedContentTypes } from '../../components/shared/modals/fileUploadModal/types';
import { handleSetDocumentGeneratedPdfUrl } from './handlers/handleSetDocumentGeneratedPdfUrl';
import { handleSetDocumentApprovalRequest } from './handlers/handleSetDocumentApprovalRequest';
import { handleSetIsDocumentEditable } from './handlers/handleSetIsDocumentEditable';
import { handleSetInteractionsFeedToken } from './handlers/handleSetInteractionsFeedToken';
import { handleSetDocumentCompareModeEnabled } from './handlers/handleSetDocumentCompareModeEnabled';
import { DocumentSigner } from './actions/set-lease-document-signers.action';
import { handleSetDocumentSigners } from './handlers/handleSetDocumentSigners';
import { handleSetDocumentOriginalImportedAssetUrl } from './handlers/handleSetDocumentOriginalImportedAssetUrl';
import { handleSetIsReceiverCollaborationEnabled } from './handlers/handleSetIsReceiverCollaborationEnabled';
import { handleSetDocumentTrackChangesMode } from './handlers/handleSetTrackChangesMode';
import { handleSetIsDocumentTitleEditable } from './handlers/handleSetIsDocumentTitleEditable';
import { handleSetIsEligibleForSigningPrep } from './handlers/handleSetIsEligibleForSigningPrep';
import { handleSetRejectedSuggestion } from './handlers/handleSetRejectedSuggestion';
import { handleSetDocumentPlaceholderRefreshKey } from './handlers/handleSetDocumentPlaceholderRefreshKey';

export type LeaseDocumentStateType = {
  id: string | null;
  status: null | string;
  document: LeaseDocumentType | null;
  template: LeaseDocumentTemplateType | null;
  interactionsFeedToken: string | null;
  approvalRequest: DocumentApprovalRequestType | null;
  currentTeamPossession: boolean;
  isEditable: boolean;
  isEligibleForSigningPrep: boolean;
  isTitleEditable: boolean;
  receiverCollaborationEnabled: boolean;
  title: string | null;
  name: string | null;
  documentSuggestions: DocumentSuggestion[];
  isEditorDisabled: boolean;
  isCompareModeEnabled: boolean;
  isEditing: boolean;
  isPending: boolean;
  isEditorLoaded: boolean;
  lastUpdated: string;
  receiverTeamId: string | null;
  teamId: string | null;
  eversignPdfUrl: string | null;
  eversignStatus: string;
  sessionActivities: string[];
  editorSuggestions: string[];
  isRejectedSuggestion: boolean;
  shouldReturnForReceiverReview: boolean;
  restrictedEditingMode: boolean;
  trackChangesMode: boolean;
  source: DocumentSourceEnum | null;
  importedAssetUrl: string | null;
  originalImportedAssetUrl: string | null;
  contentType: ImportedDocumentSupportedContentTypes | null;
  generatedDocumentPdfUrl: string | null;
  signers: DocumentSigner[];
  placeholderRefreshKey: number | null;
};

export const initialState: LeaseDocumentStateType = {
  id: null,
  status: null,
  document: null,
  template: null,
  interactionsFeedToken: null,
  approvalRequest: null,
  currentTeamPossession: false,
  isEditorDisabled: false,
  receiverCollaborationEnabled: false,
  isEligibleForSigningPrep: false,
  isCompareModeEnabled: false,
  isEditable: true,
  isTitleEditable: true,
  title: null,
  name: null,
  documentSuggestions: [],
  lastUpdated: '',
  isEditing: false,
  isPending: false,
  isEditorLoaded: false,
  receiverTeamId: null,
  teamId: null,
  eversignPdfUrl: null,
  eversignStatus: '',
  sessionActivities: [],
  editorSuggestions: [],
  isRejectedSuggestion: false,
  shouldReturnForReceiverReview: false,
  restrictedEditingMode: false,
  trackChangesMode: false,
  source: null,
  importedAssetUrl: null,
  originalImportedAssetUrl: null,
  contentType: null,
  generatedDocumentPdfUrl: null,
  signers: [],
  placeholderRefreshKey: 0,
};

const reducer = (state: LeaseDocumentStateType = initialState, action: LeaseDocumentAction): LeaseDocumentStateType => {
  switch (action.type) {
    case 'setLeaseDocument':
      return handleSetLeaseDocument(state, action);

    case 'setLeaseDocumentTemplate':
      return handleSetLeaseDocumentTemplate(state, action);

    case 'setLeaseDocumentId':
      return handleSetLeaseDocumentId(state, action);

    case 'setLeaseDocumentStatus':
      return handleSetLeaseDocumentStatus(state, action);

    case 'setInteractionsFeedToken':
      return handleSetInteractionsFeedToken(state, action);

    case 'setLeaseDocumentEditorDisabled':
      return handleSetLeaseDocumentEditorDisabled(state, action);

    case 'setLeaseDocumentPossession':
      return handleSetLeaseDocumentPossession(state, action);

    case 'setLeaseDocumentSuggestions':
      return handleSetLeaseDocumentSuggestions(state, action);

    case 'setLeaseDocumentTitle':
      return handleSetLeaseDocumentTitle(state, action);

    case 'setLeaseDocumentName':
      return handleSetLeaseDocumentName(state, action);

    case 'setLeaseDocumentIsEditing':
      return handleSetLeaseDocumentIsEditing(state, action);

    case 'setLeaseDocumentIsPending':
      return handleSetLeaseDocumentIsPending(state, action);

    case 'setLeaseEditorIsLoaded':
      return handleSetLeaseEditorIsLoaded(state, action);

    case 'setLeaseDocumentLastUpdated':
      return handleSetLeaseDocumentLastUpdated(state, action);

    case 'setLeaseDocumentReceiverTeamId':
      return handleSetLeaseDocumentReceiverTeamId(state, action);

    case 'setLeaseDocumentTeamId':
      return handleSetLeaseDocumentTeamId(state, action);

    case 'setDocumentEversignPdfUrl':
      return handleSetDocumentEversingUrlPdf(state, action);

    case 'setDocumentApprovalRequest':
      return handleSetDocumentApprovalRequest(state, action);

    case 'setIsDocumentEditable':
      return handleSetIsDocumentEditable(state, action);

    case 'setIsDocumentTitleEditable':
      return handleSetIsDocumentTitleEditable(state, action);

    case 'setIsEligibleForSigningPrep':
      return handleSetIsEligibleForSigningPrep(state, action);

    case 'setIsReceiverCollaborationEnabled':
      return handleSetIsReceiverCollaborationEnabled(state, action);

    case 'setDocumentTrackChangesMode':
      return handleSetDocumentTrackChangesMode(state, action);

    case 'setDocumentRestrictedEditingMode':
      return handleSetDocumentRestrictedEditingMode(state, action);

    case 'setDocumentCompareModeEnabled':
      return handleSetDocumentCompareModeEnabled(state, action);

    case 'setLeaseDocumentEversignStatus':
      return handleSetLeaseDocumentEversignStatus(state, action);

    case 'setLeaseDocumentCloudDocumentVersion':
      return handleSetLeaseDocumentCloudVersion(state, action);

    case 'resetLeaseDocument':
      return { ...initialState };

    case 'saveLeaseDocumentDraft':
      return state;

    case 'addSessionActivity':
      return handleAddSessionActivity(state, action);

    case 'resetSessionActivities':
      return handleResetSessionActivity(state);

    case 'setEditorSuggestions':
      return handleSetEditorSuggestions(state, action);

    case 'setRejectedSuggestion':
      return handleSetRejectedSuggestion(state, action);

    case 'setDocumentShouldReturnForReceiverReview':
      return handleSetDocumentShouldReturnForReceiverReview(state, action);

    case 'setDocumentSource':
      return handleSetDocumentSource(state, action);

    case 'setDocumentImportedAssetUrl':
      return handleSetDocumentImportedAssetUrl(state, action);

    case 'setDocumentOriginalImportedAssetUrl':
      return handleSetDocumentOriginalImportedAssetUrl(state, action);

    case 'setDocumentImportedAssetContentType':
      return handleSetDocumentImportedAssetContentType(state, action);

    case 'setDocumentGeneratedDocumentPdfUrl':
      return handleSetDocumentGeneratedPdfUrl(state, action);

    case 'setDocumentSigners':
      return handleSetDocumentSigners(state, action);

      case 'setDocumentPlaceholderRefreshKey':
        return handleSetDocumentPlaceholderRefreshKey(state, action);

    default:
      return state;
  }
};

export { reducer as leaseDocumentReducer };
