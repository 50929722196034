import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownBody, DropdownToggleBlue } from '../../../../../dropdown';
import LoadingSpinner from '../../../../../loading-spinner';
import { useDealRoles } from '../../../../../../shared/hooks/useDealRoles/useDealRoles';
import {
  selectLeaseDocumentTeam,
  selectLeaseDocumentReceiverTeam,
} from '../../../../../../store/lease-document/selectors/lease-document.selectors';
import {
  selectIsInternalApprovalModalOpen,
  selectIsSendDocumentModalOpen,
  selectIsSendForSigningPreparationModalOpen,
  selectIsSendViaEmailModalOpen,
} from '../../../../../../store/modals/modals.selectors';
import {
  setShowRequiredPlaceholdersModal,
  setShowSendDocumentModal,
  setShowSendDocumentViaEmailModal,
  setShowRequestInternalReviewModal,
  setClosePendingWarningReviewModal,
} from '../../../../../../store/modals/modals.actions';
import SendContainer from '../send/sendContainer';
import Tooltip from '../../../../../tooltip';
import RequestInternalApprovalModalContainer from './RequestInternalApprovalModalContainer';
//import ReviewViaEmailModal from './modals/ReviewViaEmailModal';
import ReviewViaEmailModalContainer from './modals/ReviewViaEmailModalContainer';
import styles from './RequestReview.module.scss';
import { SendToPreparingToSignInternal } from '../sendToPreparingToSignInternal';
import { SendForSigning } from '../sendForPreparingToSign';
import { useQuery } from '@apollo/client';
import { queriesV2 } from '../../../../../../api';

import PropdocsModal from '../../../../../modal/PropdocsModal';

import placeholderCompletionStyles from '../sendToPreparingToSignInternal/styles.module.scss'

type RequestReviewContainerProps = {
  dealTeamsData: {
    [U: string]: any;
  };
  documentTeamsAdminData?: any;
  documentId: string;
  dealId: string;
  teamId: string;
  handleSaveDocument?: (() => Promise<void>) | undefined;
  canEdit: boolean;
  canShare: boolean;
  isDocumentEditable: boolean;
  isDocumentInDraft: boolean;
  isDocumentCreator: boolean;
  isDocumentShared: boolean;
  shouldShowInternalSendForSigning: boolean;
  shouldShowSendForSigning: boolean;
};

export const REVIEW_VIA_EMAIL_TOOLTIP =
  'Document will be emailed as a PDF. Replies will be available in the Deal Activity.';

const RequestReviewContainer = ({
  dealTeamsData,
  documentTeamsAdminData,
  documentId,
  dealId,
  handleSaveDocument,
  canEdit,
  canShare,
  teamId,
  isDocumentEditable,
  isDocumentInDraft,
  isDocumentCreator,
  isDocumentShared,
  shouldShowInternalSendForSigning,
  shouldShowSendForSigning,
}: RequestReviewContainerProps) => {

  const dispatch = useDispatch();

  const placeholdersPlugin = window?.editor?.plugins.get('Placeholder');
  const placeholdersInDoc = placeholdersPlugin?.editor?.getPlaceholders();

  const placeholders = placeholdersInDoc?.map((placeholder: any) => {
    return {
      id: placeholder.getAttribute('placeholderid'),
      uuid: placeholder.getAttribute('placeholderid'),
      title: placeholder.getAttribute('name'),
      isRequired: placeholder.getAttribute('required') === 'true',
    }
  })

  const placeholdersToComplete = placeholders?.filter((placeholder: any) => placeholder.isRequired === true)?.length || 0

  const isInternalApprovalModalOpen = useSelector(selectIsInternalApprovalModalOpen);

  const isSendDocumentModalOpen = useSelector(selectIsSendDocumentModalOpen);

  const isSendViaEmailModalOpen = useSelector(selectIsSendViaEmailModalOpen);

  const isSendForSigningPrepModalOpen = useSelector(selectIsSendForSigningPreparationModalOpen);

  const creatorTeamId = useSelector(selectLeaseDocumentTeam);

  const [showRequestInternalApprovalModal, setShowRequestInternalApprovalModal] = useState(isInternalApprovalModalOpen);

  const [showSendViaEmailModal, setshowSendViaEmailModal] = useState(isSendViaEmailModalOpen);

  const [isDropdownToggled, setDropdownToggled] = useState(isSendDocumentModalOpen || isSendForSigningPrepModalOpen);

  const { isCreator, isDealRolesLoading } = useDealRoles(dealId);

  const handleSendViaEmailModalClose = () => {
    setshowSendViaEmailModal(false);
  };

  const handleInternalApprovalModalHide = () => {
    setShowRequestInternalApprovalModal(false);
  };

  if (isDealRolesLoading || !dealTeamsData) {
    return <LoadingSpinner />;
  }

  const {
    getDealById: {
      members: dealMembers,
      team: { approvers: creatorTeamApprovers },
    },
  } = dealTeamsData;

  const receiverTeamApprovers = dealTeamsData?.getDealById?.receiverTeam?.approvers;

  const creatorTeamAdmin = documentTeamsAdminData?.getDocumentById?.team?.adminUser;
  let receiverTeamAdmin = documentTeamsAdminData?.getDocumentById?.receiverTeam?.adminUser;

  if (!receiverTeamAdmin) {
    receiverTeamAdmin = teamId == dealTeamsData?.getDealById?.receiverTeam?.id ? creatorTeamAdmin :  dealTeamsData?.getDealById?.receiverTeam?.adminUser;
  }

  const counterpartyEmail =  teamId == creatorTeamId ? receiverTeamAdmin?.email : creatorTeamAdmin?.email;
  const counterpartyName =  teamId == creatorTeamId ? receiverTeamAdmin?.fullName : creatorTeamAdmin?.fullName;

  const showInternalSendForSigningPrep = shouldShowInternalSendForSigning || isSendForSigningPrepModalOpen;

  return (
    <div className={styles.requestReviewContainer}>
      <div className={styles.requestReviewDropdown}>
        <Dropdown
          show={isDropdownToggled}
          onToggle={() => {
            setDropdownToggled(!isDropdownToggled);
          }}
        >
          <Dropdown.Toggle id="dropdown-deal-actions" as={DropdownToggleBlue}>
            <span className={styles.dropdownTitle}>
              <FormattedMessage id="deals.actions" defaultMessage="Send" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <DropdownBody>
              {(canShare || (isDocumentCreator && isDocumentInDraft)) && (
                <Dropdown.Header>Counterparty</Dropdown.Header>
              )}

              {((canShare && !canEdit) || (canShare && canEdit && !isDocumentEditable)) && (
                <SendContainer
                  teamId={teamId}
                  documentId={documentId}
                  dealId={dealId}
                  showCounterparty={true}
                  counterpartyEmail={counterpartyEmail}
                  counterpartyName={counterpartyName}
                  handleSaveDocument={handleSaveDocument}
                  placeholdersToComplete={placeholdersToComplete}
                  isDropdownOption={true}
                />
              )}

              {canEdit && isDocumentEditable && (
                <>
                  {canShare && (
                    <SendContainer
                      teamId={teamId}
                      documentId={documentId}
                      dealId={dealId}
                      showCounterparty={true}
                      counterpartyEmail={counterpartyEmail}
                      counterpartyName={counterpartyName}
                      handleSaveDocument={handleSaveDocument}
                      placeholdersToComplete={placeholdersToComplete}
                      isDropdownOption
                    />
                  )}

                  {isDocumentCreator && isDocumentInDraft && (
                    <Dropdown.Item
                      as={Button}
                      variant="link"
                      onClick={() => {

                        setshowSendViaEmailModal(true);
                      }}
                    >
                      via Email
                      <Tooltip testId="review-via-email-tooltip" placement="bottom">
                        {REVIEW_VIA_EMAIL_TOOLTIP}
                      </Tooltip>
                    </Dropdown.Item>
                  )}

                  <Dropdown.Header>Internal</Dropdown.Header>
                  <Dropdown.Item
                    as={Button}
                    variant="link"
                    onClick={() => {
                      setShowRequestInternalApprovalModal(true);
                    }}
                  >
                    Review
                  </Dropdown.Item>
                </>
              )}

              {showInternalSendForSigningPrep && (
                <SendToPreparingToSignInternal
                  isOpen={isSendForSigningPrepModalOpen}
                  dealId={dealId}
                  documentId={documentId}
                  teamId={teamId}
                  handleSaveDocument={handleSaveDocument}
                  placeholdersToComplete={placeholdersToComplete}
                />
              )}
              {shouldShowSendForSigning && <SendForSigning teamId={teamId} documentId={documentId} dealId={dealId} />}
            </DropdownBody>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <RequestInternalApprovalModalContainer
        isOpen={showRequestInternalApprovalModal}
        placeholdersToComplete={placeholdersToComplete}
        onClose={handleInternalApprovalModalHide}
        dealMembers={dealMembers}
        teamMembersWithApprovePermission={isCreator ? creatorTeamApprovers : receiverTeamApprovers}
        documentId={documentId}
        handleSaveDocument={handleSaveDocument}
        teamId={teamId}
        isReviewOnly={isDocumentShared}
      />

      <ReviewViaEmailModalContainer
        isOpen={showSendViaEmailModal}
        onClose={handleSendViaEmailModalClose}
        placeholdersToComplete={placeholdersToComplete}
        teamId={teamId}
        dealId={dealId}
      ></ReviewViaEmailModalContainer>
    </div>
  );
};

export default RequestReviewContainer;
