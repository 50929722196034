const DateSvgComponent = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="2048.000000pt"
    height="512.000000pt"
    viewBox="0 0 2048.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <rect x="0" y="0" width="100%" height="100%" fill="#afafaf" />
    <g
      x="0"
      y="0"
      transform="translate(50.000000,450.000000) scale(0.07500000,-0.07500000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M840 4920 l0 -200 -147 0 c-91 0 -175 -6 -218 -15 -225 -47 -413
-235 -460 -460 -22 -105 -22 -3665 0 -3770 47 -225 235 -413 460 -460 105 -22
4065 -22 4170 0 225 47 413 235 460 460 22 105 22 3665 0 3770 -47 225 -235
413 -460 460 -43 9 -127 15 -217 15 l-148 0 0 200 0 200 -200 0 -200 0 0 -200
0 -200 -1320 0 -1320 0 0 200 0 200 -200 0 -200 0 0 -200z m0 -800 l0 -200
200 0 200 0 0 200 0 200 1320 0 1320 0 0 -200 0 -200 200 0 200 0 0 201 0 201
153 -4 c144 -3 156 -4 190 -28 21 -14 50 -43 65 -64 l27 -39 3 -274 3 -273
-2160 0 -2161 0 0 253 c0 139 5 268 10 289 13 44 81 115 124 127 17 5 93 10
169 10 l137 1 0 -200z m3878 -2233 l-3 -1354 -27 -39 c-15 -21 -44 -50 -65
-64 l-37 -25 -2026 0 -2026 0 -37 25 c-21 14 -50 43 -65 64 l-27 39 -3 1354
-2 1353 2160 0 2160 0 -2 -1353z"
      />
      <path d="M760 2620 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M1560 2620 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M2360 2620 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M3160 2620 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M3960 2620 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M760 1820 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M1560 1820 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M2360 1820 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M3160 1820 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M3960 1820 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M760 1020 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M1560 1020 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M2360 1020 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
      <path d="M3160 1020 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z" />
    </g>
  </svg>
);

export default DateSvgComponent;
