import { ApolloClient } from '@apollo/client';
import { getDocumentById } from '../../api/queries_v2';
import { GET_DOCUMENT_ROLE_BY_DOCUMENT_ID } from '../../shared/hooks/useDocumentRole/useDocumentRole';
import { graphql } from '../../gql';

type ResponsePayloadType = {
  id: string | number;
};

export const givePossessionToCounterparty = (
  documentId: string,
  additionalMessage?: string,
  sessionActivities?: string[]
) => (client: ApolloClient<object>): Promise<ResponsePayloadType> => {
  const variables = {
    documentId,
    additionalMessage,
    sessionActivities,
  };

  const mutation = graphql(/* GraphQL */`
    mutation givePossessionToCounterparty($documentId: ID!, $additionalMessage: String, $sessionActivities: [String]) {
      givePossessionToCounterparty(
        documentId: $documentId
        additionalMessage: $additionalMessage
        sessionActivities: $sessionActivities
        confirm: true
      ) {
        id
      }
    }
  `);

  const refetchQueries = [
    {
      query: getDocumentById,
      variables: {
        documentId,
      },
    },
    {
      query: GET_DOCUMENT_ROLE_BY_DOCUMENT_ID,
      variables: {
        documentId,
      },
    },
  ];

  return client.mutate({ mutation, variables, refetchQueries }).then(({ data }: Record<any, any>) => {
    return data.givePossessionToCounterparty;
  });
};
