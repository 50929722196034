import React from 'react';
import c from 'classnames';
import Form from 'react-bootstrap/Form';
import { ReactComponent as SearchIcon } from '../../shared/icons/search-icon.svg';
import styles from './styles.module.scss';

const SearchInput = ({ className, inputClassName = '', iconClassName = '', style = null, ...rest }) => (
  <div style={style} className={c(styles.root, className)}>
    <SearchIcon className={c('text-primary', iconClassName || styles.icon)} />
    <Form.Control type="search" className={inputClassName || styles.input} {...rest} />
  </div>
);

export default SearchInput;
