import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import icon from "./icon.svg";

export default class PrivateThreadUI extends Plugin {
  static get requires() {
      return ['Comments']
  }

  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add("togglePrivateThread", (locale) => {
      const command = editor.commands.get("addCommentThread");

      const trackChangesEditing = editor.plugins.get("TrackChangesEditing");
      trackChangesEditing.enableCommand("togglePrivateThread");

      const togglePrivateThread = editor.commands.get("togglePrivateThread");
      const buttonView = new ButtonView(locale);

      buttonView.set({
        icon,
        withText: false,
        tooltip: () => "Private Comment",
      });

      buttonView.bind("isEnabled").to(command, "isEnabled");

      this.listenTo(buttonView, "execute", () => {
        togglePrivateThread.clearForceDisabled("commentsOnlyMode");
        togglePrivateThread.set("isEnabled", true);

        this.editor.execute("addCommentThread", {
          privateThread: togglePrivateThread.isEnabled,
        });
      });

      this.listenTo(command, "execute", (evt, optionsArray = []) => {
        const { privateThread } = optionsArray[0] || [];

        if (!privateThread) {
          togglePrivateThread.set("isEnabled", false);
        }
      });

      return buttonView;
    });
  }
}
