import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';

export type RemoveComments = {
  documentId: string;
  threadId: string;
};

const mutation = graphql(/* GraphQL */`
  mutation RemoveDocumentComments($documentId: ID!, $threadId: String!) {
    removeDocumentComments(documentId: $documentId, threadId: $threadId)
  }
`);

export const removeDocumentComments = (options: RemoveComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const removeDocumentCommentsWithResult = (options: RemoveComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.removeDocumentComments.success);
  });
};
