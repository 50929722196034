import { Transition } from 'react-transition-group';
import React from 'react';

const fadeStyles = {
  entering: 'show',
  entered:  'show',
  exiting:  'hide',
  exited:  'hide',
  unmounted: 'hide'
};

const Fade = ({ children, ...props }: any) => (
  <Transition {...props} timeout={200}>
    {(status, innerProps) =>
      React.cloneElement(children, {
        ...innerProps,
        className: `fade ${fadeStyles[status]} ${children.props.className}`,
      })
    }
  </Transition>
);

export default Fade;
