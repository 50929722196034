import React, { useCallback, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import styles from '../../../manage-participants/styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubscriptionRequiredModal } from '../../../../pages';
import InviteReceivingTeamFormik from '../../../../forms/InviteReceivingTeamFormContainer';
import { useTeamInfo } from '../../../../team-id-context';
import { useUserDealRestrictions } from '../../../../auth/user-restrictions';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

interface EmptyCounterpartyProps {
  canInvite: boolean;
  refreshCounterpartyData: () => void;
}

const EmptyCounterparty = ({ canInvite, refreshCounterpartyData }: EmptyCounterpartyProps) => {
  const { formatMessage } = useIntl();
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const [showInviteCounterpartyModal, setShowInviteCounterpartyModal] = useState(false);

  const invitationIsSent = useCallback(() => {
    setShowInviteCounterpartyModal(false);
  }, []);
  const { canInviteCounterparty } = useUserDealRestrictions();

  const { teamId } = useTeamInfo();
  const handleInviteCounterpartyButton = canInviteCounterparty
    ? () => setShowInviteCounterpartyModal(true)
    : () => setSubscriptionRequiredModal(true);

  return (
    <>
      <Container className="pl-4 pr-4 flex-row text-center">
        <p className="mb-2">
          No counterparty has <br />
          been invited yet.
        </p>
        {canInvite && (
          <Button
            variant="primary"
            onClick={handleInviteCounterpartyButton}
            className={styles.handleParticipantsButton}
          >
            <TitleFormattedMessage id="cta.invite-counterparty" defaultMessage="Invite Counterparty" />
          </Button>
        )}
      </Container>
      <Modal show={showInviteCounterpartyModal} onHide={() => setShowInviteCounterpartyModal(false)} size="lg">
        <div>
          <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
            <Modal.Title>
              <header className={styles.invitationModal__title}>
                <FormattedMessage id="title.add-team-member" defaultMessage="Invite Counterparty to the Deal" />
              </header>
            </Modal.Title>
          </Modal.Header>
          <InviteReceivingTeamFormik
            /*
                  // @ts-ignore */
            bodyContainer={Modal.Body}
            /*
                  // @ts-ignore */
            actionsContainer={Modal.Footer}
            renderSecondaryAction={() => (
              <Button variant="link" className="text-secondary" onClick={() => setShowInviteCounterpartyModal(false)}>
                <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
              </Button>
            )}
            onSuccess={invitationIsSent}
            teamId={teamId}
            sendWithDocumentId={false}
            shareDocument={false}
            refreshCounterpartyData={refreshCounterpartyData}
          />
        </div>
      </Modal>
      <SubscriptionRequiredModal
        isOpen={showSubscriptionRequiredModal}
        onClose={() => setSubscriptionRequiredModal(false)}
      />
    </>
  );
};

export default EmptyCounterparty;
