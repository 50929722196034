import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';
import { CommentInputType } from "../../../gql/graphql";

export type UpdateComment = {
  documentId: string;
  comment: CommentInputType;
};

const mutation = graphql(/* GraphQL */`
  mutation updateDocumentComment($documentId: ID!, $comment: CommentInputType!) {
    updateDocumentComment(documentId: $documentId, comment: $comment) {
      ...DocumentCommentFields
    }
  }
`);

export const updateDocumentComment = (options: UpdateComment) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const updateDocumentCommentWithResult = (options: UpdateComment) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.updateDocumentComment);
  });
};
