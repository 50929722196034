//Restrictions Hook

import { useQuery } from '@apollo/client';
import { useAuth } from '.';
import { useTeamInfo } from '../team-id-context';
import { get as getLatestTeamId } from '../utils/latest-team-id';
import { queries } from '../api';
import client from '../api/client';
import { useDealId } from '../deal-id-context';

enum DocumentTypes {
  DRAFT = 'app',
  BLANK = 'blank',
  IMPORTED = 'imported',
  IMPORTED_WORD = 'imported_word',
}

export enum CreatedTemplateTypes {
  BLANK = 'blank',
  IMPORTED = 'imported',
  IMPORTED_WORD = 'imported_word',
}

interface UserRestrictions {
  deals: boolean;
  templates: boolean;
  clauses: boolean;
  invite_counterparty: boolean;
  create_teams: boolean;
  documents: [DocumentTypes];
  create_templates: [CreatedTemplateTypes];
  create_clauses: boolean;
}

export function useUserRestrictions(contextOverride: any = {}) {
  const { teamId: contextTeamId } = useTeamInfo();
  const [{ user }] = useAuth();
  const latestTeamId = getLatestTeamId(user!.id);

  const teamId = contextOverride.teamId ?? contextTeamId ?? latestTeamId;

  const { loading: restrictionsLoading, data: restrictionsResponse } = useQuery(queries.getRestrictionsByCurrentUser, {
    skip: !teamId,
  /* WHY ???? client,*/
    variables: { teamId },
  });

  const restrictions: UserRestrictions = restrictionsResponse?.getRestrictionsByCurrentUser?.restrictions;
  const canCreateDeal = restrictions?.deals;
  const canCreateTeam = restrictions?.create_teams;
  const canCreateClauses = restrictions?.create_clauses;
  const hasAccessToTemplates = restrictions?.templates;
  const hasAccessToClauses = restrictions?.clauses;
  const creatableTemplateTypes = restrictions?.create_templates;

  return {
    restrictionsLoading,
    canCreateDeal,
    canCreateTeam,
    hasAccessToTemplates,
    hasAccessToClauses,
    creatableTemplateTypes,
    canCreateClauses,
  };
}

export function useUserDealRestrictions(contextOverride: any = {}) {
  const { teamId: contextTeamId } = useTeamInfo();
  const contextDealId = useDealId();

  const teamId = contextOverride.teamId ?? contextTeamId;
  const dealId = contextOverride.dealId ?? contextDealId;

  const { loading: restrictionsLoading, data: restrictionsResponse } = useQuery(queries.getRestrictionsByCurrentUser, {
    skip: !teamId || !dealId,
    client,
    variables: { teamId, dealId },
  });

  const restrictions: UserRestrictions = restrictionsResponse?.getRestrictionsByCurrentUser?.restrictions;

  const canInviteCounterparty = restrictions?.invite_counterparty;

  const canCreateBlankDocument = restrictions?.documents && restrictions?.documents?.includes(DocumentTypes.BLANK);
  const canUploadDocument = restrictions?.documents && restrictions?.documents?.includes(DocumentTypes.IMPORTED);
  const canCreateDocumentFromTemplate =
    restrictions?.documents && restrictions?.documents?.includes(DocumentTypes.DRAFT);
  const canCreateDocumentFromWord =
    restrictions?.documents && restrictions?.documents?.includes(DocumentTypes.DRAFT && DocumentTypes.IMPORTED_WORD);

  return {
    restrictionsLoading,
    canInviteCounterparty,
    canCreateBlankDocument,
    canUploadDocument,
    canCreateDocumentFromTemplate,
    canCreateDocumentFromWord,
  };
}
