import React from 'react';

import { ReactComponent as LogoIcon } from '../../shared/icons/logo-icon.svg';
import { ReactComponent as LogoFull } from '../../shared/icons/propdocs-logo.svg';

import styles from './styles.module.scss';

const Logotext = ({ isStacked = false, isFull = false }) => (
  <span>
    {isStacked && (
      <div>
        <LogoIcon className={styles.logoIcon} />
      </div>
    )}
    {isFull && (
      <div>
        <LogoFull className={styles.logoFull} />
      </div>
    )}
  </span>
);

export default Logotext;
