import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseEditorIsLoadedAction } from '../actions/lease-document.actions';

export const handleSetLeaseEditorIsLoaded = (
  state: LeaseDocumentStateType,
  action: SetLeaseEditorIsLoadedAction
): LeaseDocumentStateType => ({
  ...state,
  isEditorLoaded: action.isEditorLoaded,
});
