import React from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

import ForgotPasswordForm from '../../forms/forgotPasswordForm';

import PublicLayout from '../../components/public-layout';

const ForgotPassword = () => {
  const { formatMessage } = useIntl();
  return (
    <PublicLayout>
      <Helmet title={formatMessage({ id: 'title.forgot-password', defaultMessage: 'Forgot password?' })} />
      <ForgotPasswordForm />
    </PublicLayout>
  );
};

export default ForgotPassword;
