import React from 'react';
import BootstrapSpinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface SpinnerProps {
  center?: boolean;
  variant?: 'primary' | 'secondary';
  className?: string;
}

const Spinner = ({ center = true, variant = 'primary', className }: SpinnerProps) => (
  <div className={classNames(styles.root, className, { [styles.center]: center })} data-testid={'loading'}>
    <BootstrapSpinner animation="grow" variant={variant} size="sm" className={classNames(styles.icon)} />
    <BootstrapSpinner animation="grow" variant={variant} role="Loading" className={classNames(styles.icon)} />
    <BootstrapSpinner animation="grow" variant={variant} size="sm" className={classNames(styles.icon)} />
  </div>
);

const LoadingSpinner = ({ overlay = false, ...props }) => {
  if (overlay)
    return (
      <div className={styles.overlay}>
        <Spinner {...props} />
      </div>
    );

  return <Spinner {...props} />;
};

export default LoadingSpinner;

/**
 * Height should be 32 or greater otherwise it'll be smaller
 * than the loading spinner itself
 * @param height
 * @param width
 * @param className
 */
export const LoadingSpinnerWithHeight = ({ height = 32, width = 64, className = '' }) => (
  <div style={{ height, width, position: 'relative' }} className={className}>
    <LoadingSpinner />
  </div>
);
