import { SetLeaseDocumentAction, SetLeaseDocumentTemplateAction } from '../actions/lease-document.actions'
import { LeaseDocumentStateType } from '../lease-document.reducer'

export const handleSetLeaseDocument = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentAction
): LeaseDocumentStateType => ({
  ...state,
  document: action.document,
})

export const handleSetLeaseDocumentTemplate = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentTemplateAction
): LeaseDocumentStateType => ({
  ...state,
  template: action.template,
})
