import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentRestrictedEditingModeAction } from '../actions/lease-document.actions';

export const handleSetDocumentRestrictedEditingMode = (
  state: LeaseDocumentStateType,
  action: SetDocumentRestrictedEditingModeAction
): LeaseDocumentStateType => {
  return {
    ...state,
    restrictedEditingMode: action.restrictedEditingMode,
  };
};
