import React from 'react';
import classNames from 'classnames';
import BaseToggle from 'react-toggle';
// @ts-ignore
import styles from './styles.scss';

interface ToggleProps {
  className?: string;
  isCustomDisabledState?: boolean;
  [key: string]: any;
}

const Toggle = ({ className, isCustomDisabledState = true, ...rest }: ToggleProps) => (
  <BaseToggle
    icons={false}
    className={classNames(
      styles.root,
      {
        'react-toggle--disabled react-toggle--disabled-custom': isCustomDisabledState,
      },
      className
    )}
    {...rest}
  />
);

export default Toggle;
