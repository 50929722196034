import {
  stateOptionsCanada,
  stateOptionsUS,
  stateOptionsAustralia,
  defaultStateOptions,
} from '../../shared/constants/dealAddress';

export enum AvailibleCountries {
  CANADA = 'canada',
  UNITED_STATES = 'unitedstates',
  AUSTRALIA = 'australia',
}

export const countryOptions = [
  { value: AvailibleCountries.CANADA, label: { id: 'countryOption.CA', defaultMessage: 'Canada' } },
  { value: AvailibleCountries.UNITED_STATES, label: { id: 'countryOption.US', defaultMessage: 'United States' } },
  { value: AvailibleCountries.AUSTRALIA, label: { id: 'countryOption.Australia', defaultMessage: 'Australia' } },
];

export const selectedStateOptions = (selectedState: AvailibleCountries) => {
  switch (selectedState) {
    case AvailibleCountries.UNITED_STATES:
      return stateOptionsUS;
    case AvailibleCountries.AUSTRALIA:
      return stateOptionsAustralia;
    case AvailibleCountries.CANADA:
      return stateOptionsCanada;
    default:
      return defaultStateOptions;
  }
};
