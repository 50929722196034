import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DocumentSigner } from '../../../../../../store/lease-document/actions/set-lease-document-signers.action';
import Tooltip from '../../../../../tooltip';
import styles from './styles.module.scss';

interface SignerProps {
  id: string;
  signedAt: string;
  email: string;
  order: number;
}

interface Props {
  signers?: DocumentSigner[] | null;
}

enum SigningStatus {
  SIGNED = 'Signed',
  NOTIFIED = 'Notified',
  PENDING = 'Pending',
}

export const SignersTooltipTable = ({ signers }: any) => {
  const signersList = signers?.map((signer: SignerProps, index: any, arr: any) => {
    const isSigned = signer?.signedAt;
    const isNotified = !!arr[index - 1]?.signedAt || !arr[index - 1];
    return (
      <Row className={styles.signersContainer} key={signer.email}>
        <Col className={styles.signerData}>{signer?.email}:</Col>
        <Col className={styles[isSigned ? 'signerStatus__signed' : 'signerStatus']}>
          {isSigned ? SigningStatus.SIGNED : isNotified ? SigningStatus.NOTIFIED : SigningStatus.PENDING}
        </Col>
      </Row>
    );
  });

  return <Container>{signersList}</Container>;
};

export const signersCounter = ({
  hasSignedNumber,
  totalSigners,
}: {
  hasSignedNumber: number;
  totalSigners: number;
}) => (
  <span className={styles.signersBadgeContainer__signersCounter}>
    ({hasSignedNumber}/{totalSigners})
  </span>
);

export const SigningProcessBadgeContainer = ({ signers }: Props) => {
  const sortedSigners = signers?.sort((a, b) => {
    return a?.order - b?.order;
  });
  const totalSigners = signers?.length || 0;
  const hasSignedNumber = sortedSigners?.filter((signer: SignerProps) => !!signer.signedAt).length || 0;

  return (
    <>
      <div className={styles.signersBadgeContainer}>
        <span>
          <FormattedMessage id="document-signing.badge" defaultMessage="Signing" />
        </span>
        <span className={styles.signersBadgeContainer__counter}>
          <Tooltip
            testId="internal-signers-counter"
            icon={signersCounter({ hasSignedNumber, totalSigners })}
            placement="bottom-end"
          >
            <SignersTooltipTable signers={signers} />
          </Tooltip>
        </span>
      </div>
    </>
  );
};
