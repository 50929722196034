import React from 'react';
import { useIntl } from 'react-intl';

import { name as permissionName } from '../../utils/permission-messages';

import { ReactComponent as ViewIcon } from '../../shared/icons/view.svg';
import { ReactComponent as CommentIcon } from '../../shared/icons/comment.svg';
import { ReactComponent as EditIcon } from '../../shared/icons/edit.svg';
import { ReactComponent as InviteIcon } from '../../shared/icons/invite.svg';
import { ReactComponent as ApproveIcon } from '../../shared/icons/approve.svg';
import { Permissions } from '../../shared/constants/permissions';
import Tooltip from '../tooltip';

/**
 * Map a permission key to an icon component
 *
 * @function getIconComponent
 * @param {string} key - Permission key
 * @returns {Object} - Icon component
 */
export function getIconComponent(key) {
  switch (key) {
    case Permissions.View:
      return ViewIcon;
    case Permissions.Comment:
      return CommentIcon;
    case Permissions.Edit:
      return EditIcon;
    case Permissions.Invite:
      return InviteIcon;
    case Permissions.Approve:
      return ApproveIcon;
    default:
      console.error(`No icon for permission key '${key}'`);
      return null;
  }
}

const PermissionIcon = ({ permission, withAltText = true, ...rest }) => {
  const { formatMessage } = useIntl();

  const Icon = getIconComponent(permission.key);
  if (Icon == null) {
    return null;
  }

  const props = {};
  if (withAltText) {
    props.permission = formatMessage(permissionName(permission));
  } else {
    props['aria-hidden'] = true;
  }

  return (
    <Tooltip testId="signers-approvers-counter" icon={<Icon {...props} {...rest} />} placement="bottom-end">
      {props.permission}
    </Tooltip>
  );
};

export default PermissionIcon;
