import { EditorType } from '../../../components/lease-wizard/lease-editor/utils/lease-editor.types';

export const preventEditorEnabling = (editor: EditorType) => {
  editor.on(
    'set:isReadOnly',
    (evt: any, _name: string, value: boolean, oldValue: boolean) => {
      if (!value && oldValue) {
        evt.return = true;

        evt.stop();
      }
    },
    {
      priority: 'highest',
    }
  );
};

export const updateListsDropdownValue = () => {
  const decimalList = window.document.querySelector("[data-cke-tooltip-text='Decimal with leading zero']");
  decimalList?.setAttribute('data-cke-tooltip-text', 'Decimal with decimal');
};
