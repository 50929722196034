import React, { ReactNode } from 'react';
import { NavLink as BaseLink } from 'react-router-dom';

export function fillPlaceholders(pathPattern: string, values: { [key: string]: string }) {
  const matches = pathPattern.match(/:[a-z]+/gi);
  if (!matches) {
    return pathPattern;
  }

  const placeholders = matches.filter((value, index, self) => self.indexOf(value) === index);

  placeholders.sort((a, b) => b.length - a.length);

  return placeholders.reduce((outputPath, placeholder) => {
    const value = values[placeholder.substring(1)];
    if (value == null) {
      throw new Error(`Missing value for ${placeholder}`);
    }
    return outputPath.replace(new RegExp(placeholder, 'g'), value);
  }, pathPattern);
}

interface LinkProps {
  children: ReactNode;
  href: string;
  className?: string;
  target?: string;
  rel?: string;
  hrefParams: { [key: string]: string };
}
const Link = ({ children, href, hrefParams, className, target, rel, ...rest }: LinkProps) => (
  <BaseLink to={fillPlaceholders(href, hrefParams)} className={className} rel={rel} {...rest}>
    {children}
  </BaseLink>
);

export default Link;
