import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

const filterSuggestionsByAuthorRole = (userRole) => (suggestion) =>  {
  const { authorRole } = suggestion.attributes || {};

  return authorRole && authorRole !== 'lost' && authorRole !== userRole
}

class TrackChangesPermissions extends Plugin {
  static get requires() {
    return ['TrackChanges'];
  }

  _acceptAllSuggestions(evt) {
    const trackChanges = this.editor.plugins.get('TrackChanges');
    const userRole = this.editor.config.get('userDocumentRole')
    const suggestions = trackChanges.getSuggestions();
    const counterpartySuggestions = suggestions.filter(filterSuggestionsByAuthorRole(userRole))

    for (const suggestion of counterpartySuggestions) {
      suggestion.accept();
    }

    evt.stop();
  }

  _acceptSelectedSuggestions(evt) {
    const trackChanges = this.editor.plugins.get('TrackChanges');
    const userRole = this.editor.config.get('userDocumentRole')
    const suggestions = trackChanges.getSuggestions();
    const counterpartySuggestions = suggestions.filter(filterSuggestionsByAuthorRole(userRole))
    const selection = this.editor.model.document.selection;
    const range = selection.getFirstRange();

    for (const suggestion of counterpartySuggestions) {
      if (suggestion.isIntersectingWithRange(range)) {
        suggestion.accept();
      }
    }

    evt.stop();
  }

  _discardAllSuggestions(evt) {
    const trackChanges = this.editor.plugins.get('TrackChanges');
    const userRole = this.editor.config.get('userDocumentRole')
    const suggestions = trackChanges.getSuggestions();
    const counterpartySuggestions = suggestions.filter(filterSuggestionsByAuthorRole(userRole))

    for (const suggestion of counterpartySuggestions) {
      suggestion.discard();
    }

    evt.stop();
  }

  _discardSelectedSuggestions(evt) {
    const trackChanges = this.editor.plugins.get('TrackChanges');
    const userRole = this.editor.config.get('userDocumentRole')
    const suggestions = trackChanges.getSuggestions();
    const counterpartySuggestions = suggestions.filter(filterSuggestionsByAuthorRole(userRole))
    const selection = this.editor.model.document.selection;
    const range = selection.getFirstRange();

    for (const suggestion of counterpartySuggestions) {
      if (suggestion.isIntersectingWithRange(range)) {
        suggestion.discard();
      }
    }

    evt.stop();
  }

  init() {
    const editor = this.editor;

    editor.commands.get('acceptAllSuggestions').on('execute', this._acceptAllSuggestions, {priority: 'high'});
    editor.commands.get('acceptSelectedSuggestions').on('execute', this._acceptSelectedSuggestions, {priority: 'high'});
    editor.commands.get('discardAllSuggestions').on('execute', this._discardAllSuggestions, {priority: 'high'});
    editor.commands.get('discardSelectedSuggestions').on('execute', this._discardSelectedSuggestions, {priority: 'high'});
  }
}

export default TrackChangesPermissions;
