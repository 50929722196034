import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useApolloClient as apolloUseApolloClient, useMutation, useQuery } from '@apollo/client';
import DecoupledEditor from '@simplelease/ckeditor5-build-decoupled-document';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { useDrop } from 'react-dnd';
import { MentionCustomization } from './utils/mention-customization';
import { mutations, queriesV2 } from '../../../api';
import SimpleleaseImageUploadAdapter from '../../../utils/cke-image-uploader';
import { EditorType, LeaseEditorContainerPropTypes } from './utils/lease-editor.types';
import CommentsAdapter from '../../../utils/cke-comments-adapter';
import { UsersIntegration } from '../../../utils/cke-users-integration';
import { useAuth } from '../../../auth';

import LoadingSpinner from '../../loading-spinner';
import { useLeaseDocumentWizardErrorNotifier } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-wizard-error-notifier/use-lease-document-wizard-error-notifier.hook';
import {
  DOCUMENT_RECEIVER_WARNING,
  exportPdfConfig,
  formattingOptions,
  restrictedFormattingOptions,
  LeaseEditorErrors,
  listProperties,
  MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR,
  restrictedToolbar,
  restrictedToolbarItems,
  toolbarItems,
  TRACK_CHANGES_BUTTON_LABEL,
} from './constants/EditorConstats';
import {
  selectDocumentRestrictedEditingMode,
  selectDocumentSessionActivities,
  selectDocumentTrackChangesMode,
  selectIsDocumentEditable,
  selectLeaseDocumentCloudVersion,
  selectLeaseDocumentStatus,
  selectLeaseDocumentTitle,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import { selectDealId } from '../../../store/deals/deals.selectors';
import { customCommentMentionRenderer, getFeedItems } from './comments/lease-editor-comments';
import { useCommentContext, useCommentThreadRendered } from '../../../contexts/commentContext';
import { DocumentPermissions } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { GetDealByIdVariables, GetDealTeamsByTeamIdData } from './LeaseEditorTypes';
import TimeoutModal from '../lease-document-wizard-modal/custom-modals/timeoutModal/TimeoutModal';
import { TrackChangesIntegration } from '../../../utils/ckeditor/trackChangesAdapter';
import { CreateSuggestionPayload, DocumentTypesEnum } from '../../../utils/ckeditor/trackChangesAdapter/types';
import { useSuggestionsHandling } from '../../../shared/hooks/useSuggestionsHandling';
import { InsertedFieldsTooltip } from '../../shared/insertFieldsTooltip';
import RevisionHistoryAdapter from '../../../utils/ckeditor/revisionHistoryAdapter';
import { useRevisionHistory } from '../../../shared/hooks/useRevisionHistory';
import {
  ALIGNMENT_CONFIG,
  FONT_BG_COLOR_CONFIG,
  FONT_COLOR_CONFIG,
  FONT_FAMILY_CONFIG,
  FONT_SIZE_CONFIG,
  HIGHLIGHT_CONFIG,
  PAGINATION_CONFIG,
  TABLE_CONFIG,
} from '../../../utils/ckeditor/config/constants';
import { preventEditorEnabling, updateListsDropdownValue } from '../../../utils/ckeditor/utils';
import { useDocumentRole } from '../../../shared/hooks/useDocumentRole/useDocumentRole';
import { useNotifications } from '../../../notifications';
import {
  addSessionActivity,
  setDocumentCompareModeEnabled,
  setEditorSuggestions,
  setLeaseDocumentEditorDisabled,
  setLeaseEditorIsLoaded,
  setRejectedSuggestion,
} from '../../../store/lease-document/actions/lease-document.actions';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import { useDealRoles } from '../../../shared/hooks/useDealRoles/useDealRoles';
import { ApprovalRequestStatus } from '../lease-document-wizard-header/components/headerActions/requestReview/RequestInternalApprovalModalConstants';
import {
  handleInlineNegativeTextIndent,
  handleLeftMarginRemovalFromFigure,
  handleLetterSpacingRemovalOnImportWord,
  handleLineHeightRemovalOnImportWord,
  handleListStylingOnImportWord,
  handleMarginStylesRemovalOnImportWord,
  handleNegativeMarginRemovalOnImportWord,
  handleSaveRevision,
} from './constants/helpers';
import { setShowHeaderFooterEditorModal } from '../../../store/modals/modals.actions';
import { Warning } from '../../shared/warning';
import './lease-editor.scss';
import { LeaseDocumentComment } from '../lease-document.types';
import { useEditorConfigFunctions } from './utils/editorConfigFunctions';
import { TrackChangesTooltip } from './components/trackChangesTooltip';
import { draftShareString } from '../lease-document-wizard-header/lease-document-wizard-header.utils';
import useEditorSetupData from './hooks/useEditorSetupData';
import createPlaceholderAdapter from '../../../utils/ckeditor/placeholderAdapter/placeholderAdapter';

export const PRIVATE_COMMENT_MARKER = '[PRIVATE]';

declare global {
  interface Window {
    editor: EditorType;
    commentsContext: LeaseDocumentComment[];
  }
}
export const IDLE_TIMER_TIMEOUT_MILLISECONDS = 1000 * 60 * 10;

export const LeaseEditorContainer = ({
  leaseDocument,
  save,
  documentId,
  documentType,
  showMenu = true,
  canEdit,
  canComment,
  permissions,
  isShared,
  teamId,
  hasCurrentTeamPossession,
  approvalRequest,
  documentData,
  documentLoading
}: LeaseEditorContainerPropTypes) => {
  const { getDecoupledEditor } = DecoupledEditor;
  const commentContext = useCommentContext();
  const updateThreadRendered = useCommentThreadRendered();
  const navigate = useNavigate();
  const updateThreadRenderedContext = (threadId: string, contextComments: Object[]) =>
    updateThreadRendered(threadId, contextComments);
  // @ts-ignore - user: { fullName: string, email: string }
  const [{ authLoading, user }] = useAuth();

  const [, { hide: hideNotifications }] = useNotifications();

  const [isDocumentLocked, setIsDocumentLocked] = useState(false);
  const userId = user.id;
  const notifyError = useLeaseDocumentWizardErrorNotifier();
  const dealId = useSelector(selectDealId);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const documentTitle = useSelector(selectLeaseDocumentTitle);
  const sessionActivitiesList = useSelector(selectDocumentSessionActivities);
  const restrictedEditingMode = useSelector(selectDocumentRestrictedEditingMode);
  const trackChangesMode = useSelector(selectDocumentTrackChangesMode);
  const isDocumentEditable = useSelector(selectIsDocumentEditable);

  const isLockedRef = useRef<boolean>(false);
  const [lockDocument, { loading: isLockLeaseDocumentLoading }] = useMutation(mutations.lockDocument);
  const [unlockDocument] = useMutation(mutations.unlockDocument);
  const {
    createSuggestion,
    getSuggestionById,
    deleteSuggestionById,
    acceptSuggestionById,
    rejectSuggestionById,
    pendSuggestionById,
  } = useSuggestionsHandling(documentId, DocumentTypesEnum.DOCUMENT);
  const { getDocumentRevisions, updateDocumentRevisions } = useRevisionHistory(documentId, DocumentTypesEnum.DOCUMENT);
  const { isDocumentCreator, isDocumentRoleLoading } = useDocumentRole(documentId!);
  const { isCreator, isDealRolesLoading } = useDealRoles(dealId);
  const activitiesRef = useRef<string[]>([]);
  const isDocumentExecuted = [DocumentStatusEnum.EXECUTED].includes(documentStatus as DocumentStatusEnum);
  const isApprovalRequestPendingOrApproved =
    approvalRequest?.status === ApprovalRequestStatus.PENDING ||
    (approvalRequest?.status === ApprovalRequestStatus.APPROVED && !isDocumentEditable);
  useEffect(() => {
    activitiesRef.current = sessionActivitiesList;
  }, [sessionActivitiesList]);

  const isApprovalRequestPending = approvalRequest?.status === ApprovalRequestStatus.PENDING;

  const {
    addCommentThread,
    getCommentThread,
    reopenCommentThread,
    resolveCommentThread,
    updateCommentThread,
    removeCommentThread,
    removeComment,
    updateComment,
    postComment,
  } = useEditorConfigFunctions({ type: 'DOCUMENT', documentId: documentId as string });

  const {
    loading: placeholderCompletenessLoading,
    data
  } = useQuery(queriesV2.getDocumentPlaceholderCompleteness, {
    skip: !documentId,
    variables: {
      documentId: String(documentId),
      documentType: 'Document'
    },
  })

  const handleUnlock = useCallback(async () => {
    if (isLockedRef.current) {
      await unlockDocument({ variables: { documentId: documentId!, userId, sessionActivities: activitiesRef.current } });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    !isApprovalRequestPendingOrApproved &&
      lockDocument({ variables: { documentId: documentId!, userId } })
        .then(() => {
          isLockedRef.current = true;
        })
        .catch(() => {
          if (hasCurrentTeamPossession) {
            setIsDocumentLocked(true);
            !isDocumentExecuted && notifyError({ message: LeaseEditorErrors.IS_LOCKED.defaultMessage, pinned: true });
          }
        });

    return () => {
      if (isLockedRef.current || (isApprovalRequestPendingOrApproved && !isDocumentEditable)) {
        unlockDocument({
          variables: { documentId: documentId!, userId, sessionActivities: activitiesRef.current },
          refetchQueries: [
            {
              query: queriesV2.getDocumentsByDeal,
              variables: {
                dealId,
              },
            },
          ],
        });
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    return () => {
      hideNotifications();
    };
  }, [hideNotifications]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnlock);

    return () => {
      window.removeEventListener('beforeunload', handleUnlock);
    };
  }, []); // eslint-disable-line

  const navigateToDealDetails = useCallback(() => {
    const route = `/teams/${teamId}/deals/${dealId}`;

    navigate(route);
  }, [dealId, teamId, navigate]);

  // TODO: clean / optimize
  // const handleCommentCreationError = useCallback(() => {
  //   notifyError({
  //     message: intl.formatMessage({
  //       id: 'LeaseDocumentCommentsDrawer.comment-creation-error',
  //       defaultMessage: 'Unable to create a comment',
  //     }),
  //   });
  // }, [notifyError, intl]);
  const { loading: dealLoading, data: dealData } = useQuery<GetDealTeamsByTeamIdData, GetDealByIdVariables>(
    queriesV2.getDealTeamsByTeamId,
    {
      skip: !dealId,
      variables: {
        dealId: dealId,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  );

  const cloudVersion = useSelector(selectLeaseDocumentCloudVersion);
  const saveData = useCallback(
    document => {
      return save({
        body: document,
        cloudDocumentVersion: Number(cloudVersion) + 1,
      });
    },
    [cloudVersion, save]
  );

  const userCommentArchivePermission = useMemo(() => {
    if (dealLoading || !dealData) return false;

    const {
      getDealById: { team },
    } = dealData;
    return team.teamMembers.some(member => {
      return member.user.id === user.id;
    });
  }, [dealData, dealLoading, user]);

  const userIsReceiver = useCallback(() => {
    if (documentLoading || !documentData) return false;

    const {
      getDocumentById: { currentUserRole },
    } = documentData;
    return currentUserRole === 'receiver';
  }, [documentData, documentLoading]);

  const userList = useMemo(() => {
    if (dealLoading || !dealData) return [];

    const {
      getDealById: { team, receiverTeam, members: dealMembers, timelineMembers: dealTimelineMembers },
    } = dealData;
    const receiverTeamMembers = receiverTeam?.teamMembers || [];
    const bothTeamsMembers = [...team.teamMembers, ...receiverTeamMembers];
    const dealMembersIds = dealMembers.map(({ id }) => id);
    const teamsMembersWithAccessToDeal = bothTeamsMembers.filter(({ user }) => dealMembersIds.includes(user.id));

    const mappedTimelineMembers = dealTimelineMembers.map(({ id, email, fullName }) => ({ 
      id, name: dealMembersIds.includes(id) ? fullName : `${fullName} *deleted*`, email 
    }));

    return mappedTimelineMembers;
  }, [dealLoading, dealData]);

  const currentUserTeamMembersList = useMemo(() => {
    if (dealLoading || !dealData) return [];

    const {
      getDealById: { team, receiverTeam, members: dealMembers },
    } = dealData;
    const receiverTeamMembers = receiverTeam?.teamMembers || [];
    const currentUserTeamList = isCreator ? team.teamMembers : receiverTeamMembers;
    const dealMembersIds = dealMembers.map(({ id }) => id);
    const teamsMembersWithAccessToDeal = currentUserTeamList.filter(({ user }) => dealMembersIds.includes(user.id));
    return teamsMembersWithAccessToDeal.map(({ user }) => ({ id: user.id, name: user.fullName, email: user.email }));
  }, [dealLoading, isCreator, dealData]);

  if (
    authLoading ||
    dealLoading ||
    isLockLeaseDocumentLoading ||
    isDocumentRoleLoading ||
    isDealRolesLoading ||
    documentLoading
  ) {
    return <LoadingSpinner />;
  }

  if (!user || !dealData) {
    return null;
  }

  return (
    <>
      <LeaseEditor
        editor={getDecoupledEditor({
          context: commentContext,
          threadRendered: updateThreadRenderedContext,
          isCreator: isDocumentCreator,
          documentReadOnly: isApprovalRequestPending || isDocumentExecuted,
          permissions,
          isShared,
          userDocumentRole: isDocumentCreator ? 'creator' : 'receiver',
          documentStatus,
          renderTooltip: () => {},
          enableRestrictedEditingMode: restrictedEditingMode,
          documentType: 'lease',
        })}
        isCreator={isDocumentCreator}
        user={user}
        saveData={saveData}
        postComment={postComment}
        updateComment={updateComment}
        addCommentThread={addCommentThread}
        updateCommentThread={updateCommentThread}
        resolveCommentThread={resolveCommentThread}
        reopenCommentThread={reopenCommentThread}
        removeComment={removeComment}
        removeCommentThread={removeCommentThread}
        getCommentThread={getCommentThread}
        leaseDocument={leaseDocument}
        documentId={documentId}
        userList={userList}
        currentUserTeamMembersList={currentUserTeamMembersList}
        showMenu={showMenu}
        canEdit={canEdit && !isApprovalRequestPendingOrApproved}
        canComment={canComment && !isApprovalRequestPendingOrApproved}
        hasCurrentTeamPossession={hasCurrentTeamPossession}
        permissions={permissions}
        documentStatus={documentStatus}
        notifyError={notifyError}
        navigateToDealDetails={navigateToDealDetails}
        documentType={documentType}
        createSuggestion={createSuggestion}
        getSuggestionById={getSuggestionById}
        deleteSuggestionById={deleteSuggestionById}
        acceptSuggestionById={acceptSuggestionById}
        rejectSuggestionById={rejectSuggestionById}
        pendSuggestionById={pendSuggestionById}
        save={save}
        getDocumentRevisions={getDocumentRevisions}
        createDocumentRevision={updateDocumentRevisions}
        isDocumentLocked={isDocumentLocked}
        restrictedEditingMode={restrictedEditingMode}
        trackChangesMode={trackChangesMode}
        isApprovalRequestPending={isApprovalRequestPendingOrApproved}
        documentTitle={documentTitle || 'Document'}
        isDocumentCreator={isDocumentCreator}
        userCommentArchivePermission={userCommentArchivePermission}
        userIsReceiver={userIsReceiver()}
        teamId={teamId}
      />
    </>
  );
};

type LeaseEditorPropTypes = {
  editor: any;
  user: any;
  saveData: any;
  leaseDocument: Record<string, any>;
  isCreator: boolean;
  documentId?: string;
  documentType?: string;
  userList: any;
  currentUserTeamMembersList: any;
  postComment: any;
  updateComment: any;
  addCommentThread: any;
  updateCommentThread: any;
  reopenCommentThread: any;
  removeComment: any;
  removeCommentThread: any;
  resolveCommentThread: (commentId: string, documentId: string) => Promise<any>;
  getCommentThread: any;
  showMenu: boolean;
  permissions: DocumentPermissions;
  canEdit: boolean;
  canComment: boolean;
  isDocumentLocked: boolean;
  hasCurrentTeamPossession: boolean;
  documentStatus: string | null;
  documentTitle: string;
  notifyError: ({ message, pinned }: { message: string; pinned: boolean }) => void;
  createSuggestion: (data: CreateSuggestionPayload) => Promise<any>;
  getSuggestionById: (suggestionId: string) => Promise<any>;
  deleteSuggestionById: (suggestionId: string) => Promise<any>;
  acceptSuggestionById: (suggestionId: string, suggestionData: string) => Promise<any>;
  rejectSuggestionById: (suggestionId: string, suggestionData: string) => Promise<any>;
  pendSuggestionById: (suggestionId: string) => Promise<any>;
  navigateToDealDetails: () => void;
  save: any;
  getDocumentRevisions: any;
  createDocumentRevision: any;
  restrictedEditingMode: boolean;
  trackChangesMode: boolean;
  isApprovalRequestPending: boolean;
  isDocumentCreator: boolean;
  userCommentArchivePermission: boolean;
  userIsReceiver: boolean | never[] | undefined;
  teamId: string;
};

const LeaseEditor = ({
  editor,
  isCreator,
  user,
  postComment,
  addCommentThread,
  updateComment,
  resolveCommentThread,
  updateCommentThread,
  reopenCommentThread,
  removeComment,
  removeCommentThread,
  getCommentThread,
  leaseDocument,
  documentId,
  userList,
  currentUserTeamMembersList,
  showMenu,
  canEdit,
  canComment,
  documentStatus,
  documentTitle,
  notifyError,
  permissions,
  navigateToDealDetails,
  createSuggestion,
  getSuggestionById,
  deleteSuggestionById,
  acceptSuggestionById,
  rejectSuggestionById,
  pendSuggestionById,
  save,
  getDocumentRevisions,
  createDocumentRevision,
  hasCurrentTeamPossession,
  isDocumentLocked,
  restrictedEditingMode,
  trackChangesMode,
  isApprovalRequestPending,
  isDocumentCreator,
  userCommentArchivePermission,
  userIsReceiver,
  teamId,
}: LeaseEditorPropTypes) => {
  const toolbarElem = useRef<HTMLDivElement>(null);
  const revisionToolbarElem = useRef<HTMLDivElement>(null);
  const sidebarNode = useRef<HTMLDivElement>(document.createElement('div'));
  const apolloClient = apolloUseApolloClient();
  const dispatch = useDispatch();
  const [syncDocumentSuggestions] = useMutation(mutations.syncDocumentSuggestions);
  const [updateEditorConfiguration] = useMutation(mutations.updateDocumentEditorConfiguration);
  const [createDocumentPlaceholder] = useMutation(mutations.createDocumentPlaceholder);
  const [removeDocumentPlaceholder] = useMutation(mutations.removeDocumentPlaceholder);
  const [updateDocumentPlaceholder] = useMutation(mutations.updateDocumentPlaceholder);
  const idleTimerRef = useRef<IdleTimer>(null);
  const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false);

  const {
    isLayoutReady,
    setCkeInstance,
    ckeInstance,
  } = useEditorSetupData({ resize: true, documentId, documentType: 'document', apolloClient });

  const customUploadAdapter = useCallback(
    function(editor: EditorType) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return new SimpleleaseImageUploadAdapter(loader, apolloClient, documentId, notifyError);
      };
    },
    [apolloClient, documentId, notifyError]
  );

  const usersPluginIntegration = useCallback(
    function(editor: EditorType) {
      return new UsersIntegration(editor, user, userList);
    },
    [user, userList]
  );

  const MentionPlugin = useMemo(() => {
    return editor.builtinPlugins.find((plugin: any) => plugin.pluginName === 'Mention');
  }, [editor.builtinPlugins]);

  const saveSessionActivity = useCallback(
    (activity: string) => {
      dispatch(addSessionActivity(activity));
    },
    [dispatch]
  );

  const cloudDocumentVersion = useSelector(selectLeaseDocumentCloudVersion);

  const isEditorDisabled = useCallback(() => {
    if (!documentStatus || !hasCurrentTeamPossession || isDocumentLocked || isApprovalRequestPending) {
      dispatch(setLeaseDocumentEditorDisabled(true));
      return true;
    }

    if (!permissions.hasEditPermission && !permissions.hasCommentPermission) return true;

    if (documentStatus === DocumentStatusEnum.PREPARING_TO_SIGN && !isCreator) return true;

    const shouldDocumentBeEditable = [
      DocumentStatusEnum.DRAFT,
      DocumentStatusEnum.UPLOADED,
      DocumentStatusEnum.REVIEWING,
    ].includes(documentStatus as DocumentStatusEnum);

    return !shouldDocumentBeEditable;
  }, [
    documentStatus,
    hasCurrentTeamPossession,
    isDocumentLocked,
    permissions.hasEditPermission,
    permissions.hasCommentPermission,
    isCreator,
    dispatch,
    isApprovalRequestPending,
  ]);

  const tokenUrlCallback = useCallback(() => {
    if (!documentId) {
      return;
    }

    const queryPromise = apolloClient.query({
      query: queriesV2.getCkeditorDocumentToken,
      variables: { documentId },
      fetchPolicy: 'no-cache',
    });

    return queryPromise.then(({ data }: Record<any, any>) => data.getCkeditorDocumentToken);
  }, [apolloClient, documentId]);

  const handleAutoSave = useCallback(
    (editor: EditorType) => {
      if (!isEditorDisabled() && hasCurrentTeamPossession) {
        const trackChanges = editor.plugins.get('TrackChanges');
        const suggestionsData = trackChanges.getSuggestions({
          skipNotAttached: true,
          toJSON: true,
        });
        const externalSuggestionsIds = suggestionsData.map((suggestion: any) => suggestion.id);

        syncDocumentSuggestions({
          variables: {
            documentId: documentId!,
            documentType: DocumentTypesEnum.DOCUMENT,
            externalIds: externalSuggestionsIds,
          },
          fetchPolicy: 'network-only',
        });
      }

      const revisionTracker = editor.plugins.get('RevisionTracker');
      return revisionTracker.update();
    },
    [documentId, syncDocumentSuggestions, isEditorDisabled, hasCurrentTeamPossession]
  );

  const hasOnlyCommentsPermission = !permissions.hasEditPermission && permissions.hasCommentPermission;
  const hasUserCommentsPermission = permissions.hasCommentPermission;

  const isDocumentSigningOrExecuted = [DocumentStatusEnum.SIGNING, DocumentStatusEnum.EXECUTED].includes(
    documentStatus as DocumentStatusEnum
  );

  const isDocumentDraftOrReviewing = [
    DocumentStatusEnum.DRAFT,
    DocumentStatusEnum.UPLOADED,
    DocumentStatusEnum.REVIEWING,
  ].includes(documentStatus as DocumentStatusEnum);

  const isDocumentDraft = [DocumentStatusEnum.DRAFT].includes(documentStatus as DocumentStatusEnum);

  const getRevisionActions = () => {
    if (isDocumentSigningOrExecuted) {
      return ['compareAgainstSelected'];
    }

    if (isCreator) {
      return permissions.hasEditPermission && isDocumentDraft && !isEditorDisabled()
        ? ['compareAgainstSelected', 'restoreRevision', 'nameRevision']
        : permissions.hasEditPermission
        ? ['compareAgainstSelected', 'nameRevision']
        : ['compareAgainstSelected'];
    }

    return permissions.hasEditPermission ? ['compareAgainstSelected', 'nameRevision'] : ['compareAgainstSelected'];
  };

  const isRenamingDisabled = isDocumentSigningOrExecuted || !permissions.hasEditPermission;
  const config = {
    licenseKey: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
    default_styles: true,
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
      disallow: [
        {
          name: 'script',
        },
        {
          attributes: [
            {
              key: /^on(.*)/i,
              value: true,
            },
            {
              key: /.*/,
              value: {
                lastIndex: 0,
                dotAll: false,
                flags: 'i',
                global: false,
                hasIndices: false,
                ignoreCase: true,
                multiline: false,
                source: '(\\b)(on\\S+)(\\s*)=|javascript:|(<\\s*)(\\/*)script',
                sticky: false,
                unicode: false,
              },
            },
            {
              key: /.*/,
              value: {
                lastIndex: 0,
                dotAll: false,
                flags: 'i',
                global: false,
                hasIndices: false,
                ignoreCase: true,
                multiline: false,
                source: 'data:(?!image\\/(png|jpeg|gif|webp))',
                sticky: false,
                unicode: false,
              },
            },
          ],
        },
      ],
    },
    cloudServices: {
      tokenUrl: tokenUrlCallback,
      uploadUrl: process.env.REACT_APP_CKEDITOR_IMAGE_UPLOAD_URL,
      webSocketUrl: process.env.REACT_APP_CKEDITOR_WEBSOCKET_URL,
      documentId: documentId,
    },
    userDocumentRole: isCreator ? 'creator' : 'receiver',
    toolbar: restrictedEditingMode
      ? restrictedToolbarItems(isCreator)
      : toolbarItems(isCreator, userCommentArchivePermission),
    balloonToolbar: hasUserCommentsPermission && ['publicComment', 'togglePrivateThread'],
    restrictedEditing: {
      allowedCommands: restrictedToolbar,
      allowedAttributes: ['checked'],
    },
    formattingOptions: restrictedEditingMode ? restrictedFormattingOptions : formattingOptions,
    importWord: {
      defaultStyles: true,
      tokenUrl: tokenUrlCallback,
    },
    highlight: HIGHLIGHT_CONFIG,
    fontSize: FONT_SIZE_CONFIG,
    fontFamily: FONT_FAMILY_CONFIG,
    fontColor: FONT_COLOR_CONFIG,
    fontBackgroundColor: FONT_BG_COLOR_CONFIG,
    extraPlugins: [
      customUploadAdapter,
      usersPluginIntegration,
      CommentsAdapter,
      TrackChangesIntegration,
      RevisionHistoryAdapter,
      createPlaceholderAdapter(
        teamId,
        documentId,
        createDocumentPlaceholder,
        updateDocumentPlaceholder,
      ),
    ],
    removePlugins: [
      'EasyImage',
      'ImageCaption',
      'List',
      'TodoListEditing',
      'TodoList',
      'Base64UploadAdapter',
    ],
    autosave: {
      waitingTime: 5000,
      save: (canEdit || canComment) && isDocumentDraftOrReviewing ? handleAutoSave : () => {},
    },
    pagination: PAGINATION_CONFIG,
    commentsOnly: hasOnlyCommentsPermission,
    comments: {
      editorConfig: {
        extraPlugins: [MentionPlugin, MentionCustomization],
        mention: {
          feeds: [
            {
              marker: '@',
              feed: (queryText: any) => getFeedItems(queryText, userList, currentUserTeamMembersList),
              itemRenderer: customCommentMentionRenderer,
            },
          ],
        },
      },
    },
    sidebar: {
      container: sidebarNode.current,
      preventScrollOutOfView: true,
    },
    editHeaderFooter: {
      callHeaderFooterEditorModal: () => {
        dispatch(setShowHeaderFooterEditorModal(true));
      },
    },
    toolTip: {
      renderToolTipClassName: 'tooltip',
      toolTipRenderer: (domElement: HTMLElement, children: any, title: string) => {
        const WrappedComponent = () => (
          <InsertedFieldsTooltip title={title} style={{ marginBottom: '5px' }}>
            <span>{children}</span>
          </InsertedFieldsTooltip>
        );

        ReactDOM.render(<WrappedComponent />, domElement);
      },
    },
    table: TABLE_CONFIG,
    alignment: ALIGNMENT_CONFIG,
    slCommentsAdapter: {
      postComment,
      addCommentThread,
      getCommentThread,
      removeComment,
      removeCommentThread,
      reopenCommentThread,
      resolveCommentThread,
      updateComment,
      updateCommentThread,
      saveSessionActivity,
    },
    suggestionsAdapter: {
      createSuggestion,
      getSuggestionById,
      deleteSuggestionById,
      acceptSuggestionById,
      rejectSuggestionById,
      pendSuggestionById,
      saveSessionActivity,
    },
    exportPdf: exportPdfConfig(documentTitle, leaseDocument.header, leaseDocument.footer),
    list: listProperties,
    revisionHistory: {
      editorContainer: document.querySelector('#editor-container'),
      viewerContainer: document.querySelector('#revision-viewer-container'),
      viewerEditorElement: document.querySelector('#revision-viewer-editor'),
      viewerSidebarContainer: document.querySelector('#revision-viewer-sidebar'),
      cloudDocumentVersion,
      save,
      createDocumentRevision,
      documentId,
      getDocumentRevisions,
      canEdit,
      canComment,
      documentType: DocumentTypesEnum.DOCUMENT,
      showRevisionViewerCallback: (revisionConfig: any) => {
        const isDocumentSignedOrExecuted = [DocumentStatusEnum.SIGNING, DocumentStatusEnum.EXECUTED].includes(
          documentStatus as DocumentStatusEnum
        );
        const shouldShowRestoreButton =
          isCreator &&
          permissions.hasEditPermission &&
          !isDocumentSignedOrExecuted &&
          isDocumentDraft &&
          !isEditorDisabled();

        revisionConfig.toolbar = shouldShowRestoreButton
          ? ['exitToEditing', 'restoreRevision', 'changesNavigation']
          : ['exitToEditing', 'changesNavigation'];

        const editorContainer = revisionConfig.revisionHistory.editorContainer;
        const viewerContainer = revisionConfig.revisionHistory.viewerContainer;
        const viewerElement = revisionConfig.revisionHistory.viewerEditorElement;

        /*
        HideRevisionsBeforeShare:

        For counterparties reviewing the product it:
        - Hides all the revisions after any revision that contains " - DraftShare"

        For all users it:
        - Removes this string from the user-readable title
        - Locks the name input so that it cannot be changed
        - Hides the actions dropdown for changing the name
         */
        class HideRevisionsBeforeShare {
          afterInit() {
            const dayElements: NodeListOf<HTMLElement> = document.querySelectorAll(
              '.ck-revision-history-sidebar__time-period'
            );
            // once this is set we hide the entire day for the remaining days revisions
            let hideDay = false;
            let hide = false;
            dayElements.forEach((day: HTMLElement) => {
              if (hideDay) {
                day.style.display = 'none';
              }
              const elements: NodeListOf<HTMLElement> = day.querySelectorAll(
                '.ck-revision-history-sidebar__revision-wrapper'
              );
              elements.forEach((element: HTMLElement) => {
                if (hide) {
                  element.style.display = 'none';
                }
                const input: HTMLInputElement | null = element.querySelector('.ck-input');
                const actions: HTMLInputElement | null = element.querySelector(
                  '.ck-revision-history-sidebar__revision__actions'
                );
                if (actions && input && input.value.includes(draftShareString)) {
                  input.value = input.value.replace(draftShareString, '');
                  input.disabled = true;
                  if (userIsReceiver) {
                    element.style.display = 'none';
                    hide = true;
                    hideDay = true;
                  }
                }
              });
            });
          }
        }

        revisionConfig.plugins.push(HideRevisionsBeforeShare);

        return editor.create(viewerElement, revisionConfig).then((createdEditor: any) => {
          viewerContainer.style.display = 'block';
          editorContainer.style.display = 'none';

          if (revisionToolbarElem.current) {
            revisionToolbarElem.current.innerText = '';
          }
          revisionToolbarElem.current?.appendChild(createdEditor.ui.view.toolbar.element);

          const backToEditingButton = createdEditor.ui.view.toolbar.items._items[0];
          if ([DocumentStatusEnum.REVIEWING].includes(documentStatus as DocumentStatusEnum) && backToEditingButton) {
            backToEditingButton.element.onclick = () => {
              const trackChangesToolbarItem = window.editor.ui.view.toolbar.items.find(
                (item: any) => item.buttonView && item.buttonView.label === TRACK_CHANGES_BUTTON_LABEL
              );
              trackChangesToolbarItem.buttonView.actionView.isEnabled = false;
              const dropdownPanelView = trackChangesToolbarItem.template.children[1];
              const dropdownListView = dropdownPanelView.children._items[0];

              const dropdownViewCollection = dropdownListView.template.children[0];
              const dropdownListItemView = dropdownViewCollection._items[0];
              const dropdownListViewCollection = dropdownListItemView.template.children[0];
              const switchButtonView = dropdownListViewCollection._items[0];

              switchButtonView.isEnabled = false;
            };
          }

          return createdEditor;
        });
      },
      revisionActions: getRevisionActions(),
    },
  };

  const isExportPDFunavailable = [DocumentStatusEnum.EXECUTED].includes(documentStatus as DocumentStatusEnum);

  const handleIdle = () => {
    idleTimerRef?.current?.reset();
    setShowTimeoutModal(true);
  };

  const handleTimeoutModalClose = () => {
    setShowTimeoutModal(false);
    idleTimerRef?.current?.reset();
  };

  const removeSaveButtonIfSignedOrExecuted = useCallback(
    (editor: EditorType) => {
      const isDocumentSignedOrExecuted = [DocumentStatusEnum.SIGNING, DocumentStatusEnum.EXECUTED].includes(
        documentStatus as DocumentStatusEnum
      );

      if (!isDocumentSignedOrExecuted) return;

      editor.ui.view.toolbar.items
        .find((item: any) => item.buttonView && item.buttonView?.label?.startsWith('Revision'))
        .panelView.children.get(0)
        .items.remove(0);
    },
    [documentStatus]
  );

  const disableToolbarButtonForUsersWithoutEditPermission = useCallback(
    (editor: EditorType) => {
      if (permissions.hasEditPermission) return;

      editor.ui.view.toolbar.items.find(
        (item: any) => item.buttonView && item.buttonView?.label?.startsWith('Revision')
      ).isEnabled = false;
    },
    [permissions.hasEditPermission]
  );

  const disableHeaderFooterEditorForLockedDocument = useCallback(
    (editor: EditorType) => {
      if (isDocumentSigningOrExecuted || isDocumentLocked || isEditorDisabled() || !permissions.hasEditPermission) {
        const editHeaderFooterButton = editor.ui.view.toolbar?.items?._items?.find(
          (item: any) => item.label && item.label.startsWith('Header')
        );

        if (editHeaderFooterButton) {
          editHeaderFooterButton.isEnabled = false;
        }
      }
    },
    [isDocumentSigningOrExecuted, isDocumentLocked, isEditorDisabled, permissions]
  );

  // eslint-disable-next-line
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'signer',
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // @Todo get rid of updating the document data in redux on autosaving
  // Updating editor data and passing updated value causes editor to return cursor to the beginning of the doc
  // Saving initial editor data into a ref prevents re-rendering on data change
  const leaseEditorData = useRef(leaseDocument.body);

  return (
    <>
      <div className="editors-holder">
        <div className={classNames('lease-editor', { 'lease-editor__with-slide-out': showMenu })} id="editor-container">
          <div className="lease-editor__toolbar" ref={toolbarElem} />
          <div className="lease-editor__editable-container">
            <div id="Dynamic" />
            <TrackChangesTooltip />
            {!ckeInstance && <LoadingSpinner className="spinner__document-preparing" />}
            <div id="Editor" data-testid="Editor" className={classNames({ 'd-none': !ckeInstance })} ref={drop}>
              {!isDocumentCreator &&
                [DocumentStatusEnum.REVIEWING].includes(documentStatus as DocumentStatusEnum) &&
                !isApprovalRequestPending && (
                  <div className="pl-1 pr-2 m-auto">
                    <Warning text={DOCUMENT_RECEIVER_WARNING} largeCentered />
                  </div>
                )}
              {isLayoutReady && (
                <CKEditor
                  editor={editor}
                  config={config}
                  data={leaseEditorData.current}
                  disabled={isEditorDisabled()}
                  onReady={(editor: EditorType) => {
                    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                      CKEditorInspector.attach(editor);
                    }

                    isEditorDisabled() && preventEditorEnabling(editor);

                    if (isExportPDFunavailable) {
                      editor.commands.get('exportPdf').forceDisabled('forEveryone');
                    }

                    setCkeInstance(editor);
                    window.editor = editor;

                    // Toolbar
                    if (toolbarElem.current) {
                      toolbarElem.current.textContent = '';
                    }
                    toolbarElem.current?.appendChild(editor.ui.view.toolbar.element);
                    const commentsRepositoryPlugin = editor.plugins.get('CommentsRepository');

                    // Sidebar
                    const annotationsUIs = editor.plugins.get('AnnotationsUIs');
                    const customAnnotationsPlugin = editor.plugins.get('CustomAnnotationsUI');

                    annotationsUIs.register('customUI', customAnnotationsPlugin);
                    annotationsUIs.switchTo('wideSidebar');
                    annotationsUIs.deactivateAll();

                    restrictedEditingMode &&
                      editor.plugins.get('RestrictedEditingModeEditing').enableCommand('togglePrivateThread');

                    restrictedEditingMode &&
                      editor.plugins.get('RestrictedEditingModeEditing').enableCommand('strikethrough');

                    const initSuggestions = editor.plugins
                      .get('TrackChanges')
                      .getSuggestions({ skipNotAttached: true })
                      .map(({ id }: { id: string }) => id);
                    dispatch(setEditorSuggestions(initSuggestions));
                    dispatch(setRejectedSuggestion(false));

                    editor.model.markers.on(
                      'update:suggestion',
                      async (evt: any, marker: any, oldRange: any, newRange: any) => {
                        if (!newRange || !oldRange) {
                          const suggestions = editor.plugins
                            .get('TrackChanges')
                            .getSuggestions({ skipNotAttached: true })
                            .map(({ id }: { id: string }) => id);

                          dispatch(setEditorSuggestions(suggestions));
                        }
                      }
                    );

                    annotationsUIs.activate('customUI', (item: any) => {
                      if (item.type === 'comment') {
                        const threadId = item.innerView.element.dataset.threadId;

                        const thread = commentsRepositoryPlugin.getCommentThread(threadId);

                        thread.comments._items.forEach((item: any) => {
                          if (item.content === PRIVATE_COMMENT_MARKER) {
                            const commentMarker = document.querySelectorAll(`[data-comment=${item.threadId}]`);
                            commentMarker?.forEach(elem => elem.classList.remove('ck-comment-marker'));
                          }
                        });

                        return thread.comments._items.some((item: any) => item.content === PRIVATE_COMMENT_MARKER);
                      }

                      return false;
                    });

                    annotationsUIs.activate(
                      window.innerWidth < MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR ? 'narrowSidebar' : 'wideSidebar',
                      (item: any) => {
                        if (item.type === 'comment') {
                          const threadId = item.innerView.element.dataset.threadId;

                          const thread = commentsRepositoryPlugin.getCommentThread(threadId);

                          const isPrivateThread = thread.comments._items.some(
                            (item: any) => item.content === PRIVATE_COMMENT_MARKER
                          );

                          return !isPrivateThread;
                        }
                        return true;
                      }
                    );

                    const sideBarNode = document.getElementById('Sidebar');
                    sideBarNode?.appendChild(sidebarNode.current);

                    const fontFamily = editor.commands.get('fontFamily');
                    fontFamily.execute({ value: 'Arial, Helvetica, sans-serif' });

                    removeSaveButtonIfSignedOrExecuted(editor);
                    disableToolbarButtonForUsersWithoutEditPermission(editor);
                    disableHeaderFooterEditorForLockedDocument(editor);

                    const numberedListDropdown = document.getElementsByClassName('ck-splitbutton__arrow');
                    const listArrowItem = Array.from(numberedListDropdown).find((item: Element) => {
                      if (item instanceof HTMLElement) {
                        return item.dataset?.ckeTooltipText === 'Numbered List';
                      } else return false;
                    });

                    listArrowItem?.addEventListener(
                      'click',
                      () => {
                        updateListsDropdownValue();
                      },
                      { once: true }
                    );

                    const dropdownButtonsCollection = document.getElementsByClassName('ck-dropdown__button');

                    const hideBalloonToolbarOnDropdownClick = () => {
                      const visibleBalloon = document.getElementsByClassName('ck-balloon-panel_visible');
                      Array.from(visibleBalloon).forEach((elem: Element) => {
                        elem.classList.remove('ck-balloon-panel_visible');
                      });
                    };

                    Array.from(dropdownButtonsCollection).forEach((item: Element) => {
                      if (item instanceof HTMLElement) {
                        item?.addEventListener('click', hideBalloonToolbarOnDropdownClick);
                      } else return false;
                    });

                    dispatch(setLeaseEditorIsLoaded(true));

                    handleSaveRevision(editor);

                    editor.ui.update();

                    editor.focus();

                    const trackChangesToolbarItem = editor.ui.view.toolbar.items.find(
                      (item: any) => item.buttonView && item.buttonView.label === TRACK_CHANGES_BUTTON_LABEL
                    );
                    if (trackChangesToolbarItem) {
                      trackChangesToolbarItem.buttonView.actionView.element.removeAttribute('data-cke-tooltip-text');
                      trackChangesToolbarItem.buttonView.actionView.element.setAttribute('id', 'trackChangesTooltip');
                    }
                    const trackChanges = editor.commands.get('trackChanges');
                    const documentInReview = [DocumentStatusEnum.REVIEWING].includes(
                      documentStatus as DocumentStatusEnum
                    );
                    // Set the tracked changes editor config when the CKEditor button is pressed
                    trackChanges.on('change', (_event: any, _data: string, newValue: boolean, _oldValue: boolean) => {
                      !documentInReview &&
                        documentId &&
                        updateEditorConfiguration({
                          variables: {
                            documentId,
                            configuration: {
                              trackChanges: newValue,
                            },
                          },
                        });
                    });

                    if (trackChangesMode && !documentInReview) {
                      editor.execute('trackChanges');
                    }

                    editor.on('change', () => {
                      if (!editor._readOnlyLocks.size) {
                        dispatch(setDocumentCompareModeEnabled(false));
                      }
                      disableToolbarButtonForUsersWithoutEditPermission(editor);
                      disableHeaderFooterEditorForLockedDocument(editor);
                      const backToEditingButton = editor.ui.view.toolbar.items._items[0];
                      if (documentInReview && backToEditingButton) {
                        const trackChangesToolbarItem = window.editor.ui.view.toolbar.items.find(
                          (item: any) => item.buttonView && item.buttonView.label === TRACK_CHANGES_BUTTON_LABEL
                        );
                        trackChangesToolbarItem.buttonView.actionView.isEnabled = false;
                        const dropdownPanelView = trackChangesToolbarItem.template.children[1];
                        const dropdownListView = dropdownPanelView.children._items[0];

                        const dropdownViewCollection = dropdownListView.template.children[0];
                        const dropdownListItemView = dropdownViewCollection._items[0];
                        const dropdownListViewCollection = dropdownListItemView.template.children[0];
                        const switchButtonView = dropdownListViewCollection._items[0];
                        switchButtonView.isEnabled = false;
                      }
                    });

                    editor.commands.get('importWord').on('dataInsert', (event: any, data: any) => {
                      handleLeftMarginRemovalFromFigure(data);
                      handleListStylingOnImportWord(data);
                      handleLineHeightRemovalOnImportWord(data);
                      handleLetterSpacingRemovalOnImportWord(data);
                      handleMarginStylesRemovalOnImportWord(data);
                      handleNegativeMarginRemovalOnImportWord(data);
                      handleInlineNegativeTextIndent(data);
                    });

                    editor.on('destroy', () => {
                      const editorWrapper = document.querySelector('.ck-body-wrapper');
                      const editorWrapperParent = editorWrapper?.parentNode;
                      editorWrapperParent?.removeChild(editorWrapper as Element);
                    });
                  }}
                  onError={(error: any) => {
                    console.error('ERROR: ', error);
                  }}
                />
              )}
            </div>
            <div id="Sidebar" className="lease-editor__sidebar ck-content wide sidebar-container" />
          </div>
        </div>
        <div
          id="revision-viewer-container"
          className={classNames('revision-viewer__container', {
            'revision-viewer__container_counterparty': !isCreator,
            'revision-viewer__container_no-editing-name': isRenamingDisabled,
          })}
        >
          <div className="revision-viewer__toolbar" ref={revisionToolbarElem} />
          <div className="editor-container">
            <div id="revision-viewer-editor" className="revision-viewer__editor" />
            <div className="sidebar-container revision-viewer__sidebar" id="revision-viewer-sidebar" />
          </div>
        </div>
      </div>
      <IdleTimer
        key="idleTimer"
        ref={idleTimerRef as any}
        startOnMount
        element={document}
        onIdle={handleIdle}
        timeout={IDLE_TIMER_TIMEOUT_MILLISECONDS}
      />
      {showTimeoutModal && <TimeoutModal onClose={handleTimeoutModalClose} onTimeOut={navigateToDealDetails} />}
    </>
  );
};
