import React from 'react';
import cls from 'classnames';
import { Button } from 'react-bootstrap';
import { ReactComponent as PropdocsIcon } from '../../shared/icons/propdocs-icon.svg';
import Avatar from '../avatar';
import { LeaseDocumentWizardHeaderLastSaved } from '../lease-wizard/lease-document-wizard-header/lease-document-wizard-header-lastsaved/LeaseDocumentWizardHeaderLastSaved';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './ActivitiesCard.module.scss';
import { ActivityDateChat } from './ActivityDateChat';

export type ActivityPropTypes = {
  id: string;
  title: {
    id: string | number;
    defaultMessage: string;
  };
  time: string | null;
  conductor: string | undefined;
};

export enum DealActivityVerbs {
  SIGNER_SIGNED = 'document-signer-signed',
  EXECUTED = 'document-executed',
  FULL_APPROVAL = 'document-approval-request-approved',
  EMAIL_REPLY_RECEIVED = 'document-feedback-created',
}

export type ActivityPropsType = {
  id: string;
  description?: string;
  time?: string;
  actor_data: {
    full_name: string;
    email: string;
  };
  object_data: {
    object_id: string;
  };
  verb: string;
};

const AVATAR_FONT_COLOR = '#737373';
const AVATAR_FONT_SIZE = '13px';
const AVATAR_FILL_COLOR = '#ffff';

export const RenderDealActivityData = (
  activityProps: ActivityPropsType,
  isSidebar: boolean,
  setSelectedActivityId: (id: string | null) => void
) => {
  const isDocumentApprovedActivity = activityProps.verb === DealActivityVerbs.FULL_APPROVAL;
  const isDocumentSignedBySignerActivity = activityProps.verb === DealActivityVerbs.SIGNER_SIGNED;
  const isDocumentExecutedActivity = activityProps.verb === DealActivityVerbs.EXECUTED;
  const isEmailReplyReceivedActivity = activityProps.verb === DealActivityVerbs.EMAIL_REPLY_RECEIVED;

  return (
    <div
      key={activityProps.id}
      id={`button--${activityProps.id}`}
      className={cls(styles.activityCardItem, { [styles.activityCardSidebarItem]: isSidebar })}
    >
      <div className={styles.activityTitle}>
        {isDocumentApprovedActivity ||
        isDocumentSignedBySignerActivity ||
        isDocumentExecutedActivity ||
        isEmailReplyReceivedActivity ? (
          <span className={styles.propdocsAvatar}>
            <PropdocsIcon height={28} />
          </span>
        ) : (
          <span className={styles.userAvatar}>
            <Avatar
              email={activityProps.actor_data?.email}
              fullName={activityProps.actor_data?.full_name}
              title={activityProps.actor_data?.full_name}
              size={24}
              fontSize={AVATAR_FONT_SIZE}
              fontColor={AVATAR_FONT_COLOR}
              backgroundColor={AVATAR_FILL_COLOR}
            />
          </span>
        )}

        <span className={styles.activityActorName}>
          {isDocumentExecutedActivity || isDocumentApprovedActivity
            ? ''
            : isDocumentSignedBySignerActivity || isEmailReplyReceivedActivity
            ? activityProps.actor_data?.email
            : activityProps.actor_data?.full_name}
        </span>

        <span className={styles.activityDate}>
          <ActivityDateChat datetime={`${activityProps.time}Z`} intervalInSecond={60} />
        </span>
      </div>

      <div className={styles.activityDescription}>
        <span>{activityProps.description}</span>
      </div>

      {isEmailReplyReceivedActivity && (
        <Button
          variant="outline-primary"
          className={styles.viewEmailButton}
          onClick={() => {
            setSelectedActivityId(activityProps.object_data?.object_id);
          }}
        >
          <TitleFormattedMessage id="cta.view-email-reply" defaultMessage={'View Email Reply'} />
        </Button>
      )}
    </div>
  );
};
