import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import c from 'classnames';
import { useIntl } from 'react-intl';

import useOnClickOff from '../../utils/use-on-click-off';
import { toggleSubNavCollapsed } from '../../store/navigation/navigation.actions';
import { selectSubNavCollapsed } from '../../store/navigation/navigation.selectors';

import styles from './styles.module.scss';

const SubNav = props => {
  const { formatMessage } = useIntl();
  const subNavCollapsed = useSelector(selectSubNavCollapsed);
  const dispatch = useDispatch();
  const subNavRef = useRef(null);
  const buttonText = formatMessage({ id: 'nav.toggleSubNav', defaultMessage: 'Toggle page navigation' });

  useOnClickOff(subNavRef, () =>
    props.shouldSubNavBeTogglable ? subNavCollapsed || dispatch(toggleSubNavCollapsed(true)) : {}
  );

  function toggleCollapse() {
    return dispatch(toggleSubNavCollapsed(!subNavCollapsed));
  }

  /**
   * Clone children elements, attach toggleCollapse function and return to render
   *
   * @param {ReactElement} child
   */
  function cloneChildrenWithState(child) {
    return React.cloneElement(child, {
      toggleCollapse,
    });
  }

  return (
    <div className={styles.subNavWrapper} ref={subNavRef}>
      <div
        id="main-sub-nav"
        className={c(
          styles.subNav,
          { [styles.collapsed]: subNavCollapsed },
          { [styles.hiddenSubNav]: !props.shouldSubNavBeTogglable }
        )}
        aria-expanded={!subNavCollapsed}
      >
        {props.shouldSubNavBeTogglable && (
          <button
            className={c(styles.subNavToggle, 'mt-5')}
            type="button"
            aria-expanded={!subNavCollapsed}
            aria-controls="main-sub-nav"
            aria-label={buttonText}
            title={buttonText}
            onClick={toggleCollapse}
          />
        )}
        <div className={styles.subNavMask}>
          <div className={styles.subNavContent}>{React.Children.map(props.children, cloneChildrenWithState)}</div>
        </div>
      </div>
    </div>
  );
};

export default SubNav;
