import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch as reduxUseDispatch, useSelector } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../../notifications';
import { SendReminderConfirmationModal } from './SendReminderConfirmationModal';
import {
  selectDocumentSigners,
  selectLeaseDocumentId,
} from '../../../../store/lease-document/selectors/lease-document.selectors';
import { remindCurrentSigner } from '../../../../utils/api/remind-current-signer';
import { DocumentStatusEnum } from '../../../../shared/constants/leaseDocument';
import {
  dispatchEraseLeaseDocumentValuesFromStore,
  dispatchLeaseDocumentResponseToStore,
} from '../../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';

import { paths } from '../../../../routing';
import { getDocumentSigners } from '../../../../api/queries_v2';

const UNSIGN_DOCUMENT_ERROR_MESSAGE = "Unable to remove the tenant's signature.";

export const SendReminderConfirmationModalContainer = ({
  useDispatch = reduxUseDispatch,
  show,
  onHide,
  dealId,
  teamId,
  documentId,
}: {
  useDispatch?: any;
  show: boolean;
  onHide: any;
  teamId: string | null;
  dealId: string;
  documentId?: string;
}) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const optionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const currentDocumentId = useSelector(selectLeaseDocumentId);

  const { data, loading: isSignersLoading, error } = useQuery(getDocumentSigners, {
    variables: { documentId: documentId! },
    skip: !documentId,
  });

  const documentSigners = useSelector(selectDocumentSigners);

  const signers = documentId ? data?.getDocumentById.signers : documentSigners;

  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const nextSigner = useMemo(() => {
    if (!signers) {
      return null;
    }
    const nextSigners = [...signers].filter(signer => !signer.signedAt);
    nextSigners.sort((a, b) => a.order - b.order);

    if (nextSigners.length === 0) {
      return null;
    }

    return nextSigners[0];
  }, [signers]);

  const handleError = useCallback(() => {
    notifyError({
      message: UNSIGN_DOCUMENT_ERROR_MESSAGE,
    });
  }, [notifyError]);

  const handleConfirm = () => {
    const selectedDocumentId = documentId ? documentId : currentDocumentId;
    const additionalMessage = optionalMessageInputRef.current?.value;
    setIsSending(true);
    remindCurrentSigner({ documentId: selectedDocumentId!, additionalMessage })(apolloClient)
      .then(() => {
        notifySuccess({
          message: {
            id: 'phrase.remind-signing.success',
            defaultMessage: 'Reminder email sent to {email}',
            values: { email: nextSigner?.email },
          },
        });
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setIsSending(false);
        onHide();
      });
  };

  return (
    <SendReminderConfirmationModal
      handleConfirm={handleConfirm}
      show={show}
      onHide={onHide}
      isSending={isSending}
      nextSigner={nextSigner}
      optionalMessageInputRef={optionalMessageInputRef}
    />
  );
};
