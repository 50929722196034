export enum DocumentStatusEnum {
  DRAFT = 'draft',
  REVIEWING = 'reviewing',
  SIGNING = 'signing',
  EXECUTED = 'executed',
  TERMINATED = 'terminated',
  TEMPLATE = 'template',
  PREPARING_TO_SIGN = 'preparing_to_sign',
  UPLOADED = 'uploaded',
  SHARED = 'shared',
  APPROVAL_PENDING = 'Approval Pending',
}

export enum DocumentPossessionIndicator {
  INTERNAL = '(Internal)',
  EXTERNAL = '(External)',
}
