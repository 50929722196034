import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import LoginForm from '../../forms/loginForm';
import PublicLayout from '../../components/public-layout';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/loading-spinner';
import { useAuthTokenRedirect } from './helpers/authHelpers';
import ExpiredAuthTokenView from './components/ExpiredAuthTokenView';

const Login = () => {
  const {
    token,
    dealId,
    teamId,
    documentId
  } = useParams();
  const { formatMessage } = useIntl();

  const { isLoadingToken, expiredToken} = useAuthTokenRedirect({ token, teamId, dealId, documentId });

  if (isLoadingToken) {
    return <LoadingSpinner />;
  }

  if (expiredToken && token) {
    return <ExpiredAuthTokenView token={token} />
  }

  return (
    <PublicLayout>
      <Helmet title={formatMessage({ id: 'title.login', defaultMessage: 'Sign in' })} />
      <h1>
        <FormattedMessage id="title.login" defaultMessage="Sign in" />
      </h1>
      <LoginForm token={token} />
    </PublicLayout>
  );
};

export default Login;
