import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PlaceholderItem from './placeholderItem';


interface PlaceholderSidebarSidebarProps {
  placeholders: {
    uuid: string;   id: string;
    title?: string | null | undefined;
    isRequired: boolean;  status: string;
  }[];
  activeUuid: string | null;
}

const PlaceholderSidebar = ({ placeholders, activeUuid }: PlaceholderSidebarSidebarProps) => {
  const [activePlaceholderUuid, setActivePlaceholderUuid] = useState(activeUuid);

  const editorPlaceholders = () => {
    const foundPlaceholders: any[] = [];

    const root = window.editor.model.document.getRoot();
  
    // Function to traverse the model tree and collect placeholders
    const traverse = (node: any) => {
      if (node.is('element', 'placeholder')) {
        foundPlaceholders.push(node);
      }
  
      if (node.is('element')) {
        for (const child of node.getChildren()) {
          traverse(child);
        }
      }
    };
  
    traverse(root);
    
    return foundPlaceholders;
  }
  
  const handlePlaceholderClick = (placeholderId: string) => {

    setActivePlaceholderUuid(placeholderId);

    const editor = window.editor;
    const model = editor.model;
    const doc = model.document;
  
    // Step 1: Find the placeholder model element

    const placeholderElement = editorPlaceholders().find((placeholder: any) => placeholder.getAttribute('placeholderid') === placeholderId);

    if (placeholderElement) {
      // Step 2: Set selection to the placeholder element
      model.change((writer: any) => {
        const range = writer.createRangeOn(placeholderElement);
        writer.setSelection(range);
      });

      const view = window.editor.editing.view;
      const mapper = window.editor.editing.mapper;

      // Get the view element corresponding to the model element
      const viewElement = mapper.toViewElement(placeholderElement);
      const domConverter = view.domConverter;
      const domElement = domConverter.mapViewToDom(viewElement);

      view.scrollToTheSelection();

      domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

      domElement.click();

    } else {
      console.error('Placeholder element not found');
    }
  };

  const renderPlaceholders = () => {
    const sortedPlaceholders = [...placeholders];
    sortedPlaceholders.sort((a, b) => {
      const titleA = a.title?.toLowerCase() || '';
      const titleB = b.title?.toLowerCase() || '';
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
    return sortedPlaceholders.map((placeholder) => {
     return (
      <PlaceholderItem
          key={placeholder.id}
          id={placeholder.id}
          uuid={placeholder.uuid}
          title={placeholder.title}
          isActive={placeholder.uuid === activePlaceholderUuid}
          isRequired={placeholder.isRequired}
          onClick={handlePlaceholderClick}
        />
     );
    });
  }

  useEffect(() => {
    setActivePlaceholderUuid(activeUuid);
  }, [activeUuid]);

  return (
    <div className={classNames(styles.wrapper)}>
      <h2>Placeholders</h2>
      {(placeholders.length != 0) && renderPlaceholders()}
      {(placeholders.length == 0) && (
        <div className={styles.noPlaceholders}>
          No placeholders found.
        </div>
      )}
    </div>
  );
};

export default PlaceholderSidebar;
