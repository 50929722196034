import { DocumentPossessionIndicator, DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import { getReadableDocumentStatus } from '../../lease-wizard/utils/lease-wizard.utils';
import { TemplateType } from '../../../consts/forms-types';
import { DocumentTemplateType } from '../../../shared/templateType';

export const getDocumentStatusIndicator = (
  documentStatus: DocumentStatusEnum | string | DocumentTemplateType,
  hasCurrentTeamPossession: boolean
) => {
  const possessionIndicator = hasCurrentTeamPossession
    ? DocumentPossessionIndicator.INTERNAL
    : DocumentPossessionIndicator.EXTERNAL;

  const isDocumentStatusReviewing = documentStatus === DocumentStatusEnum.REVIEWING;
  const templateType = documentStatus === TemplateType.CLAUSE ? TemplateType.CLAUSE : documentStatus;
  const status = getReadableDocumentStatus(templateType);

  return isDocumentStatusReviewing ? `${status} ${possessionIndicator}` : `${status}`;
};
