import { GlobalModalActionsEnum } from '../../components/document-table/DocumentTableConstants';

type SetModalActionType = 'setActiveModal';
export type SetActiveModalAction = {
  type: SetModalActionType;
  activeModal: GlobalModalActionsEnum | null;
};

export const setActiveModal = (activeModal: GlobalModalActionsEnum | null): SetActiveModalAction => ({
  type: 'setActiveModal',
  activeModal,
});

type ShowSendDocumentModalActionType = 'showSendDocumentModal';
export type ShowSendDocumentModalAction = {
  type: ShowSendDocumentModalActionType;
  isOpen: boolean;
};

export const setShowSendDocumentModal = (isOpen: boolean): ShowSendDocumentModalAction => ({
  type: 'showSendDocumentModal',
  isOpen,
});

type ShowSendDocumentViaEmailModalActionType = 'showSendDocumentViaEmailModal';
export type ShowSendDocumentViaEmailModalAction = {
  type: ShowSendDocumentViaEmailModalActionType;
  isOpen: boolean;
};

export const setShowSendDocumentViaEmailModal = (isOpen: boolean): ShowSendDocumentViaEmailModalAction => ({
  type: 'showSendDocumentViaEmailModal',
  isOpen,
});

type ShowRequiredPlaceholdersModalActionType = 'showRequiredPlaceholdersModal';
export type ShowRequiredPlaceholdersModalAction = {
  type: ShowRequiredPlaceholdersModalActionType;
  isOpen: boolean;
};

export const setShowRequiredPlaceholdersModal = (isOpen: boolean): ShowRequiredPlaceholdersModalAction => ({
  type: 'showRequiredPlaceholdersModal',
  isOpen,
});


type ShowRequestInternalReviewModalActionType = 'showRequestInternalReviewDocumentModal';
export type ShowRequestInternalReviewModalAction = {
  type: ShowRequestInternalReviewModalActionType;
  isOpen: boolean;
};

export const setShowRequestInternalReviewModal = (isOpen: boolean): ShowRequestInternalReviewModalAction => ({
  type: 'showRequestInternalReviewDocumentModal',
  isOpen,
});

type ShowGrantAccessModalActionType = 'showGrantAccessModal';
export type ShowGrantAccessModalAction = {
  type: ShowGrantAccessModalActionType;
  isOpen: boolean;
};

export const setShowGrantAccessModal = (isOpen: boolean): ShowGrantAccessModalAction => ({
  type: 'showGrantAccessModal',
  isOpen,
});

type ShowHeaderFooterEditorModalActionType = 'showHeaderFooterEditorModal';
export type ShowHeaderFooterEditorModalAction = {
  type: ShowHeaderFooterEditorModalActionType;
  isOpen: boolean;
};

export const setShowHeaderFooterEditorModal = (isOpen: boolean): ShowHeaderFooterEditorModalAction => ({
  type: 'showHeaderFooterEditorModal',
  isOpen,
});

type ShowSendForSigningPreparationModalActionType = 'showSendForSigningPreparationModal';
export type ShowSendForSigningPreparationModalAction = {
  type: ShowSendForSigningPreparationModalActionType;
  isOpen: boolean;
};

export const setShowSendForSigningPreparationModal = (isOpen: boolean): ShowSendForSigningPreparationModalAction => ({
  type: 'showSendForSigningPreparationModal',
  isOpen,
});

type ClosePendingWarningReviewModalActionType = 'closePendingReviewWarningModal';
export type ClosePendingWarningReviewModalAction = {
  type: ClosePendingWarningReviewModalActionType;
  isClosed: boolean;
};

export const setClosePendingWarningReviewModal = (isClosed: boolean): ClosePendingWarningReviewModalAction => ({
  type: 'closePendingReviewWarningModal',
  isClosed,
});

export type CloseDocumentModals = 'closeDocumentModals';
export type CloseDocumentModalsAction = {
  type: CloseDocumentModals;
};

export const closeDocumentModals = (): CloseDocumentModalsAction => ({
  type: 'closeDocumentModals',
});

export type ModalAction =
  | SetActiveModalAction
  | ShowSendDocumentModalAction
  | ShowGrantAccessModalAction
  | ShowRequestInternalReviewModalAction
  | ShowSendDocumentViaEmailModalAction
  | ShowHeaderFooterEditorModalAction
  | CloseDocumentModalsAction
  | ShowRequiredPlaceholdersModalAction
  | ShowSendForSigningPreparationModalAction
  | ClosePendingWarningReviewModalAction;
