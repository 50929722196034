import { ApolloClient, gql } from '@apollo/client';
import { getDealById } from '../../../api/queries';
import { getDocumentsByDeal } from '../../../api/queries_v2';

type ResponsePayloadType = {
  success: boolean;
};

export function cancelReceiverTeamDealInvite({
  dealId,
  additionalMessage,
}: {
  dealId: string | number;
  additionalMessage?: string;
}) {
  return function(client: ApolloClient<object>): Promise<ResponsePayloadType> {
    const variables = {
      dealId,
      additionalMessage,
    };

    const mutation = gql`
      mutation cancelReceiverTeamDealInvite($dealId: ID!, $additionalMessage: String) {
        cancelReceiverTeamDealInvite(id: $dealId, additionalMessage: $additionalMessage) {
          receiverTeam {
            id
          }
        }
      }
    `;

    const refetchQueries = [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
      {
        query: getDocumentsByDeal,
        variables: {
          dealId,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.cancelReceiverTeamDealInvite.receiverTeam);
  };
}
