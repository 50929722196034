export const SuccessMessages = {
  fileUpload: {
    id: 'lease-document-wizard.file-field.message.success',
    defaultMessage: 'File Successfully Uploaded',
  },
  registration: {
    id: 'phrase.register.success',
    defaultMessage: 'Registration successful!',
  },
  registrationCheckEmail: {
    id: 'phrase.register.success-check-email',
    defaultMessage: 'Registration successful!',
  },
  verification: {
    id: 'phrase.verification.success',
    defaultMessage: 'Your email has been verified',
  },
  archiveDeal: {
    id: 'phrase.deal.archived.success',
    defaultMessage: 'Deal successfully archived',
  },
  unarchiveDeal: {
    id: 'phrase.deal.unarchived.success',
    defaultMessage: 'Deal successfully unarchived',
  },
  archiveTemplate: {
    id: 'phrase.template.archived.success',
    defaultMessage: 'Template successfully archived',
  },
  archiveClause: {
    id: 'phrase.clause.archived.success',
    defaultMessage: 'Clause successfully archived',
  },
  unarchiveTemplate: {
    id: 'phrase.template.unarchived.success',
    defaultMessage: 'Template successfully unarchived',
  },
  unarchiveClause: {
    id: 'phrase.clause.unarchived.success',
    defaultMessage: 'Clause successfully unarchived',
  },
};
