import { createSelector } from 'reselect';
import { RootReducer } from '../store.types';
import { ModalsStateType } from './modals.reducer';

export const selectModalsRoot = (state: RootReducer): ModalsStateType => state.modals;

export const selectActiveModal = createSelector(selectModalsRoot, state => state.activeModal);

export const selectIsSendDocumentModalOpen = createSelector(
  selectModalsRoot,
  state => state.showSendDocumentModal.isOpen
);

export const selectIsInternalApprovalModalOpen = createSelector(
  selectModalsRoot,
  state => state.showRequestInternalReviewDocumentModal.isOpen
);

export const selectIsGrantAccessModalOpen = createSelector(
  selectModalsRoot,
  state => state.showGrantAccessModal.isOpen
);

export const selectIsSendViaEmailModalOpen = createSelector(
  selectModalsRoot,
  state => state.showSendViaEmailModal.isOpen
);

export const selectIsRequiredPlaceholdersModalOpen = createSelector(
  selectModalsRoot,
  state => state.showRequiredPlaceholdersModal.isOpen
);

export const selectIsHeaderFooterEditorModalOpen = createSelector(
  selectModalsRoot,
  state => state.showHeaderFooterEditorModal.isOpen
);

export const selectIsSendForSigningPreparationModalOpen = createSelector(
  selectModalsRoot,
  state => state.showSendForSigningPreparationModal.isOpen
);

export const selectIsPendingReviewWarningModalClosed = createSelector(
  selectModalsRoot,
  state => state.closePendingReviewWarningModal.isClosed
);