import { useLocation } from 'react-router-dom';

import * as navigationPaths from './paths';

// Collect all paths into a flat array and turn them into something useful
function flattenPaths(item: any): any {
  if (item instanceof RegExp) return [];
  switch (typeof item) {
    case 'object':
      let paths = Object.values(item).map(flattenPaths);
      return [].concat(...paths);
    case 'string':
      return [item];
    default:
      return [];
  }
}
const allRoutes: { path: string; placeholders: string[]; regex: string }[] = flattenPaths(navigationPaths).map(
  (path: string) => {
    const regex = new RegExp(`^${path.replace(/:[a-z]+/gi, '([^/]+)')}$`);
    const placeholders = (path.match(/:[a-z]+/gi) ?? []).map(placeholder => placeholder.substring(1));
    return { regex, placeholders, path };
  }
);

export default function useRoute() {
  const currentPath = useLocation();
  for (const { regex, placeholders, path } of allRoutes) {
    const match = currentPath.pathname.match(regex);
    if (match) {
      return {
        path,
        placeholders: placeholders.reduce(
          (acc, placeholder, index) => ({
            ...acc,
            [placeholder]: match[index + 1],
          }),
          {}
        ),
      };
    }
  }
  return null;
}
