import React, { useCallback, useRef, useState } from 'react';
import SendForSigning from './sendForSigning';
import SendForSigningModal from './sendForSigningModal';
import { useSelector } from 'react-redux';
import { selectCurrentDeal } from '../../../../../../store/deals/deals.selectors';
import { useNavigate } from 'react-router-dom';
import { SendForSigningSuccessMessage } from '../../../../../../consts/forms-constants';
import { useCheckIfDocumentIsReadyForSigning } from '../../../../../../shared/hooks/useCheckIfDocumentIsReadyForSigning';
import { useApolloClient, useMutation } from '@apollo/client';
import { DefaultNotificationMessages } from '../../../../DefaultNotificationMessages';
import { useNotifications } from '../../../../../../notifications';
import { mutations } from '../../../../../../api';
import { getDealsForTeam } from '../../../../../../api/queries';
import { paths } from '../../../../../../routing';
import { useDocumentRole } from '../../../../../../shared/hooks/useDocumentRole/useDocumentRole';
import {
  selectDocumentEditorSuggestions,
  selectLeaseDocumentReceiverTeam,
} from '../../../../../../store/lease-document/selectors/lease-document.selectors';
import { selectShouldReturnForReceiverReview } from '../../../../../../store/lease-document/selectors/select-should-return-for-receiver-review.selector';
import Button from 'react-bootstrap/Button';

interface Props {
  teamId: string;
  documentId: string;
  dealId: string;
  asLink?: boolean;
}

const SendForSigningContainer = ({ documentId, teamId, dealId, asLink = false }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const optionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const currentDeal = useSelector(selectCurrentDeal);
  const isDealShared = !!currentDeal?.receiverEmail;
  const { isDocumentCreator } = useDocumentRole(documentId);
  const receiverTeamId = useSelector(selectLeaseDocumentReceiverTeam);
  const shouldReturnForReceiverReview = useSelector(selectShouldReturnForReceiverReview);
  const { isDocumentReadyLoading, checkIfDocumentIsReadyForSigning } = useCheckIfDocumentIsReadyForSigning(
    documentId,
    useApolloClient
  );
  const editorSuggestions = useSelector(selectDocumentEditorSuggestions);
  const isSuggestionsArrayEmpty = !editorSuggestions.length;
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();
  const createNotReadyNotification = useCallback(() => {
    return notifyError({
      message: {
        id: 'lease-document-wizard-header.action.sign-now',
        defaultMessage: DefaultNotificationMessages.NOT_READY_TO_BE_SIGNED,
      },
    });
  }, [notifyError]);

  const [prepareToSignDocument] = useMutation(mutations.prepareToSignDocument, {
    refetchQueries: [
      {
        query: getDealsForTeam,
        variables: {
          teamId,
        },
      },
    ],
  });

  const dealUrl = paths.deals.detail
    .replace(':teamId', `${isDocumentCreator ? teamId : receiverTeamId}`)
    .replace(':dealId', dealId);

  const handleSend = async () => {
    const { isDocumentReadyForSigning } = await checkIfDocumentIsReadyForSigning();

    if (!isDocumentReadyForSigning) {
      return createNotReadyNotification();
    }
    const additionalMessage = optionalMessageInputRef.current?.value;
    setIsLoading(true);
    prepareToSignDocument({ variables: { documentId, additionalMessage } })
      .then(() => {
        navigate(dealUrl);
        notifySuccess({
          message: {
            id: 'lease-document-wizard-header.action.sign-now',
            defaultMessage: SendForSigningSuccessMessage.SEND_FOR_SIGNING,
          },
        });
        setIsLoading(false);
      })
      .catch(error => {
        notifyError({ id: 'deal.send-deal.error', message: error });
      });
  };

  return (
    <>
      {asLink
        ? <Button className="p-0" variant="link" onClick={() => setShowModal(true)}>click here</Button>
        : <SendForSigning
            onClick={() => setShowModal(true)}
            disabled={
              isDocumentReadyLoading || !isSuggestionsArrayEmpty || (isDocumentCreator && shouldReturnForReceiverReview)
            }
            showUnresolvedTooltip={!isSuggestionsArrayEmpty}
        />
      }
      <SendForSigningModal
        show={showModal}
        onHide={() => setShowModal(false)}
        isDealShared={isDealShared}
        isLoading={isLoading}
        handler={handleSend}
        optionalMessageInputRef={optionalMessageInputRef}
        teamId={teamId}
      />
    </>
  );
};

export default SendForSigningContainer;
