import PrepareToSigning from './prepareToSigning';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch as reduxUseDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  selectDocumentGeneratedAssetUrl,
  selectDocumentImportedAssetContentType,
  selectDocumentSource,
  selectLeaseDocument,
  selectLeaseDocumentLastUpdated,
  selectLeaseDocumentStatus,
  selectLeaseDocumentTitle,
} from '../../store/lease-document/selectors/lease-document.selectors';
import { useLeaseDocumentWizardErrorNotifier } from '../lease-document-wizard/shared/hooks/use-lease-document-wizard-error-notifier/use-lease-document-wizard-error-notifier.hook';
import { dispatchLeaseDocumentResponseToStore } from '../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { setDeal } from '../../store/deals/deals.actions';
import { useLeaseDocumentTeamPermissions } from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { useDocumentRole } from '../../shared/hooks/useDocumentRole/useDocumentRole';
import { fillPlaceholders, paths } from '../../routing';
import {
  resetLeaseDocumentState,
  setLeaseDocumentLastUpdated,
} from '../../store/lease-document/actions/lease-document.actions';
import LoadingSpinner from '../../components/loading-spinner';
import { useIntl as reactIntlUseIntl } from 'react-intl';
import { ApolloClient, useApolloClient as apolloUseApolloClient, useMutation, useQuery } from '@apollo/client';
import { getDocumentById } from '../../utils/api/get-document-by-id';
import { DocumentPayload } from '../../utils/api/lease-document-interface.types';
import { mutations, queriesV2 } from '../../api';
import { LeaseEditorErrors } from '../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { useAuth } from '../../auth';
import { useNotifications } from '../../notifications';
import { DocumentStatusEnum } from '../../shared/constants/leaseDocument';
import { graphql } from '../../gql';

type PrepareToSigningContainerProps = {
  useDispatch?: (...args: any[]) => any;
  useApolloClient?: (...args: any[]) => any;
  useIntl?: (...args: any[]) => any;
  getDocument?: (offerId: string | number) => (client: ApolloClient<object>) => Promise<DocumentPayload>;
};

export const GET_XFDF_ANNOTATIONS_URL = graphql(/* GraphQL */ `
  query GetXfdfAnnotations($documentId: ID!) {
    getDocumentById(id: $documentId) {
      id
      signaturesXfdfUrl
      commentsXfdfUrl
      inputXfdfUrl
    }
  }
`);

const PrepareToSigningContainer = ({
  useDispatch = reduxUseDispatch,
  useIntl = reactIntlUseIntl,
  useApolloClient = apolloUseApolloClient,
  getDocument = getDocumentById,
}: PrepareToSigningContainerProps) => {
  const apolloClient = useApolloClient();
  const reactIntl = useIntl();
  const navigate = useNavigate();
  const { documentId, teamId, dealId } = useParams();
  const dispatch = useDispatch();
  const document = useSelector(selectLeaseDocument);
  const documentTitle = useSelector(selectLeaseDocumentTitle);
  const lastUpdated = useSelector(selectLeaseDocumentLastUpdated);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const documentContentType = useSelector(selectDocumentImportedAssetContentType);
  const documentSource = useSelector(selectDocumentSource);
  const [, { hide: hideNotifications }] = useNotifications();
  const notifyError = useLeaseDocumentWizardErrorNotifier();
  const [{ authLoading, user }] = useAuth();
  const { isDocumentCreator, hasCurrentTeamPossession, isDocumentRoleLoading } = useDocumentRole(documentId!);
  const generatedDocumentPdf = useSelector(selectDocumentGeneratedAssetUrl);
  const isDocumentExecuted = [DocumentStatusEnum.EXECUTED].includes(documentStatus as DocumentStatusEnum);

  const [saveAnnotationsXfDfLayer] = useMutation(mutations.saveAnnotationsXfDfLayer, {
    onCompleted: ({ saveSignaturesLayerForDocument }) => {
      dispatch(setLeaseDocumentLastUpdated(saveSignaturesLayerForDocument!.lastUpdated));
    },
  });
  const { data: annotationsData, loading: annotationsDataLoading } = useQuery(GET_XFDF_ANNOTATIONS_URL, {
    variables: {
      documentId: documentId!,
    },
  });

  const isDataLoading = isDocumentRoleLoading || annotationsDataLoading || authLoading;

  const handleError = useCallback(() => {
    notifyError(
      reactIntl.formatMessage({
        id: 'lease-document-wizard.fetch-error',
        defaultMessage: 'Unable to fetch the Document',
      })
    );
  }, [notifyError, reactIntl]);

  useEffect(() => {
    if (
      !isDataLoading &&
      documentStatus &&
      teamId &&
      dealId &&
      documentId &&
      documentStatus !== DocumentStatusEnum.PREPARING_TO_SIGN
    ) {
      const urlToNavigate = fillPlaceholders(paths.Documents.document, { teamId, dealId, documentId });

      navigate(urlToNavigate);
    }
  }, [dealId, documentStatus, isDataLoading, navigate, documentId, teamId]);

  useEffect(() => {
    return () => {
      hideNotifications();
    };
  }, [hideNotifications]);

  /**
   * Load document
   * @TODO: Refactor so that this isn't just a copy-paste-modify of the same code
   * in LeaseDocumentWizardOfferPage
   */
  const handleOfferLoadError = useCallback(
    errorCode => {
      if (errorCode === 'unauthorized-query-access') {
        navigate('/');
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (document !== null) {
      return;
    }

    if (!documentId) {
      return;
    }

    getDocument(documentId)(apolloClient)
      .then(response => {
        dispatch(setDeal(response.deal));

        // @ts-ignore
        dispatchLeaseDocumentResponseToStore(dispatch, response);
      })
      .catch(err => {
        if (typeof handleOfferLoadError === 'function') {
          handleOfferLoadError(err);
        }

        handleError();
      });
  }, [apolloClient, dispatch, document, getDocument, handleError, documentId, handleOfferLoadError]);

  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });
  const onClosePath = paths.deals.detail;
  const onCloseParams: { [key: string]: string } | {} = document && teamId ? { teamId, dealId } : {};
  const onCloseUrl = document && teamId ? fillPlaceholders(onClosePath, onCloseParams) : '';

  const handleLeaseWizardClose = useCallback(() => {
    navigate(onCloseUrl);
    dispatch(resetLeaseDocumentState());
  }, [dispatch, navigate, onCloseUrl]);

  const isLockedRef = useRef<boolean>(false);

  const [isDocumentLocked, setIsDocumentLocked] = useState(false);
  const [lockDocument, { loading: isLockLeaseDocumentLoading }] = useMutation(mutations.lockDocument);

  const [unlockDocument] = useMutation(mutations.unlockDocument);
  useEffect(() => {
    lockDocument({ variables: { documentId: documentId!, userId: user.id } })
      .then(() => {
        isLockedRef.current = true;
      })
      .catch((err: any) => {
        if (hasCurrentTeamPossession) {
          setIsDocumentLocked(true);
          !isDocumentExecuted && notifyError({ message: LeaseEditorErrors.IS_LOCKED.defaultMessage, pinned: true });
        }
      });

    return () => {
      if (isLockedRef.current) {
        unlockDocument({
          variables: { documentId: documentId!, userId: user.id, sessionActivities: [] },
          refetchQueries: [
            {
              query: queriesV2.getDocumentsByDeal,
              variables: {
                dealId,
              },
            },
          ],
        });
      }
    };
  }, []); // eslint-disable-line

  const handleUnlock = useCallback(() => {
    if (isLockedRef.current) {
      unlockDocument({ variables: { documentId: documentId!, userId: user.id, sessionActivities: null } });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnlock);

    return () => {
      window.removeEventListener('beforeunload', handleUnlock);
    };
  }, []); // eslint-disable-line

  if (
    !document ||
    !documentId ||
    !dealId ||
    !teamId ||
    isDataLoading ||
    !documentStatus ||
    isLockLeaseDocumentLoading ||
    !generatedDocumentPdf ||
    !documentSource ||
    !documentContentType
  ) {
    return <LoadingSpinner />;
  }

  return (
    <PrepareToSigning
      lastUpdated={lastUpdated}
      documentTitle={documentTitle || ''}
      isDocumentCreator={isDocumentCreator}
      documentId={documentId}
      dealId={dealId}
      teamId={teamId}
      permissions={permissions}
      handleClose={handleLeaseWizardClose}
      pdfUrl={generatedDocumentPdf}
      saveAnnotations={saveAnnotationsXfDfLayer as any}
      annotationsStringUrl={annotationsData?.getDocumentById.signaturesXfdfUrl || null}
      formFieldsStringUrl={annotationsData?.getDocumentById.inputXfdfUrl || null}
      isDocumentLocked={isDocumentLocked}
      documentContentType={documentContentType}
      documentStatus={documentStatus}
    />
  );
};

export default PrepareToSigningContainer;
