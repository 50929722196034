import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as TrashIcon } from '../../../../../../../shared/icons/trashcan-icon.svg';
import { ReactComponent as ApproveIcon } from '../../../../../../../shared/icons/checkmark-icon.cc.svg';
import { ReactComponent as RejectIcon } from '../../../../../../../shared/icons/reject.svg';
import { SIGNER_ITEM_BUTTON_NAME } from '../signerItem/constants';
import styles from './styles.module.scss';

interface RecipientItemProps {
  email: string;
  isDeletingSigner?: boolean;
  deleteSigner: (signerId: string) => Promise<void>;
}

const RecipientItem = ({ email, isDeletingSigner, deleteSigner }: RecipientItemProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames(styles.wrapper2, {
        [styles['wrapper-deleting']]: isDeleting,
      })}
      ref={ref}
      data-email={email}
    >
      <div className={classNames(styles['content'], { [styles['content-deleting']]: isDeleting })}>
        <span className={styles['email-text']}>{email}</span>
        <span className={styles.controls}>
          <button
            className={styles.button}
            name={SIGNER_ITEM_BUTTON_NAME}
            onClick={e => {
              setIsDeleting(true);
            }}
            type="button"
          >
            <TrashIcon className={styles['icon-delete']} />
          </button>
        </span>
      </div>
      <div className={classNames(styles['delete'], { 'd-none': !isDeleting })}>
        <span className={styles['delete-title']}>Delete recipient?</span>
        <span className={styles['delete-buttons']}>
          <button
            onClick={async e => {
              deleteSigner(email);
              setIsDeleting(false);
            }}
            disabled={isDeletingSigner}
            className={styles.button}
            name={SIGNER_ITEM_BUTTON_NAME}
            type="button"
          >
            <ApproveIcon className={styles['icon-approve']} />
          </button>
          <button
            className={styles.button}
            disabled={isDeletingSigner}
            onClick={e => {
              setIsDeleting(false);
            }}
            name={SIGNER_ITEM_BUTTON_NAME}
            type="button"
          >
            <RejectIcon className={styles['icon-reject-delete']} />
          </button>
        </span>
      </div>
    </div>
  );
};

export default RecipientItem;
