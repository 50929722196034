import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentCompareModeEnabledAction } from '../actions/lease-document.actions';

export const handleSetDocumentCompareModeEnabled = (
  state: LeaseDocumentStateType,
  action: SetDocumentCompareModeEnabledAction
): LeaseDocumentStateType => ({
  ...state,
  isCompareModeEnabled: action.isCompareModeEnabled,
});
