import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import headerFooterIcon from "../../../shared/icons/header_footer_icon.svg";

export default class HeaderFooterButton extends Plugin {
  init() {
    const editor = this.editor;
    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add("editHeaderFooter", () => {
      // The button will be an instance of ButtonView.
      const button = new ButtonView();

      button.set({
        label: "Header/Footer",
        icon: headerFooterIcon,
        tooltip: true,
      });

      button.on("execute", () => {
        const footerHeaderEditorModal = this._getHeaderFooterButtonConfig();
        footerHeaderEditorModal.callHeaderFooterEditorModal();
      });

      return button;
    });
  }

  _getHeaderFooterButtonConfig() {
    return this.editor.config.get("editHeaderFooter");
  }
}
