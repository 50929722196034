import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { graphqlErrorsToUiErrors, mutations, useTeamPermissions } from '../../api';
import { useNotifications } from '../../notifications';
import { useTeamInfo } from '../../team-id-context';

import ErrorMessage from '../error-message';
import Toggle from '../toggle';
import LoadingSpinner from '../loading-spinner';
import { PermissionsChart } from './permissionsChart';

import { mutatePermissions } from './utils';
import filterPermissions from '../../utils/filter-permissions';
import { Permissions } from '../../shared/constants/permissions';
import { getTeamById } from '../../api/queries';
import {Permission} from "../../store/permissions/permissions.reducer";
import {union} from "lodash";

interface EditPermissionsListProps {
  permissions: Permission[],
  userId: string,
  dealIds: string[],
  readOnly: boolean,
  isAdminUser: boolean,
  invitationId: string | null,
  isYou: boolean,
}

/**
 * This component (the default export) is a list of permissions which, if
 * readOnly isn't true, can be edited with edits taking effect immediately.
 */
const EditPermissionsList = ({ permissions, userId, readOnly = false, dealIds, isAdminUser, invitationId, isYou = false }: EditPermissionsListProps) => {
  const { teamId } = useTeamInfo();
  const { loading, teamPermissions, error: graphqlError } = useTeamPermissions();
  const [, { error: notifyError }] = useNotifications();
  const [updateUserPermissions] = useMutation(mutations.updateUserPermissions, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
    ],
  });
  const [updatePendingInvitation] = useMutation(mutations.updatePendingInvitation, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
    ],
  });

  const handleToggleChange = useCallback(
    async (permissionKey, isChecked) => {
      const isEditPermission = permissionKey === Permissions.Edit;
      const isApprovePermission = permissionKey === Permissions.Approve;
      const permissionUpdates = [
        {
          permissionKey,
          allowed: isChecked,
        },
      ];

      if (isChecked && isEditPermission) {
        permissionUpdates.push({
          permissionKey: Permissions.Comment,
          allowed: true,
        });
      }

      if (isChecked && isApprovePermission) {
        permissionUpdates.push({
          permissionKey: Permissions.Edit,
          allowed: true,
        });
      }

      try {
        await updateUserPermissions({
          variables: {
            userId,
            teamId,
            permissionUpdates,
          },
        });
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [notifyError, teamId, updateUserPermissions, userId]
  );

  const handlePendingToggleChange = useCallback(
    async (permissionKey, isChecked, invitationId, permissions, allPermissions) => {
      let editedPermissionIds = permissions.map((item: Permission) => item.id)
      const isEditPermission = permissionKey === Permissions.Edit;
      const isApprovePermission = permissionKey === Permissions.Approve;

      const getPermissionId = (key: string) => {
        return allPermissions.filter((item: Permission) => item.key === key)[0].id
      }

      const newTogglesPermissions: string[] = [getPermissionId(permissionKey)]

      if (isChecked && isEditPermission) {
        newTogglesPermissions.push(getPermissionId(Permissions.Comment))
      }

      if (isChecked && isApprovePermission) {
        newTogglesPermissions.push(getPermissionId(Permissions.Comment))
        newTogglesPermissions.push(getPermissionId(Permissions.Edit))
      }

      if (isChecked) {
        editedPermissionIds = union(editedPermissionIds, newTogglesPermissions)
      } else {
        editedPermissionIds = editedPermissionIds.filter((id: string) => id !== newTogglesPermissions[0])
      }

      try {
        await updatePendingInvitation({
          variables: {
            id: invitationId,
            permissionIds: editedPermissionIds,
            dealIds
          }
        })
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [dealIds, notifyError, updatePendingInvitation]
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!teamPermissions) {
    const errors = graphqlErrorsToUiErrors(graphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const allPermissions = mutatePermissions(teamPermissions.filter((perm: any) => filterPermissions(perm, isAdminUser)));

  // Does this user have the given permission?
  function hasPermission(permission: Permission) {
    return permissions.some((p: Permission) => p.key === permission.key);
  }

  const hasEditPermission = () => permissions.some((permissionItem: Permission) => permissionItem.key === Permissions.Edit);
  const hasApprovePermission = () => permissions.some((permissionItem: Permission) => permissionItem.key === Permissions.Approve);

  const isToggleDisabled = (permissionKey: string) => {
    const isCommentPermission = permissionKey === Permissions.Comment;
    const isEditPermission = permissionKey === Permissions.Edit;

    const isCommentDisabled = isCommentPermission && hasEditPermission();
    const isEditDisabled = isEditPermission && hasApprovePermission();

    return isCommentDisabled || isEditDisabled;
  };

  return (
    <PermissionsChart
      allPermissions={allPermissions}
      renderState={(permission: Permission) => {
        const shouldShowCustomDisabledState = permission.key === Permissions.View || isAdminUser || isYou;

        return (
          <Toggle
            data-testid="edit-permission-toggle"
            checked={isAdminUser || hasPermission(permission)}
            disabled={shouldShowCustomDisabledState || isToggleDisabled(permission.key)}
            isCustomDisabledState={shouldShowCustomDisabledState}
            onChange={(evt: any) => !!invitationId
              ? handlePendingToggleChange(permission.key, evt.currentTarget.checked, invitationId, permissions, allPermissions)
              : handleToggleChange(permission.key, evt.currentTarget.checked)}
          />
        );
      }}
    />
  );
};

export default EditPermissionsList;
