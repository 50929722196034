import { LeaseDocumentStateType } from '../lease-document.reducer'
import { SetLeaseDocumentLastUpdatedAction } from '../actions/lease-document.actions'

export const handleSetLeaseDocumentLastUpdated = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentLastUpdatedAction
): LeaseDocumentStateType => ({
  ...state,
  lastUpdated: action.lastUpdated,
})
