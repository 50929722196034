/**
 * Score a password based on strength
 *
 * @function getPasswordStrength
 * @async
 * @param {string} password - Password input
 * @returns {Promise<number>} - Rating from 0 to 5
 */
export default async function getPasswordStrength(password: string) {
  const zxcvbn = await import('zxcvbn');
  const { score } = zxcvbn.default(password);
  return score;
}
