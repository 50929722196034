import { ApolloClient } from '@apollo/client';
import { Revision } from '../../ckeditor/revisionHistoryAdapter/types';
import { graphql } from '../../../gql';

interface GetLeaseDocumentRevisionsResponse {
  data: {
    getDocumentById: {
      id: string;
      documentRevisions: {
        attributes: object;
        revisionId: string;
        authorsIds?: string[] | null;
        createdAt: string;
        creatorId?: string | null;
        fromVersion: number;
        toVersion: number;
      }[]
    };
  };
}

const query = graphql(/* GraphQL */`
  query GetLeaseDocumentRevisionsByDocumentId($offerId: ID!) {
    getDocumentById(id: $offerId) {
      id
      documentRevisions {
        attributes
        revisionId
        authorsIds
        createdAt
        creatorId
        fromVersion
        toVersion
        name
      }
    }
  }
`);

export const getLeaseDocumentRevisions = (documentId: string) => (
  client: ApolloClient<object>
): Promise<Revision[]> => {
  const variables = { offerId: documentId! };

  return client.query({ query, variables }).then(({ data }: GetLeaseDocumentRevisionsResponse) => {
    const revisionsResponse = data.getDocumentById.documentRevisions;

    return revisionsResponse.map(revision => {
      const { revisionId } = revision;

      return {
        id: revisionId,
        ...revision
      } as Revision;
    });
  });
};
