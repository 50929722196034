import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchInput from '../../../components/search-input';
import { DropdownBody, Dropdown, DropdownToggle, DropdownItem } from '../../../components/dropdown';
import { Checkbox } from '../../../components/checkbox-or-radio-field';
import slugify from '../../../utils/slugify';
import styles from './styles.module.scss';
import { ChangeEvent } from 'react';

interface LeaseTemplateModalFiltersProps {
  filterText: string;
  setFilterText: (text: string) => void;
  creators: any[];
  filterCreators: Set<any>;
  setFilterCreators: (creators: Set<any>) => void;
}

export function LeaseTemplateModalFilters({
  filterText,
  setFilterText,
  creators,
  filterCreators,
  setFilterCreators,
}: LeaseTemplateModalFiltersProps) {
  const { formatMessage } = useIntl();

  return (
    <Row className="justify-content-start">
      <Col lg={8}>
        <SearchInput
          className={styles.templateSearch}
          value={filterText}
          name="template-search"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFilterText(event.currentTarget.value)}
          placeholder={formatMessage({
            id: 'lease.filter.name.placeholder',
            defaultMessage: 'Search by template name',
          })}
          aria-label={formatMessage({
            id: 'lease.filter.name.label',
            defaultMessage: 'Template name',
          })}
          data-testid="template-search"
        />
      </Col>
      <Col lg={4}>
        <Dropdown>
          {/*
            //@ts-ignore */}
          <Dropdown.Toggle id="dropdown-filter-creators" as={DropdownToggle} className={'w-100'}>
            <FormattedMessage
              id="lease.filter.creator.label-readout"
              defaultMessage={`{creatorCount, select,
                      0 {Creator (all)}
                      1 {Creator ({creator, select,
                        other {{creator}}
                      })}
                      other {{creatorCount} creators}
                    }`}
              values={{
                creatorCount: filterCreators.size === creators.length ? 0 : filterCreators.size,
                creator: filterCreators.size ? filterCreators.values().next().value : '',
              }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <DropdownBody>
              <DropdownItem highlightable>
                <Checkbox
                  checked={filterCreators.size === creators.length}
                  indeterminate={filterCreators.size > 0 && filterCreators.size < creators.length}
                  id="dropdown-filter-leases-_all"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const checkbox = event.currentTarget;
                    if (checkbox.checked) {
                      setFilterCreators(new Set(creators));
                    } else {
                      setFilterCreators(new Set());
                    }
                  }}
                  label={<FormattedMessage id="leases.filter.creators.all-creators" defaultMessage="All creators" />}
                />
              </DropdownItem>
              {creators.map(creator => (
                <DropdownItem key={creator} highlightable>
                  <Checkbox
                    key={creator}
                    checked={filterCreators.has(creator)}
                    value={creator}
                    id={`dropdown-filter-leases-${slugify(creator)}`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const checkbox = event.currentTarget;
                      const newSet = new Set(filterCreators);
                      if (checkbox.checked) newSet.add(checkbox.value);
                      else newSet.delete(checkbox.value);
                      setFilterCreators(newSet);
                    }}
                    label={
                      <FormattedMessage id={`dropdown-filter-leases-${slugify(creator)}`} defaultMessage={creator} />
                    }
                  />
                </DropdownItem>
              ))}
            </DropdownBody>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}
