import {
  GlobalModalActionsEnum,
  ModalActionsEnum,
  ModalActionTitle,
} from '../../../document-table/DocumentTableConstants';

export const confirmButtonTitle = (activeModal?: GlobalModalActionsEnum | null) => {
  switch (activeModal) {
    case ModalActionsEnum.SEND_FOR_SIGNING:
      return ModalActionTitle.SEND_FOR_SIGNING;
    case ModalActionsEnum.TERMINATE:
      return 'Terminate';
    case ModalActionsEnum.UNSEND:
      return 'Unsend';
    default:
      return 'Send';
  }
};
