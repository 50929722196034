import { ApolloClient } from '@apollo/client';
import { getDealById } from '../../../api/queries';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  success: boolean;
};

export function resendDealInviteEmail(dealId: string, receiverEmail: string) {
  return function(client: ApolloClient<object>): Promise<ResponsePayloadType> {
    const variables = {
      dealId,
      receiverEmail,
    };

    const mutation = graphql(/* GraphQL */`
      mutation resendDealInviteEmail($dealId: ID!, $receiverEmail: String!) {
        resendDealInviteEmail(id: $dealId, email: $receiverEmail) {
          receiverTeam {
            id
          }
        }
      }
    `);

    const refetchQueries = [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.resendDealInviteEmail.receiverTeam);
  };
}
