import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import useInterval from '../../utils/use-interval';

export const ActivityDateChat = ({
  datetime,
  intervalInSecond,
  shouldBeShortenedTimeApplied,
}: {
  datetime: string;
  intervalInSecond: number;
  shouldBeShortenedTimeApplied?: boolean;
}) => {
  const [lastUpdatedTime, setLastUpdatedTime] = useState(dayjs(datetime).format('LLL'));
  const setUpdatedTimeFromNow = useCallback(() => setLastUpdatedTime(dayjs(datetime).fromNow()), [datetime]);
  const intervalForUpdate = intervalInSecond * 1000;
  const formattedTime = useMemo(() => {
    return dayjs(`${datetime}`).format('MM-DD-YYYY HH:mm');
  }, [datetime]);

  useEffect(() => {
    setLastUpdatedTime(dayjs(dayjs.max(dayjs(), dayjs(datetime))).fromNow(true));
    setUpdatedTimeFromNow();
  }, [datetime, setUpdatedTimeFromNow]);

  useInterval(() => {
    setUpdatedTimeFromNow();
  }, intervalForUpdate);

  return <span title={formattedTime}>{lastUpdatedTime}</span>;
};
