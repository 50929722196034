import React from 'react';
import { LeaseDocumentWizardCTAType } from '../../../lease-wizard/lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import styles from '../../../lease-wizard/lease-document-wizard-header/components/headerActions/sendToPreparingToSignInternal/styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { LeaseDocumentWizardModal } from '../../../lease-wizard/lease-document-wizard-modal/LeaseDocumentWizardModal';

interface ShareExecutedDocumentModalProps {
  onHide: () => void;
  isLoading: boolean;
  onConfirm: () => void;
  show: boolean;
}

const ShareExecutedDocumentModal = ({ onConfirm, onHide, isLoading, show }: ShareExecutedDocumentModalProps) => {
  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onHide,
      label: 'Cancel',
      theme: 'outline-secondary',
    },
    {
      onClick: onConfirm,
      label: 'Send',
      theme: 'secondary',
      isLoading,
      disabled: isLoading,
      className: styles['cancel-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={styles.title}
      size="lg"
      show={show}
      onHide={onHide}
      title={
        <FormattedMessage
          id="lease-document-wizard-modal.send-for-signing"
          defaultMessage="Send Document to Counterparty"
        />
      }
      modalCTAs={modalCTAs}
    >
      <div className={styles.main}>
        Your counterparty will not be able to edit this document since it has already been signed.
      </div>
    </LeaseDocumentWizardModal>
  );
};
export default ShareExecutedDocumentModal;
