
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PrivateThreadCommand from './privateThreadCommand';

export default class PrivateThreadEditing extends Plugin {

  init() {
    this._defineSchema();
	this._defineConverters();
	this.editor.commands.add( 'togglePrivateThread', new PrivateThreadCommand( this.editor ) );
  }

  _defineSchema() { }
  _defineConverters() { }

}
