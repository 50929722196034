export enum DealModalActionsEnum {
  CANCEL = 'deal_action_modal_cancel',
  RESEND = 'deal_action_modal_resend',
  REMOVE_COUNTERPARTY = 'deal_action_modal_removeCounterparty',
}

export const dealStatusesForStatusDropdown = `{status, select,
  in_progress {In Progress}
  completed {Completed}
  archived {Archived}
  lost {Lost}
  other {{status}}
}`;

export const defaultDealStatuses = new Set(['in_progress', 'completed']);
