import React, { useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import TeamMembersList from './TeamMembersList';
import { graphqlErrorsToUiErrors, queriesV2 } from '../../api';
import { getDealsForTeam, getDealForTeam, getTeamById } from '../../api/queries';
import { useTeamInfo } from '../../team-id-context';
import { useDealId } from '../../deal-id-context';
import { useAuth } from '../../auth';
import LoadingSpinner from '../loading-spinner';
import ErrorMessage from '../error-message';
import { fuzzyInputSearch } from '../../pages/deals/helpers/determineDealsActionModalMessage';
import { useLeaseDocumentTeamPermissions } from '../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';

interface TeamMembersListContainerProps {
  isReadOnly?: boolean;
  showDealMembersOnly?: boolean;
  filterText?: string;
  sorting?: string;
  filterDeals?: Set<string>;
  allDeals?: [];
  passedTeamData?: any;
}

export const TeamMembersListContainer = ({
  showDealMembersOnly = false,
  isReadOnly = false,
  filterText,
  filterDeals,
  sorting,
  allDeals,
  passedTeamData,
}: TeamMembersListContainerProps) => {
  const apolloClient = useApolloClient();
  const { loading: isTeamInfoLoading, teamId } = useTeamInfo();
  const dealId = useDealId();
  // @ts-ignore TODO add proper typings
  const [{ authLoading: isAuthLoading, user }] = useAuth();

  const { loading: isTeamLoading, data: queryTeamData, error: teamGraphqlError } = useQuery(getTeamById, {
    skip: !teamId || !!passedTeamData,
    variables: {
      teamId,
    },
  });

  const teamData = passedTeamData || queryTeamData;

  const filteredTeamData = useMemo(() => {
    if (isTeamLoading || !teamData) return [];

    const filteredMembers = teamData.teamById?.teamMembers?.filter((member: any) => {
      if (filterText) {
        return fuzzyInputSearch({ searchValue: member?.user?.fullName, filteredText: filterText });
      }

      if (filterDeals?.size) {
        const userDeals = member?.user?.deals?.map((deal: { id: string }) => deal.id);
        const userDealInFilteredDealsArr = userDeals.find((deal: any) => filterDeals.has(deal));
        if (!userDealInFilteredDealsArr) return false;
      }

      return true;
    });
    const filteredTeam = { ...teamData, teamById: { ...teamData.teamById, teamMembers: filteredMembers } };

    return filteredTeam;
  }, [filterText, isTeamLoading, teamData, filterDeals, sorting]);

  const currentUserIsAdmin = teamData?.teamById?.adminUser?.id === user.id;
  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });
  const hasUserInvitePermission = !!permissions?.hasInviteSendingPermission;

  const { loading: isDealsForTeamLoading, data: dealsData, error: dealsGraphqlError } = useQuery(getDealsForTeam, {
    skip:
      (!currentUserIsAdmin && !hasUserInvitePermission) || !hasUserInvitePermission || (isReadOnly && dealId != null),
    variables: {
      teamId,
    },
  });
  const { loading: isDealForTeamLoading, data: dealData } = useQuery(getDealForTeam, {
    fetchPolicy: 'cache-and-network',
    skip: (!currentUserIsAdmin && !hasUserInvitePermission) || !dealId,
    variables: {
      dealId,
    },
  });

  const [loadDealTeamsByTeamId, { loading: isDealLoading, data: dealTeamsData }] = useLazyQuery(
    queriesV2.getDealTeamsByTeamId,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        dealId: dealId,
      },
    }
  );

  const isLoading =
    isTeamInfoLoading ||
    isAuthLoading ||
    isTeamLoading ||
    isDealsForTeamLoading ||
    isDealForTeamLoading ||
    isDealLoading;

  useEffect(() => {
    if (dealId && showDealMembersOnly) {
      loadDealTeamsByTeamId();
    }
  }, [dealData, loadDealTeamsByTeamId, dealId, showDealMembersOnly]);

  if (isLoading && isTeamLoading) {
    return <LoadingSpinner />;
  }

  if (!teamData) {
    // TODO add proper typings
    const errors: any = graphqlErrorsToUiErrors(teamGraphqlError as any);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }
  const dealMembersIds = dealTeamsData?.getDealById?.members?.map(({ id }: { id: string }) => id) || [];

  return (
    <TeamMembersList
      showDealMembersOnly={showDealMembersOnly}
      isReadOnly={isReadOnly}
      dealsData={dealsData}
      dealsGraphqlError={dealsGraphqlError}
      teamData={showDealMembersOnly ? teamData : filteredTeamData}
      dealData={dealData}
      dealMembersIds={dealMembersIds}
      shouldShowPendingInvitations={!filterText}
      sorting={sorting}
    />
  );
};
