import React from 'react';
import { DocumentSubheaderProps } from './lease-document-wizard-header.types';
import { DocumentSubheader } from './DocumentSubheader';
import { GrantAccess } from './components/headerActions/grantAccess';
import { UnlockDocument } from './components/headerActions/unlockDocument';

export const DocumentSubheaderContainer = ({
  documentId,
  dealId,
  teamId,
  grantAccessSubheader,
  unlockDocumentSubheader,
  orgTemplateSubheader,
  organizationName,
  isClauseTemplate,
}: DocumentSubheaderProps) => {
  const getHeaderActions = () =>
    (grantAccessSubheader || orgTemplateSubheader) && dealId ? (
      <GrantAccess teamId={teamId} documentId={documentId} dealId={dealId} />
    ) : (
      unlockDocumentSubheader && <UnlockDocument teamId={teamId} documentId={documentId} />
    );

  return (
    <DocumentSubheader
      actions={getHeaderActions()}
      grantAccessSubheader={grantAccessSubheader}
      unlockDocumentSubheader={unlockDocumentSubheader}
      orgTemplateSubheader={orgTemplateSubheader}
      organizationName={organizationName}
      isClauseTemplate={isClauseTemplate}
    />
  );
};
