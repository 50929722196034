import React from 'react';
import Button from 'react-bootstrap/Button';
import { ReactComponent as TrashcanIcon } from '../../../shared/icons/trashcan-icon.svg';
import { RemoveTeamMember } from '../../../pages/deals/constants/AddOrEditTeamConstants';
import { TitleFormattedMessage } from '../../titleFormattedMessage';

const RemoveTeamMemberButton = ({ onClick, shouldBeRemovedFromTeam = false }) => (
  <p>
    <Button variant="link" className="text-danger pl-0 mt-4" onClick={onClick}>
      <TrashcanIcon className="align-middle mr-1" />
      <TitleFormattedMessage
        id="cta.remove-team-member"
        defaultMessage={shouldBeRemovedFromTeam ? RemoveTeamMember.FROM_TEAM : RemoveTeamMember.FROM_DEAL}
      />
    </Button>
  </p>
);

export default RemoveTeamMemberButton;
