import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import Command from "@ckeditor/ckeditor5-core/src/command";

import { ButtonView } from "@ckeditor/ckeditor5-ui";
import tagIcon from "../../../shared/icons/diez_icon.svg";
import "./pagenumber.css";

export class InsertPageNumber extends Plugin {
  static get requires() {
    return [InsertPageNumberEditing, InsertPageNumberUI];
  }
}

class InsertPageNumberCommand extends Command {
  execute({ value }) {
    const editor = this.editor;
    const selection = editor.model.document.selection;

    editor.model.change((writer) => {
      // Create a <pageNumber> element with the "name" attribute (and all the selection attributes)...
      const pageNumber = writer.createElement("pageNumber", {
        ...Object.fromEntries(selection.getAttributes()),
        name: value,
      });

      // ... and insert it into the document. Put the selection on the inserted element.
      editor.model.insertObject(pageNumber, null, null, {
        setSelection: "after",
      });
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;

    const isAllowed = model.schema.checkChild(
      selection.focus.parent,
      "pageNumber"
    );

    this.isEnabled = isAllowed;
  }
}

class InsertPageNumberUI extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add("pageNumber", (locale) => {
      const button = new ButtonView();
      button.label = "Page number";
      button.tooltip = true;
      button.icon = tagIcon;

      const command = editor.commands.get("pageNumber");
      button.bind("isEnabled").to(command);

      this.listenTo(button, "execute", (evt) => {
        editor.execute("pageNumber", { value: "[#]" });
        editor.editing.view.focus();
      });

      return button;
    });
  }
}

class InsertPageNumberEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add(
      "pageNumber",
      new InsertPageNumberCommand(this.editor)
    );

    this.editor.editing.mapper.on(
      "viewToModelPosition",
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) =>
        viewElement.hasClass("pageNumber")
      )
    );
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register("pageNumber", {
      inheritAllFrom: "$inlineObject",

      allowAttributes: ["name", "tooltip"],
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for("upcast").elementToElement({
      view: {
        name: "span",
        classes: ["pageNumber"],
      },
      model: (viewElement, { writer: modelWriter }) => {
        return modelWriter.createElement("pageNumber", {
          name: "[#]",
        });
      },
    });

    conversion.for("editingDowncast").elementToElement({
      model: "pageNumber",
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = createInsertPageNumberView(modelItem, viewWriter);

        // Enable widget handling on a pageNumber element inside the editing view.
        return toWidget(widgetElement, viewWriter);
      },
    });

    conversion.for("dataDowncast").elementToElement({
      model: "pageNumber",
      view: (modelItem, { writer: viewWriter }) =>
        createInsertPageNumberView(modelItem, viewWriter),
    });

    // Helper method for both downcast converters.
    function createInsertPageNumberView(modelItem, viewWriter) {
      const name = modelItem.getAttribute("name");

      const pageNumberView = viewWriter.createContainerElement("span", {
        class: "pageNumber",
        "data-cke-tooltip-text": "Page number",
        "data-cke-tooltip-position": "s",
      });

      // Insert the pageNumber name (as a text).
      const innerText = viewWriter.createText(name);
      viewWriter.insert(
        viewWriter.createPositionAt(pageNumberView, 0),
        innerText
      );

      return pageNumberView;
    }
  }
}
