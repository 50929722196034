import { SetIsDocumentEditableAction } from '../actions/lease-document.actions';
import { LeaseDocumentStateType } from '../lease-document.reducer';

export const handleSetIsDocumentEditable = (
  state: LeaseDocumentStateType,
  action: SetIsDocumentEditableAction
): LeaseDocumentStateType => ({
  ...state,
  isEditable: action.isEditable,
});
