export const DOCUMENT_ACTIVITY_ITEM_BUTTON_NAME = 'document-activity-item-button';

export enum DocumentActivityActionVerbs {
  SUGGESTION_ACCEPTED = 'document-suggestion-accepted',
  SUGGESTION_REJECTED = 'document-suggestion-rejected',
}

export enum DocumentActivityActions {
  NEW_SUGGESTION = 'New Suggestion',
  NEW_COMMENT = 'New Comment',
  NEW_SUGGESTION_REPLY = 'New Suggestion Reply',
  NEW_COMMENT_REPLY = 'New Comment Reply',
}

export enum DocumentActivityTypeEnum {
  comment = 'Comment',
  suggestion = 'Suggestion',
}

export enum ActivitiesNavigation {
  NEXT = 'Next',
  PREV = 'Prev',
}

export type ActivityPropsType = {
  id: string;
  description: string;
  time: string;
  actor_data: {
    full_name: string;
    email: string;
  };
  verb: string;
  extra: {
    data?: {
      suggestionsData: string;
    };
    external_id: string;
    section_name: string;
  };
  object_data: {
    object_id: string;
  };
  inactive?: boolean;
};
