import { UI, WebViewerInstance } from '@pdftron/webviewer';
import { renderCloseFormBuilderButton, renderOpenFormBuilderButton } from './customApryseTools';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { renderFormFillingDropdownTool } from './formFillingDropdownTool';

enum HeaderItemTypes {
  TOOL_BUTTON = 'toolButton',
  TOGGLE_ELEMENT_BUTTON = 'toggleElementButton',
}

interface SetNecessaryHeaderItemsProps {
  instance: WebViewerInstance;
  isSigningProcessStarted: boolean;
  isDocumentCreator: boolean;
  hasUserEditPermission: boolean;
  hasUserCommentPermission: boolean;
  isOrganizationTemplate?: boolean;
  isDocumentExecuted?: boolean;
  saveFormFields?: (options: MutationFunctionOptions) => Promise<void>;
  documentId?: string;
  isReceiverCollaborationEnabled?: boolean;
  toggleReceiverCollaborationForDocument?: (options: MutationFunctionOptions) => Promise<FetchResult<boolean>>;
  isDocumentInReview?: boolean;
}

export const setNecessaryHeaderItems = ({
  instance,
  isSigningProcessStarted,
  isDocumentExecuted,
  isDocumentCreator,
  hasUserEditPermission,
  hasUserCommentPermission,
  isOrganizationTemplate,
  saveFormFields,
  documentId,
  toggleReceiverCollaborationForDocument,
  isReceiverCollaborationEnabled,
  isDocumentInReview,
}: SetNecessaryHeaderItemsProps): void => {
  const {
    Core: { Annotations, documentViewer },
  } = instance;
  const annotationManager = documentViewer.getAnnotationManager();

  const createStickyAnnotation = () => {
    const currentPage = documentViewer.getCurrentPage();
    const selectionCoordinates = documentViewer.getSelectedTextQuads()[currentPage];
    const middleX = (selectionCoordinates[0].x1 + selectionCoordinates[0].x2) / 2 - 10;
    const middleY = (selectionCoordinates[0].y3 + selectionCoordinates[selectionCoordinates.length - 1].y3) / 2 - 25;

    const annot = new Annotations.StickyAnnotation({
      PageNumber: currentPage,
      X: middleX,
      Y: middleY,
      Icon: Annotations.StickyAnnotation.IconNames.COMMENT,
      StrokeColor: new Annotations.Color(255, 205, 69, 1),
    });
    annot.Author = annotationManager.getCurrentUser();

    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
    documentViewer.clearSelection();

    instance.UI.openElements(['notesPanel']);
    setTimeout(() => {
      annotationManager.selectAnnotation(annot);
    }, 500);
  };

  const headerToolsContainer = instance.UI.iframeWindow.document.querySelector<HTMLElement>('.HeaderToolsContainer');
  if (headerToolsContainer) {
    headerToolsContainer.style.display = 'none';
  }

  instance.UI.setHeaderItems((header: UI.Header) => {
    const toolNamesToRemove = ['Pan', 'TextSelect', 'AnnotationEdit', 'ActionButton', 'AnnotationCreateTextHighlight'];
    const elementButtonsToRemove = ['searchPanel', (isSigningProcessStarted || isOrganizationTemplate) && 'notesPanel'];
    const headerItems = header.getItems().reduce((items: { [key: string]: any }[], item: { [key: string]: any }) => {
      if (item.type === HeaderItemTypes.TOOL_BUTTON) {
        return toolNamesToRemove.includes(item.toolName) ? items : [...items, item];
      }

      if (item.type === HeaderItemTypes.TOGGLE_ELEMENT_BUTTON) {
        return elementButtonsToRemove.includes(item.element) ? items : [...items, item];
      }

      return [...items, item];
    }, []);

    if (
      !isSigningProcessStarted &&
      hasUserCommentPermission &&
      !isDocumentExecuted &&
      (!isDocumentInReview || isDocumentCreator)
    ) {
      const toggleNotesPanelButton = headerItems.splice(8, 1)[0];
      isDocumentCreator &&
        hasUserEditPermission &&
        headerItems.splice(
          7,
          0,
          {
            type: 'toolGroupButton',
            toolGroup: 'stickyTools',
            dataElement: 'stickyToolGroupButton',
            title: 'annotation.stickyNote',
          },
          {
            type: 'toolGroupButton',
            toolGroup: 'strikeoutTools',
            dataElement: 'strikeoutToolGroupButton',
            title: 'annotation.strikeout',
          },
          {
            type: 'toolGroupButton',
            toolGroup: 'freeTextTools',
            dataElement: 'freeTextToolGroupButton',
            title: 'annotation.freetext',
          },
          { type: 'divider', hidden: ['mobile', 'small-mobile'] },
          toggleNotesPanelButton,
          {
            type: 'toolButton',
            toolName: 'TextFormFieldCreateTool',
          },
          {
            type: 'toolButton',
            toolName: 'CheckBoxFormFieldCreateTool',
          }
        );

      !isDocumentCreator &&
        hasUserEditPermission &&
        headerItems.splice(
          7,
          0,
          {
            type: 'toolGroupButton',
            toolGroup: 'stickyTools',
            dataElement: 'stickyToolGroupButton',
            title: 'annotation.stickyNote',
          },
          { type: 'divider', hidden: ['mobile', 'small-mobile'] },
          toggleNotesPanelButton
        );
    }

    if (isDocumentExecuted) {
      const toggleNotesPanelButton = headerItems.splice(8, 1)[0];
      headerItems.splice(7, 0, toggleNotesPanelButton);
    }

    //disable toolgroup buttons for Form Builder
    instance.UI.disableElements([
      'signatureFieldToolGroupButton',
      'radioButtonFieldToolGroupButton',
      'listBoxFieldToolGroupButton',
      'comboBoxFieldToolGroupButton',
      'applyFormFieldButton',
    ]);

    header.update(headerItems);

    if (!isSigningProcessStarted) {
      const documentViewer = instance.Core.documentViewer;
      const strikeoutCreateTool = documentViewer.getTool('AnnotationCreateTextStrikeout');

      instance.UI.textPopup.update([]);

      if (isDocumentCreator && hasUserEditPermission && !isDocumentInReview) {
        instance.UI.textPopup.add([
          {
            //@ts-ignore
            img: 'icon-tool-comment-line',
            dataElement: 'stickyToolButton',
            type: 'actionButton',
            onClick: createStickyAnnotation,
          },
          {
            //@ts-ignore
            img: 'icon-tool-text-manipulation-strikethrough',
            dataElement: 'textStrikeoutToolButton',
            type: 'actionButton',
            onClick: () => {
              documentViewer.setToolMode(strikeoutCreateTool);
            },
          },
        ]);
      } else {
        hasUserCommentPermission &&
          instance.UI.textPopup.add([
            {
              //@ts-ignore
              img: 'icon-tool-comment-line',
              dataElement: 'stickyToolButton',
              type: 'actionButton',
              onClick: createStickyAnnotation,
            },
          ]);
      }
    }

    if (isSigningProcessStarted) {
      instance.UI.disableElements(['annotationStyleEditButton']);
    }

    if (
      !isOrganizationTemplate &&
      isDocumentCreator &&
      hasUserEditPermission &&
      !isSigningProcessStarted &&
      !isDocumentExecuted
    ) {
      header
        .get('toggleNotesButton')
        .insertAfter(
          !!toggleReceiverCollaborationForDocument && documentId
            ? {
                type: 'customElement',
                render: () =>
                  renderFormFillingDropdownTool({
                    documentId,
                    toggleReceiverCollaborationForDocument,
                    isReceiverCollaborationEnabled,
                  }),
              }
            : {}
        )
        .insertAfter(!!toggleReceiverCollaborationForDocument ? { type: 'divider' } : {})
        .insertAfter(
          !isDocumentInReview || isDocumentCreator
            ? {
                type: 'customElement',
                render: () =>
                  renderOpenFormBuilderButton({
                    instance,
                    documentId,
                    saveFormFields,
                  }),
              }
            : {}
        );

      header.get('checkBoxFieldCreateToolButton').insertAfter({
        type: 'customElement',
        dataElement: 'closeFormBuilderButton',
        render: () =>
          renderCloseFormBuilderButton({
            instance,
            documentId,
            saveFormFields,
          }),
      });

      header.get('toggleNotesButton').insertAfter({
        type: 'divider',
      });
    }
  });

  documentViewer.getTool('TextFormFieldCreateTool').setStyles(() => ({
    StrokeThickness: 1,
    StrokeColor: new Annotations.Color(162, 162, 162),
    FillColor: new Annotations.Color(162, 162, 162),
  }));

  documentViewer.getTool('CheckBoxFormFieldCreateTool').setStyles(() => ({
    StrokeColor: new Annotations.Color(162, 162, 162),
    FillColor: new instance.Core.Annotations.Color(222, 226, 255),
  }));

  instance.UI.unregisterTool('Pan');
};
