import React from 'react';
import { Core } from '@pdftron/webviewer';
import { getAnnotationTitleByAnnotationType } from '../../helpers';
import { AnnotationTypes } from '../../../../components/lease-wizard/lease-editor/components/signersSidebar/components/insertFields/insertFields';

const CustomAnnotationPopup = ({ annotation }: { annotation: Core.Annotations.StampAnnotation }) => {
  const userEmail = annotation.getCustomData('signerEmail');
  const annotationType = annotation.getCustomData('annotationType') as AnnotationTypes;

  return (
    <div>
      <p style={{ margin: '0 0 5px 0', fontWeight: 800 }}>{userEmail}</p>
      <p style={{ margin: 0 }}>{getAnnotationTitleByAnnotationType(annotationType)}</p>
    </div>
  );
};

export default CustomAnnotationPopup;
