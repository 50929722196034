import React from 'react';

import PermissionIcon from '../permission-icon';

import styles from './styles.module.scss';

const InlinePermissionsList = ({ permissions }) => (
  <ul className={styles.root}>
    {permissions.map((permission, index) => (
      <li key={`${permission.key}-${index}`}>
        <PermissionIcon permission={permission} />
      </li>
    ))}
  </ul>
);

export default InlinePermissionsList;
