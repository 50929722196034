import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Field, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import SubmitButton from '../../components/submit-button';
import ErrorMessage from '../../components/error-message';
import { ReactMultiEmail } from 'react-multi-email';
import { Table } from 'react-bootstrap';
import Toggle from '../../components/toggle';
import styles from './../styles.module.scss';
import { Warning } from '../../components/shared/warning';
import {
  DUPLICATE_EMAIL_WARNING,
  ORG_MEMBER_EMAIL_WARNING,
  PENDING_EMAIL_WARNING,
} from '../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { EMAIL_VALIDATION_ERRORS } from './AddDealTeamMemberFormContainer';
import { Permissions } from '../../shared/constants/permissions';

export interface AddTeamMemberFormProps {
  bodyContainer?: any;
  actionsContainer?: any;
  renderSecondaryAction: any;
  errors: { [key: string]: any };
  isSubmitting: boolean;
  invitationEmails: string[];
  setInvitationEmails: React.SetStateAction<any>;
  userPermissions: UserPermissionsType[];
  setUserPermissions: React.SetStateAction<any>;
  validateEmail: (email: string) => boolean;
  emailValidationError: EMAIL_VALIDATION_ERRORS;
  handleReset?: () => any;
  allPermissions: any;
}
export interface UserPermissionsType {
  email: string;
  permissionIds: Set<string>;
}
export interface UserPermissionsApiData {
  email: string;
  permissionIds: string[];
}

const AddDealTeamMemberForm = ({
  bodyContainer: BodyContainer = React.Fragment,
  actionsContainer: ActionsContainer = React.Fragment,
  renderSecondaryAction,
  errors,
  isSubmitting,
  invitationEmails,
  setInvitationEmails,
  userPermissions,
  setUserPermissions,
  validateEmail,
  emailValidationError,
  allPermissions,
}: AddTeamMemberFormProps) => {
  const getPermissionIdByKey = (key: string) => allPermissions.find((permission: any) => permission.key === key).id;

  useEffect(() => {
    // Add new userPermissions when a new email is added to the input
    const newEmails = invitationEmails.filter(
      email => userPermissions.filter(invite => invite.email === email).length === 0
    );
    newEmails.forEach(newEmail => {
      const userInvitePermissions = userPermissions;
      userInvitePermissions.push({
        email: newEmail,
        permissionIds: new Set<string>('1'),
      });
      setUserPermissions(userInvitePermissions);
    });

    // Remove userPermissions when an email is removed from the input
    const filteredUserPermissionsList = userPermissions.filter(
      (userPermission: UserPermissionsType) =>
        invitationEmails.filter(email => userPermission.email === email).length > 0
    );

    setUserPermissions(filteredUserPermissionsList);
  }, [invitationEmails]);

  const editUserPermission = (event: any, formUserPermissions: UserPermissionsType) => {
    // Change the value in the permissions object passed in
    const permissionNumber = event.target.name;
    let tempPermissionIds = formUserPermissions.permissionIds;

    const commentPermissionId = getPermissionIdByKey(Permissions.Comment);
    const editPermissionId = getPermissionIdByKey(Permissions.Edit);
    const approvePermissionId = getPermissionIdByKey(Permissions.Approve);

    switch (permissionNumber) {
      case editPermissionId:
        tempPermissionIds.has(permissionNumber)
          ? tempPermissionIds.delete(permissionNumber)
          : tempPermissionIds.add(commentPermissionId).add(editPermissionId);
        break;
      case approvePermissionId:
        tempPermissionIds.has(permissionNumber)
          ? tempPermissionIds.delete(permissionNumber)
          : tempPermissionIds
              .add(commentPermissionId)
              .add(editPermissionId)
              .add(approvePermissionId);
        break;
      default:
        tempPermissionIds.has(permissionNumber)
          ? tempPermissionIds.delete(permissionNumber)
          : tempPermissionIds.add(permissionNumber);
    }

    // Replace and set the new permissions list state value
    const newUserPermissionsList = userPermissions.map(item => {
      return item.email === formUserPermissions.email
        ? {
            email: formUserPermissions.email,
            permissionIds: tempPermissionIds,
          }
        : item;
    });
    setUserPermissions(newUserPermissionsList);
  };

  const renderEmailErrorMsg = () => {
    switch (emailValidationError) {
      case EMAIL_VALIDATION_ERRORS.existing:
        return <Warning text={DUPLICATE_EMAIL_WARNING} />;
      case EMAIL_VALIDATION_ERRORS.pending:
        return <Warning text={PENDING_EMAIL_WARNING} />;
      case EMAIL_VALIDATION_ERRORS.orgMember:
        return <Warning text={ORG_MEMBER_EMAIL_WARNING} />;
      default:
        return null;
    }
  };

  const renderPermissionToggles = () => {
    const filteredAllPermissions = allPermissions
      .slice()
      .filter((permission: any) => permission.key !== Permissions.Whitelist)
      .sort((a: any, b: any) => a.displayOrder - b.displayOrder);

    const editPermissionId = getPermissionIdByKey(Permissions.Edit);
    const approvePermissionId = getPermissionIdByKey(Permissions.Approve);
    const toggles: any[] = [];
    userPermissions.forEach(userPermission => {
      toggles.push(
        <tr key={userPermission.email} className={styles.inviteUsersTableRow}>
          <td data-testid="email">{userPermission.email}</td>
          {filteredAllPermissions.map((permission: any) => {
            switch (permission.key) {
              case Permissions.View:
                return (
                  <td data-testid="permission-toggle" key={permission.id}>
                    <Toggle checked={userPermission.permissionIds.has(permission.id)} disabled name={permission.id} />
                  </td>
                );
              case Permissions.Comment:
                return (
                  <td data-testid="permission-toggle" key={permission.id}>
                    <Toggle
                      checked={userPermission.permissionIds.has(permission.id)}
                      onChange={(event: any) => editUserPermission(event, userPermission)}
                      disabled={userPermission.permissionIds.has(editPermissionId)}
                      isCustomDisabledState={false}
                      name={permission.id}
                    />
                  </td>
                );
              case Permissions.Edit:
                return (
                  <td data-testid="permission-toggle" key={permission.id}>
                    <Toggle
                      checked={userPermission.permissionIds.has(permission.id)}
                      onChange={(event: any) => editUserPermission(event, userPermission)}
                      disabled={userPermission.permissionIds.has(approvePermissionId)}
                      isCustomDisabledState={false}
                      name={permission.id}
                    />
                  </td>
                );
              case Permissions.Invite:
                return (
                  <td data-testid="permission-toggle" key={permission.id}>
                    <Toggle
                      checked={userPermission.permissionIds.has(permission.id)}
                      onChange={(event: any) => editUserPermission(event, userPermission)}
                      isCustomDisabledState={false}
                      name={permission.id}
                    />
                  </td>
                );
              case Permissions.Approve:
                return (
                  <td data-testid="permission-toggle" key={permission.id}>
                    <Toggle
                      checked={userPermission.permissionIds.has(permission.id)}
                      onChange={(event: any) => editUserPermission(event, userPermission)}
                      isCustomDisabledState={false}
                      name={permission.id}
                    />
                  </td>
                );
              default:
                return null;
            }
          })}
        </tr>
      );
    });
    return toggles;
  };

  const renderPermissionsTable = () => {
    return (
      invitationEmails.length !== 0 && (
        <>
          <h3 className="mt-3">
            <FormattedMessage id="title.permissions" defaultMessage="Permissions" />
          </h3>
          <span>
            <FormattedMessage
              id="phrase.edit-permissions-title"
              defaultMessage="Team members can view all current and future documents in this deal"
            />
          </span>
          <Table>
            <thead>
              <tr className={styles.inviteUsersTableHeader}>
                <th></th>
                <th>View</th>
                <th>Comment</th>
                <th>Edit</th>
                <th>Invite</th>
                <th>Approve</th>
              </tr>
            </thead>
            <tbody>{renderPermissionToggles()}</tbody>
          </Table>
        </>
      )
    );
  };

  return (
    <Form noValidate>
      <BodyContainer>
        {errors._general && (
          <Alert variant="danger">
            <ErrorMessage>{errors._general}</ErrorMessage>
          </Alert>
        )}
        <h3>
          <FormattedMessage id="title.email-address" defaultMessage="Email address" />
        </h3>
        <span>
          <FormattedMessage
            id="phrase.new-team-member-will-receive-activation-email"
            defaultMessage="Your new team member will receive an email to activate their account."
          />
        </span>
        <Row>
          <Col className="mb-1" lg={12}>
            <ReactMultiEmail
              emails={invitationEmails}
              onChange={_emails => {
                //@ts-ignore
                setInvitationEmails(_emails);
              }}
              validateEmail={email => {
                return validateEmail(email);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            ></ReactMultiEmail>
            {renderEmailErrorMsg()}
          </Col>
        </Row>
        {renderPermissionsTable()}
      </BodyContainer>

      <Field
        className={styles.dealInviteModal__input}
        id="inviteDealTeamForm.additionalMessage"
        name="additionalMessage"
        component="textarea"
        placeholder="Message (Optional)"
        data-testid="additional-message"
      />

      <ActionsContainer>
        {renderSecondaryAction && renderSecondaryAction()}
        <SubmitButton
          label={<FormattedMessage id="cta.send-invitation-email" defaultMessage="Send invitation email" />}
          disabled={isSubmitting || invitationEmails.length === 0}
          isSubmitting={isSubmitting}
          data-testid="submit-button"
        />
      </ActionsContainer>
    </Form>
  );
};

export default AddDealTeamMemberForm;
