import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';

export type RemoveComment = {
  templateId: string;
  threadId: string;
  commentId: string;
};

const mutation = graphql(/* GraphQL */`
  mutation RemoveTemplateComment($templateId: ID!, $threadId: String!, $commentId: String!) {
    removeTemplateComment(templateId: $templateId, threadId: $threadId, commentId: $commentId)
  }
`);

export const removeTemplateComment = (options: RemoveComment) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const removeTemplateCommentWithResult = (options: RemoveComment) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.removeTemplateComment.success);
  });
};
