import React from 'react';
import { Table } from '../../../table';

interface Props {
  table: any;
  renderRowSubComponent: any;
  shouldPaginationBeRendered?: boolean;
}

const TeamMembersTable = ({ table, renderRowSubComponent, shouldPaginationBeRendered }: Props) => {
  return <Table striped isStickyHeader table={table} renderRowSubComponent={renderRowSubComponent} />;
};

export default TeamMembersTable;
