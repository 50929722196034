import { Permissions } from '../../../../shared/constants/permissions';
import mutateTeamPermissions from '../../../../utils/mutate-team-permissions';
import filterPermissions from '../../../../utils/filter-permissions';
import { Permission, TeamPermission } from '../../../../store/permissions/permissions.reducer';

interface TeamMemberDeal {
  id: string;
  title: string;
}

interface TeamMemberUser {
  id: string;
  fullName: string;
  companyName: string;
  email: string;
  deals: TeamMemberDeal[];
  invitationId: string | null;
}

interface MemberPermission {
  allowed: boolean;
  permission: {
    key: string;
  };
}

interface ParseTeamMemberParams {
  memberPermissions: MemberPermission[];
  member: TeamMemberUser;
  isAdmin: boolean;
  hasAccessToDeal: boolean;
}

interface ParseInvitationParams {
  invitation: Invitation;
  hasAccessToDeal: boolean;
}

interface Invitation {
  id: string;
  permissions: MemberPermission['permission'][];
  email: string;
  dealIds: string[];
}

export interface ParsedTeamMember {
  id: string;
  fullName: string;
  companyName: string;
  email: string;
  isAdmin: boolean;
  isWhitelisted: boolean;
  permissions: object;
  hasAccessToDeal: boolean;
  dealIds: string[];
  invitationId: string | null;
}

export interface SidebarTeamMember extends ParsedTeamMember {
  permissions: Permission[];
}

interface FilterTeamMembersToDisplayParams {
  teamMember: ParsedTeamMember;
  deal: {
    dealAccessUsers: string[];
    id: string;
  };
  currentDealId: string;
}

export const parseTeamMember = ({
  memberPermissions,
  member,
  isAdmin,
  hasAccessToDeal,
}: ParseTeamMemberParams): ParsedTeamMember => {
  return {
    id: member.id,
    fullName: member.fullName,
    companyName: member.companyName,
    email: member.email,
    isAdmin,
    isWhitelisted: memberPermissions?.some(
      ({ allowed, permission: { key } }) => (allowed || isAdmin) && key === Permissions.Whitelist
    ),
    permissions: mutateTeamPermissions(memberPermissions as TeamPermission[], isAdmin),
    hasAccessToDeal,
    dealIds: member.deals.map(deal => deal.id),
    invitationId: member.invitationId,
  };
};

export const parseInvitation = ({ invitation, hasAccessToDeal }: ParseInvitationParams): ParsedTeamMember => ({
  id: `invitation-${invitation.id}`,
  fullName: '',
  companyName: '',
  email: invitation.email,
  isAdmin: false,
  isWhitelisted: invitation.permissions.some(({ key }) => key === Permissions.Whitelist),
  permissions: invitation.permissions.filter(perm => filterPermissions(perm as Permission, false)),
  hasAccessToDeal,
  dealIds: invitation.dealIds,
  invitationId: invitation.id,
});

export const filterTeamMembersToDisplay = ({
  teamMember: { id: userId, isWhitelisted, invitationId, dealIds },
  deal,
  currentDealId,
}: FilterTeamMembersToDisplayParams) => {
  const isMultipleDealMode = !deal;
  if (isMultipleDealMode) return true;

  const hasSpecificAccess = deal.dealAccessUsers.includes(userId);
  if (hasSpecificAccess) return true;

  const isPendingInvitation = invitationId !== null && dealIds?.includes(currentDealId);

  return isWhitelisted || isPendingInvitation;
};
