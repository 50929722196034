import { ApolloClient } from '@apollo/client';
import { getDealById } from '../../../api/queries';
import { DocumentActionType } from '../../../consts/forms-types';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  success: boolean;
};

export const sendDealToReceiver = ({
  dealId,
  receiverEmail,
  additionalMessage,
  documentId,
  documentAction,
}: {
  dealId: string;
  receiverEmail: string;
  additionalMessage?: string;
  documentId?: string | null;
  documentAction: DocumentActionType;
}) => {
  return function(client: ApolloClient<object>): Promise<ResponsePayloadType> {
    const variables = {
      dealId,
      receiverEmail,
      additionalMessage,
      documentId,
      documentAction,
    };

    const mutation = graphql(/* GraphQL */`
      mutation sendDealToReceiver(
        $dealId: ID!
        $receiverEmail: String!
        $additionalMessage: String
        $documentId: ID
        $documentAction: String
      ) {
        sendDealToReceiver(
          id: $dealId
          email: $receiverEmail
          additionalMessage: $additionalMessage
          documentId: $documentId
          documentAction: $documentAction
          confirm: true
        ) {
          receiverTeam {
            id
          }
        }
      }
    `);

    const refetchQueries = [
      {
        query: getDealById,
        variables: {
          dealId: dealId!,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.sendDealToReceiver.receiverTeam);
  };
};
