import { EditorType } from '../../../components/lease-wizard/lease-editor/utils/lease-editor.types';
import { Revision, UpdateRevisionData } from './types';
import { getDocumentRevisionById } from "../../api/get-document-revision-by-id/get-document-revision-by-id";
import { client } from "../../../api";

class RevisionHistoryAdapter {
  private readonly editor: EditorType;

  constructor(editor: EditorType) {
    this.editor = editor;
  }

  static get pluginName() {
    return 'RevisionHistoryAdapter';
  }

  static get requires() {
    return ['RevisionHistory'];
  }

  async init() {
    const revisionHistory = this.editor.plugins.get('RevisionHistory');
    const revisionHistoryConfig = this.editor.config.get('revisionHistory');

    revisionHistory.adapter = {
      /**
       * getRevision: retrieves revision data for a single revision
       * - this adapter function is called if the revision data sent from our API does NOT have diffData
       * @param revisionId
       */
      getRevision: async ( { revisionId }: any ) => {
        const {
          documentId,
          documentType,
        } = revisionHistoryConfig;

        return await getDocumentRevisionById(revisionId, documentId, documentType)(client)
      },

      updateRevisions: async (revisionsData: UpdateRevisionData[]) => {
        const {
          cloudDocumentVersion,
          save,
          createDocumentRevision,
          documentId,
          documentType,
          canEdit,
          canComment,
        } = revisionHistoryConfig;

        if (!canEdit && !canComment) {
          return;
        }

        const documentData = this.editor.getData();

        const saveDocumentPromise = save({ body: documentData, cloudDocumentVersion });

        const createRevisionsPromises = () => {
          return createDocumentRevision({
            variables: {
              documentId,
              documentType,
              data: revisionsData,
            },
          }).then((rev: any) => {
            return rev.data.updateDocumentRevisions.map((item: any) => {
              let {id: oldId, revisionId: id, ...rest} = item
              return { id, ...rest };
            });
          })
        }
        
        return Promise.allSettled([saveDocumentPromise, createRevisionsPromises()])
          .then(() => {
            return Promise.resolve();
          })
          .catch(() => {
            return Promise.reject();
          });
      },
    };

    const documentRevisions = await this._fetchDocumentRevisions();

    for (const revisionData of documentRevisions) {
      revisionHistory.addRevisionData(revisionData);
    }

    return Promise.resolve();
  }

  async _fetchDocumentRevisions(): Promise<Revision[]> {
    const revisionHistoryConfig = this.editor.config.get('revisionHistory');

    const { getDocumentRevisions } = revisionHistoryConfig;

    return await getDocumentRevisions();
  }
}

export default RevisionHistoryAdapter;
