import React, { createContext, useContext, useState } from 'react';
import _ from 'lodash';

/** 1.0 - this is required because CKE fires and react don't mix well,
 * somehow the state is being mutated and it is likely being mutated
 * within CKE, this hack creates a uniq copy of all new comments
 * accumulates them and updates the react state with a uniq copy
 * @type {*[]}
 */
let accumArray = [];

export const CommentsContext = createContext();
export const UpdateComments = createContext();
export const ThreadRendered = createContext();

export const useCommentContext = () => {
  return useContext(CommentsContext);
};
export const useUpdateComments = () => {
  return useContext(UpdateComments);
};

export const useCommentThreadRendered = () => {
  return useContext(ThreadRendered);
};

export const CommentContextProvider = ({ children, InitialComments = [] }) => {
  const [comments, setComments] = useState(InitialComments);

  const updateComments = newComments => {
    /** read comment 1.0 top of document **/
    accumArray = [...accumArray, ...newComments];
    accumArray = _.uniq(accumArray);
    setComments(accumArray);
  };

  const threadRendered = (threadId, contextComments = []) => {
    if (contextComments.length) {
      const commentsUpdate = contextComments.map(comment => {
        if (comment.threadId === threadId) {
          return { ...comment, threadRendered: true };
        }

        return comment;
      });

      updateComments(commentsUpdate);
    }
  };

  return (
    <CommentsContext.Provider value={comments}>
      <UpdateComments.Provider value={updateComments}>
        <ThreadRendered.Provider value={threadRendered}>{children}</ThreadRendered.Provider>
      </UpdateComments.Provider>
    </CommentsContext.Provider>
  );
};
