import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';

export type ReopenComments = {
  templateId: string;
  threadId: string;
};

const mutation = graphql(/* GraphQL */`
  mutation ReopenTemplateComments($templateId: ID!, $threadId: String!) {
    reopenTemplateComments(templateId: $templateId, threadId: $threadId)
  }
`);

export const reopenTemplateComments = (options: ReopenComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const reopenTemplateCommentsWithResult = (options: ReopenComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.reopenTemplateComments.success);
  });
};
