import ContextPlugin from '@ckeditor/ckeditor5-core/src/contextplugin';
import CustomAnnotationsUIEditing from "./CustomAnnotationsUIEditing";
import CustomAnnotationsUIUI from "./CustomAnnotationsUIUI";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

const getCustomAnnotationsUI = () => {
    class CustomAnnotationsUI extends ContextPlugin {
        static get pluginName() {
            return "CustomAnnotationsUI";
        }

        constructor( ...args ) {
            super( ...args );
            this.set( 'activeAnnotation', null );
        }

        attach() {}

        detach() {}

        setActiveAnnotation() {}
    }

    return CustomAnnotationsUI;
}

export default getCustomAnnotationsUI
