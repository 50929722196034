import { Dropdown, DropdownTogglePeople } from '../../dropdown';
import { FormattedMessage } from 'react-intl';
import React from 'react';

interface ToggleProps {
  totalCount: number;
  selectedCount: number;
  isWhitelisted: boolean;
  id: string;
}

const Toggle = ({ totalCount, selectedCount, isWhitelisted, id }: ToggleProps) => (
  <Dropdown.Toggle id={id} as={DropdownTogglePeople}>
    {isWhitelisted ? (
      <FormattedMessage
        id="phrase.all-deals-selected"
        defaultMessage="All Deals ({totalCount, number})"
        values={{ totalCount }}
      />
    ) : (
      <FormattedMessage
        id="phrase.some-deals-selected"
        defaultMessage="Deals ({selectedCount, number})"
        values={{ selectedCount }}
      />
    )}
  </Dropdown.Toggle>
);

export default Toggle;
