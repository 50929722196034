import React, { useEffect } from 'react';
import { paths, useNavigate } from '../../routing';
import { useAuth } from '../../auth';
import LoadingSpinner from '../../components/loading-spinner';

const AuthRefresh = () => {
  const [{ loggedIn }, { refreshToken }] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const doRefresh = async () => {
      const refreshResult = await refreshToken();
      if (refreshResult?.success) {
        navigate(paths.home, true);
      } else {
        navigate(paths.auth.login, true);
      }
    };
    doRefresh();
  }, [refreshToken, navigate]);

  if (loggedIn) {
    navigate(paths.home, true);
    return null;
  }

  return <LoadingSpinner />;
};

export default AuthRefresh;
