import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentShouldReturnForReceiverReviewAction } from '../actions/lease-document.actions';

export const handleSetDocumentShouldReturnForReceiverReview = (
  state: LeaseDocumentStateType,
  action: SetDocumentShouldReturnForReceiverReviewAction
): LeaseDocumentStateType => ({
  ...state,
  shouldReturnForReceiverReview: action.shouldReturnForReceiverReview,
});
