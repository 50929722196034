import { useQuery } from '@apollo/client';

import { queries } from '.';

export default function usePartyIds(options = {}) {
  const { loading, data } = useQuery(queries.getParties, options);

  const partyIds: { [key: string]: string } = {};
  if (data) {
    // FIXME: the disabled linter rule on the next line is because of a false
    // positive; see https://github.com/facebook/create-react-app/pull/7594
    // eslint-disable-next-line no-unused-vars
    for (const { id, name } of data.parties) {
      partyIds[name] = id;
    }
  }

  return { loading, partyIds };
}
