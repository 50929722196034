import React from 'react';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';

interface Props {
  onClick: () => void;
}

const SendToExternalSigning = ({ onClick }: Props) => {
  return (
    <Button className="mr-2 ml-2" onClick={onClick}>
      <TitleFormattedMessage id="cta.send-signing" defaultMessage="Send for signing" />
    </Button>
  );
};

export default SendToExternalSigning;
