import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';

export type ResolveTemplateComment = {
  threadId: string;
  templateId: string;
};

const mutation = graphql(/* GraphQL */`
  mutation ResolveTemplateComments($templateId: ID!, $threadId: String!) {
    resolveTemplateComments(threadId: $threadId, templateId: $templateId) {
      attributes
      comments {
        ...DocumentCommentFields
      }
      resolvedAt
      resolvedBy
      threadId
    }
  }
`);

export const resolveTemplateCommentsWithResult = (options: ResolveTemplateComment) => (
  client: ApolloClient<object>
): Promise<boolean> => {
  const variables = options;

  return client
    .mutate({ mutation, variables })
    .then(({ data }: Record<any, any>) => data.resolveTemplateComments);
};
