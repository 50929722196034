import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import styles from "../styles.module.scss";
import React from "react";
import { useQuery } from "@apollo/client";
import { queries } from "../../../api";
import { Button } from "react-bootstrap";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import LoadingSpinner from "../../../components/loading-spinner";
import {TitleFormattedMessage} from "../../../components/titleFormattedMessage";

interface TermsOfUseModalProps {
  showTermsOfUseModal: boolean;
  onModalHide: () => void;
  organizationId: string;
}
const TermsOfUseModal = ({
  showTermsOfUseModal,
  onModalHide,
  organizationId,
}: TermsOfUseModalProps) => {
  const { formatMessage } = useIntl();

  const { data: organizationsData, loading: isOrganizationsDataLoading } = useQuery(queries.getOrganizations);

  const currentOrg = organizationsData?.organizations.find((org) => {
    return org.id === organizationId
  })

  if (isOrganizationsDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Modal show={showTermsOfUseModal} onHide={onModalHide} size="lg">
      <div className={styles.termsOfUseModalContent}>
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title>
            <header>
              <FormattedMessage id="title.terms-of-use" defaultMessage="Terms of Use" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-100">
          <div className={styles.termOfUseOrgParsed}>
            {parse(sanitizeHtml(currentOrg?.termsOfUse?.content as string))}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-end">
          <Button variant="primary" className="pl-3 pr-3" onClick={onModalHide}>
            <TitleFormattedMessage id="cta.close" defaultMessage="Close" />
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default TermsOfUseModal;
