import React from 'react';
import styles from './styles.module.scss';
import { Button, Row, InputGroup, FormControl } from 'react-bootstrap';
import { Form, Field, Formik } from 'formik';
import * as yup from 'yup';
import { EMAIL_VALIDATION_REGEX } from '../../../../../../../shared/constants/emailValidation';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { TitleFormattedMessage } from '../../../../../../titleFormattedMessage';
import { ReactComponent as PlusIcon } from '../../../../../../../shared/icons/plus.svg';

interface AddSignerFormProps {
  onSubmit: (email: string) => void;
  isCreatingSigner: boolean;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required()
    .matches(EMAIL_VALIDATION_REGEX, 'Please provide correct email'),
});

const AddSignerForm = ({ onSubmit, isCreatingSigner }: AddSignerFormProps) => {
  return (
    <Formik
      validateOnBlur
      initialValues={{
        email: '',
      }}
      onSubmit={(values, actions) => {
        onSubmit(values.email);
        actions.resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, handleBlur }) => {
        const isTouchedAndInvalid = !!touched.email && !!errors.email && !!values.email;
        return (
          <Form className={styles.wrapper}>
            <Row className="mx-0 w-100">
              <InputGroup>
                <FormControl
                  id="addSignerForm.signerEmail"
                  data-testid="signer-email-field"
                  as={Field}
                  name="email"
                  label={<FormattedMessage id="form.name.fullName" defaultMessage="Add signer" />}
                  type="text"
                  placeholder="Enter signer email"
                  isInvalid={isTouchedAndInvalid}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Row>
            <Row className={classNames(styles.buttons)}>
              <Button
                variant="primary"
                type="submit"
                className={styles['buttons-add']}
                disabled={!values.email || !!errors.email || isCreatingSigner}
              >
                <PlusIcon width={16} height={16} />
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddSignerForm;
