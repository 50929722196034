import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePagination, useSortBy, useTable } from 'react-table';
import Container from 'react-bootstrap/Container';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import { ContentHeader } from '../../components/private-layout';
import { Table } from '../../components/table';
import {
  OrganizationType,
  OrgatizationMembership,
  OrgatizationMembershipType,
  OrgatizationMemberType,
  RequestStatuses,
} from './constants';
import { ReactComponent as TrashIcon } from '../../shared/icons/trashcan-icon.svg';
import { useLeaseDocumentTeamPermissions } from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useTeamInfo } from '../../team-id-context';
import { LeaseDocumentWizardModal } from '../../components/lease-wizard/lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../components/lease-wizard/lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import AddOrganizationModal from './modals/AddOrganizationModal';
import { graphqlErrorsToUiErrors, mutations, queries } from '../../api';
import LoadingSpinner from '../../components/loading-spinner';
import { hasUserTeamOwnedOrganization, organizationsAccessByUser } from '../../api/queries';
import { useNotifications } from '../../notifications';
import ErrorMessage from '../../components/error-message';
import TermsOfUseModal from './modals/TermsOfUseModal';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';
import styles from './styles.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentBody } from '../../components/private-layout/ContentBody';
import classNames from 'classnames';

const Organizations = () => {
  const { formatMessage } = useIntl();
  const apolloClient = useApolloClient();
  const teamIdLookup = useTeamInfo().teamId;
  const [, { error: notifyError }] = useNotifications();
  const [showDeleteMembershipModal, setShowDeleteMembershipModal] = useState(false);
  const [showAddOrganizationModal, setShowAddOrganizationModal] = useState(false);
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [orgRequestSelectedId, setOrgRequestSelectedId] = useState('');

  const { isAdmin } = useLeaseDocumentTeamPermissions({ teamId: teamIdLookup, apolloClient });
  const { data: userData, loading: userDataLoading } = useQuery(queries.getCurrentUser);
  const { error: organizationsDataLoadingError } = useQuery(hasUserTeamOwnedOrganization, {
    skip: !teamIdLookup,
    variables: { teamId: teamIdLookup },
  });
  const [removeOrganizationRequest] = useMutation(mutations.removeOrganizationRequest, {
    refetchQueries: [
      {
        query: organizationsAccessByUser,
      },
    ],
  });

  const handleMembershipModalHide = () => {
    setShowDeleteMembershipModal(false);
    setSelectedRequestId(null);
  };

  const handleMemberManage = (url?: string) => {
    console.log(url);
    if (url) {
      window.location.href = url;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage id="title.deals.title" defaultMessage="Name" />,
        accessor: 'name',
        Cell: ({ cell: { value } }: any) => {
          return <FormattedMessage id="cta.go-to.details" defaultMessage={value.orgName} />;
        },
      },
      {
        Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Type" />,
        accessor: 'memberType',
        Cell: ({ cell: { value } }: any) => {
          return (
            <span className={styles.uppercasedValue}>
              <FormattedMessage
                id="cta.memberType"
                defaultMessage={OrgatizationMembership[value.toLocaleLowerCase() as OrgatizationMembershipType]}
              />
            </span>
          );
        },
      },
      {
        Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Member ID" />,
        accessor: 'memberId',
      },
      {
        Header: <FormattedMessage id="title.organizations.expiresAt" defaultMessage="Expiry Date" />,
        accessor: 'expiresAt',
        Cell: ({ cell: { value } }: any) => {
          return <span className="text-nowrap">{value && dayjs(value).format('ll')}</span>;
        },
      },
      {
        Header: <FormattedMessage id="title.deals.deal-status" defaultMessage="Status" />,
        accessor: 'status',
        Cell: ({
          row: {
            original: { status, name, memberType, portalUrl },
          },
        }: any) => {
          const handleMembershipRemove = () => {
            setShowDeleteMembershipModal(true);
            setSelectedRequestId(name.requestId);
          };

          const canMembershipBeRemoved =
            isAdmin || OrgatizationMembership[memberType as OrgatizationMembershipType] === OrgatizationMembership.user;

          return (
            <div className={styles.deleteMembershipContainer}>
              <span className={styles.uppercasedValue}>
                <FormattedMessage id="cta.go-to.details" defaultMessage={status} />
              </span>
              {status === 'Paid' ? (
                <Button
                  variant="primary"
                  className={styles['add-organization-button']}
                  onClick={() => handleMemberManage(portalUrl)}
                >
                  <TitleFormattedMessage id="cta.manage" defaultMessage="Manage" />
                </Button>
              ) : (
                canMembershipBeRemoved && (
                  <span>
                    <TrashIcon onClick={handleMembershipRemove} className={styles['icon-delete']} />
                  </span>
                )
              )}
            </div>
          );
        },
      },
    ],
    [isAdmin]
  );

  const { loading: isOrganizationsAccessByUserLoading, data: organizationsAccessByUserData } = useQuery(
    organizationsAccessByUser
  );

  const data = useMemo(() => {
    const userAndCurrentTemaOrgsData = organizationsAccessByUserData?.organizationsAccessByUser.filter(
      request =>
        request.requesterType.toLowerCase() === OrgatizationMemberType.user || request.requesterId === teamIdLookup
    );

    return [...(userAndCurrentTemaOrgsData || [])].map(request => ({
      requestId: request.id,
      name: { orgName: request.organization.name, requestId: request.id },
      memberType: request.requesterType.toLocaleLowerCase(),
      memberId: request.membershipId,
      expiresAt: request.expiresAt,
      portalUrl: request.portalUrl,
      status:
        request.isPaid && request.status !== RequestStatuses.EXPIRED
          ? 'Paid'
          : request.status === 'approved'
          ? 'active'
          : request.status,
    }));
  }, [organizationsAccessByUserData, teamIdLookup]);

  const handleMembershipRemove = async () => {
    try {
      await removeOrganizationRequest({ variables: { organizationRequestId: selectedRequestId! } });
    } catch (error) {
      notifyError({
        message: {
          id: 'document.action.error',
          defaultMessage: error,
        },
      });
    } finally {
      handleMembershipModalHide();
    }
  };

  const removeMembershipModalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: handleMembershipModalHide,
      label: 'Cancel',
      theme: 'outline-secondary',
    },
    {
      onClick: handleMembershipRemove,
      label: 'Remove',
      theme: 'secondary',
      className: styles['cancel-button'],
    },
  ];

  //@ts-ignore
  const organizationsTable = useTable({ data, columns }, useSortBy, usePagination);

  if (userDataLoading || isOrganizationsAccessByUserLoading) {
    return <LoadingSpinner />;
  }

  if (organizationsDataLoadingError) {
    const errors = graphqlErrorsToUiErrors(organizationsDataLoadingError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  return (
    <>
      <section className={styles.settingsContainer}>
        <Helmet title={formatMessage({ id: 'title.organizations', defaultMessage: 'Organizations' })} />
        <ContentHeader>
          <div className={classNames(styles.settingsContainer__title, 'ml-3')} role={'heading'}>
            <FormattedMessage id="title.organizations" defaultMessage="Organizations" />
          </div>
        </ContentHeader>
        <ContentBody>
          <Row className={styles.settingsContainer__addOrgButton}>
            <Col className={'mr-3 ml-3 non-mobile'}>
              <Button
                variant="secondary"
                className={styles['add-organization-button']}
                onClick={() => {
                  setShowAddOrganizationModal(true);
                }}
              >
                <TitleFormattedMessage id="cta.add-organization" defaultMessage="Add Organization" />
              </Button>
            </Col>
            <Col className={'mr-3 ml-3 mobile'}>
              <Button
                variant="secondary"
                className="mobileHeaderBtn"
                onClick={() => {
                  setShowAddOrganizationModal(true);
                }}
              >
                <TitleFormattedMessage id="cta.add-organization" defaultMessage="Add Organization" />
              </Button>
            </Col>
          </Row>
          <Row className={styles.contentTable}>
            <Col className={'mr-3 ml-3'}>
              <Table striped isStickyHeader table={organizationsTable} />
            </Col>
          </Row>
        </ContentBody>
      </section>
      <LeaseDocumentWizardModal
        size="lg"
        show={showDeleteMembershipModal}
        onHide={handleMembershipModalHide}
        modalCTAs={removeMembershipModalCTAs}
        title="Remove Organization"
      >
        If necessary, once removed you can submit another request to re-gain access.
      </LeaseDocumentWizardModal>
      <AddOrganizationModal
        showAddOrganizationModal={showAddOrganizationModal}
        onModalHide={() => {
          setShowAddOrganizationModal(false);
        }}
        isAdmin={isAdmin}
        userId={userData!.currentUser!.id}
        organizationsAccessByUserData={
          (organizationsAccessByUserData?.organizationsAccessByUser! as unknown) as OrganizationType[]
        }
        setShowTermsOfUseModal={setShowTermsOfUseModal}
        setOrgRequestSelectedId={setOrgRequestSelectedId}
        orgRequestSelectedId={orgRequestSelectedId}
      ></AddOrganizationModal>
      <TermsOfUseModal
        showTermsOfUseModal={showTermsOfUseModal}
        onModalHide={() => {
          setShowTermsOfUseModal(false);
        }}
        organizationId={orgRequestSelectedId}
      />
    </>
  );
};

export default Organizations;
