import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'classnames';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useTable, useSortBy, usePagination } from 'react-table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { graphqlErrorsToUiErrors, mutations, queries } from '../../api';
import { getDealsForTeam } from '../../api/queries';
import slugify from '../../utils/slugify';
import { useTeamInfo } from '../../team-id-context';
import { useAuth, usePermissions } from '../../auth';
import { useUserRestrictions } from '../../auth/user-restrictions';
import { Dropdown, DropdownToggle, DropdownBody, DropdownItem } from '../../components/dropdown';
import SearchInput from '../../components/search-input';
import ErrorMessage from '../../components/error-message';
import PrivateLayout, { ContentHeader } from '../../components/private-layout';
import { Table } from '../../components/table';
import { Checkbox } from '../../components/checkbox-or-radio-field';
import LoadingSpinner from '../../components/loading-spinner';
import { dealStatusMessages, statusOptions } from './constants';
import PendingInvitesModal from './pending-deals-modals/PendingInvitesModal';
import { UserHasUnassignedDealsModal } from '../../components/shared/modals';
import { Permissions } from '../../shared/constants/permissions';
import { DealStatus } from './components/statusDropdown/statusDropdown';
import AddNewDealModal from './modals/AddNewDealModal';
import SubscriptionRequiredModal from '../modals/subscriptionModal/SubscriptionRequiredModal';
import { fillPlaceholders, paths } from '../../routing';
import { defaultDealStatuses } from './constants/DealConstants';
import { dealTableColumns } from './components/deal-table/dealTableColumns';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';
import { ContentBody } from '../../components/private-layout/ContentBody';
import { ReactComponent as FilterIcon } from '../../shared/icons/filter-icon.svg';
import styles from './styles.module.scss';
import FilterSidebar from './components/filterSidebar';
import { MobileTable } from '../../components/mobile-layout/MobileTable';
import { DealData, MobileDealItem } from '../../components/mobile-layout/MobileDealItem';

const DealsLanding = () => {
  const { formatMessage } = useIntl();
  const { teamId } = useTeamInfo();
  const { dealToken } = useParams();
  const navigate = useNavigate();
  const { isAdmin, hasPermission } = usePermissions();
  const { canCreateDeal } = useUserRestrictions();
  const canEdit = hasPermission(Permissions.Edit);
  const [
    {
      user: { dealInvitations, pendingDeals },
    },
  ] = useAuth();
  const currentPendingDeals = pendingDeals;
  const [isPendingInvitesModalOpen, setPendingInvitesModalOpen] = useState(
    currentPendingDeals ? !!currentPendingDeals.length : false
  );
  const [isUnassignedDealModalOpen, setIsUnassignedDealModalOpen] = useState(true);
  const [showAddDealModal, setShowAddDealModal] = useState(false);
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [sorting, setSorting] = useState('');
  const [updateDealDetails] = useMutation(mutations.updateDealDetails);

  const { loading: dealsDataLoading, data: dealsData, error: graphqlError } = useQuery(getDealsForTeam, {
    variables: {
      teamId,
    },
  });

  const { loading: loadingUser, data: userData, error, refetch: refetchUser } = useQuery(queries.getCurrentUser);

  const handleUpdateDealDetails = useCallback(
    async (dealId: string, newStatus: DealStatus) => {
      await updateDealDetails({
        variables: {
          dealId,
          dealDetails: {
            status: newStatus,
          },
        },
        refetchQueries: [
          {
            query: getDealsForTeam,
            variables: {
              teamId,
            },
          },
        ],
      });
    },
    [teamId, updateDealDetails]
  );

  const handleAddNewDealButton = canCreateDeal
    ? () => {
        setShowAddDealModal(true);
      }
    : () => {
        setSubscriptionRequiredModal(true);
      };

  const userDealNotifications = useMemo(() => {
      return userData?.currentUser?.communicationPreferences.deal_notifications
    }, [userData?.currentUser?.communicationPreferences.deal_notifications]
  )

  const refreshUserData = useCallback(async () => {
    await refetchUser()
  }, [refetchUser])

  const columns = useMemo(() => dealTableColumns(
    {
      canEdit,
      handleUpdateDealDetails,
      userDealNotifications: userDealNotifications,
      refetchUser: refreshUserData,
    }),
    [canEdit, handleUpdateDealDetails, userDealNotifications, refreshUserData]
  );

  const [filterText, setFilterText] = useState('');
  const [filterCreators, setFilterCreators] = useState(new Set());
  const [filterStatuses, setFilterStatuses] = useState(new Set(defaultDealStatuses));

  // Collect all unique city names for the filters menu

  const allCreators = useMemo(() => {
    if (dealsDataLoading || !dealsData || loadingUser) {
      return [];
    }

    // @Todo update the typings properly
    const sortedCreatorsData = dealsData.dealsForTeam
      .map((deal: { team?: { id: string; name: string } }) => (deal.team?.id !== teamId ? deal.team?.name : ''))
      .filter((value: any, index: number, self: any) => self.indexOf(value) === index && !!value)
      .sort((a: any, b: any) => a.localeCompare(b));

    const userTeam = dealsData.dealsForTeam.find(
      (deal: { team?: { id: string; name: string } }) => deal.team?.id === teamId
    );

    if (userTeam) {
      sortedCreatorsData.unshift(`${userTeam.team?.name} (Your Team)`);
    }

    return sortedCreatorsData;
  }, [dealsDataLoading, dealsData, loadingUser, teamId]);

  useEffect(() => {
    setFilterCreators(new Set(allCreators));
  }, [allCreators, setFilterCreators, dealsData]);

  useEffect(() => {
    if (!dealInvitations) return;
    const foundInvitation = dealInvitations.find(
      (dealInvitation: { token: string }) => dealInvitation.token === dealToken
    );

    if (!foundInvitation) {
      navigate(fillPlaceholders(paths.deals.landing, { teamId }));
    }
  }, [dealInvitations, dealToken, navigate, teamId]);

  const onDealDetail = (dealId: string) => {
    const dealDeatilsUrl = fillPlaceholders(paths.deals.detail, {
      teamId,
      dealId: dealId,
    });
    navigate(dealDeatilsUrl);
  };

  // Filter and mutate data for the table
  const data = useMemo(() => {
    if (dealsDataLoading || !dealsData || loadingUser) return [];

    const filteredData = dealsData.dealsForTeam
      .filter((deal: any) => {
        if (filterText) {
          // Filter out if no address fields match the query
          const filterTextLower = filterText.toLocaleLowerCase();
          const searchValues = filterTextLower.split(' ');
          let searchResults: string[] = [];
          searchValues.forEach(obj => {
            if (deal.title.toLocaleLowerCase().includes(obj)) {
              searchResults.push(deal.title.toLocaleLowerCase());
            }
          });

          if (searchResults.length !== searchValues.length) return false;
        }

        if (filterCreators.size && filterCreators.size < allCreators.length) {
          if (!filterCreators.has(deal.team?.name) && !filterCreators.has(`${deal.team?.name} (Your Team)`))
            return false;
        }

        if (filterStatuses.size && filterStatuses.size < statusOptions.length) {
          // Filter out if the status isn't one of those chosen
          if (!filterStatuses.has(deal.status)) return false;
        }

        return true;
      })
      .map((deal: any) => ({
        id: deal.id,
        currentUserRole: deal.currentUserRole,
        title: deal.title,
        creator: deal.team?.name,
        status: deal.status,
        updatedAt: deal.updatedAt,
        // TOOD: Add a value here
        notify: deal.id ,
        onRowClick: () => {
          const dealDeatilsUrl = fillPlaceholders(paths.deals.detail, {
            teamId,
            dealId: deal.id,
          });
          navigate(dealDeatilsUrl);
        },
      }));

    filteredData.sort((a: any, b: any) => {
      switch (sorting) {
        case 'ascName':
          return a.title.localeCompare(b.title);
        case 'descName':
          return b.title.localeCompare(a.title);
        case 'statusInProgress':
          return a.status.localeCompare(b.status);
        case 'statusArchived':
          return b.status.localeCompare(a.status);
        case 'dateToLatest':
          return b?.updatedAt?.localeCompare(a?.updatedAt);
        case 'dateToNewest':
          return a?.updatedAt?.localeCompare(b?.updatedAt);
        case 'accessMyTeam':
          return a.creator.localeCompare(b.creator);
        case 'accessPrivate':
          return b.creator.localeCompare(a.creator);

        default:
          return a?.updatedAt && b?.updatedAt && b?.updatedAt?.localeCompare(a?.updatedAt);
      }
    });

    return filteredData;
  }, [
    dealsDataLoading,
    dealsData,
    filterText,
    filterCreators,
    sorting,
    allCreators.length,
    filterStatuses,
    teamId,
    navigate,
    loadingUser,
  ]);

  const sortBy = useMemo(() => {
    switch (sorting) {
      case 'ascName':
        return {
          id: 'title',
          desc: false,
        };
      case 'descName':
        return {
          id: 'title',
          desc: true,
        };
      case 'statusInProgress':
        return {
          id: 'status',
          desc: false,
        };
      case 'statusArchived':
        return {
          id: 'status',
          desc: true,
        };
      case 'dateToLatest':
        return {
          id: 'updatedAt',
          desc: false,
        };
      case 'dateToNewest':
        return {
          id: 'updatedAt',
          desc: true,
        };
      case 'accessMyTeam':
        return {
          id: 'creator',
          desc: false,
        };
      case 'accessPrivate':
        return {
          id: 'creator',
          desc: true,
        };

      default:
        return {
          id: 'updatedAt',
          desc: false,
        };
    }
  }, [sorting]);

  const table = useTable(
    {
      data,
      columns,
      initialState: {
        hiddenColumns: ['dealId'],
        sortBy: [sortBy],
      },
    },
    useSortBy,
    usePagination
  );

  if (dealsDataLoading || loadingUser) {
    return <LoadingSpinner />;
  }

  if (!dealsData) {
    const errors = graphqlErrorsToUiErrors(graphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const foundInvitation = dealInvitations
    ? dealInvitations.find((dealInvitation: { token: string }) => dealInvitation.token === dealToken)
    : null;

  return (
    <PrivateLayout>
      <Helmet title={formatMessage({ id: 'title.deals', defaultMessage: 'Deals' })} />
      <ContentHeader>
        <div className={styles.dealPageHeader}>
          <div data-testid="deals-header" role={'heading'} className={'ml-3'}>
            <FormattedMessage id="title.deals" defaultMessage="Deals" />
          </div>
          {!!currentPendingDeals.length && (
            <Button
              /*
                //@ts-ignore */
              variant="btn btn-outline-primary shadow-none"
              className={styles.dealPageHeader__pendingButton}
              onClick={() => setPendingInvitesModalOpen(true)}
            >
              <TitleFormattedMessage
                id="lease-document-wizard-modal.lease-confirmation-button-cancel"
                defaultMessage={`Pending Invites (${currentPendingDeals.length})`}
              />
            </Button>
          )}
        </div>
      </ContentHeader>

      <ContentBody>
        <Row className="mb-3">
          <Col className={'mx-3 d-flex justify-content-between non-mobile-flex'}>
            <div className={'d-flex flex-fill'}>
              <SearchInput
                className="mr-3"
                value={filterText}
                onChange={(event: any) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'deals.filter.title',
                  defaultMessage: 'Search Deal Name',
                })}
                ariaLabel={formatMessage({
                  id: 'deals.filter.name',
                  defaultMessage: 'Name',
                })}
              />

              <div className={'mr-3'}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-filter-creator"
                    data-testid="dropdown-filter-creator"
                    as={DropdownToggle}
                    /*
                  // @ts-ignore */
                    className="w-100 overflow-hidden"
                  >
                    <FormattedMessage
                      id="deals.filter.creator.label-readout"
                      defaultMessage={`{filterCount, select,
                      0 {Creator (all)}
                      1 {Creator ({creator})}
                      other {{filterCount} Teams}
                    }`}
                      values={{
                        filterCount: filterCreators.size === allCreators.length ? 0 : filterCreators.size,
                        creator: filterCreators.size ? filterCreators.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropdownItem highlightable>
                      <Checkbox
                        checked={filterCreators.size === allCreators.length}
                        indeterminate={filterCreators.size > 0 && filterCreators.size < allCreators.length}
                        id="dropdown-filter-creators-_all"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.currentTarget;
                          if (checkbox.checked) {
                            setFilterCreators(new Set(allCreators));
                          } else {
                            setFilterCreators(new Set());
                          }
                        }}
                        label={formatMessage({
                          id: 'deals.filter.creator.all-creators',
                          defaultMessage: 'All Creators',
                        })}
                      />
                    </DropdownItem>
                    <DropdownBody>
                      {allCreators.map((creator: any) => (
                        <DropdownItem key={creator} highlightable>
                          <Checkbox
                            key={creator}
                            value={creator}
                            checked={filterCreators.has(creator)}
                            id={`dropdown-filter-cities-${slugify(creator)}`}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterCreators);
                              if (checkbox.checked) newSet.add(checkbox.value);
                              else newSet.delete(checkbox.value);
                              setFilterCreators(newSet);
                            }}
                            label={creator}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className={'mr-3'}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-filter-status"
                    data-testid="dropdown-filter-status"
                    as={DropdownToggle}
                    /*
                  // @ts-ignore */
                    className="w-100"
                  >
                    <FormattedMessage
                      id="deals.filter.status.label-readout"
                      defaultMessage={`{filterCount, select,
                      0 {Status}
                      all {Status}
                      1 {Status ({status, select,
                           in_progress {In Progress}
                           completed {Completed}
                           archived {Archived}
                           lost {Lost}
                           other {{status}}
                      })}
                      other {{filterCount} Statuses}
                    }`}
                      values={{
                        filterCount: filterStatuses.size === statusOptions.length ? 'all' : filterStatuses.size,
                        status: filterStatuses.size ? filterStatuses.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    <DropdownItem highlightable>
                      <Checkbox
                        checked={filterStatuses.size === statusOptions.length}
                        indeterminate={filterStatuses.size > 0 && filterStatuses.size < statusOptions.length}
                        id="dropdown-filter-statuses-_all"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.currentTarget;
                          if (checkbox.checked) {
                            setFilterStatuses(new Set(Object.keys(dealStatusMessages)));
                          } else {
                            setFilterStatuses(new Set());
                          }
                        }}
                        label={formatMessage({
                          id: 'deals.filter.status.all-statuses',
                          defaultMessage: 'All Statuses',
                        })}
                      />
                    </DropdownItem>
                    <DropdownBody>
                      {statusOptions.map(({ value: status, label }) => (
                        <DropdownItem key={status} highlightable>
                          <Checkbox
                            key={status}
                            value={status}
                            checked={filterStatuses.has(status)}
                            id={`dropdown-filter-statuses-${slugify(status)}`}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterStatuses);
                              if (checkbox.checked) newSet.add(checkbox.value);
                              else newSet.delete(checkbox.value);
                              setFilterStatuses(newSet);
                            }}
                            label={<FormattedMessage {...label} />}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {(isAdmin || canEdit) && (
              <Button className="btn btn-secondary" onClick={handleAddNewDealButton} data-testid="button-add-new-deal">
                <TitleFormattedMessage id="deals.add" defaultMessage="Add new deal" />
              </Button>
            )}
          </Col>
          <Col className={'mx-3 d-flex flex-column justify-content-between mobile-flex'}>
            {(isAdmin || canEdit) && (
              <Button
                className={clsx('btn btn-secondary', styles.mobileHeaderBtn)}
                onClick={handleAddNewDealButton}
                data-testid="button-add-new-deal-mobile"
              >
                <TitleFormattedMessage id="deals.add" defaultMessage="Add new deal" />
              </Button>
            )}
            <div className={'d-flex flex-fill mt-3'}>
              <SearchInput
                iconClassName={styles.mobileSearch__icon}
                inputClassName={styles.mobileSearch}
                className={styles.mobileSearch__container}
                value={filterText}
                onChange={(event: any) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'deals.filter.title',
                  defaultMessage: 'Search Deal Name',
                })}
                ariaLabel={formatMessage({
                  id: 'deals.filter.name',
                  defaultMessage: 'Name',
                })}
              />

              <Button className="filterBtn" onClick={() => setShowFilter(true)}>
                <FilterIcon width={24} height={24} />
              </Button>
            </div>
          </Col>
        </Row>

        <Row className={styles.contentTable}>
          <Col className={'ml-3 mr-3'}>
            <div>
              {data.length ? (
                <>
                  <div className="non-mobile">
                    <Table striped isStickyHeader table={table} />
                  </div>
                  <div className="mobile">
                    <MobileTable>
                      {data?.map((deal: DealData) => (
                        <MobileDealItem
                          key={`mobile-${deal.id}`}
                          deal={deal}
                          handleUpdateDealDetails={handleUpdateDealDetails}
                          onClick={() => onDealDetail(deal.id)}
                        />
                      ))}
                    </MobileTable>
                  </div>
                </>
              ) : (
                <>
                  {filterText ||
                  filterCreators.size !== allCreators.length ||
                  filterStatuses.size !== defaultDealStatuses.size ? (
                    <p className="lead text-center">
                      <FormattedMessage id="phrase.no-results" defaultMessage="No results match your filters." />
                    </p>
                  ) : (
                    <div className="text-center">
                      <h2>
                        <FormattedMessage id="phrase.deals.no-deals" defaultMessage="No Deals" />
                      </h2>
                      <p className="lead">
                        <FormattedMessage id="phrase.deals.empty" defaultMessage="Your deals will appear here." />
                      </p>
                      {canEdit && (
                        <Button
                          variant="outline-secondary"
                          className="mt-2"
                          data-testid="button-add-new-deal"
                          onClick={handleAddNewDealButton}
                        >
                          <TitleFormattedMessage id="card.deals.cta-first" defaultMessage="Add First Deal" />
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </ContentBody>
      <PendingInvitesModal
        isOpen={isPendingInvitesModalOpen}
        onClose={() => setPendingInvitesModalOpen(false)}
        currentPendingDeals={currentPendingDeals}
        pendingInvites={dealInvitations}
        showSubscriptionRequiredModal={() => {
          setSubscriptionRequiredModal(true);
        }}
      />
      {foundInvitation && dealToken && isUnassignedDealModalOpen && (
        <UserHasUnassignedDealsModal
          handleHide={() => {
            setIsUnassignedDealModalOpen(false);
            navigate(fillPlaceholders(paths.deals.landing, { teamId }));
          }}
          showSubscriptionRequiredModal={() => {
            setSubscriptionRequiredModal(true);
          }}
          onSuccess={() => {
            setIsUnassignedDealModalOpen(false);
            navigate(fillPlaceholders(paths.deals.landing, { teamId }));
          }}
          invitation={foundInvitation}
        />
      )}
      <AddNewDealModal
        showAddDealModal={showAddDealModal}
        onModalHide={() => {
          setShowAddDealModal(false);
        }}
      />
      <SubscriptionRequiredModal
        isOpen={showSubscriptionRequiredModal}
        onClose={() => setSubscriptionRequiredModal(false)}
      />
      <FilterSidebar
        isOpen={showFilter}
        filterCreators={filterCreators}
        allCreators={allCreators}
        filterStatuses={filterStatuses}
        onClose={() => setShowFilter(!showFilter)}
        setSorting={setSorting}
        setFilterCreators={setFilterCreators}
        setFilterStatuses={setFilterStatuses}
      />
    </PrivateLayout>
  );
};

export default DealsLanding;
