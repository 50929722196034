import React from 'react';
import { ModalActionsEnum, ModalActionTitle } from '../../../../../document-table/DocumentTableConstants';
import { LeaseDocumentWizardActionModal } from '../../../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/LeaseDocumentWizardActionModal';

interface Props {
  show: boolean;
  onHide: () => void;
  isDealShared: boolean;
  handler: () => void;
  isLoading: boolean;
  suggestionsAlert: { title: string; text: string } | null;
  optionalMessageInputRef: any;
  teamId: string;
  showCounterparty?: boolean;
  counterpartyName?: string;
  counterpartyEmail?: string;
}

const SendModal = ({
  show,
  onHide,
  isDealShared,
  handler,
  isLoading,
  suggestionsAlert,
  optionalMessageInputRef,
  teamId,
  showCounterparty,
  counterpartyName,
  counterpartyEmail,
}: Props) => {
  return (
    <LeaseDocumentWizardActionModal
      show={show}
      onHide={onHide}
      isDealShared={isDealShared}
      handler={handler}
      isPossessionPending={isLoading}
      title={ModalActionTitle.SEND}
      activeModal={ModalActionsEnum.SEND}
      suggestionsAlert={suggestionsAlert}
      showCounterparty={showCounterparty}
      counterpartyName={counterpartyName} 
      counterpartyEmail={counterpartyEmail} 
      optionalMessageInputRef={optionalMessageInputRef}
      teamId={teamId}
    />
  );
};

export default SendModal;
