import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { useQuery, useMutation } from '@apollo/client';
import { queries, mutations, graphqlErrorsToUiErrors } from '../api';
import { useNotifications } from '../notifications';
import { get as getLatestTeamId } from '../utils/latest-team-id';
import NotFound from './not-found';
import ErrorMessage from '../components/error-message';
import { ContentHeader } from '../components/private-layout';
import { ProfileCard, CardRow } from '../components/card';
import LoadingSpinner from '../components/loading-spinner';
import ChangeNameForm from '../forms/changeNameForm';
import { SubscriptionDetailsCard } from '../components/card/SubscriptionDetailsCard';
import styles from '../components/card/styles.module.scss';
import dayjs from 'dayjs';
import PropdocsModal from '../components/modal/PropdocsModal';
import { Button } from 'react-bootstrap';
import { sendForgotPasswordEmail } from '../api/auth';
import { DealNotificationsCard } from '../components/card/DealNotificationsCard';
import { updateUserCommunicationPreference } from '../api/mutations';

const Profile = () => {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [, { success: notifySuccess, error: notifyError }] = useNotifications();
  const [updateUser] = useMutation(mutations.updateUser, {
    onCompleted: data => {
      const success = data!.updateUser!.success;
      if (!success) {
        notifyError({
          message: {
            id: 'phrase.name-change.error',
            defaultMessage: 'There was an error updating your name. Try again later.',
          },
        });
      } else {
        notifySuccess({ message: { id: 'phrase.name-change.success', defaultMessage: 'Your name has been updated.' } });
      }
      setOpenModal(null);
    },
  });
  const [updateCommunicationPreferences] = useMutation(updateUserCommunicationPreference);

  const { loading, data, error, refetch: refetchUser } = useQuery(queries.getCurrentUser);
  const { loading: portalDataLoading, data: portalData, error: portalDataError } = useQuery(queries.getPortalUrl);

  if (loading || portalDataLoading) {
    return <LoadingSpinner />;
  }

  if (!data || !portalData) {
    const errors = graphqlErrorsToUiErrors(error) || graphqlErrorsToUiErrors(portalDataError);
    console.warn('ERROR', errors);
    if (errors._general?.id === 'api.error.not-found') {
      return <NotFound />;
    }
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const user = data.currentUser!;
  const portalUrl = portalData!.currentUser!.portalUrl!;
  const teamId = getLatestTeamId(user.id);
  
  const updateName = async ({ fullName, companyName }: { fullName: string; companyName: string }) => {
    try {
      await updateUser({
        variables: {
          fullName,
        },
        update: (store, { data }) => {
          store.writeQuery({
            query: queries.getCurrentUser,
            // @ts-ignore
            data,
          });
        },
        refetchQueries: [{ query: queries.getTeamById, variables: { teamId } }],
      });
    } catch (error) {
      notifyError({ message: error });
    }
  };

  return (
    <section>
      <Helmet title={formatMessage({ id: 'title.account', defaultMessage: 'Account' })} />
      <ContentHeader>
        <div className={'ml-3'}>
          <FormattedMessage id="title.account" defaultMessage="Account" />
        </div>
        <Container />
      </ContentHeader>
      <Container className="mt-5 d-flex" fluid>
        <ProfileCard name={user.fullName}>
          <CardRow onClick={() => setOpenModal('names')} data-testid="full-name-profile-button">
            <div>
              <p className={styles.descriptionTitle}>
                <FormattedMessage id="title.fullName" defaultMessage="Full Name" />
              </p>
              <p>{user.fullName}</p>
            </div>
          </CardRow>
          <CardRow onClick={() => setOpenModal('password')} data-testid="password-profile-button">
            <div>
              <p className={styles.descriptionTitle}>
                <FormattedMessage id="title.password" defaultMessage="Password" />
              </p>
              <p>********</p>
            </div>
          </CardRow>
        </ProfileCard>
        <SubscriptionDetailsCard
          subscriptionType={user.subscriptionName!}
          dealLimit={user.dealLimit!}
          dealsCreated={user.dealCount}
          upgradeLink={portalUrl}
          renewalDate={`${dayjs(user.subscriptionExpiresAt).format('LL')}`}
        />
        <DealNotificationsCard
          communicationPreferences={user.communicationPreferences}
          updateCommunicationPreferences={updateCommunicationPreferences}
          refetchUser={refetchUser}
        />
      </Container>
      <PropdocsModal
        show={openModal === 'password'}
        setShow={() => setOpenModal(null)}
        size="md"
        title={<FormattedMessage id="title.change-password" defaultMessage="Change Password?" />}
      >
        <div>
          <div data-testid="change-password-modal-content" className={styles.passwordModal__body}>
            We will send a link to your email to update your password
          </div>
          <div className={styles.passwordModal__actions}>
            <Button
              data-testid="send-password-link"
              variant="secondary"
              onClick={() => {
                setOpenModal(null);
                sendForgotPasswordEmail({ email: user.email }).then(() => setOpenModal('confirm'));
              }}
            >
              <FormattedMessage id="cta.send-update-link" defaultMessage="Send Update Link" />
            </Button>
          </div>
        </div>
      </PropdocsModal>
      <PropdocsModal
        show={openModal === 'confirm'}
        setShow={() => setOpenModal(null)}
        size="md"
        title={<FormattedMessage id="title.update-link-sent" defaultMessage="Update Link Sent" />}
      >
        <div>
          <div className={styles.passwordModal__body}>
            <p data-testid="update-link-sent-modal-content">
              We've sent an update link to {user.email}.<br /> Click on the link in the email to update your password.
            </p>
            <p>
              Didn't receive an email? Check your spam folder or{' '}
              <a
                href="javascript:void(0)"
                data-testid="resend-reset-email"
                onClick={e => {
                  e.preventDefault();
                  setOpenModal(null);
                  sendForgotPasswordEmail({ email: user.email }).then(() => setOpenModal('confirm'));
                }}
              >
                resend the email
              </a>
            </p>
          </div>
        </div>
      </PropdocsModal>
      <Modal show={openModal === 'names'} onHide={() => setOpenModal(null)} size="lg">
        <Modal.Header closeButton>
          <div className="d-flex justify-content-center">
            <Modal.Title>Change Name</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="p-5">
          <ChangeNameForm currentUser={user} onSave={updateName} onCancel={() => setOpenModal(null)} />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Profile;
