import { ApolloClient, gql } from '@apollo/client';
import { LeaseDocumentCommentPayloadType, } from '../../../components/lease-wizard/lease-document.types';
import { transformCommentsPayload } from '../../transform-comments-payload/transform-comments-payload.util';

const query = gql`
  fragment DocumentCommentFields on CkeDocumentComment {
    id
    attributes
    authorId
    commentId
    content
    createdAt
    isPublic
    threadId
    updatedAt
  }

  query getDocumentCommentsByDocument($documentId: ID!, $threadId: String) {
    getDocumentCommentsByDocument(documentId: $documentId, threadId: $threadId) {
      attributes
      comments {
        ...DocumentCommentFields
      }
      resolvedBy
      resolvedAt
      threadId
    }
  }
`;

export const getCommentsForLeaseDocument = (documentId: string, threadId: string) => (
  client: ApolloClient<object>
): Promise<any> => {
  const variables = {
    documentId,
    threadId,
  };

  return client.query({ query, variables }).then(({ data }: Record<any, any>) => {
    const comments: LeaseDocumentCommentPayloadType[] = data.getDocumentCommentsByDocument;
    return transformCommentsPayload(comments);
  });
};

export const getCommentsForLeaseDocumentSection = (documentId: string, threadId: string) => (
  client: ApolloClient<object>
): Promise<any> => {
  const variables = {
    documentId,
    threadId,
  };

  return client.query({ query, variables }).then(({ data }: Record<any, any>) => {
    return data.getDocumentCommentsByDocument;
  });
};
