import React, { ReactNode } from 'react';
import SkipLink from '../skip-link';
import Nav from './nav';
import SubNav from './sub-nav';
import ContentHeader from './content-header';
import styles from './styles.module.scss';

interface PrivateLayoutProps {
  children: ReactNode;
  subNavElement?: ReactNode;
  shouldSubNavBeTogglable?: boolean;
  shouldScrollBeDisabled?: boolean;
}

const PrivateLayoutContainer = ({
  children,
  subNavElement,
  shouldSubNavBeTogglable = true,
  shouldScrollBeDisabled,
}: PrivateLayoutProps) => {
  return (
    <>
      <PrivateLayout
        shouldSubNavBeTogglable={shouldSubNavBeTogglable}
        subNavElement={subNavElement}
        shouldScrollBeDisabled={shouldScrollBeDisabled}
      >
        {children}
      </PrivateLayout>
    </>
  );
};

const PrivateLayout = ({
  children,
  subNavElement,
  shouldSubNavBeTogglable,
  shouldScrollBeDisabled,
}: PrivateLayoutProps) => (
  <div className={styles.root}>
    <SkipLink href="#main-content" />
    <nav className={styles.mobileNav}>
      <Nav />
      {subNavElement && <SubNav shouldSubNavBeTogglable={shouldSubNavBeTogglable}>{subNavElement}</SubNav>}
    </nav>
    <main id="main-content" className={shouldScrollBeDisabled ? styles.unscrollableContainer : ''}>
      {children}
    </main>
  </div>
);

export default PrivateLayoutContainer;
export { ContentHeader };
