import * as React from 'react';
import styles from './styles.module.scss';

export const OptionalMessageInputRef = React.forwardRef((props, ref) => (
  <div className={styles.dealModalInputWrapper}>
    <textarea
      className={styles.dealModalInputWrapper__input}
      //@ts-ignore
      ref={ref}
      placeholder="Message (Optional)"
    />
  </div>
));
