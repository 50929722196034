import { FormattedMessage } from 'react-intl';
import React, { Fragment, MouseEvent, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Dropdown, DropdownToggle } from '../../../../dropdown';

export enum DocumentActivityTypesEnum {
  all = 'all',
  comments = 'comments',
  suggestions = 'suggestions',
}

interface StatusDropdownProps {
  currentDocumentActivity: DocumentActivityTypesEnum;
  onDocumentActivityChange: (newDocumentActivityType: DocumentActivityTypesEnum) => void;
}

const DropdownBody = ({
  children,
  as: Component = 'div',
}: {
  children: ReactNode;
  as?: keyof JSX.IntrinsicElements;
}) => {
  return <Component className={styles.dropdownBody}>{children}</Component>;
};

const DocumentActivityDropdown = ({ currentDocumentActivity, onDocumentActivityChange }: StatusDropdownProps) => {
  return (
    <Dropdown
      onClick={(evt: MouseEvent) => {
        evt.stopPropagation();
      }}
      className={styles.documentActivityDropdownWrapper}
    >
      <Dropdown.Toggle
        id="dropdown-deal-actions"
        as={DropdownToggle}
        /*
        // @ts-ignore */
        className={classNames('w-100 slightly-shorter', styles['dropdown-toggle'])}
      >
        <FormattedMessage id="deals.actions" defaultMessage={DocumentActivityTypesEnum[currentDocumentActivity]} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        <DropdownBody>
          {Object.entries(DocumentActivityTypesEnum).map(([key, value]: [string, string]) => (
            <Fragment key={key}>
              <Dropdown.Item
                as={Button}
                variant="link"
                onClick={() => {
                  onDocumentActivityChange(key as DocumentActivityTypesEnum);
                }}
              >
                {value}
              </Dropdown.Item>
            </Fragment>
          ))}
        </DropdownBody>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DocumentActivityDropdown;
