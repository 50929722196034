import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage as BaseFormikErrorMessage } from 'formik';

import { generic } from '../error-messages';

/**
 * Convert an error message parameter to props ready for react-intl
 *
 * This handles error messages from Formik/yup, or from the API, or generic
 * strings, and gets them ready for react-intl.
 *
 * Formik and yup support only rudimentary internationalization. Error messages
 * have to be strings in order for placeholders to be used, and by the time the
 * message gets to the form it has already had the placeholders filled. This is
 * no good for react-intl.
 *
 * Our workaround in that case is to set as the error message a JSON-stringified
 * object which contains all the props react-intl wants. Since it is a string,
 * yup can substitute in the parameters into the `values` object, meaning we
 * still end up with a separate message ID and map of parameters.
 *
 * The parameter for this function, therefore, is either a JSON-encoded set of
 * parameters ready for react-intl (or the same, but not JSON-stringified, if
 * such as if it's from an API response and so didn't have to go through yup),
 * or it's a plain string message.
 *
 * @function errorMessageToReactIntlProps
 * @param {(string|Object)} error - JSON-stringified object, object, or plain
 *                                  string
 * @return {Object} - Props ready for react-intl's FormattedMessage component
 */
function errorMessageToReactIntlProps(error: any) {
  // If it looks like a message object, return it
  if (typeof error === 'object' && error?.id && error?.defaultMessage) {
    return error;
  }

  // If it's a string maybe it's a JSON-stringified message object
  if (typeof error === 'string') {
    try {
      const decoded = JSON.parse(error);
      if (decoded.id && decoded.defaultMessage) {
        return decoded;
      }
    } catch {
      // Couldn't parse as JSON; fall back to just rendering this string
      return {
        id: error,
        defaultMessage: error,
      };
    }
  }

  // It's something unexpected; warn and return the generic error message
  console.warn('errorMessageToReactIntlProps received parameter in an unexpected format', error);
  return generic;
}

interface ErrorMessageProps {
  children: ReactNode;
}
const ErrorMessage = ({ children }: ErrorMessageProps) => (
  <FormattedMessage {...errorMessageToReactIntlProps(children)} />
);

export default ErrorMessage;

export const FormikErrorMessage = (props: any) => <BaseFormikErrorMessage {...props} component={ErrorMessage} />;
