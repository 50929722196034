import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import { Button, Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { ReactComponent as CheckmarkIcon } from '../../../shared/icons/form-icon-valid.svg';
import { graphqlErrorsToUiErrors, queries } from '../../../api';
import LoadingSpinner from '../../../components/loading-spinner';
import NotFound from '../../not-found';
import ErrorMessage from '../../../components/error-message';
import {TitleFormattedMessage} from "../../../components/titleFormattedMessage";
import styles from './styles.module.scss';

const UPGRADE_SUBSCRIPTION_MESSAGE =
  'Upgrade to access a full suite of tools to draft, edit, negotiate and sign your agreements.';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SubscriptionRequiredModal = ({ isOpen, onClose }: ModalProps) => {
  const { formatMessage } = useIntl();
  const { loading: portalDataLoading, data: portalData, error: portalDataError } = useQuery(queries.getPortalUrl);

  if (portalDataLoading) {
    return <LoadingSpinner />;
  }

  if (!portalData) {
    const errors = graphqlErrorsToUiErrors(portalDataError);
    console.warn('ERROR', errors);
    if (errors._general?.id === 'api.error.not-found') {
      return <NotFound />;
    }
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }
  return (
    <Modal show={isOpen} onHide={onClose} centered className={styles.subscribtionRequiredModal}>
      <div className={styles.subscribtionRequiredModalContainer}>
        <Modal.Header
          closeButton
          closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
          onClick={onClose}
        >
          <Modal.Title>
            <header className={styles.subscribtionRequiredModalHeader}>
              <FormattedMessage id="title.archive-template" defaultMessage="Streamline your deals by upgrading!" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.subscribtionRequiredModalBody}>
          <p>
            <FormattedMessage id="title.archive-template" defaultMessage={UPGRADE_SUBSCRIPTION_MESSAGE} />
          </p>
          <p>
            <CheckmarkIcon height={32} />
            <FormattedMessage id="title.archive-template" defaultMessage="Advanced Document Editor" />
          </p>
          <p>
            <CheckmarkIcon height={32} />
            <FormattedMessage id="title.archive-template" defaultMessage="Internal Approvals" />
          </p>
          <p>
            <CheckmarkIcon height={32} />
            <FormattedMessage id="title.archive-template" defaultMessage="Template Management" />
          </p>
          <p>
            <CheckmarkIcon height={32} />
            <FormattedMessage id="title.archive-template" defaultMessage="eSignatures" />
          </p>
          <p>
            <FormattedMessage id="title.archive-template" defaultMessage="... and much more!" />
          </p>
          <Container className={styles.upgradeButtonContainer}>
            <Button href={portalData!.currentUser!.portalUrl!}>
              <TitleFormattedMessage id="cta.upgrade" defaultMessage="Upgrade" />
            </Button>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container className={styles.subscribtionRequiredModalFooter}>
            Questions? Contact us at
            <a className="ml-1" href="mailto:info@propdocs.com" target="_blank" rel="noopener noreferrer">
              info@propdocs.com
            </a>
          </Container>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SubscriptionRequiredModal;
