import SendSigningReminder from './sendSigningReminder';
import React, { useState } from 'react';
import SendSigningReminderModal from './sendSigningReminderModal';

interface Props {
  className: string;
  dealId: string;
  teamId: string;
}

const SendSigningReminderContainer = ({ dealId, teamId, className }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SendSigningReminder onClick={() => setShowModal(true)} className={className} />
      <SendSigningReminderModal show={showModal} onHide={() => setShowModal(false)} dealId={dealId} teamId={teamId} />
    </>
  );
};

export default SendSigningReminderContainer;
