import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ArchiveModal from './ArchiveModal';
import Button from 'react-bootstrap/Button';
import AddNewTemplateFormik from '../../forms/AddNewTemplateFormContainer';
import { Dropdown, DropdownBody } from '../../components/dropdown';
import { mutations, queriesV2 } from '../../api';
import { useNotifications } from '../../notifications';
import { SuccessMessages } from '../../success-messages';
import { paths } from '../../routing';
import { TemplateType } from '../../consts/forms-types';
import { ReactComponent as DotsIcon } from '../../shared/icons/dotsRoundIcon.svg';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';
import styles from './styles.module.scss';

type TemplateControlsProps = {
  status: string;
  editPermission: boolean;
  selectedTemplateData: any;
  teamId: string;
  onSuccess: () => void;
  templateType?: TemplateType;
};

type DropdownActionsType = {
  text: string;
  onClick: () => void;
  isAvailable: boolean;
};

enum TemplateActionStatus {
  archive = 'Archive',
  unarchive = 'Unarchive',
  view = 'View',
  edit = 'Edit',
  clone = 'Clone',
}

export enum TemplateStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

const Controls = ({
  status,
  editPermission,
  selectedTemplateData,
  teamId,
  onSuccess,
  templateType,
}: TemplateControlsProps) => {
  const { formatMessage } = useIntl();
  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const [currentTemplateType, setCurrentTemplateType] = useState<TemplateType>(TemplateType.DOCUMENT);
  const navigate = useNavigate();
  //@ts-ignore TODO: update useNotifications to TS
  const [, { success: notifySuccess, error: notifyError }] = useNotifications();
  const [updateDocumentTemplate] = useMutation(mutations.updateDocumentTemplate);
  const [cloneDocumentTemplate] = useMutation(mutations.cloneDocumentTemplate);
  const isOptionAvailable = editPermission;
  const isActive = status === TemplateStatus.ACTIVE;

  const navigateToEditor = useCallback(
    (templateId: string, teamId: string) => {
      const dealUrl = paths.templates.document.replace(':teamId', teamId).replace(':templateId', templateId);
      navigate(dealUrl);
    },
    [navigate]
  );

  useEffect(() => {
    !!templateType && setCurrentTemplateType(templateType);
  }, [templateType]);

  const onUpdateArchived = async () => {
    const archiveMessage =
      currentTemplateType === TemplateType.CLAUSE ? SuccessMessages.archiveClause : SuccessMessages.archiveTemplate;
    const unarchiveMessage =
      currentTemplateType === TemplateType.CLAUSE ? SuccessMessages.unarchiveClause : SuccessMessages.unarchiveTemplate;
    const successMessage = isActive ? archiveMessage : unarchiveMessage;
    try {
      await updateDocumentTemplate({
        variables: { documentTemplateId: selectedTemplateData.id, hidden: isActive },
        refetchQueries: [
          {
            query: queriesV2.getDocumentTemplates,
            variables: {
              teamId,
            },
          },
        ],
      });
      notifySuccess({ message: successMessage });
      setArchiveModalOpen(false);
      onSuccess();
    } catch (error) {
      notifyError({ message: error });
      return false;
    }
  };

  const onTemplateClone = useCallback(async () => {
    try {
      const clonedTemplate = await cloneDocumentTemplate({
        variables: { documentTemplateId: selectedTemplateData.id },
      });
      const clonedTemplateId = clonedTemplate!.data!.cloneDocumentTemplate!.id;
      navigateToEditor(clonedTemplateId, teamId);
    } catch (error) {
      notifyError({ message: error });
      return false;
    }
  }, [cloneDocumentTemplate, notifyError, selectedTemplateData, teamId, navigateToEditor]);

  const allDropDownActions = [
    {
      text: TemplateActionStatus.view,
      onClick: () => {
        navigateToEditor(selectedTemplateData.id, teamId);
      },
      isAvailable: true,
    },
    {
      text: isActive ? TemplateActionStatus.archive : TemplateActionStatus.unarchive,
      onClick: isActive ? () => setArchiveModalOpen(true) : onUpdateArchived,
      isAvailable: isOptionAvailable,
    },
    {
      text: TemplateActionStatus.clone,
      onClick: () => {
        onTemplateClone();
      },
      isAvailable: isOptionAvailable && templateType !== TemplateType.UPLOADED,
    },
  ];

  const dropDownActions = (actions: DropdownActionsType[]) => {
    return actions
      .filter(action => action.isAvailable)
      .map((action, index) => (
        <Dropdown.Item as={Button} variant="link" onClick={action.onClick} key={index}>
          {action.text}
        </Dropdown.Item>
      ));
  };

  const templateIsUpdated = () => {
    onSuccess();
    setShowEditTemplate(false);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-deal-actions" className={styles.dottedDropdown} as={DotsIcon}>
          <FormattedMessage id="deals.actions" defaultMessage={`Actions`} />
        </Dropdown.Toggle>
        <Dropdown.Menu align="right">
          <DropdownBody>{dropDownActions(allDropDownActions)}</DropdownBody>
        </Dropdown.Menu>
      </Dropdown>
      <ArchiveModal
        isOpen={isArchiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
        onUpdateArchived={onUpdateArchived}
        templateType={templateType}
      />
      <Modal show={showEditTemplate} onHide={() => setShowEditTemplate(false)} size="sm">
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title>
            <header>
              <FormattedMessage id="title.edit-template" defaultMessage="Edit Template" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <AddNewTemplateFormik
          bodyContainer={Modal.Body}
          actionsContainer={Modal.Footer}
          renderSecondaryAction={() => (
            <Button variant="link" className="text-secondary" onClick={() => setShowEditTemplate(false)}>
              <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
            </Button>
          )}
          existingTemplateFields={selectedTemplateData}
          templateFormEditMode={true}
          onSuccess={templateIsUpdated}
        />
      </Modal>
    </>
  );
};

export default Controls;
