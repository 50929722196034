import { client as apolloClient, mutations } from '../../../api';
import { useCallback } from 'react';
import { getLeaseDocumentRevisions } from '../../../utils/api/getLeaseDocumentRevisions/getLeaseDocumentRevisions';
import { useMutation } from '@apollo/client';
import { DocumentTypesEnum } from '../../../utils/ckeditor/trackChangesAdapter/types';
import { getLeaseTemplateRevisions } from '../../../utils/api/get-lease-template-revisions/getLeaseTemplateRevisions';

const useRevisionHistory = (documentId: string | undefined, documentType: string, _client = apolloClient) => {
  const [updateDocumentRevisions] = useMutation(mutations.updateDocumentRevisions);
  const getDocumentRevisions = useCallback(async () => {
    if (!documentId) return;

    if (documentType === DocumentTypesEnum.DOCUMENT) {
      return await getLeaseDocumentRevisions(documentId)(apolloClient);
    }

    return await getLeaseTemplateRevisions(documentId)(apolloClient);
  }, [documentId, documentType]);

  return {
    getDocumentRevisions,
    updateDocumentRevisions,
  };
};

export default useRevisionHistory;
