import { PermissionsActions } from './permissions.actions';

export type PermissionByTeam = 'view' | 'comment' | 'edit' | 'invite' | 'whitelist' | 'approve' | 'admin';

export type TeamPermissionName =
  | PermissionByTeam
  | 'landlord-view'
  | 'landlord-comment'
  | 'landlord-edit'
  | 'landlord-invite'
  | 'landlord-whitelist'
  | 'landlord-approve'
  | 'landlord-admin'
  | 'tenant-view'
  | 'tenant-comment'
  | 'tenant-invite'
  | 'tenant-edit'
  | 'tenant-admin';

export type Permission = {
  id: string;
  key: TeamPermissionName;
  displayOrder: number;
};

export type TeamPermission = {
  teamId: string;
  permissionName: TeamPermissionName;
  allowed: boolean;
  permission?: Permission;
};

export type TeamWithParty = {
  teamId: string;
  party: {
    name: string;
  };
};
export type PermissionsStateType = {
  teamPermissions: TeamPermission[];
  ownedTeams: string[];
  allTeams: TeamWithParty[];
};

const initialState: PermissionsStateType = {
  teamPermissions: [],
  ownedTeams: [],
  allTeams: [],
};

const reducer = (state: PermissionsStateType = initialState, action: PermissionsActions): PermissionsStateType => {
  switch (action.type) {
    case 'setTeamPermissions':
      return {
        ...state,
        teamPermissions: action.teamPermissions,
      };

    case 'setOwnedTeams':
      return {
        ...state,
        ownedTeams: action.ownedTeams,
      };

    case 'setAllTeams':
      return {
        ...state,
        allTeams: action.allTeams,
      };

    default:
      return state;
  }
};

export { reducer as permissionsReducer };
export { initialState as initialPermissionsState };
