import React, { useCallback } from 'react';
import Modal from 'react-overlays/Modal'
import { ReactComponent as CloseIcon } from '../../shared/icons/close-icon.svg';

import styles from './styles.module.scss'
import classNames from 'classnames';
import Fade from './Fade'
interface PropdocsModalProps {
  title: React.ReactElement;
  show: boolean;
  setShow: () => void;
  size?: "sm" | "md" | "lg";
  className?: string;
  disableClose?: boolean;
  children: React.ReactElement;
}

/**
 * Propdocs Modal
 * - This modal is the base of the modal we use for other modals in the app
 *
 * TODO: Change over other modals to use this base
 *
 * @param show
 * @param setShow
 * @param size
 * @param children
 * @constructor
 */
function PropdocsModal({ title, show, setShow, size, children, className, disableClose = false }: PropdocsModalProps) {
  const renderBackdrop = (props: any) => <div className={classNames(styles.propdocsModal_backdrop)} {...props} />;

  const sizeClass = useCallback(() => {
    return size ? `${size}-modal` : ""
  }, [size])

  const modalClassNames = classNames(styles[sizeClass()], className);

  return (
    <Modal
      show={show}
      onHide={() => setShow()}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
      transition={Fade}
      backdropTransition={Fade}
      className={modalClassNames}
    >
      <>
        {!disableClose && <div onClick={() => setShow()} className={styles.propdocsModal_content__closeButton}>
          <CloseIcon className={styles.propdocsModal_content__closeIcon} />
        </div>}
        <div className={styles.propdocsModal_content}>
          <div className={styles.propdocsModal_content__header}>
            <h2 className={styles.propdocsModal_content__title}>
              {title}
            </h2>
          </div>
          {children}
        </div>
      </>
    </Modal>
  );
}

export default PropdocsModal;
