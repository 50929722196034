import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSortBy, useTable } from 'react-table';
import { Button, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { setActiveModal } from '../../store/modals/modals.actions';
import { ModalActionsEnum, CreateDocumentModalsEnum } from './DocumentTableConstants';
import { useDealId } from '../../deal-id-context';
import LoadingSpinner from '../loading-spinner';
import CreateDocumentContainer from './CreateDocumentContainer';
import { useDealRoles } from '../../shared/hooks/useDealRoles/useDealRoles';
import { Table } from '../table';
import { getDocumentStatusIndicator } from './helpers/documentStatusIndication';
import { useLeaseDocumentWizardErrorNotifier } from '../../pages/lease-document-wizard/shared/hooks/use-lease-document-wizard-error-notifier/use-lease-document-wizard-error-notifier.hook';
import { LeaseEditorErrors } from '../lease-wizard/lease-editor/constants/EditorConstats';
import { DocumentStatusEnum } from '../../shared/constants/leaseDocument';
import { getDocumentAccessLabel, handleDocumentNavigation } from './helpers/documentDefinitionHelpers';
import { ApprovalRequestStatus } from '../lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/RequestInternalApprovalModalConstants';
import { useUserDealRestrictions } from '../../auth/user-restrictions';
import SubscriptionRequiredModal from '../../pages/modals/subscriptionModal/SubscriptionRequiredModal';
import { fuzzyInputSearch } from '../../pages/deals/helpers/determineDealsActionModalMessage';
import { Document } from '../../gql/graphql';
import { DocumentTableColumns } from './DocumentTableColumns';
import { DocumentTableProps } from './helpers/documentTableTypes';
import DocumentTableSearchbar from './DocumentTableSearchbar';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import { toggleManageDealParticipantsCollapsed } from '../../store/navigation/navigation.actions';
import styles from './DocumentTable.module.scss';
import FilterSidebar from './filterSidebar';
import { MobileDocItem } from '../mobile-layout/MobileDocItem';
import ActivitySidebar from './activitySidebar';

const DocumentTable = ({
  permissionsData,
  isDealShared,
  teamId,
  dealDocumentsData,
  alertableDocuments,
}: DocumentTableProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    canCreateBlankDocument,
    canCreateDocumentFromTemplate,
    canCreateDocumentFromWord,
    canUploadDocument,
  } = useUserDealRestrictions();
  const dealId = useDealId();
  const navigate = useNavigate();
  const notifyError = useLeaseDocumentWizardErrorNotifier();
  const { isDealRolesLoading } = useDealRoles(dealId);
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const [filterTitleText, setFilterTitleText] = useState('');
  const [filterStatuses, setFilterStatuses] = useState<Set<string>>(new Set());
  const isDocumentsDataAvailible = !isEmpty(dealDocumentsData);
  const [showFilter, setShowFilter] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [sorting, setSorting] = useState('');

  const hasUserEditPermission = !!permissionsData?.hasEditPermission;

  const alertableDocumentsIDs = useMemo(() => {
    return alertableDocuments?.map((document: Document) => document.id);
  }, [alertableDocuments]);

  const handleTemplateSelectorModal = useCallback(() => {
    return canCreateDocumentFromTemplate
      ? dispatch(setActiveModal(CreateDocumentModalsEnum.TEMPLATE_SELECTOR))
      : setSubscriptionRequiredModal(true);
  }, [dispatch, canCreateDocumentFromTemplate]);

  const handleCreateBlankDocumentModal = useCallback(() => {
    return canCreateBlankDocument
      ? dispatch(setActiveModal(CreateDocumentModalsEnum.BLANK_DOCUMENT))
      : setSubscriptionRequiredModal(true);
  }, [dispatch, canCreateBlankDocument]);

  const handleFileUploadModal = useCallback(() => {
    return canUploadDocument
      ? dispatch(setActiveModal(ModalActionsEnum.UPLOAD_FILE))
      : setSubscriptionRequiredModal(true);
  }, [dispatch, canUploadDocument]);

  const handleDocFileUploadModal = useCallback(() => {
    return canCreateDocumentFromWord
      ? dispatch(setActiveModal(ModalActionsEnum.UPLOAD_DOC_FILE))
      : setSubscriptionRequiredModal(true);
  }, [dispatch, canCreateDocumentFromWord]);

  const handleEditDocument = useCallback(
    (documentId: string, hasCurrentTeamPossession: boolean, documentStatus: string) => {
      const isDocumentStatusReviewing = documentStatus === DocumentStatusEnum.REVIEWING;
      const isDocumentStatusPreparingToSign = documentStatus === DocumentStatusEnum.PREPARING_TO_SIGN;

      if (!hasCurrentTeamPossession && isDocumentStatusReviewing) {
        return notifyError({ message: LeaseEditorErrors.NO_POSSESSION.defaultMessage, pinned: true });
      }

      if (!hasCurrentTeamPossession && isDocumentStatusPreparingToSign) {
        return notifyError({ message: LeaseEditorErrors.IS_PREPARING_TO_SIGN.defaultMessage });
      }

      if (documentId) {
        handleDocumentNavigation({ documentId, dealId, teamId, documentStatus, navigate });
      }
    },
    [dealId, teamId, notifyError, navigate]
  );

  const columns = useMemo(
    () =>
      DocumentTableColumns({
        alertableDocumentsIDs,
        teamId,
        dealId,
        handleEditDocument,
        isDealShared,
        permissions: permissionsData,
      }),
    [alertableDocumentsIDs, teamId, dealId, handleEditDocument, isDealShared, permissionsData]
  );

  const allStatuses = useMemo(() => {
    if (!dealDocumentsData) {
      return [];
    }
    return dealDocumentsData.map((document: Document) =>
      document.approvalRequest?.status === ApprovalRequestStatus.PENDING && document.currentTeamPossession
        ? 'Approval Pending'
        : getDocumentStatusIndicator(document.status as DocumentStatusEnum, !!document.currentTeamPossession)
    );
  }, [dealDocumentsData]);

  useEffect(() => {
    setFilterStatuses(new Set(allStatuses));
  }, [allStatuses]);

  const handleManageDealParticipantsClick = () => {
    dispatch(toggleManageDealParticipantsCollapsed(false));
  };

  const documentsData = useMemo(() => {
    if (!dealDocumentsData) return [];

    const docData = dealDocumentsData
      .filter((document: Document) => {
        if (
          (filterStatuses.size &&
            !filterStatuses.has(
              document.approvalRequest?.status === ApprovalRequestStatus.PENDING && document.currentTeamPossession
                ? 'Approval Pending'
                : getDocumentStatusIndicator(document.status as DocumentStatusEnum, !!document.currentTeamPossession)
            )) ||
          !filterStatuses.size
        ) {
          return false;
        }

        if (filterTitleText && !fuzzyInputSearch({ filteredText: filterTitleText, searchValue: document.title })) {
          return false;
        }
        return true;
      })
      .map((document: Document) => {
        return {
          id: document.id,
          name: formatMessage({
            id: `format.name${document.id}`,
            defaultMessage: document.title,
          }),
          status:
            document.approvalRequest?.status === ApprovalRequestStatus.PENDING && document.currentTeamPossession
              ? 'Approval Pending'
              : document.status,
          document,
          access: getDocumentAccessLabel(document.receiverTeam),
          onRowClick: () => {
            handleEditDocument(document.id, !!document.currentTeamPossession, document.status || '');
          },
        };
      });

    docData
      .sort((a: any, b: any) => {
        switch (sorting) {
          case 'ascName':
            return a.name.localeCompare(b.name);

          case 'descName':
            return b.name.localeCompare(a.name);

          case 'accessMyTeam':
            return a.access?.localeCompare(b.access || '');

          case 'accessPrivate':
            return b.access?.localeCompare(a.access || '');

          default:
            return moment(b.document.lastUpdated).isBefore(a.document.lastUpdated) ? -1 : 1;
        }
      })
      .sort((a: { document: Document }) => {
        return alertableDocumentsIDs?.includes(a.document.id) ? -1 : 1;
      });

    return docData;
  }, [
    dealDocumentsData,
    formatMessage,
    handleEditDocument,
    sorting,
    filterTitleText,
    filterStatuses,
    alertableDocumentsIDs,
  ]);

  const table = useTable(
    {
      data: documentsData,
      //@ts-ignore
      columns,
    },
    useSortBy
  );

  if (!permissionsData || isDealRolesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Row className={'mb-3 align-items-center'}>
        <Col className={classNames(styles.documentButtons, 'd-flex justify-content-between')}>
          <DocumentTableSearchbar
            filterTitleText={filterTitleText}
            setFilterTitleText={setFilterTitleText}
            filterStatuses={filterStatuses}
            allStatuses={allStatuses}
            setShowFilter={setShowFilter}
            setShowActivity={setShowActivity}
            setFilterStatuses={setFilterStatuses}
          />

          <div className={classNames(styles.documentButtons__btns, 'd-flex')}>
            <Button variant="primary" onClick={handleManageDealParticipantsClick}>
              <TitleFormattedMessage id="cta.add-new-template" defaultMessage="Manage People" />
            </Button>

            {hasUserEditPermission && (
              <div className={'ml-3'}>
                <CreateDocumentContainer
                  handleTemplateSelectorModal={handleTemplateSelectorModal}
                  handleFileUploadModal={handleFileUploadModal}
                  handleDocFileUploadModal={handleDocFileUploadModal}
                  handleCreateBlankDocumentModal={handleCreateBlankDocumentModal}
                  hasReceiverEditPermission={hasUserEditPermission}
                  hasDealDocuments
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row className={styles.documentTableCard}>
        {isDocumentsDataAvailible ? (
          <Col className={styles.documentDataTable}>
            <div className="non-mobile">
              <Table striped isStickyHeader table={table} isActionColumn />
            </div>
            <div className="mobile">
              {documentsData.map(document => (
                <MobileDocItem
                  key={document.id}
                  doc={document}
                  teamId={teamId}
                  dealId={dealId}
                  isDealShared={isDealShared}
                  permissions={permissionsData}
                  handleEdit={handleEditDocument}
                />
              ))}
            </div>
          </Col>
        ) : (
          <CreateDocumentContainer
            handleTemplateSelectorModal={handleTemplateSelectorModal}
            handleFileUploadModal={handleFileUploadModal}
            handleDocFileUploadModal={handleDocFileUploadModal}
            handleCreateBlankDocumentModal={handleCreateBlankDocumentModal}
            hasReceiverEditPermission={false}
          />
        )}
      </Row>
      <SubscriptionRequiredModal
        isOpen={showSubscriptionRequiredModal}
        onClose={() => setSubscriptionRequiredModal(false)}
      />
      <FilterSidebar
        isOpen={showFilter}
        filterStatuses={filterStatuses}
        allStatuses={allStatuses}
        onClose={() => setShowFilter(!showFilter)}
        setSorting={setSorting}
        setFilterStatuses={setFilterStatuses}
      />
      <ActivitySidebar isOpen={showActivity} onClose={() => setShowActivity(!showActivity)} />
    </>
  );
};

export default DocumentTable;
