export const home = '/';
export const homeWithToken = '/invitation/:token';
export const auth = {
  login: '/auth/login',
  loginRedirectWithToken: '/auth/login/:token',
  loginRedirectToDeals: '/auth/login/:token/teams/:teamId/deals',
  loginRedirectToDealOverview: '/auth/login/:token/teams/:teamId/deals/:dealId',
  loginRedirectToDocument: '/auth/login/:token/teams/:teamId/deals/:dealId/documents/:documentId',
  loginRedirectToSigningPrep: '/auth/login/:token/teams/:teamId/deals/:dealId/documents/:documentId/sign',
  loginRedirectToOrganizations: '/auth/login/:token/settings/:teamId/organizations',
  loginRedirectToRequests: '/auth/login/:token/settings/:teamId/requests',
  loginByInvitation: '/auth/login/invitation/:token',
  refresh: '/auth/refresh',
  register: '/auth/register',
  confirmByToken: '/auth/confirmation/:token',
  registerByToken: '/auth/register/:token',
  counterpartyInvite: 'auth/invitation/:token',
  teamMemberInvite: 'auth/register/:token',
  registerByConfirmation: '/auth/confirmation/:token/:email',
  invitation: '/auth/invitation/:token', // Printed in email
  forgotPassword: '/auth/password/forgot',
  resetPassword: '/users/password/edit', // Printed in email
  confirmEmail: '/users/confirmation', // Printed in email
};

export const deals = {
  _catchallRegex: /^\/teams\/\d+\/deals\b/,
  landing: '/teams/:teamId/deals',
  landingWithToken: '/teams/:teamId/deals/invitation/:dealToken',
  detail: '/teams/:teamId/deals/:dealId',
};

export const templates = {
  _catchallRegex: /^\/teams\/\d+\/templates\b/,
  landing: '/teams/:teamId/templates',
  document: '/teams/:teamId/templates/:templateId',
  orgTemplate: '/teams/:teamId/orgtemplates/:templateId',
};

export const clauses = {
  _catchallRegex: /^\/teams\/\d+\/clauses\b/,
  landing: '/teams/:teamId/clauses',
};

export const team = '/teams/:teamId';
export const noTeam = '/no-team';
export const account = '/account';
export const settings = {
  _catchallRegex: /^\/settings\b/,
  default: '/settings',
  organizations: '/settings/:teamId/organizations',
  requests: '/settings/:teamId/requests',
};

export const documentPath = '/teams/:teamId/deals/:dealId/documents/:documentId';

export const Documents = {
  document: '/teams/:teamId/deals/:dealId/documents/:documentId',
  signDocument: '/teams/:teamId/deals/:dealId/documents/:documentId/sign',
};

export const help = '/help';

export const magicSign = '/sign/:token';
export const signError = '/sign-error';

export const mobileWarning = '/mobile-warning';
