import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentSourceAction } from '../actions/lease-document.actions';

export const handleSetDocumentSource = (
  state: LeaseDocumentStateType,
  action: SetDocumentSourceAction
): LeaseDocumentStateType => ({
  ...state,
  source: action.source,
});
