import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import clsx from 'classnames';
import { useTeamInfo } from '../../team-id-context';
import InviteReceivingTeamFormik from '../../forms/InviteReceivingTeamFormContainer';
import SubscriptionRequiredModal from '../../pages/modals/subscriptionModal/SubscriptionRequiredModal';
import CounterpartyMembersListContainer from './CounterpartyMembersListContainer';
import { TeamMember } from '../lease-wizard/lease-editor/LeaseEditorTypes';
import { setActiveModal } from '../../store/modals/modals.actions';
import { ModalActionsEnum } from '../document-table/DocumentTableConstants';
import { DealModalActionsEnum } from '../../pages/deals/constants/DealConstants';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './styles.module.scss';

interface CounterpartyPanelProps {
  isAdmin: boolean;
  hasCreatorInvitePermission: boolean;
  canInviteCounterparty: boolean;
  isDealShared: boolean;
  counterpartyTeam: TeamMember[];
  isDealAccepted: boolean;
  counterpartyInvitedEmail: string;
  counterpartyAdminUserId: string;
  refetchDealData: () => Promise<void>;
}

const CounterpartyPanel = ({
  isAdmin,
  hasCreatorInvitePermission,
  canInviteCounterparty,
  isDealShared,
  counterpartyTeam,
  isDealAccepted,
  counterpartyInvitedEmail,
  counterpartyAdminUserId,
  refetchDealData,
}: CounterpartyPanelProps) => {
  const { teamId } = useTeamInfo();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const [showInviteCounterpartyModal, setShowInviteCounterpartyModal] = useState(false);

  const handleInviteCounterpartyButton = canInviteCounterparty
    ? () => setShowInviteCounterpartyModal(true)
    : () => setSubscriptionRequiredModal(true);

  const invitationIsSent = useCallback(async () => {
    await refetchDealData();
    setShowInviteCounterpartyModal(false);
  }, [refetchDealData]);

  const handleActionModal = useCallback(
    (name: string) => {
      dispatch(setActiveModal(name as ModalActionsEnum));
    },
    [dispatch]
  );

  return (
    <>
      <Container className={clsx('mb-3 w-100 position-relative', styles.counterpartyPanel)}>
        {' '}
        {!isDealShared && (
          <Container className="mt-5 mb-3 d-flex justify-content-center flex-column align-items-center">
            <Row className="m-4">
              <FormattedMessage
                id="cta.no-counterparty-invited"
                defaultMessage="No counterparty has been invited yet"
              />
            </Row>
            {(isAdmin || hasCreatorInvitePermission) && (
              <Row>
                <Button
                  variant="primary"
                  onClick={handleInviteCounterpartyButton}
                  className={styles.handleParticipantsButton}
                >
                  <TitleFormattedMessage id="cta.invite-counterparty" defaultMessage="Invite Counterparty" />
                </Button>
              </Row>
            )}
          </Container>
        )}
        {isDealAccepted && hasCreatorInvitePermission && (
          <div className={styles.participantsButtonContainer}>
            <Button
              variant="outline-danger"
              className={styles.handleParticipantsButton}
              onClick={() => handleActionModal(DealModalActionsEnum.REMOVE_COUNTERPARTY)}
            >
              <TitleFormattedMessage id="cta.remove" defaultMessage="Remove" />
            </Button>
          </div>
        )}
        {isDealShared && (
          <Row>
            <Col>
              <CounterpartyMembersListContainer
                counterpartyTeam={counterpartyTeam}
                isDealAccepted={isDealAccepted}
                counterpartyInvitedEmail={counterpartyInvitedEmail}
                counterpartyAdminUserId={counterpartyAdminUserId}
                hasCreatorInvitePermission={hasCreatorInvitePermission}
              />
            </Col>
          </Row>
        )}
      </Container>

      <Modal show={showInviteCounterpartyModal} onHide={() => setShowInviteCounterpartyModal(false)} size="lg">
        <div>
          <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
            <Modal.Title>
              <header className={styles.invitationModal__title}>
                <FormattedMessage id="title.add-team-member" defaultMessage="Invite Counterparty to the Deal" />
              </header>
            </Modal.Title>
          </Modal.Header>
          <InviteReceivingTeamFormik
            /*
                  // @ts-ignore */
            bodyContainer={Modal.Body}
            /*
                  // @ts-ignore */
            actionsContainer={Modal.Footer}
            renderSecondaryAction={() => (
              <Button variant="link" className="text-secondary" onClick={() => setShowInviteCounterpartyModal(false)}>
                <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
              </Button>
            )}
            onSuccess={invitationIsSent}
            teamId={teamId}
          />
        </div>
      </Modal>
      <SubscriptionRequiredModal
        isOpen={showSubscriptionRequiredModal}
        onClose={() => setSubscriptionRequiredModal(false)}
      />
    </>
  );
};

export default CounterpartyPanel;
