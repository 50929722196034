import { useCallback, useState } from 'react';
import { useApolloClient as apolloUseApolloClient } from '@apollo/client';
import { getDocumentReadinessForSigning } from '../../utils/api/get-document-readiness-for-signing';

type CheckIfDocumentIsReadyResponse = {
  isDocumentReadyForSigning: boolean;
};

export const useCheckIfDocumentIsReadyForSigning = (documentId: string, useApolloClient = apolloUseApolloClient) => {
  const apolloClient = useApolloClient();
  const [isDocumentReadyLoading, setIsDocumentReadyLoading] = useState<boolean>(false);

  const checkIfDocumentIsReadyForSigning = useCallback(async (): Promise<CheckIfDocumentIsReadyResponse> => {
    try {
      setIsDocumentReadyLoading(true);
      const { isDocumentReadyForSigning }: CheckIfDocumentIsReadyResponse = await getDocumentReadinessForSigning(
        documentId
      )(apolloClient);

      return { isDocumentReadyForSigning };
    } catch (err) {
      console.warn(err);
      return { isDocumentReadyForSigning: false };
    } finally {
      setIsDocumentReadyLoading(false);
    }
  }, [documentId, apolloClient]);

  return {
    checkIfDocumentIsReadyForSigning,
    isDocumentReadyLoading,
  };
};
