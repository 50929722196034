import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { ChangeEvent } from 'react';

enum CollabAccessToDocument {
  EVERYONE = 'everyone',
  TEAM_ONLY = 'my_team',
}

export const renderFormFillingDropdownTool = ({
  documentId,
  toggleReceiverCollaborationForDocument,
  isReceiverCollaborationEnabled,
}: {
  documentId: string;
  toggleReceiverCollaborationForDocument: (options: MutationFunctionOptions) => Promise<FetchResult<boolean>>;
  isReceiverCollaborationEnabled?: boolean;
}) => {
  const handleChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    await toggleReceiverCollaborationForDocument({
      variables: { documentId, flagState: event.target.value === CollabAccessToDocument.EVERYONE },
    });
  };

  return (
    <div className="formFillOptionsDropdownContainer">
      <span>Form Filling: </span>
      <select
        defaultValue={
          isReceiverCollaborationEnabled ? CollabAccessToDocument.EVERYONE : CollabAccessToDocument.TEAM_ONLY
        }
        onChange={handleChange}
      >
        <option value={CollabAccessToDocument.TEAM_ONLY}>My Team Only</option>
        <option value={CollabAccessToDocument.EVERYONE}>Everyone</option>
      </select>
    </div>
  );
};
