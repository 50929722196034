import { ApolloClient } from '@apollo/client';
import { getDocumentRecipients } from '../../../api/queries_v2';
import { LeaseDocumentType } from '../../../components/lease-wizard/lease-document.types';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  id: string;
  document: LeaseDocumentType;
};

interface RecipientsListDetails {
  documentId: string;
  recipients: string[];
}

export const updateDocumentCopyRecipientsList = ({ documentId, recipients }: RecipientsListDetails) => (
  client: ApolloClient<object>
): Promise<ResponsePayloadType> => {
  const variables: RecipientsListDetails = {
    documentId: documentId!,
    recipients,
  };
  const mutation =  graphql(/* GraphQL */`
    mutation UpdateDocumentCopyRecipientsList_v2($documentId: ID!, $recipients: [String]!) {
      updateDocumentCopyRecipientsList(documentId: $documentId, recipients: $recipients) {
        id
        ccRecipients
      }
    }
  `);

  const refetchQueries = [
    {
      query: getDocumentRecipients,
      variables: {
        documentId: documentId!,
      },
    },
  ];

  return client
    .mutate({ mutation, variables, refetchQueries })
    .then(({ data }: Record<any, any>) => data.updateDocumentCopyRecipientsList);
};
