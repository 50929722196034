import React from 'react';
import { FormattedMessage } from 'react-intl';
import Avatar from '../avatar';
import styles from './styles.module.scss';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import { DealModalActionsEnum } from '../../pages/deals/constants/DealConstants';

interface CounterpartyMobileItemProps {
  member: {
    id?: string;
    email: string;
    name: {
      value: string;
      isUserPrimaryContact: boolean;
    };
  };
  isDealAccepted?: boolean;
  hasCreatorInvitePermission: boolean;
  handleActionModal: (value: string) => void;
}

const CounterpartyMobileItem: React.FC<CounterpartyMobileItemProps> = ({
  member,
  isDealAccepted,
  hasCreatorInvitePermission,
  handleActionModal,
}) => {
  return (
    <div className={styles.counterpartyItem}>
      <Avatar email={member.email} fullName={member.name?.value} className="mr-3" />
      <span className={styles.counterpartyItem__name}>
        {member.name ? member.name?.value || '' : member.email}
        {member.name?.isUserPrimaryContact && (
          <>
            {' '}
            <FormattedMessage id="phrase.hint.fullName" defaultMessage="(Primary Contact)" />
          </>
        )}
      </span>
      <div className={styles.counterpartyItem__email}>
        <span className={styles.counterpartyItem__email__title}>Email: </span>
        <span className={styles.counterpartyItem__email__content}>{member.email}</span>
      </div>
      {!isDealAccepted && hasCreatorInvitePermission && (
        <div>
          <span className="align-middle">
            <FormattedMessage id="permission.user-level.user" defaultMessage="Pending:" />
          </span>
          <span className="ml-1">
            <Button className="p-0" variant="link" onClick={() => handleActionModal(DealModalActionsEnum.CANCEL)}>
              <TitleFormattedMessage id="cta.clone" defaultMessage="Cancel" />
            </Button>
            <span className="pl-1 pr-1 text-primary">|</span>
            <Button className="p-0" variant="link" onClick={() => handleActionModal(DealModalActionsEnum.RESEND)}>
              <TitleFormattedMessage id="cta.archive" defaultMessage="Resend" />
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};

export default CounterpartyMobileItem;
