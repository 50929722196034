import { ApolloClient } from '@apollo/client';
import { SaveLeaseDocument, SaveLeaseDocumentTemplate } from '../lease-document-interface.types';
import { graphql } from '../../../gql';

export const updateDocument: SaveLeaseDocument = variables => (client: ApolloClient<object>) => {
  const mutation = graphql(/* GraphQL */`
    mutation UpdateDocument($documentId: ID!, $document: Json, $title: String) {
      updateDocument(documentId: $documentId, document: $document, title: $title) {
        id
        status
        document
        title
        interactionsFeedToken
        currentTeamPossession
        documentSuggestions {
          id
          externalId
          status
        }
        lastUpdated
        team {
          id
        }
        source
        originalImportedAssetUrl
        importedAssetUrl
        importedAssetDownloadPath
        shouldReturnForReceiverReview
        approvalRequest {
          id
          status
          threshold
          creator {
            id
          }
          reviewers {
            id
          }
          reviews {
            id
            approvedAt
            rejectedAt
            reviewer {
              id
              fullName
              email
            }
          }
          totalReviews
          totalApproved
        }
        isEditable
        isTitleEditable
        importedAssetContentType
        editorConfiguration
      }
    }
  `);

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => data.updateDocument);
};

export const updateDocumentTemplateContentOrVisibilityOrName: SaveLeaseDocumentTemplate = variables => (client: ApolloClient<object>) => {
  const mutation = graphql(/* GraphQL */`
    mutation updateDocumentTemplateContentOrVisibilityOrName($documentTemplateId: ID!, $name: String, $template: Json, $hidden: Boolean) {
      updateDocumentTemplate(
        documentTemplateId: $documentTemplateId
        name: $name
        hidden: $hidden
        template: $template
      ) {
        id
        name
        template
        originalImportedAssetUrl
        updatedAt
        team {
          id
        }
      }
    }
  `);

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => data.updateDocumentTemplate);
};
