import filterPermissions from './filter-permissions';
import { TeamPermission } from '../store/permissions/permissions.reducer';

const filterTeamPermissions = (isAdmin: boolean) => (teamPermission: TeamPermission) => {
  // Filter out specifically-disallowed permissions
  if (!teamPermission.allowed) {
    return false;
  }

  // @ts-ignore TODO fix permissions types
  return filterPermissions(teamPermission.permission, isAdmin);
};

export default filterTeamPermissions;
