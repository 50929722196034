import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';

interface Props {
  className: string;
  onClick: () => void;
}

const SendSigningReminder = ({ className, onClick }: Props) => {
  return (
    <Button onClick={onClick} variant="primary" className={classNames('mr-2', className)}>
      <TitleFormattedMessage
        id="lease-document-wizard-header.action.edit"
        defaultMessage="Send Reminder"
        data-testid="lease-document-wizard-header-edit-draft-action"
      />
    </Button>
  );
};

export default SendSigningReminder;
