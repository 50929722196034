import { ApolloClient } from '@apollo/client';
import { getDocumentById } from '../../../api/queries_v2';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  id: string | number;
  eversignPdfUrl: string;
};

export const startSigningDocument = ({
  documentId,
  additionalMessage,
}: {
  documentId: string;
  additionalMessage?: string;
}) => {
  return function(client: ApolloClient<object>): Promise<ResponsePayloadType> {
    const variables = {
      documentId,
      additionalMessage,
    };

    const mutation = graphql(/* GraphQL */`
      mutation startSigningDocument($documentId: ID!, $additionalMessage: String) {
        startSigningDocument(documentId: $documentId, additionalMessage: $additionalMessage) {
          id
          eversignPdfUrl
        }
      }
    `);

    const refetchQueries = [
      {
        query: getDocumentById,
        variables: {
          documentId: documentId!,
        },
      },
    ];

    return client
      .mutate({ mutation, variables, refetchQueries })
      .then(({ data }: Record<any, any>) => data.startSigningDocument);
  };
};
