const InitialsSvgComponent = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="512.000000pt"
      viewBox="0 0 2048.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <rect x="0" y="0" width="100%" height="100%" fill="#afafaf" />
      <g transform="translate(50.000000,450.000000) scale(0.07500000,-0.07500000)" fill="currentColor" stroke="none">
        <path
          d="M4645 4991 c-94 -24 -229 -127 -279 -213 -25 -44 -501 -1327 -494
-1333 2 -2 175 -67 383 -143 258 -95 382 -136 386 -128 4 6 111 297 239 646
255 695 254 695 226 830 -29 139 -108 261 -204 317 -40 23 -59 28 -137 30 -49
1 -104 -1 -120 -6z"
        />
        <path
          d="M3944 4871 c-23 -10 -53 -34 -67 -52 -14 -20 -132 -323 -277 -714
-271 -733 -271 -734 -231 -814 32 -61 90 -96 160 -95 88 1 142 39 180 124 10
25 126 333 257 685 213 573 238 646 239 701 0 69 -24 114 -81 153 -46 32 -127
37 -180 12z"
        />
        <path
          d="M1062 3670 c-183 -33 -414 -183 -561 -366 -199 -247 -328 -575 -440
-1124 -113 -549 -65 -948 138 -1151 107 -108 233 -159 387 -159 159 0 286 49
519 199 77 49 141 88 143 86 2 -2 -2 -38 -9 -79 -17 -107 -7 -219 24 -287 55
-116 155 -197 264 -215 74 -12 195 26 309 97 l31 19 24 -35 c56 -81 150 -123
306 -134 116 -9 123 -12 257 -131 85 -76 131 -95 205 -87 92 10 171 101 171
196 0 73 -30 119 -140 215 -172 150 -244 186 -401 200 l-77 7 -18 40 c-22 49
-72 94 -138 125 -106 49 -218 40 -343 -26 -40 -22 -73 -35 -73 -30 0 5 14 71
31 147 34 149 41 280 20 344 -13 41 -73 103 -114 118 -41 16 -172 13 -246 -4
-95 -23 -210 -80 -392 -198 -206 -132 -258 -158 -329 -164 -115 -11 -166 48
-197 224 -39 221 31 685 163 1082 114 342 251 541 449 652 126 69 202 63 290
-25 88 -87 143 -215 176 -405 10 -63 27 -128 37 -144 30 -52 86 -89 141 -95
79 -7 117 4 165 51 60 58 72 103 56 222 -64 487 -341 824 -690 840 -47 2 -109
0 -138 -5z"
        />
        <path
          d="M3745 3130 c-17 -28 -633 -1723 -651 -1793 -35 -132 -55 -301 -61
-512 -6 -196 3 -292 34 -392 15 -52 15 -54 -11 -127 -30 -85 -32 -117 -10
-151 24 -37 70 -49 113 -28 31 16 40 29 71 106 31 78 40 92 80 119 149 99 404
427 526 673 41 85 674 1794 674 1822 0 7 -1 13 -3 13 -2 0 -173 62 -380 139
-208 76 -379 135 -382 131z"
        />
      </g>
    </svg>
  );
};

export default InitialsSvgComponent;
