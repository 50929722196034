import classNames from 'classnames';
import {
  ActivityPropsType,
  DocumentActivityActions,
  DocumentActivityActionVerbs,
  DocumentActivityTypeEnum,
  DOCUMENT_ACTIVITY_ITEM_BUTTON_NAME,
} from './components/constants';
import dayjs from 'dayjs';
import { DocumentActivityTypesEnum } from './documentActivityDropdown';
import Tooltip from '../../../../tooltip';
import { ReactComponent as Clock } from '../../../../../shared/icons/clock.svg';
import { InlineLoader } from './components/loader';
import Avatar from '../../../../avatar';
import styles from './components/styles.module.scss';
import useInterval from '../../../../../utils/use-interval';
import { useCallback, useMemo, useState } from 'react';

//@ts-ignore
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'Just now',
    past: '%s',
    s: 'Just now',
    m: '1 m ago',
    mm: '%d m ago',
    h: '1 h ago',
    hh: '%d h ago',
    d: '1 d ago',
    dd: '%d d ago',
    M: '1 mo ago',
    MM: '%d mo ago',
    y: '1 y ago',
    yy: '%d y ago',
  },
});

const ACTIVITY_ACTION_CHAR_LENGTH = 7;
const ACTIVITY_TIMESTAMP_UPDATE_INTERVAL = 60000;
const AVATAR_FONT_COLOR = '#737373';
const AVATAR_FONT_SIZE = '13px';
const AVATAR_FILL_COLOR = '#ffff';

type Props = {
  activityProps: ActivityPropsType;
  currentDocumentActivityType: string;
  selectedActivityId?: string | null;
  selectedActivityState: string | null;
  hoveredActivityId?: string | null;
  isActivityDataLoading: boolean;
  shouldAllActivitiesBeShown: boolean;
  handleActivityClick: any;
  setHoveredActivityId: (id: string | null) => void;
};

export const RenderDocumentActivityData = ({
  activityProps,
  currentDocumentActivityType,
  selectedActivityId,
  selectedActivityState,
  hoveredActivityId,
  isActivityDataLoading,
  handleActivityClick,
  setHoveredActivityId,
  shouldAllActivitiesBeShown,
}: Props) => {
  const isSuggestionResolvedOrRejected = [
    DocumentActivityActionVerbs.SUGGESTION_ACCEPTED,
    DocumentActivityActionVerbs.SUGGESTION_REJECTED,
  ].includes(activityProps.verb as DocumentActivityActionVerbs);
  const annotationThreads = window.editor.plugins.get('Annotations')._visibleAnnotations._items;
  const activityDatabaseId = activityProps?.object_data?.object_id;
  const sectionId = activityProps?.extra?.section_name;
  const [relativeActivityTime, setRelativeTime] = useState(dayjs(`${activityProps.time}Z`).fromNow(true));
  const setUpdatedTimeFromNow = useCallback(() => setRelativeTime(dayjs(`${activityProps.time}Z`).fromNow(true)), [
    activityProps.time,
  ]);
  const formattedTime = useMemo(() => {
    return dayjs(`${activityProps.time}z`).format('MM-DD-YYYY HH:mm');
  }, [activityProps]);

  useInterval(() => {
    setUpdatedTimeFromNow();
  }, ACTIVITY_TIMESTAMP_UPDATE_INTERVAL);

  const isAnnotationPartOfMergedReplaceAnnotation = !!annotationThreads.find((currentAnnotation: any) => {
    return (
      currentAnnotation?.view?.mainView?._model?.next?.id === activityProps?.extra?.external_id ||
      currentAnnotation?.view?.mainView?._model?.previous?.id === activityProps?.extra?.external_id
    );
  });

  const shouldNewSuggestionBeDisplayed =
    (activityProps.description === DocumentActivityActions.NEW_SUGGESTION &&
      !isAnnotationPartOfMergedReplaceAnnotation) ||
    activityProps.description !== DocumentActivityActions.NEW_SUGGESTION;

  const renderActivityInfo = () => {
    const activityActionName = activityProps.extra?.data?.suggestionsData?.slice(0, ACTIVITY_ACTION_CHAR_LENGTH);
    const activityActionDescription = activityProps.extra?.data?.suggestionsData?.slice(ACTIVITY_ACTION_CHAR_LENGTH);
    return (
      <div className={styles.suggestionsData}>
        <span className={styles.suggestionsData__name}>{activityActionName}</span>{' '}
        <span>{activityActionDescription}</span>
      </div>
    );
  };

  return (
    <>
      {(currentDocumentActivityType === DocumentActivityTypesEnum.all ||
        activityProps.description.includes(
          currentDocumentActivityType === DocumentActivityTypesEnum.comments
            ? DocumentActivityTypeEnum.comment
            : DocumentActivityTypeEnum.suggestion
        )) &&
        shouldNewSuggestionBeDisplayed &&
        (shouldAllActivitiesBeShown ? true : !activityProps.inactive) && (
          <button
            key={activityProps.id}
            id={`button--${activityProps.id}`}
            className={classNames(
              styles.activityCardItem,
              {
                [styles.activityCardItem__selected]: activityProps.id === selectedActivityId,
              },
              'activityButton'
            )}
            onClick={() =>
              handleActivityClick({
                activityProps,
                sectionId,
                activityDatabaseId,
              })
            }
            onMouseEnter={() => {
              setHoveredActivityId(activityProps.id);
            }}
            onMouseLeave={() => {
              setHoveredActivityId(null);
            }}
            name={DOCUMENT_ACTIVITY_ITEM_BUTTON_NAME}
          >
            <div className={styles.activityTitle}>
              <span className={styles.userAvatar}>
                <Avatar
                  email={activityProps.actor_data?.email}
                  fullName={activityProps.actor_data?.full_name}
                  title={activityProps.actor_data?.full_name}
                  size={25}
                  fontSize={AVATAR_FONT_SIZE}
                  fontColor={AVATAR_FONT_COLOR}
                  backgroundColor={AVATAR_FILL_COLOR}
                />
              </span>

              <div className={styles.activityActorAndDate}>
                <span className={styles.activityActor}>{activityProps.actor_data?.full_name}</span>
                <span className={styles.activityDate}>
                  <span title={formattedTime}>{relativeActivityTime}</span>
                </span>
              </div>
            </div>
            <div className={styles.activityDescriptionDate}>
              <span className={styles.activityDescription}>{activityProps.description}</span>
              {isActivityDataLoading && activityProps.id === selectedActivityId && <InlineLoader />}
              {selectedActivityState && activityProps.id === hoveredActivityId && (
                <Tooltip
                  show={activityProps.id === selectedActivityId}
                  testId="lease-document-tooltip"
                  placement="right"
                  icon={false}
                >
                  {selectedActivityState}
                </Tooltip>
              )}
            </div>
            {isSuggestionResolvedOrRejected && renderActivityInfo()}
          </button>
        )}
    </>
  );
};
