import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';
import { CommentInputType } from "../../../gql/graphql";

export type CreateComments = {
  templateId: string;
  comments: CommentInputType | CommentInputType[];
  threadId: string;
  attributes: any;
  resolvedBy?: string;
  resolvedAt?: string;
  unlinkedAt?: string;
};

const mutation = graphql(/* GraphQL */`
  mutation CreateTemplateComments(
    $templateId: ID!,
    $threadId: String!,
    $attributes: Json!,
    $comments: [CommentInputType!]!,
    $resolvedAt: String
    $resolvedBy: String
    $unlinkedAt: String
  ) {
    createTemplateComments(
      templateId: $templateId,
      threadId: $threadId,
      attributes: $attributes,
      comments: $comments,
      resolvedBy: $resolvedBy,
      resolvedAt: $resolvedAt,
      unlinkedAt: $unlinkedAt,
    ) {
      attributes
      threadId
      resolvedAt
      resolvedBy
      comments {
        ...DocumentCommentFields
      }
    }
  }
`);

export const createTemplateComments = (options: CreateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const createTemplateCommentsWithResult = (options: CreateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.createTemplateComments);
  });
};
