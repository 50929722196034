import clsx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import className from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import { useUserRestrictions } from '../../auth/user-restrictions';
import { useTeamInfo } from '../../team-id-context';
import { usePartyIds, queries } from '../../api';
import { get as getLatestTeamId, set as setLatestTeamId } from '../../utils/latest-team-id';
import { Item } from './MobileItem';
import { SettingsItem } from './SettingsItem';
import { ReactComponent as DealsIcon } from '../../shared/icons/deals-mobile-icon.svg';
import { ReactComponent as TeamIcon } from '../../shared/icons/people-mobile-icon.svg';
import { ReactComponent as HelpIcon } from '../../shared/icons/help-mobile-icon.svg';
import { ReactComponent as SignoutIcon } from '../../shared/icons/signout-mobile-icon.svg';
import { ReactComponent as TemplateIcon } from '../../shared/icons/templates-mobile-icon.svg';
import { ReactComponent as ClausesIcon } from '../../shared/icons/clauses-mobile-icon.svg';
import { ReactComponent as SettingsIcon } from '../../shared/icons/settings-mobile-icon.svg';
import { setTeamPermissions } from '../../store/permissions/permissions.actions';
import { resetLeaseDocumentState } from '../../store/lease-document/actions/lease-document.actions';

import { A, fillPlaceholders, paths } from '../../routing';
import { SubscriptionRequiredModal } from '../../pages';
import { ReactComponent as CloseIcon } from '../../shared/icons/close-icon.svg';
import { ReactComponent as LogoIcon } from '../../shared/icons/logo-icon.svg';
import styles from '../private-layout/styles.module.scss';
import SettingsMobileSubnav from './settings-mobile-subnav';
import CurrentWorkspaceIndicatorMobile from './current-user-indicator-mobile';
import MobileSidebar from './mobile-sidebar';

export const MobileMenu = ({ isOpen, onClose }) => {
  const [{ user }, { logout }] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname: path } = useLocation();
  const { restrictionsLoading, hasAccessToTemplates, hasAccessToClauses } = useUserRestrictions();
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const teamId = useTeamInfo().teamId ?? getLatestTeamId(user?.id);
  const { loading: partyIdsLoading, partyIds } = usePartyIds();
  const { loading: teamLoading, data: teamData } = useQuery(queries.getTeamById, {
    skip: teamId == null,
    variables: { teamId },
  });

  const loading = partyIdsLoading || teamLoading || restrictionsLoading;
  const partyId = loading ? null : teamData?.teamById.party.id ?? partyIds.tenant;
  const isCurrent =
    paths.settings._catchallRegex?.test(path) ?? path === fillPlaceholders(paths.settings.organizations, { teamId });
  const [showSubnav, setShowSubnav] = useState(isCurrent);

  useEffect(() => {
    if (teamId == null) return;
    setLatestTeamId(user?.id, teamId);
  }, [teamId, partyId, partyIds, user]);

  const handleLogout = useCallback(
    data => {
      dispatch(setTeamPermissions([]));
      logout(data).then(() => {
        navigate(paths.auth.login);
        dispatch(resetLeaseDocumentState());
      });
    },
    [logout, dispatch, navigate]
  );

  return (
    <MobileSidebar
      isOpen={isOpen}
      onClose={onClose}
      header={
        <A href={paths.home} aria-label="Home" className={styles.logoIcon}>
          <LogoIcon width={64} height={64} />
          PropDocs
        </A>
      }
    >
      <div className={styles.mobileMenu__children}>
        {teamId ? (
          <Item
            href={paths.deals.landing}
            hrefParams={{ teamId }}
            pathRegex={paths.deals._catchallRegex}
            icon={DealsIcon}
            qa="main-nav-deals"
          >
            <FormattedMessage id="nav.deals" defaultMessage="Deals" />
          </Item>
        ) : (
          <Item href={paths.home} icon={DealsIcon}>
            <FormattedMessage id="nav.deals" defaultMessage="Deals" />
          </Item>
        )}
        <Item href={paths.team} hrefParams={{ teamId }} icon={TeamIcon} qa="main-nav-team">
          <FormattedMessage id="nav.people" defaultMessage="People" />
        </Item>
        {hasAccessToTemplates ? (
          <Item
            href={paths.templates.landing}
            hrefParams={{ teamId }}
            pathRegex={paths.templates._catchallRegex}
            icon={TemplateIcon}
            qa="main-nav-templates"
          >
            <FormattedMessage id="nav.templates" defaultMessage="Templates" />
          </Item>
        ) : (
          <span className={styles.mobileMenu__item} onClick={() => setSubscriptionRequiredModal(true)}>
            <TemplateIcon height="25" width="25" className="ml-1" />
            <div className={styles.mobileMenu__itemLabel}>
              <FormattedMessage id="nav.templates" defaultMessage="Templates" />
            </div>
          </span>
        )}
        {hasAccessToClauses ? (
          <Item
            href={paths.clauses.landing}
            hrefParams={{ teamId }}
            pathRegex={paths.clauses._catchallRegex}
            icon={ClausesIcon}
            qa="main-nav-clauses"
          >
            <FormattedMessage id="nav.clauses" defaultMessage="Clauses" />
          </Item>
        ) : (
          <span className={styles.mobileMenu__item} onClick={() => setSubscriptionRequiredModal(true)}>
            <ClausesIcon height="25" width="25" className="ml-1" />
            <div className={styles.mobileMenu__itemLabel}>
              <FormattedMessage id="nav.clauses" defaultMessage="Clauses" />
            </div>
          </span>
        )}
        <SettingsItem
          pathRegex={paths.settings._catchallRegex}
          href={paths.settings.organizations}
          hrefParams={{ teamId }}
          icon={SettingsIcon}
          qa="main-nav-settings"
          onClick={() => setShowSubnav(!showSubnav)}
          showSubnav={showSubnav}
        >
          <span>
            <FormattedMessage id="nav.settings" defaultMessage="Settings" />
          </span>
        </SettingsItem>

        <span className={className(styles.mobileSubnavWrapper, { [styles.showSubnav]: showSubnav })}>
          <SettingsMobileSubnav />
        </span>
        {/* The following is a trusted site */
        /* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href="//propdocs.com/support/ "
          className={styles.mobileMenu__item}
          data-testid="main-nav-help"
          target="_blank"
        >
          <HelpIcon height="25" width="25" className="ml-1 mr-3" />
          <div>
            <FormattedMessage id="nav.help" defaultMessage="Help" />
          </div>
        </a>
        <span onClick={handleLogout} className={className(styles.mobileMenu__item)}>
          <SignoutIcon height="25" width="25" className="ml-1 mr-3" />
          <div>
            <FormattedMessage id="nav.sign_out" defaultMessage="Sign Out" />
          </div>
        </span>
        <CurrentWorkspaceIndicatorMobile />
        <SubscriptionRequiredModal
          isOpen={showSubscriptionRequiredModal}
          onClose={() => setSubscriptionRequiredModal(false)}
        />
      </div>
    </MobileSidebar>
  );
};
