import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { graphql } from '../../../gql';

export enum DealUserRoles {
  CREATOR = 'creator',
  RECEIVER = 'receiver',
}

export const GET_DEAL_ROLES_BY_DEAL_ID = graphql(/* GraphQL */`
  query GetDealRolesByDealId($dealId: ID!) {
    getDealById(dealId: $dealId) {
      id
      currentUserRole
    }
  }
`);

export const useDealRoles = (dealId: string) => {
  const {
    data: dealRolesData,
    loading: isDealRolesLoading,
    error: dealRolesError,
  } = useQuery(GET_DEAL_ROLES_BY_DEAL_ID, { variables: { dealId }, skip: !dealId });

  return useMemo(() => {
    return {
      isCreator: dealRolesData ? dealRolesData.getDealById.currentUserRole === DealUserRoles.CREATOR : false,
      isDealRolesLoading,
      dealRolesError,
    };
  }, [dealRolesData, dealRolesError, isDealRolesLoading]);
};
