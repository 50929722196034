import { Route } from 'react-router-dom';
import { DocumentPage } from '../pages/lease-document-wizard/shared-offer';
import PrivateLayout from '../components/private-layout';
import * as pages from '../pages';
import * as paths from './paths';
import PrivateRoute from './privateRoute';
import { TemplatePageContainer } from '../pages/lease-document-wizard/template/TemplatePageContainer';
import PrepareToSigningContainer from '../pages/signing/prepareToSigningContainer';

export const AuthRoutes = () => (
  <>
    <Route path={paths.auth.login} element={<pages.auth.Login />} />
    <Route path={paths.auth.loginRedirectWithToken} element={<pages.auth.Login />} />
    <Route path={paths.auth.register} element={<pages.auth.Register />} />
    <Route path={paths.auth.loginRedirectToDeals} element={<pages.auth.Login />} />
    <Route path={paths.auth.loginRedirectToDealOverview} element={<pages.auth.Login />} />
    <Route path={paths.auth.loginRedirectToDocument} element={<pages.auth.Login />} />
    <Route path={paths.auth.loginRedirectToSigningPrep} element={<pages.auth.Login />} />
    <Route path={paths.auth.loginRedirectToOrganizations} element={<pages.auth.Login />} />
    <Route path={paths.auth.loginRedirectToRequests} element={<pages.auth.Login />} />
    <Route path={paths.auth.registerByToken} element={<pages.auth.Register />} />
    <Route path={paths.auth.confirmByToken} element={<pages.auth.ConfirmByToken />} />
    <Route path={paths.auth.counterpartyInvite} element={<pages.auth.CounterpartyInvite />} />
    <Route path={paths.auth.refresh} element={<pages.auth.AuthRefresh />} />
    <Route path={paths.auth.loginByInvitation} element={<pages.auth.Login />} />
    <Route path={paths.auth.resetPassword} element={<pages.auth.ResetPassword />} />
    {/* Printed in email */}
    <Route path={paths.auth.forgotPassword} element={<pages.auth.ForgotPassword />} />
    <Route path={paths.auth.registerByConfirmation} element={<pages.auth.Register withConfirmation />} />{' '}
    {/* Printed in email */}
  </>
);

export const HomeRoutes = () => (
  <>
    <Route path={paths.home} element={<pages.Landing />} />
    <Route path={paths.homeWithToken} element={<pages.Landing />} />
  </>
);

export const DealRoutes = () => (
  <>
    <Route
      path={paths.deals.landing}
      element={
        <PrivateRoute>
          <pages.deals.Landing />
        </PrivateRoute>
      }
    />
    <Route
      path={paths.deals.landingWithToken}
      element={
        <PrivateRoute>
          <pages.deals.Landing />
        </PrivateRoute>
      }
    />
    <Route
      path={paths.deals.detail}
      element={
        <PrivateRoute>
          <pages.deals.Detail />
        </PrivateRoute>
      }
    />
  </>
);

export const TeamRoutes = () => (
  <>
    <Route
      path={paths.team}
      element={
        <PrivateRoute>
          <PrivateLayout>
            <pages.Team />
          </PrivateLayout>
        </PrivateRoute>
      }
    />
    <Route
      path={paths.noTeam}
      element={
        <PrivateRoute>
          <pages.NoTeamPage />
        </PrivateRoute>
      }
    />
  </>
);

export const TemplateRoutes = () => (
  <>
    <Route
      path={paths.templates.landing}
      element={
        <PrivateRoute>
          <PrivateLayout>
            <pages.templates.Landing />
          </PrivateLayout>
        </PrivateRoute>
      }
    />
    <Route
      path={paths.templates.document}
      element={
        <PrivateRoute>
          <TemplatePageContainer />
        </PrivateRoute>
      }
    />
    <Route
      path={paths.templates.orgTemplate}
      element={
        <PrivateRoute>
          <TemplatePageContainer isOrganizationTemplate />
        </PrivateRoute>
      }
    />
  </>
);

export const ClauseRoutes = () => (
  <>
    <Route
      path={paths.clauses.landing}
      element={
        <PrivateRoute>
          <PrivateLayout>
            <pages.clauses.Landing />
          </PrivateLayout>
        </PrivateRoute>
      }
    />
  </>
);

export const DocumentRoutes = () => {
  return (
    <>
      <Route
        path={paths.documentPath}
        element={
          <PrivateRoute>
            <DocumentPage />
          </PrivateRoute>
        }
      />
      <Route
        path={paths.Documents.signDocument}
        element={
          <PrivateRoute>
            <PrepareToSigningContainer />
          </PrivateRoute>
        }
      />
      <Route path={paths.magicSign} element={<pages.MagicSignPage />} />
      <Route path={paths.signError} element={<pages.SignErrorPage />} />
    </>
  );
};

export const SettingsRoutes = () => (
  <>
    <Route
      path={paths.settings.organizations}
      element={
        <PrivateRoute>
          <PrivateLayout shouldScrollBeDisabled>
            <pages.Organizations />
          </PrivateLayout>
        </PrivateRoute>
      }
    />
    <Route
      path={paths.settings.default}
      element={
        <PrivateRoute>
          <PrivateLayout shouldScrollBeDisabled>
            <pages.SettingsLanding />
          </PrivateLayout>
        </PrivateRoute>
      }
    />
    <Route
      path={paths.settings.requests}
      element={
        <PrivateRoute>
          <PrivateLayout shouldScrollBeDisabled>
            <pages.Requests />
          </PrivateLayout>
        </PrivateRoute>
      }
    />
  </>
);

export const AccountRoute = () => (
  <Route
    path={paths.account}
    element={
      <PrivateRoute>
        <PrivateLayout>
          <pages.Profile />
        </PrivateLayout>
      </PrivateRoute>
    }
  />
);

export const MobileWarningRoute = () => <Route path={paths.mobileWarning} element={<pages.MobileWarningPage />} />;

export const NotFound = () => <Route path="*" element={<pages.NotFound />} />;
