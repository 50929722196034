import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

export default class AbbreviationEditing extends Plugin {
  init() {
    this._defineSchema(); // ADDED
    this._defineConverters();
  }

  _defineSchema() {
    // ADDED
    const schema = this.editor.model.schema;

    // Extend the text node's schema to accept the abbreviation attribute.
    schema.extend("$text", {
      allowAttributes: ["insertNumberedList"],
    });
  }

  _defineConverters() {
    // ADDED
    const conversion = this.editor.conversion;

    conversion.for("downcast").attributeToElement({
      model: "abbreviation",
      // Callback function provides access to the model attribute value
      // and the DowncastWriter.
      view: (modelAttributeValue, conversionApi) => {
        const { writer } = conversionApi;

        return writer.createAttributeElement("ol", {
          title: modelAttributeValue,
          class: "Custom",
        });
      },
    });

    conversion.for("upcast").elementToAttribute({
      view: {
        name: "abbr",
        attributes: ["title"],
      },
      model: {
        key: "paragraph",
        // Callback function provides access to the view element.
        value: (viewElement) => {
          const title = viewElement.getAttribute("title");

          return title;
        },
      },
    });
  }
}
