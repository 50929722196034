import { LeaseDocumentStateType } from '../lease-document.reducer'
import {
  SetLeaseDocumentNameAction,
  SetLeaseDocumentNameActionType,
  SetLeaseDocumentTitleAction,
} from '../actions/lease-document.actions';

export const handleSetLeaseDocumentTitle = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentTitleAction
): LeaseDocumentStateType => ({
  ...state,
  title: action.title,
})

export const handleSetLeaseDocumentName = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentNameAction
): LeaseDocumentStateType => ({
  ...state,
  name: action.name,
})
