import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { paths, useNavigate } from '../../routing';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { graphqlErrorsToUiErrors, queries } from '../../api';
import RegisterForm from '../../forms/register-form';
import PublicLayout from '../../components/public-layout';
import ErrorMessage from '../../components/error-message';
import LoadingSpinner from '../../components/loading-spinner';
import styles from './style.module.scss';
import ConfirmEmailRegisterFormik from '../../forms/ConfirmEmailRegisterForm';
import RegisterInvitedTeamMemberForm from '../../forms/RegisterInvitedTeamMemberForm';
import MagicLinkWrapper from './components/MagicLinkWrapper';
import ExpiredAuthTokenView from './components/ExpiredAuthTokenView';
import { useAuthTokenRedirect } from './helpers/authHelpers';

const Register = ({ withConfirmation }: { withConfirmation?: boolean }) => {
  const { token, email } = useParams();
  const confirmationToken = token;
  const navigate = useNavigate();

  const { loading, data: invitationData, error: graphqlError } = useQuery(queries.getInvitationByToken, {
    skip: !token || withConfirmation,
    variables: {
      token: token!,
    },
  });

  console.log('invitation', invitationData);

  const authToken = invitationData?.getInvitationByToken?.onboarding?.loginAuthToken || undefined;
  const redirectTo = invitationData?.getInvitationByToken?.onboarding?.redirectTo || undefined;

  const { isLoadingToken, expiredToken } = useAuthTokenRedirect({ token: authToken, redirectTo });

  if (loading || isLoadingToken) {
    return !!token ? (
      <MagicLinkWrapper loading={loading} background={'dealsPage'}>
        <LoadingSpinner />
      </MagicLinkWrapper>
    ) : (
      <Wrapper>
        <LoadingSpinner />
      </Wrapper>
    );
  }

  if (graphqlError || expiredToken) {
    let error = graphqlErrorsToUiErrors(graphqlError);
    if (error.invitationToken?.id === 'form.error.api.invitation-cancelled') {
      // @ts-ignore
      error = {
        id: 'phrase.register.invitation-cancelled',
        defaultMessage: error.invitationToken.defaultMessage,
      };
    } else if (error._general?.id === 'api.error.not-found') {
      // @ts-ignore
      error = {
        id: 'phrase.register.token-invalid',
        defaultMessage: 'This invitation link is invalid.',
      };
    }
    return token ? (
      <ExpiredAuthTokenView token={token} />
    ) : (
      <Wrapper titleMessage={{ id: 'title.join-the-team', defaultMessage: 'Welcome to Propdocs!' }}>
        <p className="lead">
          <ErrorMessage>{error}</ErrorMessage>
        </p>
      </Wrapper>
    );
  }

  if (token && invitationData) {
    const invitation = invitationData.getInvitationByToken;
    if (invitation?.status === 'approved') {
      navigate(paths.home);
    }
    if (invitation!.status !== 'pending') {
      return (
        <Wrapper>
          <p className="lead">
            <FormattedMessage
              id="phrase.register.invitation-expired-or-revoked"
              defaultMessage="This invitation has expired, been revoked, or has already been used."
            />
          </p>
        </Wrapper>
      );
    }

    return (
      <MagicLinkWrapper
        loading={loading}
        background={'dealsPage'}
        titleMessage={{ id: 'title.join-the-team', defaultMessage: 'Welcome to Propdocs!' }}
      >
        <p className={styles.magicLinkSubHeader}>
          <FormattedMessage
            id="phrase.register.you-were-invited-to-join-team"
            defaultMessage={invitation?.onboarding.tagline}
            values={{
              b: (msg: string) => <strong>{msg}</strong>,
              senderName: invitation!.senderName,
              teamName: invitation!.teamName,
            }}
          />
        </p>
        <RegisterInvitedTeamMemberForm token={token} invitation={invitation} />
      </MagicLinkWrapper>
    );
  }

  return (
    <Wrapper
      titleMessage={confirmationToken ? { id: 'title.register', defaultMessage: 'Welcome to Propdocs' } : undefined}
      descriptionMessage={confirmationToken && `Create an account for ${email}`}
      className={styles['register-form-wrapper']}
      confirmationToken={confirmationToken}
    >
      {confirmationToken ? <RegisterForm confirmationToken={confirmationToken} /> : <ConfirmEmailRegisterFormik />}
    </Wrapper>
  );
};

interface WrapperProps {
  children?: ReactNode;
  titleMessage?: MessageDescriptor;
  descriptionMessage?: string;
  className?: string;
  confirmationToken?: string;
}

const Wrapper = ({
  children,
  titleMessage = { id: 'title.register', defaultMessage: 'Create your free account' },
  descriptionMessage = '',
  className,
  confirmationToken,
}: WrapperProps) => {
  const { formatMessage } = useIntl();
  return (
    <PublicLayout mainContentClassName={className} confirmationToken={confirmationToken}>
      <Helmet title={formatMessage(titleMessage)} />
      <h1 data-testid="sign-up" className="mb-0">
        {<FormattedMessage {...titleMessage} />}
      </h1>
      {descriptionMessage && (
        <p data-testid="sign-up-note" className="mb-4 font-weight-normal">
          {descriptionMessage}
        </p>
      )}
      {children}
    </PublicLayout>
  );
};

export default Register;
