import { permissionsComparator } from '../../utils/comparators';
import { Permissions } from '../../shared/constants/permissions';

export const mutatePermissions = permissions => permissions.sort(permissionsComparator);

export const filterPermissions = permission => {
  // The 'whitelist' permission isn't displayed in the normal way

  return permission.key !== Permissions.Whitelist;
};
