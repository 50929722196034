import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LeaseBadge } from '../../../components/lease-wizard/lease-badge/LeaseBadge';
import { getDocumentStatusIndicator } from '../../../components/document-table/helpers/documentStatusIndication';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import { DocumentTemplateType } from "../../../shared/templateType";

interface TemplateBadgeContainerProps {
  templateType: string | DocumentTemplateType
}

export const TemplateBadgeContainer = ({ templateType }: TemplateBadgeContainerProps) => {
  const badgeStatusIndicator = getDocumentStatusIndicator(templateType, false);

  return (
    <LeaseBadge documentStatus={DocumentStatusEnum.TEMPLATE}>
      <FormattedMessage
        id={`lease-document-wizard.badge.${DocumentStatusEnum.TEMPLATE}-offer`}
        defaultMessage={badgeStatusIndicator}
      />
    </LeaseBadge>
  );
};
