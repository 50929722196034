/**
 * Map a permission key to a permission name message object
 */
export const name = (permission: { key: string; name: string }) => {
  return {
    id: `permission.permission.${permission.key}.name`,
    defaultMessage: permission.name,
  };
};

/**
 * Map a permission key to a permission description message object
 */
export const description = (permission: { key: string; description: string }) => {
  return {
    id: `permission.permission.${permission.key}.description`,
    defaultMessage: permission.description,
  };
};
