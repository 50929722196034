import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LeaseDocumentWizardModal } from '../../../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import { cancelDocumentApprovalRequest } from '../../../../../../api/mutations';
import { getDocumentById } from '../../../../../../api/queries_v2';
import { useNotifications } from '../../../../../../notifications';
import { fillPlaceholders, paths } from '../../../../../../routing';
import { OptionalMessageInputRef } from '../../../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/OptionalMessageInputRef';
import styles from './RequestReview.module.scss';
import { cancelApprovalRequestSuccessMessage } from './RequestInternalApprovalModalConstants';

interface Props {
  onHide: () => void;
  show: boolean;
  documentId: string;
  dealId: string;
  teamId: string;
  requestId: string;
}

const CancelApprovalRequestModal = ({ onHide, show, documentId, dealId, requestId, teamId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const dealDeatilsUrl = fillPlaceholders(paths.deals.detail, {
    teamId,
    dealId,
  });
  const [cancelDocumentApprovalRequestMutation] = useMutation(cancelDocumentApprovalRequest, {
    refetchQueries: [
      {
        query: getDocumentById,
        variables: {
          documentId,
        },
      },
    ],
  });

  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const handleCancelApprovalRequest = useCallback(async () => {
    setIsLoading(true);
    const additionalMessage = additionalMessageInputRef.current?.value;

    try {
      await cancelDocumentApprovalRequestMutation({
        variables: {
          requestId,
          additionalMessage,
        },
      });
      navigate(dealDeatilsUrl);
      notifySuccess({
        message: formatMessage(cancelApprovalRequestSuccessMessage),
      });
    } catch (error) {
      notifyError({ message: error });
    } finally {
      setIsLoading(false);
    }
  }, [
    cancelDocumentApprovalRequestMutation,
    notifyError,
    requestId,
    dealDeatilsUrl,
    notifySuccess,
    formatMessage,
    navigate,
  ]);

  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onHide,
      label: 'Close',
      theme: 'outline-secondary',
    },
    {
      onClick: handleCancelApprovalRequest,
      label: 'Confirm',
      theme: 'secondary',
      isLoading,
      disabled: isLoading,
      className: styles['action-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={styles.title}
      size="lg"
      show={show}
      onHide={onHide}
      title={
        <FormattedMessage
          id="lease-document-wizard-modal.handle_internal_approval"
          defaultMessage="Cancel Approval Request"
        />
      }
      modalCTAs={modalCTAs}
    >
      <div className={styles.main}> Canceling will remove all received approvals</div>
      <OptionalMessageInputRef ref={additionalMessageInputRef} />
    </LeaseDocumentWizardModal>
  );
};

export default CancelApprovalRequestModal;
