export enum DocumentTemplateType {
  FULLY_EDITABLE = 'Fully Editable',
  PDF = 'PDF',
  RESTRICTED = 'Restricted',
}

export const getTemplateType = (documentTemplate: any): DocumentTemplateType => {
  if (documentTemplate.source === 'imported') {
    return DocumentTemplateType.PDF;
  }
  if (documentTemplate.editorConfiguration?.restricted_editing) {
    return DocumentTemplateType.RESTRICTED;
  }
  return DocumentTemplateType.FULLY_EDITABLE;
};
