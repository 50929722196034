import React from 'react';
import Button from 'react-bootstrap/Button';
import { ReactComponent as TrashcanIcon } from '../../../../shared/icons/trashcan-icon.svg';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

const RemoveTeamMemberSidebarButton = ({ onClick }: { onClick: () => void }) => (
  <p className="mb-0">
    <Button variant="link" className="text-danger pl-0 mt-2 mb-0" onClick={onClick}>
      <TrashcanIcon className="align-middle mr-1" />
      <TitleFormattedMessage id="cta.remove-team-member" defaultMessage="Remove from this deal" />
    </Button>
  </p>
);

export default RemoveTeamMemberSidebarButton;
