import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SendDocumentForSigning from './sendDocumentForSigning';
import { useApolloClient } from '@apollo/client';
import { startSigningDocument } from '../../../../utils/api/signing-lease-document-offer/signing-lease-document-offer';
import { SendForSigningSuccessMessage } from '../../../../consts/forms-constants';
import { useNotifications } from '../../../../notifications';
import { paths } from '../../../../routing';

interface SendDocumentForSigningContainerProps {
  show: boolean;
  onHide: () => void;
  documentId: any;
  dealId: string;
  teamId: string | null;
}

const SendDocumentForSigningContainer = ({
  show,
  onHide,
  documentId,
  dealId,
  teamId,
}: SendDocumentForSigningContainerProps) => {
  const [isRequestPending, setIsRequestPending] = useState(false);
  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [, { success: notifySuccess, error: notifyError }] = useNotifications();

  const handleConfirm = useCallback(async () => {
    const additionalMessage = additionalMessageInputRef.current?.value;
    setIsRequestPending(true);

    startSigningDocument({ documentId, additionalMessage })(apolloClient)
      .then(() => {
        const dealUrl = paths.deals.detail.replace(':teamId', teamId as string).replace(':dealId', dealId);
        navigate(dealUrl);
        notifySuccess({
          message: {
            id: 'lease-document-wizard-header.action.sign-now',
            defaultMessage: SendForSigningSuccessMessage.SEND_FOR_SIGNING,
          },
        });
      })
      .catch(error => {
        notifyError({
          id: 'deal.send-deal.error',
          message: {
            id: 'lease-document-wizard-header.action.sign-now',
            defaultMessage:
              'Document cannot be sent for signing until at least one signer is created and all signers have a signing location identified in the document.',
          },
        });
      })
      .finally(() => {
        setIsRequestPending(false);
      });
  }, [apolloClient, dealId, documentId, notifyError, notifySuccess, teamId, navigate]);

  return (
    <SendDocumentForSigning
      additionalMessageInputRef={additionalMessageInputRef}
      onHide={onHide}
      show={show}
      onConfirm={handleConfirm}
      isLoading={isRequestPending}
    />
  );
};

export default SendDocumentForSigningContainer;
