import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/client';
import { orderBy } from 'lodash';
import { LeaseDocumentWizardActionModal } from '../lease-wizard/lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/LeaseDocumentWizardActionModal';
import { terminateDocument } from '../../utils/api/terminate-lease-document-offer/terminate-document';
import { unsendDocument } from '../../utils/api/unsend-lease-document-offer/unsend-document';
import {
  setActiveModal,
  setShowGrantAccessModal,
  setShowRequestInternalReviewModal,
  setShowSendDocumentModal,
  setShowSendDocumentViaEmailModal,
  setShowSendForSigningPreparationModal,
} from '../../store/modals/modals.actions';
import { selectActiveModal } from '../../store/modals/modals.selectors';
import { checkDropdownActionAvailability } from './helpers/checkDropdownActionAvailability';
import { determineActionModalMessage } from './helpers/determineActionModalMessage';
import { downloadPdfHelper } from './helpers/downloadPdfHelper';
import {
  DocumentActionsDropdownProps,
  DocumentInterface,
  DropdownActions,
  ModalActionsEnum,
  ModalActionTitle,
  SendDocumentButtonMessage,
} from './DocumentTableConstants';
import {
  DocumentActionsFailure,
  DocumentActionsSuccess,
  INTERNAL_PREPARE_FOR_SIGNING_TOOLTIP,
  SHOULD_RETURN_FOR_RECEIVER_REVIEW_TOOLTIP,
  UNRESOLVED_SUGGESTIONS_TOOLTIP,
} from './helpers/messageConstants';
import DocumentActionsDropdown from './DocumentActionsDropdown';
import { useCheckIfDocumentIsReadyForSigning } from '../../shared/hooks/useCheckIfDocumentIsReadyForSigning';
import { DefaultNotificationMessages } from '../lease-wizard/DefaultNotificationMessages';
import { useNotifications } from '../../notifications';
import Tooltip from '../tooltip';
import { SendForSigningSuccessMessage } from '../../consts/forms-constants';
import { mutations } from '../../api';
import { getAlertableDocumentsByTeam, getDocumentById } from '../../api/queries_v2';
import SendToPreparingToSignInternalModal from '../lease-wizard/lease-document-wizard-header/components/headerActions/sendToPreparingToSignInternal/sendToPreparingToSignInternalModal';
import { ShareExecutedDocumentModal } from '../shared/modals';
import { DocumentStatusEnum } from '../../shared/constants/leaseDocument';
import { DocumentSourceEnum } from '../lease-wizard/lease-editor/constants/EditorConstats';
import { ApprovalRequestStatus } from '../lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/RequestInternalApprovalModalConstants';
import { REVIEW_VIA_EMAIL_TOOLTIP } from '../lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/RequestReviewContainer';
import CancelExternalSigningModal from '../lease-wizard/lease-document-wizard-header/components/headerActions/cancelExternalSigning/cancelExternalSigningModal';
import SendSigningReminderModal from '../lease-wizard/lease-document-wizard-header/components/headerActions/sendSigningReminder/sendSigningReminderModal';
import { SupportedFileTypes } from '../shared/modals/fileUploadModal/constants';
import { ImportedDocumentSupportedContentTypes } from '../shared/modals/fileUploadModal/types';

const DocumentActionsDropdownContainer = ({
  dealId,
  viewAction,
  permissions,
  documentData,
  isDealShared,
  isDocumentCreator,
  teamId,
  isDocPlaceholderComplete = false,
}: DocumentActionsDropdownProps) => {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const activeModal = useSelector(selectActiveModal);
  const optionalMessageInputRef = React.useRef<HTMLTextAreaElement>(null);
  const {
    id: documentId,
    documentSuggestions,
    source: docSource,
    generatedDocumentPdfUrl,
    importedAssetUrl,
    importedAssetContentType,
    eversignPdfUrl,
    approvalRequest,
    currentTeamPossession,
    isEditable,
    title,
    status,
    isEligibleForSigningPrep,
    shouldReturnForReceiverReview,
  } = {
    ...documentData,
    currentTeamPossession: !!documentData.currentTeamPossession,
  };

  const documentSource = docSource === 'app' ? DocumentSourceEnum.APP : DocumentSourceEnum.IMPORTED;
  const isApprovalRequestStatusPendingInPossession =
    approvalRequest?.status === ApprovalRequestStatus.PENDING && currentTeamPossession;
  const isDocumentLocked = approvalRequest?.status === ApprovalRequestStatus.APPROVED && !isEditable;
  const isDocumentInDraft = [DocumentStatusEnum.DRAFT, DocumentStatusEnum.UPLOADED, DocumentStatusEnum.SHARED].includes(
    status as DocumentStatusEnum
  );

  const [, { success: notifySuccess, error: notifyError }] = useNotifications();
  const [selectedOffer, setSelectedOffer] = React.useState<DocumentInterface | null>(null);
  const [isLoading, setLoading] = React.useState(false);
  const generatedPdfUrl = documentSource === DocumentSourceEnum.APP ? generatedDocumentPdfUrl : importedAssetUrl;
  const canShowModal = [
    ModalActionsEnum.SEND,
    ModalActionsEnum.UNSEND,
    ModalActionsEnum.TERMINATE,
    ModalActionsEnum.SEND_FOR_SIGNING,
    ModalActionsEnum.CANCEL_SIGNING,
  ].includes(activeModal as ModalActionsEnum);
  const { isDocumentReadyLoading, checkIfDocumentIsReadyForSigning } = useCheckIfDocumentIsReadyForSigning(documentId);
  const isSuggestionsArrayEmpty = !documentSuggestions.filter(suggestion => suggestion?.status !== 'accepted').length;
  const [prepareToSignDocument] = useMutation(mutations.prepareToSignDocument, {
    variables: { documentId },
    refetchQueries: [
      {
        query: getDocumentById,
        variables: {
          documentId,
        },
      },
      {
        query: getAlertableDocumentsByTeam,
        variables: {
          teamId,
          dealId,
        },
      },
    ],
  });
  const documentActionFailureResponse = useCallback(
    (failureMessage: string) => {
      notifyError({
        message: {
          id: 'document.action.error',
          defaultMessage: failureMessage,
        },
      });
    },
    [notifyError]
  );

  const documentActionSuccessfulResponse = useCallback(
    (successMessage: string) => {
      notifySuccess({
        message: {
          id: 'document.action.success',
          defaultMessage: successMessage,
        },
      });
    },
    [notifySuccess]
  );

  const permissionsHelperProps = {
    permissions,
    isDocumentCreator,
    ...documentData,
    currentTeamPossession: !!documentData.currentTeamPossession,
  };

  const handleSendForSigningClick = () => {
    if (isDocPlaceholderComplete) {
      handleActionModal(ModalActionsEnum.SEND_FOR_SIGNING, documentData);
    }
  }

  const allDropDownActions: DropdownActions = [
    {
      text: 'View',
      id: 'view-dropdown',
      onClick: viewAction,
      isAvailable: true,
    },
    {
      text: ModalActionTitle.GRANT_ACCESS,
      id: 'grant-access-dropdown',
      onClick: () => handleGrantAccess(),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.GRANT_ACCESS,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: isDocumentInDraft ? 'Request Review via Propdocs' : 'Request Counterparty Review',
      id: 'send-dropdown',
      onClick: () => handleSend(),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.SEND,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: (
        <div className="lease-document-tooltip-container">
          Request Review via Email
          <Tooltip testId="review-via-email-tooltip" placement="bottom">
            {REVIEW_VIA_EMAIL_TOOLTIP}
          </Tooltip>
        </div>
      ),
      id: 'review-via-email-dropdown',
      testId: 'testid-review-via-email-dropdown',
      onClick: () => handleSendViaEmail(),
      isAvailable:
        isDocumentInDraft &&
        isDocumentCreator &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.SEND_VIA_EMAIL,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: 'Request Internal Review',
      id: 'internal-approval-dropdown',
      onClick: () => handleRequestInternalApproval(),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        !isDocumentLocked &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.INTERNAL_APPROVAL,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: 'Un-send',
      id: 'unsend-dropdown',
      onClick: () => handleActionModal(ModalActionsEnum.UNSEND, documentData),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.UNSEND,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: 'Terminate',
      id: 'terminate-dropdown',
      onClick: () => handleActionModal(ModalActionsEnum.TERMINATE, documentData),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.TERMINATE,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: 'Download',
      id: 'download-dropdown',
      onClick: () =>
        downloadPdfHelper(
          eversignPdfUrl || generatedPdfUrl,
          title,
          importedAssetContentType
            ? (importedAssetContentType as ImportedDocumentSupportedContentTypes)
            : 'application/pdf'
        ),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.DOWNLOAD,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: (
        <div className="lease-document-tooltip-container">
          {SendDocumentButtonMessage.SEND_FOR_SIGNING}
          {!isSuggestionsArrayEmpty && (
            <Tooltip testId="lease-document-tooltip">{UNRESOLVED_SUGGESTIONS_TOOLTIP}</Tooltip>
          )}
        </div>
      ),
      id: 'signNow-dropdown',
      disabled: isDocumentReadyLoading || !isSuggestionsArrayEmpty,
      onClick: () => handleActionModal(ModalActionsEnum.SEND_FOR_SIGNING, documentData),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.SEND_FOR_SIGNING,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: (
        <div className="lease-document-tooltip-container">
          {SendDocumentButtonMessage.PREPARE_FOR_SIGNING}
          {!isEligibleForSigningPrep &&
            (shouldReturnForReceiverReview ? (
              <Tooltip testId="lease-document-tooltip">{SHOULD_RETURN_FOR_RECEIVER_REVIEW_TOOLTIP}</Tooltip>
            ) : (
              <Tooltip testId="lease-document-tooltip">{INTERNAL_PREPARE_FOR_SIGNING_TOOLTIP}</Tooltip>
            ))}
        </div>
      ),
      id: 'prepare-for-signing-dropdown',
      disabled:
        isDocumentReadyLoading ||
        !isEligibleForSigningPrep ||
        (documentData.status === DocumentStatusEnum.REVIEWING && !documentData.isEligibleForSigningPrep),
      // TODO: Change this to open a new modal that we will show to ask the user if the want to proceed or this based on the completion
      onClick: () => handleSendForSigningPrep(),//handleActionModal(ModalActionsEnum.INTERNAL_SEND_FOR_SIGNING, documentData),
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.INTERNAL_SEND_FOR_SIGNING,
          ...permissionsHelperProps,
          documentSource,
        }),
    },
    {
      text: 'Grant Counterparty Access',
      id: 'send-executed',
      isAvailable:
        !isApprovalRequestStatusPendingInPossession &&
        checkDropdownActionAvailability({
          action: ModalActionsEnum.SEND_EXECUTED,
          ...permissionsHelperProps,
          documentSource,
        }),
      onClick: () => handleActionModal(ModalActionsEnum.SEND_EXECUTED, documentData),
    },
    {
      text: 'Send Reminder',
      id: 'send-reminder-dropdown',
      onClick: () => handleActionModal(ModalActionsEnum.SEND_REMINDER, documentData),
      isAvailable: checkDropdownActionAvailability({
        action: ModalActionsEnum.SEND_REMINDER,
        ...permissionsHelperProps,
        documentSource,
      }),
    },
    {
      text: 'Cancel Signing',
      id: 'cancel-signing-dropdown',
      onClick: () => handleActionModal(ModalActionsEnum.CANCEL_SIGNING, documentData),
      isAvailable: checkDropdownActionAvailability({
        action: ModalActionsEnum.CANCEL_SIGNING,
        ...permissionsHelperProps,
        documentSource,
      }),
    },
  ];

  const handleUnsend = () => {
    const additionalMessage = optionalMessageInputRef.current?.value;
    if (selectedOffer) {
      setLoading(true);
      unsendDocument({ documentId: selectedOffer?.id, dealId, additionalMessage })(apolloClient)
        .then(() => documentActionSuccessfulResponse(DocumentActionsSuccess.UNSEND))
        .catch(() => documentActionFailureResponse(DocumentActionsFailure.UNSEND))
        .finally(() => {
          dispatch(setActiveModal(null));
          setLoading(false);
          setSelectedOffer(null);
        });
    }
  };

  const handleSendForSigning = async () => {
    const { isDocumentReadyForSigning } = await checkIfDocumentIsReadyForSigning();

    if (!isDocumentReadyForSigning) {
      return documentActionFailureResponse(DefaultNotificationMessages.NOT_READY_TO_BE_SIGNED);
    }

    setLoading(true);
    prepareToSignDocument()
      .then(() => {
        notifySuccess({ message: SendForSigningSuccessMessage.SEND_FOR_SIGNING });
      })
      .catch(error => notifyError({ id: 'deal.send-deal.error', message: error }))
      .finally(() => {
        dispatch(setActiveModal(null));
        setLoading(false);
        setSelectedOffer(null);
      });
  };

  const handleSend = () => {
    setLoading(true);
    viewAction();
    dispatch(setShowSendDocumentModal(true));
  };

  const handleSendViaEmail = () => {
    setLoading(true);
    viewAction();
    dispatch(setShowSendDocumentViaEmailModal(true));
  };

  const handleSendForSigningPrep = () => {
    setLoading(true);
    viewAction();
    dispatch(setShowSendForSigningPreparationModal(true));
  };

  const handleRequestInternalApproval = () => {
    setLoading(true);
    viewAction();
    dispatch(setShowRequestInternalReviewModal(true));
  };

  const handleGrantAccess = () => {
    setLoading(true);
    viewAction();
    dispatch(setShowGrantAccessModal(true));
  };

  const handleTerminate = () => {
    const additionalMessage = optionalMessageInputRef.current?.value;

    setLoading(true);

    terminateDocument({ documentId: selectedOffer?.id, dealId, additionalMessage })(apolloClient)
      .then(() => documentActionSuccessfulResponse(DocumentActionsSuccess.TERMINATE))
      .catch(() => documentActionFailureResponse(DocumentActionsFailure.TERMINATE))
      .finally(() => {
        dispatch(setActiveModal(null));
        setLoading(false);
        setSelectedOffer(null);
      });
  };

  const handleActionModal = (name: string, offer: any) => {
    setSelectedOffer(offer);
    dispatch(setActiveModal(name as ModalActionsEnum));
  };

  const modalType = activeModal ? activeModal.split('_').pop() : null;

  const EVENTS: { [key: string]: { title: string; handler: () => void } } = {
    terminate: {
      title: ModalActionTitle.TERMINATE,
      handler: handleTerminate,
    },
    send: {
      title: ModalActionTitle.SEND,
      handler: handleSend,
    },
    unsend: {
      title: ModalActionTitle.UNSEND,
      handler: handleUnsend,
    },
    // signing: {
    //   title: ModalActionTitle.SEND_FOR_SIGNING,
    //   handler: handleSendForSigningPrep,//handleSendForSigning,
    // },
  };

  const onModalHide = () => {
    setSelectedOffer(null);
    dispatch(setActiveModal(null));
  };

  const determineHandler = () => {
    if (!modalType) return;

    return EVENTS[modalType]?.handler || null;
  };

  const determineTitle = () => {
    if (!modalType) return;

    return EVENTS[modalType]?.title || '';
  };

  const renderModals = () => {
    if (!selectedOffer) return null;

    return (
      <>
        <LeaseDocumentWizardActionModal
          show={canShowModal}
          onHide={onModalHide}
          isLoading={isLoading}
          handler={determineHandler()}
          title={determineTitle()}
          activeModal={activeModal}
          isDealShared={isDealShared}
          optionalMessageInputRef={optionalMessageInputRef}
          teamId={teamId}
        >
          {determineActionModalMessage(modalType || null)}
        </LeaseDocumentWizardActionModal>

        {/* <SendToPreparingToSignInternalModal
          onHide={onModalHide}
          show={activeModal === ModalActionsEnum.INTERNAL_SEND_FOR_SIGNING}
          documentId={documentId}
          dealId={dealId}
          teamId={teamId}
        /> */}

        <ShareExecutedDocumentModal
          show={activeModal === ModalActionsEnum.SEND_EXECUTED}
          onHide={onModalHide}
          documentId={documentId}
          dealId={dealId}
          teamId={teamId}
        />
        <CancelExternalSigningModal
          show={activeModal === ModalActionsEnum.CANCEL_SIGNING}
          onHide={onModalHide}
          documentId={documentId}
          dealId={dealId}
          teamId={teamId}
        />
        <SendSigningReminderModal
          show={activeModal === ModalActionsEnum.SEND_REMINDER}
          onHide={onModalHide}
          documentId={documentId}
          dealId={dealId}
          teamId={teamId}
        />
      </>
    );
  };

  return (
    <>
      <DocumentActionsDropdown allDropDownActions={orderBy(allDropDownActions, 'order')} />
      {renderModals()}
    </>
  );
};

export default DocumentActionsDropdownContainer;
