import React, { useEffect, useState } from 'react';
import MobileSidebar from '../../../../components/mobile-layout/mobile-sidebar';
import { FormattedMessage } from 'react-intl';
import DropdownPanel from '../../../../components/dropdown-panel';
import { CheckboxOrRadio, RadioField } from '../../../../components/checkbox-or-radio-field';
import { SortingForm } from './SortingForm';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../../../../components/titleFormattedMessage';

import styles from './styles.module.scss';
import { DealForm } from './DealForm';

export interface DealForFilter {
  title: string;
  id: string;
}

interface FilterSidebarProps {
  isOpen: boolean;
  filterDeals: Set<any>;
  allDeals: DealForFilter[];
  onClose: () => void;
  setFilterDeals: (value: Set<string>) => void;
  setSorting: (value: string) => void;
}

export interface FilterData {
  sort: string;
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  isOpen,
  onClose,
  allDeals,
  filterDeals,
  setSorting,
  setFilterDeals,
}) => {
  const [deals, setDeals] = useState(filterDeals);

  useEffect(() => {
    if (isOpen) {
      setDeals(filterDeals);
    }
  }, [isOpen]);

  const onSetDeals = (value: Set<string>) => {
    setDeals(value);
  };

  const onSubmit = async (values: FilterData, actions: any) => {
    setSorting(values.sort);
    setFilterDeals(deals);
    onClose();
  };

  const onReset = (resetForm: any) => {
    resetForm && resetForm();
    setFilterDeals(new Set());
    onClose();
  };

  return (
    <MobileSidebar
      header={
        <div data-testid="deals-header" role={'heading'} className={'ml-3'}>
          <FormattedMessage id="title.filter" defaultMessage="Filter" />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik onSubmit={onSubmit} initialValues={{ sort: '' }}>
        {formikProps => (
          <Form noValidate className={styles.mobileFilterForm}>
            <div>
              <DropdownPanel title="Sorting" hasBorder>
                <SortingForm />
              </DropdownPanel>
              <DropdownPanel title="Deals">
                <DealForm deals={allDeals} filtered={deals} setFilterDeals={onSetDeals} />
              </DropdownPanel>
            </div>
            <div className={styles.mobileFilterBtns}>
              <Button onClick={() => onReset(formikProps.resetForm)} variant="btn btn-outline-primary shadow-none">
                <TitleFormattedMessage id="mobileFilter.clearBtn" defaultMessage="Clear" />
              </Button>
              <Button type="submit" variant="btn btn-primary shadow-none">
                <TitleFormattedMessage id="mobileFilter.applyBtn" defaultMessage="Apply" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </MobileSidebar>
  );
};

export default FilterSidebar;
