import { ApolloClient } from '@apollo/client';
import { graphql } from '../../gql';
import { ResendMagicLinkMutationPayload } from '../../gql/graphql';

export const resendMagicLink = ({ token }: { token: string; }) => (client: ApolloClient<object>): Promise<ResendMagicLinkMutationPayload> => {
  const mutation = graphql(/* GraphQL */`
    mutation resendMagicLink($token: String!) {
      resendMagicLink(token: $token) {
        message,
        success
      }
    }
  `);

  return client
    .mutate({
      mutation,
      variables: { token }
    })
    .then(({ data }: Record<any, any>) => {
      return data.resendMagicLink;
    });
};
