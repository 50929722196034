import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentOriginalImportedAssetUrlAction } from '../actions/lease-document.actions';

export const handleSetDocumentOriginalImportedAssetUrl = (
  state: LeaseDocumentStateType,
  action: SetDocumentOriginalImportedAssetUrlAction
): LeaseDocumentStateType => ({
  ...state,
  originalImportedAssetUrl: action.url,
});
