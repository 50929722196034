import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationTypes } from '../../components/lease-wizard/lease-editor/components/signersSidebar/components/insertFields/insertFields';
import { generateBase64StringFromSVGComponent } from '../../components/lease-wizard/lease-editor/components/signersSidebar/components/insertFields/helpers';
import { DateSvg, InitialsSvg, SignatureSvg, TextSvg } from './components/svgBlocks';
import { ImportedDocumentSupportedContentTypes } from "../../components/shared/modals/fileUploadModal/types";
import { AnnotationType } from '../uploadedWebViewer/helpers/constants';

const AnnotationsParamsByAnnotationType = {
  [AnnotationTypes.TEXT]: {
    ICON: TextSvg,
    TITLE: 'Free Text',
    WIDTH: 160,
    HEIGHT: 50,
  },
  [AnnotationTypes.DATE]: {
    ICON: DateSvg,
    TITLE: 'Date Signed',
    WIDTH: 80,
    HEIGHT: 25,
  },
  [AnnotationTypes.INITIALS]: {
    ICON: InitialsSvg,
    TITLE: 'Initials',
    WIDTH: 50,
    HEIGHT: 50,
  },
  [AnnotationTypes.SIGNATURE]: {
    ICON: SignatureSvg,
    TITLE: 'Signature',
    WIDTH: 160,
    HEIGHT: 50,
  },
};

export const disableUiFeatures = (instance: WebViewerInstance, allowManipulation: boolean = false) => {
  let featuresToDisable = [
    instance.UI.Feature.ThumbnailMerging,
    instance.UI.Feature.MultipleViewerMerging,
    /* described https://www.pdftron.com/documentation/web/guides/manipulation/thumbnails-controls/#selecting-multiple-pages,
    however TS complains about not seeing in types
    @ts-ignore */
    instance.UI.Feature.ThumbnailMultiselect,
  ];

  if (!allowManipulation) {
    featuresToDisable.push(instance.UI.Feature.ThumbnailReordering);
  }

  // @ts-ignore
  instance.UI.disableFeatures(featuresToDisable);

  let elementsToDisable = [
    'annotationCommentButton',
    'ribbons',
    'documentControl',
    'squigglyToolGroupButton',
    'shapeToolGroupButton',
    'redoButton',
    'underlineToolGroupButton',
    'toolsOverlay',
  ];

  if (!allowManipulation) {
    elementsToDisable.push('thumbnailControl');
  }
  else {
    const moreOptionsContextItemsToDisable = [
      'thumbRotateClockwise',
      'thumbDelete',
      'pageAdditionalControlsHeader',
      'moveToTop',
      'moveToBottom',
      'pageRotationHeader',
      'rotatePageClockwise',
      'rotatePageCounterClockwise',
      'replacePage',
      'extractPage',
      'insertBlankPagePanelButton',
      'insertBlankPagePanel',
    ];
    elementsToDisable = elementsToDisable.concat(moreOptionsContextItemsToDisable);
  }

  instance.UI.disableElements(elementsToDisable);

  //instance.UI.setToolbarGroup('toolbarGroup-Annotate');

  // disabling right click context menus
  instance.UI.disableElements(['contextMenuPopup']);
  instance.UI.pageManipulationOverlay.disableOpeningByRightClick();

  // hiding all iconx except delete on the annotation
  instance.UI.disableElements(['linkButton']);

  // // disable states for comment-actions
  instance.UI.disableElements([
    'notePopupStateCompleted',
    'notePopupStateNone',
    'notePopupStateCancelled',
    'notePopupStateMarked',
    'notePopupStateUnmarked',
  ]);

  instance.UI.enableElements(['strikeoutToolGroupButton']);
};

export const createAnnotationBlock = (
  instance: WebViewerInstance,
  position: { x: number; y: number },
  annotationType: AnnotationTypes,
  zoom: number
): Core.Annotations.StampAnnotation | Core.Annotations.FreeTextAnnotation | null => {
  const {
    Core: { Annotations, documentViewer },
  } = instance;
  const scrollElement = documentViewer.getScrollViewElement();
  const scrollLeft = scrollElement.scrollLeft || 0;
  const scrollTop = scrollElement.scrollTop || 0;
  const point = { x: position.x + scrollLeft, y: position.y + scrollTop };
  const doc = documentViewer.getDocument();
  const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
  const page = displayMode.getSelectedPages(point, point);
  if (!!point.x && page.first == null) {
    return null; //don't add field to an invalid page location
  }
  const page_idx = page.first !== null ? page.first : documentViewer.getCurrentPage();
  const page_info = doc.getPageInfo(page_idx);
  const page_point = displayMode.windowToPage(point, page_idx);

  const annotation = [AnnotationTypes.SIGNATURE, AnnotationTypes.INITIALS].includes(annotationType)
    ? new Annotations.StampAnnotation()
    : new Annotations.FreeTextAnnotation();
  annotation.PageNumber = page_idx;
  annotation.disableRotationControl();
  annotation.MaintainAspectRatio = false;
  annotation.FillColor = new Annotations.Color(211, 211, 211, 0.5);
  annotation.StrokeThickness = 1;
  annotation.StrokeColor = new Annotations.Color(0, 165, 228);
  const blockWidth = AnnotationsParamsByAnnotationType[annotationType].WIDTH;
  const blockHeight = AnnotationsParamsByAnnotationType[annotationType].HEIGHT;
  annotation.Height = blockHeight / zoom;
  annotation.Width = blockWidth / zoom;
  annotation.X = (page_point.x || page_info.width / 2) - annotation.Width / 2;
  annotation.Y = (page_point.y || page_info.height / 2) - annotation.Height / 2;

  return annotation;
};

export const setAnnotationParametersByAnnotationType = (
  annotation: Core.Annotations.StampAnnotation | Core.Annotations.FreeTextAnnotation,
  annotationType: AnnotationTypes,
  viewerInstance: WebViewerInstance
): void => {
  const { Core } = viewerInstance;
  annotation.MaintainAspectRatio = [AnnotationTypes.SIGNATURE, AnnotationTypes.INITIALS].includes(annotationType);

  if ([AnnotationTypes.INITIALS, AnnotationTypes.SIGNATURE].includes(annotationType)) {
    const svgComponent = AnnotationsParamsByAnnotationType[annotationType].ICON;
    (annotation as Core.Annotations.StampAnnotation).setImageData(
      generateBase64StringFromSVGComponent(svgComponent),
      true
    );

    return;
  }

  if (annotationType === AnnotationTypes.DATE) {
    (annotation as Core.Annotations.FreeTextAnnotation).TextAlign = 'center';
    (annotation as Core.Annotations.FreeTextAnnotation).TextVerticalAlign = 'center';
  }
  annotation.NoResize = annotationType === AnnotationTypes.DATE;
  (annotation as Core.Annotations.FreeTextAnnotation).TextColor = new Core.Annotations.Color(0, 0, 0);
  (annotation as Core.Annotations.FreeTextAnnotation).FontSize = '12px';
  (annotation as Core.Annotations.FreeTextAnnotation).setContents(getAnnotationTitleByAnnotationType(annotationType));
  annotation.FillColor = new Core.Annotations.Color(175, 175, 175);
  annotation.StrokeColor = new Core.Annotations.Color(175, 175, 175);
  annotation.LockedContents = true;
};

export const getAnnotationTitleByAnnotationType = (type: AnnotationTypes) => {
  return AnnotationsParamsByAnnotationType[type]?.TITLE || '';
};

export const disableFormFieldsInDocument = (instance: WebViewerInstance) => {
  const {
    Core: { documentViewer, Annotations },
  } = instance;
  const annotationManager = documentViewer.getAnnotationManager();

  const disableFormFields = () => {
    const fieldManager = annotationManager.getFieldManager();

    fieldManager.forEachField(field => disableFormField(field));

    annotationManager.getAnnotationsList().forEach(annotation => {
      if (annotation instanceof Annotations.CheckButtonWidgetAnnotation) {
        //@ts-ignore
        Annotations.WidgetAnnotation.getCustomStyles = widget => {
          if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
            const isReadonly = !!widget.fieldFlags.get(Annotations.WidgetFlags['READ_ONLY']);

            return {
              backgroundColor: isReadonly && '#8c8c8c',
            };
          }
        };
      }
    });
  };

  const disableFormField = (field: any) => {
    if (field.widgets) {
      field.widgets.forEach((widget: any) => {
        widget.fieldFlags.set('ReadOnly', true);
        widget.ReadOnly = true;
        widget.Locked = true;
        widget.LockedContents = true;
      });
    }
    if (field.children) {
      field.children.forEach((field: any) => disableFormField(field));
    }
  };

  documentViewer.addEventListener('annotationsLoaded', async () => disableFormFields());

  disableFormFields();
};

interface FixPdfDocumentRotationProps {
  instance: WebViewerInstance;
  formFieldsUrl?: string | null;
  isDocumentSigning: boolean;
  annotationsStringUrl: string | null;
  document: {
    url: string,
    contentType: ImportedDocumentSupportedContentTypes,
    status: string,
    id: string,
    title: string
  };
  teamId: string;
  replaceImportDocument: any;
}

interface ReorderPdfPagesProps {
  instance: WebViewerInstance;
  document: {
    url: string,
    contentType: ImportedDocumentSupportedContentTypes,
    status?: string | null,
    id: string,
    title: string,
  };
  teamId: string;
  replaceImportDocument: any;
  isTemplate?: boolean;
  saveAnnotations?: any;
  saveFormFields: any;
  saveSignaturesLayer?: any;
  saveComments?: any;
  licenseKey: string;
}

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  const bytes = new Uint8Array(buffer);
  let binary = '';

  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

/**
 * reorderPdfPages() - a helper function that will re-attach the imported asset.
 * @param instance
 * @param document
 * @param teamId
 * @param replaceImportDocument
 */
export const reorderPdfPages = async ({
  instance,
  document,
  teamId,
  isTemplate=false,
  replaceImportDocument,
  saveAnnotations,
  saveFormFields,
  saveSignaturesLayer,
  saveComments,
  licenseKey
}: ReorderPdfPagesProps) => {
  const { Core: { documentViewer, PDFNet } } = instance;

  const annotationManager:Core.AnnotationManager = documentViewer.getAnnotationManager();

  const doc = await documentViewer.getDocument().getPDFDoc();;

  await PDFNet.runWithCleanup(async () => {
    await doc.lock();

    doc.refreshAnnotAppearances();

    const pdfBuffer = await doc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);

    console.log('replacing doc!');

    let mutationVariables;

    if (isTemplate) {
      mutationVariables = {
        teamId: teamId,
        templateId: document.id,
        fileData: {
          content: arrayBufferToBase64(pdfBuffer),
          contentEncodingType: 'base64',
          contentType: document.contentType
        }
      }
    }
    else {
      mutationVariables = {
        teamId: teamId,
        documentId: document.id,
        fileData: {
          content: arrayBufferToBase64(pdfBuffer),
          contentEncodingType: 'base64',
          contentType: document.contentType
        }
      }
    }
    

    await replaceImportDocument({
      variables: mutationVariables
    });

    const strikeoutAnnotList = annotationManager
    .getAnnotationsList()
    .filter(annot => annot.Subject === AnnotationType.STRIKEOUT);

    const freetextAnnotList = annotationManager
      .getAnnotationsList()
      .filter(annot => annot.Subject === AnnotationType.FREE_TEXT);

    const noteAnnotList = annotationManager
      .getAnnotationsList()
      .filter(annot => annot.Subject === AnnotationType.NOTE);

    const fieldsString = await annotationManager.exportAnnotations({
      links: false,
      widgets: true,
      annotList: [...strikeoutAnnotList, ...freetextAnnotList],
      fields: true,
    });

    const annotationsString = await annotationManager.exportAnnotations({
      links: false,
      annotList: [...noteAnnotList],
      widgets: false,
      fields: false,
    });


    if (saveFormFields) {
      let mutationVariables;

      if(isTemplate) {
        mutationVariables = {
          documentTemplateId: document.id,
          xfdfString: fieldsString,
        }
      } else {
        mutationVariables = {
          documentId: document.id,
          xfdfString: fieldsString,
        }
      }

      await saveFormFields({ variables: mutationVariables });
    }

    if(saveAnnotations) {
      let mutationVariables;

      if(isTemplate) {
        mutationVariables = {
          documentTemplateId: document.id,
          xfdfString: annotationsString,
        }
      } else {
        mutationVariables = {
          documentId: document.id,
          xfdfString: annotationsString,
        }
      }

      await saveAnnotations({ variables: mutationVariables });
    }

    if (saveComments) {
      const xfdfCommentsString = await annotationManager.exportAnnotations({
        links: false,
        widgets: false,
        fields: false,
      });

      if(isTemplate) {
        mutationVariables = {
          documentTemplateId: document.id,
          xfdfString: xfdfCommentsString,
        }
      } else {
        mutationVariables = {
          documentId: document.id,
          xfdfString: xfdfCommentsString,
        }
      }

      await saveComments({ variables: mutationVariables});
    }
  }, licenseKey);
};

/**
 * fixPdfDocumentRotation() - a helper function that will fix the rotation of a PDF button if it is rotated incorrectly.
 * Some imported PDF docs are rotated (90, 180, 270 degrees) even when the document looks fine in the Apryse editor,
 * when sent for signing the drag-n-drop fields will be rotated the same as the docs original rotation and position.
 * @param instance
 * @param isDocumentSigning
 * @param annotationsStringUrl
 * @param formFieldsUrl
 * @param document
 * @param teamId
 * @param replaceImportDocument
 */
export const fixPdfDocumentRotation = async ({
  instance,
  isDocumentSigning,
  annotationsStringUrl,
  formFieldsUrl,
  document,
  teamId,
  replaceImportDocument,
}: FixPdfDocumentRotationProps) => {
  const { Core: { documentViewer, PDFNet } } = instance;
  const annotationManager:Core.AnnotationManager = documentViewer.getAnnotationManager();

  documentViewer.addEventListener('annotationsLoaded', async () => {
    const annotationsPromises = [];

    if (formFieldsUrl) {
      const promise1 = fetch(formFieldsUrl)
        .then(res => res.text())
        .then(text => annotationManager.importAnnotations(text));
      annotationsPromises.push(promise1);
    }

    if (!isDocumentSigning && annotationsStringUrl) {
      const promise2 = fetch(annotationsStringUrl)
        .then(res => res.text())
        .then(text => annotationManager.importAnnotations(text));
      annotationsPromises.push(promise2);
    }

    await Promise.all(annotationsPromises);

    if (documentViewer.getCompleteRotation(1) !== 0) {
      const doc: Core.PDFNet.PDFDoc = await PDFNet.PDFDoc.create();
      const uploadedDoc: Core.PDFNet.PDFDoc = await documentViewer.getDocument().getPDFDoc();

      await PDFNet.runWithCleanup(async () => {
        await uploadedDoc.lock();

        const stamper: Core.PDFNet.Stamper = await PDFNet.Stamper.create(PDFNet.Stamper.SizeType.e_relative_scale, 1, 1);
        const pageCount = documentViewer.getPageCount();

        await Promise.all(
          Array(pageCount).fill(0).map(async (_, i) => {
            const index = i + 1;
            const srcPage: Core.PDFNet.Page = await uploadedDoc.getPage(index);
            const pgSet: Core.PDFNet.PageSet = await PDFNet.PageSet.createRange(index, index);
            const page: Core.PDFNet.Page = await doc.pageCreate();
            await doc.pagePushBack(page);

            await stamper.setAlignment(PDFNet.Stamper.HorizontalAlignment.e_horizontal_left, PDFNet.Stamper.VerticalAlignment.e_vertical_bottom);
            await stamper.stampPage(doc, srcPage, pgSet);
          })
        );

        const pdfBuffer = await doc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);

        await replaceImportDocument({
          variables: {
            teamId,
            documentId: document.id,
            fileData: {
              content: arrayBufferToBase64(pdfBuffer),
              contentEncodingType: 'base64',
              contentType: document.contentType
            }
          }
        });

        // Upload the new document to the server, load the new document to the viewer
        instance.UI.loadDocument(doc);

      }, 'demo:1697064776005:7ce57cf50300000000cb73224c467e7d737bb193497b922907b9a9f29f');
    }
  });
};
