import { useEffect } from 'react';
import { DOCUMENT_ACTIVITY_ITEM_BUTTON_NAME } from '../components/lease-wizard/lease-editor/components/documentActivitySidebar/components/constants';
import { SIGNER_ITEM_BUTTON_NAME } from '../components/lease-wizard/lease-editor/components/signersSidebar/components/signerItem/constants';

/**
 * Detect click-off events and escape key presses. Useful for triggering close behaviour on navs and popouts.
 *
 * @function useOnClickOff
 * @param {Ref} containerRef - The container within which clicks will not trigger 'onClickOff'
 * @param {CallableFunction} onClickOff - The function that will be triggered when a click off or escape key press is detected.
 * @returns {null}
 */
export default function useOnClickOff(containerRef, onClickOff) {
  useEffect(() => {
    const onClick = e => {
      const activeModal = document.getElementsByClassName('fade modal');

      if (!!activeModal[0] && activeModal[0].contains(e.target)) {
        return;
      }

      const isSignerButton = e.path?.find(
        node => node.name === SIGNER_ITEM_BUTTON_NAME || node.name === DOCUMENT_ACTIVITY_ITEM_BUTTON_NAME
      );

      if (containerRef.current.compareDocumentPosition(e.target) >= 20 || e.target.href || !!isSignerButton) return;
      onClickOff();
    };
    const onKeyup = e => {
      // escape
      if (e.keyCode === 27) onClickOff();
    };
    document.addEventListener('click', onClick);
    document.addEventListener('keyup', onKeyup);

    return function cleanup() {
      document.removeEventListener('click', onClick);
      document.removeEventListener('keyup', onKeyup);
    };
  });
}
