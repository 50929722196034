export enum OrgatizationMemberType {
  team = 'team',
  user = 'user',
}

export enum OrgatizationMembership {
  team = 'Team',
  user = 'Individual',
}

export type OrgatizationMembershipType = keyof typeof OrgatizationMembership;

export enum RequestMembershipType {
  id = 'requestOrganizationAccess.membershipType',
  defaultMessage = 'Type',
}

export enum RequestMembershipOrganization {
  id = 'requestOrganizationAccess.organization',
  defaultMessage = 'Organization',
}

export const EXPIRY_DATE_CHANGE_MESSAGE = 'Update the expiry date for this membership to change the status';

export const USER_PAID_SUBSCRIPTION_MESSAGE =
  'User has paid for a subscription to your organization and cannot be removed';

export const MEMBERSHIP_TYPE_MESSAGE =
  "Team requests provide all users on your team access to this Organization's templates.";

export const ORGANIZATION_MEMBER_ONLY_MESSAGE = 'This orgnaization only provides access its members';

export enum RequestStatuses {
  PENDING = 'pending',
  ACTIVE = 'approved',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  PAID = 'Paid',
}

export enum HandleRequestNotification {
  APPROVED = 'Membership is now active',
  REJECTED = 'Membership has been rejected',
}

export type OrganizationRequestsType = {
  id: string | number;
  requesterType: string;
  requesterId: string | number;
  user: {
    id: string | number;
    fullName: string;
  };
  membershipId: string | number;
  status: string;
  requesterName: string;
  organization: OrganizationType;
};

export type OrganizationType = {
  id: string | number;
  name: string;
  adminTeam: {
    id: string | number;
  };
  organizationRequests: OrganizationRequestsType;
};
