import React, { useState } from 'react';
import styles from '../style.module.scss';
import Logotext from '../../../components/logotext';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { resendMagicLink } from '../../../utils/api/resend-magic-link';
import { useApolloClient } from '@apollo/client';

interface WrapperProps {
  token: string;
}

const ExpiredAuthTokenView = ({ token }: WrapperProps) => {
  const [isTokenSent, setIsTokenSent] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const apolloClient = useApolloClient();

  const onSubmit = async () => {
    const { success, message } = await resendMagicLink({ token })(apolloClient)
    if (message) {
      setResponseMessage(message);
    }
  }

  if (!isTokenSent) {
    return (
      <div className={styles.expiredTokenContainer}>
        <Logotext isFull />
        <h1>
          <FormattedMessage id="title.expiredTokenHeader" defaultMessage="Sorry, this link has expired." />
        </h1>
        <h2>
          <FormattedMessage id="title.expiredTokenSubHeader" defaultMessage="Click the button below to get a new link." />
        </h2>
        <Button variant={'secondary'} onClick={async () => {
          await onSubmit()
          setIsTokenSent(true);
        }}>
          <FormattedMessage id="title.expiredTokenCTA" defaultMessage="Send New Link" />
        </Button>
        <span className={styles.expiredContactText}>If the issue continues to persist, please <a href="https://help.propdocs.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">let us know</a></span>
      </div>
    )
  }

  return (
    <div className={styles.expiredTokenContainer}>
      <Logotext isFull />
      <h1>
        <FormattedMessage id="title.expiredTokenHeader" defaultMessage="Check your inbox" />
      </h1>
      <h2>
        <FormattedMessage id="title.expiredTokenSubHeader" defaultMessage={responseMessage} />
      </h2>
      <span className={styles.expiredContactText}>If the issue continues to persist, please <a href="https://help.propdocs.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">let us know</a></span>
    </div>
  )
};

export default ExpiredAuthTokenView;
