export enum ActivityCardEnum {
  ID = 'card.activity.title',
  DEFAULT_MESSAGE = 'Activity',
  BOOTSTRAP_WIDTH = '12',
}

export enum DealCreatedEnum {
  ID = 'card.activity.deal-created',
  DEFAULT_MESSAGE = 'Deal Created',
}

export enum ReceiverInvitedEnum {
  ID = 'card.activity.deal-receiverInvited',
  DEFAULT_MESSAGE = 'Invited',
}

export enum ReceiverAcceptedInviteEnum {
  ID = 'card.activity.deal-receiverAcceptedInvite',
  DEFAULT_MESSAGE = 'Accepted Invite',
}

export enum DocumentSignedEnum {
  ID = 'card.activity.deal-documentSigned',
  DEFAULT_MESSAGE = 'Signed',
}
