import React, { ExoticComponent, Fragment, ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Field, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import ErrorMessage from '../components/error-message';
import styles from '../pages/deals/styles.module.scss';

interface InviteReceivingTeamFormProps {
  bodyContainer: ExoticComponent<{ children?: ReactNode }>;
  actionsContainer: ExoticComponent<{ children?: ReactNode }>;
  renderSecondaryAction: any;
  errors: any;
  isSubmitting: boolean;
  isValid: boolean;
  shouldDocumentBeShared: boolean;
  shouldAccessBeGranted?: boolean;
}

const InviteReceivingTeamForm = ({
  bodyContainer: BodyContainer = Fragment,
  actionsContainer: ActionsContainer = Fragment,
  renderSecondaryAction,
  errors,
  isSubmitting,
  isValid,
  shouldDocumentBeShared,
  shouldAccessBeGranted,
}: InviteReceivingTeamFormProps) => {
  return (
    <Form noValidate>
      <div className={shouldDocumentBeShared ? styles.modalBody__shareDocument : styles.modalBody}>
        {!shouldAccessBeGranted ? (
          <BodyContainer>
            {shouldDocumentBeShared ? (
              <div>
                Send both the document and deal by inviting the primary contact from the counterparty. Your team will
                not be permitted to edit the document until the counterparty sends it back.
              </div>
            ) : (
              <div className={styles.modalBody__text}>
                Invite the primary contact from the counterparty to access the deal.
              </div>
            )}
          </BodyContainer>
        ) : (
          <BodyContainer>
            {shouldDocumentBeShared ? (
              <div>
                Send both the document and deal by inviting the primary contact from the counterparty. Both teams will
                be able to access the document.
              </div>
            ) : (
              <div className={styles.modalBody__text}>Both teams will be able to access the document.</div>
            )}
          </BodyContainer>
        )}

        <BodyContainer>
          {errors._general && (
            <Alert variant="danger">
              <ErrorMessage>{errors._general}</ErrorMessage>
            </Alert>
          )}

          <Row noGutters className="justify-content-center flex-nowrap">
            <Col>
              <TextField
                //@ts-ignore TODO update TextField to ts
                id="inviteReceivingTeamForm.email"
                name="email"
                type="email"
                label={<FormattedMessage id="inviteReceivingTeamForm.email" defaultMessage="Email" />}
              />
              <div className={styles.dealModalInputWrapper}>
                <Field
                  className={styles.dealModalInputWrapper__input}
                  id="inviteReceivingTeamForm.additionalMessage"
                  name="additionalMessage"
                  component="textarea"
                  placeholder="Message (Optional)"
                />
              </div>
            </Col>
          </Row>
        </BodyContainer>
        <ActionsContainer>
          <Row noGutters className="align-items-end">
            <Col>
              {renderSecondaryAction?.()}
              <span className="lease-document-wizard-cta-stack__button">
                <SubmitButton
                  label={
                    <FormattedMessage
                      id="cta.send-deal"
                      defaultMessage={shouldDocumentBeShared ? 'Send' : 'Send Deal'}
                    />
                  }
                  disabled={isSubmitting || !isValid}
                  isSubmitting={isSubmitting}
                />
              </span>
            </Col>
          </Row>
        </ActionsContainer>
      </div>
    </Form>
  );
};

export default InviteReceivingTeamForm;
