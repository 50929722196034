import { ApolloClient } from '@apollo/client';
import { SaveLeaseDocumentTemplate } from '../lease-document-interface.types';
import { graphql } from '../../../gql';

export const updateDocumentTemplate: SaveLeaseDocumentTemplate = variables => (client: ApolloClient<object>) => {
  const mutation = graphql(/* GraphQL */`
    mutation updateDocumentTemplate($documentTemplateId: ID!, $name: String, $template: Json, $hidden: Boolean) {
      updateDocumentTemplate(
        documentTemplateId: $documentTemplateId
        name: $name
        hidden: $hidden
        template: $template
      ) {
        id
        name
        template
        updatedAt
        team {
          id
        }
      }
    }
  `);

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => data.updateDocumentTemplate);
};
