import React from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../../notifications';
import { getTeamsWhereUserHasEditPermissions, UserTeamPermission } from './helpers';
import { onboardTeamForSharedDeal } from '../../../../utils/api/onboard-team-for-shared-deal/onboard-team-for-shared-deal';
import UserHasUnassignedDealsModal from './UserHasUnassignedDealsModal';
import { queriesV2 } from '../../../../api';
import { useTeamInfo } from '../../../../team-id-context';
import { AcceptInvitationErrorMessage } from '../../../../consts/forms-constants';
import { useAuth } from '../../../../auth';

export enum FormikValuesEnum {
  assignExistingDealTeam = 'existing',
  assignNewDealTeam = 'new',
}

interface UserHasUnassignedDealsModalContainerProps {
  handleHide: () => void;
  onSuccess: () => void;
  showSubscriptionRequiredModal: () => void;
  invitation: any;
}

enum InvitationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Cancelled = 'cancelled',
}

const UserHasUnassignedDealsModalContainer = ({
  handleHide,
  showSubscriptionRequiredModal,
  onSuccess,
  invitation,
}: UserHasUnassignedDealsModalContainerProps) => {
  const apolloClient = useApolloClient();
  const [, { error: notifyError }] = useNotifications();
  const navigate = useNavigate();
  const [{ user }] = useAuth();
  const deal = invitation.relatedInvitation?.deals[0] || invitation.deals[0];

  const dealTitle = deal.title;
  const { teamId } = useTeamInfo();

  const { data: currentUserData, loading: isCurrentUserTeamsLoading } = useQuery(
    queriesV2.getCurrentUserTeamsWhereUserHasEditPermissions
  );

  const currentUserTeamPermissions: UserTeamPermission[] = currentUserData?.currentUser.teamPermissions || [];
  const teamsWhereUserHasEditPermissions = getTeamsWhereUserHasEditPermissions(currentUserTeamPermissions);

  const getSelectTeamDropdownOptions = () =>
    teamsWhereUserHasEditPermissions.map(team => ({
      label: {
        id: `assignDeal.teamOption.${team.id}`,
        defaultMessage: team.name,
      },
      value: team.id,
    }));

  const handleSubmitOnboardTeam = (values: any) => {
    values.assignDealTeam === FormikValuesEnum.assignExistingDealTeam
      ? (values.newTeamName = '')
      : (values.existingTeamId = '');

    onboardTeamForSharedDeal(
      deal.id,
      values.existingTeamId,
      user.id,
      values.newTeamName
    )(apolloClient)
      .then(data => {
        const { id } = data.receiverTeam;
        const { redirectTo } = data.invitation.onboarding;

        if (id === teamId) {
          onSuccess();
          navigate(`/${redirectTo}`);
        } else {
          const route = `/teams/${data.receiverTeam.id}/deals`;
          onSuccess();
          navigate(route);
        }
      })
      .catch(error =>
        notifyError({
          id: 'deal.send-deal.error',
          message:
            error.message === AcceptInvitationErrorMessage.SAME_TEAM_SERVER_MESSAGE
              ? AcceptInvitationErrorMessage.SAME_TEAM_MESSAGE
              : error,
        })
      );
  };

  const handleSubmitAcceptInvitation = (values: any) => {
    const invitationStatus = invitation.relatedInvitation?.status || invitation.status;
    if (invitationStatus !== InvitationStatus.Pending) return;

    handleSubmitOnboardTeam(values);
  };

  return (
    <UserHasUnassignedDealsModal
      onSubmit={handleSubmitAcceptInvitation}
      isLoading={isCurrentUserTeamsLoading}
      existingTeams={getSelectTeamDropdownOptions()}
      dealTitle={dealTitle}
      handleHide={handleHide}
      showSubscriptionRequiredModal={showSubscriptionRequiredModal}
    />
  );
};

export default UserHasUnassignedDealsModalContainer;
