import { Button } from 'react-bootstrap';
import React from 'react';
import { SendDocumentButtonMessage } from '../../../../../document-table/DocumentTableConstants';
import { TitleFormattedMessage } from '../../../../../titleFormattedMessage';
import styles from './styles.module.scss';

interface SendProps {
  disabled?: boolean;
  onClick: () => void;
}

const GrantAccessButton = ({ disabled, onClick }: SendProps) => {
  return (
    <Button className={styles['grant-access-button']} disabled={disabled} onClick={onClick} variant="outline-primary">
      <TitleFormattedMessage
        id="lease-document-wizard-header.action.grant-access"
        defaultMessage={SendDocumentButtonMessage.GRANT_ACCESS}
        data-testid="lease-document-wizard-header-grant-access-action"
      />
    </Button>
  );
};

export default GrantAccessButton;
