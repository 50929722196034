import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import styles from '../styles.module.scss';
import { CurrentTeamSidebarTypesEnum } from '../documentTeamSidebarContainer';

interface ToggleButtonProps {
  setCurrentTeamSidebarType(value: CurrentTeamSidebarTypesEnum): any;
  currentTeamSidebarType: CurrentTeamSidebarTypesEnum;
}

const TeamToggleButtons = ({ setCurrentTeamSidebarType, currentTeamSidebarType }: ToggleButtonProps) => {
  const handleTeamTypeChange = (value: CurrentTeamSidebarTypesEnum) => {
    setCurrentTeamSidebarType(value);
  };

  const toggleButtonStyles = (buttonType: CurrentTeamSidebarTypesEnum) => {
    return buttonType === currentTeamSidebarType
      ? styles.teamSidebarSectionButtonActive
      : styles.teamSidebarSectionButton;
  };

  return (
    <>
      <ToggleButtonGroup
        className={styles.teamSidebarTopToggle}
        name={'example'}
        type="radio"
        value={currentTeamSidebarType}
      >
        <ToggleButton
          className={toggleButtonStyles(CurrentTeamSidebarTypesEnum.counterparty)}
          name="counterparty"
          onChange={e => handleTeamTypeChange(e.currentTarget.value as CurrentTeamSidebarTypesEnum)}
          value={CurrentTeamSidebarTypesEnum.counterparty}
        >
          Counterparty
        </ToggleButton>
        <ToggleButton
          className={toggleButtonStyles(CurrentTeamSidebarTypesEnum.dealteam)}
          name="dealteam"
          onChange={e => handleTeamTypeChange(e.currentTarget.value as CurrentTeamSidebarTypesEnum)}
          value={CurrentTeamSidebarTypesEnum.dealteam}
        >
          My Deal Team
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default TeamToggleButtons;
