import React, { ReactNode } from 'react';
import { Button, Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Card } from './index';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './styles.module.scss';

type SubscriptionDetailsProps = {
  subscriptionType: string;
  dealLimit: string | number;
  dealsCreated: string | number;
  renewalDate: string;
  upgradeLink: string;
  children?: ReactNode;
};

export const SubscriptionDetailsCard = ({
  subscriptionType,
  dealLimit,
  dealsCreated,
  renewalDate,
  upgradeLink,
}: SubscriptionDetailsProps) => {
  return (
    <Card title={null}>
      <div className={styles.subscriptionHeader}>
        <h4>
          <FormattedMessage id="title.subscriptionDetails" defaultMessage="Subscription Details" />
        </h4>
        <span className={styles.subscriptionSubheader}>
          <FormattedMessage id="title.subscriptionDetails" defaultMessage={subscriptionType} />
        </span>
      </div>

      <div className={styles.cardRow}>
        <div>
          <p className={styles.descriptionTitle}>
            <FormattedMessage id="title.fullName" defaultMessage="Deal Limit" />
          </p>
          <p>{dealLimit}</p>
        </div>
      </div>
      <div className={styles.cardRow}>
        <div>
          <p className={styles.descriptionTitle}>
            <FormattedMessage id="title.fullName" defaultMessage="Deals Created" />
          </p>
          <p>{dealsCreated}</p>
        </div>
      </div>
      <div className={styles.cardRow}>
        <div>
          <p className={styles.descriptionTitle}>
            <FormattedMessage id="title.fullName" defaultMessage="Renewal Date" />
          </p>
          <p>{renewalDate}</p>
        </div>
      </div>
      <Container className={styles.upgradeButtonContainer}>
        <Button href={upgradeLink}>
          <TitleFormattedMessage id="cta.upgrade" defaultMessage={'Upgrade'} />
        </Button>
      </Container>
    </Card>
  );
};
