import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import RemoveMemberFromDealConfirmationModal from './RemoveMemberFromDealConfirmationModal';
import { useNotifications } from '../../../../notifications';
import { getTeamById } from '../../../../api/queries';
import { mutations, queriesV2 } from '../../../../api';
import { useTeamInfo } from '../../../../team-id-context';
import { useDealId } from '../../../../deal-id-context';

interface RemoveMemberFromDealConfirmationModalContainerProps {
  userToRemoveFromDeal: any;
  setUserToRemoveFromDeal: (user: any) => void;
}

const RemoveMemberFromDealConfirmationModalContainer = ({
  userToRemoveFromDeal,
  setUserToRemoveFromDeal,
}: RemoveMemberFromDealConfirmationModalContainerProps) => {
  const [, { success: notifySuccess, error: notifyError }] = useNotifications();
  const { teamId } = useTeamInfo();
  const dealId = useDealId();

  const [removeTeamMemberFromDeal] = useMutation(mutations.removeTeamMemberFromDeal, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
      {
        query: queriesV2.getDealTeamsByTeamId,
        variables: {
          dealId: dealId,
        },
      },
    ],
  });

  const handleRemoveMemberFromDeal = useCallback(async () => {
    setUserToRemoveFromDeal(null);
    try {
      await removeTeamMemberFromDeal({
        variables: {
          userId: userToRemoveFromDeal.id,
          dealId,
        },
      });

      notifySuccess({
        message: {
          id: 'phrase.remove-team-member-from-deal.success',
          defaultMessage: '{fullName} has been removed from the deal.',
          values: userToRemoveFromDeal,
        },
      });
    } catch (error) {
      notifyError({ message: error });
    }
  }, [notifyError, notifySuccess, dealId, removeTeamMemberFromDeal, setUserToRemoveFromDeal, userToRemoveFromDeal]);

  const handleHide = () => {
    setUserToRemoveFromDeal(null);
  };

  return (
    <RemoveMemberFromDealConfirmationModal
      onHide={handleHide}
      onClick={handleRemoveMemberFromDeal}
      userToRemoveFromDeal={userToRemoveFromDeal}
    />
  );
};

export default RemoveMemberFromDealConfirmationModalContainer;
