import { Plugin, Command } from '@ckeditor/ckeditor5-core';
import getDocumentPlaceholders from './utils/searchPlaceholders';
import { Widget, toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget';

/**
 * ShowDialogCommand class to show a custom dialog in the editor.
 */
class ShowDialogCommand extends Command {
  /**
   * Executes the command, firing the 'showCustomDialog' event in the editor.
   */
  execute(uuid) {
    this.editor.fire('showCustomDialog', { uuid });
  }

  /**
   * Refreshes the command state. Can include any logic needed to determine if the command should be enabled.
   */
  refresh() {
    this.isEnabled = true;
  }
}

/**
 * HideDialogCommand class to hide a custom dialog in the editor.
 */
class HideDialogCommand extends Command {
  /**
   * Executes the command, firing the 'hideCustomDialog' event in the editor.
   */
  execute() {
    this.editor.fire('hideCustomDialog');
  }

  /**
   * Refreshes the command state. Can include any logic needed to determine if the command should be enabled.
   */
  refresh() {
    this.isEnabled = true;
  }
}

/**
 * ReplacePlaceholderCommand class to replace a placeholder in the editor.
 */
class ReplacePlaceholderCommand extends Command {
  /**
   * Executes the command, replacing the selected element with the given input value.
   * @param {string} inputValue The value to replace the selected element with.
   */
  execute(inputValue) {
    const selection = this.editor.model.document.selection;

    this.editor.model.change((writer) => {
      const textNode = writer.createText(inputValue);

      this.editor.model.deleteContent(selection);
      this.editor.model.insertContent(textNode, selection);
    });
  }

  /**
   * Refreshes the command state. Determines if the command can be enabled based on the schema.
   */
  refresh() {
    this.isEnabled = true;
  }
}

/**
 * PlaceholderCommand class to insert a placeholder in the editor.
 */
class PlaceholderCommand extends Command {
  execute({ value, required, placeholderid, documentType }) {
    const editor = this.editor;
    const selection = editor.model.document.selection;

    // const placeholderAdapter = this.editor.plugins.get('PlaceholderAdapter');

    editor.model.change(async writer => {
      const markerName = `placeholder:${placeholderid}`;

      // Check if the marker already exists and its range
      const existingMarker = editor.model.markers.get(markerName);
      if (existingMarker) {
        // await placeholderAdapter.adapter.updatePlaceholder({
        //   linkableType: documentType === 'template' ? 'DocumentTemplate' : 'Document',
        //   placeholderUuid: placeholderid,
        //   status: 'complete',
        // });
        writer.removeMarker(existingMarker)
      }

      // Create the placeholder element
      const placeholder = writer.createElement('placeholder', {
        ...Object.fromEntries(selection.getAttributes()),
        name: value,
        required,
        placeholderid,
      });

      // Remove the existing placeholder if any
      if (selection.getSelectedElement() && selection.getSelectedElement().is('element', 'placeholder')) {
        writer.remove(selection.getSelectedElement());
      }

      // Insert the new placeholder element
      editor.model.insertObject(placeholder, null, null, {setSelection: 'on'});

      // Ensure the marker is re-added or updated correctly
      const range = writer.createRangeOn(placeholder);
      writer.addMarker(markerName, {
        range: range,
        usingOperation: true,
        affectsData: false
      });

      const cursorRange = writer.createRange(writer.createPositionAfter(placeholder));
      writer.setSelection(cursorRange);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const isAllowed = model.schema.checkChild(selection.focus.parent, 'placeholder');
    this.isEnabled = isAllowed;
  }
}

/**
 * Factory function to create the PlaceholdersEditing plugin.
 * @param documentType The type of document, affecting the placeholder style.
 * @returns The PlaceholdersEditing plugin class.
 */
const getPlaceholdersEditing = ({ documentType }) => {
  /**
   * PlaceholdersEditing plugin class to define schema, converters, and commands for placeholders.
   */
  class PlaceholdersEditing extends Plugin {
    /**
     * Required plugins for PlaceholdersEditing.
     */
    static get requires() {
      return [Widget];
    }

    /**
     * Initializes the plugin, setting schema, converters, and commands.
     */
    init() {
      const isPlaceholderAdapterAvailable = this.editor.plugins.has('PlaceholderAdapter');

      if (!isPlaceholderAdapterAvailable) {
        return;
      }


      this._defineSchema();
      this._defineConverters();

      this.editor.getPlaceholders = this.getPlaceholders.bind(this);

      this.editor.commands.add('placeholder', new PlaceholderCommand(this.editor));
      this.editor.commands.add('showCustomDialog', new ShowDialogCommand(this.editor));
      this.editor.commands.add('hideDialogCommand', new HideDialogCommand(this.editor));
      this.editor.commands.add('replacePlaceholder', new ReplacePlaceholderCommand(this.editor));


      const placeholderAdapter = this.editor.plugins.get('PlaceholderAdapter');

      this.editor.model.document.on('change:data', async (evt, data) => {
        const differ = this.editor.model.document.differ;
        const changes = differ.getChanges();

        for (const entry of changes) {
          if (entry.type === 'remove' && entry.name === 'placeholder') {
            const placeholderUuid = entry.attributes.get('placeholderid');
            await placeholderAdapter.adapter.updatePlaceholder({
              linkableType: documentType === 'template' ? 'DocumentTemplate' : 'Document',
              placeholderUuid,
              status: 'complete',
            });
          }
          if (entry.type === 'insert' && entry.name === 'placeholder') {
            const placeholderUuid = entry.attributes.get('placeholderid');
            await placeholderAdapter.adapter.updatePlaceholder({
              linkableType: documentType === 'template' ? 'DocumentTemplate' : 'Document',
              placeholderUuid,
              status: 'incomplete',
            });
          }
        }

        // Re-add markers after changes
        this._addMarkersToPlaceholders();
      });

      this.editor.editing.mapper.on(
        'viewToModelPosition',
        viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('placeholder'))
      );

      this.editor.editing.view.document.on('click', (evt, data) => {
        const viewElement = this.editor.editing.view.domConverter.domToView(data.domTarget);
        const modelElement = this.editor.editing.mapper.toModelElement(viewElement);

        if (modelElement && modelElement.name === 'placeholder') {
          if (!viewElement.hasAttribute('data-suggestion')) {
            const uuid = modelElement.getAttribute('placeholderid');
            this.editor.execute('showCustomDialog',  { uuid });
          }

          data.preventDefault();
          evt.stop();
        }
      });

      const trackChangesEditing = this.editor.plugins.get('TrackChangesEditing');

      trackChangesEditing.enableCommand("placeholder");

      trackChangesEditing.enableCommand("showCustomDialog");

      trackChangesEditing.enableCommand("hideDialogCommand");

      trackChangesEditing.enableCommand("replacePlaceholder");
    }

    /**
     * Defines the schema for the placeholder element in the model.
     */
    _defineSchema() {
      const schema = this.editor.model.schema;

      schema.register('placeholder', {
        inheritAllFrom: '$inlineObject',
        allowAttributes: ['name', 'required', 'placeholderid'],
      });
    }

    /**
     * Defines converters for placeholder elements.
     */
    _defineConverters() {
      const conversion = this.editor.conversion;

      conversion.for('upcast').elementToElement({
        view: {
          name: 'span',
          classes: ['placeholder'],
        },
        model: (viewElement, { writer: modelWriter }) => {
          if (viewElement) {
            const name = viewElement.getChild(0).data.slice(1, -1);
            const required = viewElement.getAttribute('required');
            const placeholderid = viewElement.getAttribute('placeholderid');

            return modelWriter.createElement('placeholder', { name, required, placeholderid, documentType });
          }
        },
      });

      conversion.for('editingDowncast').elementToElement({
        model: 'placeholder',
        view: (modelItem, { writer: viewWriter }) => {
          const widgetElement = createPlaceholderView(modelItem, viewWriter);
          return toWidget(widgetElement, viewWriter);
        },
      });

      conversion.for('dataDowncast').elementToElement({
        model: 'placeholder',
        view: (modelItem, { writer: viewWriter }) => {
          return createPlaceholderView(modelItem, viewWriter)
        },
      });

      conversion.for('editingDowncast').attributeToAttribute({
        model: {
          name: 'placeholder',
          key: 'name'
        },
        view: modelAttributeValue => {
          return {
            key: 'placeholderName',
            value: modelAttributeValue
          };
        },
        converterPriority: 'low'
      });

      conversion.for('editingDowncast').attributeToAttribute({
        model: {
          name: 'placeholder',
          key: 'required'
        },
        view: modelAttributeValue => {
          return {
            key: 'required',
            value: modelAttributeValue
          };
        },
        converterPriority: 'low'
      });

      this.editor.conversion.for('dataDowncast').add(dispatcher => {
        dispatcher.on('insert:$text', (evt, data, conversionApi) => {
          if (data.item.previousSibling && data.item.previousSibling.is('element') && data.item.previousSibling.name === 'placeholder') {
            const writer = conversionApi.writer;
            const viewText = conversionApi.mapper.toViewElement(data.item);
            writer.removeClass('placeholder', viewText);
            writer.removeStyle('background-color', viewText);
            writer.removeStyle('border', viewText);
          }
        });
      });

      this.editor.conversion.for('editingDowncast').add(dispatcher => {
        dispatcher.on('insert:$text', (evt, data, conversionApi) => {
          if (data.item.previousSibling && data.item.previousSibling.is('element') && data.item.previousSibling.name === 'placeholder') {
            const writer = conversionApi.writer;
            const viewText = conversionApi.mapper.toViewElement(data.item);
            writer.removeClass('placeholder', viewText);
            writer.removeStyle('background-color', viewText);
            writer.removeStyle('border', viewText);
          }
        });
      });

      conversion.for('editingDowncast').add(dispatcher => {
        dispatcher.on('attribute:name:placeholder', (evt, data, conversionApi) => {
          const viewWriter = conversionApi.writer;
          const viewElement = conversionApi.mapper.toViewElement(data.item);

          if (viewElement) {
            const innerText = viewWriter.createText(`[${data.attributeNewValue}]`);
            viewWriter.remove(viewElement.getChild(0));
            viewWriter.insert(viewWriter.createPositionAt(viewElement, 0), innerText);
          }
        });
      });

      /**
       * Helper method to create a placeholder view element.
       * @param modelItem The model item representing the placeholder.
       * @param viewWriter The view writer to create the view element.
       * @returns The created view element for the placeholder.
       */
      function createPlaceholderView(modelItem, viewWriter) {
        const name = modelItem.getAttribute('name');
        const required = modelItem.getAttribute('required');
        const placeholderid = modelItem.getAttribute('placeholderid');

        const placeholderStyle = 'background-color: #fef3ef; margin: 3px;';

        const placeholderView = viewWriter.createContainerElement('span', {
          required,
          placeholderName: name,
          placeholderid,
          class: 'placeholder',
          style: placeholderStyle,
        });

        const innerText = viewWriter.createText('[' + name + ']');
        viewWriter.insert(viewWriter.createPositionAt(placeholderView, 0), innerText);

        return placeholderView;
      }
    }

    _addMarkersToPlaceholders() {
      const editor = this.editor;
      const model = editor.model;

      model.change(writer => {
        const root = model.document.getRoot();
        this._traverseAndAddMarkers(root, writer);
      });
    }

    _traverseAndAddMarkers(node, writer) {
      if (node.is('element', 'placeholder')) {
        const placeholderid = node.getAttribute('placeholderid');

        const markerName = `placeholder:${placeholderid}`;

        // Check if the marker already exists before adding it
        if (!this.editor.model.markers.has(markerName)) {
          const range = writer.createRangeOn(node);
          writer.addMarker(markerName, {
            range: range,
            usingOperation: true,
            affectsData: false
          });
        }
      }

      for (const child of node.getChildren()) {
        if (child.is('element')) {
          this._traverseAndAddMarkers(child, writer);
        }
      }
    }

    getPlaceholders() {
      const placeholders = [];
      const root = this.editor.model.document.getRoot();
    
      // Function to traverse the model tree and collect placeholders
      const traverse = node => {
        if (node.is('element', 'placeholder')) {
          placeholders.push(node);
        }
    
        if (node.is('element')) {
          for (const child of node.getChildren()) {
            traverse(child);
          }
        }
      };
    
      traverse(root);
      
      return placeholders;
    }
  }

  return PlaceholdersEditing;
}

export default getPlaceholdersEditing;
