import CommentView from "@ckeditor/ckeditor5-comments/src/comments/ui/view/commentview";
import Template from "@ckeditor/ckeditor5-ui/src/template";

/** CLOSURE: This closes the reference of the react context state to the CK editor view. **/
let ReactCommentsContext = [];
let ThreadRendered; // eslint-disable-line

export const getCustomCommentView = (context, threadRendered) => {
  /** Save the reference to the context as a closed over state to the view class. **/
  ReactCommentsContext = context || [];
  ThreadRendered = threadRendered || null; // eslint-disable-line no-unused-vars

  class CustomCommentView extends CommentView {
    constructor(...args) {
      super(...args);
      this.togglePrivateThreadCommand = {};
      this.isEditorInCommentsOnly = {};
    }

    getTemplate() {
      const templateDefinition = super.getTemplate();
      const templateRef =
        templateDefinition.children[0].children[1].children[0].children;
      this.togglePrivateThreadCommand = window.editor.commands.get(
        "togglePrivateThread"
      ); // eslint-disable-line
      const renderTemplate = () => {
        const commentId = this._model.id;
        const ReactComment = ReactCommentsContext.filter(
          (comment) => comment.id === commentId
        );

        if (ReactComment.length) {
          if (!ReactComment[0].threadRendered) {
            const template = this.renderExistingComment(ReactComment);
            templateRef.push(template);
          }
          threadRendered(this._model.threadId, ReactCommentsContext);
        } else {
          const template = this.renderNewComment();
          templateRef.push(template);
        }

        return templateDefinition;
      };
      return renderTemplate();
    }

    renderExistingComment(ReactComment) {
      const isPublic = ReactComment[0].visibility === "public";
      return this.renderPermissionIndicatorTemplate(!isPublic);
    }

    renderNewComment() {
      this.setThreadPermission();
      return this.renderPermissionIndicatorTemplate(
        this.togglePrivateThreadCommand.isEnabled
      );
    }

    renderPermissionIndicatorTemplate(condition) {
      const commentOnlyPlugin = window.editor.plugins.get("CommentsOnly");
      this.isEditorInCommentsOnly &&
        commentOnlyPlugin.clearForceDisabled("privateCommentThread");

      return new Template({
        tag: "div",
        attributes: {
          class: "foo",
          style: {
            display: "inline-block",
            width: "auto",
            "text-align": "left",
            color: condition ? "#2f89ac" : "#467f30",
            padding: "2px 5px",
            "font-size": "9px",
            "font-weight": "bold",
            margin: 0,
          },
        },
        children: [condition ? "Private" : "Public"],
      }).render();
    }

    setThreadPermission() {
      this.isEditorInCommentsOnly =
        window.editor.plugins.get("CommentsOnly").isEnabled;

      const threadExists = ReactCommentsContext.filter(
        (comment) => comment.threadId === this._model.threadId
      );

      if (threadExists.length) {
        const hasPrivate = threadExists.some((comment) => !comment.isPublic);
        const hasPublic = threadExists.some((comment) => comment.isPublic);

        const commentOnlyPlugin = window.editor.plugins.get("CommentsOnly");
        this.isEditorInCommentsOnly &&
          commentOnlyPlugin.forceDisabled("privateCommentThread");

        if (hasPrivate) {
          this.togglePrivateThreadCommand.set("isEnabled", true);
        }
        if (hasPublic) {
          this.togglePrivateThreadCommand.set("isEnabled", false);
        }
      }
    }
  }
  return CustomCommentView;
};
