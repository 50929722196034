import React, { useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useApolloClient, useQuery } from '@apollo/client';
import { selectManageDealParticipantsCollapsed } from '../../store/navigation/navigation.selectors';
import { toggleManageDealParticipantsCollapsed } from '../../store/navigation/navigation.actions';
import useOnClickOff from '../../utils/use-on-click-off';
import EditTeam from './EditTeam';
import { useTeamInfo } from '../../team-id-context';
import { usePermissions } from '../../auth';
import { useUserDealRestrictions } from '../../auth/user-restrictions';
import { useLeaseDocumentTeamPermissions } from '../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { useDealRoles } from '../../shared/hooks/useDealRoles/useDealRoles';
import LoadingSpinner from '../loading-spinner';
import { useDealId } from '../../deal-id-context';
import { queriesV2 } from '../../api';
import CounterpartyPanel from './CounterpartyPanel';
import { TeamMember, User } from '../lease-wizard/lease-editor/LeaseEditorTypes';
import { ReactComponent as CloseIcon } from '../../shared/icons/close-icon.svg';
import styles from './styles.module.scss';
import { selectCurrentDeal } from '../../store/deals/deals.selectors';

interface ManageParticipantsLayoutProps {
  isAdmin: boolean;
  isCreator: boolean;
  hasInviteSendingPermission: boolean;
  isDealShared: boolean;
  counterpartyTeam: {
    teamMembers: TeamMember[];
    adminUser: {
      id: number;
    };
  };
  dealMembers: User[];
  isDealAccepted: boolean;
  counterpartyInvitedEmail: string;
  counterpartyAdminUserId: string;
  refetchDealData: () => Promise<void>
}

interface ManageParticipantsLayoutContainerProps {
  isDealDataLoading: boolean;
  dealData: any;
  refetchDealData: () => Promise<void>
}

const ManageParticipantsLayoutContainer = ({ isDealDataLoading, dealData, refetchDealData}: ManageParticipantsLayoutContainerProps) => {
  const isDealParticipantsCollapsed = useSelector(selectManageDealParticipantsCollapsed);
  const subNavRef = useRef(null);
  const dispatch = useDispatch();
  const teamId = useTeamInfo().teamId;
  const dealId = useDealId();
  const apolloClient = useApolloClient();
  const { isAdmin } = usePermissions();
  const { isCreator, isDealRolesLoading } = useDealRoles(dealId);
  const { hasInviteSendingPermission } = useLeaseDocumentTeamPermissions({ teamId, apolloClient });
  const currentDeal = useSelector(selectCurrentDeal);

  const toggleCollapse = () => {
    return dispatch(toggleManageDealParticipantsCollapsed(true));
  };

  const onClickOff = () => {
    return isDealParticipantsCollapsed || toggleCollapse();
  };

  useOnClickOff(subNavRef, onClickOff);

  const counterpartyInvitedEmail = useMemo(() => {
    return dealData?.getDealById?.receiverEmail;
  }, [dealData?.getDealById?.receiverEmail])

  const counterpartyTeam = useMemo(() => {
    return isCreator ? dealData?.getDealById.receiverTeam : dealData?.getDealById.team;

  }, [dealData?.getDealById.receiverTeam, dealData?.getDealById.team, isCreator])

  if (isDealRolesLoading || isDealDataLoading || !dealData.getDealById || !currentDeal) {
    return <LoadingSpinner />;
  }

  const isDealAccepted = dealData?.getDealById?.receiverAcceptedInvitationDate;
  const invitationSentBy = currentDeal?.invitationSentBy;
  const dealMembers = dealData?.getDealById?.members;

  return (
    <div
      ref={subNavRef}
      className={classNames(
        styles.manageParticipantsWrapper,
        styles[`manageParticipantsWrapper__${isDealParticipantsCollapsed ? 'collapsed' : 'show'}`],
        styles.manageParticipantsMobile
      )}
    >
      <ManageParticipantsLayout
        isAdmin={isAdmin}
        hasInviteSendingPermission={hasInviteSendingPermission}
        isCreator={isCreator}
        isDealShared={!!counterpartyTeam}
        counterpartyTeam={counterpartyTeam}
        dealMembers={dealMembers}
        isDealAccepted={isDealAccepted}
        counterpartyInvitedEmail={counterpartyInvitedEmail}
        counterpartyAdminUserId={isCreator ? counterpartyTeam?.adminUser?.id : invitationSentBy?.id}
        refetchDealData={refetchDealData}
      />
    </div>
  );
};

const ManageParticipantsLayout = ({
  isAdmin,
  isCreator,
  hasInviteSendingPermission,
  counterpartyTeam,
  dealMembers,
  isDealAccepted,
  counterpartyInvitedEmail,
  counterpartyAdminUserId,
  refetchDealData
}: ManageParticipantsLayoutProps) => {
  const dispatch = useDispatch();
  const [isCounterpartyTabActive, setIsCounterpartyTabActive] = useState(true);
  const { canInviteCounterparty } = useUserDealRestrictions();
  const handleDealParticipantsPanelHide = () => {
    dispatch(toggleManageDealParticipantsCollapsed(true));
  };
  const hasCreatorInvitePermission = isCreator && hasInviteSendingPermission;

  const counterpartyMembersWithAccessToDeal = useMemo(() => {
    const dealMembersIds = dealMembers?.map((member: User) => member.id);
    const filteredCounterpartyTeam = counterpartyTeam?.teamMembers?.filter((member: TeamMember) =>
      dealMembersIds.includes(member.user.id)
    );
    return filteredCounterpartyTeam;
  }, [dealMembers, counterpartyTeam]);

  return (
    <div className={styles.manageParticipants}>
      <Row className={classNames(styles.manageParticipants__header, styles.manageElements, 'm-0 p-0')}>
        <Col lg={5} md={10} className={classNames(styles.manageParticipants__tabsContainer, 'm-0 p-0')}>
          <Col
            className={classNames(
              styles.manageParticipants__tab,
              isCounterpartyTabActive && styles['manageParticipants__tab--active']
            )}
            onClick={() => setIsCounterpartyTabActive(true)}
          >
            <FormattedMessage id="title.manage.participants" defaultMessage="Counterparty" />
          </Col>
          <Col
            className={classNames(
              styles.manageParticipants__tab,
              !isCounterpartyTabActive && styles['manageParticipants__tab--active']
            )}
            onClick={() => setIsCounterpartyTabActive(false)}
          >
            <FormattedMessage id="title.manage.participants" defaultMessage="Your Deal Team" />
          </Col>
        </Col>

        <Col lg={2} md={2} className={classNames(styles.manageParticipants__close, styles.closeParticipants)}>
          <span>Manage People </span>
          <span onClick={handleDealParticipantsPanelHide}>
            <CloseIcon />
          </span>
        </Col>
      </Row>
      <Row className={classNames(styles.manageParticipants__content, styles.manageTabs)}>
        {isCounterpartyTabActive ? (
          <Row
            className={classNames(
              styles.manageParticipants__counterparty,
              'm-0 p-0 w-100 ml-3 mr-3',
              isCounterpartyTabActive && styles['manageParticipants__counterparty--show']
            )}
          >
            <CounterpartyPanel
              isAdmin={isAdmin}
              canInviteCounterparty={canInviteCounterparty}
              hasCreatorInvitePermission={hasCreatorInvitePermission}
              isDealShared={!!counterpartyTeam}
              counterpartyTeam={counterpartyMembersWithAccessToDeal}
              isDealAccepted={isDealAccepted}
              counterpartyInvitedEmail={counterpartyInvitedEmail}
              counterpartyAdminUserId={counterpartyAdminUserId}
              refetchDealData={refetchDealData}
            />
          </Row>
        ) : (
          <Row
            className={classNames(
              styles.manageParticipants__dealTeam,
              'm-0 p-0 ml-3 mr-3',
              !isCounterpartyTabActive && styles['manageParticipants__dealTeam--show'],
              styles.manageDealTeamWork
            )}
          >
            <EditTeam />
          </Row>
        )}
      </Row>
    </div>
  );
};

export default ManageParticipantsLayoutContainer;
