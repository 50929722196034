import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';

export type SerializedFormData = {
  [key: string]: string;
};

/**
 * Given a form element, serializes the form data
 * @param formElement
 * @return serialized form data
 */
export const serializeFormData = (formElement: HTMLFormElement): SerializedFormData => {
  const formData = new FormData(formElement);
  const serialized: SerializedFormData = {};
  formData.forEach((v, k) => {
    serialized[k] = v as string;
  });
  return serialized;
};

/**
 * These prefixes are used for form submission processing.
 */

export const leaseFieldKeyPrefix = 'field___';

export const getReadableDocumentStatus = (documentStatus: DocumentStatusEnum | string ): string => {
  return documentStatus.replace(/[_]/g, ' ');
};
