import { ChangeEvent } from 'react';
import { Checkbox, RadioField } from '../checkbox-or-radio-field';
import { FormattedMessage, useIntl } from 'react-intl';
import slugify from '../../utils/slugify';

interface CreatorFormProps {
  creators: string[];
  filtered: Set<string>;
  setFilterCreators: (value: Set<string>) => void;
}

export const CreatorForm: React.FC<CreatorFormProps> = ({ creators, filtered, setFilterCreators }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Checkbox
        checked={filtered.size === creators.length}
        indeterminate={filtered.size > 0 && filtered.size < creators.length}
        id="mobile-filter-creators-_all"
        data-testid="mobile-filter-creators-_all"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const checkbox = event.currentTarget;
          if (checkbox.checked) {
            setFilterCreators(new Set(creators));
          } else {
            setFilterCreators(new Set());
          }
        }}
        label={formatMessage({
          id: 'deals.filter.creator.all-creators',
          defaultMessage: 'All Creators',
        })}
      />
      {creators.map((creator: any) => (
        <Checkbox
          key={creator}
          value={creator}
          checked={filtered.has(creator)}
          id={`mobile-filter-creators-${slugify(creator)}`}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const checkbox = event.currentTarget;
            const newSet = new Set(filtered);
            if (checkbox.checked) newSet.add(checkbox.value);
            else newSet.delete(checkbox.value);
            setFilterCreators(newSet);
          }}
          label={creator}
        />
      ))}
    </>
  );
};
