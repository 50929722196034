import React, { useEffect, useState } from 'react';
import { AddSignerForm, SignerItem, InsertFields } from './components';
import { Signer } from './signersSidebarContainer';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Tooltip from '../../../../tooltip';
import { AddRecipientForm } from './components/addRecipientForm';
import { ReactComponent as ChevronUpIcon } from '../../../../../shared/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../../shared/icons/chevron-down.svg';
import { Button } from 'react-bootstrap';

interface SignersSidebarProps {
  addSigner: (...args: any[]) => void;
  signers: Signer[];
  recipients: string[];
  moveItem: (...args: any[]) => void;
  updateSigner: (id: string, email: string, order: number) => Promise<void>;
  updateRecepientsList: (recipients: string[]) => Promise<void>;
  isCreatingSigner: boolean;
  isDeletingSigner: boolean;
  deleteSigner: (signerId: string, signerOrder: number) => Promise<void>;
  isUpdatingSigner: boolean;
  isDisabled: boolean;
  addField?: (...args: any[]) => void;
  shouldShowInsertionUi: boolean;
  viewer?: HTMLDivElement | null;
}

const TIMEOUT_FOR_ADD_SIGNER_TOOLTIP_SHOW = 1800;
const TIMEOUT_FOR_DUPLICATED_SIGNER_TOOLTIP_SHOW = 1800;

const SignersSidebar = ({
  addSigner,
  updateRecepientsList,
  signers,
  recipients,
  moveItem,
  updateSigner,
  isCreatingSigner,
  isDeletingSigner,
  deleteSigner,
  isUpdatingSigner,
  isDisabled,
  addField,
  shouldShowInsertionUi,
  viewer,
}: SignersSidebarProps) => {
  const [expandedSignersArea, setExpandedSignersArea] = useState(false);
  const [showSignerAddedTooltip, setShowSignerAddedTooltip] = useState(false);
  const [showDuplicatedErrorTooltip, setShowDuplicatedErrorTooltip] = useState(false);
  
  const onAddSignerSubmit = async (email: string) => {
    if (signers.find(signer => signer.email === email)) {
      setShowDuplicatedErrorTooltip(true);
      setTimeout(() => {
        setShowDuplicatedErrorTooltip(false);
      }, TIMEOUT_FOR_DUPLICATED_SIGNER_TOOLTIP_SHOW);
      return;
    }
    addSigner(email);
    setShowSignerAddedTooltip(true);
    setTimeout(() => {
      setShowSignerAddedTooltip(false);
    }, TIMEOUT_FOR_ADD_SIGNER_TOOLTIP_SHOW);
  };
  const renderSignerItems = () =>
    signers.map((signer, index) => (
      <SignerItem
        key={signer.id}
        index={index}
        id={signer.id}
        order={index}
        email={signer.email}
        moveCard={moveItem}
        updateSigner={updateSigner}
        deleteSigner={deleteSigner}
        isDeletingSigner={isDeletingSigner}
        isUpdatingSigner={isUpdatingSigner}
      />
    ));

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles['wrapper-disabled']]: isDisabled,
      })}
    >
      {shouldShowInsertionUi && (
        <InsertFields
          addField={addField}
          signers={signers}
          className={styles['insert-fields']}
          showSignerAddedTooltip={showSignerAddedTooltip}
          showDuplicatedErrorTooltip={showDuplicatedErrorTooltip}
          onAddSignerSubmit={onAddSignerSubmit}
          isCreatingSigner={isCreatingSigner}
          viewer={viewer}
        />
      )}
      <div className={styles['signers-title']}>
        <div>
          Manage Signers
          <Tooltip placement="right" testId="add-signers-tooltip">
            Drag to arrange signing order.
          </Tooltip>
        </div>
        <Button variant="link" onClick={() => setExpandedSignersArea(!expandedSignersArea)}>
          {expandedSignersArea ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>
      </div>

      {expandedSignersArea && (
        <div
          id="signers-container"
          className={classNames(styles['signers-container'], {
            [styles['signers-container-insertion']]: shouldShowInsertionUi,
          })}
        >
          {!!signers.length && <>{renderSignerItems()}</>}
          {!signers.length && <p className="text-center">No signers added yet</p>}
        </div>
      )}
      <AddRecipientForm recipients={recipients} onSubmit={updateRecepientsList} isCreatingSigner={isCreatingSigner} />
    </div>
  );
};

export default SignersSidebar;
