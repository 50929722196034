import { ModalActionsEnum } from '../DocumentTableConstants';
import { DocumentPermissions } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import { DocumentSourceEnum } from '../../lease-wizard/lease-editor/constants/EditorConstats';

type PermissionCheckProps = {
  action: string;
  permissions?: DocumentPermissions;
  isInvitationAccepted?: boolean;
  status?: string | null;
  isDocumentCreator: boolean;
  currentTeamPossession: boolean;
  receiverTeam?: null | { id: string };
  documentSource: DocumentSourceEnum;
};

export const checkDropdownActionAvailability = ({
  action,
  permissions,
  status: documentStatus,
  isDocumentCreator,
  currentTeamPossession,
  receiverTeam,
  documentSource,
}: PermissionCheckProps): boolean => {
  const isDocumentStatusMatch = (currentDocumentStatus: string): boolean => {
    return documentStatus === currentDocumentStatus;
  };

  const hasUserInvitePermission = !!permissions?.hasInviteSendingPermission;
  const hasUserEditPermission = !!permissions?.hasEditPermission;

  switch (action) {
    case ModalActionsEnum.UNSEND:
      return (
        isDocumentCreator &&
        hasUserInvitePermission &&
        (isDocumentStatusMatch(DocumentStatusEnum.REVIEWING) || isDocumentStatusMatch(DocumentStatusEnum.SHARED))
      );

    case ModalActionsEnum.SEND:
      const isReviewingDocumentAvailableToBeSent =
        hasUserInvitePermission && currentTeamPossession && isDocumentStatusMatch(DocumentStatusEnum.REVIEWING);
      const isDraftDocumentAvailableToBeSent =
        hasUserInvitePermission &&
        isDocumentCreator &&
        (isDocumentStatusMatch(DocumentStatusEnum.DRAFT) ||
          isDocumentStatusMatch(DocumentStatusEnum.UPLOADED) ||
          isDocumentStatusMatch(DocumentStatusEnum.SHARED));

      return isReviewingDocumentAvailableToBeSent || isDraftDocumentAvailableToBeSent;

    case ModalActionsEnum.TERMINATE:
      return hasUserEditPermission && isDocumentCreator && !isDocumentStatusMatch(DocumentStatusEnum.EXECUTED);

    case ModalActionsEnum.DOWNLOAD:
      return isDocumentStatusMatch(DocumentStatusEnum.EXECUTED) || documentSource === DocumentSourceEnum.IMPORTED;

    case ModalActionsEnum.SEND_FOR_SIGNING:
      return (
        !isDocumentCreator &&
        currentTeamPossession &&
        isDocumentStatusMatch(DocumentStatusEnum.REVIEWING) &&
        hasUserEditPermission
      );

    case ModalActionsEnum.INTERNAL_SEND_FOR_SIGNING:
      return (
        isDocumentCreator &&
        currentTeamPossession &&
        (isDocumentStatusMatch(DocumentStatusEnum.DRAFT) ||
          isDocumentStatusMatch(DocumentStatusEnum.REVIEWING) ||
          isDocumentStatusMatch(DocumentStatusEnum.UPLOADED) ||
          isDocumentStatusMatch(DocumentStatusEnum.SHARED)) &&
        hasUserEditPermission
      );

    case ModalActionsEnum.SEND_EXECUTED:
      return (
        isDocumentCreator &&
        isDocumentStatusMatch(DocumentStatusEnum.EXECUTED) &&
        hasUserInvitePermission &&
        !receiverTeam
      );

    case ModalActionsEnum.GRANT_ACCESS:
      return (
        isDocumentCreator &&
        isDocumentStatusMatch(DocumentStatusEnum.UPLOADED) &&
        hasUserInvitePermission &&
        !receiverTeam
      );

    case ModalActionsEnum.INTERNAL_APPROVAL:
      const isReviewingDocumentAvailableToBeSentForInternalApproval =
        hasUserEditPermission && currentTeamPossession && isDocumentStatusMatch(DocumentStatusEnum.REVIEWING);
      const isDraftDocumentAvailableToBeSentForInternalApproval =
        hasUserEditPermission &&
        isDocumentCreator &&
        (isDocumentStatusMatch(DocumentStatusEnum.DRAFT) ||
          isDocumentStatusMatch(DocumentStatusEnum.UPLOADED) ||
          isDocumentStatusMatch(DocumentStatusEnum.SHARED));

      return (
        isReviewingDocumentAvailableToBeSentForInternalApproval || isDraftDocumentAvailableToBeSentForInternalApproval
      );

    case ModalActionsEnum.SEND_VIA_EMAIL:
      return hasUserEditPermission;

    case ModalActionsEnum.CANCEL_SIGNING:
      return hasUserEditPermission && isDocumentCreator && isDocumentStatusMatch(DocumentStatusEnum.SIGNING);

    case ModalActionsEnum.SEND_REMINDER:
      return hasUserEditPermission && isDocumentCreator && isDocumentStatusMatch(DocumentStatusEnum.SIGNING);

    default:
      return false;
  }
};
