import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useApolloClient, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useTeamInfo } from '../../team-id-context';
import { useDealId } from '../../deal-id-context';
import ArchiveModal from './archiveModal';
import { LoadingSpinnerWithHeight } from '../../components/loading-spinner';
import { useDealRoles } from '../../shared/hooks/useDealRoles/useDealRoles';
import StatusDropdown, { DealStatus, DropdownStatusesEnum } from './components/statusDropdown/statusDropdown';
import { usePermissions } from '../../auth';
import { useLeaseDocumentTeamPermissions } from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { selectCurrentDeal } from '../../store/deals/deals.selectors';
import { mutations } from '../../api';
import { getDealById } from '../../api/queries';
import styles from './styles.module.scss';

const LOADING_SPINNER_HEIGHT = 35;
const LOADING_SPINNER_WIDTH = 102;

interface ControlsProps {
  teamId: string;
  dealId: string;
  controlClassName?: string;
}

const Controls = ({ teamId, dealId, controlClassName }: ControlsProps) => {
  const teamIdLookup = useTeamInfo().teamId;
  const dealIdLookup = useDealId();
  const currentDeal = useSelector(selectCurrentDeal);
  const { isAdmin } = usePermissions();
  const selectedTeamId: string = teamId || teamIdLookup;
  const selectedDealId: string = dealId || dealIdLookup;
  const { isCreator, isDealRolesLoading } = useDealRoles(selectedDealId);

  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const apolloClient = useApolloClient();
  const [updateDealDetails] = useMutation(mutations.updateDealDetails, {
    refetchQueries: [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
    ],
  });
  const { hasEditPermission } = useLeaseDocumentTeamPermissions({ teamId: selectedTeamId, apolloClient });
  const handleUpdateDealStatus = useCallback(
    async (dealId: string, newStatus: DealStatus) => {
      await updateDealDetails({
        variables: {
          dealId,
          dealDetails: {
            status: newStatus,
          },
        },
      });
    },
    [updateDealDetails]
  );

  if (isDealRolesLoading) {
    return <LoadingSpinnerWithHeight height={LOADING_SPINNER_HEIGHT} width={LOADING_SPINNER_WIDTH} />;
  }

  return (
    <>
      <div className="d-flex">
        {(isAdmin || hasEditPermission) && isCreator ? (
          <StatusDropdown
            /*
             // @ts-ignore */
            id={styles['status-dropdown']}
            className={'w-100'}
            wrapperClassName={controlClassName}
            currentStatus={currentDeal?.status as DealStatus}
            onStatusChange={(newStatus: DealStatus) => {
              handleUpdateDealStatus(dealId!, newStatus);
            }}
          />
        ) : (
          <span className={styles.headerDealStatus}>
            <FormattedMessage id="cta.edit" defaultMessage={DropdownStatusesEnum[currentDeal?.status as DealStatus]} />
          </span>
        )}
      </div>

      <ArchiveModal
        isOpen={isArchiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
        onUpdateArchived={() => {}}
      />
    </>
  );
};

export default Controls;
