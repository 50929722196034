import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';
import { CommentInputType } from "../../../gql/graphql";

export type CreateComments = {
  documentId: string;
  comments: CommentInputType[];
  threadId: string;
  attributes: string;
  resolvedBy: string;
  resolvedAt: string;
  unlinkedAt?: string;
};

const mutation = graphql(/* GraphQL */`
  mutation CreateDocumentComments(
    $documentId: ID!,
    $threadId: String!,
    $attributes: Json!,
    $comments: [CommentInputType!]!,
    $resolvedAt: String
    $resolvedBy: String
    $unlinkedAt: String
  ) {
    createDocumentComments(
      documentId: $documentId,
      threadId: $threadId,
      attributes: $attributes,
      comments: $comments,
      resolvedBy: $resolvedBy,
      resolvedAt: $resolvedAt,
      unlinkedAt: $unlinkedAt,
    ) {
      attributes
      threadId
      resolvedAt
      resolvedBy
      comments {
        ...DocumentCommentFields
      }
    }
  }
`);

export const createDocumentComments = (options: CreateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const createDocumentCommentsWithResult = (options: CreateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.createDocumentComments);
  });
};
