import {
  DocumentApprovalRequestType,
  DocumentSuggestion, LeaseDocumentTemplateType,
  LeaseDocumentType,
} from '../../../components/lease-wizard/lease-document.types';
import { Dispatch } from 'redux';
import { RootReducer } from '../../store.types';
import { selectLeaseDocumentId, selectLeaseDocumentTitle } from '../selectors/lease-document.selectors';
import { dispatchLeaseDocumentResponseToStore } from '../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { DocumentSourceEnum } from '../../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { ImportedDocumentSupportedContentTypes } from '../../../components/shared/modals/fileUploadModal/types';
import { DocumentSigner } from './set-lease-document-signers.action';

type GetSimpleleaseAppState = () => RootReducer;

/**
 * document
 */
type SetLeaseDocumentActionType = 'setLeaseDocument';
export type SetLeaseDocumentAction = {
  type: SetLeaseDocumentActionType;
  document: LeaseDocumentType | null;
};

export const setLeaseDocument = (document: LeaseDocumentType | null): SetLeaseDocumentAction => ({
  type: 'setLeaseDocument',
  document,
});

type SetLeaseDocumentTemplateActionType = 'setLeaseDocumentTemplate';
export type SetLeaseDocumentTemplateAction = {
  type: SetLeaseDocumentTemplateActionType;
  template: LeaseDocumentTemplateType | null;
};

export const setLeaseDocumentTemplate = (template: LeaseDocumentTemplateType | null): SetLeaseDocumentTemplateAction => ({
  type: 'setLeaseDocumentTemplate',
  template,
});

/**
 * approvalRequest
 */
type SetDocumentApprovalRequestActionType = 'setDocumentApprovalRequest';
export type SetDocumentApprovalRequestAction = {
  type: SetDocumentApprovalRequestActionType;
  approvalRequest: DocumentApprovalRequestType | null;
};

export const setDocumentApprovalRequest = (
  approvalRequest: DocumentApprovalRequestType | null
): SetDocumentApprovalRequestAction => ({
  type: 'setDocumentApprovalRequest',
  approvalRequest,
});

/**
 * id document editable
 */
type SetIsDocumentEditableActionType = 'setIsDocumentEditable';
export type SetIsDocumentEditableAction = {
  type: SetIsDocumentEditableActionType;
  isEditable: boolean;
};

export const setIsDocumentEditable = (isEditable: boolean): SetIsDocumentEditableAction => ({
  type: 'setIsDocumentEditable',
  isEditable,
});

type SetIsDocumentTitleEditableActionType = 'setIsDocumentTitleEditable';
export type SetIsDocumentTitleEditableAction = {
  type: SetIsDocumentTitleEditableActionType;
  isTitleEditable: boolean;
};

export const setIsDocumentTitleEditable = (isTitleEditable: boolean): SetIsDocumentTitleEditableAction => ({
  type: 'setIsDocumentTitleEditable',
  isTitleEditable,
});

type SetIsEligibleForSigningPrepActionType = 'setIsEligibleForSigningPrep';
export type SetIsEligibleForSigningPrepAction = {
  type: SetIsEligibleForSigningPrepActionType;
  isEligibleForSigningPrep: boolean;
};

export const setIsEligibleSigningPrep = (isEligibleForSigningPrep: boolean): SetIsEligibleForSigningPrepAction => ({
  type: 'setIsEligibleForSigningPrep',
  isEligibleForSigningPrep,
});

type SetIsReceiverCollaborationEnabledActionType = 'setIsReceiverCollaborationEnabled';
export type SetIsReceiverCollaborationEnabledAction = {
  type: SetIsReceiverCollaborationEnabledActionType;
  receiverCollaborationEnabled: boolean;
};

export const setIsReceiverCollaborationEnabled = (
  receiverCollaborationEnabled: boolean
): SetIsReceiverCollaborationEnabledAction => ({
  type: 'setIsReceiverCollaborationEnabled',
  receiverCollaborationEnabled,
});

/**
 * id
 */
export type SetLeaseDocumentIdActionType = 'setLeaseDocumentId';
export type SetLeaseDocumentIdAction = {
  type: SetLeaseDocumentIdActionType;
  id: string | null;
};

export const setLeaseDocumentId = (id: string | null): SetLeaseDocumentIdAction => ({
  type: 'setLeaseDocumentId',
  id,
});

/**
 * status
 */
export type SetLeaseDocumentStatusActionType = 'setLeaseDocumentStatus';

export type SetLeaseDocumentEditorDisabledActionType = 'setLeaseDocumentEditorDisabled';

export type SetDocumentCompareModeEnabledActionType = 'setDocumentCompareModeEnabled';

export type SetLeaseDocumentPossessionType = 'setLeaseDocumentPossession';

export type SetLeaseDocumentSuggestionsType = 'setLeaseDocumentSuggestions';

export type SetLeaseDocumentStatusAction = {
  type: SetLeaseDocumentStatusActionType;
  status: string | null;
};

export type SetLeaseDocumentPossessionAction = {
  type: SetLeaseDocumentPossessionType;
  currentTeamPossession: boolean;
};

export type SetLeaseDocumentEditorDisabledAction = {
  type: SetLeaseDocumentEditorDisabledActionType;
  isEditorDisabled: boolean;
};

export type SetDocumentCompareModeEnabledAction = {
  type: SetDocumentCompareModeEnabledActionType;
  isCompareModeEnabled: boolean;
};

export type SetLeaseDocumentSuggestionsAction = {
  type: SetLeaseDocumentSuggestionsType;
  documentSuggestions: DocumentSuggestion[];
};

export const setLeaseDocumentStatus = (status: string | null): SetLeaseDocumentStatusAction => ({
  type: 'setLeaseDocumentStatus',
  status,
});

export const setLeaseDocumentPossession = (currentTeamPossession: boolean): SetLeaseDocumentPossessionAction => ({
  type: 'setLeaseDocumentPossession',
  currentTeamPossession,
});

export const setLeaseDocumentEditorDisabled = (isEditorDisabled: boolean): SetLeaseDocumentEditorDisabledAction => ({
  type: 'setLeaseDocumentEditorDisabled',
  isEditorDisabled,
});

export const setDocumentCompareModeEnabled = (isCompareModeEnabled: boolean): SetDocumentCompareModeEnabledAction => ({
  type: 'setDocumentCompareModeEnabled',
  isCompareModeEnabled,
});

export const setLeaseDocumentSuggestions = (documentSuggestions: DocumentSuggestion[]) => ({
  type: 'setLeaseDocumentSuggestions',
  documentSuggestions,
});

export type SetDocumentEversignPdfUrl = 'setDocumentEversignPdfUrl';
export type SetDocumentEversignPdfUrlAction = {
  type: SetDocumentEversignPdfUrl;
  eversignPdfUrl: string | null;
};

export const setDocumentEversignPdfUrl = (eversignPdfUrl: string | null) => ({
  type: 'setDocumentEversignPdfUrl',
  eversignPdfUrl,
});

export type SetDocumentTrackChangesMode = 'setDocumentTrackChangesMode';
export type SetDocumentTrackChangesModeAction = {
  type: SetDocumentTrackChangesMode;
  trackChangesMode: boolean;
};

export type SetDocumentRestrictedEditingMode = 'setDocumentRestrictedEditingMode';
export type SetDocumentRestrictedEditingModeAction = {
  type: SetDocumentRestrictedEditingMode;
  restrictedEditingMode: boolean;
};

export const setDocumentRestrictedEditingMode = (restrictedEditingMode: boolean) => ({
  type: 'setDocumentRestrictedEditingMode',
  restrictedEditingMode,
});

export const setDocumentTrackChangesMode = (trackChangesMode: boolean) => ({
  type: 'setDocumentTrackChangesMode',
  trackChangesMode,
});

export type SetLeaseDocumentEversignStatus = 'setLeaseDocumentEversignStatus';
export type SetLeaseDocumentEversignStatusAction = {
  type: SetLeaseDocumentEversignStatus;
  eversignStatus: string;
};

export const setLeaseDocumentEversignStatus = (eversignStatus: string) => ({
  type: 'setLeaseDocumentEversignStatus',
  eversignStatus,
});

/**
 * handleSetInteractionsFeedToken
 */
export type SetInteractionsFeedTokenType = 'setInteractionsFeedToken';
export type SetInteractionsFeedTokenAction = {
  type: SetInteractionsFeedTokenType;
  interactionsFeedToken: string | null;
};

export const setInteractionsFeedToken = (interactionsFeedToken: string | null): SetInteractionsFeedTokenAction => ({
  type: 'setInteractionsFeedToken',
  interactionsFeedToken,
});

/**
 * title
 */
export type SetLeaseDocumentTitleActionType = 'setLeaseDocumentTitle';
export type SetLeaseDocumentTitleAction = {
  type: SetLeaseDocumentTitleActionType;
  title: string | null;
};

export const setLeaseDocumentTitle = (title: string | null): SetLeaseDocumentTitleAction => ({
  type: 'setLeaseDocumentTitle',
  title,
});

/**
 * name
 */
export type SetLeaseDocumentNameActionType = 'setLeaseDocumentName';
export type SetLeaseDocumentNameAction = {
  type: SetLeaseDocumentNameActionType;
  name: string | null;
};

export const setLeaseDocumentName = (name: string | null): SetLeaseDocumentNameAction => ({
  type: 'setLeaseDocumentName',
  name,
});

export type SetLeaseDocumentCloudDocumentVersion = 'setLeaseDocumentCloudDocumentVersion';
export type SetLeaseDocumentCloudDocumentVersionAction = {
  type: SetLeaseDocumentCloudDocumentVersion;
  documentVersion: number | string;
};
export const setLeaseDocumentCloudDocumentVersion = (documentVersion: string | number) => ({
  type: 'setLeaseDocumentCloudDocumentVersion',
  documentVersion,
});

/**
 * isEditing
 */
export type SetLeaseDocumentIsEditing = 'setLeaseDocumentIsEditing';
export type SetLeaseDocumentIsEditingAction = {
  type: SetLeaseDocumentIsEditing;
  isEditing: boolean;
};

export const setLeaseDocumentIsEditing = (isEditing: boolean): SetLeaseDocumentIsEditingAction => ({
  type: 'setLeaseDocumentIsEditing',
  isEditing,
});

/**
 * isPending
 */
export type SetLeaseDocumentIsPending = 'setLeaseDocumentIsPending';
export type SetLeaseDocumentIsPendingAction = {
  type: SetLeaseDocumentIsPending;
  isPending: boolean;
};

export const setLeaseDocumentIsPending = (isPending: boolean): SetLeaseDocumentIsPendingAction => ({
  type: 'setLeaseDocumentIsPending',
  isPending,
});

export type SetLeaseEditorIsLoaded = 'setLeaseEditorIsLoaded';
export type SetLeaseEditorIsLoadedAction = {
  type: SetLeaseEditorIsLoaded;
  isEditorLoaded: boolean;
};

export const setLeaseEditorIsLoaded = (isEditorLoaded: boolean): SetLeaseEditorIsLoadedAction => ({
  type: 'setLeaseEditorIsLoaded',
  isEditorLoaded,
});

/**
 * lastUpdated
 */
export type SetLeaseDocumentLastUpdated = 'setLeaseDocumentLastUpdated';
export type SetLeaseDocumentLastUpdatedAction = {
  type: SetLeaseDocumentLastUpdated;
  lastUpdated: string;
};

export const setLeaseDocumentLastUpdated = (lastUpdated: string): SetLeaseDocumentLastUpdatedAction => ({
  type: 'setLeaseDocumentLastUpdated',
  lastUpdated,
});

/**
 * signers
 */

export type SetDocumentSigners = 'setDocumentSigners';
export type SetDocumentSignersAction = {
  type: SetDocumentSigners;
  signers: DocumentSigner[];
};

export const setDocumentSigners = (signers: DocumentSigner[]): SetDocumentSignersAction => ({
  type: 'setDocumentSigners',
  signers,
});

/**
 * receiverTeamId
 */
export type SetLeaseDocumentReceiverTeamId = 'setLeaseDocumentReceiverTeamId';
export type SetLeaseDocumentReceiverTeamIdAction = {
  type: SetLeaseDocumentReceiverTeamId;
  receiverTeamId: string | null;
};

export const setLeaseDocumentReceiverTeamId = (
  receiverTeamId: string | null
): SetLeaseDocumentReceiverTeamIdAction => ({
  type: 'setLeaseDocumentReceiverTeamId',
  receiverTeamId,
});

/**
 * teamId
 */
export type SetLeaseDocumentTeamId = 'setLeaseDocumentTeamId';
export type SetLeaseDocumentTeamIdAction = {
  type: SetLeaseDocumentTeamId;
  teamId: string | null;
};

export const setLeaseDocumentTeamId = (teamId: string | null): SetLeaseDocumentTeamIdAction => ({
  type: 'setLeaseDocumentTeamId',
  teamId,
});

/**
 * saveLeaseDocumentDraft
 */
export type SaveLeaseDocumentDraft = 'saveLeaseDocumentDraft';
export type SaveLeaseDocumentDraftAction = {
  type: SaveLeaseDocumentDraft;
};

/**
 * resetLeaseDocument
 */
export type ResetLeaseDocument = 'resetLeaseDocument';
export type ResetLeaseDocumentAction = {
  type: ResetLeaseDocument;
};

export const resetLeaseDocumentState = (): ResetLeaseDocumentAction => ({
  type: 'resetLeaseDocument',
});

export const saveLeaseDocument = (opts: any) => (dispatch: Dispatch, getState: GetSimpleleaseAppState) => {
  let { id, document, save, apolloClient, onError, onSuccess } = opts;
  const title = selectLeaseDocumentTitle(getState());
  const documentId = selectLeaseDocumentId(getState());

  dispatch({ type: 'saveLeaseDocumentDraft' });
  dispatch(setLeaseDocumentIsPending(true));
  const fieldsForUpdate = {
    documentId: id,
    document,
    title: documentId ? title : null,
  };

  return save(fieldsForUpdate)(apolloClient)
    .then((response: any) => {
      dispatchLeaseDocumentResponseToStore(dispatch, response, false);

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    })
    .catch(onError)
    .finally(() => {
      dispatch(setLeaseDocumentIsPending(false));
    });
};

export const saveDocumentTemplate = (opts: any) => (dispatch: Dispatch, getState: GetSimpleleaseAppState) => {
  let { id, template, save, name, onError, onSuccess, apolloClient } = opts;

  dispatch({ type: 'saveLeaseDocumentDraft' });
  dispatch(setLeaseDocumentIsPending(true));

  let fieldsForUpdate: any = {
    documentTemplateId: id,
  };

  if (template) {
    fieldsForUpdate['template'] = template;
  }

  if (name) {
    fieldsForUpdate['name'] = name;
  }

  return save(fieldsForUpdate)(apolloClient)
    .then((response: any) => {
      dispatchLeaseDocumentResponseToStore(dispatch, response, false);

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    })
    .catch(onError)
    .finally(() => {
      dispatch(setLeaseDocumentIsPending(false));
    });
};

/**
 * add document session activity
 */
export type AddSessionActivity = 'addSessionActivity';
export type AddSessionActivityAction = {
  type: AddSessionActivity;
  activity: string;
};

export const addSessionActivity = (activity: string): AddSessionActivityAction => ({
  type: 'addSessionActivity',
  activity,
});

/**
 * add document session activity
 */
export type ResetSessionActivities = 'resetSessionActivities';

export type ResetSessionActivitiesAction = {
  type: ResetSessionActivities;
};
export const resetSessionActivities = () => ({
  type: 'resetSessionActivities',
});

export type SetEditorSuggestions = 'setEditorSuggestions';
export type SetEditorSuggestionsAction = {
  type: SetEditorSuggestions;
  suggestions: string[];
};

export const setEditorSuggestions = (suggestions: string[]): SetEditorSuggestionsAction => ({
  type: 'setEditorSuggestions',
  suggestions,
});

export type SetRejectedSuggestion = 'setRejectedSuggestion';
export type SetRejectedSuggestionAction = {
  type: SetRejectedSuggestion;
  isRejected: boolean;
};

export const setRejectedSuggestion = (isRejected: boolean): SetRejectedSuggestionAction => ({
  type: 'setRejectedSuggestion',
  isRejected,
});

export type SetDocumentShouldReturnForReceiverReview = 'setDocumentShouldReturnForReceiverReview';
export type SetDocumentShouldReturnForReceiverReviewAction = {
  type: SetDocumentShouldReturnForReceiverReview;
  shouldReturnForReceiverReview: boolean;
};

export const setDocumentShouldReturnForReceiverReview = (
  shouldReturnForReceiverReview: boolean
): SetDocumentShouldReturnForReceiverReviewAction => ({
  type: 'setDocumentShouldReturnForReceiverReview',
  shouldReturnForReceiverReview,
});

export type SetDocumentSource = 'setDocumentSource';
export type SetDocumentSourceAction = {
  type: SetDocumentSource;
  source: DocumentSourceEnum | null;
};

export const setDocumentSource = (source: DocumentSourceEnum): SetDocumentSourceAction => ({
  type: 'setDocumentSource',
  source,
});

export type SetDocumentImportedAssetUrl = 'setDocumentImportedAssetUrl';
export type SetDocumentImportedAssetUrlAction = {
  type: SetDocumentImportedAssetUrl;
  url: string | null;
};

export const setDocumentImportedAssetUrl = (url: string | null): SetDocumentImportedAssetUrlAction => ({
  type: 'setDocumentImportedAssetUrl',
  url,
});

export type SetDocumentOriginalImportedAssetUrl = 'setDocumentOriginalImportedAssetUrl';
export type SetDocumentOriginalImportedAssetUrlAction = {
  type: SetDocumentOriginalImportedAssetUrl;
  url: string | null;
};

export const setDocumentOriginalImportedAssetUrl = (url: string | null): SetDocumentOriginalImportedAssetUrlAction => ({
  type: 'setDocumentOriginalImportedAssetUrl',
  url,
});

export type SetDocumentImportedAssetContentType = 'setDocumentImportedAssetContentType';
export type SetDocumentImportedAssetContentTypeAction = {
  type: SetDocumentImportedAssetContentType;
  contentType: ImportedDocumentSupportedContentTypes | null;
};

export const setDocumentImportedAssetContentType = (
  contentType: ImportedDocumentSupportedContentTypes | null
): SetDocumentImportedAssetContentTypeAction => ({
  type: 'setDocumentImportedAssetContentType',
  contentType,
});

export type SetDocumentGeneratedDocumentPdfUrl = 'setDocumentGeneratedDocumentPdfUrl';
export type SetDocumentGeneratedDocumentPdfUrlAction = {
  type: SetDocumentGeneratedDocumentPdfUrl;
  url: string | null;
};

export const setDocumentGeneratedDocumentPdfUrl = (url: string | null): SetDocumentGeneratedDocumentPdfUrlAction => ({
  type: 'setDocumentGeneratedDocumentPdfUrl',
  url,
});

export type SetDocumentPlaceholderRefreshKey = 'setDocumentPlaceholderRefreshKey';
export type SetDocumentPlaceholderRefreshKeyAction = {
  type: SetDocumentPlaceholderRefreshKey;
  placeholderRefreshKey: number;
};

export const setDocumentPlaceholderRefreshKey = (placeholderRefreshKey: number): SetDocumentPlaceholderRefreshKeyAction => ({
  type: 'setDocumentPlaceholderRefreshKey',
  placeholderRefreshKey,
});

export type LeaseDocumentAction =
  | SetLeaseDocumentAction
  | SetLeaseDocumentTemplateAction
  | SetDocumentApprovalRequestAction
  | SetLeaseDocumentIdAction
  | SetLeaseDocumentStatusAction
  | SetInteractionsFeedTokenAction
  | SetLeaseDocumentPossessionAction
  | SetLeaseDocumentSuggestionsAction
  | SetLeaseDocumentEditorDisabledAction
  | SetLeaseDocumentTitleAction
  | SetLeaseDocumentNameAction
  | SetLeaseDocumentIsEditingAction
  | SetLeaseDocumentIsPendingAction
  | SetLeaseEditorIsLoadedAction
  | SetLeaseDocumentLastUpdatedAction
  | SetDocumentSignersAction
  | SetLeaseDocumentReceiverTeamIdAction
  | SetLeaseDocumentTeamIdAction
  | SetDocumentEversignPdfUrlAction
  | SetDocumentRestrictedEditingModeAction
  | SetDocumentTrackChangesModeAction
  | SetDocumentCompareModeEnabledAction
  | SetLeaseDocumentEversignStatusAction
  | SaveLeaseDocumentDraftAction
  | ResetLeaseDocumentAction
  | SetLeaseDocumentCloudDocumentVersionAction
  | AddSessionActivityAction
  | ResetSessionActivitiesAction
  | SetEditorSuggestionsAction
  | SetDocumentShouldReturnForReceiverReviewAction
  | SetDocumentSourceAction
  | SetDocumentImportedAssetUrlAction
  | SetDocumentOriginalImportedAssetUrlAction
  | SetDocumentImportedAssetContentTypeAction
  | SetDocumentGeneratedDocumentPdfUrlAction
  | SetIsDocumentEditableAction
  | SetIsDocumentTitleEditableAction
  | SetIsReceiverCollaborationEnabledAction
  | SetIsEligibleForSigningPrepAction
  | SetRejectedSuggestionAction
  | SetDocumentPlaceholderRefreshKeyAction;
