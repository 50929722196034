import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Header = () => (
  <header>
    <FormattedMessage id="title.terms-and-conditions" defaultMessage="Terms &amp; Conditions" />
  </header>
);

export const Body = () => (
  <div className="body-text legal-document">
    <p>Last Updated: March 12, 2020</p>
    <p>
      Propdocs Corp. ("<span className="legal-term">Propdocs</span>" "<span className="legal-term">us</span>" "
      <span className="legal-term">we</span>" "<span className="legal-term">our</span>") welcomes you to the Propdocs
      Platform (the "<span className="legal-term">Platform</span>"). The Platform includes the Propdocs website (the "
      <span className="legal-term">Website</span>") and associated products, services, and content. Please read these
      Terms &amp; Conditions (these "<span className="legal-term">Terms</span>") carefully before accessing or using the
      Platform. These Terms are a legally binding contract between you and Propdocs.
    </p>
    <p>
      You accept and agree to be bound by these Terms when you: (a) access or use any of the Platform; (b) click or tap
      a button, or check a box, indicating you accept these Terms; or (c) when, during the registration or sign-up
      process, you use some other industry standard mechanism for indicating acceptance of these Terms. By accepting
      these Terms, you agree with our{' '}
      <a href="https://propdocs.com/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      .
    </p>
    <p>
      If you are subscribing for or using the Platform on behalf of an entity (e.g. a company), then you: (a) represent
      and warrant that you are an authorized representative or agent of that entity with the authority to bind such
      entity to these Terms; and (b) accept these Terms on behalf of such entity. In such a circumstance, the words
      "you", "your" and "yourself" as used in these Terms will refer to and apply to both that entity and you
      personally.
    </p>
    <p className="important extra-important">
      THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION IN SECTION 14.2 WHICH PROVIDES THAT YOU WILL RESOLVE ALL
      DISPUTES THROUGH MANDATORY AND BINDING ARBITRATION, UNLESS YOU OPT OUT THROUGH THE MECHANISM PROVIDED IN THESE
      TERMS OR ARE IN A PROVINCE, STATE, OR COUNTRY IN WHICH THE COURTS OR LAW WILL NOT PERMIT YOU TO CONSENT TO BINDING
      ARBITRATION. THIS MEANS THAT, IN THE EVENT OF A DISPUTE WITH Propdocs, YOU WILL NOT BE ABLE TO HAVE THAT DISPUTE
      RESOLVED BY A JUDGE OR A JURY. ADDITIONALLY, IN SECTION 14.3 OF THESE TERMS, YOU ALSO WAIVE YOUR RIGHT TO
      PARTICIPATE IN CLASS ACTIONS AGAINST Propdocs AND WAIVE YOUR RIGHT TO A JURY TRIAL. PLEASE CAREFULLY READ SECTIONS
      14.2 AND 14.3.
    </p>
    <ol>
      <li>
        <h3>The Platform</h3>
        <ol>
          <li>
            <h4>Description.</h4> The Platform is a web-based lease transaction management platform that enables
            registered users ("<span className="legal-term">Users</span>") to negotiate, enter into, pay, accept
            payment, administer, and manage commercial lease agreements ("
            <span className="legal-term">Lease Agreements</span>") for commercial deals ("
            <span className="legal-term">Deals</span>").
          </li>
          <li>
            <h4>Relationship of the Parties.</h4>{' '}
            <ol>
              <li>
                Propdocs does not own, lease, license, sell, create, resell, control, manage, offer, supply, or deliver
                any Deals. Although we provide lease agreement forms (each form, a "
                <span className="legal-term">Lease Form</span>"), we do not assist you with the negotiation of any Lease
                Agreements and we are not parties to any Lease Agreements you enter into as a result of your use of a
                Lease Form. When a Landlord and Tenant enter into a Lease Agreement, they are contracting directly with
                each other and not with Propdocs.
              </li>
              <li className="important">
                Landlords and Tenants are not employees, partners, representatives, agents, joint venturers, independent
                contractors or franchisees of Propdocs. Propdocs is not a lessee or lessor of Deals and does not
                employee or contract individuals to grant leases to or lease Deals. Propdocs does not, and does not
                employee or contract individuals, to negotiate, enter into, or manage Lease Agreements.
              </li>
            </ol>
          </li>
          <li className="important">
            <h4 className="extra-important">NO LEGAL SERVICES OR BROKERAGE SERVICES.</h4>{' '}
            <ol>
              <li>
                Propdocs does not own, lease, license, sell, create, resell, control, manage, offer, supply, or deliver
                any Deals. Although we provide lease agreement forms (each form, a "
                <span className="legal-term">Lease Form</span>"), we do not assist you with the negotiation of any Lease
                Agreements and we are not parties to any Lease Agreements you enter into as a result of your use of a
                Lease Form. When a Landlord and Tenant enter into a Lease Agreement, they are contracting directly with
                each other and not with Propdocs.
              </li>
              <li>
                Propdocs is not a law firm. We do not provide Legal Advice (defined in Section 15.15). Any communication
                you receive from Propdocs or our employees, agents, or representatives is purely for informational
                purposes and must not be relied upon for the purposes of making a decision with respect to a legal
                matter. In the event any of our employees, agents, or representatives provide you with Legal Advice, you
                agree that such communication is not authorized by Propdocs and you shall immediately delete and
                disregard any Legal Advice contained in that communication. Your use of the Platform or Lease Forms does
                not create a solicitor-client relationship between you and Propdocs. Communications between us and you
                do not create a solicitor-client relationship.
              </li>
              <li>
                Any decision you make regarding a legal matter (those decisions "
                <span className="legal-term">Legal Decisions</span>") is solely your responsibility. You agree to retain
                qualified legal counsel licensed to practice in your jurisdiction to advise you on Legal Decisions if
                doing so would be reasonable in your circumstances.
              </li>
              <li>
                We do not represent, warrant, or guarantee that any Lease Form or Lease Agreement will: (i) meet your
                specific needs; (ii) be appropriate or suitable in your circumstances; or (iii) be compliant with the
                governing laws of your jurisdiction. You acknowledge that you should always review your intended Lease
                Agreement with a qualified legal practitioner licensed in your jurisdiction before entering into a Lease
                Agreement.
              </li>
              <li>
                Propdocs has no responsibility for determining or advising you on how long any Lease Agreement must be
                stored or retained under the governing laws of your jurisdiction.
              </li>
              <li>
                You are solely responsible for determining whether the Lease Agreement may be executed electronically in
                your jurisdiction.
              </li>
              <li>
                Propdocs is not a broker and does not provide brokerage services. Propdocs is not a representative or
                agent of Landlords or Tenants. Without limiting the foregoing, we do not represent you in connection
                with any Lease Agreements or their negotiation. Nothing contained in these Terms will be construed as
                creating any agency, brokerage, partnership, joint venture or other form of joint enterprise,
                employment, or fiduciary relationship between Propdocs and you, and neither Propdocs nor you will have
                authority to contract for or bind the other party in any manner whatsoever.
              </li>
            </ol>
          </li>
          <li>
            <h4>Scope of Services.</h4> The prices, features, functionalities, services, and options of the Platform
            depend on your User type, on your selections, and, if you a Landlord, your subscription plan ("
            <span className="legal-term">Subscription</span>"). The Platform may be changed, withdrawn, suspended, or
            terminated at any time in our sole discretion without notice. We will not be liable if for any reason all or
            any part of the Platform is restricted to users or unavailable at any time or for any period.
          </li>
          <li>
            <h4>Service Areas.</h4> Availability of the Platform, and certain features, functionalities, and services of
            the Platform, are restricted to our authorized geographic service areas. For example, you are not permitted
            to use in connection with Deals located outside of our service area. Check our website to confirm if you
            live within one of our service areas. We reserve the right to decline to provide services to any User, in
            our sole discretion, even if they or their Deal is within a service area.
          </li>
          <li>
            <h4>Restrictions on Deals.</h4> Only Deals which meet our eligibility requirements (as determined in our
            sole discretion) may be premises under Lease Agreements negotiated or managed through our Platform. Check
            out our website for Deal eligibility requirements, as revised from time to time.
          </li>
          <li>
            <h4>Geographic Restriction.</h4> The owner of the Website is based in the Province of British Columbia in
            Canada. We provide this Platform for use only by persons located in Canada. This site is not intended for
            use in any jurisdiction where its use is not permitted. If you access the site from outside Canada you do so
            at your own risk and you are responsible for compliance with local laws of your jurisdiction.
          </li>
          <li>
            <h4>Sharing &amp; Storage.</h4> This Section 1.8 applies only to Landlords with a paid and active
            Subscription. During an active Subscription, Propdocs will store your Lease Agreements, and will share via
            the Platform your Lease Agreements with Tenants as directed by you;{' '}
            <span className="emphasis">except that</span> Propdocs may place reasonable use limits on your storage and
            sharing privileges as necessary to prevent abuse or strain of the Platform.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">ACCOUNT REGISTRATION AND SECURITY</h3>
        <ol>
          <li>
            <h4>Registration.</h4> To receive certain services and to access certain password protected areas of the
            Platform you must first complete the registration process to create an account with us (an "
            <span className="legal-term">Account</span>"), which may involve: (a) registering a unique user name and
            password (collectively, "<span className="legal-term">Credentials</span>"); (b) providing contact
            information, such as your name, company name, phone number, email address, and delivery address; (c)
            providing payment and billing information, including, without limitation, such information as necessary to
            enable us to receive payment instructions from you in connection with a business bank account ("
            <span className="legal-term">Bank Account</span>") at an authorized financial institution; and (d) and
            providing us with any other information we request. You may only create one Account. Additionally, Tenants
            may only create an Account if invited by a Landlord.
          </li>
          <li>
            <h4>Registration Data.</h4> During the registration process, you will provide true, accurate, current and
            complete information about yourself as requested by us (such information being the "
            <span className="legal-term">Registration Data</span>"). You will also maintain and promptly update the
            Registration Data and other Account data to keep it true, accurate, current and complete. If you provide any
            information that is untrue, inaccurate, not current or complete, or if Propdocs has reasonable grounds to
            suspect that such information is untrue, inaccurate, not current or complete, Propdocs has the right to
            suspend or delete your Account and refuse delivery of any and all current or future services to you.
          </li>
          <li>
            <h4>Receipt of Calls and Messages.</h4> By providing us with a phone number, you consent to receive calls
            and text-messages (SMS) from us regarding the Platform. Standard rates apply. Subject to reasonable
            processing times, you may opt-out of these communications by using the unsubscribe mechanism provided to
            you.
          </li>
          <li>
            <h4>Call Recording.</h4> Your phone calls with Propdocs may be recorded for training purposes, to enhance
            customer services, for security, and to confirm our discussions with you.
          </li>
          <li>
            <h4>No Account Sharing.</h4> Your Account is specific to you and may not be shared with or transferred to
            any other person. If you are using the Platform as, or on behalf of, an organization, each natural person in
            your organization who uses the Platform requires their own separate Account. You are solely responsible for
            maintaining the confidentiality of your Account and Credentials and you will be held responsible for any
            harm caused by disclosing or resulting from any unauthorized use of your Credentials. Except as authorized
            in writing by Propdocs, you will not permit any other person to use your Account or Credentials, and you
            will immediately notify Propdocs if you know or suspect that your Account or Credentials have been used by
            any other person. You shall not use or access an Account which is not your own. You must exercise caution
            when accessing your Account from a public or shared computer so that others are not able to view or record
            your password or other Personal Information. You also agree to ensure that you logout from your Account at
            the end of each session.
          </li>
          <li>
            <h4>Unauthorized Access.</h4> You shall take reasonable steps to prevent unauthorized access to the
            Platform, including without limitation by protecting your passwords and other log-in information. You shall
            notify Propdocs immediately of any known or suspected unauthorized use of the Platform or breach of its
            security and shall use reasonable efforts to stop said breach.
          </li>
          <li>
            <h4>Responsibility for Access.</h4> You are solely responsible for all costs, expenses, and liabilities
            related to your access, use, and receipt of the Platform. For example, you are responsible for (a) your
            internet, wireless, and data costs incurred in connection with your use or access of the Platform; and (b)
            obtaining and maintaining any device or other hardware or software you use to access and use the Platform.
            Your ability to access the Platform is dependent on your continued access to sufficient internet and/or data
            services.
          </li>
          <li>
            <h4>Communications from your Account.</h4> Propdocs may act upon any communication that is given through
            your Account or by using your Credentials. All instructions communicated to Propdocs through your Account or
            Credentials will be binding on you. Propdocs is not required to verify the actual identity or authority of a
            person using your Account or Credentials, but Propdocs may in its discretion at any time require
            verification of the identity of a person seeking to access your Account and may deny access to and use of
            your Account if Propdocs is not satisfied with the verification. If Propdocs, in its discretion, considers
            your Account or Credentials to be unsecure or to have been used inappropriately, then Propdocs may
            immediately cancel or suspend the Account or Credentials without any notice to you. We may require you to
            change your Credentials from time to time.
          </li>
          <li>
            <h4>Linking Bank Accounts.</h4> Multiple Accounts may be linked to a single Bank Account by individuals
            authorized to give instructions regarding that Bank Account (those individuals, "
            <span className="legal-term">Authorized Individuals</span>"). If you are an Authorized Individual, you
            confirm that any other individuals who use the Platform on your behalf are authorized by you to give
            instructions with respect to the Bank Accounts linked to your Account. To revoke the authorization of a
            person or an Account to provide instructions with respect to a Bank Account, you must contact us at
            support@propdocs.com.
          </li>
          <li>
            <h4>Age of Majority.</h4> You represent and warrant that you are the legal age of majority under applicable
            law to form a binding contract with Propdocs.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">LEASE FORMS AND LEASE AGREEMENTS</h3>
        <ol>
          <li>
            <h4>Lease Forms.</h4> Lease Forms are the intellectual property of Propdocs or our licensors. Conditional
            upon your compliance with these Terms, Propdocs hereby grants you a non-exclusive, non-sublicensable, and
            non-transferable, limited license to use the Lease Forms solely for the purposes of drafting, negotiating,
            and entering into Lease Agreements through the Platform. The Lease Forms may not be used for any other
            purpose. This license is immediately revoked upon the deletion of your Account.
          </li>
          <li>
            <h4>Binding Agreement.</h4> When you are ready to enter into a Lease Agreement, you will visit a third-party
            website to execute the Lease Agreement. Once you and all other parties to the Lease Agreement have executed
            the Lease Agreement, a legally binding contract will be formed between you and the other parties to your
            Lease Agreement, provided that the legal requirements for contract formation have been met. Propdocs is not
            a party to the Lease Agreement and does not represent, warrant, or guarantee the enforceability of any Lease
            Agreement.
          </li>
          <li>
            <h4>Payments under Lease Agreements.</h4> Tenants are responsible for paying Landlords the Lease Payments at
            the times and in the amounts specified in the applicable Lease Agreements. As part of the Platform, we may
            provide Tenants the ability to pay Landlords, and Landlords the ability to receive payment of, Lease
            Payments by electronic funds transfer or pre-authorized debit through a third party service provider (that
            third party, the "<span className="legal-term">Lease Payment Service Provider</span>" and the services
            provided by the Lease Payment Service Provider, the "
            <span className="legal-term">Lease Payment Services</span>"). Your use of the Lease Payment Services is
            subject to the eligibility requirements and the terms and conditions and privacy policy of the Lease Payment
            Service Provider (those terms and conditions and privacy policy, the "
            <span className="legal-term">Lease Payment Terms</span>"). The Lease Payment Terms will be made available to
            you for your review and acceptance before you may access the Lease Payment Services. In the event of a
            conflict between these Terms and the Lease Payment Terms with respect to Lease Payment Services, the Lease
            Payment Terms will govern to the extent of the conflict. We are not a party to the Lease Payment Terms. If
            you are a Tenant and make a request to send funds by pre-authorized debit from your Bank Account, you will
            first be required to accept the{' '}
            <a href="https://propdocs.com/padagreement" target="_blank" rel="noopener noreferrer">
              pre-authorized debit agreement
            </a>{' '}
            presented to you ("<span className="legal-term">PAD Agreement</span>"). You consent to us sharing your
            information with the Lease Payment Service Provider and such other parties as necessary to facilitate and
            enable the payment and receipt of Lease Payments under your Lease Agreements. This may include sharing your
            PAD Agreement with the Lease Payment Service Provider and any Landlords under Lease Agreements to which you
            are a party. Propdocs is not responsible or liable for the Lease Payment Services.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">FEES</h3>
        <ol>
          <li>
            <h4>Advertisements.</h4> Advertisements respecting our products and services are invitations to you to make
            offers to purchase products and services and are not offers to sell. A properly-completed order on our
            Platform by you constitutes only your offer to purchase the products or services in your order, even if
            Propdocs has processed payment for such offer. Your order will be accepted only if and when Propdocs sends a
            purchase confirmation email ("<span className="legal-term">Confirmation Email</span>") to the email address
            you provided during your registration or order process or as otherwise updated on your Account. If Propdocs
            does not accept your order in whole or in part, Propdocs will refund any amounts paid by you to Propdocs for
            the order or part of the order not accepted and no contract will be formed with respect to such order.
          </li>
          <li>
            <h4>Corrections.</h4> Any price offers or advertisements given by Propdocs will be valid for the period
            stated on the offer or advertisement, except that Propdocs reserves the right to reject any sale for any
            reason, including without limitation for typographical errors, illustrative errors, or a misprint in the
            price offer or advertisement. In the event of a pricing mistake, we will not be bound by the incorrect
            price.
          </li>
          <li>
            <h4>Charges for Platform.</h4> Your use of certain products or services on the Platform may result in
            charges ("<span className="legal-term">Charges</span>") to you. Charges may include applicable taxes. Prices
            for products or services will be presented to you at or before the point of purchase. You agree to promptly
            pay all applicable Charges. Charges might be one-time, usage based, or recurring, depending on what you have
            purchased. When incurring a Charge, you must provide accurate and complete personal and billing information
            as requested by us. Access to the Platform is conditional upon your prompt payment of Charges.
          </li>
          <li>
            <h4>Subscription &amp; Auto-Renewal.</h4> Certain services on the Platform may require registering for an
            ongoing Subscription. If you register for a Subscription, you will be billed on a recurring, periodic basis
            at the end of each billing period for the fees applicable to your Subscription, which may include accrued
            Charges calculated based on your usage of the Platform. The length of your billing period depends on the
            subscription plan you select when registering for a Subscription. At the end of each billing period, your
            Subscription will automatically renew unless you have cancelled your Subscription through the Platform or
            unless Propdocs has terminated this Agreement or deleted your Account.
          </li>
          <li>
            <h4>Payment.</h4> When you provide a payment method, such as a credit card, that is accepted by us, you
            represent and warrant that you are authorized to use such payment method and that you authorize us (or our
            third-party payment processor) to charge your payment method for all applicable Charges. For example, when
            you purchase services from us, you permit us to charge the payment method you have chosen in the total
            amount of your purchase or the amounts accrued under your Subscription (including applicable taxes). If we,
            or our third-party payment processor, are unable to verify your payment method, or if it is otherwise
            invalid or not acceptable, we may suspend your access to the Platform or cancel your order. Notwithstanding
            any suspension of your access to the Platform or cancellation of your order, you remain obligated to pay us
            for any Charges.
          </li>
          <li>
            <h4>Sales Final.</h4> Except as otherwise provided in these Terms, or as is required by law, all fees paid
            by you to us are non-refundable and non-returnable, including, without limitation, with respect to any
            Subscription. Subject to (a) the terms applicable to your selected payment processing method, and (b)
            applicable law; you are solely responsible for any unauthorized, fraudulent, or erroneous transactions
            related to your use of the Platform or any use of the Platform through your Account.
          </li>
          <li>
            <h4>Prices.</h4> Prices on our website are expressed in Canadian Dollars (CAD) unless otherwise noted. If
            the product or service sold has additional terms and conditions, those additional terms and conditions will
            be provided by us to you prior to the sale. We may modify the fees charged for services on the Platform at
            any time, except that fee changes to your Subscription will not apply until the commencement of your next
            Billing Period.
          </li>
          <li>
            <h4>Promotional Offers.</h4> At our discretion, we may make available certain products or services at a
            discount or for free for a trial period ("<span className="legal-term">Promotions</span>"). We reserve the
            right to modify or cancel these Promotions at any time. If we reasonably determine you have violated the
            terms applicable to the Promotion, we may charge you the full price for the product or service that was
            otherwise subject to the Promotion. At the end of any free trials, if you have not cancelled your
            Subscription or terminated this Agreement in accordance with its terms, we (or our third-party payment
            processor) may begin charging you for your Subscription Plan without additional notice to you
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">TERMINATION AND SUSPENSION</h3>
        <ol>
          <li>
            <h4>Access to Platform.</h4> We may terminate, suspend, or limit your right to access and use the Platform
            if we are of the belief that you have or soon will breach these Terms. If your access or use of the Platform
            has been terminated, suspended, or limited by Propdocs, you shall not register a new Account on the
            Platform. You will not be entitled to a refund if we terminate, suspend, or limit your right to access or
            use the Platform in accordance with this section. For the avoidance of doubt, if Propdocs terminates,
            suspends, or limits your access and use of the Platform under this section, the Terms will remain in force.
          </li>
          <li>
            <h4>Termination of Terms.</h4> We may terminate these Terms at any time by providing notice to you. The
            following provisions will survive termination of these Terms: Articles and Sections 1.2, 1.3, 2.7, 2.10,
            2.8, 4.2, 5, 6.1, 6.2, 7, 8.1, 8.2, 8.4, 8.5, 9.2, 11.1, 11, 12, 13, 14, and 15, any warranties you provide
            under these Terms, and all other provisions of these Terms which must survive termination of these Terms to
            fulfill their purpose will survive termination of these Terms.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">OUR INTELLECTUAL PROPERTY</h3>
        <ol>
          <li>
            <h4>Trademarks.</h4> Propdocs, Propdocs.com, and related words and logos, as well as the names of other
            products or service of Propdocs, are trade names, trade-marks, or registered trademarks of Propdocs. The
            names of other companies, products or services referred to on the Platform may be the trademarks of their
            respective owners. Any unauthorized use of any of these trade names or trademarks is prohibited.
          </li>
          <li>
            <h4>Copyrights.</h4> All Propdocs Materials are Copyright &copy; 2019, Propdocs or its licensors, except as
            otherwise indicated in writing. All rights reserved.
          </li>
          <li>
            <h4>License.</h4> You may download (subject to the terms of any applicable software license) or print a hard
            copy of materials from this website for your own personal use, but any copying or reproduction of these
            materials for commercial purposes without the permission of Propdocs is prohibited. The distribution,
            re-transmission, republication, modification, reverse engineering, sale or other exploitation of this
            Platform without the permission of Propdocs is also prohibited.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">YOUR USER CONTENT AND PRIVACY</h3>
        <ol>
          <li>
            <h4>Permission from You.</h4> Portions of the Platform may allow Users to post and exchange information and
            content (such information and content, "<span className="legal-term">User Content</span>"), such as images,
            text, audio, video, and other forms of data or communication. For the avoidance of doubt, User Content
            includes, without limitation, any modification you or other Users make to Lease Forms; except that any
            unmodified portion of the Lease Form remains the intellectual property of Propdocs. Propdocs does not
            screen, edit or review any User Content before it is posted or transmitted. You retain the copyright and any
            other rights you already hold in your User Content. By submitting, uploading, posting, or providing User
            Content on our Platform, you grant Propdocs all such rights and permission as necessary or useful: (a) to
            access, Process and otherwise use your User Content in order to provide Propdocs's current and future
            products and/or services to you and other persons; (b) to track and analyze your use of the Platform; (c) to
            make your User Content available to other users of the Platform and other third parties as directed or
            authorized by you; (d) to provide analysis and insights regarding leasing trends and leasing transaction
            data; (e) to carry out lease abstraction and publish the results therefrom; and (d) to enforce these Terms
            and exercise our rights and perform our obligations under these Terms.
            <p>
              To the extent that you have intellectual property rights in your User Content, you grant Propdocs a
              world-wide, perpetual, non-exclusive, royalty-free, sublicensable, transferable license to exploit, use,
              access, store, reproduce, adapt, translate, publish, publicly perform, publicly display, modify, repost,
              sublicense, create derivative works from, and distribute your User Content for the purposes outlined in
              this Agreement (the "<span className="legal-term">User Content Licence</span>").
            </p>
          </li>
          <li>
            <h4>Rights in Your User Content.</h4> You confirm, represent, and warrant to Propdocs that you have all the
            rights, power, and authority necessary to grant the User Content Licence and that any User Content you
            submit to us or otherwise post on or upload to our Platform is and shall be your own original work or work
            which you are authorized to supply to us. When you submit or post any User Content on our or through our
            Platform and you are the author of such User Content, you irrevocably and unconditionally waive all moral
            rights you may now or in the future have in any such User Content.
          </li>
          <li>
            <h4>Disclaimer.</h4> Please note that User Content on our Platform does not necessarily reflect the views of
            Propdocs, and Propdocs disclaims all responsibility for any such User Content and for any losses or expenses
            resulting from their use or appearance on the Platform.
          </li>
          <li>
            <h4>Responsibility.</h4> You understand that all User Content, whether publicly posted or privately
            transmitted when posting, sharing, displaying, sending or submitting material using our Platform, is the
            sole responsibility of the person from whom it originated. This means that you, and not Propdocs, are
            entirely responsible: (i) for all User Content that you upload, post, email, transmit, or otherwise make
            available via our Platform; and (ii) for ensuring that all User Content is accurate, lawful, suitable for
            your circumstances, and does not include misleading information or infringe or violate anyone's rights.
            Propdocs does not control the User Content posted via our website and, therefore, does not guarantee the
            accuracy, integrity, suitability, or quality of any User Content. You therefore agree that you will not hold
            Propdocs responsible or liable for any inaccuracies or for any errors or omissions in any User Content, or
            for any loss, injury or damages of any kind incurred as a result of the use of or reliance upon any User
            Content posted, emailed, transmitted, or otherwise made available via our Platform.
          </li>
          <li>
            <h4>Removal of User Content.</h4> You acknowledge that Propdocs is not obligated to pre-screen, monitor, or
            modify User Content, but that Propdocs has the right (but not the obligation) to refuse, remove, modify, or
            delete any User Content that is available via our Platform that violates these Terms, or is otherwise
            objectionable, in our sole and arbitrary discretion. You acknowledge and expressly consent to Propdocs,
            accessing, preserving, and disclosing your Account information and User Content if required to do so by law
            or if in good faith Propdocs believes that such access, preservation or disclosure is reasonably necessary
            to: (a) comply with legal process; (b) enforce these Terms; (c) respond to claims that any User Content
            violates the rights of third-parties; (d) respond to your requests for customer service; or (e) protect the
            rights, property, or personal safety or security of Propdocs, or our affiliates, staff, users, or the
            public.
          </li>
          <li>
            <h4>Feedback.</h4> We welcome any questions, comments or feedback you might have about the Platform or these
            Terms ("<span className="legal-term">Feedback</span>"). Please refer to the Contact section of our website
            for our contact information. You should not submit or send to us any patentable ideas or patent
            applications, advertising or marketing suggestions, know-how, trade secrets, prototypes or any information,
            written or oral, which you regard as confidential or commercially sensitive or valuable (collectively
            referred to as "<span className="legal-term">Unwanted Submissions</span>"). While we value your feedback,
            you agree not to submit any Unwanted Submissions. We shall not be subject to any obligation of
            confidentiality nor be liable for any use and/or disclosure of such submissions. You agree we are free to
            use your Unwanted Submissions as we see fit without any liability owed to you.
          </li>
          <li>
            <h4>Risk of Exposure.</h4>{' '}
            <span className="important extra-important">
              YOU UNDERSTAND AND AGREE THAT SHARING CONTENT ONLINE INVOLVES RISKS OF UNAUTHORIZED DISCLOSURE OR EXPOSURE
              AND THAT, IN SUBMITTING YOUR USER CONTENT TO OR TRANSMITTING IT THROUGH THE PLATFORM, YOU ASSUME THESE
              RISKS.
            </span>{' '}
            Propdocs offers no representation, warranty, or guarantee that your User Content or Lease Agreements will
            not be exposed or disclosed through the Platform or through errors or the actions of third parties.
          </li>
          <li>
            <h4>Third-Party Rights Warranty.</h4> You represent, warrant, and covenant to Propdocs that your User
            Content, Feedback, and Unwanted Submissions do not violate the privacy rights, intellectual property rights,
            personality rights, or any other rights, of any person.
          </li>
          <li>
            <h4>Infringing Content.</h4> As copyright holders ourselves, we respect copyright holders' rights and will
            quickly respond to any claims of copyright infringement reported on the Services. If you find anything on
            the Services that you believe infringes your copyright or the copyrights of a person you are authorized to
            represent, please report the alleged infringement to us at info@Propdocs.com.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">DATA &amp; SECURITY</h3>
        <ol>
          <li>
            <h4>Data Backup.</h4> The Platform does not replace the need for you to independently maintain regular data
            backups or redundant data archives. Propdocs HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION,
            DESTRUCTION, DAMAGE, CORRUPTION, OR RECOVERY OF YOUR USER DATA OR LEASE AGREEMENTS.
          </li>
          <li>
            <h4>Customer Control and Responsibility.</h4> You are solely responsible for: (a) all of your User Data, (b)
            the security and use of your Access Credentials; and (c) all access to and use of the Platform and directly
            or indirectly by or through your Account or Access Credentials, with or without your knowledge or consent,
            including all results obtained from, and all conclusions, decisions and actions based on, such access or use
          </li>
          <li>
            <h4>Access and Security.</h4> You shall employ all physical, administrative and technical controls,
            screening and security procedures and other safeguards necessary to protect against any unauthorized access
            to or use of the Platform through your Account or Access Credentials.
          </li>
          <li>
            <h4>User Data.</h4> As between you and Propdocs, you are and will remain the sole and exclusive owner of all
            right, title and interest in and to all of your User Data (to the extent you are the owner), including all
            intellectual property rights relating thereto, subject to Section 7.1. Propdocs is the owner of all
            Resultant Data.
          </li>
          <li>
            <h4>Resultant Data.</h4> Without limiting Propdocs's rights with respect to the Resultant Data, Propdocs may
            use, reproduce, sell, publicize, or otherwise exploit Resultant Data in any way, in its sole discretion.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">ACCEPTABLE USE POLICY</h3>
        <ol>
          <li>
            <h4>Condition.</h4> Your access and use of the Platform is conditional upon your continual compliance with
            these Terms.
          </li>
          <li>
            <h4>Acceptable Use.</h4> You shall not misuse the Platform or assist or permit any other person's misuse of
            the Platform. Without limiting the generality of the foregoing or anything else in these Terms:
            <ol>
              <li>
                you warrant and agree that your use of the Platform and your User Content and Lease Agreements will not:
                <ol>
                  <li>
                    in any manner violate any applicable federal, provincial, state, local, or international law or
                    regulation including, without limitation, any laws regarding the export of data or software, patent,
                    trademark, trade secret, copyright, or other intellectual property, legal rights (including the
                    rights of publicity and privacy of others) or contain any material that could give rise to any civil
                    or criminal liability under applicable laws or regulations or that otherwise may be in conflict with
                    these Terms and our{' '}
                    <a href="https://propdocs.com/privacy" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                    ;
                  </li>
                  <li>
                    without limiting the generality of section 9.2(a)(i), infringe, violate, or misappropriate the
                    intellectual property rights, personality rights, or privacy rights of any third party;
                  </li>
                  <li>
                    involve, include, or contain any material that is exploitive, obscene, harmful, threatening,
                    abusive, harassing, hateful, defamatory, sexually explicit or pornographic, violent, inflammatory,
                    or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age
                    or other such prohibited ground or be otherwise objectionable;
                  </li>
                  <li>
                    involve stalking, attempting to exploit any individual or harm minors in any way by exposing them to
                    inappropriate content or otherwise nor asking for personal information;
                  </li>
                  <li>involve, provide, or contribute any false, inaccurate or misleading information;</li>
                  <li>
                    involve or include sending, knowingly receiving, uploading, downloading, using, or reusing any
                    material that does not comply with these Terms;
                  </li>
                  <li>
                    involve impersonating any person or entity or falsely stating or misrepresenting your affiliation
                    with a person or entity;
                  </li>
                  <li>
                    involve or include engaging in any other conduct that restricts or inhibits anyone's use or
                    enjoyment of the Platform, or which, as determined by us, may harm Propdocs, or other users of the
                    Platform or expose them to liability;
                  </li>
                  <li>
                    involve or include causing annoyance, inconvenience, or needless anxiety or be likely to upset,
                    embarrass, or alarm any other person;
                  </li>
                  <li>involve or include sending spam or unsolicited commercial electronic messages;</li>
                  <li>promote any illegal activity, or advocate, promote, or assist any unlawful act;</li>
                  <li>
                    give the impression that your User Content originates from or is endorsed by us or any other person
                    or entity, if this is not the case; or
                  </li>
                  <li>
                    involve or include acts or omissions that may be considered defamatory, discriminatory, fraudulent,
                    tortious, or otherwise malicious or harmful to any person; and
                  </li>
                </ol>
              </li>
              <li>
                you agree not to:
                <ol>
                  <li>
                    publish, distribute, copy, or use the Lease Forms except as specifically permitted in these Terms;
                  </li>
                  <li>use the Propdocs Materials in any manner contrary to applicable law or these Terms;</li>
                  <li>access content and data that is not intended for you;</li>
                  <li>attempt to breach or breach our website's security and/or authentication measures;</li>
                  <li>restrict, disrupt or disable service to users, hosts, servers or networks;</li>
                  <li>illicitly reproduce TCP/IP packet header;</li>
                  <li>disrupt network services or otherwise disrupt our ability to monitor the website;</li>
                  <li>
                    use any robot, spider, or other automatic device, process, or means to access the Platform for any
                    purpose, including monitoring or copying any of the material on the Platform;
                  </li>
                  <li>
                    introduce or distribute any viruses, trojan horses, worms, logic bombs, or other material that is
                    malicious or technologically harmful;
                  </li>
                  <li>
                    attack our website via a denial-of-service attack, distributed denial-of-service attack, flooding,
                    mailbombing or crashing;
                  </li>
                  <li>
                    to use scrapers, indexing tools, or other automated tools for the purposes of data mining or
                    surveying on our website;
                  </li>
                  <li>to mirror or frame any part of our Platform;</li>
                  <li>interface any application with our Platform, unless authorized in writing by Propdocs;</li>
                  <li>
                    harvest or otherwise collect, use or disclose personal information about our customers, including
                    email addresses, without their prior consent and then only in compliance with applicable data
                    protection and privacy laws;
                  </li>
                  <li>
                    reverse engineer, decompile, copy, modify, distribute, transmit, license, sublicense, display,
                    revise, perform, transfer, sell or otherwise make available to any third party or otherwise publish,
                    deep-link, create derivative works from or exploit in any way our Platform except as permitted by us
                    under these Terms, or as expressly provided under applicable law; and
                  </li>
                  <li>otherwise attempt to interfere with the proper working of the Platform.</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">THIRD-PARTY SERVICES</h3>
        <ol>
          <li>
            <h4>Third-Parties.</h4> From time to time we may link to other websites or make third-party content or
            services available to you (collectively, "<span className="legal-term">Third-Party Materials</span>") on
            through the Platform as a convenience to you. The provision of Third-Party Materials does not imply an
            endorsement or an affiliation with their owners or operators. Propdocs has no control over any Third-Party
            Materials and your use of access of them may be subject to a third-party's terms and conditions and privacy
            policy. The content on third-party websites is the sole responsibility of the owner or operator of the
            linked website.
            <p>
              Additionally, certain services of the Platform might be provided by third-party providers, such as payment
              processing services or the service which allows for electronic execution of your Lease Agreements. We do
              not control such third-parties. We do not warrant, endorse, guarantee, provide any conditions, warranties,
              or representations, or assume any responsibility or liability for any information, content, product, or
              service advertised, offered, made available, or performed by any third-party unless we expressly say so
              and we will not be a party to any transaction that you may enter into with any such third-party.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">DISCLAIMERS</h3>
        <ol>
          <li>
            <h4>Disclaimer.</h4>{' '}
            <span className="important extra-important">
              YOUR USE OF THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ACQUIRED THROUGH THE PLATFORM IS
              AT YOUR OWN RISK. THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ACQUIRED THROUGH THE
              PLATFORM ARE MADE AVAILABLE ON AN "AS IS" AND AS AVAILABLE BASIS. NEITHER Propdocs NOR THE Propdocs
              ASSOCIATES MAKE ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT
              LIMITATION ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
              NONINFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE, COURSE OF
              DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING: (a)
              Propdocs AND THE Propdocs ASSOCIATES HAVE NO OBLIGATION TO INDEMNIFY OR DEFEND YOU AGAINST CLAIMS RELATED
              TO INFRINGEMENT OF INTELLECTUAL PROPERTY; (b) Propdocs AND THE Propdocs ASSOCIATES DO NOT REPRESENT OR
              WARRANT THAT THE PLATFORM WILL PERFORM WITHOUT INTERRUPTION OR ERROR; (c) Propdocs AND THE Propdocs
              ASSOCIATES DO NOT REPRESENT OR WARRANT THAT THE PLATFORM IS SECURE FROM HACKING OR OTHER UNAUTHORIZED
              INTRUSION OR THAT YOUR USER CONTENT OR LEASE AGREEMENTS WILL REMAIN PRIVATE OR SECURE; AND (d) Propdocs
              AND THE Propdocs ASSOCIATES DISCLAIM ANY REPRESENTATION OR WARRANTY CONCERNING PRODUCTS OR SERVICES
              PROVIDED BY OTHER USERS OF THE PLATFORM OR OTHER THIRD PARTIES (EVEN IF ADVERTISED OR MADE AVAILABLE ON
              THE PLATFORM).
            </span>
            <p className="important extra-important">
              WE DO NOT SCREEN OR EVALUATE LANDLORDS, TENANTS, OR DEALS WITH RESPECT TO SUITABILITY FOR YOU OR YOUR
              PURPOSES. YOU ACKNOWLEDGE THAT IT IS YOUR SOLE RESPONSIBILITY TO EVALUATE WHETHER ENTERING A LEASE
              AGREEMENT IS APPROPRIATE OR ADVISABLE IN YOUR CIRCUMSTANCES.
            </p>
          </li>
          <li>
            <h4>No Uptime Guarantee.</h4> Propdocs does not guarantee 24x7 uptime availability of the Platform. Without
            limiting the foregoing, you acknowledge that the Platform will be unavailable during: (a) downtime and
            scheduled upgrades; and (b) unavailability caused by circumstances beyond Propdocs's reasonable control.
            Without limiting anything else herein, Propdocs has no liability for any downtime or unavailability of the
            Platform.
          </li>
          <li>
            <h4>Interactions with Other Users.</h4> You agree that you are solely responsible for your transactions,
            agreements, or other interactions, either through the Platform or through other means of communication, with
            other Users of the Platform. You acknowledge that that Propdocs has no liability for any such interactions.
            Propdocs may monitor or become involved in disputes between you and other Users of the Platform but has no
            obligation to do so.
          </li>
          <li>
            <h4>Third Party Sites and Content.</h4> You understand that the Platform may contain or send you links to
            third party websites, applications or features not owned or controlled by Propdocs ("
            <span className="legal-term">Third Party Sites</span>"), and that links to Third Party Sites may also appear
            in content available to you through the Platform. The Platform may also enable interactions between the
            Platform and a Third Party Site through applications that connect the Platform, or your Account on the
            Platform, with a Third Party Site. Through Third Party Sites you may be able to access content, products, or
            services from third parties that Propdocs does not control and/or share your User Content with others.{' '}
            <span className="extra-important">
              YOU ACCESS THIRD PARTY SITES ENTIRELY AT YOUR OWN RISK, AND Propdocs WILL HAVE NO LIABILITY FOR YOUR USE
              OF OR ACCESS TO THIRD PARTY SITES AND/OR THIRD PARTY CONTENT, PRODUCTS, OR SERVICES.
            </span>
          </li>
          <li>
            <h4>LEASE FORMS.</h4>{' '}
            <span className="important extra-important">
              NEITHER Propdocs NOR THE Propdocs ASSOCIATES REPRESENT OR WARRANT THAT THE LEASE FORMS OR LEASE AGREEMENTS
              WILL MEET YOUR SPECIFIC GOALS, PROTECT YOUR SPECIFIC INTERESTS, OR WITHSTAND CHALLENGES TO THEIR LEGAL OR
              FACTUAL SUFFICIENCY. THE LEASE FORMS MADE AVAILABLE THROUGH THE PLATFORM ARE OF A GENERAL NATURE AND MAY
              NOT BE SUFFICIENT OR SUITABLE FOR YOUR SPECIFIC CIRCUMSTANCES. THE LEASE FORMS COME WITH NO GUARANTIES,
              WARRANTIES, OR REPRESENTATIONS AS TO QUALITY, COMPLETENESS, APPROPRIATENESS, OR SUITABILITY FOR ANY
              PARTICULAR PURPOSE. BY MAKING LEASE FORMS AVAILABLE TO YOU, WE ARE NOT PROVIDING LEGAL COUNSEL OR LEGAL
              ADVICE. OUR PROVISION OF LEASE FORMS DOES NOT CONSTITUTE THE PRACTICE OF LAW OR THE PROVISION OF LEGAL
              SERVICES. LEASE FORMS SHOULD NOT BE RELIED UPON IN SUBSTITUTION FOR QUALIFIED LEGAL ADVICE FROM A LAWYER
              LICENSED TO PRACTICE LAW IN YOUR JURISDICTION.
            </span>
          </li>
          <li>
            <h4>LEASE PAYMENT SERVICES DISCLAIMER.</h4> Without limiting any other section of Article 11, we
            specifically disclaim all representations and warranties of any kind, express or implied, including without
            limitation any implied warranties or conditions of merchantability or fitness for a particular purpose, with
            respect to the Lease Payment Services and Lease Payment Service Provider. You confirm you are aware that the
            Lease Payment Services are exclusively provided by the Lease Payment Service Provider and not by Propdocs.
            You release Propdocs from all liability related to the Lease Payment Services. Your use of the Lease Payment
            Services at your own risk.
          </li>
          <li>
            <h4>Accuracy of Content.</h4> Although we work to provide you with accurate content, mistakes sometimes
            happen and we do not warrant that any content is or remains available, accurate, complete and up to date,
            free from bugs, errors or omissions or fit or suitable for any purpose.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">INDEMNIFICATION</h3>
        <ol>
          <li>
            To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless
            Propdocs and the Propdocs Associates from and against any and all third party claims, suit, proceeding,
            liability, or cost arising out of or related to: (a) your access or use of the Platform or any Third-Party
            Materials; (b) violation of these Terms by you or any person through your Account; (c) infringement or
            violation of third party intellectual property, privacy or publicity rights by User Content submitted to or
            transmitted through the Platform from your Account, including without limitation by your User Content; (d)
            claims that use of the Platform through your Account harasses, defames, or defrauds a third party, infringes
            or misappropriates copyright, trade secret, or other intellectual property rights, or violates the CAN-Spam
            Act of 2003, CASL, or any other law or restriction on electronic advertising or spam emails; (e) any of your
            User Content Processed through the Platform; (f) any products or services purchased or received by you
            through the Platform, including those provided by third-parties; (g) access or use of the Lease Payment
            Services by you or any other person using your Account; (h) your breach of the Lease Payment Terms; (i) any
            payments made, or attempted to be made, through the Lease Payment Services; or (j) your breach of a Lease
            Agreement.
            <p>
              Your obligations set forth in this Article 12.1 include retention and payment of legal counsel and payment
              of court costs, as well as settlement at your expense and payment of judgments. Propdocs will have the
              right, not to be exercised unreasonably, to reject any settlement or compromise that requires that it
              admit wrongdoing or liability or subjects it to any ongoing affirmative obligations.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3>Limitation of Liability</h3>
        <ol>
          <li className="important">
            <h4>Dollar Cap.</h4>{' '}
            <span className="extra-important">
              WITHOUT LIMITING SECTION 13.2, Propdocs AND THE Propdocs ASSOCIATES CUMULATIVE LIABILITY FOR CLAIMS
              ARISING OUT OF OR RELATED TO THESE TERMS OR THE PLATFORM (INCLUDING WITHOUT LIMITATION YOUR USE OF THE
              LEASE FORMS OR PAD AGREEMENT) WILL NOT EXCEED THE AMOUNT YOU PAID US IN THE YEAR IMMEDIATELY PRECEDING THE
              EVENT GIVING RISE TO THE CLAIM (OR IF YOU USED THE PLATFORM ON A FREE BASIS, THEN OUR AND THE Propdocs
              ASSOCIATES' LIABILITY WILL NOT EXCEED $1.00 CAD).
            </span>
          </li>
          <li className="important">
            <h4>Limitation of Liability.</h4>{' '}
            <span className="extra-important">
              IN NO EVENT WILL PROPdOCS OR THE Propdocs ASSOCIATES BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT,
              INCIDENTAL, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING ANY DAMAGES FOR BUSINESS
              INTERRUPTION, LOSS OF USE, LOSS OF DATA, LOSS OF REVENUE OR PROFIT, COST OF CAPITAL, LOSS OF BUSINESS
              OPPORTUNITY, LOSS OF GOODWILL, PERSONAL INJURY, DEATH, PAIN AND SUFFERING, OR EMOTIONAL DISTRESS, WHETHER
              ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF PRIVACY, OR ANY OTHER THEORY OF
              LIABILITY OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT WE WERE
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </span>
          </li>
        </ol>
      </li>
      <li>
        <h3>Governing Law and Disputes</h3>
        <ol>
          <li>
            <h4>Governing Law.</h4> The courts in some countries, states, or provinces will not apply the law of British
            Columbia to some types of disputes. If you reside in one of those countries, states, or provinces, then
            where British Columbia law is excluded from applying, your country, state, or province's laws will apply to
            such disputes related to these Terms and the Platform. Otherwise, these Terms and and the Platform are
            governed and interpreted pursuant to the laws of the Province of British Columbia, Canada and the federal
            laws of Canada applicable therein, without regard to principles of conflicts of law that would impose the
            law of another jurisdiction. You agree that the United Nations Convention on Contracts for the International
            Sale of Goods does not apply to these Terms.
          </li>
          <li className="important">
            <h4>Arbitration.</h4> If the courts in your province, state, or country will not permit you to consent to
            binding arbitration, then any legal suit, action, or proceeding arising out of or related to these Terms or
            the Platform will be instituted exclusively in the courts of the Province of British Columbia, Canada, and
            you irrevocably submit to the exclusive jurisdiction of such courts in any such suit, action, or proceeding.
            Otherwise, you agree that in the event of any dispute between you and Propdocs arising out of or relating to
            these Terms or the Platform that you and Propdocs shall consult and negotiate with each other and,
            recognizing your mutual interests, attempt to reach a solution satisfactory to both you and Propdocs. If you
            and Propdocs do not reach settlement within a period of 60 days, then such dispute shall be referred to and
            finally resolved by mandatory and binding arbitration under the International Commercial Arbitration Rules
            of Procedure of the British Columbia International Commercial Arbitration Centre. The appointing authority
            shall be the British Columbia International Commercial Arbitration Centre. The case shall be administered by
            the British Columbia International Commercial Arbitration Centre in accordance with its Rules. The place of
            arbitration shall be Vancouver, British Columbia, Canada. The number of arbitrators shall be set to one
            unless otherwise required by the Rules. The language of the arbitration will be English. The existence and
            content of the arbitration proceedings, including documents submitted by the parties, correspondence to and
            from the British Columbia International Commercial Arbitration Centre, correspondence to and from the
            arbitrator, and orders and awards issued by the sole arbitrator, shall remain strictly confidential and
            shall not be disclosed to any third party (except for professional advisors) without the express written
            consent from the other party unless: (a) (i) the disclosure to the third party is reasonably required in the
            context of conducting the arbitration proceedings; and (ii) the third party agrees unconditionally in
            writing to be bound by the confidentiality obligation stipulated herein; or (b) such disclosure is required
            by applicable law or court order. Notwithstanding the foregoing, Propdocs may seek injunctive or other
            equitable relief to protect its rights, and for any other purpose, in any court of competent jurisdiction.
            <p>
              You may opt out of the above arbitration clause. If you opt out, then neither you nor we will be able to
              require the other to participate in arbitration for the purposes of resolving any dispute, claim or
              controversy between you and Propdocs arising out of or relating to these Terms or the Platform. To opt
              out, you must, within 30 days of accepting these Terms, deliver to Propdocs a clear written statement
              indicating that you wish to opt out of the arbitration provisions in these Terms. The statement must
              contain: (a) your name; (b) your mailing address; (c) your telephone number; (d) your email address; and
              (e) your Account name (if any). The opt-out statement must be delivered to the following address:
            </p>
            <div className="mailing-address">
              <div>Propdocs Corp.</div>
              <div>201 - 388 West 8th Avenue</div>
              <div>Vancouver, British Columbia, Canada</div>
              <div>V5Y 3X2</div>
            </div>
            <p>
              If you opt out of the arbitration clause in the manner provided above, then you irrevocably agree that the
              courts of British Columbia will have exclusive jurisdiction to settle any dispute, claim, or controversy
              arising out of or related to these Terms or the Platform.
            </p>
          </li>
          <li className="important">
            <h4>Waiver of Class Action and Jury Trial.</h4> Where legally permissible, you hereby agree to waive any
            right you may have to commence or participate in any class action against Propdocs or the Propdocs
            Associates related to any claim and, where applicable, you also agree to opt out of any class proceedings
            against Propdocs or the Propdocs Associates. Where legally permissible, if a dispute arises between us and
            you, you hereby waive any right you may have to participate in a trial by jury with respect to that dispute.
          </li>
        </ol>
      </li>
      <li>
        <h3 className="extra-important">GENERAL</h3>
        <ol>
          <li>
            <h4>Consumer Rights.</h4> Nothing in these Terms affects consumer rights that, pursuant to applicable law,
            cannot be limited or waived.
          </li>
          <li>
            <h4>Entire Agreement.</h4> Subject to section 15.3, these Terms, together with our{' '}
            <a href="https://propdocs.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            , constitutes the sole and entire agreement between you and Propdocs regarding the Platform and supersedes
            all prior and contemporaneous understandings, agreements, representations and warranties, both written and
            oral, regarding such subject matter.
          </li>
          <li>
            <h4>Supplemental Contracts.</h4> Your use of and access to the Platform is subject to any other written and
            duly executed contracts you might have with Propdocs that expressly state they take precedence over these
            Terms.
          </li>
          <li>
            <h4>Waiver.</h4> No waiver of any of these Terms is effective unless it is in writing and signed by an
            authorized representative of the party waiving its right. No failure to exercise, or delay in exercising,
            any right, remedy, power or privilege arising from these Terms operates, or may be construed, as a waiver
            thereof. No single or partial exercise of any right, remedy, power or privilege hereunder precludes any
            other or further exercise thereof or the exercise of any other right, remedy, power or privilege.
          </li>
          <li>
            <h4>Amendments.</h4> Propdocs may amend these Terms from time to time by posting an amended version on the
            Website and sending you written notice thereof. Such amendment will become effective 14 days after such
            notice, unless you have permanently ceased use of the Platform before the expiration of that 14-day notice
            period. Your continued use of the Platform after that 14-day notice period will confirm your consent to such
            amendment. These Terms may not be amended in any other way except through a written agreement by an
            authorized representatives of each party.
          </li>
          <li>
            <h4>Assignment.</h4> We may assign these Terms in part or in their entirety, including our rights,
            interests, and obligations hereunder, without notice to you or your consent. These Terms are personal to you
            and you may not assign these Terms nor your rights, interests, or obligations under these Terms to any
            person without our express written consent.
          </li>
          <li>
            <h4>Severability.</h4> If any term or provision of these Terms is invalid, illegal or unenforceable in any
            jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision
            of these Terms or invalidate or render unenforceable such term or provision in any other jurisdiction.
          </li>
          <li>
            <h4>Headings.</h4> Headings are for convenience only and shall not affect the interpretation of these Terms.
          </li>
          <li>
            <h4>Language.</h4> The parties acknowledge that they have required that these Terms, and all related
            documents (including the{' '}
            <a href="https://propdocs.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            ) be prepared in English.{' '}
            <span className="emphasis">
              Les parties reconnaissent avoir exigé que les présentes modalités de vente et tous les documents connexes
              soient rédigés en anglais.
            </span>{' '}
            If these Terms are translated into another language, the English language text shall in any event prevail.
            Where the word or phrase "such as", "including" or "includes" is used in these Terms, it means "including
            (or includes) without limitation."
          </li>
          <li>
            <h4>Enurement.</h4> These Terms will enure to the benefit of and be binding upon the parties to these Terms
            and their respective successors, heirs and permitted assigns.
          </li>
          <li>
            <h4>Force Majeure.</h4> Propdocs will not be liable or responsible to you, nor be deemed to have defaulted
            or breached the Terms, for any failure or delay in fulfilling or performing any term of Terms when and to
            the extent such failure or delay is caused by or results from acts or circumstances beyond the reasonable
            control of Propdocs including, without limitation, acts of God, flood, fire, storm, hurricane, tsunami,
            earthquake, explosion, governmental actions, war, invasion or hostilities (whether war is declared or not),
            terrorist threats or acts, riot, or other civil unrest, national emergency, revolution, insurrection,
            epidemic, pandemic, lock-outs, strikes or other labour disputes (whether or not relating to either party's
            workforce), or restraints or delays affecting carriers or inability or delay in obtaining supplies of
            adequate or suitable materials, materials or telecommunication breakdown or power outage.
          </li>
          <li>
            <h4>Further Assurances.</h4> You agree to provide such further documents or instruments, and take such
            further actions, reasonably requested by Propdocs, to effect the purposes of these Terms and carry out its
            provisions.
          </li>
          <li>
            <h4>Third-Party Beneficiaries.</h4> Except as set forth in the following sentence, the parties do not confer
            any legal, equitable or other rights or remedies of any nature whatsoever under or by reason of this Terms
            upon any person other than the parties to these Terms and their respective successors and permitted assigns.
            The parties hereby designate the Propdocs Associates as third-party beneficiaries of Articles and Sections
            11.1, 11.5, 12, 13 and 14.
          </li>
          <li>
            <h4>Notices.</h4> Propdocs may send notices (including legal notices) your email address or other address on
            file with Propdocs, and such notices will be deemed received 24 hours after they are sent. You may send
            routine communications to <a href="mailto:support@propdocs.com">support@propdocs.com</a> and you shall send
            legal notices only to:
            <div className="mailing-address">
              <div>Propdocs Corp.</div>
              <div>201 - 388 West 8th Avenue</div>
              <div>Vancouver, British Columbia, Canada</div>
              <div>V5Y 3X2</div>
            </div>
            <p>
              and such legal notices will be deemed received upon receipt by Propdocs. All legal notices sent by you
              must be delivered by personal delivery, nationally recognized overnight courier or certified or registered
              mail (in each case, return receipt requested, postage prepaid).
            </p>
          </li>
          <li>
            <h4>Definitions.</h4> The following words have the following meanings:
            <ol>
              <li>
                "<span className="legal-term">CASL</span>" means an{' '}
                <span className="emphasis">
                  Act to promote the efficiency and adaptability of the Canadian economy by regulating certain
                  activities that discourage reliance on electronic means of carrying out commercial activities, and to
                  amend the Canadian Radio-television and Telecommunications Act, the Competition Act, the Personal
                  Information Protection and Electronic Documents Act and the Telecommunications Act (Canada).
                </span>
              </li>
              <li>
                "<span className="legal-term">Landlords</span>" means Users who are lessors, or prospective lessors, of
                Deals.
              </li>
              <li>
                "<span className="legal-term">Laws</span>" means any statute, law, ordinance, regulation, rule, code,
                order, constitution, treaty, common law, judgment, decree, other requirement or rule of law of any
                federal, provincial, state, municipal, or foreign government or political subdivision thereof, or any
                arbitrator, court or tribunal of competent jurisdiction;
              </li>
              <li>
                "<span className="legal-term">Lease Payments</span>" means rent and all other amounts a Tenant has
                agreed to pay to a Landlord under a Lease Agreement.
              </li>
              <li>
                "<span className="legal-term">Legal Advice</span>" means legal advice, including, without limitation:
                <ol>
                  <li>professional advice provided by a licensed lawyer, attorney, or notary;</li>
                  <li>
                    any legal services or communications which, under applicable law, may only be performed by or under
                    the supervision of a licensed legal practitioner;
                  </li>
                  <li>
                    the provision of an opinion regarding the substance or procedure of a law in relation to a
                    particular factual situation;
                  </li>
                  <li>
                    advising a person to take a specific course of action based upon an analysis of specific set of
                    facts and applicable law;
                  </li>
                  <li>
                    advising a person as to the appropriateness or suitability of a legal document, legal instrument, or
                    legal clause, in their circumstances;
                  </li>
                  <li>
                    advising a person as to whether additional legal documents, legal instruments, legal clauses, or
                    professional advice are required or advisable in their circumstances;
                  </li>
                  <li>
                    advising a person as to whether a legal document, legal instrument, or legal clause contains
                    incompatible, ambiguous, incoherent, unenforceable, vague, ill-suited, inappropriate, or unlawful
                    provisions;
                  </li>
                  <li>
                    advising a person as to whether any necessary or advisable clauses, details, or issues have been
                    omitted from, or improperly addressed in, any legal document, legal instrument, or legal clause;
                  </li>
                  <li>
                    advising a person as to the legal consequences that could or will flow from use of a legal document,
                    legal instrument, or legal clause;
                  </li>
                  <li>
                    advising a person as to their rights, interests, or obligations under any legal document, legal
                    instrument, or legal clause, or with respect to any legal or factual matter;
                  </li>
                  <li>
                    doing an act or negotiating in any way for the settlement of, or settling, of a claim or demand for
                    damages; and
                  </li>
                  <li>negotiating in any way the terms of a legal document, legal instrument, or legal clause.</li>
                </ol>
              </li>
              <li>
                "<span className="legal-term">Personal Information</span>" means information about an identifiable
                individual;
              </li>
              <li>
                "<span className="legal-term">Privacy Laws</span>" means all present and future Laws applicable to the
                Platform relating to the collection, use, and disclosure of personally identifiable information,
                including, without limitation, the Personal Information Protection and Electronic Documents Act
                (Canada).
              </li>
              <li>
                "<span className="legal-term">Process</span>" means to take any action or perform any operation or set
                of operations that the Platform is capable of taking or performing on any data, information or other
                content, including to collect, receive, input, upload, download, record, reproduce, store, organize,
                compile, combine, log, catalog, cross­reference, manage, maintain, copy, adapt, alter, translate or make
                other derivative works or improvements, process, retrieve, output, consult, use, perform, display,
                disseminate, transmit, submit, post, transfer, disclose or otherwise provide or make available, or
                block, erase or destroy. "<span className="legal-term">Processing</span>" and "
                <span className="legal-term">Processed</span>" have correlative meanings.
              </li>
              <li>
                "<span className="legal-term">Resultant Data</span>" means information, data and other content that is
                derived by or through the Platform from Processing User Data or Lease Agreements and is sufficiently
                different from such User Data or Lease Agreements, respectively, that the source material cannot be
                reverse engineered or otherwise identified from the inspection, analysis or further Processing of such
                information, data or content.
              </li>
              <li>
                "<span className="legal-term">Propdocs Associates</span>" means Propdocs's directors, officers,
                employees, agents, service providers, shareholders, subsidiaries, parent companies, successors, and
                assigns.
              </li>
              <li>
                "<span className="legal-term">Propdocs Materials</span>" means materials, content, and information, in
                any form or medium, provided by Propdocs on the Platform, including, without limitation, the Lease
                Forms.
              </li>
              <li>
                "<span className="legal-term">Tenants</span>" means a User who: (a) has been invited to the Platform by
                a Landlord; and (b) is a tenant, or prospective tenant, of a Deal leased or intended to be leased by a
                Landlord.
              </li>
              <li>
                "<span className="legal-term">User Data</span>" means, other than Resultant Data, information, data and
                other content, in any form or medium, that is collected, downloaded or otherwise received, directly or
                indirectly from a User by or through the Platform. For the avoidance of doubt, your User Data includes
                your User Content.
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
  </div>
);
