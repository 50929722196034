import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import classNames from 'classnames';
import { formatTextForTemplate } from './helpers';
import { ReactComponent as DragIcon } from '../../../../../../../shared/icons/drag.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../../../../shared/icons/chevron-down.svg';
import styles from './styles.module.scss';

interface ClauseTemplateItemProps {
  id: string;
  body: string;
  title: string;
  index: number;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ClauseTemplateItem = ({ id, index, body, title }: ClauseTemplateItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showTemplateText, setShowTemplateText] = useState(false);
  const formattedTemplateText = formatTextForTemplate(body);

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'signer',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'signer',
    item: () => {
      return { id, body, index };
    },
    collect: (monitor: { isDragging: (...args: any[]) => boolean }) => {
      return { isDragging: monitor.isDragging() };
    },
  });

  drag(drop(ref));

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles['wrapper-dragging']]: isDragging,
      })}
      ref={preview}
      draggable
    >
      <div className={classNames(styles['content'])}>
        <span ref={ref} data-body={body} data-id={id} className={styles.firstRow}>
          <span className={styles['drag-handle']}>
            <DragIcon className={styles['icon-drag']} />
          </span>
          <span className={styles['template-title']}>{title}</span>
          <span onClick={() => setShowTemplateText(!showTemplateText)} className={styles['expand-handle']}>
            <ChevronDownIcon
              className={classNames(styles['icon-expand-arrow'], {
                [styles['icon-expand-arrow-up']]: showTemplateText,
              })}
            />
          </span>
        </span>
        <textarea
          className={classNames(styles.secondRow, {
            [styles['show-template']]: showTemplateText,
          })}
          disabled
          value={formattedTemplateText}
        />
      </div>
    </div>
  );
};

export default ClauseTemplateItem;
