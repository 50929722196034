import { SetIsDocumentTitleEditableAction } from '../actions/lease-document.actions';
import { LeaseDocumentStateType } from '../lease-document.reducer';

export const handleSetIsDocumentTitleEditable = (
  state: LeaseDocumentStateType,
  action: SetIsDocumentTitleEditableAction
): LeaseDocumentStateType => ({
  ...state,
  isTitleEditable: action.isTitleEditable,
});
