import { useTeamInfo } from '../../../team-id-context';
import { useFormikContext } from 'formik';
import { graphqlErrorsToUiErrors, queries, queriesV2 } from '../../../api';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../loading-spinner';
import ErrorMessage from '../../error-message';
import { Dropdown } from '../../dropdown';
import { DealsDropdown } from './index';
import { CheckboxField } from '../../checkbox-or-radio-field';
import React, { ChangeEvent, useEffect } from 'react';
import Toggle from './Toggle';
import { Permissions } from '../../../shared/constants/permissions';

const SelectDealsFormFragment = () => {
  const { teamId } = useTeamInfo();
  const { values, setValues } = useFormikContext<any>();
  const { loading: isPermissionsLoading, data: permissionsData } = useQuery(queriesV2.getPermissions);
  const { loading: dealsLoading, data: dealsData, error: dealsGraphqlError } = useQuery(queries.getDealsForTeam, {
    variables: {
      teamId,
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      defaultDeals: [],
    });
  }, [setValues]);

  const loading = isPermissionsLoading || dealsLoading;

  if (loading) {
    return <LoadingSpinner />;
  }

  const whitelistPermissionId = permissionsData?.permissions.find(
    ({ key }: { key: string }) => key === Permissions.Whitelist
  )?.id;

  const isWhitelisted = values.defaultPermissions.includes(whitelistPermissionId);

  if (!dealsData) {
    // @ts-ignore
    const errors: { _general?: string } = graphqlErrorsToUiErrors(dealsGraphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const dealsForTeam = dealsData.dealsForTeam;

  return (
    <Dropdown>
      <Toggle
        id={`dropdown-select-deals-t${teamId}-new`}
        totalCount={dealsForTeam.length}
        selectedCount={values.defaultDeals?.length || '0'}
        isWhitelisted={values.defaultDeals?.length === dealsForTeam?.length}
      />

      <Dropdown.Menu
        as={DealsDropdown}
        dropdownId={`dropdown-select-deals-t${teamId}-new`}
        whitelistValue={whitelistPermissionId}
        whitelistName="defaultPermissions"
        whitelistChecked={values.defaultDeals?.length === dealsForTeam?.length}
        onWhitelistChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (event.currentTarget.checked) {
            setValues({
              ...values,
              defaultPermissions: [...values.defaultPermissions, whitelistPermissionId],
              defaultDeals: dealsForTeam.map(({ id }: { id: string }) => id),
            });
          } else {
            setValues({
              ...values,
              defaultPermissions: values.defaultPermissions.filter((p: any) => p !== whitelistPermissionId),
              defaultDeals: [],
            });
          }
        }}
        dealName="defaultDeals"
        dealValue={(deal: { id: string }) => deal.id}
        dealChecked={(deal: { id: string }) => values.defaultDeals.includes(deal.id)}
        onDealChange={(e: ChangeEvent<HTMLInputElement>, deal: { id: string }) => {
          if (e.currentTarget.checked) {
            setValues({
              ...values,
              defaultDeals: [...values.defaultDeals, deal.id],
            });
          } else {
            setValues({
              ...values,
              defaultDeals: values.defaultDeals.filter((d: string) => d !== deal.id),
            });
          }
        }}
        checkboxComponent={CheckboxField}
      >
        {dealsForTeam}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectDealsFormFragment;
