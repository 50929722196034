import { ApolloClient } from '@apollo/client';
import { TeamWithParty, TeamPermission, TeamPermissionName } from '../../../store/permissions/permissions.reducer';
import { graphql } from '../../../gql';

type TeamPermissionsByTeamResponse = {
  teamId: string;
  key: string;
  allowed: boolean;
};
export type GetTeamPermissionsQueryResponse = {
  currentUser: {
    ownedTeams: Array<{ id: string }>;
    teamPermissionsByTeam: TeamPermissionsByTeamResponse[];
    allTeams: TeamWithParty[];
  };
};

/**
 * Some properties are a bit abstract when used outside of
 * the context of permissions, e.g. `key`,
 * so this method renames them for readability
 * @param teamPermissionByTeam
 */
const renameTeamPermissionDealNames = (teamPermissionByTeam: TeamPermissionsByTeamResponse): TeamPermission => {
  const { teamId, allowed, key: permissionName } = teamPermissionByTeam;
  return {
    teamId,
    allowed,
    permissionName: permissionName as TeamPermissionName,
  };
};

export type GetTeamPermissions = {
  ownedTeams: string[];
  allTeams: TeamWithParty[];
  teamPermissions: TeamPermission[];
};

export const getTeamPermissionsQuery = () =>
  graphql(/* GraphQL */ `
    query CurrentUserTeamPermissions($teamId: ID!) {
      currentUser {
        id
        allTeams {
          teamId: id
          party {
            name
          }
        }
        ownedTeams {
          id
        }
        teamPermissionsByTeam(teamId: $teamId) {
          teamId
          teamName
          id
          key
          allowed
        }
      }
    }
  `);

/**
 * Get permissions for a specific team, as well as the user's owned teams,
 * i.e. teams for which they are admins
 * With a combination of team-specific permissions and owned teams,
 * we can implement access controls
 * @param teamId
 */
export const getTeamPermissions = (teamId: string) => (client: ApolloClient<object>): Promise<GetTeamPermissions> => {
  const query = getTeamPermissionsQuery();
  const variables = { teamId };

  return client
    .query({
      query,
      variables,
    })
    .then(({ data }) => {
      const { ownedTeams, teamPermissionsByTeam, allTeams } = data.currentUser!;

      return {
        ownedTeams: ownedTeams.map(({ id }) => id),
        teamPermissions: teamPermissionsByTeam.map(renameTeamPermissionDealNames),
        allTeams,
      };
    });
};
