import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import { LeaseDocumentWizardModal } from '../../../../lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../../../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import { unlockDocumentAfterApproval } from '../../../../../../api/mutations';
import { getDocumentById } from '../../../../../../api/queries_v2';
import { useNotifications } from '../../../../../../notifications';
import styles from './styles.module.scss';
import {
  ModalActionDescription,
  ModalActionTitle,
  UnlockDocumentMessage,
} from '../../../../../document-table/DocumentTableConstants';

interface Props {
  onHide: () => void;
  show: boolean;
  documentId: string;
  teamId: string;
}

const CancelApprovalRequestModal = ({ onHide, show, documentId, teamId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [unlockDocumentAfterApprovalMutation] = useMutation(unlockDocumentAfterApproval, {
    refetchQueries: [
      {
        query: getDocumentById,
        variables: {
          documentId,
        },
      },
    ],
  });

  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const handleCancelApprovalRequest = useCallback(async () => {
    setIsLoading(true);

    try {
      await unlockDocumentAfterApprovalMutation({
        variables: {
          documentId,
          teamId,
        },
      });

      notifySuccess({
        message: { id: 'document.unlock-action.success', defaultMessage: UnlockDocumentMessage.SUCCESS },
      });

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      notifyError({ message: error });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [unlockDocumentAfterApprovalMutation, notifyError, notifySuccess, documentId, teamId]);

  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onHide,
      label: 'Cancel',
      theme: 'outline-secondary',
    },
    {
      onClick: handleCancelApprovalRequest,
      label: 'Unlock Document',
      theme: 'secondary',
      isLoading,
      disabled: isLoading,
      className: styles['action-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={styles.title}
      size="lg"
      show={show}
      onHide={onHide}
      title={
        <FormattedMessage
          id="lease-document-wizard-modal.handle_internal_approval"
          defaultMessage={ModalActionTitle.UNLOCK_DOCUMENT}
        />
      }
      modalCTAs={modalCTAs}
    >
      <div className={styles.main}>{ModalActionDescription.UNLOCK_DOCUMENT}</div>
    </LeaseDocumentWizardModal>
  );
};

export default CancelApprovalRequestModal;
