const CKE_TABLE_CLASSNAME = 'ck-editor__nested-editable';
const CKE_PAGE_BREAK_LABEL = 'page-break__label';
const CKE_IMAGE_CLASSNAME = 'image ck-widget';
const CKE_INLINE_IMAGE_CLASSNAME = 'image-inline ck-widget';
const TABLE = 'TABLE';
const PAGE_BREAK = 'Page break';
const IMAGE = 'IMAGE';

export const filteredSuggestionsData = (suggestions: Element[]) => {
  if (!suggestions.length) {
    const annotationThreads = window.editor.plugins.get('Annotations');
    const activeAnnotationDescription = annotationThreads.activeAnnotations
      .values()
      .next()
      .value?.innerView?.descriptionParts[0].content?.split('*')
      .join('');
    return activeAnnotationDescription;
  }

  const suggestionsInnerHTMLData = suggestions.map((suggestionsHTML: any) => {
    if (
      suggestionsHTML.className?.includes(CKE_IMAGE_CLASSNAME) ||
      suggestionsHTML.className?.includes(CKE_INLINE_IMAGE_CLASSNAME)
    ) {
      return IMAGE;
    }
    return suggestionsHTML.innerHTML.replace(/<[^>]+>|&(nbsp|amp|quot|lt|gt);/g, ' ');
  });

  const suggestionsWithReplacedValuesForGraphicObjects = suggestionsInnerHTMLData.map((value, index, arr) => {
    if (value.includes(CKE_TABLE_CLASSNAME)) {
      return TABLE;
    }
    if (value.includes(CKE_PAGE_BREAK_LABEL)) {
      return PAGE_BREAK;
    }
    return value;
  });

  const suggestionInfo = `${
    suggestions[0]?.className.includes('format')
      ? 'Format'
      : suggestions[0]?.className.includes('insert')
      ? 'Insert'
      : 'Remove'
  }: ${suggestionsWithReplacedValuesForGraphicObjects
    .splice(0, Math.ceil(suggestionsWithReplacedValuesForGraphicObjects.length / 2))
    .join(' ')}`;

  return suggestionInfo;
};
