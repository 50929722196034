import { MutationFunctionOptions } from '@apollo/client';
import { WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationType } from './constants';

type Props = {
  instance: WebViewerInstance;
  documentId?: string;
  isTemplate?: boolean;
  saveFormFields?: (options: MutationFunctionOptions) => Promise<void>;
};

export const renderCloseFormBuilderButton = ({ instance, documentId, saveFormFields }: Props) => {
  const {
    Core: { documentViewer, Annotations },
  } = instance;
  const annotationManager = documentViewer.getAnnotationManager();
  const formFieldCreationManager = annotationManager.getFormFieldCreationManager();
  const openFormBuilderButton = instance.UI.iframeWindow.document.querySelector<HTMLElement>('.open-form-builder');

  const closeFormBuilderButton = document.createElement('button');
  closeFormBuilderButton.textContent = 'Close Form Builder';
  closeFormBuilderButton.setAttribute('class', 'close-form-builder');
  closeFormBuilderButton.setAttribute('data-element', 'closeFormBuilderButton');

  const headerToolsContainer = instance.UI.iframeWindow.document.querySelector<HTMLElement>('.HeaderToolsContainer');
  const headerItems = instance.UI.iframeWindow.document.querySelector<HTMLElement>('.HeaderItems');
  const toolGroupButtonsContainer = instance.UI.iframeWindow.document.querySelector<HTMLElement>(
    '.tool-group-buttons-container'
  );

  const textFieldToolButton = headerItems?.querySelector<HTMLElement>('button[data-element="textFieldToolButton"]');
  const checkBoxFieldCreateToolButton = headerItems?.querySelector<HTMLElement>(
    'button[data-element="checkBoxFieldCreateToolButton"]'
  );
  const closeFormBuilderButtonContainer = instance.UI.iframeWindow.document.querySelector<HTMLElement>(
    '[data-element="closeFormBuilderButton"]'
  );

  closeFormBuilderButton.onclick = async () => {
    headerToolsContainer!.style.display = 'none';
    toolGroupButtonsContainer!.classList.remove('disabled');
    headerItems!.classList.remove('notesHidden');
    openFormBuilderButton!.style.display = 'block';

    checkBoxFieldCreateToolButton!.style.display = 'none';
    textFieldToolButton!.style.display = 'none';

    const closeFormBuilderButton = closeFormBuilderButtonContainer?.querySelector('button');
    if (closeFormBuilderButton) closeFormBuilderButton.style.display = 'none';

    const textAnnotList = annotationManager
      .getAnnotationsList()
      .filter(annot => annot instanceof instance.Core.Annotations.TextWidgetAnnotation);

    textAnnotList.forEach(annot => {
      annot.Color = new Annotations.Color(162, 162, 162);
    });

    await formFieldCreationManager.endFormFieldCreationMode();

    if (documentId) {
      const strikeoutAnnotList = annotationManager
        .getAnnotationsList()
        .filter(annot => annot.Subject === AnnotationType.STRIKEOUT);

      const freeTextAnnotList = annotationManager
        .getAnnotationsList()
        .filter(annot => annot.Subject === AnnotationType.FREE_TEXT);

      const fieldsString = await annotationManager.exportAnnotations({
        links: false,
        widgets: true,
        annotList: [...strikeoutAnnotList, ...freeTextAnnotList],
        fields: true,
      });

      await saveFormFields?.({
        variables: { documentId, documentTemplateId: documentId, xfdfString: fieldsString },
      });
    }
  };

  return closeFormBuilderButton;
};

export const renderOpenFormBuilderButton = ({ instance }: Props) => {
  const openFormBuilderButton = document.createElement('button');
  openFormBuilderButton.textContent = 'Open Form Builder';
  openFormBuilderButton.setAttribute('class', 'open-form-builder');

  const headerItems = instance.UI.iframeWindow.document.querySelector<HTMLElement>('.HeaderItems');

  const toolGroupButtonsContainer = instance.UI.iframeWindow.document.querySelector<HTMLElement>(
    '.tool-group-buttons-container'
  );

  const textFieldToolButton = headerItems?.querySelector<HTMLElement>('button[data-element="textFieldToolButton"]');
  const checkBoxFieldCreateToolButton = headerItems?.querySelector<HTMLElement>(
    'button[data-element="checkBoxFieldCreateToolButton"]'
  );

  const closeFormBuilderButtonContainer = instance.UI.iframeWindow.document.querySelector<HTMLElement>(
    '[data-element="closeFormBuilderButton"]'
  );

  openFormBuilderButton.onclick = () => {
    instance.UI.setToolbarGroup('toolbarGroup-Forms');
    toolGroupButtonsContainer!.classList.add('disabled');
    headerItems!.classList.add('notesHidden');

    openFormBuilderButton.style.display = 'none';
    checkBoxFieldCreateToolButton!.style.display = 'flex';
    textFieldToolButton!.style.display = 'flex';

    const closeFormBuilderButton = closeFormBuilderButtonContainer?.querySelector('button');
    if (closeFormBuilderButton) closeFormBuilderButton.style.display = 'block';

    instance.UI.closeElements(['notesPanel']);
  };

  return openFormBuilderButton;
};
