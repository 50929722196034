import React from 'react';
import cn from 'classnames';
import './full-page-iframe.scss';

type FullPageElementProps = {
  id: string;
  show: boolean;
};

/**
 * Full page element. Useful to load up iframes, etc.
 * Its innerHTML should be overwritten.
 */
export const FullPageElement = ({ id, show }: FullPageElementProps) => (
  <div
    id={id}
    className={cn('full-page-element', {
      'full-page-element--hidden': !show,
    })}
  />
);
