import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';

export type ResolveComments = {
  documentId: string;
  threadId: string;
};

const mutation = graphql(/* GraphQL */`
  mutation ResolveDocumentComments(
    $documentId: ID!,
    $threadId: String!,
  ) {
    resolveDocumentComments(documentId: $documentId, threadId: $threadId) {
      attributes
      threadId
      resolvedAt
      resolvedBy
      comments {
        ...DocumentCommentFields
      }
    }
  }
`);

export const resolveDocumentComments = (options: ResolveComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const resolveDocumentCommentsWithResult = (options: ResolveComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.resolveDocumentComments);
  });
};
