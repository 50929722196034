import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useApolloClient, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { A, paths } from '../../routing';
import InputTitle from './components/statusDropdown/InputTitle';
import { graphqlErrorsToUiErrors, queries, queriesV2 } from '../../api';
import { useTeamInfo } from '../../team-id-context';
import { useDealId } from '../../deal-id-context';
import { selectActiveModal } from '../../store/modals/modals.selectors';
import { closeDocumentModals, setActiveModal } from '../../store/modals/modals.actions';
import PrivateLayout from '../../components/private-layout';
import LoadingSpinner from '../../components/loading-spinner';
import { CardContainer } from '../../components/card';
import DocumentTable from '../../components/document-table/DocumentTable';
import ActivitiesCard from '../../components/card/ActivitiesCard';
import CheckDealExists from './checkDealExists';
import Header from './header';
import Controls from './controls';
import LeaseTemplateModal from './lease-template-modal/LeaseTemplateModalContainer';
import CreateBlankDocumentModal from './lease-template-modal/CreateBlankDocumentModalContainer';
import { setDeal } from '../../store/deals/deals.actions';
import { useLeaseDocumentTeamPermissions } from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import styles from './styles.module.scss';
import { FileUploadModal } from '../../components/shared/modals';
import { CreateDocumentModalsEnum, ModalActionsEnum } from '../../components/document-table/DocumentTableConstants';
import { useDealRoles } from '../../shared/hooks/useDealRoles/useDealRoles';
import ManageParticipantsLayoutContainer from '../../components/manage-participants/ManageParticipantsLayout';
import { toggleManageDealParticipantsCollapsed } from '../../store/navigation/navigation.actions';
import { DealType } from '../../store/deals/deals.types';
import { Document } from '../../gql/graphql';
import { ReactComponent as ArrowLeftIcon } from '../../shared/icons/arrow-left.svg';
import { ContentBody } from '../../components/private-layout/ContentBody';
import DealNotificationToggle from './components/dealNotification/DealNotificationToggle';
import NotFound from '../not-found';
import ErrorMessage from '../../components/error-message';

const Page = ({ refetchDealData }: { refetchDealData: () => Promise<void> }) => {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const { formatMessage } = useIntl();
  const { teamId } = useTeamInfo();
  const dealId = useDealId();
  const { isCreator, isDealRolesLoading } = useDealRoles(dealId);
  const activeModal = useSelector(selectActiveModal);
  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });

  const { loading: isDealLoading, data: dealData } = useQuery(queries.getDealById, {
    variables: {
      dealId,
    },
  });

  const { loading: isDealDocumentsDataLoading, data: dealDocumentsData } = useQuery(queriesV2.getDocumentsByDeal, {
    variables: {
      dealId,
    },
  });

  const { loading: alertableDocumentsDataLoading, data: alertableDocuments } = useQuery(
    queriesV2.getAlertableDocumentsByTeam,
    {
      variables: {
        teamId,
        dealId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { loading: loadingUser, data: userData, error, refetch: refetchUser } = useQuery(
    queries.getCurrentUser,
    {
      fetchPolicy: 'network-only',
    }
  );

  const canEdit = permissions.hasEditPermission;

  const refreshUserData = useCallback(async () => {
    await refetchUser()
  }, [refetchUser])

  useEffect(() => {
    if (!dealData) {
      return;
    }

    dispatch(setDeal((dealData.getDealById as unknown) as DealType));
  }, [dispatch, dealData]);

  useEffect(() => {
    dispatch(closeDocumentModals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleManageDealParticipantsCollapsed(true));
  }, [dispatch]);

  const userDealNotifications = useMemo(() => {
      return userData?.currentUser?.communicationPreferences.deal_notifications
    }, [userData?.currentUser?.communicationPreferences.deal_notifications]
  )

  const isLoading = isDealLoading
    || isDealDocumentsDataLoading
    || isDealRolesLoading
    || alertableDocumentsDataLoading
    || loadingUser;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!userData) {
    const errors = graphqlErrorsToUiErrors(error);
    console.warn('ERROR', errors);
    if (errors._general?.id === 'api.error.not-found') {
      return <NotFound />;
    }
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const { title, receiverTeam } = dealData!.getDealById;

  return (
    <>
      <Helmet
        title={formatMessage(
          {
            id: 'title.deal-detail',
            defaultMessage: `Deal: ${title}`,
          },
          { title: title! }
        )}
      />

      <Header
        refetchDealData={refetchDealData}
        titleComponent={
          <>
            <div className={'d-flex align-items-center ml-3 non-mobile-flex'}>
              <A href={paths.deals.landing} hrefParams={{ teamId }}>
                <ArrowLeftIcon height="24" className={'pr-2 mr-1'} />
              </A>

              {canEdit && isCreator ? (
                <InputTitle dealId={dealId} title={title} isDealTitleEditing />
              ) : (
                <div>{title}</div>
              )}
              <div className={'pl-3'}>
                <Controls dealId={dealId} teamId={teamId} />
              </div>
              <DealNotificationToggle dealId={dealId} userDealNotifications={userDealNotifications} refetchUser={refreshUserData} />
            </div>
            <div className={'d-flex align-items-center flex-column mobile-flex'}>
              <div className="d-flex align-items-center w-100 px-2 pb-3">
                <A href={paths.deals.landing} hrefParams={{ teamId }}>
                  <ArrowLeftIcon height="24" className={'mr-3'} />
                </A>

                {canEdit && isCreator ? (
                  <InputTitle
                    dealId={dealId}
                    title={title}
                    isDealTitleEditing
                    className="justify-content-between w-100"
                  />
                ) : (
                  <div>{title}</div>
                )}
              </div>
              <div className={'w-100'}>
                <Controls dealId={dealId} teamId={teamId} controlClassName="w-100" />
              </div>
            </div>
          </>
        }
        noMore
      ></Header>

      <ContentBody className={styles.dealDetailBody}>
        {/*
        // @ts-ignore */}
        <CardContainer className={styles.cardsContainer}>
          <div className="col-md-8">
            <div>
              <DocumentTable
                dealDocumentsData={(dealDocumentsData!.getDocumentsByDeal! as unknown) as Document[]}
                permissionsData={permissions}
                isDealShared={!!receiverTeam}
                isCurrentUserAdmin={permissions.isAdmin}
                teamId={teamId}
                alertableDocuments={(alertableDocuments!.getAlertableDocumentsByTeam as unknown) as Document[]}
              />
            </div>
          </div>
          <ActivitiesCard dealData={dealData!.getDealById} dealDocumentsData={dealDocumentsData?.getDocumentsByDeal} />
        </CardContainer>

        {(permissions.isAdmin || canEdit) && (
          <>
            <LeaseTemplateModal
              isOpen={activeModal === CreateDocumentModalsEnum.TEMPLATE_SELECTOR}
              onClose={() => dispatch(setActiveModal(null))}
              dealData={dealData!.getDealById}
            />
            <CreateBlankDocumentModal
              isOpen={activeModal === CreateDocumentModalsEnum.BLANK_DOCUMENT}
              onClose={() => dispatch(setActiveModal(null))}
            />
            <FileUploadModal
              onClose={() => dispatch(setActiveModal(null))}
              isOpen={activeModal === ModalActionsEnum.UPLOAD_FILE || activeModal === ModalActionsEnum.UPLOAD_DOC_FILE}
              dealId={dealId}
              teamId={teamId}
            />
          </>
        )}
      </ContentBody>
    </>
  );
};

const Detail = () => {
  const { teamId } = useTeamInfo();
  const dealId = useDealId();
  const { loading: isDealDataLoading, data: dealData, refetch } = useQuery(queriesV2.getDealTeamsByTeamId, {
    skip: !teamId || !dealId,
    variables: {
      dealId,
      teamId,
    },
    fetchPolicy: 'no-cache',
  });
  const refetchDealData = async () => {
    await refetch();
  }
  // @ts-ignore
  return <CheckDealExists>
    <PrivateLayout>
      <ManageParticipantsLayoutContainer isDealDataLoading={isDealDataLoading} dealData={dealData} refetchDealData={refetchDealData}  />
      <Page refetchDealData={refetchDealData} />
    </PrivateLayout>
  </CheckDealExists>;
};

export default Detail;
