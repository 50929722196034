//function which get text with html-tags, replaces tags either with their defined values, or with spaces if no values ​​are set

export const formatTextForTemplate = (body: string) => {
  const valuesForReplace: Record<string, string> = {
    '<img>': '[IMAGE]',
    '<figure class="image">': '[IMAGE]',
    '<table>': '[TABLE]',
  };

  return body.replace(/<[^>]+>|&(nbsp|amp|quot|lt|gt);/g, m => valuesForReplace[m] || ' ');
};
