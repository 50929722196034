import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DangerIcon } from '../../../shared/icons/alert-danger-icon.svg';
import './lease-document-wizard-header.scss';

interface DocumentSubeaderProps {
  actions: ReactNode;
  grantAccessSubheader?: boolean;
  unlockDocumentSubheader?: boolean;
  orgTemplateSubheader?: boolean;
  organizationName?: string;
  isClauseTemplate?: boolean;
}

export const DocumentSubheader = ({
  actions,
  grantAccessSubheader,
  unlockDocumentSubheader,
  orgTemplateSubheader,
  organizationName,
  isClauseTemplate
}: DocumentSubeaderProps) => {

  const subHeaderStyleClass = () => {
    if (orgTemplateSubheader) {
      return "lease-document-wizard-subheader-warning";
    }
    else {
      return 'lease-document-wizard-subheader-info';
    }
  }

  const subHeaderText = () => {
    if (grantAccessSubheader) {
      return 'Only your team has access to this document. Grant your counterparty access to allow them to view and download the document.';
    }
    else if (orgTemplateSubheader) {
      const warningObject = isClauseTemplate
        ? 'Clause'
        : 'Template'

      return `This ${ warningObject } is available due to your active membership in ${organizationName}, it cannot be edited.`;
    }
    else {
      return 'This is the last Approved version. Unlock to make edits.'
    }
  }
  return (
    <header className={subHeaderStyleClass()} data-testid="document-header">
      <nav className="lease-document-wizard-header__actions-subheader">
        <div className="lease-document-subheader__document-title-container">
          {unlockDocumentSubheader && (
            <span className="font-weight-bold pr-2">
              <FormattedMessage id="subheader-title" defaultMessage="Approved version." />
            </span>
          )}
          <DangerIcon width={15} height={15} />
          <FormattedMessage
            id="subheader-title"
            defaultMessage={subHeaderText()}
          />
        </div>
        {!orgTemplateSubheader && <div className="col-lg-4 p-0 lease-document-subheader__document-action-container">
          <div className="pr-1 lease-document-header__edit-actions">{actions}</div>
        </div>}
      </nav>
    </header>
  );
};
