import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import AddNewTeamFormik from '../../../../forms/AddNewTeamFormContainer';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface Props {
  handleHide: () => void;
  show: boolean;
}

const CreateNewTeamModal = ({ handleHide, show }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal show={show} onHide={handleHide} size="lg">
      <div>
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title>
            <header>
              <FormattedMessage id="title.add-team-member" defaultMessage="Create a Workspace" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <AddNewTeamFormik
          // @ts-ignore
          bodyContainer={Modal.Body}
          // @ts-ignore
          actionsContainer={Modal.Footer}
          renderSecondaryAction={() => (
            <Button variant="link" className="text-secondary" onClick={handleHide}>
              <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
            </Button>
          )}
          onSuccess={handleHide}
        />
      </div>
    </Modal>
  );
};

export default CreateNewTeamModal;
