import { useCallback } from 'react';
import { ApolloClient } from '@apollo/client';
import { setLeaseDocumentIsPending } from '../../../../../store/lease-document/actions/lease-document.actions';
import { SaveLeaseDocument, SaveLeaseDocumentTemplate } from '../../../../../utils/api/lease-document-interface.types';
import { dispatchLeaseDocumentResponseToStore } from '../../../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { useDispatch } from 'react-redux';

type UseLeaseDocumentTitleSaveOptions = {
  id: string;
  onError: () => void;
  save: SaveLeaseDocument;
  apolloClient: ApolloClient<object>;
  dispatch: (...args: any[]) => any;
};

type UseLeaseDocumentTemplateTitleSaveOptions = {
  id: string;
  onError: () => void;
  save: SaveLeaseDocumentTemplate;
  apolloClient: ApolloClient<object>;
};

type SaveTitle = (title: string) => void;

export const useLeaseDocumentTitleSave = ({
  id,
  save,
  onError,
  apolloClient,
  dispatch,
}: UseLeaseDocumentTitleSaveOptions): SaveTitle => {
  return useCallback(
    (title: string) => {
      // User cannot delete title
      if (!title) {
        return;
      }

      dispatch(setLeaseDocumentIsPending(true));

      save({ documentId: id, title })(apolloClient)
        .then(response => {
          dispatchLeaseDocumentResponseToStore(dispatch, response);
        })
        .catch(onError)
        .finally(() => {
          dispatch(setLeaseDocumentIsPending(false));
        });
    },
    [apolloClient, dispatch, id, onError, save]
  );
};

export const useLeaseDocumentTemplateTitleSave = ({
  id,
  save,
  onError,
  apolloClient,
}: UseLeaseDocumentTemplateTitleSaveOptions): SaveTitle => {
  const dispatch = useDispatch();
  return useCallback(
    (name: string) => {
      // User cannot delete title
      if (!name) {
        return;
      }

      dispatch(setLeaseDocumentIsPending(true));

      save({ documentTemplateId: id, name })(apolloClient)
        .then(response => {
          dispatchLeaseDocumentResponseToStore(dispatch, response);
        })
        .catch(onError)
        .finally(() => {
          dispatch(setLeaseDocumentIsPending(false));
        });
    },
    [apolloClient, dispatch, id, onError, save]
  );
};

