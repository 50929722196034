import React from 'react';
import { Container } from 'react-bootstrap';
import cls from 'classnames';
import styles from './styles.module.scss';

interface ContentBodyProps {
  children: React.ReactNode;
  className?: string;
}

export const ContentBody = ({ children, className }: ContentBodyProps) => {
  return (
    <Container className={cls(styles.contentBody, className)} fluid>
      {children}
    </Container>
  );
};
