import { EditorType } from '../../../components/lease-wizard/lease-editor/utils/lease-editor.types';
import { filteredSuggestionsData } from './helpers';
import { CreateSuggestionData, SuggestionStatusesEnum, TrackChangesConfig } from './types';

export class TrackChangesIntegration {
  private editor: EditorType;

  constructor(editor: EditorType) {
    this.editor = editor;
  }

  static get requires() {
    return ['TrackChanges'];
  }

  init() {
    const trackChangesPlugin = this.editor.plugins.get('TrackChanges');
    const config: TrackChangesConfig = this.editor.config.get('suggestionsAdapter');
    const componentFactory = this.editor.ui.componentFactory;

    componentFactory.add('propdocsTrackChanges', () => {
      const dropdown = componentFactory.create('trackChanges');
      const splitButton = dropdown.buttonView;

      splitButton.icon = null;
      splitButton.withText = true;

      return dropdown;
    });

    trackChangesPlugin.adapter = {
      addSuggestion: async (suggestionData: CreateSuggestionData) => {

        const { data, id, originalSuggestionId, type } = suggestionData;

        const { createdAt, authorRole } = await config.createSuggestion({
          type,
          data: data || {},
          attributes: {},
          originalSuggestionId,
          externalId: id,
        });

        config.saveSessionActivity(`suggestion:${id}:${SuggestionStatusesEnum.CREATED}`);

        const suggestion = trackChangesPlugin.getSuggestion(id);
        suggestion.setAttribute('authorRole', authorRole);

        return { createdAt };
      },
      getSuggestion: async (suggestionId: string) => {
        const { attributes, author, createdAt, data, externalId, type, authorRole } = await config.getSuggestionById(
          suggestionId
        );

        return {
          attributes: {
            ...attributes,
            authorRole,
          },
          authorId: author.id,
          createdAt,
          data,
          id: externalId,
          type,
          hasComments: true,
        };
      },
      updateSuggestion: async (suggestionId: string, suggestionData: { state: SuggestionStatusesEnum }) => {
        const { state: suggestionState } = suggestionData;
        const suggestionsHtmlData = window.document.querySelectorAll(`[data-suggestion=${suggestionId}]`) as NodeListOf<
          Element
        >;

        const suggestionHTMLData = filteredSuggestionsData(Array.from(suggestionsHtmlData));

        if ([SuggestionStatusesEnum.ACCEPTED].includes(suggestionState)) {
          await config.acceptSuggestionById(suggestionId, suggestionHTMLData);
          config.saveSessionActivity(`suggestion:${suggestionId}:${suggestionState}`);
          return Promise.resolve();
        }

        if ([SuggestionStatusesEnum.REJECTED].includes(suggestionState)) {
          await config.rejectSuggestionById(suggestionId, suggestionHTMLData);
          config.saveSessionActivity(`suggestion:${suggestionId}:${suggestionState}`);
          return Promise.resolve();
        }

        if ([SuggestionStatusesEnum.OPEN].includes(suggestionState)) {
          await config.pendSuggestionById(suggestionId);
          config.saveSessionActivity(`suggestion:${suggestionId}:pending`);
          return Promise.resolve();
        }

        return Promise.resolve();
      },
    };
  }
}
