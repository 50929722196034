import { SetIsEligibleForSigningPrepAction } from '../actions/lease-document.actions';
import { LeaseDocumentStateType } from '../lease-document.reducer';

export const handleSetIsEligibleForSigningPrep = (
  state: LeaseDocumentStateType,
  action: SetIsEligibleForSigningPrepAction
): LeaseDocumentStateType => ({
  ...state,
  isEligibleForSigningPrep: action.isEligibleForSigningPrep,
});
