import React, { ReactNode, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import get from 'lodash/get';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import { FormikErrorMessage } from '../error-message';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './styles.module.scss';

export const FieldLabel = ({ children }: { children: ReactNode }) => (
  <Form.Label className="text-muted font-weight-bold">{children}</Form.Label>
);

interface BaseFieldWrapProps {
  before?: any;
  afterField?: any;
  after?: any;
  id?: string;
  label?: string | ReactNode;
  name: string;
  text?: string;
  children?: ReactNode;
}
export const BaseFieldWrap = ({
  before = null,
  afterField = null,
  after = null,
  id,
  label,
  name,
  text,
  children,
}: BaseFieldWrapProps) => (
  <Form.Group controlId={id}>
    {before}
    <FieldLabel>{label}</FieldLabel>
    {children}
    {text ? <Form.Text className="text-muted">{text}</Form.Text> : null}
    {afterField}
    <FieldFeedback name={name} />
    {after}
  </Form.Group>
);

interface BaseFieldProps {
  id?: string;
  name: string;
  as?: any;
  refPropName?: string;
  type?: string;
  children?: ReactNode;
}

export const BaseField = React.forwardRef(
  ({ id, name, as = Field, refPropName = 'innerRef', children, type, ...rest }: BaseFieldProps, ref) => {
    const { errors = {}, touched } = useFormikContext();
    const [showPassword, setShowPassword] = useState(false);

    const refProp = {
      [refPropName]: ref,
    };

    const newType = type === 'password' && showPassword ? 'text' : type;

    return (
      <div className={classNames('position-relative', { [styles.passwordField]: type === 'password' })}>
        <Form.Control
          {...refProp}
          name={name}
          as={as}
          isValid={get(touched, name) && !get(errors, name)}
          isInvalid={get(touched, name) && get(errors, name)}
          {...rest}
          type={newType}
        >
          {children}
        </Form.Control>
        {type === 'password' && (
          // @ts-ignore
          <Button variant="unstyled" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <TitleFormattedMessage id="cta.hide" defaultMessage="Hide" />
            ) : (
              <TitleFormattedMessage id="cta.view" defaultMessage="View" />
            )}
          </Button>
        )}
      </div>
    );
  }
);

export const FieldFeedback = ({ name }: { name: string }) => (
  <Form.Control.Feedback type="invalid">
    <FormikErrorMessage name={name} />
  </Form.Control.Feedback>
);
