import React, { useCallback, useRef, useState } from 'react';
import { useDispatch as reduxUseDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../../notifications';
import { UnsignDocumentConfirmationModal } from './UnsignDocumentConfirmationModal';
import { selectLeaseDocumentId } from '../../../../store/lease-document/selectors/lease-document.selectors';
import { cancelSigningDocument } from '../../../../utils/api/cancel-signing-document';
import { DocumentStatusEnum } from '../../../../shared/constants/leaseDocument';
import {
  dispatchEraseLeaseDocumentValuesFromStore,
  dispatchLeaseDocumentResponseToStore,
} from '../../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';

import { paths } from '../../../../routing';

const UNSIGN_DOCUMENT_ERROR_MESSAGE = "Unable to remove the tenant's signature.";

export const UnsignDocumentConfirmationModalContainer = ({
  useDispatch = reduxUseDispatch,
  show,
  onHide,
  dealId,
  teamId,
  documentId,
}: {
  useDispatch?: any;
  show: boolean;
  onHide: any;
  teamId: string | null;
  dealId: string;
  documentId?: string;
}) => {
  const [isUnsignPending, setIsUnsignPending] = useState<boolean>(false);
  const optionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const currentDocumentId = useSelector(selectLeaseDocumentId);

  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const handleError = useCallback(() => {
    notifyError({
      message: UNSIGN_DOCUMENT_ERROR_MESSAGE,
    });
  }, [notifyError]);

  const handleEdit = () => {
    const selectedDocumentId = documentId ? documentId : currentDocumentId;
    const additionalMessage = optionalMessageInputRef.current?.value;
    setIsUnsignPending(true);
    cancelSigningDocument({ documentId: selectedDocumentId!, additionalMessage })(apolloClient)
      .then(response => {
        const isInReviewingState = response.status === DocumentStatusEnum.PREPARING_TO_SIGN;

        if (!isInReviewingState) {
          handleError();
          return;
        }

        dispatchEraseLeaseDocumentValuesFromStore(dispatch);
        dispatchLeaseDocumentResponseToStore(dispatch, response);

        notifySuccess({
          message: {
            id: 'phrase.unsign-document.success',
            defaultMessage: 'Signing for {name} cancelled.',
            values: { name: response.title },
          },
        });

        if (!!documentId) {
          return;
        }

        navigate(
          paths.Documents.signDocument
            .replace(':documentId', selectedDocumentId as string)
            .replace(':teamId', teamId as string)
            .replace(':dealId', dealId)
        );
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setIsUnsignPending(false);
        onHide();
      });
  };

  return (
    <UnsignDocumentConfirmationModal
      handleEdit={handleEdit}
      show={show}
      onHide={onHide}
      isUnsignPending={isUnsignPending}
      optionalMessageInputRef={optionalMessageInputRef}
    />
  );
};
