import { createSelector, Selector } from 'reselect';
import { RootReducer } from '../store.types';
import { PermissionsStateType, TeamPermission } from './permissions.reducer';

export const selectPermissionsRoot = (state: RootReducer): PermissionsStateType => state.permissions;

export const selectTeamPermissions: Selector<RootReducer, TeamPermission[]> = createSelector(
  selectPermissionsRoot,
  permissionsRoot => permissionsRoot.teamPermissions
);

export const selectOwnedTeams: Selector<RootReducer, string[]> = createSelector(
  selectPermissionsRoot,
  permissionsRoot => permissionsRoot.ownedTeams
);
