import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentImportedAssetContentTypeAction } from '../actions/lease-document.actions';

export const handleSetDocumentImportedAssetContentType = (
  state: LeaseDocumentStateType,
  action: SetDocumentImportedAssetContentTypeAction
): LeaseDocumentStateType => ({
  ...state,
  contentType: action.contentType,
});
