import React, { ExoticComponent, Fragment, ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form, FormikErrors } from 'formik';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import ErrorMessage from '../components/error-message';
import { AddNewDealName } from '../consts/forms-constants';

interface AddNewDealFormProps {
  bodyContainer: ExoticComponent<{ children?: ReactNode }>;
  actionsContainer: ExoticComponent<{ children?: ReactNode }>;
  renderSecondaryAction: any;
  errors: FormikErrors<any>;
  values: { [key: string]: string };
  isSubmitting: boolean;
  isValid: boolean;
}

const AddNewDealForm = ({
  bodyContainer: BodyContainer = Fragment,
  actionsContainer: ActionsContainer = Fragment,
  renderSecondaryAction,
  errors,
  isSubmitting,
  isValid,
}: AddNewDealFormProps) => {
  return (
    <Form noValidate>
      <BodyContainer>
        {errors._general && (
          <Alert variant="danger">
            <ErrorMessage>{errors._general}</ErrorMessage>
          </Alert>
        )}

        <Row>
          <Col lg={12}>
            <TextField
              //@ts-ignore TODO update TextField to ts
              id="addNewDeal.name"
              name="deal_name"
              type="deal_name"
              label={<FormattedMessage id={AddNewDealName.id} defaultMessage={AddNewDealName.defaultMessage} />}
            />
            <div>
              <strong>Tip:</strong> Choose a good name, as the counterparty will also see it.
            </div>
          </Col>
        </Row>
      </BodyContainer>

      <ActionsContainer>
        {renderSecondaryAction && renderSecondaryAction()}
        <SubmitButton
          label={<FormattedMessage id="id.create-deal" defaultMessage="Create Deal" />}
          disabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
        />
      </ActionsContainer>
    </Form>
  );
};

export default AddNewDealForm;
