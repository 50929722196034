import React, { useEffect, useState } from 'react';
import PlaceholderSidebar from './placeholderSidebar';
import { useSelector } from 'react-redux';
import { selectDocumentPlaceholderRefreshKey, selectLeaseDocumentId, selectLeaseDocumentTemplate } from '../../../../../store/lease-document/selectors/lease-document.selectors';
import { useQuery } from '@apollo/client';
import { queriesV2 } from '../../../../../api';
import { DocumentTypesEnum } from '../../../../../utils/ckeditor/trackChangesAdapter/types';

export interface ClauseTemplate {
  id: string;
  title: string;
  status?: string;
  isRequired: boolean;
}
interface PlaceholderSidebarContainerProps {
  activeUuid: string | null;
};

const PlaceholderSidebarContainer = ({ activeUuid }: PlaceholderSidebarContainerProps) => {
  const documentId = useSelector(selectLeaseDocumentId);

  const documentType = !!useSelector(selectLeaseDocumentTemplate) ? DocumentTypesEnum.TEMPLATE : DocumentTypesEnum.DOCUMENT;

  const {
    loading: placeholderCompletenessLoading,
    data,
    refetch
  } = useQuery(queriesV2.getDocumentPlaceholderCompleteness, {
    skip: !documentId,
    fetchPolicy: 'no-cache',
    variables: {
      documentId: String(documentId),
      documentType: documentType
    },
  })

  const placeholdersInDoc = data?.getDocumentPlaceholderCompleteness?.placeholders;

  const mappedPlaceholders = placeholdersInDoc?.map((placeholder: any) => {
    return {
      id: placeholder['uuid'],
      uuid: placeholder['uuid'],
      title: placeholder['title'],
      isRequired: placeholder['isRequired'],
      status: 'incomplete',
    }
  });


  const currentPlaceholdersRefreshKey = useSelector(selectDocumentPlaceholderRefreshKey);

  const [placeholders, setPlaceholders] = useState(mappedPlaceholders ?? []);

  useEffect(() => {
    if (documentId) {
      refetch({
        documentId: String(documentId),
        documentType: documentType
      });
    }
  }, [currentPlaceholdersRefreshKey, activeUuid, refetch]);

  useEffect(() => {
    if (!placeholderCompletenessLoading && data) {
      const placeholdersInDoc = data.getDocumentPlaceholderCompleteness.placeholders;

      const newPlaceholders = placeholdersInDoc.map((placeholder: any) => ({
        id: placeholder['uuid'],
        uuid: placeholder['uuid'],
        title: placeholder['title'],
        isRequired: placeholder['isRequired'],
        status: 'incomplete',
      }));

      setPlaceholders(newPlaceholders);
    }
  }, [placeholderCompletenessLoading, data]);

  return <PlaceholderSidebar placeholders={placeholders} activeUuid={activeUuid} />;
};

export default PlaceholderSidebarContainer;
