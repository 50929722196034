import StatusDropdown, { DealStatus } from '../../pages/deals/components/statusDropdown/statusDropdown';
import { formatDate } from '../../utils/date';
import styles from './styles.module.scss';

export interface DealData {
  creator: string;
  currentUserRole: string;
  id: string;
  title: string;
  status: 'in_progress' | 'completed' | 'lost' | 'archived';
  updatedAt: string;
  onClick?: () => void;
}

interface MobileDealItemProps {
  deal: DealData;
  handleUpdateDealDetails: (sealId: string, newStatus: DealStatus) => void;
  onClick?: () => void;
}

export const MobileDealItem: React.FC<MobileDealItemProps> = ({ deal, handleUpdateDealDetails, onClick }) => {
  return (
    <div className={styles.dealItem} onClick={onClick}>
      <div className={styles.dealItem__header}>
        <div className={styles.dealItem__title}>{deal.title}</div>
        <StatusDropdown
          currentStatus={deal.status}
          onStatusChange={(newStatus: DealStatus) => {
            handleUpdateDealDetails(deal.id, newStatus);
          }}
          className="dealItem__status"
        />
      </div>
      <div className={styles.dealItem__footer}>
        <div className={styles.dealItem__creator}>{deal.creator}</div>
        <div className={styles.dealItem__updatedAt}>{formatDate(deal.updatedAt)}</div>
      </div>
    </div>
  );
};
