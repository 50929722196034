import filterTeamPermissions from './filter-team-permissions';
import { permissionsComparator } from './comparators';
import { Permission, TeamPermission } from '../store/permissions/permissions.reducer';

/**
 * Mutate a list of team permissions
 */
export default function mutateTeamPermissions(teamPermissions: TeamPermission[], isAdmin: boolean) {
  return teamPermissions
    .filter(filterTeamPermissions(isAdmin))
    .map(({ permission }) => permission as Permission)
    .sort(permissionsComparator);
}
