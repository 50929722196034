import React, { useState } from 'react';
import clsx from 'classnames';

import styles from './styles.module.scss';
import { DropdownPanelToggle } from './DropdownPanelToggle';
import { DropdownPanelBody } from './DropdownPanelBody';

interface DropdownPanelProps {
  title: string;
  children: React.ReactNode;
  hasBorder?: boolean;
}

const DropdownPanel: React.FC<DropdownPanelProps> = ({ title, children, hasBorder }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={clsx(styles.dropdown, { [styles.hasBorder]: hasBorder })}>
      <DropdownPanelToggle title={title} isOpen={isOpen} onToggle={() => setOpen(!isOpen)} />
      <DropdownPanelBody isOpen={isOpen}>{children}</DropdownPanelBody>
    </div>
  );
};

export default DropdownPanel;
