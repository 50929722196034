import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { RemoveMemberFromDealConfirmationModal, RemoveTeamMemberConfirmationModal } from './components';
import TeamMembersTableContainer from './components/teamMembersTable/TeamMembersTableContainer';

interface TeamMembersListProps {
  isReadOnly?: boolean;
  showDealMembersOnly?: boolean;
  dealsData: any;
  dealsGraphqlError?: ApolloError;
  teamData: any;
  dealData: any;
  dealMembersIds: string[];
  shouldShowPendingInvitations?: boolean;
  sorting?: string;
}

const TeamMembersList = ({
  isReadOnly = false,
  showDealMembersOnly = false,
  dealsData,
  dealsGraphqlError,
  teamData,
  dealData,
  dealMembersIds,
  shouldShowPendingInvitations,
  sorting,
}: TeamMembersListProps) => {
  const [userToRemove, setUserToRemove] = useState(null);
  const [userToRemoveFromDeal, setUserToRemoveFromDeal] = useState(null);

  return (
    <>
      <TeamMembersTableContainer
        isReadOnly={isReadOnly}
        showDealMembersOnly={showDealMembersOnly}
        setUserToRemove={setUserToRemove}
        setUserToRemoveFromDeal={setUserToRemoveFromDeal}
        dealsData={dealsData}
        dealsGraphqlError={dealsGraphqlError}
        teamData={teamData}
        dealData={dealData}
        sorting={sorting}
        dealMembersIds={dealMembersIds}
        shouldShowPendingInvitations={shouldShowPendingInvitations}
      />
      <RemoveTeamMemberConfirmationModal userToRemove={userToRemove} setUserToRemove={setUserToRemove} />
      <RemoveMemberFromDealConfirmationModal
        userToRemoveFromDeal={userToRemoveFromDeal}
        setUserToRemoveFromDeal={setUserToRemoveFromDeal}
      />
    </>
  );
};

export default TeamMembersList;
