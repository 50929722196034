import { ModalAction } from './modals.actions';
import { GlobalModalActionsEnum } from '../../components/document-table/DocumentTableConstants';

export type ModalsStateType = {
  activeModal: GlobalModalActionsEnum | null;
  showSendDocumentModal: {
    isOpen: boolean;
  };
  showGrantAccessModal: {
    isOpen: boolean;
  };
  showRequestInternalReviewDocumentModal: {
    isOpen: boolean;
  };
  showSendViaEmailModal: {
    isOpen: boolean;
  };
  showHeaderFooterEditorModal: {
    isOpen: boolean;
  };
  showRequiredPlaceholdersModal: {
    isOpen: boolean;
  }
  showSendForSigningPreparationModal: {
    isOpen: boolean;
  },
  closePendingReviewWarningModal: {
    isClosed: boolean;
  },
};

const initialState: ModalsStateType = {
  activeModal: null,
  showSendDocumentModal: {
    isOpen: false,
  },
  showGrantAccessModal: {
    isOpen: false,
  },
  showRequestInternalReviewDocumentModal: {
    isOpen: false,
  },
  showSendViaEmailModal: {
    isOpen: false,
  },
  showHeaderFooterEditorModal: {
    isOpen: false,
  },
  showRequiredPlaceholdersModal: {
    isOpen: false,
  },
  showSendForSigningPreparationModal: {
    isOpen: false,
  },
  closePendingReviewWarningModal: {
    isClosed: false,
  },
};

const reducer = (state: ModalsStateType = initialState, action: ModalAction): ModalsStateType => {
  switch (action.type) {
    case 'setActiveModal':
      return {
        ...state,
        activeModal: action.activeModal,
      };

    case 'showSendDocumentModal':
      return {
        ...state,
        showSendDocumentModal: {
          ...state.showSendDocumentModal,
          isOpen: action.isOpen,
        },
      };

    case 'showRequestInternalReviewDocumentModal':
      return {
        ...state,
        showRequestInternalReviewDocumentModal: {
          ...state.showRequestInternalReviewDocumentModal,
          isOpen: action.isOpen,
        },
      };

    case 'showGrantAccessModal':
      return {
        ...state,
        showGrantAccessModal: {
          ...state.showGrantAccessModal,
          isOpen: action.isOpen,
        },
      };

    case 'showSendDocumentViaEmailModal':
      return {
        ...state,
        showSendViaEmailModal: {
          ...state.showSendViaEmailModal,
          isOpen: action.isOpen,
        },
      };

    case 'showRequiredPlaceholdersModal':
      return {
        ...state,
        showRequiredPlaceholdersModal: {
          ...state.showRequiredPlaceholdersModal,
          isOpen: action.isOpen,
        },
      };

    case 'showHeaderFooterEditorModal':
      return {
        ...state,
        showHeaderFooterEditorModal: {
          ...state.showHeaderFooterEditorModal,
          isOpen: action.isOpen,
        },
      };

    case 'showSendForSigningPreparationModal':
      return {
        ...state,
        showSendForSigningPreparationModal: {
          ...state.showSendForSigningPreparationModal,
          isOpen: action.isOpen,
        },
      };

      case 'closePendingReviewWarningModal':
        return {
          ...state,
          closePendingReviewWarningModal: {
            ...state.closePendingReviewWarningModal,
            isClosed: action.isClosed,
          },
        };

    case 'closeDocumentModals':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export { reducer as modalsReducer };
