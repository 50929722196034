import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseDocumentSuggestionsAction } from '../actions/lease-document.actions';

export const handleSetLeaseDocumentSuggestions = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentSuggestionsAction
): LeaseDocumentStateType => ({
  ...state,
  documentSuggestions: action.documentSuggestions,
});
