import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'react-bootstrap/Button';
import BootstrapTable from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import { ReactComponent as ChevronRightIcon } from '../../shared/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../shared/icons/chevron-left.svg';
import { ReactComponent as SortAscIcon } from '../../shared/icons/sort-asc.svg';
import { ReactComponent as SortDescIcon } from '../../shared/icons/sort-desc.svg';

import styles from './styles.module.scss';

export const PAGINATION_CONTEXT = 2;

export const Table = ({
  children = false,
  table,
  renderRowSubComponent = false,
  striped = false,
  paginationAbove = false,
  paginationBelow = false,
  isActionColumn = false,
  isSmall = false,
  isStickyHeader = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [activeColId, setActiveColId] = useState(null);

  return (
    <>
      {paginationAbove && (
        <Pagination
          table={table}
          className={'pb-3'}
          aria-label={formatMessage({
            id: 'pagination.title.for-table-below',
            defaultMessage: 'Pagination for following table',
          })}
        />
      )}
      <BootstrapTable {...rest} className={classNames(rest.className, styles.styled, { ['table-sm']: isSmall })}>
        <thead>
          <tr className={classNames({ [styles.isStickyHeader]: isStickyHeader })}>
            {table.flatHeaders.map(column => (
              <th
                title={null}
                data-testid={`column-${column.id}`}
                className="align-middle"
                onMouseOver={() => setActiveColId(column.id)}
                onMouseOut={() => setActiveColId(null)}
                onFocus={() => setActiveColId(column.id)}
                onBlur={() => setActiveColId(column.id)}
                {...column.getHeaderProps({
                  style: { width: column.width !== 150 ? column.width : undefined },
                })}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <Row noGutters className="flex-nowrap">
                  {column.render('Header')}
                  {column.disableSortBy || (
                    <span className="text-primary">
                      <Button
                        variant="link"
                        className="mt-1"
                        aria-label={formatMessage({
                          id: 'cta.toggle-sort-order',
                          defaultMessage: 'Toggle sort order',
                        })}
                        data-testid={column.testId && `${column.testId}-column`}
                        onClick={e => {
                          e.stopPropagation();
                          column.getSortByToggleProps().onClick(e);
                        }}
                      >
                        <SortIcons
                          isSorted={column.isSorted}
                          isDesc={column.isSortedDesc}
                          isActive={activeColId === column.id}
                        />
                      </Button>
                    </span>
                  )}
                </Row>
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...table.getTableBodyProps()}>
          {table.rows.map((row, i) => {
            return (
              table.prepareRow(row) || (
                <React.Fragment key={row.getRowProps().key}>
                  <tr
                    {...row.getRowProps()}
                    className={classNames(
                      row.getRowProps().className,
                      {
                        isExpanded: renderRowSubComponent && row.isExpanded,
                        isOdd: striped && !(i % 2),
                        isEven: striped && !!(i % 2),
                      },
                      !!row.original.onRowClick && styles.isClickable,
                      isActionColumn && styles.isActionColumn
                    )}
                    onClick={() => row.original.onRowClick && row.original.onRowClick(row)}
                  >
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} data-testid={cell.column.testId && `${i}-${cell.column.testId}`}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                  {renderRowSubComponent && row.isExpanded && (
                    <tr
                      className={classNames('expansion', { isOdd: striped && !(i % 2), isEven: striped && !!(i % 2) })}
                    >
                      <td colSpan={row.cells.length}>{renderRowSubComponent(row)}</td>
                    </tr>
                  )}
                  <div className={styles.divider}></div>
                </React.Fragment>
              )
            );
          })}
        </tbody>
      </BootstrapTable>
      {paginationBelow && (
        <Pagination
          table={table}
          className={'pb-5'}
          aria-label={formatMessage({
            id: 'pagination.title.for-table-above',
            defaultMessage: 'Pagination for preceding table',
          })}
        />
      )}
    </>
  );
};

const SortIcons = ({ isSorted, isDesc, isActive }) => {
  return (
    <span className="d-inline-flex flex-column align-items-center align-text-top">
      <SortAscIcon
        style={{ marginBottom: '2px' }}
        className={(isSorted && !isDesc) || (!isSorted && isActive) ? 'visible' : 'invisible'}
      />
      <SortDescIcon className={(isSorted && isDesc) || (!isSorted && isActive) ? 'visible' : 'invisible'} />
    </span>
  );
};

const PageButton = ({ page, table }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      className={classNames(styles.paginationButton, { [styles.isCurrent]: table.state.pageIndex === page - 1 })}
      variant="unstyled"
      onClick={() => table.gotoPage(page - 1)}
      disabled={table.state.pageIndex === page - 1}
      aria-label={formatMessage(
        {
          id: 'cta.go-to.page-n',
          defaultMessage: 'Go to page {page, number}',
        },
        { page }
      )}
      data-testid={`table-pagination-page-${page}`}
    >
      {page.toString().padStart(2, '0')}
    </Button>
  );
};

const PageEllipsis = () => <span>…</span>;

export const Pagination = ({ table, className, ...rest }) => {
  const { formatMessage } = useIntl();

  const rangeStart = table.state.pageSize * table.state.pageIndex + 1;
  const rangeEnd = Math.min(table.state.pageSize * (table.state.pageIndex + 1), table.rows.length);
  const totalResults = table.rows.length;
  const currentPage = table.state.pageIndex + 1;
  const totalPages = table.pageOptions.length;

  // Array of controls to render next to the pagination status
  const paginationControls = [];

  if (totalPages > 1) {
    // "Previous" button is always present
    paginationControls.push(
      <Button
        key="prev"
        className={styles.paginationButton}
        variant="unstyled"
        onClick={() => table.previousPage()}
        disabled={!table.canPreviousPage}
        aria-label={formatMessage({
          id: 'cta.table.previous-page',
          defaultMessage: 'Go to previous page',
        })}
        data-testid="table-pagination-prev"
      >
        <ChevronLeftIcon />
      </Button>
    );

    /*    // Page 1 button is always present
    paginationControls.push(<PageButton key={1} page={1} table={table} />);

    // Count from page 2 or skip ahead with an ellipsis if the current page is
    // far enough ahead
    let cursor = 2;
    if (currentPage - PAGINATION_CONTEXT > 3) {
      paginationControls.push(<PageEllipsis key="ellipsis-before" />);
      cursor = currentPage - PAGINATION_CONTEXT;
    }

    // Show pages before and after the current one, unless we hit the final page
    for (; cursor <= currentPage + PAGINATION_CONTEXT && cursor <= totalPages; cursor++) {
      paginationControls.push(<PageButton key={cursor} page={cursor} table={table} />);
    }

    // Skip to the end with an ellipsis if we're far enough from there
    if (cursor < totalPages - 1) {
      paginationControls.push(<PageEllipsis key="ellipsis-after" />);
      cursor = totalPages;
    }

    // Continue counting until the end
    for (; cursor <= totalPages; cursor++) {
      paginationControls.push(<PageButton key={cursor} page={cursor} table={table} />);
    }*/

    // "Next" button is always present
    paginationControls.push(
      <Button
        key="next"
        className={styles.paginationButton}
        variant="unstyled"
        onClick={() => table.nextPage()}
        disabled={!table.canNextPage}
        aria-label={formatMessage({
          id: 'cta.table.next-page',
          defaultMessage: 'Go to next page',
        })}
        data-testid="table-pagination-next"
      >
        <ChevronRightIcon />
      </Button>
    );
  }

  return (
    <nav
      className={classNames('d-flex justify-content-end align-items-centers', className, styles.paginationNav)}
      {...rest}
    >
      <div className="mx-2">
        {0 < totalResults ? (
          <FormattedMessage
            id="pagination.current-position"
            defaultMessage="{rangeStart, number} – {rangeEnd, number} of {totalResults, number}"
            values={{
              rangeStart,
              rangeEnd,
              totalResults,
              currentPage,
              totalPages,
            }}
          />
        ) : (
          <FormattedMessage id="pagination.current-position" defaultMessage="No results" />
        )}
      </div>

      {paginationControls}
    </nav>
  );
};
