import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ApprovalRequestStatus, InternalApprovalReview } from './RequestInternalApprovalModalConstants';
import styles from './InternalApprovalBadge.module.scss';

interface Props {
  approvalRequestedReviews?: InternalApprovalReview[];
}

export const InternalApprovalApproversTooltipTable = ({ approvalRequestedReviews }: Props) => {
  const approversList = approvalRequestedReviews?.map((review: InternalApprovalReview) => {
    const isReviewApproved = review?.approvedAt;
    return (
      <Row className={styles.approversContainer}>
        <Col className={styles.approverData}>{review?.reviewer?.fullName}:</Col>
        <Col className={styles[isReviewApproved ? 'reviewStatus__approved' : 'reviewStatus']}>
          {isReviewApproved ? ApprovalRequestStatus.APPROVED : ApprovalRequestStatus.PENDING}
        </Col>
      </Row>
    );
  });

  return <Container>{approversList}</Container>;
};
