import CommentThreadView from "@ckeditor/ckeditor5-comments/src/comments/ui/view/commentthreadview";
import Template from "@ckeditor/ckeditor5-ui/src/template";
import View from "@ckeditor/ckeditor5-ui/src/view";

/** CLOSURE: This closes the reference of the react context state to the CK editor view. **/
let ReactCommentsContext = [];
let ThreadRendered;

export const getCustomCommentThreadView = (
  ReactCommentsContext,
  threadRendered,
  permissions,
  documentReadOnly,
  documentStatus,
) => {
  class CustomCommentThreadView extends CommentThreadView {
    constructor(...args) {
      super(...args);
    }

    static get requires() {
      return ["Comments"];
    }

    getTemplate() {
      const templateDefinition = super.getTemplate();
      const templateRef = templateDefinition.children[0].children;
      const togglePrivateThread = window.editor.commands.get(
        "togglePrivateThread"
      );

      if (!!templateDefinition.children[0].children.get(2)) {
        templateDefinition.children[0].children.get(2).commentInputView.cancelButtonView.set(
          {
            icon: null,
            withText: true,
            text: "Cancel",
          }
        );

        templateDefinition.children[0].children.get(2).commentInputView.submitButtonView.set(
          {
            icon: null,
            withText: true,
            label: "Comment",
          }
        );
      }

      const commentsRepository = editor.plugins.get("CommentsRepository");

      commentsRepository._threads.forEach((values) => {
        values.set("isRemovable", false);
      });

      commentsRepository.on(`addComment`, () => {
        if (!templateRef.get(1).element) {
          let indicator =
            window.document.getElementsByClassName("comment_indicator");
          if (indicator.length > 0) {
            indicator[0].parentNode.removeChild(indicator[0]);
          }
        }
      });

      const renderTemplate = () => {
        const template = this.renderPermissionIndicatorTemplate(
          togglePrivateThread.isEnabled
        );

        // Append the template to the correct comment view
        if (!!templateRef.get(0) && permissions.hasCommentPermission && !documentReadOnly && documentStatus !== 'signing') {
          // Create and set out template to a new view
          const newView = new View()
          newView.setTemplate(template)
          templateRef.add(newView, 1)
        }
        return templateDefinition;
      };
      return renderTemplate();
    }
    renderPermissionIndicatorTemplate(condition) {
      return new Template({
        tag: "div",
        attributes: {
          class: [
            "comment_indicator",
            // These indicator attributes are used in the client to only show this template for comment privileged users
            this.bindTemplate.if( 'isActive', 'comment_indicator--isActive' ),
            this.bindTemplate.if( 'isDirty', 'comment_indicator--isDirty' ),
          ],
          style: {
            width: "100%",
            "text-align": "left",
            color: condition ? "#2f89ac" : "#467f30",
            padding: "10px 50px 0px 50px",
            "font-size": "9px",
            "font-weight": "bold",
            background: "#F3F7FB",
            margin: "0px 0px -10px 0px",
            "border-left": "3px solid #f2de02",
          },
        },
        children: [condition ? "Private" : "Public"],
      });
    }
  }

  return CustomCommentThreadView;
};

export default getCustomCommentThreadView;
