import React, { useMemo } from 'react';
import classNames from 'classnames';
import md5 from 'md5';
import { getBackgroundColor, getFontSize, getGravatarUrl, getInitials } from './helpers';
import { DEFAULT_AVATAR_SIZE } from './constants';
import styles from './styles.module.scss';

interface AvatarProps {
  size?: number;
  email: string;
  fullName: string;
  className?: string;
  title?: string;
  backgroundColor?: string;
  fontColor?: string;
  fontSize?: string;
}

const Avatar = ({
  size = DEFAULT_AVATAR_SIZE,
  email,
  fullName,
  className,
  title,
  backgroundColor,
  fontColor,
  fontSize,
}: AvatarProps) => {
  const emailHash = useMemo(() => email && md5(email), [email]);

  return (
    <span
      className={classNames(styles.root, className)}
      style={{
        backgroundColor: backgroundColor || getBackgroundColor(emailHash),
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${size / 2}px`,
      }}
      title={title}
      data-testid="avatar-wrapper"
    >
      <span
        className={styles.initials}
        aria-hidden="true"
        style={{ fontSize: fontSize || getFontSize(fullName, email), color: fontColor || 'black' }}
      >
        {getInitials(fullName, email)}
      </span>
      <img
        className={styles.image}
        src={getGravatarUrl(emailHash)}
        srcSet={[
          getGravatarUrl(emailHash, size),
          `${getGravatarUrl(emailHash, Math.round(size * 1.5))} 1.5x`,
          `${getGravatarUrl(emailHash, size * 2)} 2x`,
          `${getGravatarUrl(emailHash, size * 3)} 3x`,
        ].join(', ')}
        alt=""
      />
    </span>
  );
};

export default Avatar;
