import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { queries, graphqlErrorsToUiErrors } from '../../api';
import { useTeamInfo } from '../../team-id-context';
import { useDealId } from '../../deal-id-context';
import ErrorMessage from '../../components/error-message';
import LoadingSpinner from '../../components/loading-spinner';
import NotFound from '../not-found';

interface CheckDealExistsProps {
  children: ReactNode;
}

const CheckDealExists = ({ children }: CheckDealExistsProps) => {
  const { teamId } = useTeamInfo();
  const dealId = useDealId();
  const { loading: getDealLoading, data: dealData, error: dealGraphqlError } = useQuery(queries.getDealById, {
    variables: {
      dealId
    },
  });

  const { loading: teamQueryLoading, data: teamData, error: teamGraphqlError } = useQuery(queries.getTeamById, {
    variables: {
      teamId,
    },
  });

  const loading = getDealLoading || teamQueryLoading;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!dealData || !teamData) {
    const dealErrors = graphqlErrorsToUiErrors(dealGraphqlError || teamGraphqlError);
    if (dealErrors._general?.id === 'api.error.not-found') {
      return (<NotFound />) as ReactNode;
    }
    return <ErrorMessage>{dealErrors._general}</ErrorMessage>;
  }

  return children as ReactNode;
};

export default CheckDealExists;
