import React, { useEffect, useRef, useState } from 'react';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { MutationFunctionOptions } from '@apollo/client';
import classNames from 'classnames';
import { LeaseBadgeContainer } from '../../components/lease-wizard/lease-badge-container/LeaseBadgeContainer';
import { LeaseDocumentWizardHeaderLastSaved } from '../../components/lease-wizard/lease-document-wizard-header/lease-document-wizard-header-lastsaved/LeaseDocumentWizardHeaderLastSaved';
import { LeaseDocumentWizardHeaderContainer } from '../../components/lease-wizard/lease-document-wizard-header/LeaseDocumentWizardHeaderContainer';
import { DocumentPermissions } from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { SignersSidebar } from '../../components/lease-wizard/lease-editor/components/signersSidebar';
import LeaseWizardSideNav from '../../components/lease-wizard/lease-wizard-sidenav/leaseWizardSideNav';
import {
  createAnnotationBlock,
  disableFormFieldsInDocument,
  disableUiFeatures,
  setAnnotationParametersByAnnotationType,
} from './helpers';
import { AnnotationTypes } from '../../components/lease-wizard/lease-editor/components/signersSidebar/components/insertFields/insertFields';
import { CustomAnnotationPopup } from './components';
import { ImportedDocumentSupportedContentTypes } from '../../components/shared/modals/fileUploadModal/types';
import { getExtensionByContentType } from '../uploadedWebViewer/uploadedViewer/helpers';
import { DocumentStatusEnum } from '../../shared/constants/leaseDocument';
import styles from './styles.module.scss';
import './styles.module.scss';
import { setNecessaryHeaderItems } from '../uploadedWebViewer/helpers/headerToobarHandlers';
import { AnnotationType } from '../uploadedWebViewer/helpers/constants';

export interface PrepareToSigningPageProps {
  lastUpdated: string;
  documentTitle: string;
  isDocumentCreator: boolean;
  documentId: string;
  permissions: DocumentPermissions;
  handleClose: () => void;
  pdfUrl: string;
  saveAnnotations: (options: MutationFunctionOptions) => Promise<any>;
  annotationsStringUrl: string | null;
  formFieldsStringUrl: string | null;
  isDocumentLocked: boolean;
  dealId: string;
  teamId: string;
  documentContentType: ImportedDocumentSupportedContentTypes;
  documentStatus: string;
}

export type FieldToAdd = {
  type: AnnotationTypes;
  name: string;
  value: string;
  signerId: string;
  signerOrder: number;
  dropPoint: { x: number; y: number };
};

const PrepareToSigning = ({
  lastUpdated,
  documentTitle,
  documentId,
  permissions,
  handleClose,
  pdfUrl,
  saveAnnotations,
  annotationsStringUrl,
  formFieldsStringUrl,
  isDocumentLocked,
  isDocumentCreator,
  dealId,
  teamId,
  documentContentType,
  documentStatus,
}: PrepareToSigningPageProps) => {
  const viewer = useRef<HTMLDivElement>(null);
  const isSigningProcessStarted = [
    DocumentStatusEnum.PREPARING_TO_SIGN,
    DocumentStatusEnum.SIGNING,
    DocumentStatusEnum.EXECUTED,
  ].includes(documentStatus as DocumentStatusEnum);
  const [viewerInstance, setViewerInstance] = useState<WebViewerInstance | null>(null);
  const [customSettingsApplied, setCustomSettingsApplied] = useState<boolean>(false);
  const dragOver = (e: any) => {
    e.preventDefault();
    return false;
  };

  const addField = ({ type, value = '', signerId, dropPoint }: FieldToAdd) => {
    if (!viewerInstance) return;

    const {
      Core: { documentViewer },
    } = viewerInstance;
    const annotManager = documentViewer.getAnnotationManager();
    const zoom = documentViewer.getZoomLevel();
    const stampAnnotation = createAnnotationBlock(viewerInstance, dropPoint, type, zoom);

    if (!stampAnnotation) return;

    setAnnotationParametersByAnnotationType(stampAnnotation, type, viewerInstance);

    stampAnnotation.setCustomData('signerId', signerId);
    stampAnnotation.setCustomData('annotationType', type);
    stampAnnotation.setCustomData('signerEmail', value);

    annotManager.deselectAllAnnotations();
    annotManager.addAnnotation(stampAnnotation, {});
    annotManager.redrawAnnotation(stampAnnotation);
    annotManager.selectAnnotation(stampAnnotation);
  };

  const handleUpdateAnnotations = async (signerId: string, email: string) => {
    if (!viewerInstance) return;

    const {
      Core: { documentViewer, annotationManager },
    } = viewerInstance;
    const annotManager = documentViewer.getAnnotationManager();

    const documentAnnotations = annotManager.getAnnotationsList();

    const signersAnnotations = documentAnnotations.filter(annot => {
      const annotationSignerId = annot.getCustomData('signerId');

      return annotationSignerId === signerId;
    });

    signersAnnotations.forEach(annot => {
      annot.setCustomData('signerEmail', email);

      annotManager.redrawAnnotation(annot);
    });

    const annotationsString = await annotationManager.exportAnnotations({
      links: false,
      widgets: false,
      fields: false,
    });

    await saveAnnotations({
      variables: { documentId, xfdfString: annotationsString },
    });
  };

  const handleDeleteAnnotations = (signerId: string) => {
    if (!viewerInstance) return;

    const {
      Core: { documentViewer },
    } = viewerInstance;
    const annotManager = documentViewer.getAnnotationManager();

    const documentAnnotations = annotManager.getAnnotationsList();

    const signersAnnotations = documentAnnotations.filter(annot => {
      const annotationSignerId = annot.getCustomData('signerId');

      return annotationSignerId === signerId;
    });

    annotManager.deleteAnnotations(signersAnnotations);
  };

  const drop = (e: any) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer',
        initialDoc: pdfUrl,
        extension: getExtensionByContentType(documentContentType as ImportedDocumentSupportedContentTypes),
        licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
        filename: documentTitle,
        css: `${process.env.PUBLIC_URL}/apryseStyles.css`,
      },
      viewer.current as HTMLDivElement
    ).then(async instance => {
      instance.UI.disableElements(['settingsButton']);
      const {
        Core: { documentViewer, Annotations },
      } = instance;
      instance.UI.setToolbarGroup('toolbarGroup-View');
      const annotationManager = documentViewer.getAnnotationManager();
      if (isDocumentLocked || !permissions.hasEditPermission) {
        annotationManager.enableReadOnlyMode();
      }

      instance.UI.setAnnotationContentOverlayHandler(annotation => {
        if (annotation.Subject === AnnotationType.STRIKEOUT) {
          return;
        }
        return <CustomAnnotationPopup annotation={annotation} />;
      });

      if (isDocumentLocked || !permissions.hasEditPermission) {
        annotationManager.enableReadOnlyMode();
        annotationManager.disableRedaction();
        annotationManager.disableDraggingAcrossPages();
      }

      if (annotationsStringUrl) {
        await documentViewer.setDocumentXFDFRetriever(async () => {
          const xfdf = await fetch(annotationsStringUrl);

          return await xfdf.text();
        });
      }

      annotationManager.addEventListener('annotationChanged', async (annotations, action, info) => {
        if (info.imported) {
          annotations.forEach(async (annot: any) => {
            if (annot instanceof Annotations.SignatureWidgetAnnotation) {
              annot.hidden = true;
            }
          });
          return;
        }

        if (!permissions.hasEditPermission || isDocumentLocked) {
          return;
        }

        if (action === 'add') {
          annotations.forEach((annotation: Core.Annotations.Annotation) => {
            if (annotation.Subject === 'Stamp') {
              const annotationType = annotation.getCustomData('annotationType') as AnnotationTypes;
              annotation.disableRotationControl();
              annotation.MaintainAspectRatio = [AnnotationTypes.SIGNATURE, AnnotationTypes.INITIALS].includes(
                annotationType
              );

              annotation.LockedContents = [AnnotationTypes.DATE, AnnotationTypes.TEXT].includes(annotationType);
            }
          });
        }
        const annotationsString = await annotationManager.exportAnnotations({
          links: false,
          widgets: false,
          fields: false,
        });

        await saveAnnotations({
          variables: { documentId, xfdfString: annotationsString },
        });
      });

      setNecessaryHeaderItems({
        instance,
        isSigningProcessStarted,
        isDocumentCreator,
        hasUserEditPermission: false,
        hasUserCommentPermission: false,
      });
      disableUiFeatures(instance);
      if (!isDocumentCreator || !permissions.hasEditPermission || isSigningProcessStarted) {
        disableFormFieldsInDocument(instance);
      }
      instance.UI.disableReplyForAnnotations((annot: any) => {
        if (annot.Subject === AnnotationType.STRIKEOUT) {
          return true;
        }
        return false;
      });

      instance.UI.updateElement('annotationCommentButton', {
        style: {
          display: 'none',
        },
      });

      instance.UI.disableElements(['textPopup']);

      setViewerInstance(instance);

      const iframeDoc = instance.UI.iframeWindow.document.body;
      iframeDoc.addEventListener('dragover', dragOver);
      iframeDoc.addEventListener('drop', drop);

      for (const hotkey in instance.UI.hotkeys.Keys) {
        instance.UI.hotkeys.off(hotkey);
      }
      setCustomSettingsApplied(true);
    });

    return () => {
      if (!viewerInstance) return;

      const iframeDoc = viewerInstance.UI.iframeWindow.document.body;
      iframeDoc.removeEventListener('dragover', dragOver);
      iframeDoc.removeEventListener('drop', drop);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- should be called only once after mount

  return (
    <div className={styles.wrapper}>
      <LeaseDocumentWizardHeaderContainer
        canEdit={false}
        onClose={handleClose}
        title={documentTitle}
        titleBadge={<LeaseBadgeContainer />}
        lastSaved={<LeaseDocumentWizardHeaderLastSaved datetime={lastUpdated} intervalInSecond={60} />}
        canShare={false}
        canRequestInternalApproval={false}
        documentId={documentId}
        dealId={dealId}
        teamId={teamId}
        permissions={permissions}
        isDocumentLocked={isDocumentLocked}
      />
      <div className="d-flex overflow-hidden w-100">
        <LeaseWizardSideNav disableClose isCounterparty={!isDocumentCreator} onClose={handleClose}>
          <aside>
            <SignersSidebar
              permissions={permissions}
              addField={addField}
              updateAnnotations={handleUpdateAnnotations}
              deleteAnnotations={handleDeleteAnnotations}
              shouldShowInsertionUi
              isDocumentLocked={isDocumentLocked}
              viewer={viewer.current}
            />
          </aside>
        </LeaseWizardSideNav>
        <div
          className={classNames(styles.webviewer, styles['webviewer_view-uploaded-prepare-to-signing'], {
            [styles['webviewer-receiver']]: !isDocumentCreator,
            [styles['webviewer-display']]: customSettingsApplied,
          })}
          ref={viewer}
        />
      </div>
    </div>
  );
};

export default PrepareToSigning;
