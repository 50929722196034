import { EditorType } from '../../../components/lease-wizard/lease-editor/utils/lease-editor.types';
import { setDocumentPlaceholderRefreshKey } from '../../../store/lease-document/actions/lease-document.actions';
import { selectDocumentPlaceholderRefreshKey } from '../../../store/lease-document/selectors/lease-document.selectors';
import store from '../../../store/store.js';

/**
 * Properties required for adding a placeholder
 */
interface AddPlaceholderProps {
  isRequired: boolean;
  title: string;
}

/**
 * Properties required for removing a placeholder
 */
interface RemovePlaceholderProps {
  linkableType: "DocumentTemplate" | "Document";
  linkableId: string;
  placeholderUuid: string;
}

/**
 * Properties required for updating a placeholder
 */
interface UpdatePlaceholderProps {
  isRequired: boolean;
  linkableId: string;
  linkableType: "DocumentTemplate" | "Document";
  placeholderUuid: string;
  status: string;
  teamId: string;
  title: string;
}

/**
 * Function to create a Placeholder Adapter
 *
 * @param teamId The ID of the team; can be null but should always be a value is authenticated user loads the editor
 * @param linkableId The ID of the linkable entity; can be undefined but should be defined for authenticated users
 * @param createDocumentPlaceholder Function to create a document placeholder
 * @param updateDocumentPlaceholder Function to update a document placeholder
 * @returns A class that acts as a Placeholder Adapter to be used by the Placeholder plugin in our custom CKE fork
 */
export default function createPlaceholderAdapter(
  teamId: string | null,
  linkableId: string | undefined,
  createDocumentPlaceholder: Function,
  updateDocumentPlaceholder: Function,
) {
  /**
   * Placeholder Adapter class that contains methods to add, update, and remove placeholders
   */
  return class PlaceholderAdapter {
    private editor: EditorType;

    /**
     * Constructor to initialize the Placeholder Adapter with an editor instance
     *
     * @param editor Instance of the editor
     */
    constructor(editor: EditorType) {
      this.editor = editor;
    }

    /**
     * Defines the name of the plugin
     */
    static get pluginName() {
      return 'PlaceholderAdapter';
    }

    /**
     * Defines the required dependencies for this plugin
     */
    static get requires() {
      return ['Placeholder'];
    }

    /**
     * Adapter object containing methods to add, update, and remove placeholders
     */
    adapter = {
      /**
       * Method to add a new placeholder
       *
       * @param params Parameters for adding a placeholder
       * @returns The newly created placeholder data
       */
      addPlaceholder: async ({ isRequired, title }: AddPlaceholderProps) => {
        const createdPlaceholder = await createDocumentPlaceholder({
          variables: {
            isRequired,
            linkableId,
            linkableType: 'DocumentTemplate',
            teamId,
            title,
          },
        });

        const prevKey = selectDocumentPlaceholderRefreshKey(store.getState()) || 0;

        store.dispatch(setDocumentPlaceholderRefreshKey(prevKey + 1));

        return createdPlaceholder?.data?.createDocumentPlaceholder;
      },

      /**
       * Method to update an existing placeholder
       *
       * @param params Parameters for updating a placeholder
       * @returns The updated placeholder data
       */
      updatePlaceholder: async ({
        isRequired,
        linkableType,
        placeholderUuid,
        status,
        title
      }: UpdatePlaceholderProps) => {
        const updatedPlaceholder = await updateDocumentPlaceholder({
          variables: {
            isRequired,
            linkableId,
            linkableType,
            placeholderUuid,
            status,
            teamId,
            title,
          },
        });

        const prevKey = selectDocumentPlaceholderRefreshKey(store.getState()) || 0;

        store.dispatch(setDocumentPlaceholderRefreshKey(prevKey + 1));

        return updatedPlaceholder?.data?.updateDocumentPlaceholder;
      },
    };

    /**
     * Initialize the Placeholder Adapter by setting the adapter on the Placeholder plugin
     */
    init = () => {
      const placeholderPlugin = this.editor.plugins.get('Placeholder');
      if (!placeholderPlugin) {
        return;
      }

      // Set the adapter for the Placeholder plugin
      placeholderPlugin.adapter = this.adapter;

      return Promise.resolve();
    }
  }
}
