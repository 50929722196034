export const draftShareString =  " - DraftShare";

export const saveDocumentVersionOnPossessionPass = async ({
  success,
  possessionTeamName,
  sentForExternalReview = false,
}: {
  success: boolean;
  possessionTeamName?: string;
  sentForExternalReview?: boolean;
}) => {
  const revisionTracker = window.editor.plugins.get('RevisionTracker');
  let sharingString = `Version Shared by ${possessionTeamName}`;
  let possessionString = `Draft Prepared for Sharing by ${possessionTeamName}`;
  if (sentForExternalReview) {
    sharingString = possessionString + draftShareString;
  }
  return await revisionTracker.saveRevision({ name: success ? sharingString : '' });
};
