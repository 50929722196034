import { ApolloClient } from '@apollo/client';
import { getDocumentsByDeal } from '../../../api/queries_v2';
import { LeaseDocumentType } from '../../../components/lease-wizard/lease-document.types';
import { graphql } from '../../../gql';

type ResponsePayloadType = {
  id: string;
  document: LeaseDocumentType;
};

interface TemplateDetails {
  dealId: string;
  templateId?: string;
  title: string;
}

export const createDocument = (templateDetails: TemplateDetails) => (
  client: ApolloClient<object>
): Promise<ResponsePayloadType> => {
  const { dealId } = templateDetails;
  const variables: TemplateDetails = {
    dealId,
    templateId: templateDetails.templateId,
    title: templateDetails.title,
  };
  const mutation = graphql(/* GraphQL */`
    mutation CreateDocument($dealId: ID!, $templateId: ID, $title: String!) {
      createDocument(dealId: $dealId, templateId: $templateId, title: $title) {
        id
        status
        document
        title
        lastUpdated
        team {
          id
        }
      }
    }
  `);

  const refetchQueries = [
    {
      query: getDocumentsByDeal,
      variables: {
        dealId,
      },
    },
  ];

  return client
    .mutate({ mutation, variables, refetchQueries })
    .then(({ data }: Record<any, any>) => data.createDocument);
};
