import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

const TRACK_CHANGES_BUTTON_LABEL = "Track changes";

export const getCreatorPermissionsEditing = (
  isCreator,
  permissions,
  isShared,
  documentStatus
) => {
  class CreatorPermissionsEditing extends Plugin {
    static get requires() {
      return ["TrackChanges"];
    }

    init() {
      this.editor.on("ready", () => {
        if (!permissions.hasApprovePermission) {
          const { commands } = this.editor;

          // disable all commands from track changes dropdown
          commands.get("acceptSuggestion") &&
            commands
              .get("acceptSuggestion")
              .forceDisabled("singleSuggestionDisable");
          commands.get("acceptAllSuggestions") &&
            commands
              .get("acceptAllSuggestions")
              .forceDisabled("allSuggestionsDisable");
          commands.get("discardSuggestion") &&
            commands
              .get("discardSuggestion")
              .forceDisabled("singleSuggestionDiscardDisable");
          commands.get("discardAllSuggestions") &&
            commands
              .get("discardAllSuggestions")
              .forceDisabled("multipleSuggestionsDiscard");
        }

        const trackChangesToolbarItem = this.editor.ui.view.toolbar.items.find(
          (item) =>
            item.buttonView &&
            item.buttonView.label === TRACK_CHANGES_BUTTON_LABEL
        );

        if (!trackChangesToolbarItem) return;

        trackChangesToolbarItem.isOpen = true;
        trackChangesToolbarItem.isOpen = false;

        // dig into cke layout and disable trackChanges toggle in the dropdown
        const dropdownPanelView = trackChangesToolbarItem.template.children[1];
        const dropdownListView = dropdownPanelView.children._items[0];

        const dropdownViewCollection = dropdownListView.template.children[0];
        const dropdownListItemView = dropdownViewCollection._items[0];

        const dropdownListViewCollection =
          dropdownListItemView.template.children[0];
        const switchButtonView = dropdownListViewCollection._items[0];
        dropdownViewCollection._items.forEach((item) => {
          if (!item.template.children.length) return;
          const itemLabel = item.template.children[0]._items[0].label;
          item.template.children[0]._items[0].label = itemLabel.includes(
            "Discard"
          )
            ? itemLabel.replace("Discard", "Reject")
            : itemLabel;
        });

        if (isShared) {
          // execute the command to turn on track changes functionality
          this.editor.commands.get("trackChanges").execute();

          trackChangesToolbarItem.buttonView.actionView.isEnabled = false;
          switchButtonView.isEnabled = false;

          if (documentStatus === "reviewing") {
            /*
              Should rename 'accept/discard all suggestions'
              into 'accept all Counterparty suggestions'
               */
            dropdownViewCollection._items.forEach((item) => {
              if (!item.template.children.length) return;

              const itemLabel = item.template.children[0]._items[0].label;

              item.template.children[0]._items[0].label = itemLabel.includes(
                "selected"
              )
                ? itemLabel
                : itemLabel.replace("suggestions", "Counterparty suggestions");
            });
          }
        }
      });
    }
  }
  return CreatorPermissionsEditing;
};
export default getCreatorPermissionsEditing;
