import React, { ChangeEvent, forwardRef, useState } from 'react';
import { Checkbox } from '../../checkbox-or-radio-field';
import { FormattedMessage } from 'react-intl';
import SearchInput from '../../search-input';
import { DropdownItem } from '../../dropdown';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { filterDealBy } from '../helpers';
import { ReactComponent as DangerIcon } from '../../../shared/icons/close-icon.svg';

const DealsDropdown = forwardRef(
  (
    {
      children,
      dropdownId,
      isWhitelisted,
      onWhitelistChange,
      whitelistChecked,
      whitelistDisabled,
      whitelistValue,
      whitelistName,
      onDealChange,
      dealDisabled,
      dealChecked,
      dealName,
      dealValue,
      style,
      className,
      'aria-labelledby': ariaLabelledby,
      onClick,
      readOnly = false,
      checkboxComponent: CheckboxComponent = Checkbox,
    }: any,
    ref: any
  ) => {
    const [query, setQuery] = useState('');

    const renderWhitelistCheckbox = () => (
      <DropdownItem highlightable as="li">
        <CheckboxComponent
          type="checkbox"
          disabled={whitelistDisabled}
          id="select-all-deals"
          value={whitelistValue}
          name={whitelistName}
          onChange={onWhitelistChange}
          checked={whitelistChecked}
          label={
            <FormattedMessage
              id="phrase.select-all-deals"
              defaultMessage="Select all deals ({totalCount, number})"
              values={{
                totalCount: children.length,
              }}
            />
          }
        />
      </DropdownItem>
    );

    const renderDealCheckboxes = () => (
      <>
        {children
          .filter((deal: any) => filterDealBy(deal, query.trim()))
          .map((deal: any) => {
            const { title } = deal;
            return (
              <DropdownItem key={deal.id} highlightable as="li">
                <CheckboxComponent
                  type="checkbox"
                  id={`${dropdownId}.select-deal.${deal.id}`}
                  disabled={dealDisabled}
                  value={dealValue(deal)}
                  checked={dealChecked(deal)}
                  onChange={(e: any) => onDealChange(e, deal)}
                  label={title}
                />
              </DropdownItem>
            );
          })}
      </>
    );

    const onClose = () => {
      document.getElementById(dropdownId)?.click();
    };

    return (
      <div
        ref={ref}
        style={style}
        className={classNames(className, styles.selectDealDropdownContainer)}
        aria-labelledby={ariaLabelledby}
        onClick={onClick}
      >
        <div className={classNames(styles.selectDealDropDown, 'non-mobile-flex bg-light align-items-center')}>
          <SearchInput
            autoFocus
            className="w-auto"
            placeholder="Search Deals"
            /*

            //@ts-ignore  */
            style={{ flex: 'auto' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value)}
            value={query}
            id={`${dropdownId}-query`}
          />
        </div>
        <div className="mobile-flex">
          <div className={classNames(styles.selectDealsDropdown, 'mobile-flex')}>
            <h4>Select Deals</h4>
          </div>
        </div>
        <ul className={classNames(styles.resultsList, 'list-unstyled m-0')}>
          {renderWhitelistCheckbox()}
          {renderDealCheckboxes()}
        </ul>
      </div>
    );
  }
);

export default DealsDropdown;
