import React, { ExoticComponent, Fragment, ReactNode, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Group from 'react-bootstrap/Form';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { FormikErrors, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import SubmitButton from '../components/submit-button';
import ErrorMessage from '../components/error-message';
import { FieldLabel } from '../components/fields/base';

interface EditExpiryDateProps {
  bodyContainer: ExoticComponent<{ children?: ReactNode }>;
  actionsContainer: ExoticComponent<{ children?: ReactNode }>;
  renderSecondaryAction: any;
  errors: FormikErrors<any>;
  values: { date: string };
  setFieldValue: (name: string, value: string) => void;
  isSubmitting: boolean;
  isValid: boolean;
  validateForm: ()=>void
}

const EditExpiryDateForm = ({
  bodyContainer: BodyContainer = Fragment,
  actionsContainer: ActionsContainer = Fragment,
  renderSecondaryAction,
  errors,
  isSubmitting,
  isValid,
  values,
  setFieldValue,
                              validateForm
}: EditExpiryDateProps) => {
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <Form noValidate>
      <BodyContainer>
        {errors._general && (
          <Alert variant="danger">
            <ErrorMessage>{errors._general}</ErrorMessage>
          </Alert>
        )}

        <Row>
          <Col lg={12}>
            <Group>
              <FieldLabel>Expiry Date</FieldLabel>
              <SingleDatePicker
                date={values.date ? moment(values.date) : null}
                onDateChange={date => setFieldValue('date', date ? date.format('ll') : '')}
                focused={focus}
                onFocusChange={({ focused }) => setFocus(focused)}
                numberOfMonths={1}
                displayFormat="DD-MMM-YYYY"
                showClearDate={true}
                block
                small
                id={'expiry-date'}
              />
            </Group>
          </Col>
        </Row>
      </BodyContainer>

      <ActionsContainer>
        {renderSecondaryAction && renderSecondaryAction()}
        <SubmitButton
          label={<FormattedMessage id="id.save-expiry-date" defaultMessage="Save" />}
          disabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
        />
      </ActionsContainer>
    </Form>
  );
};

export default EditExpiryDateForm;
