import { ApolloClient } from '@apollo/client';
import { DocumentPayload } from './lease-document-interface.types';
import { queriesV2 } from '../../api';
import { graphql } from '../../gql';

export const cancelSigningDocument = ({
  documentId,
  additionalMessage,
}: {
  documentId: string;
  additionalMessage?: string;
}) => (client: ApolloClient<object>): Promise<DocumentPayload> => {
  const mutation = graphql(/* GraphQL */`
    mutation CancelSigningDocument($documentId: ID!, $additionalMessage: String) {
      cancelSigningDocument(documentId: $documentId, additionalMessage: $additionalMessage) {
        id
        status
        document
        title
        lastUpdated
        eversignStatus
        receiverTeam {
          id
        }
        team {
          id
        }
        deal {
          id
          title
        }
      }
    }
  `);

  return client
    .mutate({
      mutation,
      variables: { documentId, additionalMessage },
      refetchQueries: [{ query: queriesV2.getDocumentById, variables: { documentId } }],
    })
    .then(({ data }: Record<any, any>) => {
      return data.cancelSigningDocument;
    });
};
