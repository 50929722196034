import { DocumentTemplateType } from '../../shared/templateType';

export const templateStatusMessages = {
  active: { id: 'template-status.active', defaultMessage: 'Active' },
  archived: { id: 'template-status.archived', defaultMessage: 'Archived' },
};

export const statusOptions = [
  { value: 'active', label: templateStatusMessages.active },
  { value: 'archived', label: templateStatusMessages.archived },
];

export const templateTypeMessages = {
  ['PDF']: { id: 'template-type.PDF', defaultMessage: 'PDF' },
  ['Fully Editable']: { id: 'template-type.FULLY_EDITABLE', defaultMessage: 'Fully Editable' },
  ['Restricted']: { id: 'template-type.RESTRICTED', defaultMessage: 'Restricted' },
};

export const templateTypeOptions = [
  { value: DocumentTemplateType.PDF, label: templateTypeMessages['PDF'] },
  { value: DocumentTemplateType.FULLY_EDITABLE, label: templateTypeMessages['Fully Editable'] },
  { value: DocumentTemplateType.RESTRICTED, label: templateTypeMessages['Restricted'] },
];
