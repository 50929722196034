import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { LeaseBadgeContainer } from '../../../components/lease-wizard/lease-badge-container/LeaseBadgeContainer';
import {
  DocumentPermissions,
  useLeaseDocumentTeamPermissions,
} from '../shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { LeaseDocumentWizardHeaderContainer } from '../../../components/lease-wizard/lease-document-wizard-header/LeaseDocumentWizardHeaderContainer';
import { LeaseDocumentWizardHeaderLastSaved } from '../../../components/lease-wizard/lease-document-wizard-header/lease-document-wizard-header-lastsaved/LeaseDocumentWizardHeaderLastSaved';
import LeaseWizardSideNav from '../../../components/lease-wizard/lease-wizard-sidenav/leaseWizardSideNav';
import { CommentContextProvider } from '../../../contexts/commentContext';
import { LeaseEditorContainer } from '../../../components/lease-wizard/lease-editor/LeaseEditor';
import { SignersSidebar } from '../../../components/lease-wizard/lease-editor/components/signersSidebar';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';
import {
  selectDocumentApprovalRequest,
  selectDocumentCompareModeEnabled,
  selectDocumentRestrictedEditingMode, selectDocumentSessionActivities,
  selectIsDocumentEditable,
  selectIsLeaseEditorLoaded,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import { DocumentSourceEnum, givePossessionSuccessMessage, successSendDocumentMessage } from '../../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { UploadedDocumentsViewer } from '../../uploadedWebViewer/uploadedViewer';
import { DocumentSubheaderContainer } from '../../../components/lease-wizard/lease-document-wizard-header/DocumentSubheaderContainer';
import '../shared/lease-document-wizard-page.scss';
import { ApprovalRequestStatus } from '../../../components/lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/RequestInternalApprovalModalConstants';
import DocumentActivitiesAndTemplatesSidenav from '../../../components/lease-wizard/documentActivitiesAndTemplatesNavigation/documentActivitiesAndTemplatesSidenav';
import AddHeaderAndFooterModalContainer from '../../deals/modals/AddHeaderAndFooterModal';
import { selectIsHeaderFooterEditorModalOpen } from '../../../store/modals/modals.selectors';
import { setShowHeaderFooterEditorModal } from '../../../store/modals/modals.actions';
import { givePossessionToCounterparty } from '../../../utils/api/give-possession-to-counterparty';
import {
  saveDocumentVersionOnPossessionPass
} from '../../../components/lease-wizard/lease-document-wizard-header/lease-document-wizard-header.utils';
import { paths } from '../../../routing';
import { useDocumentRole } from '../../../shared/hooks/useDocumentRole/useDocumentRole';
import { useApolloClient, useQuery } from '@apollo/client';
import { resetSessionActivities } from '../../../store/lease-document/actions/lease-document.actions';
import { useNavigate } from 'react-router-dom';
import { selectCurrentDeal } from '../../../store/deals/deals.selectors';
import { useIntl } from 'react-intl';
import { useNotifications } from '../../../notifications';
import { getDocumentById } from '../../../api/queries_v2';

export interface DocumentPageProps {
  canEdit: boolean;
  canComment: boolean;
  onSave: any;
  onTitleSave: (title: string) => void;
  lastUpdated: string;
  documentTitle: string;
  isDocumentCreator: boolean;
  hasCurrentTeamPossession: boolean;
  document: any;
  documentId: string;
  teamId: any;
  isShared?: boolean;
  canInvite: boolean;
  permissions: DocumentPermissions;
  documentStatus: DocumentStatusEnum;
  handleClose: () => void;
  dealId: string;
  documentSource: DocumentSourceEnum;
  isDocXDocument: boolean;
}

const DocumentPage = ({
  canEdit,
  canComment,
  onSave,
  onTitleSave,
  lastUpdated,
  documentTitle,
  isDocumentCreator,
  hasCurrentTeamPossession,
  document,
  documentId,
  teamId,
  canInvite,
  permissions,
  isShared,
  documentStatus,
  handleClose,
  dealId,
  documentSource,
  isDocXDocument,
}: DocumentPageProps) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDocumentLoaded = useSelector(selectIsLeaseEditorLoaded);
  const isDocumentRestricted = useSelector(selectDocumentRestrictedEditingMode);
  const approvalRequest = useSelector(selectDocumentApprovalRequest);
  const isHeaderFooterEditorModalOpen = useSelector(selectIsHeaderFooterEditorModalOpen);
  const isEditable = useSelector(selectIsDocumentEditable);
  const isCompareModeEnabled = useSelector(selectDocumentCompareModeEnabled);
  const isRequestReviewApproved = approvalRequest?.status === ApprovalRequestStatus.APPROVED;
  const isRequestReviewPending = approvalRequest?.status === ApprovalRequestStatus.PENDING;
  const optionalMessageInputRef = React.useRef<HTMLTextAreaElement>(null);

  const dealUrl = paths.deals.detail.replace(':teamId', teamId as string).replace(':dealId', dealId);
  const { possessionTeamName } = useDocumentRole(documentId);
  const sessionActivities = useSelector(selectDocumentSessionActivities);
  const currentDeal = useSelector(selectCurrentDeal);
  const isDealShared = !!currentDeal?.receiverEmail;
  const { formatMessage } = useIntl();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const { data: documentData, loading: documentLoading } = useQuery(getDocumentById, {
    variables: { documentId: documentId! },
    skip: !documentId,
  });

  const handleModalClose = () => {
    dispatch(setShowHeaderFooterEditorModal(false));
  };

  const canShare = useMemo(() => {
    const isShareableStatus =
      !documentStatus ||
      [
        DocumentStatusEnum.DRAFT,
        DocumentStatusEnum.REVIEWING,
        DocumentStatusEnum.UPLOADED,
        DocumentStatusEnum.SHARED,
      ].includes(documentStatus);
    return canInvite && isShareableStatus;
  }, [canInvite, documentStatus]);

  const canRequestInternalApproval = useMemo(() => {
    const isShareableStatus =
      !documentStatus ||
      [
        DocumentStatusEnum.DRAFT,
        DocumentStatusEnum.REVIEWING,
        DocumentStatusEnum.UPLOADED,
        DocumentStatusEnum.SHARED,
      ].includes(documentStatus);
    return canEdit && isShareableStatus;
  }, [canEdit, documentStatus]);

  const isUploaded = [DocumentStatusEnum.UPLOADED].includes(documentStatus);

  const documentInReview = [DocumentStatusEnum.REVIEWING, DocumentStatusEnum.APPROVAL_PENDING].includes(documentStatus as DocumentStatusEnum)
    || approvalRequest?.status === ApprovalRequestStatus.PENDING
    || (!isRequestReviewPending && !isEditable);

  const isSignersSidebarAccessible = documentStatus === DocumentStatusEnum.PREPARING_TO_SIGN;

  const isClauseTemplatesSidebarAccessible =
    documentStatus === DocumentStatusEnum.DRAFT ||
    (documentStatus === DocumentStatusEnum.UPLOADED && isDocXDocument) ||
    documentStatus === DocumentStatusEnum.REVIEWING ||
    documentStatus === DocumentStatusEnum.EXECUTED;

  const hasInvitePermission = !!permissions?.hasInviteSendingPermission;
  const hasCloseButton =
    !(isDocumentCreator && documentSource === DocumentSourceEnum.APP && isSignersSidebarAccessible) &&
    !(isClauseTemplatesSidebarAccessible && (isDocumentLoaded || documentSource === DocumentSourceEnum.APP));

  const handleSendDocumentMutation = useCallback(async () => {
    const isAppDocument = documentSource === DocumentSourceEnum.APP;

    if (isAppDocument) {
      onSave && (await onSave());
      await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName });
    }

    const additionalMessage = optionalMessageInputRef.current?.value;

    givePossessionToCounterparty(
      documentId,
      additionalMessage,
      sessionActivities
    )(apolloClient)
      .then(() => {
        dispatch(resetSessionActivities());
        navigate(dealUrl);
        isDealShared &&
        notifySuccess({
          message: formatMessage(
            documentStatus === DocumentStatusEnum.DRAFT ? successSendDocumentMessage : givePossessionSuccessMessage
          ),
        });
      })
      .catch(error => {
        if (isAppDocument) {
          saveDocumentVersionOnPossessionPass({ success: false });
        }

        notifyError({ message: error.message });
      });
  }, [
    apolloClient,
    dealUrl,
    documentId,
    documentSource,
    documentStatus,
    formatMessage,
    onSave,
    notifyError,
    notifySuccess,
    possessionTeamName,
    givePossessionToCounterparty,
    isDealShared,
    sessionActivities,
    dispatch,
    navigate,
  ]);

  const isReviewing = documentStatus === DocumentStatusEnum.REVIEWING;
  const isDraft = documentStatus === DocumentStatusEnum.DRAFT;
  const { hasInviteSendingPermission } = useLeaseDocumentTeamPermissions({ teamId, apolloClient });

  return (
    <div className="lease-document-wizard-page">
      <div>
        {isDocumentCreator && documentSource === DocumentSourceEnum.APP && isSignersSidebarAccessible && (
          <LeaseWizardSideNav isCounterparty={!isDocumentCreator} onSave={onSave}>
            <aside
              className={classNames('lease-document-wizard-page__sidebar-layout', {
                'lease-document-wizard-page__sidebar-layout-hidden': !isDocumentCreator,
              })}
            >
              <SignersSidebar permissions={permissions} />
            </aside>
          </LeaseWizardSideNav>
        )}
        {isClauseTemplatesSidebarAccessible && isDocumentLoaded && (
          <DocumentActivitiesAndTemplatesSidenav
            permissions={permissions}
            isDocumentCreator={isDocumentCreator}
            isDocumentRestricted={isDocumentRestricted}
            isCollapsible={!isCompareModeEnabled}
            onClose={handleClose}
            handleSendDocumentMutation={handleSendDocumentMutation}
            canEdit={canEdit}
            isReviewing={isReviewing}
            hasInviteSendingPermission={hasInviteSendingPermission}
            dealId={dealId}
            teamId={teamId}
            documentId={documentId}
            optionalMessageInputRef={optionalMessageInputRef}
            onSave={onSave}
            canShare={canShare}
            isDraft={isDraft}
            isShared={isShared}
            isUploaded={isUploaded}
          />
        )}
      </div>

      <div className="lease-document-wizard-page__full-size-layout">
        <div className="lease-document-wizard-page__header-layout">
          <LeaseDocumentWizardHeaderContainer
            canEdit={canEdit}
            onClose={hasCloseButton ? handleClose : undefined}
            title={documentTitle}
            titleBadge={<LeaseBadgeContainer />}
            onSaveTitle={onTitleSave}
            lastSaved={<LeaseDocumentWizardHeaderLastSaved datetime={lastUpdated} intervalInSecond={60} />}
            canShare={canShare}
            canRequestInternalApproval={canRequestInternalApproval}
            permissions={permissions}
            onSave={onSave}
            documentId={documentId}
            dealId={dealId}
            teamId={teamId}
            documentSource={documentSource}
          />
          {isUploaded && !isDocXDocument && hasInvitePermission && !isRequestReviewPending && (
            <DocumentSubheaderContainer documentId={documentId} dealId={dealId} teamId={teamId} grantAccessSubheader />
          )}
          {isRequestReviewApproved && !isEditable && canEdit && (
            <DocumentSubheaderContainer
              documentId={documentId}
              dealId={dealId}
              teamId={teamId}
              unlockDocumentSubheader
            />
          )}
        </div>
        {(documentSource === DocumentSourceEnum.APP || isDocXDocument) && (
          <CommentContextProvider InitialComments={[]}>
            <LeaseEditorContainer
              documentId={documentId}
              documentType="offer"
              save={onSave}
              leaseDocument={document}
              teamId={teamId}
              showMenu={isDocumentCreator}
              hasCurrentTeamPossession={hasCurrentTeamPossession}
              canEdit={canEdit}
              canComment={canComment}
              permissions={permissions}
              isShared={isShared}
              approvalRequest={approvalRequest}
              documentData={documentData}
              documentLoading={documentLoading}
            />
          </CommentContextProvider>
        )}
        {documentSource === DocumentSourceEnum.IMPORTED && !isDocXDocument && (
          <UploadedDocumentsViewer
            documentId={documentId}
            teamId={teamId}
            userPermissions={permissions}
            hasCurrentTeamPossession={hasCurrentTeamPossession}
            isDocumentInReview={documentInReview}
          />
        )}
      </div>
      <AddHeaderAndFooterModalContainer
        isOpen={isHeaderFooterEditorModalOpen}
        onClose={handleModalClose}
        documentId={documentId}
        teamId={teamId}
        document={document}
        editorConfiguration={documentData?.getDocumentById.editorConfiguration}
      />
    </div>
  );
};

export default DocumentPage;
