import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatFeed, StreamApp } from 'react-activity-feed';
import { connect } from 'getstream';
import { Card } from './index';
import { ActivityCardEnum } from './ActivitiesCardConstants';
import { RenderDealActivityData } from './ActivitiesCardHelper';
import ShowEmailReplyModalContainer from './modals/ShowEmailReplyModalContainer';

type ActivityPropsType = {
  id: string;
  description?: string;
  time?: string;
  actor_data: {
    full_name: string;
    email: string;
  };
  object_data: {
    object_id: string;
  };
  verb: string;
};

type ActivityCardProps = {
  dealData: { [key: string]: any };
  dealDocumentsData?: { [key: string]: any };
  isSidebar?: boolean;
};

const API_KEY = process.env.REACT_APP_GETSTREAM_API_KEY as string;
const APP_ID = process.env.REACT_APP_GETSTREAM_APP_ID as string;
const HEALTH_CHECK_TIMER = 30000;

const ActivitiesCard = ({ dealData, dealDocumentsData, isSidebar }: ActivityCardProps) => {
  const [incomingActivitiesCount, setIncomingActivitiesCount] = useState(0);
  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(null);
  const [fetchedActivities, setFetchedActivities] = useState<any>();
  const [showFeed, setShowFeed] = useState(false);

  const getstreamUserToken = dealData?.activityCardToken;
  const { formatMessage } = useIntl();

  useEffect(() => {
    const fetchActivities = async () => {
      const getStreamActivities = await connect(API_KEY, getstreamUserToken, APP_ID);
      const getStreamActivitiesData = await getStreamActivities.feed('timeline', undefined, getstreamUserToken);
      return getStreamActivitiesData.get();
    };

    fetchActivities().then(data => setFetchedActivities(data.results));
  }, [getstreamUserToken, dealData, dealDocumentsData]);

  useEffect(() => {
    setTimeout(() => {
      setShowFeed(true);
    }, HEALTH_CHECK_TIMER);
  }, []);

  return (
    <Card
      title={!isSidebar && formatMessage({ id: ActivityCardEnum.ID, defaultMessage: ActivityCardEnum.DEFAULT_MESSAGE })}
      isBlue={!isSidebar}
      isSidebar={isSidebar}
      isActivitiesCard
      isFullHeight
      width={'4'}
      className={!isSidebar ? 'mr-3 ml-1 non-mobile' : 'mobile'}
    >
      {!showFeed ? (
        fetchedActivities &&
        fetchedActivities.map((activity: ActivityPropsType) =>
          RenderDealActivityData(activity, !!isSidebar, (id: string | null) => {
            setSelectedActivityId(id);
          })
        )
      ) : (
        <StreamApp apiKey={API_KEY} appId={APP_ID} token={getstreamUserToken}>
          <FlatFeed
            notify={true}
            options={{ limit: 100 }}
            Notifier={props => {
              const activitiesCount = props.adds?.length as number;
              if (activitiesCount !== incomingActivitiesCount) {
                //@ts-ignore
                props.onClick();
                setIncomingActivitiesCount(activitiesCount);
              }
              return <></>;
            }}
            Activity={props => {
              //@ts-ignore
              const activityProps = props.activity as ActivityPropsType;
              return RenderDealActivityData(activityProps, !!isSidebar, (id: string | null) => {
                setSelectedActivityId(id);
              });
            }}
            feedGroup="timeline"
          />
        </StreamApp>
      )}
      <ShowEmailReplyModalContainer
        isOpen={!!selectedActivityId}
        onClose={() => {
          setSelectedActivityId(null);
        }}
        activityId={selectedActivityId}
      />
    </Card>
  );
};

export default ActivitiesCard;
