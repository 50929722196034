import Send from './send';
import SendModal from './sendModal';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DocumentStatusEnum } from '../../../../../../shared/constants/leaseDocument';
import {
  selectDocumentEditorSuggestions,
  selectDocumentSessionActivities,
  selectDocumentSource,
  selectLeaseDocumentStatus,
} from '../../../../../../store/lease-document/selectors/lease-document.selectors';
import { useLeaseDocumentTeamPermissions } from '../../../../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { selectCurrentDeal } from '../../../../../../store/deals/deals.selectors';
import { sendDocumentToReceiver } from '../../../../../../utils/api/send-document-to-receiver';
import { givePossessionToCounterparty } from '../../../../../../utils/api/give-possession-to-counterparty';
import { saveDocumentVersionOnPossessionPass } from '../../../lease-document-wizard-header.utils';
import {
  DocumentSourceEnum,
  givePossessionSuccessMessage,
  ShareWithUnresolvedSuggestionsWarning,
  successSendDocumentMessage,
} from '../../../../lease-editor/constants/EditorConstats';
import { useDocumentRole } from '../../../../../../shared/hooks/useDocumentRole/useDocumentRole';
import { useNotifications } from '../../../../../../notifications';
import { paths } from '../../../../../../routing';
import { selectIsSendDocumentModalOpen } from '../../../../../../store/modals/modals.selectors';
import { Button, Dropdown } from 'react-bootstrap';
import { setClosePendingWarningReviewModal, setShowSendDocumentModal } from '../../../../../../store/modals/modals.actions';
import { resetSessionActivities } from '../../../../../../store/lease-document/actions/lease-document.actions';
import PlaceholderWarningModal from '../../../../lease-document-wizard-modal/custom-modals/placeholderWarningModal/placeholderWarningModal';


interface Props {
  teamId: string;
  documentId: string;
  dealId: string;
  handleSaveDocument: (() => Promise<void>) | undefined;
  isDropdownOption?: boolean;
  showCounterparty?: boolean;
  counterpartyEmail?: string;
  counterpartyName?: string;
  placeholdersToComplete?: number;
}

const SendContainer = ({ handleSaveDocument, documentId, dealId, teamId, isDropdownOption, showCounterparty, counterpartyEmail, counterpartyName, placeholdersToComplete = 0}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();

  const isSendDocumentModalOpen = useSelector(selectIsSendDocumentModalOpen);

  const sessionActivities = useSelector(selectDocumentSessionActivities);

  const hasRequiredPlaceholders = placeholdersToComplete !== 0;

  const [showModal, setShowModal] = useState(isSendDocumentModalOpen && !hasRequiredPlaceholders);

  const [showWarningModal, setShowWarningModal] = useState(isSendDocumentModalOpen && hasRequiredPlaceholders);

  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const optionalMessageInputRef = React.useRef<HTMLTextAreaElement>(null);
  const documentSource = useSelector(selectDocumentSource);
  const { formatMessage } = useIntl();
  const apolloClient = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const { hasInviteSendingPermission } = useLeaseDocumentTeamPermissions({ teamId, apolloClient });
  const currentDeal = useSelector(selectCurrentDeal);
  const isDealShared = !!currentDeal?.receiverEmail;
  const isDocumentInDraft = [DocumentStatusEnum.DRAFT, DocumentStatusEnum.UPLOADED, DocumentStatusEnum.SHARED].includes(
    documentStatus as DocumentStatusEnum
  );
  const { possessionTeamName, userRole } = useDocumentRole(documentId);

  const dealUrl = paths.deals.detail.replace(':teamId', teamId as string).replace(':dealId', dealId);

  useSelector(selectDocumentEditorSuggestions); // using to subscribe to store updates for editor suggestions


  const [confirmSend, setConfirmSend] = useState(false)

  const canShare = useMemo(() => {
    if (!documentStatus) return true;

    const isShareableStatus = [
      DocumentStatusEnum.DRAFT,
      DocumentStatusEnum.REVIEWING,
      DocumentStatusEnum.UPLOADED,
      DocumentStatusEnum.SHARED,
    ].includes(documentStatus as DocumentStatusEnum);

    return hasInviteSendingPermission && isShareableStatus;
  }, [hasInviteSendingPermission, documentStatus]);

  const sentForExternalReview = useMemo(() => {
    return [DocumentStatusEnum.DRAFT].includes(documentStatus as DocumentStatusEnum)
  }, [documentStatus])

  const sendDocumentMutationToRun = isDocumentInDraft ? sendDocumentToReceiver : givePossessionToCounterparty;

  const handleSendDocumentMutation = useCallback(async () => {
    const isAppDocument = documentSource === DocumentSourceEnum.APP;

    if (isAppDocument) {
      handleSaveDocument && (await handleSaveDocument());
      sentForExternalReview && await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName, sentForExternalReview });
      await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName });
    }

    const additionalMessage = optionalMessageInputRef.current?.value;

    setTimeout(async () => {
      await sendDocumentMutationToRun(
        documentId,
        additionalMessage,
        sessionActivities
      )(apolloClient)
        .then(() => {
          dispatch(resetSessionActivities());
          navigate(dealUrl);
          isDealShared &&
          notifySuccess({
            message: formatMessage(
              documentStatus === DocumentStatusEnum.DRAFT ? successSendDocumentMessage : givePossessionSuccessMessage
            ),
          });
        })
        .catch(error => {
          if (isAppDocument) {
            saveDocumentVersionOnPossessionPass({ success: false });
          }

          notifyError({ message: error.message });
        });
    }, 2000);
  }, [
    documentSource,
    sendDocumentMutationToRun,
    documentId,
    sessionActivities,
    apolloClient,
    handleSaveDocument,
    sentForExternalReview,
    possessionTeamName,
    dispatch,
    navigate,
    dealUrl,
    isDealShared,
    notifySuccess,
    formatMessage,
    documentStatus,
    notifyError,
    placeholdersToComplete
  ]);

  const handleSendModalHide = () => {
    setShowModal(false);
    dispatch(setShowSendDocumentModal(false));
  };

  const handleSendDocument = useCallback(async () => {
    setIsLoading(true);

    if (documentSource === DocumentSourceEnum.IMPORTED) {
      return handleSendDocumentMutation();
    }

    const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

    if (!documentPendingActionsPlugin?.hasAny) {
      return handleSendDocumentMutation();
    }

    documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
      if (!documentPendingActionsPlugin.hasAny) {
        event.off();
        event.stop();
        return handleSendDocumentMutation();
      }
    });
  }, [documentSource, handleSendDocumentMutation]);

  const pendingSamePartySuggestions = () => {
    if (window.editor?.state === 'destroyed') return;

    const suggestions =
      window.editor &&
      window.editor.plugins
        .get('TrackChanges')
        .getSuggestions()
        .filter((item: any) => item.isInContent);

    const counterpartySuggestions = suggestions?.filter((suggestion: any) => {
      return suggestion.attributes.authorRole && suggestion.attributes.authorRole !== userRole;
    });

    return counterpartySuggestions?.length;
  };

  const getPendingSuggestionsAlert = () => {
    const suggestionsNumber = pendingSamePartySuggestions();

    if (!suggestionsNumber) return null;
    return {
      text: ShareWithUnresolvedSuggestionsWarning.text.replace('{num}', `${suggestionsNumber}`),
      title: ShareWithUnresolvedSuggestionsWarning.title,
    };
  };

  return (
    <>
      {!isDropdownOption && <Send disabled={!canShare} onClick={() => setShowModal(true)} />}
      {isDropdownOption && (
        <Dropdown.Item as={Button} variant="link" onClick={() => {
          if (placeholdersToComplete == 0) {
            setShowWarningModal(false);
            setShowModal(true);
          }
          else {
            setShowWarningModal(true);
            setShowModal(false);
          }
        }} 
        disabled={!canShare}>
          {isDocumentInDraft ? 'via Propdocs' : 'Review'}
        </Dropdown.Item>
      )}
      <PlaceholderWarningModal
        isOpen={showWarningModal}
        placeholders={placeholdersToComplete}
        onContinue={() => {
          setShowWarningModal(false);
          setConfirmSend(true);
          setShowModal(true);
        }}
        onHide={() => setShowWarningModal(false)}
        onReview={() => setShowWarningModal(false)}
      />

      <SendModal
        show={showModal}
        onHide={handleSendModalHide}
        isDealShared={isDealShared}
        handler={handleSendDocument}
        isLoading={isLoading}
        suggestionsAlert={getPendingSuggestionsAlert?.()}
        showCounterparty={showCounterparty}
        counterpartyEmail={counterpartyEmail}
        counterpartyName={counterpartyName}
        optionalMessageInputRef={optionalMessageInputRef}
        teamId={teamId}
      />
    </>
  );
};

export default SendContainer;
