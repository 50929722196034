import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useDocumentRole } from '../../../../shared/hooks/useDocumentRole/useDocumentRole';
import RequestReviewContainer from '../components/headerActions/requestReview/RequestReviewContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsDocumentEditable,
  selectLeaseDocumentStatus,
} from '../../../../store/lease-document/selectors/lease-document.selectors';
import { selectIsPendingReviewWarningModalClosed } from '../../../../store/modals/modals.selectors';
import { setClosePendingWarningReviewModal } from '../../../../store/modals/modals.actions';
import { DocumentStatusEnum } from '../../../../shared/constants/leaseDocument';
import styles from './pendingReviewWarningModal.module.scss';

interface PendingReviewWarningProps {
  showModal: boolean;
  hideModal: (set: boolean) => void;
  onCloseDocument: (() => void) | undefined;
  canEdit: boolean;
  dealId: string;
  teamId: string;
  documentId: string;
  dealTeamsData: any;
  onSave: (() => Promise<void>) | undefined;
  canShare: boolean;
  shouldShowInternalSendForSigning: boolean | null | undefined;
  shouldShowSendForSigning: boolean | null | undefined;
}
const PendingReviewWarningModal = ({
  showModal,
  hideModal,
  onCloseDocument,
  dealId,
  teamId,
  documentId,
  canEdit,
  dealTeamsData,
  onSave,
  canShare,
  shouldShowInternalSendForSigning,
  shouldShowSendForSigning,
}: PendingReviewWarningProps) => {
  const { formatMessage } = useIntl();

  const { isDocumentCreator } = useDocumentRole(documentId);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const isDocumentEditable = useSelector(selectIsDocumentEditable);
  const isDraft = documentStatus === DocumentStatusEnum.DRAFT;
  const isUploaded = documentStatus === DocumentStatusEnum.UPLOADED;
  const isShared = documentStatus === DocumentStatusEnum.SHARED;

  const classes = classNames(styles['pendingReviewWarningModal'], 'lease-document-wizard-modal-custom')

  const dispatch = useDispatch();

  if (useSelector(selectIsPendingReviewWarningModalClosed)) {
    dispatch(setClosePendingWarningReviewModal(false));
  }

  return (
    <Modal show={showModal} onHide={() => hideModal(!showModal)} size="lg" className={classes}>
      <Modal.Header
        closeButton
        closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
      >
        <Modal.Title as={'h2'}>
          <header>
            <FormattedMessage id="title.warn-user-leaving-document_header" defaultMessage="Before You Go..." />
          </header>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage id="title.warn-user-leaving-document_message" defaultMessage="Are you ready to send the document for review or signing?" />
        </p>
      </Modal.Body>
      <Modal.Footer className={'justify-content-center'}>
        <div className={'lease-document-wizard-modal-button-section'}>
          <Button
            onClick={() => {
              onCloseDocument && onCloseDocument()
            }}
            className={classNames(
              `btn btn-outline-secondary`,
              'lease-document-wizard-cta-stack__button',
              'mr-lg-4'
            )}
          >
            {"Not Yet"}
          </Button>
          <RequestReviewContainer
            dealTeamsData={dealTeamsData}
            documentId={documentId}
            dealId={dealId}
            handleSaveDocument={onSave}
            canEdit={canEdit}
            canShare={canShare}
            teamId={teamId}
            isDocumentEditable={isDocumentEditable}
            isDocumentInDraft={isDraft || isUploaded || isShared}
            isDocumentCreator={isDocumentCreator}
            isDocumentShared={isShared}
            shouldShowInternalSendForSigning={!!shouldShowInternalSendForSigning}
            shouldShowSendForSigning={!!shouldShowSendForSigning}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PendingReviewWarningModal;
