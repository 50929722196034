import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import RegisterForm from '../../forms/register-form';
import PublicLayout from '../../components/public-layout';
import styles from './style.module.scss';

const ConfirmByToken = () => {
  const { token } = useParams();
  const confirmationToken = token;

  return (
    <Wrapper
      titleMessage={confirmationToken ? { id: 'title.register', defaultMessage: 'Welcome to Propdocs' } : undefined}
      descriptionMessage={confirmationToken && `Create an account`}
      className={styles['register-form-wrapper']}
      confirmationToken={confirmationToken}
    >
      <RegisterForm confirmationToken={confirmationToken} />
    </Wrapper>
  );
};

interface WrapperProps {
  children?: ReactNode;
  titleMessage?: MessageDescriptor;
  descriptionMessage?: string;
  className?: string;
  confirmationToken?: string;
}

const Wrapper = ({
  children,
  titleMessage = { id: 'title.register', defaultMessage: 'Create your free account' },
  descriptionMessage = '',
  className,
  confirmationToken,
}: WrapperProps) => {
  const { formatMessage } = useIntl();
  return (
    <PublicLayout mainContentClassName={className} confirmationToken={confirmationToken}>
      <Helmet title={formatMessage(titleMessage)} />
      <h1 data-testid="sign-up" className="mb-0">
        {<FormattedMessage {...titleMessage} />}
      </h1>
      {descriptionMessage && (
        <p data-testid="sign-up-note" className="mb-4 font-weight-normal">
          {descriptionMessage}
        </p>
      )}
      {children}
    </PublicLayout>
  );
};

export default ConfirmByToken;
