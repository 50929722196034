import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import {
  selectLeaseDocumentEditorDisabled,
  selectLeaseDocumentId,
  selectLeaseDocumentStatus,
  selectLeaseDocumentTeam,
} from '../../../../../store/lease-document/selectors/lease-document.selectors';
import LoadingSpinner from '../../../../loading-spinner';
import { useDocumentRole } from '../../../../../shared/hooks/useDocumentRole/useDocumentRole';
import { DocumentPermissions } from '../../../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import ClauseTemplatesSidebar from './clauseTemplatesSidebar';
import { DocumentStatusEnum } from '../../../../../shared/constants/leaseDocument';
import { getDocumentTemplates, getOrganizationsTemplatesByUser } from '../../../../../api/queries_v2';
import { selectDealId } from '../../../../../store/deals/deals.selectors';
import { TemplateStatus } from '../../../../../pages/templates/Controls';
import { TemplateType } from '../../../../../consts/forms-types';
import { LeaseTemplateType } from '../../../../../pages/deals/lease-template-modal/LeaseTemplateModalContainer';

export interface ClauseTemplate {
  id: string;
  body: string;
  title: string;
  templateType?: string;
  status?: string;
}

interface ClauseTemplateProps {
  permissions: DocumentPermissions;
}

const ClauseTemplateContainer = ({ permissions }: ClauseTemplateProps) => {
  const documentId = useSelector(selectLeaseDocumentId);
  const teamId = useSelector(selectLeaseDocumentTeam);
  const documentStatus = useSelector(selectLeaseDocumentStatus);
  const isEditorDisabled = useSelector(selectLeaseDocumentEditorDisabled);
  const { isDocumentCreator, isDocumentRoleLoading } = useDocumentRole(documentId);
  const { data: templatesData, loading: isTemplatesDataLoading } = useQuery(getDocumentTemplates, {
    variables: { teamId, templateType: 'clause' },
    skip: !documentId,
    fetchPolicy: 'network-only',
  });

  const { loading: organizationsTemplatesDataLoading, data: organizationsTemplatesData } = useQuery(
    getOrganizationsTemplatesByUser,
    {
      fetchPolicy: 'no-cache',
      variables: {
        teamId,
        templateType: 'clause'
      },
    }
  );

  const filterByTemplateType = (template: LeaseTemplateType | ClauseTemplate) => {
    return template?.templateType === TemplateType.CLAUSE && template?.status === TemplateStatus.ACTIVE;
  };

  const filterOrgsTemplates = (templates: [LeaseTemplateType]) => {
    return templates?.filter(
      (template: LeaseTemplateType) => filterByTemplateType(template)
    );
  };

  const relevantOrgsTemplates = filterOrgsTemplates(organizationsTemplatesData?.organizationsTemplatesByUser) || [];

  const relevantTeamTemplates =
    templatesData?.getDocumentTemplates.filter((template: any) => filterByTemplateType(template)) || [];

  const isDraftOrReviewing = [DocumentStatusEnum.DRAFT, DocumentStatusEnum.REVIEWING].includes(
    documentStatus as DocumentStatusEnum
  );

  const isUpdatingDisabled =
    isEditorDisabled || !isDocumentCreator || !isDraftOrReviewing || !permissions.hasEditPermission;

  const clauseTemplates: ClauseTemplate[] =
    [...relevantTeamTemplates, ...relevantOrgsTemplates].map((template: any) => ({
      body: template.template.body,
      title: template.name,
      id: template.id,
    })) || [];

  const sortedTemplates = clauseTemplates.sort((a, b) => a.title.localeCompare(b.title));

  if (isDocumentRoleLoading || isTemplatesDataLoading || organizationsTemplatesDataLoading) {
    return <LoadingSpinner />;
  }

  return <ClauseTemplatesSidebar clauseTemplates={sortedTemplates} isDisabled={isUpdatingDisabled} />;
};

export default ClauseTemplateContainer;
