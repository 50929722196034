import { useState, useEffect, useCallback } from 'react';
import { useCommentContext } from '../../../../contexts/commentContext';
import { changeSidebarMode } from '../utils/changeSidebarMode';
import { EditorType } from '../utils/lease-editor.types';
import { queriesV2 } from '../../../../api';
import { ApolloClient } from '@apollo/client';

interface UseEditorSetupDataProps {
  resize?: boolean;
  documentId?: string;
  documentType: 'template' | 'document';
  apolloClient: ApolloClient<object>;
}

function useEditorSetupData({ resize, documentId, documentType, apolloClient }: UseEditorSetupDataProps) {
  const [ckeInstance, setCkeInstance] = useState<EditorType | null>(null);
  const [isLayoutReady, setIsLayoutReady] = useState<boolean>(false);
  const commentsContext = useCommentContext();

  const checkPendingActions = useCallback(
    (domEvt: any) => {
      if (!ckeInstance) {
        return;
      }

      if (ckeInstance.plugins.get('PendingActions').hasAny) {
        domEvt.preventDefault();
        domEvt.returnValue = true;
      }
    },
    [ckeInstance]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', checkPendingActions);

    return () => {
      window.removeEventListener('beforeunload', checkPendingActions);
    };
  }, [checkPendingActions]);

  useEffect(() => {
    setIsLayoutReady(true);
  }, []);

  useEffect(() => {
    window.commentsContext = commentsContext;
  }, [commentsContext]);

  useEffect(() => {
    if (!resize) return;
    window.addEventListener('resize', changeSidebarMode);
    return () => {
      window.removeEventListener('resize', changeSidebarMode);
    };
  }, [resize]);


  return { isLayoutReady, ckeInstance, setCkeInstance };
}

export default useEditorSetupData;
