import React, { useState } from 'react';
import { Button, Row, InputGroup, FormControl } from 'react-bootstrap';
import { Form, Field, Formik } from 'formik';
import * as yup from 'yup';
import { EMAIL_VALIDATION_REGEX } from '../../../../../../../shared/constants/emailValidation';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Tooltip from '../../../../../../tooltip';
import RecipientItem from './recipientItem';
import { ReactComponent as ChevronUpIcon } from '../../../../../../../shared/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../../../../shared/icons/chevron-down.svg';
import styles from './styles.module.scss';
import {TitleFormattedMessage} from "../../../../../../titleFormattedMessage";

interface AddSignerFormProps {
  onSubmit: (recipients: string[]) => void;
  isCreatingSigner: boolean;
  recipients: string[];
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required()
    .matches(EMAIL_VALIDATION_REGEX, 'Please provide correct email'),
});

const CC_TOOLTIP_MESSAGE =
  'The final signed copy of this document will be sent to all signers, all deal participants and any emails you specify here';

const AddRecipientForm = ({ recipients, onSubmit, isCreatingSigner }: AddSignerFormProps) => {
  const [expandedRecipientsArea, setExpandedRecipientsArea] = useState(false);

  const handleSignersDelete = async (emailToDelete: string) => {
    const filteredRecipientsList = recipients.filter((email: string) => email !== emailToDelete);
    onSubmit(filteredRecipientsList);
  };
  const renderRecipientsItems = () =>
    recipients?.map((signer, index) => {
      return <RecipientItem key={index} email={signer} deleteSigner={handleSignersDelete} />;
    });
  return (
    <Formik
      validateOnBlur
      initialValues={{
        email: '',
      }}
      onSubmit={(values, actions) => {
        onSubmit([...recipients, values.email]);
        actions.resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, handleBlur }) => {
        const isTouchedAndInvalid = !!touched.email && !!errors.email && !!values.email;
        return (
          <Form className={styles.wrapper}>
            <div className="d-flex justify-content-between align-items-center">
              <h1 className={styles.title}>
                Receive Signed Copy (CC)
                <Tooltip testId="cc-form-tooltip" placement="right">
                  {CC_TOOLTIP_MESSAGE}
                </Tooltip>
              </h1>
              <Button variant="link" onClick={() => setExpandedRecipientsArea(!expandedRecipientsArea)}>
                {expandedRecipientsArea ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </div>

            {expandedRecipientsArea && (
              <>
                {' '}
                <Row className="mx-0">
                  <InputGroup>
                    <FormControl
                      id="addSignerForm.signerEmail"
                      data-testid="signer-email-field"
                      as={Field}
                      name="email"
                      label={<FormattedMessage id="form.name.fullName" defaultMessage="Add Recipient" />}
                      type="text"
                      placeholder="Enter recipients email"
                      isInvalid={isTouchedAndInvalid}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                </Row>
                <Row className={classNames(styles.buttons, 'mx-0')}>
                  <Button
                    variant="primary"
                    type="submit"
                    className={styles['buttons-add']}
                    disabled={
                      !values.email ||
                      !!errors.email ||
                      isCreatingSigner ||
                      recipients.map(recipient => recipient.toLowerCase()).includes(values.email.trim().toLowerCase())
                    }
                  >
                    <TitleFormattedMessage id="cta.add-recipient" defaultMessage="Add Recipient" />
                  </Button>
                </Row>
                <div className="mt-2">{renderRecipientsItems()}</div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddRecipientForm;
