import {
  View,
  LabeledFieldView,
  createLabeledInputText,
  ButtonView,
  submitHandler,
  SwitchButtonView,
  LabelView,
} from '@ckeditor/ckeditor5-ui';
import icon from './trashcan.svg';

/**
 * The TemplateFormView class provides a UI form for creating or editing placeholders
 * in a CKEditor instance. The form fields and buttons are dynamically created based on
 * the type of document (template or lease).
 */
export class TemplateFormView extends View {
  /**
   * Constructor to initialize the TemplateFormView.
   *
   * @param {Locale} locale - The locale instance.
   * @param {string} documentType - The type of document ('template' or 'lease').
   * @param {Editor} editor - The CKEditor instance.
   * @param {Function} hideUi - Function to hide the UI.
   */
  constructor(locale, documentType, editor, hideUi) {
    super(locale);

    this.editor = editor;
    this._hideUi = hideUi;

    this.documentType = documentType;
    this.selectedElementName = null;
    this.selectedElementRequired = false;

    // Initialize form elements based on document type
    this._initializeFormElements();
  }

  /**
   * Renders the TemplateFormView.
   */
  render() {
    super.render();

    // Setup form submission handling
    submitHandler({
      view: this,
    });
  }

  /**
   * Focuses the first input element in the form.
   */
  focus() {
    if (this.documentType === 'template') {
      this.childViews._items[1].focus();
    }
    if (this.documentType === 'lease') {
      this.childViews._items[2].focus();
    }
  }

  /**
   * Sets the selected placeholder values in the form fields.
   *
   * @param {string} name - The name of the selected element.
   * @param {boolean} required - Whether the selected element is required.
   */
  setSelectedElementValues(name, required) {
    this.selectedElementName = name;
    this.selectedElementRequired = required;
    this.updateHeaderView();

    // Store a reference to the selected placeholder
    const model = this.editor.model;
    const selection = model.document.selection;
    this.selectedPlaceholder = selection.getSelectedElement();
  }

  /**
   * Updates the header view of the form based on the selected element values.
   */
  updateHeaderView() {
    if (this.selectedElementName) {
      this.leaseFormHeaderView.set({
        text: `${this.selectedElementName} ${this.selectedElementRequired ? '(required)' : ''}`,
      });
      this.templateFormInputView.fieldView.value = this.selectedElementName;
    } else {
      this.leaseFormHeaderView.set({
        text: 'Add placeholder text:',
      });
      this.templateFormInputView.fieldView.value = '';
    }

    this.templateFormRequiredCheckbox.set({
      isOn: this.selectedElementRequired,
    });
  }

  /**
   * Deletes the currently selected placeholder element.
   */
  _deletePlaceholder() {
    const editor = this.editor;
    const model = editor.model;
    const selection = model.document.selection;

    model.change((writer) => {
      // Assuming the placeholder is the currently selected element
      const placeholder = selection.getSelectedElement();

      // Check if a placeholder is selected
      if (placeholder && placeholder.name === 'placeholder') {
        // Remove the placeholder
        this.editor.model.deleteContent(selection);
        editor.fire('hideDialogCommand');
      }
    });
  }

  /**
   * Initializes form elements based on the document type and configures the template.
   */
  _initializeFormElements() {
    // Elements for template type documents
    this.templateFormInputView = this._createInput('Placeholder name');
    this.templateFormHeaderView = this._createHeadingText('Placeholder');
    this.templateFormRequiredCheckbox = this._createCheckbox('Required');
    this.templateSaveButtonView = this._createButton('Save', 'ck-button-save');
    this.templateSaveButtonView.type = 'submit';
    this.templateDeletePlaceholderButton = this._createIconButton('Delete', 'ck-button-cancel', icon);
    this.templateDeletePlaceholderButton.on('execute', () => this._deletePlaceholder());

    // Elements for lease type documents
    this.leaseFormInputView = this._createInput('Insert value');
    this.leaseFormHeaderView = this._createHeadingText('Add placeholder text:', 'ck-placeholder-form-header');
    this.leaseSaveButtonView = this._createButton('Insert', 'ck-button-save');
    this.leaseSaveButtonView.type = 'submit';
    this.leaseDeletePlaceholderButton = this._createIconButton('Delete', 'ck-button-cancel', icon);
    this.leaseDeletePlaceholderButton.on('execute', () => this._deletePlaceholder());

    if (this.documentType === 'template') {
      this.childViews = this.createCollection([
        this.templateFormHeaderView,
        this.templateFormInputView,
        this.templateDeletePlaceholderButton,
        this.templateFormRequiredCheckbox,
        this.templateSaveButtonView,
      ]);

      this.setTemplate({
        tag: 'form',
        attributes: {
          class: ['ck', 'ck-placeholder-form-template'],
          tabindex: '-1',
        },
        children: this.childViews,
      });
    }

    if (this.documentType === 'lease') {
      this.childViews = this.createCollection([
        this.leaseFormHeaderView,
        this.leaseDeletePlaceholderButton,
        this.leaseFormInputView,
        this.leaseSaveButtonView,
      ]);

      this.setTemplate({
        tag: 'form',
        attributes: {
          class: ['ck', 'ck-placeholder-form-lease'],
          tabindex: '-1',
        },
        children: this.childViews,
      });
    }
  }

  /**
   * Creates a labeled input field view.
   *
   * @param {string} label - The label for the input field.
   * @returns {LabeledFieldView} The labeled field view.
   */
  _createInput(label) {
    const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);
    labeledInput.label = label;
    return labeledInput;
  }

  /**
   * Creates a switch button view (checkbox).
   *
   * @param {string} label - The label for the checkbox.
   * @returns {SwitchButtonView} The switch button view.
   */
  _createCheckbox(label) {
    const switchButton = new SwitchButtonView();

    switchButton.set({
      label: label,
      withText: true,
      isOn: false,
    });
    switchButton.on('execute', () => { switchButton.isOn = !switchButton.isOn });
    return switchButton;
  }

  /**
   * Creates a button view.
   *
   * @param {string} label - The label for the button.
   * @param {string} className - The class name for styling the button.
   * @returns {ButtonView} The button view.
   */
  _createButton(label, className) {
    const button = new ButtonView();

    button.set({
      label,
      withText: true,
      tooltip: true,
      class: className,
    });

    return button;
  }

  /**
   * Creates an icon button view.
   *
   * @param {string} label - The label for the button.
   * @param {string} className - The class name for styling the button.
   * @param {string} icon - The icon for the button.
   * @returns {ButtonView} The button view with an icon.
   */
  _createIconButton(label, className, icon) {
    const button = new ButtonView();

    button.set({
      label,
      icon,
      tooltip: true,
      class: className,
    });

    return button;
  }

  /**
   * Creates a heading text view.
   *
   * @param {string} text - The text for the heading.
   * @returns {LabelView} The label view for the heading text.
   */
  _createHeadingText(text, className) {
    const headingText = new LabelView(this.locale);

    headingText.set({
      text,
      class: className,
    });

    return headingText;
  }
}
