import React from 'react';
import { FormattedMessage } from 'react-intl';
import cls from 'classnames';
import { ReactComponent as EditIcon } from '../../shared/icons/icon-edit.svg';
import styles from './styles.module.scss';

const DEFAULT_CARD_BOOTSTRAP_WIDTH = '4';

export const Card = ({
  isBlue = false,
  isFullHeight = false,
  isActivitiesCard = false,
  isSidebar = false,
  children,
  title,
  icon: Icon = undefined,
  width = DEFAULT_CARD_BOOTSTRAP_WIDTH,
  className = '',
}) => (
  <div className={`col-md-${width}`}>
    <div
      className={cls(
        styles.card,
        className,
        { [styles.cardBlue]: isBlue },
        { [styles.cardFullHeight]: isFullHeight },
        { [styles.activitiesCard]: isActivitiesCard },
        { [styles.cardSidebar]: isSidebar }
      )}
    >
      {Icon && (
        <div className={styles.cardIcon}>
          <Icon />
        </div>
      )}
      {title && <h4 className={styles.cardTitle}>{title}</h4>}
      <div className={styles.cardContent}>{children}</div>
    </div>
  </div>
);

export const CardContainer = ({ children }) => <div className="row mt-4">{children}</div>;

export const ProfileCard = ({ name, children }) => {
  return (
    <Card>
      <div className={styles.profileHeader}>
        <h4>
          <FormattedMessage id="title.yourProfile" defaultMessage="Your Profile" />
        </h4>
      </div>
      {children}
    </Card>
  );
};

export const CardRow = ({ children, onClick, ...rest }) => (
  <button {...rest} className={styles.cardRow} onClick={onClick}>
    {children}
    <EditIcon />
  </button>
);

export default Card;
