import React from 'react';
import { auth as paths } from './routing/paths';

export const generic = {
  id: 'api.error.generic',
  defaultMessage: 'Something went wrong',
};

export const string = {
  default: { id: 'form.error.string.default', defaultMessage: 'This value is invalid' },
  email: {
    id: 'form.error.string.email',
    defaultMessage: 'This must be an email address',
  },
  min: {
    id: 'form.error.string.min',
    defaultMessage: 'Minimum length is {min}',
  },
  equalTo: {
    id: 'form.error.string.equal-to',
    defaultMessage: 'Field {path} must be equal to {reference}',
  },
  minPasswordStrength: {
    id: 'form.error.string.min-password-strength',
    defaultMessage: `Minimum password strength is “{min, select,
        0 {very weak}
        1 {weak}
        2 {okay}
        3 {moderate}
        4 {strong}
        5 {very strong}
      }”`,
  },
};

export const boolean = {
  checked: {
    id: 'form.error.boolean.checked',
    defaultMessage: 'This must be checked',
  },
};

export const mixed = {
  required: {
    id: 'form.error.required',
    defaultMessage: 'This field is required',
  },
  unique: {
    id: 'form.error.unique',
    defaultMessage: 'This field must be unique',
  },
  notType: {
    id: 'form.error.typeError',
    defaultMessage: 'This must be a {type}',
  },
};

interface ApiError {
  id: string;
  defaultMessage: string;
  values?: {
    [key: string]: Function;
  };
}

export const api: { [key: string]: ApiError } = {
  'authentication-failed': {
    id: 'api.error.authentication-failed',
    defaultMessage: 'You have entered an incorrect email and/or password. Need an account? <a>Sign Up</a>',
    values: {
      a: (msg: string) => <a href={paths.register}>{msg}</a>,
    },
  },
  existingUser: {
    id: 'api.error.user-exists',
    defaultMessage: 'Email has already been taken',
  },
  blank: mixed.required,
  'too-short': string.min,
  invalid: string.default,
  taken: mixed.unique,
  'no-team-membership': {
    id: 'api.error.no-team-membership',
    defaultMessage: 'You are not a member of the team. Please ask your team admin to invite you to join.',
  },
  expired: {
    id: 'api.error.expired',
    defaultMessage: `{path, select,
      resetPasswordToken {This password reset link}
      invitationToken {This invitation link}
      other {This token}
    } has expired.`,
  },
  'not-found': {
    id: 'api.error.not-found',
    defaultMessage: 'The requested resource was not found.',
  },
  'unauthorized-query-access': {
    id: 'api.error.unauthorized-query-access',
    defaultMessage: 'You do not have access to this resource.',
  },
  'no-lease-access': {
    id: 'api.error.no-lease-access',
    defaultMessage: 'You do not have access to this lease.',
  },
  'already-accepted': {
    id: 'api.error.already-accepted',
    defaultMessage: 'This invitation was already accepted.',
  },
  'admin-required': {
    id: 'api.error.admin-required',
    defaultMessage: 'Only an admin of the team may perform this action.',
  },
  'unhandled-server-error': generic,
};

export const errorCodeVerificationPending = 'verification-pending';
