import React, { useEffect, useState } from 'react';
import MobileSidebar from '../../../../components/mobile-layout/mobile-sidebar';
import { FormattedMessage } from 'react-intl';
import DropdownPanel from '../../../../components/dropdown-panel';
import { CheckboxOrRadio, RadioField } from '../../../../components/checkbox-or-radio-field';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../../../../components/titleFormattedMessage';

import styles from './styles.module.scss';
import { SortingForm } from './SortingForm';
import { CheckboxForm } from '../../../../components/mobile-layout/CheckboxForm';
import { statusOptions, templateStatusMessages } from '../../constants';
import { CreatorForm } from '../../../../components/FilterSideBar/CreatorForm';

interface FilterSidebarProps {
  isOpen: boolean;
  filterCreators: Set<any>;
  allCreators: string[];
  filterStatuses: Set<string>;
  onClose: () => void;
  setFilterCreators: (value: Set<string>) => void;
  setFilterStatuses: (value: Set<string>) => void;
  setSorting: (value: string) => void;
}

export interface FilterData {
  sort: string;
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  isOpen,
  onClose,
  allCreators,
  filterCreators,
  filterStatuses,
  setSorting,
  setFilterCreators,
  setFilterStatuses,
}) => {
  const [creators, setCreators] = useState(filterCreators);
  const [statuses, setStatuses] = useState(filterStatuses);

  useEffect(() => {
    if (isOpen) {
      setCreators(filterCreators);
    }
  }, [isOpen]);

  const onSetCreators = (value: Set<string>) => {
    setCreators(value);
  };

  const onSubmit = async (values: FilterData, actions: any) => {
    setSorting(values.sort);
    setFilterCreators(creators);
    setFilterStatuses(statuses);
    onClose();
  };

  const onReset = (resetForm: any) => {
    resetForm && resetForm();
    setFilterCreators(new Set());
    setFilterStatuses(new Set());
    onClose();
  };

  return (
    <MobileSidebar
      header={
        <div data-testid="deals-header" role={'heading'} className={'ml-3'}>
          <FormattedMessage id="title.filter" defaultMessage="Filter" />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik onSubmit={onSubmit} initialValues={{ sort: '' }}>
        {formikProps => (
          <Form noValidate className={styles.mobileFilterForm}>
            <div>
              <DropdownPanel title="Sorting" hasBorder>
                <SortingForm />
              </DropdownPanel>
              <DropdownPanel title="Creators">
                <CreatorForm creators={allCreators} filtered={creators} setFilterCreators={onSetCreators} />
              </DropdownPanel>
              <DropdownPanel title="All Statuses">
                <CheckboxForm
                  filtered={statuses}
                  setFiltered={setStatuses}
                  messages={templateStatusMessages}
                  options={statusOptions}
                  allLabel="All Statuses"
                  type="status"
                />
              </DropdownPanel>
            </div>
            <div className={styles.mobileFilterBtns}>
              <Button onClick={() => onReset(formikProps.resetForm)} variant="btn btn-outline-primary shadow-none">
                <TitleFormattedMessage id="mobileFilter.clearBtn" defaultMessage="Clear" />
              </Button>
              <Button type="submit" variant="btn btn-primary shadow-none" data-testid="filterApplyBtn">
                <TitleFormattedMessage id="mobileFilter.applyBtn" defaultMessage="Apply" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </MobileSidebar>
  );
};

export default FilterSidebar;
