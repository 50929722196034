import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDocumentEditorSuggestions } from '../../../../../../store/lease-document/selectors/lease-document.selectors';
import { selectIsGrantAccessModalOpen } from '../../../../../../store/modals/modals.selectors';
import UnlockDocumentModal from './unlockDocumentModal';
import UnlockDocumentButton from './unlockDocumentButton';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as LockIcon } from '../../../icons/lock-icon.svg';
import styles from './styles.module.scss';

interface Props {
  teamId: string;
  documentId: string;
}

const UnlockDocumentContainer = ({ documentId, teamId }: Props) => {
  const isGrantAccessModalOpen = useSelector(selectIsGrantAccessModalOpen);
  const [showModal, setShowModal] = useState(isGrantAccessModalOpen);
  useSelector(selectDocumentEditorSuggestions); // using to subscribe to store updates for editor suggestions

  return (
    <>
      <div className={styles['locked-label']}>
        <LockIcon height="28" className="mr-2" />
        <FormattedMessage
          id="lease-document-wizard-header.action.Locked-document"
          defaultMessage="Locked"
          data-testid="lease-document-wizard-header-Locked-documen"
        />
      </div>
      <UnlockDocumentButton onClick={() => setShowModal(true)} />
      <UnlockDocumentModal
        show={showModal}
        onHide={() => setShowModal(false)}
        teamId={teamId}
        documentId={documentId}
      />
    </>
  );
};

export default UnlockDocumentContainer;
