import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import ErrorMessage from '../components/error-message';
import SubmitButton from '../components/submit-button';
import { TextField } from '../components/fields';
import { TitleFormattedMessage } from '../components/titleFormattedMessage';

interface ChangeNameFormProps {
  errors: { [key: string]: any };
  isSubmitting: boolean;
  isValid: boolean;
  onCancel: (...args: any[]) => any;
}
const ChangeNameForm = ({ errors, isSubmitting, isValid, onCancel }: ChangeNameFormProps) => {
  return (
    <Form noValidate>
      {errors._general && (
        <Alert variant="danger">
          <ErrorMessage>{errors._general}</ErrorMessage>
        </Alert>
      )}

      <div className="w-75">
        <TextField
          id="changeNameForm.fullName"
          name="fullName"
          type="text"
          label={<FormattedMessage id="form.name.fullName" defaultMessage="Full Name" />}
          data-testid="full-name-field"
        />
      </div>
      <div className="d-flex justify-content-end">
        {/* @ts-ignore */}
        <Button className="mr-3" variant="link-secondary" onClick={onCancel} data-testid="cancel-name-change">
          <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
        </Button>
        <SubmitButton
          label={<FormattedMessage id="cta.changeName" defaultMessage="Change Name" data-testid="change-name" />}
          disabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
          data-testid="change-name-button"
        />
      </div>
    </Form>
  );
};

const schema = yup.object().shape({
  fullName: yup.string().required(),
});

interface ChangeNameFormikProps {
  onCancel: (...args: any[]) => any;
  onSave: (...args: any[]) => any;
  currentUser: any;
}
const ChangeNameFormik = ({ onCancel, onSave, currentUser }: ChangeNameFormikProps) => {
  // replace these values with a query from the BE
  const { fullName } = currentUser;
  return (
    <Formik
      // @ts-ignore
      onCancel={onCancel}
      validationSchema={schema}
      onSubmit={onSave}
      component={formikProps => <ChangeNameForm {...formikProps} onCancel={onCancel} />}
      initialValues={{
        fullName,
      }}
    />
  );
};

export default ChangeNameFormik;
