import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../notifications';
import { paths } from '../routing';

const NotFound = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [, { error: notifyError }] = useNotifications();

  navigate(paths.home);

  notifyError({
    message: formatMessage({
      id: 'phrase.404-not-found',
      defaultMessage: 'Error 404: the page was not found.',
    }),
  });

  return null;
};

export default NotFound;
