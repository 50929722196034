import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { graphqlErrorsToUiErrors } from '../../../api';
import { getDocumentTemplates, getOrganizationsTemplatesByUser } from '../../../api/queries_v2';
import { useTeamInfo } from '../../../team-id-context';
import { useDealId } from '../../../deal-id-context';
import { LeaseTemplateModalTable } from './leaseTemplateModalTable';
import { LeaseTemplateModalFilters } from './leaseTemplateModalFilters';
import NotFound from '../../not-found';
import ErrorMessage from '../../../components/error-message';
import LoadingSpinner from '../../../components/loading-spinner';
import LeaseTemplateModal from './LeaseTemplateModal';
import TemplateInputFormik from '../../../forms/TemplateInputForm';
import styles from './styles.module.scss';
import { TemplateType } from '../../../consts/forms-types';
import { fuzzyInputSearch } from '../helpers/determineDealsActionModalMessage';
import { getTemplateType } from '../../../shared/templateType';

/**
 * lease template modal component
 * @param {boolean} isOpen - if modal is open
 * @param {function} onClose - on close handler
 * @param {object} dealData - deal data object
 * @returns {JSX.Element} - the modal componet
 * @constructor
 */
export type LeaseTemplateModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  dealData?: any;
  modalFilters?: JSX.Element;
};

export type LeaseTemplateType = {
  customTemplate: boolean;
  dealType: string | null;
  description: string;
  hidden: boolean;
  id: string | Number;
  country: string;
  state?: string;
  name: string;
  status: string;
  templateType: string;
  team: {
    name: string;
  };
  template: {
    body: string;
  };
};

export interface ApolloErrorInterface {
  _general: { id: string | number };
}

const LeaseTemplateModalContainer = ({ isOpen, onClose, dealData }: LeaseTemplateModalComponentProps) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<string | number>('');
  const [filterText, setFilterText] = React.useState('');
  const [filterCreators, setFilterCreators] = React.useState(new Set());
  const [creatingDraft, setCreatingDraft] = React.useState(false);
  const { teamId, teamName } = useTeamInfo();
  const dealId = useDealId();
  const [templateSourceTeamUUID, setSelectedTemplateSourceTeamUUID] = React.useState('');
  const [templateSourceTeamName, setSelectedTemplateSourceTeamName] = React.useState('');
  const {
    loading: leaseDocumentsDeafultTemplatesLoading,
    data: leaseDocumentsDeafultTemplatesData,
    error: graphqlErrorLoadingDeafultTemplates,
  } = useQuery(getDocumentTemplates, {
    fetchPolicy: 'no-cache',
    variables: {
      teamId: null,
    },
  });

  const {
    loading: leaseDocumentsCustomTemplatesLoading,
    data: leaseDocumentsCustomTemplatesData,
    error: graphqlErrorLoadingCustomTemplates,
  } = useQuery(getDocumentTemplates, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    variables: {
      teamId: teamId,
    },
  });

  const {
    loading: organizationsTemplatesDataLoading,
    data: organizationsTemplatesData,
    error: graphqlErrorOrganizationsTemplatesData,
  } = useQuery(getOrganizationsTemplatesByUser, {
    fetchPolicy: 'no-cache',
    variables: {
      teamId,
    },
  });

  const TEMPLATE_STATUS_ACTIVE = 'active';
  const isLoading =
    leaseDocumentsDeafultTemplatesLoading || leaseDocumentsCustomTemplatesLoading || organizationsTemplatesDataLoading;

  const creators = React.useMemo(() => {
    const organizationsTemplatesCreators = organizationsTemplatesData?.organizationsTemplatesByUser?.map(
      (orgTemplate: { team: { name: string } }) => {
        return orgTemplate.team?.name;
      }
    );

    return ['Propdocs']
      .concat(teamName ? teamName : [])
      .concat(organizationsTemplatesCreators ? Array.from(new Set(organizationsTemplatesCreators)) : []);
  }, [teamName, organizationsTemplatesData]);

  const selectTemplate = useCallback((templateId: string | number, teamUUID: string, teamName?: string) => {
    setSelectedTemplate(templateId);
    setSelectedTemplateSourceTeamUUID(teamUUID);
    setSelectedTemplateSourceTeamName(teamName || '');
  }, []);

  React.useEffect(() => {
    if (creators.length) setFilterCreators(new Set(creators));
  }, [creators, setFilterCreators]);

  if (isLoading || creatingDraft) {
    return <LoadingSpinner />;
  }

  if (!leaseDocumentsDeafultTemplatesData && graphqlErrorLoadingDeafultTemplates) {
    const errors = graphqlErrorsToUiErrors(graphqlErrorLoadingDeafultTemplates) as ApolloErrorInterface;
    if (errors._general.id === 'api.error.not-found') {
      return <NotFound />;
    }
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  if (!leaseDocumentsCustomTemplatesData && graphqlErrorLoadingCustomTemplates) {
    const errors = graphqlErrorsToUiErrors(graphqlErrorLoadingCustomTemplates) as ApolloErrorInterface;
    if (errors._general.id === 'api.error.not-found') {
      return <NotFound />;
    }
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  if (!organizationsTemplatesData && graphqlErrorOrganizationsTemplatesData) {
    const errors = graphqlErrorsToUiErrors(graphqlErrorOrganizationsTemplatesData) as ApolloErrorInterface;
    if (errors._general.id === 'api.error.not-found') {
      return <NotFound />;
    }
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }
  /**
   * creat draft api call
   * @param templateId
   * @returns {Promise<void>}
   */
  const isCreatingLeaseDraft = () => {
    setCreatingDraft(true);
  };

  const onModalClose = () => {
    setSelectedTemplate('');
    onClose();
  };

  const { dealType } = dealData;

  const filterByDealType = (template: LeaseTemplateType) => {
    return template.dealType ? template.dealType === dealType : template;
  };

  const filterByTemplateType = (template: LeaseTemplateType) => {
    return template.templateType === TemplateType.DOCUMENT;
  };

  const filterByStatus = (template: LeaseTemplateType) => {
    const templateStatus = template.status.toLocaleLowerCase();
    return templateStatus === TEMPLATE_STATUS_ACTIVE;
  };

  const filterByCreator = (template: LeaseTemplateType) => {
    let teamName = template.team?.name || 'Propdocs';
    if (filterCreators.size < creators.length) {
      if (!filterCreators.has(teamName)) return false;
    }
    return true;
  };

  /**
   * verifies service type
   * @param {object} template - the template object
   * @returns {boolean}
   */
  const filterBySearch = (template: LeaseTemplateType) => {
    return fuzzyInputSearch({ searchValue: template.name, filteredText: filterText });
  };

  /**
   * filters the displayable templates
   * @param {object[]} templates - available templates array
   * @returns {boolean}
   */
  const filterTemplates = (templates: Array<any>) => {
    return templates.filter(
      (template: LeaseTemplateType) =>
        filterByDealType(template) &&
        filterByCreator(template) &&
        filterBySearch(template) &&
        filterByTemplateType(template)
    );
  };

  const filterActiveCustomTemplates = (templates: Array<any>) => {
    return templates.filter(
      (template: LeaseTemplateType) =>
        filterByStatus(template) &&
        filterByCreator(template) &&
        filterBySearch(template) &&
        filterByTemplateType(template)
    );
  };

  const filterOrgsTemplates = (templates: [LeaseTemplateType]) => {
    return templates.filter(
      (template: LeaseTemplateType) =>
        filterByDealType(template) &&
        filterByTemplateType(template) &&
        filterBySearch(template) &&
        filterByCreator(template)
    );
  };

  const defaultTemplates = filterTemplates(leaseDocumentsDeafultTemplatesData!.getDocumentTemplates);
  const customTemplates = filterActiveCustomTemplates(leaseDocumentsCustomTemplatesData!.getDocumentTemplates);
  const organizationsTemplates = filterOrgsTemplates(organizationsTemplatesData?.organizationsTemplatesByUser);

  const allTemplates = [...customTemplates, ...defaultTemplates, ...organizationsTemplates].map(el => {
    const docTemplateType = getTemplateType(el);
    return { ...el, docTemplateType };
  });

  const modalFilters = () => {
    return (
      <div>
        <LeaseTemplateModalFilters
          filterText={filterText}
          setFilterText={setFilterText}
          creators={creators}
          filterCreators={filterCreators}
          setFilterCreators={setFilterCreators}
        />
        {allTemplates.length ? (
          <LeaseTemplateModalTable
            data={allTemplates}
            selectTemplate={selectTemplate}
            selectedTemplateId={selectedTemplate}
          />
        ) : (
          <div className="py-5">
            <FormattedMessage
              id="lease.template-modal.no-templates"
              defaultMessage="Sorry, there are no available leases. Please contact <a>support@propdocs.com</a> for further assistance."
              values={{ a: (msg: string) => <a href="mailto:support@propdocs.com">{msg}</a> }}
            />
          </div>
        )}
        {selectedTemplate && (
          <div className={styles.templateModalFooterContainer}>
            <div>
              <FormattedMessage id="lease.template.creator" defaultMessage="Document Name" />
            </div>
            <TemplateInputFormik
              dealId={dealId}
              teamId={teamId}
              templateId={selectedTemplate}
              isCreatingLeaseDraft={isCreatingLeaseDraft}
              onClose={onClose}
              templateSourceTeamName={templateSourceTeamName}
              templateSourceTeamId={templateSourceTeamUUID}
            ></TemplateInputFormik>
          </div>
        )}
      </div>
    );
  };

  return <LeaseTemplateModal isOpen={isOpen} onClose={onModalClose} modalFilters={modalFilters()} />;
};

export default LeaseTemplateModalContainer;
