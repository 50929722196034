import { FormattedMessage } from 'react-intl';
import React, { Fragment, MouseEvent } from 'react';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import { Dropdown, DropdownBody, DropdownToggle } from '../../../../components/dropdown';
import styles from './styles.module.scss';

export enum DropdownStatusesEnum {
  in_progress = 'In Progress',
  completed = 'Completed',
  lost = 'Lost',
  archived = 'Archived',
}

export type DealStatus = keyof typeof DropdownStatusesEnum;

interface StatusDropdownProps {
  currentStatus: DealStatus;
  onStatusChange: (newStatus: DealStatus) => void;
  className?: string;
  wrapperClassName?: string;
}

const StatusDropdown = ({
  currentStatus,
  onStatusChange,
  className,
  wrapperClassName,
  ...rest
}: StatusDropdownProps) => {
  return (
    <Dropdown
      onClick={(evt: MouseEvent) => {
        evt.stopPropagation();
      }}
      className={classNames(styles.wrapper, wrapperClassName)}
    >
      <Dropdown.Toggle
        data-testid="dropdown-deal-actions"
        id="dropdown-deal-actions"
        as={DropdownToggle}
        className={className ? className : classNames('w-100 slightly-shorter', styles['dropdown-toggle'])}
        {...rest}
      >
        <FormattedMessage id="deals.actions" defaultMessage={DropdownStatusesEnum[currentStatus]} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <DropdownBody>
          {Object.entries(DropdownStatusesEnum).map(([key, value]: [string, string]) => (
            <Fragment key={key}>
              <Dropdown.Item
                as={Button}
                variant="link"
                onClick={() => {
                  onStatusChange(key as DealStatus);
                }}
              >
                {value}
              </Dropdown.Item>
            </Fragment>
          ))}
        </DropdownBody>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatusDropdown;
