import clsx from 'classnames';

import { ReactComponent as CloseIcon } from '../../shared/icons/close-icon.svg';
import styles from '../private-layout/styles.module.scss';

const MobileSidebar = ({ header, children, isOpen, onClose }) => {
  return (
    <div
      className={clsx(styles.mobileMenu, {
        [styles.mobileMenu__isOpen]: isOpen,
      })}
    >
      <div className={styles.mobileMenu__background}></div>
      <div className={styles.mobileMenu__body}>
        <div className={styles.mobileMenu__header}>
          <div className={styles.mobileMenu__title}>{header}</div>
          <CloseIcon width={18} height={18} onClick={onClose} />
        </div>

        {children}
      </div>
    </div>
  );
};

export default MobileSidebar;
