import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseDocumentEversignStatusAction } from '../actions/lease-document.actions';

export const handleSetLeaseDocumentEversignStatus = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentEversignStatusAction
): LeaseDocumentStateType => ({
  ...state,
  eversignStatus: action.eversignStatus,
});
