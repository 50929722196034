import React, { ChangeEvent, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Checkbox } from '../../../../checkbox-or-radio-field';
import DocumentActivityDropdown, { DocumentActivityTypesEnum } from './documentActivityDropdown';
import DocumentActivitySidebar from './documentActivitySidebar';
import styles from './styles.module.scss';

const DocumentActivitySidebarContainer = () => {
  const { formatMessage } = useIntl();
  const [currentDocumentActivityType, setCurrentDocumentActivityType] = useState<DocumentActivityTypesEnum>(
    DocumentActivityTypesEnum.all
  );
  const [shouldAllActivitiesBeShown, setShouldAllActivitiesBeShown] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <h2>Activity</h2>
        <DocumentActivityDropdown
          currentDocumentActivity={currentDocumentActivityType}
          onDocumentActivityChange={(newStatus: DocumentActivityTypesEnum) => {
            setCurrentDocumentActivityType(newStatus);
          }}
        />
        <Container className="p-0">
          <Checkbox
            checked={shouldAllActivitiesBeShown}
            id="activities-filter-active"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const checkbox = event.currentTarget;
              if (checkbox.checked) {
                setShouldAllActivitiesBeShown(true);
              } else {
                setShouldAllActivitiesBeShown(false);
              }
            }}
            label={formatMessage({
              id: 'activities.filter.active',
              defaultMessage: 'Show Resolved/Deleted Activities',
            })}
            disabled={false}
          />
        </Container>
      </div>

      <DocumentActivitySidebar
        currentDocumentActivityType={currentDocumentActivityType}
        shouldAllActivitiesBeShown={shouldAllActivitiesBeShown}
      />
    </>
  );
};

export default DocumentActivitySidebarContainer;
