import { Plugin } from '@ckeditor/ckeditor5-core';
import getPlaceholdersUI from "./placeholdersUI";
import getPlaceholdersEditing from "./placeholdersEditing";

const getPlaceholdersPlugin = ({ documentType }) => {

  class Placeholder extends Plugin {

    static get pluginName() {
      return 'Placeholder';
    }
    static get requires() {
      return [ getPlaceholdersEditing({ documentType }), getPlaceholdersUI({ documentType }) ];
    }
  }

  return Placeholder;
}

export default getPlaceholdersPlugin;
