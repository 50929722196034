import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider, useIntl } from 'react-intl';
import { Provider } from 'react-redux';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import Dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DndProvider } from 'react-dnd';
import { HTML5BackendImpl } from 'react-dnd-html5-backend/dist/HTML5BackendImpl';
import relativeTime from 'dayjs/plugin/relativeTime';
import minMax from 'dayjs/plugin/minMax';
import './custom-validators';
import { AuthContext, useAuthReducer } from './auth';
import { NotificationsProvider } from './notifications';
import Notifications from './components/notifications';
import { client } from './api';
import store from './store/store';
import './styles/app.scss';
import { WebappRouter } from './routing';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

class HTML5Backend extends HTML5BackendImpl {
  handleDrop(_e: DragEvent, targetId: string, ...args: any[]) {
    // @ts-ignore -- here we override some private fields
    this.registry.getTarget(targetId).monitor.originalEvent = _e;
    // @ts-ignore
    return super.handleDrop(...arguments);
  }
}

export const createHTMLBackend = (manager: any) => new HTML5Backend(manager);

Dayjs.extend(localizedFormat);
Dayjs.extend(relativeTime);
Dayjs.extend(minMax);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  auth: process.env.REACT_APP_GTM_AUTH_KEY,
  preview: process.env.REACT_APP_GTM_PREVIEW,
};

if (process.env.REACT_APP_ENVIRONMENT !== 'development' && process.env.REACT_APP_ENVIRONMENT !== 'test') {
  TagManager.initialize(tagManagerArgs);
}

const App = () => {
  const isPrivate = true;
  const auth = useAuthReducer({ currentPageIsPrivate: isPrivate });
  // change this when more language added in the future
  const locale = 'en';

  // XXX: Gross hack
  // Assign the auth methods to a property of the GraphQL client so it can log
  // the user out on errors. The client needs the auth and the auth needs the
  // client. Can't access React context from outside a render method, nor grab
  // the same copy of the reducer. This hack does the job.

  // @ts-ignore
  client.__authMethods = auth.handlers;
  moment.locale(locale);

  return (
    <IntlProvider locale={locale} textComponent={React.Fragment}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <AuthContext.Provider value={auth}>
            <NotificationsProvider>
              <DndProvider backend={createHTMLBackend}>
                <Meta />
                <Notifications />
                <WebappRouter />
              </DndProvider>
            </NotificationsProvider>
          </AuthContext.Provider>
        </Provider>
      </ApolloProvider>
    </IntlProvider>
  );
};

export default App;

export { Dayjs as dayjs };

const Meta = () => {
  const { formatMessage } = useIntl();
  return <Helmet titleTemplate={formatMessage({ id: 'title.template', defaultMessage: '%s | Propdocs' })} />;
};
