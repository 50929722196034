import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectDocumentCompareModeEnabled } from '../../../../store/lease-document/selectors/lease-document.selectors';
import { setDocumentCompareModeEnabled } from '../../../../store/lease-document/actions/lease-document.actions';
import styles from './styles.module.scss';

enum DocumentRevisionOptions {
  edit = 'edit',
  compare = 'compare',
}

export const DocumentRevisionSwitcher = () => {
  const dispatch = useDispatch();
  const [disableToggleButton, setDisableToggleButton] = useState<boolean>(false);
  const isCompareModeEnabled = useSelector(selectDocumentCompareModeEnabled);

  const handleRevisionHistoryEnable = async (value: string) => {
    setDisableToggleButton(true);
    if (value === DocumentRevisionOptions.compare) {
      dispatch(setDocumentCompareModeEnabled(true));
      await window.editor?.config?.get('revisionHistory')?.showRevisionViewerCallback();
    } else {
      dispatch(setDocumentCompareModeEnabled(false));
      await window.editor?.config?.get('revisionHistory')?.closeRevisionViewerCallback();
    }
    setDisableToggleButton(false);
  };

  return (
    <>
      <ToggleButtonGroup
        className={styles.documentRevisionTopToggle}
        name={'DocumentRevisionSwitcher'}
        type="radio"
        value={isCompareModeEnabled ? DocumentRevisionOptions.compare : DocumentRevisionOptions.edit}
      >
        <ToggleButton
          disabled={disableToggleButton}
          className={
            isCompareModeEnabled ? styles.documentRevisionSectionButton : styles.documentRevisionSectionButtonActive
          }
          name={DocumentRevisionOptions.edit}
          onChange={e => handleRevisionHistoryEnable(e.currentTarget.value)}
          value={DocumentRevisionOptions.edit}
        >
          <FormattedMessage id="cta.edit" defaultMessage="Edit" />
        </ToggleButton>

        <ToggleButton
          disabled={disableToggleButton}
          className={
            isCompareModeEnabled ? styles.documentRevisionSectionButtonActive : styles.documentRevisionSectionButton
          }
          name={DocumentRevisionOptions.compare}
          onChange={e => handleRevisionHistoryEnable(e.currentTarget.value)}
          value={DocumentRevisionOptions.compare}
        >
          <FormattedMessage id="cta.compare" defaultMessage="Compare" />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
