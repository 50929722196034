import { DealType } from './deals.types';

type SetDealActionType = 'setDeal';

export type DealAction = SetDealAction;

type SetDealAction = {
  type: SetDealActionType;
  deal: DealType;
};

export const setDeal = (deal: DealType): DealAction => ({
  type: 'setDeal',
  deal,
});
