import React, { useCallback, useEffect, useRef, useState } from 'react';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useApolloClient as apolloUseApolloClient } from '@apollo/client';
import classNames from 'classnames';
import LoadingSpinner from '../../loading-spinner';
import { queriesV2 } from '../../../api';
import { EditorType } from './utils/lease-editor.types';
import { useCommentContext } from '../../../contexts/commentContext';
import { listProperties } from './constants/EditorConstats';

import {
  ALIGNMENT_CONFIG,
  FONT_COLOR_CONFIG,
  FONT_FAMILY_CONFIG,
  HEADER_FOOTER_FONT_SIZE_CONFIG,
  HIGHLIGHT_CONFIG,
  TABLE_CONFIG,
} from '../../../utils/ckeditor/config/constants';
import './header-footer-editor.scss';
import { LeaseDocumentComment } from '../lease-document.types';

declare global {
  interface Window {
    editor: EditorType;
    editor2: EditorType;
    editor3: EditorType;
    commentsContext: LeaseDocumentComment[];
  }
}

export const HeaderFooterEditor = ({
  editor,
  documentId,
  permissions,
  isDocumentHeaderEditing,
  documentHTML,
  isFromTemplateEditor,
  lockHeaderFooterEditor,
}: any) => {
  const toolbarElem = useRef<HTMLDivElement>(null);
  const sidebarNode = useRef<HTMLDivElement>(document.createElement('div'));

  const apolloClient = apolloUseApolloClient();
  const commentsContext = useCommentContext();
  const [ckeInstance, setCkeInstance] = useState<EditorType | null>(null);
  const [isLayoutReady, setIsLayoutReady] = useState<boolean>(false);
  const checkPendingActions = useCallback(
    (domEvt: any) => {
      if (!ckeInstance) {
        return;
      }

      if (ckeInstance.plugins.get('PendingActions').hasAny) {
        domEvt.preventDefault();
        domEvt.returnValue = true;
      }
    },
    [ckeInstance]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', checkPendingActions);

    return () => {
      window.removeEventListener('beforeunload', checkPendingActions);
    };
  }, [checkPendingActions]);

  useEffect(() => {
    setIsLayoutReady(true);
  }, []);

  useEffect(() => {
    window.commentsContext = commentsContext;
  }, [commentsContext]);

  const tokenUrlCallback = useCallback(() => {
    if (!documentId) {
      return;
    }

    if (isFromTemplateEditor) {
      const queryVariables = { documentTemplateId: documentId };
      const queryPromise = apolloClient.query({
        query: queriesV2.getCkeditorTemplateToken,
        variables: queryVariables,
        fetchPolicy: 'no-cache',
      });

      return queryPromise.then(({ data }: Record<any, any>) => {
        return data.getCkeditorTemplateToken;
      });
    } else {
      const queryPromise = apolloClient.query({
        query: queriesV2.getCkeditorDocumentToken,
        variables: { documentId },
        fetchPolicy: 'no-cache',
      });

      return queryPromise.then(({ data }: Record<any, any>) => data.getCkeditorDocumentToken);
    }
  }, [apolloClient, documentId, isFromTemplateEditor]);

  const hasOnlyCommentsPermission = !permissions.hasEditPermission && permissions.hasCommentPermission;

  const toolbar = (lockHeaderFooterEditor && !isFromTemplateEditor)
    ? []
    : [
      'Undo',
      'Redo',
      '|',
      'heading',
      'Bold',
      'Italic',
      'Underline',
      'Highlight',
      'FontSize',
      'FontFamily',
      'FontColor',
      '|',
      'imageUpload',
      'insertTable',
      '|',
      'Indent',
      'Outdent',
      'alignment',
      '|',
      'pageNumber',
    ];

  const config = {
    licenseKey: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
    cloudServices: {
      tokenUrl: tokenUrlCallback,
      uploadUrl: process.env.REACT_APP_CKEDITOR_IMAGE_UPLOAD_URL,
      webSocketUrl: process.env.REACT_APP_CKEDITOR_WEBSOCKET_URL,
      documentId: documentId,
    },
    toolbar,
    highlight: HIGHLIGHT_CONFIG,
    fontSize: HEADER_FOOTER_FONT_SIZE_CONFIG,
    fontFamily: FONT_FAMILY_CONFIG,
    fontColor: FONT_COLOR_CONFIG,
    table: TABLE_CONFIG,
    //extraPlugins: [customUploadAdapter],
    removePlugins: [
      'EasyImage',
      'ImageCaption',
      'List',
      'TodoListEditing',
      'TodoList',
      'RevisionsRepository',
      'RevisionHistory',
      'UploadAdapter',
      'placeholder',
    ],
    importWord: {
      defaultStyles: true,
      tokenUrl: tokenUrlCallback,
    },
    default_styles: true,
    commentsOnly: hasOnlyCommentsPermission,

    sidebar: {
      container: sidebarNode.current,
      preventScrollOutOfView: true,
    },
    alignment: ALIGNMENT_CONFIG,
    list: listProperties,
    image: {
      styles: [
        'alignLeft',
        'alignRight',
        'alignCenter',
        'alignBlockLeft',
        'alignBlockRight',
        'alignBlockCenter',
        'block',
        'inline',
        'side',
      ],
      toolbar: ['imageStyle:breakText', 'imageStyle:inline'],
    },
    keystrokes: [['CTRL' + 86 /*V*/, false]],
  };

  return (
    <>
      <div className="header-footer-editors-holder">
        <div className="header-footer-editor" id="editor-container">
          <div className="header-footer-editor__toolbar" ref={toolbarElem} />
          <div className="header-footer-editor__editable-container">
            {!ckeInstance && <LoadingSpinner className="spinner__document-preparing" />}
            <div
              id={isDocumentHeaderEditing ? 'HeaderEditor' : 'FooterEditor'}
              className={classNames({ 'd-none': !ckeInstance })}
            >
              {(lockHeaderFooterEditor && !isFromTemplateEditor) && <div className="header-footer-editor__lock-editor-warning">
                Editing this {isDocumentHeaderEditing ? 'Header' : 'Footer'} has been restricted by the Template manager
              </div>}
              {isLayoutReady && (
                <CKEditor
                  editor={editor}
                  config={config}
                  data={''}
                  onReady={(editor: EditorType) => {
                    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                      CKEditorInspector.attach(editor);
                    }
                    editor.setData(documentHTML);
                    setCkeInstance(editor);

                    if (isDocumentHeaderEditing) {
                      window.editor2 = editor;
                    }

                    if (!isDocumentHeaderEditing) {
                      window.editor3 = editor;
                    }

                    // Toolbar
                    if (toolbarElem.current) {
                      toolbarElem.current.innerText = '';
                    }
                    toolbarElem.current?.appendChild(editor.ui.view.toolbar.element);

                    const fontFamily = editor.commands.get('fontFamily');
                    fontFamily.execute({ value: 'Arial, Helvetica, sans-serif' });

                    const dropdownButtonsCollection = document.getElementsByClassName('ck-dropdown__button');

                    const hideBalloonToolbarOnDropdownClick = () => {
                      const visibleBalloon = document.getElementsByClassName('ck-balloon-panel_visible');
                      Array.from(visibleBalloon).forEach((elem: Element) => {
                        elem.classList.remove('ck-balloon-panel_visible');
                      });
                    };

                    Array.from(dropdownButtonsCollection).forEach((item: Element) => {
                      if (item instanceof HTMLElement) {
                        item?.addEventListener('click', hideBalloonToolbarOnDropdownClick);
                      } else return false;
                    });

                    editor.editing.view.document.on('clipboardInput', (evt: any, data: any) => {
                      const height = editor.ui.view.editable.element.offsetHeight;
                      if (height > 140) {
                        evt.stop();
                      }
                    });

                    editor.model.document.on('change', () => {
                      const changes = editor.model.document.differ.getChanges();

                      for (const change of changes) {
                        if (
                          typeof change.attributeNewValue === 'string' &&
                          change.attributeNewValue?.includes('image')
                        ) {
                          editor.execute('imageTypeInline');
                        }
                      }
                    });

                    editor.editing.view.on('change', (evt: any, propertyName: any, newValue: any, oldValue: any) => {
                      const height = editor.ui.view.editable.element.offsetHeight;
                      if (height > 140) {
                        editor.commands.get('imageUpload').forceDisabled('customID');
                        editor.commands.get('insertImage').forceDisabled('customID');
                        editor.commands.get('imageInsert').forceDisabled('customID');
                        editor.commands.get('insertTable').forceDisabled('customID');
                        editor.commands.get('insertText').forceDisabled('customID');
                        editor.commands.get('input').forceDisabled('customID');
                        editor.commands.get('enter').forceDisabled('customID');
                        editor.commands.get('shiftEnter').forceDisabled('customID');
                        editor.commands.get('pageNumber').forceDisabled('customID');
                      } else {
                        editor.commands.get('imageUpload').clearForceDisabled('customID');
                        editor.commands.get('insertImage').clearForceDisabled('customID');
                        editor.commands.get('imageInsert').clearForceDisabled('customID');
                        editor.commands.get('insertTable').clearForceDisabled('customID');
                        editor.commands.get('insertText').clearForceDisabled('customID');
                        editor.commands.get('input').clearForceDisabled('customID');
                        editor.commands.get('enter').clearForceDisabled('customID');
                        editor.commands.get('shiftEnter').clearForceDisabled('customID');
                        editor.commands.get('pageNumber').clearForceDisabled('customID');
                      }
                    });

                    editor.on('destroy', () => {
                      Array.from(dropdownButtonsCollection).forEach((item: Element) => {
                        if (item instanceof HTMLElement) {
                          item?.removeEventListener('click', hideBalloonToolbarOnDropdownClick);
                        } else return false;
                      });
                    });

                    editor.ui.update();
                  }}
                  onError={(error: any) => {
                    console.error('ERROR: ', error);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
