import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import EditExpiryDateFormik from '../../../forms/EditExpiryDateFormContainer';

const EditExpiryDateModal = ({
  showModal,
  onModalHide,
  date,
  organizationRequestId,
}: {
  showModal: boolean;
  onModalHide: () => void;
  date: string;
  organizationRequestId: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal show={showModal} onHide={onModalHide} size="lg">
      <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
        <Modal.Title>
          <header>
            <FormattedMessage id="title.expiry-date-modal" defaultMessage="Edit Membership Expiry" />
          </header>
        </Modal.Title>
      </Modal.Header>
      <EditExpiryDateFormik
        date={date}
        organizationRequestId={organizationRequestId}
        onModalHide={onModalHide}
        bodyContainer={Modal.Body}
        actionsContainer={Modal.Footer}
        renderSecondaryAction={() => (
          <Button variant="link" className="text-secondary" onClick={onModalHide}>
            <FormattedMessage id="cta.cancel" defaultMessage="Cancel" />
          </Button>
        )}
      />
    </Modal>
  );
};

export default EditExpiryDateModal;
