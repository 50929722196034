import { fuzzyInputSearch } from '../../pages/deals/helpers/determineDealsActionModalMessage';

export const filterMembersBy = (teamMember: { fullName?: string }, query: any) => {
  const { fullName } = teamMember;

  if (!query.length) {
    return true;
  }

  return fuzzyInputSearch({ searchValue: fullName || '', filteredText: query });
};
