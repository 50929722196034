import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../../../shared/icons/close-icon.svg';
import { useIntl } from 'react-intl';
import '../../../components/lease-wizard/lease-document-wizard-header/lease-document-wizard-header.scss';
import { TemplateBadgeContainer } from './TemplateBadgeContainer';
import {
  DocumentRevisionSwitcher,
} from '../../../components/lease-wizard/lease-document-wizard-header/document-revision-swithcer/DocumentRevisionSwitcher';
import { DocumentTemplateType } from '../../../shared/templateType';
import { Button } from 'react-bootstrap';
import { TemplateType } from '../../../consts/forms-types';
import CreateExpressTemplateDocument from '../../deals/lease-template-modal/CreateExpressTemplateDocument';

export const KEY_ENTER_CODE = 13;

export type TemplateEditorHeaderProps = {
  title: string;
  templateTitle: string;
  templateId: string;
  teamId: string;
  canEdit: boolean;
  isUploaded: boolean;
  teamEditPermissions: boolean;
  onSaveTitle: (value: string) => void;
  onClose: () => void;
  lastSaved: any;
  templateType: string | DocumentTemplateType;
};

export const TemplatePageHeader = ({
  onClose,
  title,
  canEdit,
  lastSaved,
  onSaveTitle,
  isUploaded,
  templateType,
  templateTitle,
  teamId,
  templateId,
  teamEditPermissions,
}: TemplateEditorHeaderProps) => {
  const intl = useIntl();
  const inputId = 'lease-wizard-title-editor';
  const [titleValue, setTitleValue] = useState(title);
  const [openUseTemplateModal, setUseTemplateModal] = useState(false);
  const [previousValue, setPreviousValue] = useState(titleValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCloseClick = () => {
    onClose();
  };

  const handleSave = useCallback(() => {
    if (!titleValue) {
      setTitleValue(previousValue);
      return;
    }
    onSaveTitle(titleValue);
  }, [onSaveTitle, titleValue, previousValue]);

  const handleChange = useCallback(
    evt => {
      setPreviousValue(titleValue);
      setTitleValue(evt.target.value);
    },
    [titleValue]
  );

  const handleKeyDown = useCallback(evt => {
    // on hit enter
    if (evt.keyCode === KEY_ENTER_CODE) {
      evt.preventDefault();
      setTitleValue(evt.target.value);
      inputRef.current?.blur();
    }
  }, []);

  const labelTitle = canEdit
    ? intl.formatMessage({ id: 'lease-wizard.header.hover.title', defaultMessage: 'Rename title' })
    : '';

  useEffect(() => {
    setTitleValue(title);
  }, [title]);

  const displayUseTemplateButton = (templateType !== TemplateType.CLAUSE && teamEditPermissions) && (
    <div className="lease-document-header__create-template">
      <Button variant="primary" className="mr-2" onClick={() => setUseTemplateModal(true)}>
        Use Template
      </Button>
    </div>
  )

  return (
    <header className="lease-document-wizard-header is-template">
      <nav className="lease-document-wizard-header__actions">
        {!isUploaded && canEdit && <DocumentRevisionSwitcher />}
        <div className="lease-document-wizard-header-title">
          {canEdit && (
            <input
              ref={inputRef}
              type="text"
              id={inputId}
              value={titleValue}
              onChange={handleChange}
              onBlur={handleSave}
              onKeyDown={handleKeyDown}
              className={classNames('lease-document-wizard-header-title__input form-control')}
            />
          )}

          <label
            htmlFor={inputId}
            className={classNames('lease-document-wizard-header-title__label', {
              'lease-document-wizard-header-title__label--can-edit': canEdit,
            })}
            title={labelTitle}
          >
            {titleValue}
          </label>
          <TemplateBadgeContainer templateType={templateType} />
        </div>
        <div className="lease-document-header__last-updated small">Last Saved:&nbsp;{lastSaved}</div>
        {displayUseTemplateButton}
        <CreateExpressTemplateDocument
          isOpen={openUseTemplateModal}
          onClose={() => setUseTemplateModal(false)}
          templateName={templateTitle}
          teamId={teamId}
          templateId={templateId}
        />
      </nav>
    </header>
  );
};
