import jwt_decode from 'jwt-decode';

const AUTH_TOKEN_NAME = 'simplelease-token';

/**
 * Get the remaining time in seconds of a token
 *
 * @function tokenTtl
 * @param {string} token - Token to inspect
 * @returns {number} - Seconds from now to expiry
 */
export function ttl(token: string | undefined) {
  if (!token) {
    return 0;
  }

  const payload: { exp: number } = jwt_decode(token);
  const now = new Date().getTime();
  return Math.floor(payload.exp - now / 1000);
}

/**
 * Get the token from storage
 *
 * @function get
 * @returns {string} - Token
 */
export function get() {
  return localStorage.getItem(AUTH_TOKEN_NAME);
}

/**
 * Remove the token from storage
 *
 * @function remove
 * @returns {void}
 */
export function remove() {
  localStorage.removeItem(AUTH_TOKEN_NAME);
}

/**
 * Set a token in storage
 *
 * @function set
 * @param {string} token - Token to set
 * @returns {void}
 */
export function set(token: string) {
  localStorage.setItem(AUTH_TOKEN_NAME, token);
}
