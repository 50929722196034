import React, { useState } from 'react';
import classNames from 'classnames';
import { Pill, SignerSelect } from './components';
import { ReactComponent as PenSignature } from '../../../../../../../shared/icons/signature-with-pen.svg';
import { ReactComponent as Initials } from '../../../../../../../shared/icons/initials.svg';
import { ReactComponent as Calendar } from '../../../../../../../shared/icons/calendar-2.svg';
import { ReactComponent as Text } from '../../../../../../../shared/icons/text.svg';
import { FieldToAdd } from '../../../../../../../pages/signing/prepareToSigning';
import Tooltip, { ConfirmationTooltip, ErrorTooltip } from '../../../../../../tooltip';
import styles from './styles.module.scss';
import { AddSignerForm } from '../addSignerForm';

export type Signer = {
  id: string;
  email: string;
  order: number;
};

interface InsertFieldsProps {
  addField?: ({ ...keys }: FieldToAdd) => void;
  signers: Signer[];
  className?: string;
  showSignerAddedTooltip: boolean;
  showDuplicatedErrorTooltip: boolean;
  isCreatingSigner: boolean;
  onAddSignerSubmit: (email: string) => void;
  viewer?: HTMLDivElement | null;
}

export enum AnnotationTypes {
  SIGNATURE = 'signature',
  TEXT = 'text',
  DATE = 'date_signed',
  INITIALS = 'initials',
}

const InsertFields = ({
  addField,
  signers,
  className,
  onAddSignerSubmit,
  isCreatingSigner,
  showSignerAddedTooltip = false,
  showDuplicatedErrorTooltip = false,
  viewer,
}: InsertFieldsProps) => {
  const [selectedSignerId, setSelectedSignerId] = useState(signers[0]?.id);

  const dragStart = (e: any) => {
    e.target.style.opacity = 0.5;
    const copy = e.target.cloneNode(true);
    copy.id = 'form-build-drag-image-copy';
    copy.style.width = '250px';
    document.body.appendChild(copy);
    e.dataTransfer.setDragImage(copy, 125, 25);
    e.dataTransfer.setData('text', '');
  };

  const dragEnd = (e: any, type: AnnotationTypes) => {
    if (addField) {
      const selectedSigner = signers.find((s: any) => s.id === selectedSignerId) as Signer;
      let x = e.pageX,
        y = e.pageY;
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (viewer && isSafari) {
        const rect = viewer.getBoundingClientRect();
        x = e.pageX - rect.x - 125;
        y = rect.height - e.pageY - 25;
      }

      // @ts-ignore
      addField({
        type,
        name: type,
        value: selectedSigner.email,
        signerId: selectedSigner.id,
        dropPoint: {
          x,
          y,
        },
      });
    }
    e.target.style.opacity = 1;
    document.body.removeChild(
      // @ts-ignore
      document.getElementById('form-build-drag-image-copy')
    );
    e.preventDefault();
  };

  const selectedSigner = signers.find(s => s.id === selectedSignerId);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <h1 className={styles.title}>
        Add/Insert Signers
        <Tooltip placement="right" testId="insert-signers-tooltip">
          Select a signer and drag fields for signature, initials, date signed and/or text in to document.
        </Tooltip>
      </h1>
      <AddSignerForm onSubmit={(email: string) => onAddSignerSubmit(email)} isCreatingSigner={isCreatingSigner} />
      <div className={styles['fields-wrapper']}>
        <div className={styles['fields-wrapper__signersDropdownContainer']}>
          {/*// @ts-ignore*/}
          <SignerSelect signers={signers} onSelect={setSelectedSignerId} selectedSigner={selectedSigner} />
          <ConfirmationTooltip show={showSignerAddedTooltip} testId="lease-document-tooltip" placement="right" icon="">
            Signer added!
          </ConfirmationTooltip>
          <ErrorTooltip show={showDuplicatedErrorTooltip} testId="lease-document-tooltip" placement="right" icon="">
            This signer already exists
          </ErrorTooltip>
        </div>

        <div className={styles['pills-container']}>
          <Pill
            title="Signature"
            icon={PenSignature}
            onDragStart={e => dragStart(e)}
            onDragEnd={e => dragEnd(e, AnnotationTypes.SIGNATURE)}
            disabled={!selectedSigner}
          />
          <Pill
            title="Initials"
            icon={Initials}
            onDragStart={e => dragStart(e)}
            onDragEnd={e => dragEnd(e, AnnotationTypes.INITIALS)}
            disabled={!selectedSigner}
          />
          <Pill
            title="Date Signed"
            icon={Calendar}
            onDragStart={e => dragStart(e)}
            onDragEnd={e => dragEnd(e, AnnotationTypes.DATE)}
            disabled={!selectedSigner}
          />
          <Pill
            title="Text"
            icon={Text}
            onDragStart={e => dragStart(e)}
            onDragEnd={e => dragEnd(e, AnnotationTypes.TEXT)}
            disabled={!selectedSigner}
          />
        </div>
      </div>
    </div>
  );
};

export default InsertFields;
