import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CreateSuggestionPayload, DocumentTypesEnum } from '../../../utils/ckeditor/trackChangesAdapter/types';
import { getDocumentSuggestionById } from '../../../utils/api/get-document-suggestion-by-id/get-document-suggestion-by-id';
import { mutations, client as apolloClient } from '../../../api';
import { useDispatch } from 'react-redux';
import { setRejectedSuggestion } from '../../../store/lease-document/actions/lease-document.actions';
import { getDocumentById } from '../../../utils/api/get-document-by-id';
import { dispatchLeaseDocumentResponseToStore } from '../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';

type DocumentType = DocumentTypesEnum.DOCUMENT | DocumentTypesEnum.TEMPLATE;

const useSuggestionsHandling = (documentId: string | undefined, documentType: DocumentType, client = apolloClient) => {
  const dispatch = useDispatch();
  const [createDocumentSuggestion] = useMutation(mutations.createDocumentSuggestion);
  const [deleteDocumentSuggestionBySuggestionId] = useMutation(mutations.deleteDocumentSuggestionBySuggestionId);
  const [acceptDocumentSuggestion] = useMutation(mutations.acceptDocumentSuggestionBySuggestionId);
  const [rejectDocumentSuggestion] = useMutation(mutations.rejectDocumentSuggestionBySuggestionId);
  const [pendDocumentSuggestion] = useMutation(mutations.pendDocumentSuggestionBySuggestionId);

  const createSuggestion = useCallback(
    async ({
      type,
      data,
      attributes,
      originalSuggestionId: originalSuggestionExternalId,
      externalId,
    }: CreateSuggestionPayload) => {
      try {
        const response = await createDocumentSuggestion({
          variables: {
            documentId: documentId!,
            documentType,
            type,
            data,
            attributes,
            originalSuggestionExternalId,
            externalId,
          },
        });

        return response!.data!.createDocumentSuggestion;
      } catch (err) {
        console.warn({ err });
      }
    },
    [createDocumentSuggestion, documentId, documentType]
  );

  const getSuggestionById = useCallback(
    async (suggestionId: string) => {
      try {
        return await getDocumentSuggestionById(suggestionId)(client);
      } catch (err) {
        console.warn({ err });
      }
    },
    [client]
  );

  const acceptSuggestionById = useCallback(
    async (suggestionId: string, suggestionsData: string) => {
      try {
        const suggestion = await acceptDocumentSuggestion({
          variables: { documentSuggestionExternalId: suggestionId, data: { suggestionsData }, attributes: {} },
        });
        const response = await getDocumentById(documentId!)(client);
        dispatchLeaseDocumentResponseToStore(dispatch, response);

        return suggestion;
      } catch (err) {
        console.warn({ err });
      }
    },
    [acceptDocumentSuggestion]
  );

  const rejectSuggestionById = useCallback(
    async (suggestionId: string, suggestionsData: string) => {
      try {
        const suggestion = await rejectDocumentSuggestion({
          variables: { documentSuggestionExternalId: suggestionId, data: { suggestionsData }, attributes: {} },
        });
        const response = await getDocumentById(documentId!)(client);
        dispatchLeaseDocumentResponseToStore(dispatch, response);

        dispatch(setRejectedSuggestion(true));

        return suggestion;
      } catch (err) {
        console.warn({ err });
      }
    },
    [rejectDocumentSuggestion]
  );

  const pendSuggestionById = useCallback(
    async (suggestionId: string) => {
      try {
        return await pendDocumentSuggestion({
          variables: { documentSuggestionExternalId: suggestionId },
        });
      } catch (err) {
        console.warn({ err });
      }
    },
    [pendDocumentSuggestion]
  );

  const deleteSuggestionById = useCallback(
    async (suggestionId: string) => {
      try {
        return await deleteDocumentSuggestionBySuggestionId({
          variables: { documentSuggestionExternalId: suggestionId },
        });
      } catch (err) {
        console.warn({ err });
      }
    },
    [deleteDocumentSuggestionBySuggestionId]
  );

  return {
    createSuggestion,
    getSuggestionById,
    deleteSuggestionById,
    acceptSuggestionById,
    rejectSuggestionById,
    pendSuggestionById,
  };
};

export default useSuggestionsHandling;
