import React from 'react'

const HighlightMatches = ({ query, children: originalString = '', component: Component = 'strong' }) => {
  if (query.length === 0) {
    return originalString
  }
  const lowerQuery = query.toLocaleLowerCase()
  const lowerString = originalString.toLocaleLowerCase()

  let pos = 0
  let matchPos = null
  const outputPieces = []
  while ((matchPos = lowerString.indexOf(lowerQuery, pos)) !== -1) {
    if (matchPos > pos) {
      outputPieces.push(<React.Fragment key={pos}>{originalString.substring(pos, matchPos)}</React.Fragment>)
    }
    outputPieces.push(
      <Component key={matchPos}>{originalString.substring(matchPos, matchPos + query.length)}</Component>
    )
    pos = matchPos + query.length
  }
  if (pos < originalString.length) {
    outputPieces.push(<React.Fragment key={pos}>{originalString.substring(pos)}</React.Fragment>)
  }
  return <>{outputPieces}</>
}

export default HighlightMatches
