import React, { useCallback, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useNavigate, paths } from '../../routing';
import { queriesV2 } from '../../api';
import LoadingSpinner from '../../components/loading-spinner';
import { FullPageElement } from '../../components/FullPageElement/FullPageElement';
import { REDIRECT_AFTER_SIGN_URL } from '../../shared/constants/signing';

declare global {
  interface Window {
    eversign: any;
  }
}
const MagicSignPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const signingFlowRef = useRef<{ isLoaded: boolean }>({ isLoaded: false });
  const { loading: isSigningUrlLoading, data: getSigningUrlByTokenData, error } = useQuery(
    queriesV2.getSigningUrlByToken,
    {
      skip: !token,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        token: token!,
      },
    }
  );

  const handleEversignSigned = useCallback(() => {
    window.location.replace(REDIRECT_AFTER_SIGN_URL);
  }, []);

  const handleEversignDeclined = useCallback((): void => {
    navigate(paths.home);
  }, [navigate]);

  const handleEversignError = useCallback(() => {
    navigate(paths.signError);
  }, [navigate]);

  const renderEversign = (signingUrl: string) => {
    setTimeout(() => {
      if (signingFlowRef.current.isLoaded) return;

      if (window.eversign) {
        window.eversign.open({
          url: signingUrl,
          containerID: 'eversignFrame',
          events: {
            signed: handleEversignSigned,
            declined: handleEversignDeclined,
            error: handleEversignError,
          },
        });
      }

      signingFlowRef.current.isLoaded = true;
    }, 0);

    return null;
  };

  if (!token || error) {
    navigate(paths.signError);
  }

  if (isSigningUrlLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FullPageElement id="eversignFrame" show />
      {renderEversign(getSigningUrlByTokenData!.getSigningUrlForToken)}
    </>
  );
};

export default MagicSignPage;
