import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/loading-spinner';
import { useMutation, useQuery } from '@apollo/client';
import { mutations } from '../../../api';
import { getCurrentUsername } from '../../../api/queries';
import { useAuth } from '../../../auth';
import { LeaseEditorErrors } from '../../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { fillPlaceholders, paths } from '../../../routing';
import { ImportedDocumentSupportedContentTypes } from '../../../components/shared/modals/fileUploadModal/types';
import UploadedTemplatesViewer from './UploadedTemplatesViewer';
import { useNotifications } from '../../../notifications';
import { useLeaseDocumentWizardErrorNotifier } from '../../lease-document-wizard/shared/hooks/use-lease-document-wizard-error-notifier/use-lease-document-wizard-error-notifier.hook';
import { DocumentPermissions } from '../../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { graphql } from '../../../gql';
import { useSelector } from 'react-redux';
import {
  selectDocumentImportedAssetContentType, selectDocumentOriginalImportedAssetUrl,
  selectLeaseDocumentName,
} from '../../../store/lease-document/selectors/lease-document.selectors';

export const GET_XFDF_TEMPLATE_ANNOTATIONS_URL = graphql(/* GraphQL */`
  query GetXfdfTemplateAnnotations($documentTemplateId: ID!) {
    getDocumentTemplateById(documentTemplateId: $documentTemplateId) {
      id
      commentsXfdfUrl
      inputXfdfUrl
    }
  }
`);

interface Props {
  templateId: string;
  teamId: string;
  userPermissions: DocumentPermissions;
  templateTitle: string;
  isOrganizationTemplate?: boolean;
  organizationName?: string;
}

const UploadedTemplatesViewerContainer = ({
  templateId,
  userPermissions,
  teamId,
  templateTitle,
  isOrganizationTemplate,
}: Props) => {
  const [{ user }] = useAuth();
  const [, { hide: hideNotifications }] = useNotifications();
  const navigate = useNavigate();
  const isLockedRef = useRef<boolean>(false);
  const [isTemplateLocked, setIsTemplateLocked] = useState(false);
  const userId = user.id;
  const originalDocumentUrl = useSelector(selectDocumentOriginalImportedAssetUrl);
  const storedTemplateTitle = useSelector(selectLeaseDocumentName) || '';

  const notifyError = useLeaseDocumentWizardErrorNotifier();

  const [lockDocumentTemplate, { loading: isLockTemplateLoading }] = useMutation(mutations.lockDocumentTemplate);
  const [unlockDocumentTemplate] = useMutation(mutations.unlockDocumentTemplate);

  const { data: annotationsData, loading: annotationsDataLoading } = useQuery(GET_XFDF_TEMPLATE_ANNOTATIONS_URL, {
    variables: {
      documentTemplateId: templateId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: userData, loading: isUserDataLoading } = useQuery(getCurrentUsername, {
    fetchPolicy: 'network-only',
  });

  const [saveCommentsLayerForDocumentTemplate] = useMutation(mutations.saveCommentsLayerForDocumentTemplate);

  const [saveInputLayerForDocumentTemplate] = useMutation(mutations.saveInputLayerForDocumentTemplate);

  const navigateToTemplatesPage = useCallback(() => {
    const url = fillPlaceholders(paths.templates.landing, { teamId });
    navigate(url);
  }, [teamId, navigate]);

  const handleUnlock = useCallback(() => {
    if (isLockedRef.current) {
      unlockDocumentTemplate({ variables: { documentId: templateId, userId } });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    !isOrganizationTemplate &&
      lockDocumentTemplate({ variables: { documentId: templateId, userId } })
        .then(() => {
          isLockedRef.current = true;
        })
        .catch((err: any) => {
          setIsTemplateLocked(true);
          notifyError({ message: LeaseEditorErrors.TEMPLATE_IS_LOCKED.defaultMessage, pinned: true });
        });

    return () => {
      if (isLockedRef.current) {
        !isOrganizationTemplate && unlockDocumentTemplate({ variables: { documentId: templateId, userId } });
      }
      hideNotifications();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnlock);

    return () => {
      window.removeEventListener('beforeunload', handleUnlock);
    };
  }, []); // eslint-disable-line

  if (annotationsDataLoading || isUserDataLoading || isLockTemplateLoading || !originalDocumentUrl) {
    return <LoadingSpinner />;
  }

  const commentsData = annotationsData!.getDocumentTemplateById!.commentsXfdfUrl!;
  const inputXfdfUrl = annotationsData!.getDocumentTemplateById!.inputXfdfUrl!;

  return (
    <UploadedTemplatesViewer
      template={{
        url: originalDocumentUrl,
        contentType: 'application/pdf',
        id: templateId,
        title: storedTemplateTitle,
        teamId: teamId,
      }}
      hasUserEditPermission={userPermissions.hasEditPermission && !isOrganizationTemplate}
      hasUserCommentPermission={userPermissions.hasCommentPermission && !isOrganizationTemplate}
      saveComments={saveCommentsLayerForDocumentTemplate as any}
      saveFormFields={saveInputLayerForDocumentTemplate as any}
      commentsStringUrl={commentsData}
      formFieldsUrl={inputXfdfUrl}
      userData={userData!.currentUser!}
      isTemplateLocked={isTemplateLocked}
      navigateToTemplatesPage={navigateToTemplatesPage}
      isOrganizationTemplate={isOrganizationTemplate}
    />
  );
};

export default UploadedTemplatesViewerContainer;
