import { ApolloClient } from '@apollo/client';
import { graphql } from '../../gql';

const query = graphql(/* GraphQL */`
  query getDocumentReadinessForSigningStatus($documentId: ID!) {
    getDocumentReadinessForSigningStatus(id: $documentId)
  }
`);

export const getDocumentReadinessForSigning = (documentId: string) => (
  client: ApolloClient<object>
): Promise<any> =>
  client
    .query({
      query,
      variables: { documentId },
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => ({
      isDocumentReadyForSigning: !!data?.getDocumentReadinessForSigningStatus,
    }));
