import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from './index';
import styles from './styles.module.scss';
import Toggle from '../toggle';

type SubscriptionDetailsProps = {
  communicationPreferences: any,
  updateCommunicationPreferences: any,
  refetchUser: any
};

export const DealNotificationsCard = ({ communicationPreferences, updateCommunicationPreferences, refetchUser }: SubscriptionDetailsProps) => {
  return (
    <Card title={null}>
      <div className={styles.subscriptionHeader}>
        <h4>
          <FormattedMessage id="title.emailNotificationDetails" defaultMessage="Email Notifications" />
        </h4>
      </div>

      <div className={styles.cardRow_noBorder}>
        <div className={styles.toggleContainer}>
          <div className={styles.toggleTextContainer}>
            <p className={styles.descriptionTitle}>
              <FormattedMessage
                id="title.deals"
                defaultMessage="Deals {value} Create"
                values={{
                  value: <strong><u>You</u></strong>
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="descriptions.deals"
                defaultMessage="Turn email notifications on by default for future deals you create"
              />
            </p>
          </div>
          <Toggle
            data-testid="your-deals-notification-toggle"
            checked={communicationPreferences.default_notify_for_created_deals}
            className={styles.customToggleStyles}
            isCustomDisabledState={false}
            onChange={async (event: any) => {
              await updateCommunicationPreferences({ variables: { preferences: { defaultNotifyForCreatedDeals: event.currentTarget.checked } } });
              refetchUser();
            }}
            disabled={false}
          />
        </div>
      </div>
      <div className={styles.cardRow_noBorder}>
        <div className={styles.toggleContainer}>
          <div className={styles.toggleTextContainer}>
            <p className={styles.descriptionTitle}>
              <FormattedMessage
                id="title.deals"
                defaultMessage="Deals {value} Create"
                values={{
                  value: <strong><u>Others</u></strong>
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="descriptions.deals"
                defaultMessage="Turn email notifications on by default for future deals others create"
              />
            </p>
          </div>
          <Toggle
            data-testid="others-deals-notification-toggle"
            checked={communicationPreferences.default_notify_for_others_deals}
            className={styles.customToggleStyles}
            isCustomDisabledState={false}
            onChange={async (event: any) => {
              await updateCommunicationPreferences({ variables: { preferences: { defaultNotifyForOthersDeals: event.currentTarget.checked } } });
              refetchUser();
            }}
            disabled={false}
          />
        </div>
      </div>
    </Card>
  );
};
