import { ChangeEvent } from 'react';
import { Checkbox, RadioField } from '../checkbox-or-radio-field';
import { FormattedMessage, useIntl } from 'react-intl';
import slugify from '../../utils/slugify';

interface StatusFormProps {
  filtered: Set<string>;
  setFiltered: (value: Set<string>) => void;
  options: Array<{
    value: string;
    label: {
      id: string;
      defaultMessage: string;
    };
  }>;
  messages: Record<string, any>;
  allLabel?: string;
  type?: string;
}

export const CheckboxForm: React.FC<StatusFormProps> = ({
  filtered,
  setFiltered,
  options,
  messages,
  allLabel,
  type,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Checkbox
        checked={filtered.size === options.length}
        indeterminate={filtered.size > 0 && filtered.size < options.length}
        id={`mobile-template-filter-${type}-_all`}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const checkbox = event.currentTarget;
          if (checkbox.checked) {
            setFiltered(new Set(Object.keys(messages)));
          } else {
            setFiltered(new Set());
          }
        }}
        label={formatMessage({
          id: `templates.filter.${type}.all-statuses`,
          defaultMessage: allLabel,
        })}
      />
      {options.map(({ value: status, label }) => (
        <Checkbox
          key={status}
          value={status}
          checked={filtered.has(status)}
          id={`mobile-template-filter-${type}-${slugify(status)}`}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const checkbox = event.currentTarget;
            const newSet = new Set(filtered);
            if (checkbox.checked) newSet.add(checkbox.value);
            else newSet.delete(checkbox.value);
            setFiltered(newSet);
          }}
          label={<FormattedMessage {...label} />}
        />
      ))}
    </>
  );
};
