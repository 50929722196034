import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseDocumentEditorDisabledAction } from '../actions/lease-document.actions';

export const handleSetLeaseDocumentEditorDisabled = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentEditorDisabledAction
): LeaseDocumentStateType => ({
  ...state,
  isEditorDisabled: action.isEditorDisabled,
});
