import { LeaseDocumentStateType } from '../lease-document.reducer';
import { AddSessionActivityAction } from '../actions/lease-document.actions';

export const handleAddSessionActivity = (
  state: LeaseDocumentStateType,
  action: AddSessionActivityAction
): LeaseDocumentStateType => ({
  ...state,
  sessionActivities: [...state.sessionActivities, action.activity],
});

export const handleResetSessionActivity = (state: LeaseDocumentStateType): LeaseDocumentStateType => ({
  ...state,
  sessionActivities: [],
});
