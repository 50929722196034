import { useCallback } from 'react';
import { ApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { SaveLeaseDocument, SaveLeaseDocumentTemplate } from '../../../../../utils/api/lease-document-interface.types';
import {
  saveDocumentTemplate,
  saveLeaseDocument,
  setLeaseDocument, setLeaseDocumentTemplate,
} from '../../../../../store/lease-document/actions/lease-document.actions';
import {
  selectLeaseDocument,
  selectLeaseDocumentTemplate,
} from '../../../../../store/lease-document/selectors/lease-document.selectors';

type UseLeaseDocumentSaveOptions = {
  id: string | number;
  onSuccess?: () => void;
  onError: () => void;
  save: SaveLeaseDocument;
  apolloClient: ApolloClient<object>;
};

export const useLeaseDocumentSave = ({
  id,
  save,
  onSuccess,
  onError,
  apolloClient,
}: UseLeaseDocumentSaveOptions): ((document: { body: string; cloudDocumentVersion: number }) => void) => {
  const dispatch = useDispatch();
  const storedDocument = useSelector(selectLeaseDocument);

  const toBase64 = (str: string | number | undefined): string => {
    return str ? btoa(unescape(encodeURIComponent(String(str)))) : '';
  }

  return useCallback(
    document => {
      const documentWithHeaderAndFooterBase64 = {
        body: document?.body ? toBase64(document?.body) : undefined,
        cloudDocumentVersion: document?.cloudDocumentVersion,
        header: storedDocument?.header ? toBase64(storedDocument?.header) : undefined,
        footer: storedDocument?.footer ? toBase64(storedDocument?.footer) : undefined,
      };

      dispatch(
        saveLeaseDocument({
          id,
          document: documentWithHeaderAndFooterBase64,
          save,
          apolloClient,
          onSuccess,
          onError,
        })
      );

      if (document) {
        const documentWithHeaderAndFooter = {
          body: document.body,
          cloudDocumentVersion: document.cloudDocumentVersion,
          header: storedDocument?.header || '',
          footer: storedDocument?.footer || '',
        };
        dispatch(setLeaseDocument(documentWithHeaderAndFooter));
      }
    },
    [apolloClient, dispatch, id, onError, onSuccess, save, storedDocument]
  );
};

type UseLeaseDocumentTemplateSaveOptions = {
  id: string | number;
  onSuccess?: () => void;
  onError: () => void;
  save: SaveLeaseDocumentTemplate;
  apolloClient: ApolloClient<object>;
};

export const useLeaseDocumentTemplateSave = ({
  id,
  save,
  onSuccess,
  onError,
  apolloClient,
}: UseLeaseDocumentTemplateSaveOptions): ((document: { body: string; cloudDocumentVersion: number }) => void) => {
  const dispatch = useDispatch();
  const storedDocument = useSelector(selectLeaseDocumentTemplate);

  const toBase64 = (str: string | number | undefined): string => {
    return str ? btoa(unescape(encodeURIComponent(String(str)))) : '';
  }

  return useCallback(
    document => {
      const documentTemplateWithHeaderAndFooterBase64 = {
        body: document?.body ? toBase64(document?.body) : undefined,
        cloudDocumentVersion: document?.cloudDocumentVersion,
        header: storedDocument?.header ? toBase64(storedDocument?.header) : undefined,
        footer: storedDocument?.footer ? toBase64(storedDocument?.footer) : undefined,
      };

      dispatch(
        saveDocumentTemplate({
          id,
          template: documentTemplateWithHeaderAndFooterBase64,
          save,
          apolloClient,
          onSuccess,
          onError,
        })
      );

      if (document) {
        const documentTemplateWithHeaderAndFooter = {
          body: document.body,
          cloudDocumentVersion: document.cloudDocumentVersion,
          header: storedDocument?.header || '',
          footer: storedDocument?.footer || '',
        };
        dispatch(setLeaseDocumentTemplate(documentTemplateWithHeaderAndFooter));
      }
    },
    [apolloClient, dispatch, id, onError, onSuccess, save, storedDocument]
  );
};
