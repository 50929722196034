const getDocumentPlaceholders = (editor) => {
  const placeholders = [];
  const root = editor.model.document.getRoot();

  // Function to traverse the model tree and collect placeholders
  const traverse = node => {
    if (node.is('element', 'placeholder')) {
      placeholders.push(node);
    }

    if (node.is('element')) {
      for (const child of node.getChildren()) {
        traverse(child);
      }
    }
  };

  traverse(root);
  
  return placeholders;
}

export default getDocumentPlaceholders;