import { ApolloClient, gql } from '@apollo/client';
import {
  LeaseDocumentComment,
  LeaseDocumentCommentPayloadType,
} from '../../../components/lease-wizard/lease-document.types';
import { transformCommentsPayload } from '../../transform-comments-payload/transform-comments-payload.util';

const query = gql`
  fragment DocumentCommentFields on CkeDocumentComment {
    id
    attributes
    authorId
    commentId
    content
    createdAt
    isPublic
    threadId
    updatedAt
  }

  query getDocumentCommentsByTemplate($templateId: ID!, $threadId: String!) {
    getDocumentCommentsByTemplate(templateId: $templateId, threadId: $threadId) {
      attributes
      comments {
        ...DocumentCommentFields
      }
      resolvedBy
      resolvedAt
      threadId
    }
  }
`;

export const getDocumentCommentsByTemplate = (templateId: string, threadId: string) => (
  client: ApolloClient<object>
): Promise<LeaseDocumentComment[]> => {
  const variables = {
    templateId,
    threadId,
  };

  return client.query({ query, variables }).then(({ data }: Record<any, any>) => {
    const comments: LeaseDocumentCommentPayloadType[] = data.getDocumentCommentsByTemplate;
    return transformCommentsPayload(comments);
  });
};

export const getCommentsForTemplateSection = (templateId: string, threadId: string) => (
  client: ApolloClient<object>
): Promise<LeaseDocumentComment[]> => {
  const variables = {
    templateId,
    threadId,
  };

  return client.query({ query, variables }).then(({ data }: Record<any, any>) => {
    return data.getDocumentCommentsByTemplate;
  });
};
