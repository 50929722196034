export enum DocumentActionsSuccess {
  SEND = 'The document has been successfully sent for counterparty review.',
  UNSEND = 'Successfully unsent the document.',
  TERMINATE = 'Successfully terminated document.',
}

export enum DocumentActionsFailure {
  SEND = 'Something went wrong. Unable to Send the document.',
  SEND_NOT_SHARED_DEAL = 'Deal must be shared with receiving party before this Document can be shared.',
  SEND_NOT_READY = 'The document cannot be shared until it is in a ready to sign state. Please review the document to ensure all signatures are inserted and there are no pending suggestions.',
  UNSEND = 'Unable to unsend the document.',
  RESEND = 'Unable to resend the document.',
  TERMINATE = 'Unable to terminate document.',
}

export const UNRESOLVED_SUGGESTIONS_TOOLTIP =
  'The document cannot be prepared for signing until all pending suggestions have been resolved.';

export const SHOULD_RETURN_FOR_RECEIVER_REVIEW_TOOLTIP =
  'The document must be sent back to your counterparty to review the latest changes.';

export const INTERNAL_PREPARE_FOR_SIGNING_TOOLTIP =
  'The document cannot be prepared for signing until all pending suggestions have been resolved.';

export const SUGGESTION_MADE_BY_COUNTERPARTY_TOOLTIP =
  'You counterparty must accept or discard suggestions made by your team.';

export const ADD_ORGANIZATIONS_DESCRIPTION =
  'Access template libraries from organizations, of which you may or may not be an existing member.';
