import React from 'react';
import classNames from 'classnames';
import LoadingSpinner from '../../loading-spinner';
import './lease-loading-spinner.scss';

export const LeaseLoadingSpinner = ({ className }: { className?: string }) => (
  <div className={classNames('lease-loading-spinner', className)}>
    <LoadingSpinner />
  </div>
);
