import styles from './styles.module.scss';

export const InlineLoader = () => (
  <div className={styles['lds-ellipsis']}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
