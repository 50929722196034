import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOwnedTeams, selectTeamPermissions } from '../../../../../store/permissions/permissions.selectors';
import {
  GetTeamPermissions,
  getTeamPermissions,
} from '../../../../../utils/api/get-team-permissions/get-team-permissions';
import { ApolloClient } from '@apollo/client';
import { setAllTeams, setOwnedTeams, setTeamPermissions } from '../../../../../store/permissions/permissions.actions';
import { TeamPermissionName } from '../../../../../store/permissions/permissions.reducer';
import { Permissions } from '../../../../../shared/constants/permissions';

export type DocumentPermissions = {
  isAdmin: boolean;
  hasViewPermission: boolean;
  hasCommentPermission: boolean;
  hasEditPermission: boolean;
  hasInviteSendingPermission: boolean;
  hasApprovePermission: boolean;
};

type UseLeaseDocumentPermissions = {
  teamId?: string | null;
  apolloClient: ApolloClient<object>;
};

/**
 * Making sense of the team permissions payload with respect to the team.
 *
 * The following assumptions are made:
 *
 *   If your actual document permissions need to consider other factors, e.g. document states, etc.,
 *   these should be handled outside of the scope of this abstraction and in a separate concern.
 *
 * @param teamId
 * @param apolloClient
 */
export const useLeaseDocumentTeamPermissions = ({
  teamId,
  apolloClient,
}: UseLeaseDocumentPermissions): DocumentPermissions => {
  const dispatch = useDispatch();
  const teamPermissions = useSelector(selectTeamPermissions);
  const ownedTeams = useSelector(selectOwnedTeams);

  const allowedPermissionsForUser: TeamPermissionName[] = useMemo(
    () =>
      teamPermissions
        .filter(({ allowed }) => allowed)
        .filter(({ teamId: permissionTeamId }) => teamId === permissionTeamId)
        .map(({ permissionName }) => permissionName),
    [teamId, teamPermissions]
  );

  /**
   * Fetch permissions if we don't have any, then set them in the store
   */
  useEffect(() => {
    if (!teamId) {
      return;
    }

    getTeamPermissions(teamId!)(apolloClient).then(({ teamPermissions, ownedTeams, allTeams }: GetTeamPermissions) => {
      dispatch(setTeamPermissions(teamPermissions));
      dispatch(setOwnedTeams(ownedTeams));
      dispatch(setAllTeams(allTeams));
    });
  }, [apolloClient, dispatch, teamId]);

  return useMemo(() => {
    /**
     * A user is an admin when they have the specified team ID in their owned teams list
     */
    const isTeamAdmin = teamId ? ownedTeams.includes(String(teamId)) : false;

    const hasViewPermission = allowedPermissionsForUser.includes(Permissions.View) || isTeamAdmin;
    const hasCommentPermission = allowedPermissionsForUser.includes(Permissions.Comment) || isTeamAdmin;
    const hasEditPermission = allowedPermissionsForUser.includes(Permissions.Edit) || isTeamAdmin;
    const hasInviteSendingPermission = allowedPermissionsForUser.includes(Permissions.Invite) || isTeamAdmin;
    const hasApprovePermission = allowedPermissionsForUser.includes(Permissions.Approve) || isTeamAdmin;

    return {
      isAdmin: isTeamAdmin,
      hasViewPermission,
      hasCommentPermission,
      hasEditPermission,
      hasInviteSendingPermission,
      hasApprovePermission,
    };
  }, [ownedTeams, teamId, allowedPermissionsForUser]);
};
