import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import MobileIcon from '../shared/icons/mobileDevice.png';
import Header from '../components/public-layout/header';
import { paths } from '../routing';

export const PROPDOCS_URL = 'https://www.propdocs.com/';

const MobileWarningPage = () => {
  return (
    <section>
      <div className="d-flex flex-column text-center align-items-center">
        <div>
          <Header />
        </div>
        <div className="mt-2">
          <img src={MobileIcon} alt="Mobile logo" width="80%" />
        </div>
        <p className="h1 m-4 font-weight-normal">
          <FormattedMessage id="title.browser" defaultMessage="You need a bigger browser :)" />
        </p>
        <p className="h3 m-2 font-weight-normal">
          For the best experience use <br /> Propdocs on your laptop or desktop.
        </p>
        <p className="h3 m-4 font-weight-bold">
          <a href={PROPDOCS_URL}>
            <FormattedMessage id="cta.go-to.forgot-pass" defaultMessage="Go back to propdocs.com" />
          </a>
        </p>
        <p className="h3 m-2">
          <NavLink className="font-weight-bold text-muted" to={paths.home}>
            <FormattedMessage id="cta.go-to.forgot-pass" defaultMessage="Continue anyway" />
          </NavLink>
        </p>
      </div>
    </section>
  );
};

export default MobileWarningPage;
