import React, { ReactNode } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootstrapTooltip from 'react-bootstrap/Tooltip';
import styles from './styles.module.scss';

interface TooltipProps {
  title: string;
  children: ReactNode;
  style?: { [key: string]: string };
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

const InsertedFieldsTooltip = ({ children, title, style, placement='top' }: TooltipProps) => {
  return (
    <OverlayTrigger
      placement={placement}
      rootCloseEvent="mousedown"
      rootClose
      trigger="hover"
      overlay={
        <BootstrapTooltip
          data-testid={`insert-tooltip-${title}`}
          id={`tooltip-${title}`}
          className={styles.primaryTooltip}
          style={style}
        >
          {title}
        </BootstrapTooltip>
      }
    >
      {children as any}
    </OverlayTrigger>
  );
};

export default InsertedFieldsTooltip;
