import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import Alert from 'react-bootstrap/Alert';
import { useAuth } from '../auth';
import { useNotifications } from '../notifications';
import { paths, useNavigate } from '../routing';
import ErrorMessage from '../components/error-message';
import { TextField } from '../components/fields';
import { api as apiErrorMessages, errorCodeVerificationPending } from '../error-messages';
import SubmitButton from '../components/submit-button';
import { Warning } from '../components/shared/warning';
import styles from './styles.module.scss';
import { NavLink } from 'react-router-dom';

interface RegisterFormProps {
  errors: { [key: string]: string };
  values: { [key: string]: string };
  isSubmitting?: boolean;
  isValid?: boolean;
  activeEmailForConfirmation?: string | null;
  setFieldValue: (fieldName: string, fieldValue: string | boolean) => void;
}

const ConfirmEmailRegisterForm = ({
  errors,
  values,
  isSubmitting,
  isValid,
  activeEmailForConfirmation,
}: RegisterFormProps) => {
  const [, { success: notifySuccess, error: notifyError }] = useNotifications();
  const [, { resendConfirmationEmail }] = useAuth();

  const onResendClick = async () => {
    const { success, errors } = await resendConfirmationEmail({ email: activeEmailForConfirmation! });
    if (success) {
      notifySuccess({
        message: {
          id: 'phrase.reseng-email-confirmation.success',
          defaultMessage: 'Invite has been resent.',
        },
      });
    } else {
      notifyError({ message: errors });
    }
  };
  const messageEmailIsSentForVerification = () => {
    return (
      <span>
        We have sent an email to {activeEmailForConfirmation}. Please click the link in the email to verify your
        account.
        <button className={styles.resendVerificationLink} onClick={onResendClick}>
          Resend Invite
        </button>
      </span>
    );
  };

  return (
    <Form noValidate>
      {errors._general && (
        <Alert variant="danger">
          <ErrorMessage>{errors._general}</ErrorMessage>
        </Alert>
      )}
      {activeEmailForConfirmation && (
        <Warning title="Verification Pending" text={messageEmailIsSentForVerification()} />
      )}
      <TextField
        id="registerForm.email"
        name="email"
        type="email"
        label={<FormattedMessage id="form.email.label" defaultMessage="Email" />}
        autoComplete="username"
      />
      <div className="text-right">
        <SubmitButton
          label={<FormattedMessage id="cta.register" defaultMessage="Sign Up" />}
          disabled={isSubmitting || !isValid || activeEmailForConfirmation === values.email}
          isSubmitting={isSubmitting}
          data-testid="form-submit-button"
          className="w-100"
        />
      </div>
      <div className={styles.dividerContainer}>
        <div className={styles.divider}>
          <FormattedMessage id="cta.sign.or" defaultMessage="OR" />
        </div>
      </div>
      <p className="body-text text-center pt-4">
        <FormattedMessage id="cta.go-to.sign-in-message" defaultMessage="Already have an account?" />
        <NavLink to={paths.auth.login} data-testid="sign-up" className="pl-2">
          <FormattedMessage id="cta.go-to.sign-in" defaultMessage="Sign In" />
        </NavLink>
      </p>
    </Form>
  );
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});

const ConfirmEmailRegisterFormik = () => {
  const [, { signupWithEmailConfirmation }] = useAuth();
  const [, { warning: notifyWarning }] = useNotifications();
  const navigate = useNavigate();
  const [activeEmailForConfirmation, setActiveEmailForConfirmation] = useState<string | null>(null);

  const onSubmit = async (values: { email: string }, actions: any) => {
    if (activeEmailForConfirmation === values.email) {
      return;
    }

    const { success, errors, code: errorCode } = await signupWithEmailConfirmation(values);

    if (success) {
      setActiveEmailForConfirmation(values.email);
      values.email = '';
    } else {
      console.log(errors);
      if (errors.id === apiErrorMessages.existingUser.id) {
        navigate(paths.auth.login);
        notifyWarning({
          message: {
            id: 'phrase.register.email-exists-login',
            defaultMessage:
              'This email address is already associated with an account. Log in, or reset your password if you forgot it.',
          },
        });
      } else {
        errorCode === errorCodeVerificationPending
          ? setActiveEmailForConfirmation(values.email)
          : actions.setErrors(errors);
      }
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        email: '',
      }}
    >
      {formikProps => (
        <ConfirmEmailRegisterForm activeEmailForConfirmation={activeEmailForConfirmation} {...formikProps} />
      )}
    </Formik>
  );
};

export default ConfirmEmailRegisterFormik;
