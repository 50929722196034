export enum HandleTeamActionsEnum {
  ADD_MEMBER = 'Invite to Deal Team',
  ADD_MEMBER_BUTTON = 'Add team member to deal',
  INVITE_NEW_MEMBERS = 'Invite People',
  VIEW_MEMBERS = 'View team members subscribed to this deal.',
  VIEW_AND_ADD_MEMBERS = 'View and add team members subscribed to this deal.',
  CHANGE_PERMISSIONS = 'You can change team member permissions or remove team members later in your teams section.',
}

export enum RemoveTeamMember {
  FROM_DEAL = 'Remove Team Member from this deal',
  FROM_TEAM = 'Remove Team Member From This Workspace',
}
