import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './root.reducer'

// Conditionally compose the store with Redux dev tools if:
//    - we are in development mode
//    - the extension is installed
// This is to prevent the Redux dev tools from being loaded in production
const composeEnhancers =
  process.env.REACT_APP_ENVIRONMENT === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
    : compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store
