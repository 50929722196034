export enum SetApprovalRequirement {
  id = 'toggle.setApprovalRequirement',
  defaultMessage = 'Require all to Approve',
}

export enum RequestApproval {
  id = 'toggle.requestApproval',
  defaultMessage = 'Request Approval',
}

export type RequestReviewModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  modalFilters?: JSX.Element;
  submitDisabled: boolean;
};

export enum RequestReviewThresholdValues {
  ONE = 'one',
  ALL = 'all',
}

export enum ApprovalRequestStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum ApprovalRequestActions {
  APPROVE = 'Approve',
  REJECT = 'Reject',
}

export const requestApprovalSuccessMessage = {
  id: 'document-approval.request.success',
  defaultMessage: 'The document has been successfully sent out for an internal approval.',
};

export const requestReviewSuccessMessage = {
  id: 'document-review.request.success',
  defaultMessage: 'Notification to review the document has been successfully sent out',
};

export const rejectApprovalSuccessMessage = {
  id: 'approval-rejected.action.success',
  defaultMessage: 'Approval rejected',
};

export const confirmApprovalSuccessMessage = {
  id: 'approval-received.action.success',
  defaultMessage: 'Approval received',
};

export const cancelApprovalRequestSuccessMessage = {
  id: 'approval-cancelled.action.success',
  defaultMessage: 'Approval Request cancelled',
};

export interface InternalApprovalReview {
  id: string;
  approvedAt: string;
  rejectedAt: string;
  reviewer: {
    id: string;
    fullName: string;
  };
}

export enum ApprovalRequestThreshold {
  ONE = 'one',
  ALL = 'all',
}
