import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InviteNewTeamMemberModal from '../../../../pages/deals/modals/InviteNewTeamMemberModal';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface DealTeamInviteSectionProps {
  hasInvitePermission: boolean;
}
const DealTeamInviteSection = ({ hasInvitePermission }: DealTeamInviteSectionProps) => {
  const [showAddMember, setShowAddMember] = useState(false);

  return (
    <>
      {hasInvitePermission && (
        <>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button variant="primary" className="w-100" onClick={() => setShowAddMember(true)}>
              <TitleFormattedMessage id="cta.sidebar-add-team-member" defaultMessage="Invite New Team Member" />
            </Button>
          </div>
          <InviteNewTeamMemberModal showModal={showAddMember} hideModal={() => setShowAddMember(false)} />
        </>
      )}
    </>
  );
};

export default DealTeamInviteSection;
