export const dealStatusMessages = {
  in_progress: { id: 'deal-status.in_progress', defaultMessage: 'In Progress' },
  completed: { id: 'deal-status.completed', defaultMessage: 'Completed' },
  lost: { id: 'deal-status.lost', defaultMessage: 'Lost' },
  archived: { id: 'deal-status.archived', defaultMessage: 'Archived' },
};

export const statusOptions = [
  { value: 'in_progress', label: dealStatusMessages.in_progress },
  { value: 'completed', label: dealStatusMessages.completed },
  { value: 'lost', label: dealStatusMessages.lost },
  { value: 'archived', label: dealStatusMessages.archived },
];
