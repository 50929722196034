import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import AddNewDealFormik from '../../../forms/AddNewDealFormContainer';
import { TitleFormattedMessage } from '../../../components/titleFormattedMessage';

interface Props {
  showAddDealModal: boolean;
  onModalHide: () => void;
}

const AddNewDealModal = ({ showAddDealModal, onModalHide }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal show={showAddDealModal} onHide={onModalHide} size="lg">
      <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
        <Modal.Title>
          <header>
            <TitleFormattedMessage id="title.add-team-member" defaultMessage="Create a Deal" />
          </header>
        </Modal.Title>
      </Modal.Header>
      <AddNewDealFormik
        bodyContainer={Modal.Body}
        actionsContainer={Modal.Footer}
        renderSecondaryAction={() => (
          <Button variant="link" className="text-secondary" onClick={onModalHide}>
            <FormattedMessage id="cta.cancel" defaultMessage="Cancel" />
          </Button>
        )}
      />
    </Modal>
  );
};

export default AddNewDealModal;
