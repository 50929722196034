import { useQuery } from '@apollo/client';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { hasUserTeamOwnedOrganization } from '../../api/queries';
import { useAuth } from '../../auth';
import { paths } from '../../routing';
import { useTeamInfo } from '../../team-id-context';
import { get } from '../../utils/latest-team-id';
import LoadingSpinner from '../loading-spinner';
import { SubnavItem } from './nav';
import styles from './styles.module.scss';

const SettingsNav = () => {
  const [{ user }] = useAuth();
  const teamIdLookup = useTeamInfo().teamId;
  const latestTeamId = get(user.id);
  const teamId = teamIdLookup || latestTeamId;

  const {
    loading: isOrganizationsDataLoading,
    data: organizationsData,
    error: organizationsDataLoadingError,
  } = useQuery(hasUserTeamOwnedOrganization, {
    skip: !teamId,
    variables: { teamId },
  });

  if (isOrganizationsDataLoading && !teamId) {
    return <LoadingSpinner />;
  }

  if (organizationsDataLoadingError) {
    return <></>;
  }

  return (
    <div className={styles.settingsNav}>
      <SubnavItem href={paths.settings.organizations} hrefParams={{ teamId }} qa="settings-nav-organizations">
        <FormattedMessage id="nav.organizations" defaultMessage="Organizations" />
      </SubnavItem>
      {organizationsData?.teamById?.organization && (
        <SubnavItem href={paths.settings.requests} hrefParams={{ teamId }} qa="settings-nav-requests">
          <FormattedMessage id="nav.requests" defaultMessage="Org Requests" />
        </SubnavItem>
      )}
      <SubnavItem href={paths.account} qa="settings-nav-account">
        <FormattedMessage id="nav.account" defaultMessage="Account" />
      </SubnavItem>
    </div>
  );
};

export default SettingsNav;
