import React, { ReactChild, ReactFragment, useState } from 'react';
import classNames from 'classnames';

import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';

import { ReactComponent as HelpIcon } from '../../shared/icons/help-icon.svg';
import styles from './styles.module.scss';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
  children: ReactChild | ReactFragment;
  placement?: Placement;
  testId: string;
  variant?: 'primary' | 'secondary' | 'confirmation' | 'error';
  icon?: ReactChild | ReactFragment;
  show?: boolean;
}

interface ClickableTooltipProps {
  content: ReactChild | ReactFragment;
  children?: ReactChild | ReactFragment;
  place?: PlacesType | undefined;
}

interface BoxTooltipProps {
  children: ReactChild | ReactFragment;
  id: string;
  isOpen?: boolean;
}

const ConfirmationTooltip = ({
  children,
  placement = 'top',
  testId,
  show,
  variant = 'confirmation',
  icon = <HelpIcon className={classNames('mx-1', 'text-primary', styles.root)} />,
}: TooltipProps) => {
  return (
    <OverlayTrigger
      placement={placement as Placement}
      show={show}
      overlay={
        <BootstrapTooltip
          data-testid={testId}
          id={testId}
          className={classNames(styles[`confirmationTooltip_${placement}`], {
            [styles.confirmationTooltip]: variant === 'confirmation',
          })}
        >
          {children}
        </BootstrapTooltip>
      }
    >
      <span tabIndex={0}>{icon}</span>
    </OverlayTrigger>
  );
};

const ErrorTooltip = ({
  children,
  placement = 'top',
  testId,
  show,
  variant = 'error',
  icon = <HelpIcon className={classNames('mx-1', 'text-primary', styles.root)} />,
}: TooltipProps) => {
  return (
    <OverlayTrigger
      placement={placement as Placement}
      show={show}
      overlay={
        <BootstrapTooltip
          data-testid={testId}
          id={testId}
          className={classNames(styles[`errorTooltip_${placement}`], {
            [styles.errorTooltip]: variant === 'error',
          })}
        >
          {children}
        </BootstrapTooltip>
      }
    >
      <span tabIndex={0}>{icon}</span>
    </OverlayTrigger>
  );
};

const ClickableTooltip = ({
  content,
  children = <HelpIcon className={classNames('mx-1', 'text-primary', styles.root)} />,
  place = "right"
}: ClickableTooltipProps) => {
  return (
    <>
      <span id="clickable">{children}</span>
      <ReactTooltip
        style={{
          zIndex: '99',
          color: '#18191b',
          backgroundColor: '#ddebef',
          opacity: 0,
          fontWeight: 'normal',
          maxWidth: '300px',
          textAlign: 'center',
          padding: '8px',
          fontSize: '14px'
        }}
        anchorSelect="#clickable"
        place={place}
        clickable
      >
        {content}
      </ReactTooltip>
    </>
  );
};

const BoxTooltip = ({ children, id, isOpen }: BoxTooltipProps) => {
  return (
    <ReactTooltip
      anchorSelect={`#${id}`}
      style={{
        zIndex: '99',
        color: '#18191b',
        backgroundColor: '#fff',
        opacity: 0,
        fontWeight: 'normal',
        maxWidth: '250px',
        borderRadius: '12px',
        padding: '0px',
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
      }}
      className={styles.boxTooltip}
      classNameArrow="tooltip-arrow"
      place="bottom"
      isOpen={isOpen}
      clickable
    >
      {children}
    </ReactTooltip>
  );
};

const Tooltip = ({
  children,
  placement = 'top',
  testId,
  show,
  variant = 'primary',
  icon = <HelpIcon className={classNames('mx-1', 'text-primary', styles.root)} />,
}: TooltipProps) => {
  return (
    <OverlayTrigger
      placement={placement as Placement}
      show={show}
      overlay={
        <BootstrapTooltip
          data-testid={testId}
          id={testId}
          className={classNames(styles[`primaryTooltip_${placement}`], styles.tooltip, {
            [styles.primaryTooltip]: variant === 'primary',
          })}
        >
          {children}
        </BootstrapTooltip>
      }
    >
      <span tabIndex={0}>{icon}</span>
    </OverlayTrigger>
  );
};

export { ClickableTooltip, ConfirmationTooltip, BoxTooltip, ErrorTooltip };

export default Tooltip;
