import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetLeaseDocumentTeamIdAction } from '../actions/lease-document.actions';

export const handleSetLeaseDocumentTeamId = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentTeamIdAction
): LeaseDocumentStateType => ({
  ...state,
  teamId: action.teamId,
});
