import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import { LeaseTemplateModalComponentProps } from './LeaseTemplateModalContainer';

const LeaseTemplateModal = ({ isOpen, onClose, modalFilters }: LeaseTemplateModalComponentProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Modal show={isOpen} onHide={onClose} size="lg">
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title>
            <header>
              <FormattedMessage id="title.first-lease-template" defaultMessage="Select a Template" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalFilters}</Modal.Body>
      </Modal>
    </>
  );
};

export default LeaseTemplateModal;
