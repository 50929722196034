import { ApolloClient } from '@apollo/client';
import { graphql } from '../../../gql';
import { CommentInputType } from "../../../gql/graphql";

export type UpdateComments = {
  templateId: string;
  threadId: string;
  comments: CommentInputType | CommentInputType[];
  unlinkedAt?: string;
};

const mutation = graphql(/* GraphQL */`
  mutation UpdateTemplateComments($templateId: ID!, $threadId: String!, $comments: [CommentInputType!]!, $unlinkedAt: String) {
    updateTemplateComments(templateId: $templateId, comments: $comments, threadId: $threadId, unlinkedAt: $unlinkedAt)
  }
`);

export const updateTemplateComments = (options: UpdateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(() => true);
};

export const updateTemplateCommentsWithResult = (options: UpdateComments) => (client: ApolloClient<object>): Promise<boolean> => {
  const variables = options;

  return client.mutate({ mutation, variables }).then(({ data }: Record<any, any>) => {
    return Promise.resolve(data.updateTemplateComments.success);
  });
};
