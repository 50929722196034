import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSortBy, useTable } from 'react-table';
import { Button } from 'react-bootstrap';
import { TeamMember } from '../lease-wizard/lease-editor/LeaseEditorTypes';
import { Table } from '../table';
import Avatar from '../avatar';
import { ModalActionsEnum } from '../document-table/DocumentTableConstants';
import { setActiveModal } from '../../store/modals/modals.actions';
import { DealModalActionsEnum } from '../../pages/deals/constants/DealConstants';
import { TitleFormattedMessage } from '../titleFormattedMessage';
import styles from './styles.module.scss';
import CounterpartyMobileItem from './CounterpartyMobileItem';

interface CounterpartyMembersListContainerProps {
  counterpartyTeam: TeamMember[];
  isDealAccepted: boolean;
  counterpartyInvitedEmail: string;
  counterpartyAdminUserId: string;
  hasCreatorInvitePermission: boolean;
}

type ColumnsCellType = {
  cell: {
    value: string;
    row: {
      values: {
        id?: string;
        status: string;
        name: {
          value: string;
          isUserPrimaryContact: boolean;
        };
        email: string;
      };
    };
  };
};

export const CounterpartyMembersListContainer = ({
  counterpartyTeam,
  isDealAccepted,
  counterpartyInvitedEmail,
  counterpartyAdminUserId,
  hasCreatorInvitePermission,
}: CounterpartyMembersListContainerProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleActionModal = useCallback(
    (name: string) => {
      dispatch(setActiveModal(name as ModalActionsEnum));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage id="title.documents.name" defaultMessage="Name" />,
        accessor: 'name',
        Cell: ({
          cell: {
            row: {
              values: { name, email },
            },
          },
        }: ColumnsCellType) =>
          isDealAccepted ? (
            <span>
              <Avatar email={email} fullName={name.value} className="mr-2" />
              <span className={name.isUserPrimaryContact ? styles.counterpartyPrimaryContact : ''}>
                <span className="text-truncate" style={{ width: '200px' }}>
                  <FormattedMessage id="cta.go-to.details" defaultMessage={name.value} />
                </span>
                {name.isUserPrimaryContact && <span className="text-muted">(Primary contact)</span>}
              </span>
            </span>
          ) : (
            <span className={styles.counterpartyParticipantName} />
          ),
      },
      {
        Header: <FormattedMessage id="title.deals.template-status" defaultMessage="Email" />,
        accessor: 'email',
        Cell: ({ cell: { value } }: ColumnsCellType) => {
          return (
            <div>
              <span className="text-truncate" style={{ width: '200px' }}>
                <FormattedMessage id="cta.go-to.details" defaultMessage={value} />
              </span>
              {!isDealAccepted && hasCreatorInvitePermission && (
                <div>
                  <span className="align-middle">
                    <FormattedMessage id="permission.user-level.user" defaultMessage="Pending:" />
                  </span>
                  <span className="ml-1">
                    <Button
                      className="p-0"
                      variant="link"
                      onClick={() => handleActionModal(DealModalActionsEnum.CANCEL)}
                    >
                      <TitleFormattedMessage id="cta.clone" defaultMessage="Cancel" />
                    </Button>
                    <span className="pl-1 pr-1 text-primary">|</span>
                    <Button
                      className="p-0"
                      variant="link"
                      onClick={() => handleActionModal(DealModalActionsEnum.RESEND)}
                    >
                      <TitleFormattedMessage id="cta.archive" defaultMessage="Resend" />
                    </Button>
                  </span>
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: (
          <span className="sr-only">
            <FormattedMessage id="title.deals.detailsLink" defaultMessage="Link to Deal Details" />
          </span>
        ),
        accessor: 'handleCounterparty',
        Cell: () => <div style={{ minWidth: '200px' }}></div>,
        disableSortBy: true,
      },
    ],
    [isDealAccepted, handleActionModal, hasCreatorInvitePermission]
  );

  const documentsData = useMemo(() => {
    if (!isDealAccepted) return [{ email: counterpartyInvitedEmail }];
    if (!counterpartyTeam) return [];

    return counterpartyTeam?.map((member: TeamMember) => {
      return {
        id: member.user.id,
        name: {
          value: formatMessage({
            id: `format.name${member.user.id}`,
            defaultMessage: member.user.fullName,
          }),
          isUserPrimaryContact: Number(member.user.id) === Number(counterpartyAdminUserId),
        },
        email: member.user.email,
        handleCounterparty: { id: member.user.id },
      };
    });
  }, [formatMessage, counterpartyTeam, isDealAccepted, counterpartyInvitedEmail, counterpartyAdminUserId]);

  const table = useTable(
    {
      data: documentsData,
      //@ts-ignore
      columns,
    },
    useSortBy
  );

  return (
    <>
      <div className="non-mobile">
        <Table striped isStickyHeader table={table} />
      </div>
      <div className="mobile">
        {documentsData.map((document: any, index) => (
          <CounterpartyMobileItem
            key={document.id}
            member={document}
            isDealAccepted={isDealAccepted}
            hasCreatorInvitePermission={hasCreatorInvitePermission}
            handleActionModal={handleActionModal}
          />
        ))}
      </div>
    </>
  );
};

export default CounterpartyMembersListContainer;
