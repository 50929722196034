import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../../../tooltip';
import CancelApprovalRequestModal from './CancelApprovalRequestModal';
import { InternalApprovalApproversTooltipTable } from './InternalApprovalApproversTooltipTable';
import styles from './InternalApprovalBadge.module.scss';
import { InternalApprovalReview } from './RequestInternalApprovalModalConstants';

interface Props {
  documentId: string;
  dealId: string;
  teamId: string;
  requestId: string;
  hasUserEditPermission: boolean;
  isOneConfirmationRequired: boolean;
  approvalRequestedReviews?: InternalApprovalReview[];
}

export const approversCounter = ({
  approvedReviewsRequests,
  isOneConfirmationRequired,
  totalApprovalReviewRequests,
}: {
  approvedReviewsRequests: number;
  isOneConfirmationRequired: boolean;
  totalApprovalReviewRequests: number;
}) => (
  <span className={styles.internalApprovalBadgeContainer__approversCounter}>
    ({approvedReviewsRequests}/{isOneConfirmationRequired ? '1' : totalApprovalReviewRequests})
  </span>
);

export const InternalApprovalBadgeContainer = ({
  documentId,
  dealId,
  teamId,
  requestId,
  hasUserEditPermission,
  isOneConfirmationRequired,
  approvalRequestedReviews,
}: Props) => {
  const [showCancelApprovalRequestModal, setShowCancelApprovalRequestModal] = useState(false);
  const internalApprovalPendingMessage = 'Internal Approval Pending';
  const totalApprovalReviewRequests = approvalRequestedReviews?.length || 0;
  const approvedReviewsRequests =
    approvalRequestedReviews?.filter((request: InternalApprovalReview) => !!request.approvedAt).length || 0;

  return (
    <>
      <div className={styles.internalApprovalBadgeContainer}>
        <span className="text-muted font-weight-bold">
          <FormattedMessage id="document-internal-approval.badge" defaultMessage={internalApprovalPendingMessage} />
        </span>
        <span className="pl-2">
          <Tooltip
            testId="internal-approvers-counter"
            icon={approversCounter({ approvedReviewsRequests, isOneConfirmationRequired, totalApprovalReviewRequests })}
            placement="bottom-end"
          >
            {isOneConfirmationRequired && <p className={styles.internalApprovalTooltipTitle}>1 Approval Required</p>}
            <InternalApprovalApproversTooltipTable approvalRequestedReviews={approvalRequestedReviews} />
          </Tooltip>
        </span>
        {hasUserEditPermission && (
          <>
            <span className="text-muted font-weight-bold p-2">|</span>
            <span
              className={styles.internalApprovalBadgeContainer__cancelButton}
              onClick={() => {
                setShowCancelApprovalRequestModal(true);
              }}
            >
              Cancel
            </span>
          </>
        )}
      </div>
      <CancelApprovalRequestModal
        onHide={() => {
          setShowCancelApprovalRequestModal(false);
        }}
        show={showCancelApprovalRequestModal}
        documentId={documentId}
        dealId={dealId}
        teamId={teamId}
        requestId={requestId}
      />
    </>
  );
};
