import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownToggle } from '../../components/dropdown';

interface ToggleProps {
  selectedCount: number;
  id: string;
  header?: string;
}

const Toggle = ({ selectedCount, id, header }: ToggleProps) => (
  <Dropdown.Toggle id={id} as={DropdownToggle} className="w-100">
    <FormattedMessage
      id="phrase.team-members-selected"
      defaultMessage={`${header || 'Existing Team Members '}({selectedCount, number})`}
      values={{ selectedCount }}
    />
  </Dropdown.Toggle>
);

export default Toggle;
