import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import useOnClickOff from '../../../utils/use-on-click-off';
import styles from './styles.module.scss';

type LeaseWizardSideNavProps = {
  isCounterparty: boolean;
  className?: string;
  children: any;
  onSave?: () => void;
  onClose?: () => void;
  disableClose?: boolean;
};

const LeaseWizardSideNav = ({
  isCounterparty,
  className,
  children,
  onSave,
  onClose,
  disableClose = false,
}: LeaseWizardSideNavProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [subNavCollapsed, setSubNavCollapsed] = useState(isCounterparty);
  const subNavRef = useRef(null);
  const buttonText = formatMessage({ id: 'nav.toggleSubNav', defaultMessage: 'Toggle document fields' });

  useEffect(() => {
    setSubNavCollapsed(isCounterparty);
  }, [isCounterparty]);

  const onClickOff = () => {
    if (disableClose) return;

    return subNavCollapsed || toggleCollapse();
  };

  useOnClickOff(subNavRef, onClickOff);

  /**
   * toggles the collapsed state of the side bar nav
   */
  function toggleCollapse() {
    if (!subNavCollapsed && onSave) {
      onSave();
    }
    return setSubNavCollapsed(!subNavCollapsed);
  }

  return (
    <div className={classNames(styles.subNavWrapper, className)} ref={subNavRef}>
      <div
        id="lease-wizard-side-nav"
        className={classNames(styles.subNav, { [styles.collapsed]: subNavCollapsed })}
        aria-expanded={!subNavCollapsed}
      >
        {!disableClose && (
          <button
            className={classNames(styles.subNavToggle, 'mt-5')}
            type="button"
            aria-expanded={!subNavCollapsed}
            aria-controls="main-sub-nav"
            aria-label={buttonText}
            title={buttonText}
            onClick={toggleCollapse}
          />
        )}
        <div className={styles.subNavMask}>
          <div className={styles.subNavContent}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LeaseWizardSideNav;
