import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  LeaseDealActionModal
} from "../../../lease-wizard/lease-document-wizard-modal/custom-modals/lease-deal-action-modal/LeaseDealActionModal";
import {
  CANCEL_INVITE_MODAL_MESSAGE,
  RESEND_MODAL_MESSAGE
} from "../../../../pages/deals/helpers/determineDealsActionModalMessage";
import styles from '../../../../pages/deals/styles.module.scss';
import sidebarStyles from '../styles.module.scss';
import classNames from "classnames";
import { TeamMember } from "../../../lease-wizard/lease-editor/LeaseEditorTypes";
import { FormattedMessage } from "react-intl";
import {
  cancelReceiverTeamDealInvite
} from "../../../../utils/api/cancel_receiver_team_deal_invite/cancel_receiver_team_deal_invite";
import { resendDealInviteEmail } from "../../../../utils/api/resend_deal_invite_email/resend_deal_invite_email";
import { ResendDealInviteMessage } from "../../../document-table/DocumentTableConstants";
import { useNotifications } from "../../../../notifications";
import { useApolloClient } from "@apollo/client";
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface CounterpartyInfoItemProps {
  teamMember: TeamMember,
  isDealAccepted: boolean,
  hasCreatorInvitePermission: boolean,
  dealId: string,
  counterpartyInvitedEmail: string,
  counterpartyAdminUserId: string,
}

const CounterpartyInfoItem = (
  {
    teamMember,
    isDealAccepted,
    hasCreatorInvitePermission,
    dealId,
    counterpartyInvitedEmail,
    counterpartyAdminUserId,
  }: CounterpartyInfoItemProps
) => {
  const apolloClient = useApolloClient();
  const [showResendModal, setShowResendModal] = useState(false);
  const [isResendLoading, setResendLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCancelLoading, setCancelLoading] = useState(false);

  const additionalMessageInputRef = useRef<HTMLTextAreaElement>(null);
  const isPrimaryContact = Number(teamMember.user.id) === Number(counterpartyAdminUserId);

  const cancelModelInfo = {
    title: 'Cancel Counterparty Invite',
    submitButtonTitle: 'Cancel Invite',
    cancelButtonTitle: 'Close',
    showAdditionalMessageField: true,
    message: CANCEL_INVITE_MODAL_MESSAGE,
  };

  const resendModelInfo = {
    title: 'Resend Counterparty Invite',
    submitButtonTitle: 'Resend',
    showAdditionalMessageField: false,
    cancelButtonTitle: 'Close',
    message: RESEND_MODAL_MESSAGE,
  };

  const [, { error: notifyError, success: notifySuccess }] = useNotifications();


  const handleFailureResponse = (error: any) => {
    notifyError({
      message: {
        id: 'offers.terminate-lease-offer.error',
        defaultMessage: error || 'Something went wrong.',
      },
    });
  };

  const handleCancel = () => {
    setCancelLoading(true);
    const additionalMessage = additionalMessageInputRef?.current?.value;
    cancelReceiverTeamDealInvite({ dealId, additionalMessage })(apolloClient)
      .then(() => {
        setShowCancelModal(false)
        setCancelLoading(false);
      })
      .catch(error => {
        handleFailureResponse(error);
      })
      .finally(() => {
        setShowCancelModal(false)
        setCancelLoading(false);
      });
  };

  const handleResend = () => {
    setResendLoading(true);
    resendDealInviteEmail(
      dealId,
      counterpartyInvitedEmail
    )(apolloClient)
      .then(() => {
        setShowResendModal(false);
        setResendLoading(false);
        notifySuccess({
          message: {
            id: 'phrase.resend-invitation.success',
            defaultMessage: ResendDealInviteMessage.SUCCESS,
          },
        });
      })
      .catch(error => {
        handleFailureResponse(error);
      })
      .finally(() => {
        setShowResendModal(false);
        setResendLoading(false);
      });
  };

  const renderCancelInviteModal = () => {
    return showCancelModal && (
      <LeaseDealActionModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        isLoading={isCancelLoading}
        handler={handleCancel}
        title={cancelModelInfo.title}
        submitButtonTitle={cancelModelInfo.submitButtonTitle}
        cancelButtonTitle={cancelModelInfo.cancelButtonTitle}
      >
        {cancelModelInfo.message}
        {cancelModelInfo.showAdditionalMessageField && (
          <div className={styles.dealModalInputWrapper}>
              <textarea
                className={styles.dealModalInputWrapper__input}
                ref={additionalMessageInputRef}
                placeholder="Message (Optional)"
              />
          </div>
        )}
      </LeaseDealActionModal>
    )
  }

  const renderResendInviteModal = () => {
    return showResendModal && (
      <LeaseDealActionModal
        show={showResendModal}
        onHide={() => setShowResendModal(false)}
        isLoading={isResendLoading}
        handler={handleResend}
        title={resendModelInfo.title}
        submitButtonTitle={resendModelInfo.submitButtonTitle}
        cancelButtonTitle={resendModelInfo.cancelButtonTitle}
      >
        {resendModelInfo.message}
        {resendModelInfo.showAdditionalMessageField && (
          <div className={styles.dealModalInputWrapper}>
              <textarea
                className={styles.dealModalInputWrapper__input}
                ref={additionalMessageInputRef}
                placeholder="Message (Optional)"
              />
          </div>
        )}
      </LeaseDealActionModal>
    )
  }

  const renderEmail = () => (
    <div>
      <div className="text-truncate" style={{ width: '233px' }}>
        <FormattedMessage id="cta.go-to.details" defaultMessage={teamMember.user.email} />
      </div>
      {!isDealAccepted && hasCreatorInvitePermission && (
        <div>
          <span className="align-middle">
            <FormattedMessage id="permission.user-level.user" defaultMessage="Pending:" />
          </span>
          <span className="ml-1">
            <Button
              className="p-0"
              variant="link"
              onClick={() => setShowCancelModal(true)}
            >
              <TitleFormattedMessage id="cta.clone" defaultMessage="Cancel" />
            </Button>
            <span className="pl-1 pr-1 text-primary">|</span>
            <Button
              className="p-0"
              variant="link"
              onClick={() => setShowResendModal(true)}
            >
              <TitleFormattedMessage id="cta.resend" defaultMessage="Resend" />
            </Button>
          </span>
        </div>
      )}
    </div>
  )

  return (
    <>
      {renderCancelInviteModal()}
      {renderResendInviteModal()}
      <div className={classNames('pr-2 pl-2 pt-3 pb-3', sidebarStyles.teamMemberContainer)}>
        {!!teamMember.user.fullName && (
          <div className={sidebarStyles.counterpartyName}>
            <h4 className="mb-2">{teamMember.user.fullName}</h4>
            {isPrimaryContact && <h4 className="mb-2">(primary)</h4>}
          </div>
        )}
        {renderEmail()}
      </div>
    </>
  );
};

export default CounterpartyInfoItem;
