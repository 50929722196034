import { Plugin } from '@ckeditor/ckeditor5-core';
import {
  clickOutsideHandler,
  ContextualBalloon,
} from '@ckeditor/ckeditor5-ui';
import icon from "./placeholder-icon.svg";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import { TemplateFormView } from './placeholderView';
import getDocumentPlaceholders from './utils/searchPlaceholders';

import './styles.css';

/**
 * Factory function to create the Placeholder UI plugin.
 * @param documentType The type of document, affecting placeholder behavior.
 * @returns The PlaceholderUI plugin class.
 */
const getPlaceholdersUI = ({ documentType }) => {

  /**
   * PlaceholderUI plugin class to define UI components and interactions for placeholders.
   */
  class PlaceholderUI extends Plugin {

    /**
     * Required plugins for PlaceholderUI.
     */
    static get requires() {
      return [ ContextualBalloon ];
    }

    /**
     * Initializes the plugin, setting up UI components and event listeners.
     */
    init() {
      const editor = this.editor;

      const isPlaceholderAdapterAvailable = editor.plugins.has('PlaceholderAdapter');

      if (!isPlaceholderAdapterAvailable) {
        return;
      }

      // Create the balloon and the form view.
      this._balloon = this.editor.plugins.get( ContextualBalloon );
      this.formView = this._createFormView();

      // Register event listener for showing the custom dialog.
      this.editor.on('showCustomDialog', (evt, data) => {
        let selectedElementName = '';
  
        let selectedElementRequired = false;
        
        if (data['uuid']) {
          const uuid = data['uuid']['uuid'];

          const placeholders = getDocumentPlaceholders(this.editor);
  
          const selectedPlaceholder = placeholders.find(placeholder => placeholder.getAttribute('placeholderid') === uuid);
  
          this.editor.model.change(writer => {
  
            this.editor.editing.view.focus();
  
            const selectionRange = writer.createRangeOn(selectedPlaceholder);
  
            writer.setSelection(selectionRange, 'on');
          })

          selectedElementName = selectedPlaceholder.getAttribute('name') || '';
          selectedElementRequired = (selectedPlaceholder.getAttribute('required') === 'true') || (selectedPlaceholder.getAttribute('required') === true);
        }



        this._showUI(selectedElementName, selectedElementRequired);
      });

      // Register event listener for hiding the custom dialog.
      this.editor.on('hideDialogCommand', () => {
        this._hideUI();
      });

      // Add the "placeholder" button to the toolbar.
      editor.ui.componentFactory.add('placeholder', locale => {
        const buttonView = new ButtonView(locale);

        buttonView.set({
          icon,
          withText: false,
          tooltip: () => "Placeholder",
        });

        // Disable the placeholder button when the command is disabled.
        const command = editor.commands.get('showCustomDialog');
        buttonView.bind('isEnabled').to(command);

        this.listenTo(buttonView, 'execute', () => {
          editor.execute('showCustomDialog');
        });

        return buttonView;
      });
    }

    /**
     * Creates and returns the form view for placeholders.
     * @returns The form view for placeholders.
     */
    _createFormView() {
      const editor = this.editor;
      const formView = new TemplateFormView(editor.locale, documentType, editor, this._hideUI);

      // Handle form submission.
      this.listenTo(formView, 'submit', async () => {
        const placeholderAdapter = this.editor.plugins.get('PlaceholderAdapter');

        if (documentType === 'template') {
          const title = formView.templateFormInputView.fieldView.element.value;
          const required = formView.templateFormRequiredCheckbox.isOn;

          if (formView.currentPlaceholder) {
            const placeholderUuid = formView.currentPlaceholder.getAttribute('placeholderid');
            if (placeholderAdapter && placeholderAdapter.adapter && typeof placeholderAdapter.adapter.updatePlaceholder === 'function') {
              const updatedPlaceholder = await placeholderAdapter.adapter.updatePlaceholder({
                isRequired: !!required,
                linkableType: documentType === 'template' ? 'DocumentTemplate' : 'Document',
                placeholderUuid,
                title,
              });

              editor.model.change(writer => {
                const existingElement = editor.model.document.getRoot().getNodeByPath(formView.currentPlaceholder.getPath());
                if (existingElement) {
                  writer.setAttribute('name', title, existingElement);
                  writer.setAttribute('required', !!required, existingElement);
                }
              });

              // editor.execute('placeholder', { value: title, required, placeholderid: placeholderUuid });
            } else {
              console.log('Placeholder Adapter: Cannot access updatePlaceholder function');
            }
          } else {
            let result = {};
            if (placeholderAdapter && placeholderAdapter.adapter && typeof placeholderAdapter.adapter.addPlaceholder === 'function') {
              result = await placeholderAdapter.adapter.addPlaceholder({
                isRequired: required,
                title,
              });
            } else {
              console.log('Placeholder Adapter: Cannot access addPlaceholder function');
            }
            editor.execute('placeholder', { value: title, required, placeholderid: result.uuid });
          }
        }

        if (documentType === 'lease') {
          const placeholderReplacementInput = formView.leaseFormInputView.fieldView.element.value;
          this.editor.execute('replacePlaceholder', placeholderReplacementInput);
        }

        this._hideUI();
        editor.editing.view.focus();
      });

      // Handle form cancellation.
      this.listenTo(formView, 'cancel', () => {
        this._hideUI();
      });

      // Hide the form view when clicking outside the balloon.
      clickOutsideHandler({
        emitter: formView,
        activator: () => this._balloon.visibleView === formView,
        contextElements: [this._balloon.view.element],
        callback: () => this._hideUI(),
      });

      return formView;
    }

    /**
     * Shows the UI with the current placeholder information.
     * @param selectedElementName The name of the selected element.
     * @param required Whether the element is required.
     */
    async _showUI(selectedElementName, required) {
      this._balloon.add({
        view: this.formView,
        position: this._getBalloonPositionData()
      });

      this.formView.setSelectedElementValues(selectedElementName, required);
      this.formView.currentPlaceholder = this.editor.model.document.selection.getSelectedElement();

      this.formView.focus();
    }

    /**
     * Hides the UI and resets the form fields.
     */
    _hideUI() {
      // Clear the input field values and reset the form.
      this.formView.templateFormInputView.fieldView.value = '';
      this.formView.templateFormRequiredCheckbox.isOn = false;
      this.formView.element.reset();

      this._balloon.remove(this.formView);

      // Focus the editing view after hiding the UI.
      this.editor.editing.view.focus();
    }

    /**
     * Returns the position data for the balloon.
     * @returns The position data for the balloon.
     */
    _getBalloonPositionData() {
      const view = this.editor.editing.view;
      const viewDocument = view.document;
      let target = null;

      // Set a target position by converting view selection range to DOM.
      target = () => view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange());

      return {
        target
      };
    }
  }

  return PlaceholderUI;
}

export default getPlaceholdersUI;
