import React, { ChangeEvent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { HeaderFooterEditorContainer } from '../../../components/lease-wizard/lease-editor/HeaderFooterEditorContainer';
import { updateDocument } from '../../../utils/api/update-lease-document-offer/update-document';
import { updateDocumentTemplate } from '../../../utils/api/update-lease-document-offer/update-template-document';
import { useNotifications } from '../../../notifications';
import { useApolloClient, useMutation } from '@apollo/client';
import { dispatchLeaseDocumentResponseToStore } from '../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { useDispatch } from 'react-redux';
import { setLeaseDocumentIsPending } from '../../../store/lease-document/actions/lease-document.actions';
import { mutations } from '../../../api';
import {TitleFormattedMessage} from "../../../components/titleFormattedMessage";
import './headerFooterModal.scss';
import { Checkbox } from '../../../components/checkbox-or-radio-field';
import { Form, Formik } from 'formik';
import SubmitButton from '../../../components/submit-button';
import Tooltip from '../../../components/tooltip';
import { LeaseDocumentTemplateType, LeaseDocumentType } from '../../../components/lease-wizard/lease-document.types';

type CreateDocumentModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  documentId: string;
  teamId: string;
  editorConfiguration?: any;
  document: LeaseDocumentType | LeaseDocumentTemplateType;
  isFromTemplateEditor?: boolean;
};

const AddHeaderAndFooterModalContainer = ({
  isOpen,
  onClose,
  documentId,
  teamId,
  document,
  editorConfiguration,
  isFromTemplateEditor,
}: CreateDocumentModalComponentProps) => {
  const { formatMessage } = useIntl();
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const [, { error: notifyError }] = useNotifications();
  const [isHeaderTabActive, setIsHeaderTabActive] = useState(true);
  const [headerData, setHeaderData] = useState(document?.header || '');
  const [footerData, setFooterData] = useState(document?.footer || '');
  // const [updateDocumentTemplate] = useMutation(mutations.updateDocumentTemplate);
  const [lockTemplateHeader, setLockTemplateHeader] = useState<boolean>(editorConfiguration?.header_restricted_editing)
  const [lockTemplateFooter, setLockTemplateFooter] = useState<boolean>(editorConfiguration?.footer_restricted_editing)
  const handleSaveError = useCallback(() => {
    notifyError(
      formatMessage({
        id: 'shared-offer-page.save-error',
        defaultMessage: 'Unable to save the Document',
      })
    );
  }, [notifyError, formatMessage]);

  const [updateTemplateEditorConfiguration] = useMutation(mutations.updateTemplateEditorConfiguration);

  const onModalClose = () => {
    setIsHeaderTabActive(true);
    setHeaderData(document.header || '');
    setFooterData(document.footer || '');
    onClose();
  };

  const onSubmit = useCallback(async (values) => {
    if (!isFromTemplateEditor) {
      const fieldsForUpdate = {
        documentId,
        document: {
          header: isHeaderTabActive ? btoa(unescape(encodeURIComponent(String(window.editor2.getData())))) : btoa(unescape(encodeURIComponent(String(headerData)))),
          footer: !isHeaderTabActive ? btoa(unescape(encodeURIComponent(String(window.editor3?.getData())))) : btoa(unescape(encodeURIComponent(String(footerData)))),
        },
      };
      return updateDocument(fieldsForUpdate)(apolloClient)
        .then((response: any) => {
          dispatchLeaseDocumentResponseToStore(dispatch, response, false);
          const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

          if (!documentPendingActionsPlugin) {
            return window.location.reload();
          }

          if (!documentPendingActionsPlugin.hasAny) {
            return window.location.reload();
          }

          documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
            if (!documentPendingActionsPlugin.hasAny) {
              event.off();
              event.stop();
              return window.location.reload();
            }
          });
        })
        .catch(err => {
          handleSaveError();
        })
        .finally(() => {
          dispatch(setLeaseDocumentIsPending(false));
        });
    } else {
      const fieldsForUpdate = {
        documentTemplateId: documentId,
        template: {
          header: isHeaderTabActive ? btoa(unescape(encodeURIComponent(String(window.editor2.getData())))) : btoa(unescape(encodeURIComponent(String(headerData)))),
          footer: !isHeaderTabActive ? btoa(unescape(encodeURIComponent(String(window.editor3?.getData())))) : btoa(unescape(encodeURIComponent(String(footerData)))),
        },
      };
      await updateTemplateEditorConfiguration({
        variables: {
          templateId: documentId,
          configuration: {
            headerRestrictedEditing: lockTemplateHeader,
            footerRestrictedEditing: lockTemplateFooter,
          },
        },
      });
      return updateDocumentTemplate(fieldsForUpdate)(apolloClient).then((response) => {
        dispatchLeaseDocumentResponseToStore(dispatch, response, false);
        const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

        if (!documentPendingActionsPlugin) {
          return window.location.reload();
        }

        if (!documentPendingActionsPlugin.hasAny) {
          return window.location.reload();
        }

        documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
          if (!documentPendingActionsPlugin.hasAny) {
            event.off();
            event.stop();
            return window.location.reload();
          }
        });
      })
        .catch(err => {
          handleSaveError();
        })
        .finally(() => {
          dispatch(setLeaseDocumentIsPending(false));
        });
    }
  }, [isFromTemplateEditor, documentId, isHeaderTabActive, headerData, footerData, apolloClient, dispatch, handleSaveError, updateTemplateEditorConfiguration, lockTemplateHeader, lockTemplateFooter]);

  const renderHeaderFooterLock = () => {
    if (!isFromTemplateEditor) return null;
    return isHeaderTabActive
      ? (
        <div className="headerFooterLockContainer">
          <Checkbox
            checked={lockTemplateHeader}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const checkbox = event.currentTarget;
              setLockTemplateHeader(checkbox.checked)
            }}
            label={<>
              <FormattedMessage id="headerFooterEditor.lockHeader" defaultMessage="Lock Header" />
              <Tooltip placement="top" testId="lock-footer-tooltip">
                Prevent anyone who uses this template in a document from editing the header
              </Tooltip>
            </>}
            key="lockedHeader"
            id="lockedHeader"
          />
        </div>
      )
      : (
        <div className="headerFooterLockContainer">
          <Checkbox
            checked={lockTemplateFooter}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const checkbox = event.currentTarget;
              setLockTemplateFooter(checkbox.checked)
            }}
            label={<>
                <FormattedMessage id="headerFooterEditor.lockFooter" defaultMessage="Lock Footer" />
                <Tooltip placement="top" testId="lock-footer-tooltip">
                  Prevent anyone who uses this template in a document from editing the footer
                </Tooltip>
              </>}
            key="lockedFooter"
            id="lockedFooter"
          />
        </div>
      );
  }

  // const renderModalFooter = () => {
  //   // If isTemplate && isOrgTempalte && hasEditPermission
  //   return isFromTemplateEditor &&
  // }

  const headerFooterClass = isFromTemplateEditor
    ? 'editorFooterActionsContainer'
    : 'editorFooterActionsContainerWithoutLock';

  return (
    <Modal enforceFocus={false} show={isOpen} onHide={onModalClose} className="headerFooterModalContainer" size="lg">
      <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
        <Modal.Title className="modalHeaderContainer">
          <div
            className={classNames('modalHeaderTab', { activeTab: isHeaderTabActive })}
            onClick={() => {
              setFooterData(window.editor3.getData());
              window.editor2.setData(headerData);
              setIsHeaderTabActive(true);
            }}
          >
            <FormattedMessage id="title.first-lease-template" defaultMessage="Header" />
          </div>
          <div
            className={classNames('modalFooterTab', { activeTab: !isHeaderTabActive })}
            onClick={() => {
              setHeaderData(window.editor2.getData());
              window.editor3.setData(footerData || '');
              setIsHeaderTabActive(false);
            }}
          >
            <FormattedMessage id="title.first-lease-template" defaultMessage="Footer" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={classNames('headerEditor', { activeTab: isHeaderTabActive })}>
          <HeaderFooterEditorContainer
            documentId={documentId}
            isDocumentHeaderEditing={isHeaderTabActive}
            document={document}
            isFromTemplateEditor={isFromTemplateEditor}
            lockHeaderFooterEditor={editorConfiguration?.header_restricted_editing}
          />
        </div>
        <div className={classNames('footerEditor', { activeTab: !isHeaderTabActive })}>
          <HeaderFooterEditorContainer
            documentId={documentId}
            isDocumentHeaderEditing={!isHeaderTabActive}
            document={document}
            isFromTemplateEditor={isFromTemplateEditor}
            lockHeaderFooterEditor={editorConfiguration?.footer_restricted_editing}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Formik onSubmit={onSubmit} initialValues={{ lockedHeader: false, lockedFooter: false }}>
          {formikProps => (
            <Form noValidate className={headerFooterClass}>
              {renderHeaderFooterLock()}
              <div className="editorFooterActionButtons">
                <Button variant="link" className="text-secondary" onClick={onModalClose}>
                  <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
                </Button>
                <SubmitButton variant="secondary" disabled={false} label="Save & Close" />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Footer>
    </Modal>
  );
};

export default AddHeaderAndFooterModalContainer;
