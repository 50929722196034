import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CloseIcon } from '../../../shared/icons/close-icon.svg';
import { ReactComponent as PlaceholdersIcon } from '../../../shared/icons/placeholders-icon.svg';
import { DocumentPermissions } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import styles from './styles.module.scss';
import Tooltip from '../../tooltip';
import PlaceholderSidebarContainer from '../lease-editor/components/placeholderSidebar/placeholderSidebarContainer';
import { useSelector } from 'react-redux';
import { selectDocumentRestrictedEditingMode } from '../../../store/lease-document/selectors/lease-document.selectors';

type DocumentActivitiesAndTemplatesNavigationProps = {
  isCollapsible: boolean;
  onClose: () => void;
};

enum ActiveSidenavPanel {
  PLACEHOLDERS = 'placeholders',
}

const TemplateEditorSidenav = ({
  isCollapsible,
  onClose,
}: DocumentActivitiesAndTemplatesNavigationProps): JSX.Element => {
  const [activeSidenavPanel, setActiveSidenavPanel] = useState<ActiveSidenavPanel | null>(null);
  const [shouldShowToggleButton, setShouldShowToggleButton] = useState(false);
  const subNavRef = useRef(null);

  const isTemplateRestricted = useSelector(selectDocumentRestrictedEditingMode);

  const setActiveSidenav = (activePanel: ActiveSidenavPanel | null) => {
    setActiveSidenavPanel(activeSidenavPanel === activePanel ? null : activePanel);
  };

  const toggleCollapse = () => {
    setActiveSidenavPanel(null);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <div className={classNames(styles.subNavMenuWrapper)} ref={subNavRef}>
      <div className={styles.documentNavigationBar}>
        <button
          type="button"
          onClick={handleClose}
          className={styles.closeButton}
          data-testid="document-header__close-cta"
        >
          <Tooltip
            testId="sidebar-button-activity"
            placement="bottom"
            icon={<CloseIcon className={styles.closeIcon} />}
          >
            Exit
          </Tooltip>
        </button>
          {!isTemplateRestricted && (
          <div
            className={classNames(styles.documentNavigationBar__icon, {
              [styles.active]: isCollapsible && activeSidenavPanel === ActiveSidenavPanel.PLACEHOLDERS,
              [styles.enabled]: isCollapsible,
            })}
            onClick={() => setActiveSidenav(ActiveSidenavPanel.PLACEHOLDERS)}
          >
            <Tooltip testId="sidebar-button-team" placement="bottom" icon={<PlaceholdersIcon height="22" width="20" />}>
              <FormattedMessage id="nav.placeholders" defaultMessage="Placeholders" />
            </Tooltip>
          </div>
        )}
      </div>
      {isCollapsible && (
        <div
          id="lease-wizard-side-nav"
          className={classNames(styles.subNav, { [styles.collapsed]: !activeSidenavPanel })}
          aria-expanded={!activeSidenavPanel}
          onMouseEnter={() => setShouldShowToggleButton(true)}
          onMouseLeave={() => setShouldShowToggleButton(false)}
        >
          <button
            className={classNames(styles.subNavToggle, { [styles.subNavToggle__hidden]: !shouldShowToggleButton })}
            type="button"
            aria-expanded={!activeSidenavPanel}
            aria-controls="main-sub-nav"
            onClick={toggleCollapse}
          />
          <div className={styles.subNavMask}>
            <div className={styles.subNavContent}>
              <div style={{ display: `${activeSidenavPanel === ActiveSidenavPanel.PLACEHOLDERS ? 'block' : 'none'}` }}>
                <PlaceholderSidebarContainer activeUuid={null} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateEditorSidenav;
