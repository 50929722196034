const TextSvgComponent = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="512.000000pt"
      viewBox="0 0 2048.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <rect x="0" y="0" width="100%" height="100%" fill="#afafaf" />
      <g transform="translate(50.000000,450.000000) scale(0.07500000,-0.07500000)" fill="currentColor" stroke="none">
        <path
          d="M78 5099 c-23 -12 -46 -35 -58 -59 -19 -37 -20 -58 -20 -451 0 -404
0 -412 22 -450 36 -64 80 -79 231 -79 l127 0 0 -1500 0 -1500 -127 0 c-151 0
-195 -15 -231 -79 -22 -38 -22 -46 -22 -450 0 -393 1 -414 20 -451 13 -26 34
-47 60 -60 37 -19 58 -20 451 -20 404 0 412 0 450 22 64 36 79 80 79 231 l0
127 1500 0 1500 0 0 -127 c0 -151 15 -195 79 -231 38 -22 46 -22 450 -22 393
0 414 1 451 20 26 13 47 34 60 60 19 37 20 58 20 451 0 404 0 412 -22 450 -36
64 -80 79 -231 79 l-127 0 0 1500 0 1500 127 0 c151 0 195 15 231 79 22 38 22
46 22 450 0 393 -1 414 -20 451 -13 26 -34 47 -60 60 -37 19 -58 20 -451 20
-404 0 -412 0 -450 -22 -64 -36 -79 -80 -79 -231 l0 -127 -1500 0 -1500 0 0
127 c0 152 -17 201 -81 233 -36 19 -60 20 -451 20 -391 -1 -415 -2 -450 -21z
m682 -509 l0 -230 -230 0 -230 0 0 230 0 230 230 0 230 0 0 -230z m4060 0 l0
-230 -230 0 -230 0 0 230 0 230 230 0 230 0 0 -230z m-760 -277 c0 -219 34
-253 253 -253 l127 0 0 -1500 0 -1500 -127 0 c-219 0 -253 -34 -253 -253 l0
-127 -1500 0 -1500 0 0 127 c0 156 -16 199 -84 232 -36 18 -62 21 -169 21
l-127 0 0 1500 0 1500 127 0 c219 0 253 34 253 253 l0 127 1500 0 1500 0 0
-127z m-3300 -3783 l0 -230 -230 0 -230 0 0 230 0 230 230 0 230 0 0 -230z
m4060 0 l0 -230 -230 0 -230 0 0 230 0 230 230 0 230 0 0 -230z"
        />
        <path
          d="M1420 3731 c-19 -10 -45 -33 -57 -51 -23 -32 -23 -37 -23 -354 0
-305 1 -323 20 -354 26 -42 99 -76 144 -68 46 9 93 41 113 78 15 28 18 65 21
251 l4 217 384 0 384 0 0 -900 0 -900 -156 0 c-174 0 -207 -9 -247 -64 -16
-22 -22 -45 -22 -83 0 -56 18 -90 69 -127 27 -21 38 -21 506 -21 468 0 479 0
506 21 71 53 92 130 52 198 -39 67 -69 76 -250 76 l-158 0 0 900 0 900 384 0
384 0 4 -217 c3 -186 6 -223 21 -251 20 -37 67 -69 113 -78 45 -8 118 26 144
68 19 31 20 49 20 354 0 317 0 322 -22 354 -13 18 -39 41 -58 51 -33 18 -83
19 -1140 19 -1057 0 -1107 -1 -1140 -19z"
        />
      </g>
    </svg>
  );
};

export default TextSvgComponent;
