import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import RemoveTeamMemberConfirmationModal from './RemoveTeamMemberConfirmationModal';
import { getTeamById } from '../../../../api/queries';
import { mutations, queriesV2 } from '../../../../api';
import { useNotifications } from '../../../../notifications';
import { useDealId } from '../../../../deal-id-context';
import { useTeamInfo } from '../../../../team-id-context';

interface RemoveTeamMemberConfirmationModalContainerProps {
  userToRemove: any;
  setUserToRemove: (user: any) => void;
}

const RemoveTeamMemberConfirmationModalContainer = ({
  userToRemove,
  setUserToRemove,
}: RemoveTeamMemberConfirmationModalContainerProps) => {
  const [, { success: notifySuccess = () => {}, error: notifyError = () => {} }] = useNotifications();
  const dealId = useDealId();
  const { teamId } = useTeamInfo();

  const [removeTeamMemberFromTeam] = useMutation(mutations.removeTeamMemberFromTeam);

  const handleRemoveTeamMemberFromTeam = useCallback(async () => {
    const removeTeamMemberFromTeamRefetchQueries = dealId
      ? [
          {
            query: getTeamById,
            variables: { teamId },
          },
          {
            query: queriesV2.getDealTeamsByTeamId,
            variables: {
              dealId: dealId,
            },
          },
        ]
      : [
          {
            query: getTeamById,
            variables: { teamId },
          },
        ];
    setUserToRemove(null);
    try {
      await removeTeamMemberFromTeam({
        variables: {
          userId: userToRemove.id,
          teamId,
          refetchQueries: removeTeamMemberFromTeamRefetchQueries,
        },
      });
      notifySuccess({
        message: {
          id: 'phrase.remove-team-member.success',
          defaultMessage: '{fullName} has been removed from the team.',
          values: userToRemove,
        },
      });
    } catch (error) {
      notifyError({ message: error });
    }
  }, [notifyError, notifySuccess, removeTeamMemberFromTeam, setUserToRemove, teamId, userToRemove, dealId]);

  const handleModalHide = () => {
    setUserToRemove(null);
  };

  return (
    <RemoveTeamMemberConfirmationModal
      onHide={handleModalHide}
      onClick={handleRemoveTeamMemberFromTeam}
      userToRemove={userToRemove}
    />
  );
};

export default RemoveTeamMemberConfirmationModalContainer;
