import clsx from 'classnames';

import styles from './styles.module.scss';

interface DropdownPanelBodyProps {
  children: React.ReactNode;
  isOpen?: boolean;
}

export const DropdownPanelBody: React.FC<DropdownPanelBodyProps> = ({ children, isOpen }) => {
  return <div className={clsx(styles.dropdown__body, { [styles.dropdown__body__open]: isOpen })}>{children}</div>;
};
