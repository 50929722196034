import React from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as ChevronUpIcon } from '../../shared/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../shared/icons/chevron-down.svg';
import styles from './styles.module.scss';

export { Dropdown };

export const DropdownBody = ({ children, as: Component = 'div' }) => (
  <Component className={styles.dropdownBody}>{children}</Component>
);

export const DropdownItem = ({ children, highlightable = false, as: Component = 'div' }) => (
  <Component className={classNames(styles.dropdownItem, { [styles.highlightable]: highlightable })}>
    {children}
  </Component>
);

export const DropdownToggle = React.forwardRef(({ children, className, contentClassName, ...rest }, ref) => (
  <Button
    ref={ref}
    variant=""
    className={classNames(className, styles.dropdownToggle, 'd-flex justify-content-between align-items-center')}
    {...rest}
  >
    <span className={contentClassName}>{children}</span>
    <span className="ml-2 text-primary">{rest['aria-expanded'] ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
  </Button>
));

export const DropdownTogglePeople = React.forwardRef(({ children, className, contentClassName, ...rest }, ref) => (
  <Button
    ref={ref}
    className={classNames(className, styles.dropdownTogglePeople, 'd-flex align-items-center')}
    {...rest}
    variant=""
  >
    <span className={contentClassName}>{children}</span>
    <span className="ml-2 text-primary">{rest['aria-expanded'] ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
  </Button>
));

export const DropdownToggleOrange = React.forwardRef(({ children, className, contentClassName, ...rest }, ref) => (
  <Button
    ref={ref}
    variant="primary"
    className={classNames(className, styles.dropdownTitleOrange, 'd-flex justify-content-between align-items-center')}
    {...rest}
  >
    <span className={contentClassName}>{children}</span>
    <span className="ml-2">{rest['aria-expanded'] ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
  </Button>
));

export const DropdownToggleBlue = React.forwardRef(({ children, className, contentClassName, ...rest }, ref) => (
  <Button
    ref={ref}
    variant="primary"
    className={classNames(className, styles.dropdownTitle, 'd-flex justify-content-between align-items-center')}
    {...rest}
  >
    <span className={contentClassName}>{children}</span>
    <span className="ml-2">{rest['aria-expanded'] ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
  </Button>
));

export const DropdownToggleIcon = React.forwardRef(({ children, className, contentClassName, ...rest }, ref) => (
  <span
    ref={ref}
    variant=""
    className={classNames(styles.toggleIcon, 'd-flex justify-content-between align-items-center')}
    {...rest}
  >
    <span className={contentClassName}>{children}</span>
  </span>
));
