import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentPlaceholderRefreshKeyAction } from '../actions/lease-document.actions';

export const handleSetDocumentPlaceholderRefreshKey = (
  state: LeaseDocumentStateType,
  action: SetDocumentPlaceholderRefreshKeyAction
): LeaseDocumentStateType => ({
  ...state,
  placeholderRefreshKey: action.placeholderRefreshKey,
});
