import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

class CommentsHidePlugin extends Plugin {
    static get requires() {
        return ['Comments']
    }

    init() {
        this.editor.conversion.for('editingDowncast').markerToHighlight({
            model: 'comment',
            view: (data, conversionApi) => {
                const [ , commentType, commentId ] = data.markerName.split( ':' );

                const commentsRepositoryPlugin = this.editor.plugins.get('CommentsRepository');
                const thread = commentsRepositoryPlugin.getCommentThread(commentType)

                if (!thread) return {};

                const isThreadPrivate = !![...thread.comments].find(comment => comment.content === '[PRIVATE]');

                // TODO commented out for now, as not working, should figure out a way to run conditional converting.
                // if (isThreadPrivate) {
                //     return {};
                // }
            },
            // converterPriority: 'high'
        })
    }
}

export default CommentsHidePlugin;
