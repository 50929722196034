import DOMPurify from 'dompurify';

export type TEmailData = {
  htmlContent: string;
  emailAttachments: [{ filename: string; url: string }];
  sender: string;
  createdAt: string;
  subject: string;
};

interface ISanitizedEmailHTML {
  mainContent: { __html: string };
  nestedMessageLog: { __html: string };
}

const outlookEmailContentClassname = 'elementToProof';

export const sanitizedEmailHTML = (emailData: TEmailData): ISanitizedEmailHTML => {
  const parser = new DOMParser();

  const emailHTML = emailData?.htmlContent;
  const parsedEmail = parser.parseFromString(emailHTML, 'text/html');
  const outlookMainContentCollection = parsedEmail.getElementsByClassName(outlookEmailContentClassname);
  const outlookSignature = parsedEmail.getElementById('Signature');
  const outlookContainter = document.createElement('div');

  if (outlookMainContentCollection.length) {
    for (let i = 0; i < outlookMainContentCollection.length; i++) {
      outlookContainter.appendChild(outlookMainContentCollection[i]);
    }
    outlookSignature && outlookContainter.appendChild(outlookSignature);
  }

  const emailContentContainer = parsedEmail.querySelector('div');

  const emailContentContainerParent = emailContentContainer?.parentNode;
  !outlookMainContentCollection.length &&
    emailContentContainer &&
    emailContentContainerParent?.removeChild(emailContentContainer);

  return {
    mainContent: {
      __html: !!outlookMainContentCollection.length
        ? DOMPurify.sanitize(outlookContainter?.innerHTML)
        : emailContentContainer
        ? DOMPurify.sanitize(emailContentContainer?.innerHTML)
        : '',
    },
    nestedMessageLog: {
      __html: DOMPurify.sanitize(parsedEmail.documentElement.innerHTML),
    },
  };
};
