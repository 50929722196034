import { ApolloClient } from '@apollo/client';
import { TeamWithParty } from '../../../store/permissions/permissions.reducer';
import { graphql } from '../../../gql';

type GetTeamsForUser = {
  ownedTeams: string[];
  allTeams: TeamWithParty[];
};

/**
 * Get a user's teams before trying to figure out which team ID to use on a lease document
 */
export const getTeamsForUser = () => (client: ApolloClient<object>): Promise<GetTeamsForUser> => {
  const query = graphql(/* GraphQL */`
    query currentUser {
      currentUser {
        id
        allTeams {
          teamId: id
          party {
            name
          }
        }
        ownedTeams {
          id
        }
      }
    }
  `);

  return client.query({ query }).then(({data}) => {
    const { ownedTeams, allTeams } = data.currentUser!;

    return {
      ownedTeams: ownedTeams.map(({ id }) => id),
      allTeams,
    };
  });
};
