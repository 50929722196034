import { combineReducers } from 'redux';

// Reducers
import { dealsReducer } from './deals/deals.reducer';
import { leaseDocumentReducer } from './lease-document/lease-document.reducer';
import { modalsReducer } from './modals/modals.reducer';
import { permissionsReducer } from './permissions/permissions.reducer';
import { navigationReducer } from './navigation/navigation.reducer';

const rootReducer = combineReducers({
  deals: dealsReducer,
  leaseDocument: leaseDocumentReducer,
  modals: modalsReducer,
  permissions: permissionsReducer,
  navigation: navigationReducer,
});

export default rootReducer;
