import { createSelector, Selector } from 'reselect';
import { RootReducer } from '../../store.types';
import { LeaseDocumentStateType } from '../lease-document.reducer';
import {
  DocumentApprovalRequestType,
  DocumentSuggestion,
  LeaseDocumentTemplateType,
  LeaseDocumentType,
} from '../../../components/lease-wizard/lease-document.types';
import { DocumentSourceEnum } from '../../../components/lease-wizard/lease-editor/constants/EditorConstats';
import { ImportedDocumentSupportedContentTypes } from '../../../components/shared/modals/fileUploadModal/types';
import { DocumentSigner } from '../actions/set-lease-document-signers.action';

export const selectLeaseDocumentRoot: Selector<RootReducer, LeaseDocumentStateType> = (
  state: RootReducer
): LeaseDocumentStateType => state.leaseDocument;

export const selectLeaseDocument: Selector<RootReducer, LeaseDocumentType | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.document
);

export const selectLeaseDocumentTemplate: Selector<RootReducer, LeaseDocumentTemplateType | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.template
);

export const selectLeaseDocumentCloudVersion: Selector<RootReducer, string | number> = createSelector(
  selectLeaseDocumentRoot,
  state => state.document?.cloudDocumentVersion || 0
);

export const selectInteractionsFeedToken: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.interactionsFeedToken
);

export const selectLeaseDocumentId: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.id
);

export const selectDocumentTrackChangesMode: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.trackChangesMode
);

export const selectDocumentRestrictedEditingMode: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.restrictedEditingMode
);

export const selectIsReceiverCollaborationEnabled: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.receiverCollaborationEnabled
);

export const selectLeaseDocumentStatus: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.status
);

export const selectLeaseDocumentEditorDisabled: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isEditorDisabled
);

export const selectDocumentIsEligibleSigning: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isEligibleForSigningPrep
);

export const selectLeaseDocumentSuggestions: Selector<RootReducer, DocumentSuggestion[]> = createSelector(
  selectLeaseDocumentRoot,
  state => state.documentSuggestions
);

export const selectLeaseDocumentPossession: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.currentTeamPossession
);

export const selectDocumentCompareModeEnabled: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isCompareModeEnabled
);

export const selectLeaseDocumentTitle: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.title
);



export const selectLeaseDocumentName: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.name
);

export const selectLeaseDocumentIsEditing: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isEditing
);

export const selectLeaseDocumentIsPending: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isPending
);

export const selectIsLeaseEditorLoaded: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isEditorLoaded
);

export const selectLeaseDocumentLastUpdated: Selector<RootReducer, string> = createSelector(
  selectLeaseDocumentRoot,
  state => state.lastUpdated
);

export const selectLeaseDocumentEversignPDFUrl: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.eversignPdfUrl
);

export const selectLeaseDocumentEversignStatus: Selector<RootReducer, string> = createSelector(
  selectLeaseDocumentRoot,
  state => state.eversignStatus
);

export const selectLeaseDocumentTeam: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.teamId
);

export const selectLeaseDocumentReceiverTeam: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.receiverTeamId
);

export const selectDocumentSessionActivities: Selector<RootReducer, string[]> = createSelector(
  selectLeaseDocumentRoot,
  state => state.sessionActivities
);

export const selectDocumentEditorSuggestions: Selector<RootReducer, string[]> = createSelector(
  selectLeaseDocumentRoot,
  state => state.editorSuggestions
);

export const selectDocumentSuggestionRejected: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isRejectedSuggestion
);

export const selectDocumentSource: Selector<RootReducer, DocumentSourceEnum | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.source
);

export const selectIsDocumentEditable: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isEditable
);

export const selectIsDocumentTitleEditable: Selector<RootReducer, boolean> = createSelector(
  selectLeaseDocumentRoot,
  state => state.isTitleEditable
);

export const selectDocumentApprovalRequest: Selector<RootReducer, DocumentApprovalRequestType | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.approvalRequest
);

export const selectDocumentSigners: Selector<RootReducer, DocumentSigner[] | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.signers
);

export const selectDocumentPlaceholderRefreshKey: Selector<RootReducer, number | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.placeholderRefreshKey
);

export const selectDocumentImportedAssetUrl: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.importedAssetUrl
);

export const selectDocumentOriginalImportedAssetUrl: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => state.originalImportedAssetUrl
);

export const selectDocumentGeneratedAssetUrl: Selector<RootReducer, string | null> = createSelector(
  selectLeaseDocumentRoot,
  state => {
    if (!state.source) return null;

    return state.source === DocumentSourceEnum.APP ? state.generatedDocumentPdfUrl : state.importedAssetUrl;
  }
);

export const selectDocumentImportedAssetContentType: Selector<
  RootReducer,
  ImportedDocumentSupportedContentTypes | null
> = createSelector(selectLeaseDocumentRoot, state => {
  if (!state.source) return null;

  return state.source === DocumentSourceEnum.APP ? 'application/pdf' : state.contentType;
});
