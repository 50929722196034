import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import dayjs from 'dayjs';
import { sanitizedEmailHTML, TEmailData } from './helpers';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  emailData: TEmailData;
};

const ShowEmailReplyModal = ({ isOpen, onClose, emailData }: Props) => {
  const { formatMessage } = useIntl();
  const [showNestedMessageLog, setShowNestedMessageLog] = useState<boolean>(false);

  const renderEmailAttachments = () => {
    return emailData?.emailAttachments?.map((attachment: { filename: string; url: string }) => (
      <div key={attachment.filename}>
        <a className="font-weight-bold" href={attachment.url} target="_blank" rel="noreferrer">
          &#128206; {attachment.filename}
        </a>
      </div>
    ));
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} size="lg">
        <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
          <Modal.Title>
            <header className={styles.emailReplyTitle}>
              <FormattedMessage id="title.first-lease-template" defaultMessage="Message" />
            </header>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className={styles.emailReplyHeader}>
            <div>
              <div className="font-weight-bold">
                From: <span className="ml-1 font-weight-normal">{emailData?.sender}</span>
              </div>
              <div className="font-weight-bold">
                Subject: <span className="ml-1 font-weight-normal">{emailData?.subject}</span>
              </div>
            </div>
            <div>
              <span>{dayjs(emailData?.createdAt).format('LLL')}</span>
            </div>
          </Container>
          <Container className={styles.emailReplyBody}>
            <div dangerouslySetInnerHTML={sanitizedEmailHTML(emailData).mainContent} />
            <div
              className={styles.showNestedMessagesButton}
              onClick={() => {
                setShowNestedMessageLog(!showNestedMessageLog);
              }}
            ></div>
            {showNestedMessageLog && <div dangerouslySetInnerHTML={sanitizedEmailHTML(emailData).nestedMessageLog} />}
          </Container>
          <Container className={styles.emailReplyAttachmentsContainer}>
            <div className="font-weight-bold pb-1">Attachments</div>
            {emailData?.emailAttachments?.length
              ? renderEmailAttachments()
              : 'There were no files attached to this email'}
          </Container>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
};

export default ShowEmailReplyModal;
