import { LeaseDocumentStateType } from '../lease-document.reducer'
import { SetLeaseDocumentIsEditingAction } from '../actions/lease-document.actions'

export const handleSetLeaseDocumentIsEditing = (
  state: LeaseDocumentStateType,
  action: SetLeaseDocumentIsEditingAction
): LeaseDocumentStateType => ({
  ...state,
  isEditing: action.isEditing,
})
