import { ReactNode } from 'react';

import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import styles from './../style.module.scss';
import { Helmet } from 'react-helmet';
import PropdocsModal from '../../../components/modal/PropdocsModal';

interface WrapperProps {
  children?: ReactNode;
  titleMessage?: MessageDescriptor;
  descriptionMessage?: string;
  background: 'dealsPage' | 'dealDetailsPage' | 'blankDocPage';
  loading?: boolean;
}

const MagicLinkWrapper = ({
  children,
  titleMessage = { id: 'title.register', defaultMessage: ' ' },
  descriptionMessage = '',
  background,
  loading = false,
}: WrapperProps) => {
  const { formatMessage } = useIntl();

  const ModalTitle = (
    <div className={styles.magicLinkHeader}>
      <h2 data-testid="sign-up" className="m-0">
        {<FormattedMessage {...titleMessage} />}
      </h2>
    </div>
  );

  const bgClasses = {
    dealsPage: styles.magicLinkContainer__dealsPage,
    blankDocPage: styles.magicLinkContainer__blankDocPage,
    dealDetailsPage: styles.magicLinkContainer__dealDetailsPage,
  };

  const backgroundClass = bgClasses[background];

  return (
    <div className={backgroundClass}>
      <Helmet title={formatMessage(titleMessage)} />
      {!loading && (
        <PropdocsModal size="md" title={ModalTitle} show={true} setShow={() => {}} disableClose={true}>
          <>
            {descriptionMessage && (
              <p data-testid="sign-up-note" className="mb-4 font-weight-normal">
                {descriptionMessage}
              </p>
            )}
            {children}
          </>
        </PropdocsModal>
      )}
    </div>
  );
};

export default MagicLinkWrapper;
