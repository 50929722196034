import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetRejectedSuggestionAction } from '../actions/lease-document.actions';

export const handleSetRejectedSuggestion = (
  state: LeaseDocumentStateType,
  action: SetRejectedSuggestionAction
): LeaseDocumentStateType => ({
  ...state,
  isRejectedSuggestion: action.isRejected,
});
