import { createContext, useContext, useCallback, useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';

import * as auth from '../api/auth';
import * as queries from '../api/queries';
import client from '../api/client';

import { paths, useNavigate } from '../routing';

import { useTeamInfo } from '../team-id-context';
import { useDealId } from '../deal-id-context';

import * as token from './token';
import { Permissions } from '../shared/constants/permissions';
import { IUser } from './types';

export { token };

// A timeout or interval can't be longer than this
const MAX_DELAY_MS = 0x7fffffff;

// Refresh this long before the token is set to expire
const REFRESH_SAFETY_S = 120;

export interface RegisterData {
  email: string;
  password: string;
  fullName: string;
  companyName: string;
  invitationToken: string;
  confirmationToken?: string;
  teamName?: string;
}

export interface RegisterInvitedTeamMemberData {
  firstName: string;
  lastName: string;
  token: string;
}

export interface RegisterInvitedCounterpartyData {
  firstName: string;
  lastName: string;
  companyName: string;
  token: string;
}

type TSuccessResponseType = { success: boolean; errors: { id: string; defaultMessage: string }[] };

type TErrorsResponseType = { [key: string]: string; _general: string };

type TAuthContext = {
  state: AuthState;
  handlers: {
    login: ({ email, password }: { email: string; password: string }) => Promise<any>;
    loginByToken: ({ token }: { token: string; }) => Promise<any>;
    logout: ({
      skipApi,
    }: {
      skipApi?: boolean;
    }) =>
      | Promise<{ success: boolean; errors: { id: string; defaultMessage: string }[] } | { success: boolean } | null>
      | Promise<void>;
    register: ({ email, password, fullName, companyName, invitationToken }: RegisterData) => Promise<any>;
    registerWithEmailVerified: (
      { password, fullName, companyName }: RegisterData,
      confirmationToken: string
    ) => Promise<any>;
    registerInvitedTeamMember: ({ firstName, lastName, token }: RegisterInvitedTeamMemberData) => Promise<any>;
    registerInvitedCounterparty: ({ firstName, lastName, companyName, token }: RegisterInvitedCounterpartyData) => Promise<any>;
    signupWithEmailConfirmation: ({ email }: { email: string }) => Promise<any>;
    confirmEmail: ({ confirmationToken }: { confirmationToken: string }) => Promise<any>;
    resendConfirmationEmail: ({ email }: { email: string }) => Promise<any>;
    refreshToken: () =>
      | Promise<
          | TSuccessResponseType
          | { success: boolean; user: IUser; token?: string; errors?: string[] }
          | { success: boolean; errors: TErrorsResponseType; user?: IUser; token?: string }
        >
      | Promise<void>;
  };
};

interface AuthState {
  loggedIn: boolean;
  authLoading: boolean;
  refreshLoading: boolean;
  queryLoading: boolean;
  user: any;
  token: string | null;
}

const initialState: TAuthContext['state'] = {
  loggedIn: false,
  authLoading: false,
  refreshLoading: false,
  queryLoading: !!token.get(),
  user: null,
  token: token.get(),
};

const initialHandlers: TAuthContext['handlers'] = {
  login: () => Promise.resolve(),
  loginByToken: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerInvitedTeamMember: () => Promise.resolve(),
  registerInvitedCounterparty: () => Promise.resolve(),
  registerWithEmailVerified: () => Promise.resolve(),
  signupWithEmailConfirmation: () => Promise.resolve(),
  resendConfirmationEmail: () => Promise.resolve(),
  confirmEmail: () => Promise.resolve(),
};

interface Action {
  type: string;
  data?: any;
}

function reducer(state: AuthState, { type, data }: Action) {
  switch (type) {
    case 'authLoading':
      return {
        ...state,
        authLoading: true,
      };
    case 'authDone':
      return {
        ...state,
        authLoading: false,
      };
    case 'refreshLoading':
      return {
        ...state,
        refreshLoading: true,
      };
    case 'refreshDone':
      return {
        ...state,
        refreshLoading: false,
      };
    case 'queryLoading':
      return {
        ...state,
        queryLoading: true,
      };
    case 'queryDone':
      return {
        ...state,
        queryLoading: false,
      };
    case 'login':
    case 'register':
      token.set(data.token);
      return {
        ...state,
        loggedIn: true,
        user: { ...(data.user as IUser) },
        token: data.token,
      };
    case 'logout':
      token.remove();
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null,
      };
    case 'refreshToken':
      token.set(data.token);
      return {
        ...state,
        loggedIn: true,
        user: {
          ...(state.user ?? {}),
          ...data.user,
        },
        token: data.token,
      };
    case 'userDetails':
      if (!state.token) {
        // User has logged out; discard response
        return state;
      }
      return {
        ...state,
        loggedIn: true,
        user: {
          ...(state.user ?? {}),
          ...data,
        },
      };
    default:
      throw new Error(`Unrecognized action type "${type}"`);
  }
}

export const useAuthReducer = ({
  currentPageIsPrivate,
}: {
  currentPageIsPrivate: boolean;
}): { state: TAuthContext['state']; handlers: TAuthContext['handlers'] } => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { loading: queryLoading, data: queryResponse, refetch } = useQuery(queries.getCurrentUser, {
    client,
    skip: !state.token,
    notifyOnNetworkStatusChange: true,
  });

  // Dispatch new user data when it's received
  useEffect(() => {
    if (queryResponse) {
      dispatch({
        type: 'userDetails',
        data: {
          ...queryResponse.currentUser,
        },
      });

      // Identify the user with Pendo
      if (queryResponse?.currentUser) {
        (window as any).pendo.identify({
          visitor: {
            id: queryResponse.currentUser.email,
          },
        });
      }

    }
  }, [queryResponse]);

  // Refetch user data from GraphQL endpoint when token changes
  useEffect(() => {
    if (!state.token) {
      return;
    }

    refetch();
  }, [refetch, state.token]);

  const login = async ({ email, password }: { email: string; password: string }) => {
    dispatch({ type: 'authLoading' });
    const response = await auth.login({ email, password });
    if (response?.success) {
      const res = await client.query({
        query: queries.getCurrentUser,
        context: { headers: { Authorization: `Bearer ${response.token}` } },
      });

      dispatch({
        type: 'login',
        data: {
          ...response,
          user: {
            ...response.user,
            ...res.data.currentUser,
          },
        },
      });
    }
    dispatch({ type: 'authDone' });
    return response;
  };

  const loginByToken = async ({ token }: { token: string; }) => {
    dispatch({ type: 'authLoading' });
    const response = await auth.loginByToken({ token });
    if (response?.success) {
      const res = await client.query({
        query: queries.getCurrentUser,
        context: { headers: { Authorization: `Bearer ${response.token}` } },
      });

      dispatch({
        type: 'login',
        data: {
          ...response,
          user: {
            ...response.user,
            ...res.data.currentUser,
          },
        },
      });
    }
    dispatch({ type: 'authDone' });
    return response;
  };

  /**
   * Log the user out
   *
   * The changes to state are always made immediately, but if the API call is
   * made, the promise is only resolved once that finishes.
   *
   * @function logout
   * @async
   * @param {Object} options
   * @param {boolean} options.skipApi - Pass true to avoid sending the API request
   *                                    which would invalidate the user's token,
   *                                    for example if you know the existing
   *                                    token is invalid and so the call would
   *                                    fail
   * @returns {Promise<null|Object>} - API call response or null if the call
   *                                   wasn't made
   */
  const logout = useCallback(
    async ({ skipApi = false }) => {
      let promise = null;

      if (!skipApi) {
        // Trigger API call but don't actually wait for it for the purposes of
        // logging the user out from the front end; other code could await this if
        // necessary for some reason, so it's returned at the end
        promise = auth.logout();
      }

      // Clear GQL cache
      await client.clearStore();

      // Navigate to home page if we were on a private route
      if (currentPageIsPrivate) {
        navigate(paths.home);
      }

      dispatch({ type: 'logout' });

      return promise;
    },
    [currentPageIsPrivate, navigate]
  );

  /**
   * Refresh the user's token
   *
   * This will refresh the user's token if possible. If not possible the user
   * should probably be logged out. (This function does not do that.)
   *
   * @function refreshToken
   * @async
   * @returns {Promise<Object>} - API call response
   */
  const refreshToken = useCallback(async () => {
    dispatch({ type: 'refreshLoading' });
    const response = await auth.refreshToken();
    if (response?.success) {
      dispatch({
        type: 'refreshToken',
        data: response,
      });
    }
    dispatch({ type: 'refreshDone' });
    return response;
  }, []);

  /**
   * Register a user
   *
   * @function register
   * @async
   * @param {Object} data
   * @param {string} data.email - Email address
   * @param {string} data.password - Password
   * @param {string} data.fullName - Full name
   * @param {string} data.companyName - Company name
   * @param {string} data.invitationToken - Invitation token
   * @returns {Promise<Object>} - Data about the registered user
   */
  const register = async ({ email, password, fullName, companyName, invitationToken }: RegisterData) => {
    dispatch({ type: 'authLoading' });
    const response = await auth.register({ email, password, fullName, companyName, invitationToken });
    if (response?.success) {
      dispatch({
        type: 'register',
        data: response,
      });
    }
    dispatch({ type: 'authDone' });
    return response;
  };

  const registerInvitedTeamMember = async({ firstName, lastName, token }: RegisterInvitedTeamMemberData) => {
    dispatch({ type: 'authLoading' });
    const response = await auth.registerInvitedTeamMember({ firstName, lastName, token });
    if (response?.success) {
      dispatch({
        type: 'register',
        data: response,
      });
    }
    dispatch({ type: 'authDone' });
    return response;
  }

  const registerInvitedCounterparty = async({ firstName, lastName, companyName, token }: RegisterInvitedCounterpartyData) => {
    dispatch({ type: 'authLoading' });
    const response = await auth.registerInvitedCounterparty({ firstName, lastName, companyName, token });
    if (response?.success) {
      dispatch({
        type: 'register',
        data: response,
      });
    }
    dispatch({ type: 'authDone' });
    return response;
  }

  const registerWithEmailVerified = async (
    { password, fullName, companyName }: RegisterData,
    confirmationToken: string
  ) => {
    dispatch({ type: 'authLoading' });
    const response = await auth.registerWithEmailVerified({ password, fullName, companyName, confirmationToken });
    if (response?.success) {
      dispatch({
        type: 'register',
        data: response,
      });
    }
    dispatch({ type: 'authDone' });
    return response;
  };

  const signupWithEmailConfirmation = async ({ email }: { email: string }) => {
    dispatch({ type: 'authLoading' });
    const response = await auth.signupWithEmailConfirmation({ email });
    dispatch({ type: 'authDone' });
    return response;
  };

  /**
   * Confirm a user's email address
   *
   * @function confirmEmail
   * @async
   * @param {Object} data
   * @param {string} data.confirmationToken - Confirmation token
   * @returns {Promise<Object>} - API call response
   */
  const confirmEmail = async ({ confirmationToken }: { confirmationToken: string }) => {
    const response = await auth.confirmEmail({ confirmationToken });
    if (response?.success) {
      refetch();
    }
    return response;
  };

  /**
   * Request for confirmation email to be sent again
   *
   * @function resendConfirmationEmail
   * @async
   * @param {Object} data
   * @param {string} data.email - Email address
   * @returns {Promise<Object>} - API call response
   */
  const resendConfirmationEmail = async ({ email }: { email: string }) => {
    const response = await auth.resendConfirmationEmail({ email });

    return response;
  };

  // Capture loading state of GQL query in reducer state
  useEffect(() => {
    if (queryLoading) {
      dispatch({ type: 'queryLoading' });
    } else {
      dispatch({ type: 'queryDone' });
    }
  }, [queryLoading]);

  // Set up automatic token refresh
  useEffect(() => {
    if (!state.token) {
      return;
    }

    const ttl = token.ttl(state.token);
    if (ttl > REFRESH_SAFETY_S) {
      const timeout = setTimeout(async () => {
        const { success } = await refreshToken();
        if (!success) {
          console.error('Failed to refresh token; logging out');
          logout({ skipApi: true });
        }
      }, Math.min(MAX_DELAY_MS, (ttl - REFRESH_SAFETY_S) * 1000));

      return function cleanup() {
        clearTimeout(timeout);
      };
    }

    // Attempt to refresh the token immediately; this effect will run again
    // with the new token and the timer will then be set up
    refreshToken();
  }, [state.token, logout, refreshToken]);

  return {
    state,
    handlers: {
      login,
      loginByToken,
      logout,
      register,
      registerInvitedTeamMember,
      registerInvitedCounterparty,
      registerWithEmailVerified,
      signupWithEmailConfirmation,
      confirmEmail,
      resendConfirmationEmail,
      refreshToken,
    },
  };
};

/**
 * The auth context; this should usually be used via the hook
 * @see useAuth
 */
export const AuthContext = createContext<TAuthContext>({ state: initialState, handlers: initialHandlers });
AuthContext.displayName = 'AuthContext';

/**
 * Hook to use the auth context, which will contain the output of useAuthReducer
 * above
 *
 * @function useAuth
 * @see useAuthReducer
 */

export const useAuth = (): [TAuthContext['state'], TAuthContext['handlers']] => {
  const { state, handlers } = useContext(AuthContext);

  return [state, handlers];
};

/**
 * Hook to get a method allowing permissions to be checked against the current
 * team and deal (via context)
 *
 * @function usePermissions
 * @param {Object} contextOverride - Overrides to the context
 * @param {boolean} contextOverride.teamId - Team ID with which to override
 *                                           context
 * @param {boolean} contextOverride.dealId - Deal ID with which to
 *                                               override context
 * @returns {Object} - Object containing
 *                     - `loading` boolean
 *                     - `isAdmin` boolean (null if loading)
 *                     - `hasPermission` function, which takes a required string
 *                       `key` deal. The function returns a boolean, or
 *                       null if `loading` is still true.
 */
export function usePermissions(contextOverride: any = {}) {
  const { teamId: contextTeamId } = useTeamInfo();
  const contextDealId = useDealId();

  const teamId = contextOverride.teamId ?? contextTeamId;
  const dealId = contextOverride.dealId ?? contextDealId;

  const { loading, data: permissionsResponse } = useQuery(queries.getPermissions, {
    skip: teamId == null,
   /* WHY ???? client,*/
    variables: { teamId },
  });

  interface TeamPermission {
    allowed: boolean;
    permission: {
      key: string;
    };
  }

  const permissions: [{ key: string }] | undefined = permissionsResponse?.currentUser.teamPermissionsByTeam
    .filter((teamPermission: TeamPermission) => teamPermission.allowed)
    .map((teamPermission: TeamPermission) => teamPermission.permission);
  const whitelisted = permissions?.some(permission => permission.key === Permissions.Whitelist);
  const dealIds = permissionsResponse?.currentUser.viewableDealsByTeam;
  const isAdmin = permissionsResponse?.currentUser.ownedTeams.some((team: { id: string }) => team.id === teamId);
  return {
    loading,
    isAdmin,
    hasPermission(key: string) {
      if (teamId == null || loading) return null;
      return (
        (whitelisted || dealId == null || dealIds.includes(dealId)) &&
        permissions?.some(permission => permission.key === key)
      );
    },
  };
}
