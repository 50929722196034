import { ExoticComponent, ReactNode } from 'react';

export enum TemplateType {
  DOCUMENT = 'agreement',
  CLAUSE = 'clause',
  UPLOADED = 'uploaded',
}
export interface AddNewTemplateFormikProps {
  bodyContainer: any;
  actionsContainer: any;
  renderSecondaryAction: () => ReactNode;
  onSuccess: () => void;
  existingTemplateFields?: { [key: string]: any };
  templateFormEditMode: boolean;
  templateType?: TemplateType | null;
}

export interface InviteReceivingTeamFormikProps {
  bodyContainer: ExoticComponent<{ children?: ReactNode }>;
  actionsContainer: ExoticComponent<{ children?: ReactNode }>;
  sendDocumentToReceiverHandler: () => void;
  renderSecondaryAction: () => ReactNode;
  onSuccess?: () => void;
  shouldAccessBeGranted?: boolean;
  teamId: string;
  sendWithDocumentId: boolean;
  shareDocument: boolean;
  refreshCounterpartyData: () => void;
}

export interface DefaultDealsProps {
  id: number;
}

export interface PermissionProps {
  key: string;
}

export enum DocumentActionType {
  REVIEW = 'review',
  SHARE = 'share',
}
