import React from 'react';
import { SelectField } from '../../../components/fields';
import { OrganizationType } from '../constants';
import { SearchableSelectField } from '../../../components/fields/select-field';

const MembershipTypeOptions = [
  { value: 'Team', label: { id: 'membershipTypeOption.lease', defaultMessage: 'Team' } },
  { value: 'User', label: { id: 'membershipTypeOption.sale', defaultMessage: 'Individual' } },
];

export const MembershipTypeField = React.forwardRef(
  ({ before, afterField, after, wrapperElement, id, label, name, text, help, options, ...rest }: any, ref) => {
    return (
      <SelectField
        ref={ref}
        //@ts-ignore TODO  update SelectField to ts
        id={id}
        //@ts-ignore TODO  update SelectField to ts
        name={name}
        //@ts-ignore TODO  update SelectField to ts
        required
        options={MembershipTypeOptions}
        placeholder="Select"
        {...{ before, afterField, after, id, label, name, text, help, wrapperElement }}
      />
    );
  }
);

export const SelectOrganizationField = React.forwardRef(
  (
    {
      organizations,
      before,
      afterField,
      after,
      wrapperElement,
      id,
      label,
      name,
      text,
      help,
      options,
      placeholder,
      ...rest
    }: any,
    ref
  ) => {
    const orgsList = organizations
      .sort((a: OrganizationType, b: OrganizationType) => {
        return a.name > b.name ? 1 : -1;
      })
      .map((org: OrganizationType) => {
        return { value: org.id, label: org.name };
      });

    return (
      <SearchableSelectField
        ref={ref}
        //@ts-ignore TODO  update SelectField to ts
        id={id}
        //@ts-ignore TODO  update SelectField to ts
        name={name}
        //@ts-ignore TODO  update SelectField to ts
        required
        options={orgsList}
        placeholder={placeholder}
        {...{ before, afterField, after, id, label, name, text, help, wrapperElement }}
      />
    );
  }
);
