import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import './lease-document-wizard-cta-stack.scss';
import { Spinner } from 'react-bootstrap';

export type LeaseDocumentWizardCTAType = {
  onClick: (e?: MouseEvent) => void;
  label: ReactNode | string;
  theme: 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary' | 'link-secondary';
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
};

type LeaseDocumentWizardCTAStackProps = {
  buttonCTAs: Array<LeaseDocumentWizardCTAType>;
  direction: 'vertical' | 'horizontal';
};
export const LeaseDocumentWizardCTAStack = ({
  buttonCTAs,
  direction = 'vertical',
}: LeaseDocumentWizardCTAStackProps) => {
  return buttonCTAs.length > 0 ? (
    <div className={`lease-document-wizard-cta-stack lease-document-wizard-cta-stack--${direction}`}>
      {buttonCTAs.map(
        (
          {
            onClick,
            label,
            theme = 'primary',
            disabled = false,
            isLoading = false,
            className,
          }: LeaseDocumentWizardCTAType,
          idx
        ) => (
          <button
            key={idx}
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={classNames(
              `btn btn-${theme}`,
              'lease-document-wizard-cta-stack__button',
              `lease-document-wizard-cta-stack__button--${direction}`,
              className,
              { 'lease-document-wizard-cta-stack__button--disabled': disabled }
            )}
          >
            {isLoading && <Spinner animation="border" size="sm" />}
            {label}
          </button>
        )
      )}
    </div>
  ) : null;
};
