import { Maybe } from 'graphql/jsutils/Maybe';
import { NavigateFunction } from 'react-router-dom';
import { Team } from '../../../gql/graphql';
import { fillPlaceholders, paths } from '../../../routing';
import { DocumentStatusEnum } from '../../../shared/constants/leaseDocument';

export enum DocumentAccessLabelsEnum {
  EVERYONE = 'Everyone',
  TEAM = 'My Team',
}

export const getDocumentAccessLabel = (receiverTeam: Maybe<Team>) => {
  return receiverTeam ? DocumentAccessLabelsEnum.EVERYONE : DocumentAccessLabelsEnum.TEAM;
};

export const defineAlertableDocumentTooltipMessage = ({ documentStatus }: { documentStatus: DocumentStatusEnum }) => {
  const reviewingDocumentMessage = 'Send document for signing or request a review from your counterparty';
  const approvalPendingDocumentMessage = 'Approve or reject the pending internal approval for this document';
  const signingPrepDocumentMessage = 'Complete signing preparation and send the document for signing';

  if (documentStatus === DocumentStatusEnum.PREPARING_TO_SIGN) {
    return signingPrepDocumentMessage;
  }

  return documentStatus === DocumentStatusEnum.REVIEWING ? reviewingDocumentMessage : approvalPendingDocumentMessage;
};

export const handleDocumentNavigation = ({
  documentId,
  dealId,
  teamId,
  documentStatus,
  navigate,
}: {
  documentId: string;
  dealId: string;
  teamId: string;
  documentStatus: string;
  navigate: NavigateFunction;
}) => {
  const urlPath =
    documentStatus === DocumentStatusEnum.PREPARING_TO_SIGN ? paths.Documents.signDocument : paths.Documents.document;
  const url = fillPlaceholders(urlPath, { documentId, teamId, dealId });

  navigate(url);
};
