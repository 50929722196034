import React, { ChangeEvent, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { Checkbox } from '../../components/checkbox-or-radio-field';
import SearchInput from '../../components/search-input';
import { DropdownItem } from '../../components/dropdown';
import { filterMembersBy } from '../helpers';
import styles from '../../components/selectDeals/styles.module.scss';

const TeamMembersDropdown = forwardRef(
  (
    {
      children,
      dropdownId,
      onDealChange,
      memberDisabled,
      dealChecked,
      dealValue,
      style,
      className,
      onClick,
      checkboxComponent: CheckboxComponent = Checkbox,
    }: any,
    ref: any
  ) => {
    const [query, setQuery] = useState('');

    const renderDealCheckboxes = () => (
      <>
        {children
          .filter((teamMember: any) => filterMembersBy(teamMember.user, query.trim()))
          .map((teamMember: any) => {
            const { fullName } = teamMember.user;

            return (
              <DropdownItem key={teamMember.id} highlightable as="li">
                <CheckboxComponent
                  type="checkbox"
                  id={`${dropdownId}.select-deal.${teamMember.id}`}
                  disabled={memberDisabled(teamMember)}
                  value={dealValue(teamMember)}
                  checked={dealChecked(teamMember)}
                  onChange={(e: any) => onDealChange(e, teamMember)}
                  label={fullName}
                />
              </DropdownItem>
            );
          })}
      </>
    );

    return (
      <div ref={ref} style={style} className={className} onClick={onClick}>
        <div className="p-3 d-flex bg-light align-items-center">
          <SearchInput
            placeholder="Search Team Member"
            autoFocus
            className="w-auto"
            /*

            //@ts-ignore  */
            style={{ flex: 'auto' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value)}
            value={query}
            id={`${dropdownId}-query`}
          />
        </div>
        <ul className={classNames(styles.resultsList, 'list-unstyled m-0')}>{renderDealCheckboxes()}</ul>
      </div>
    );
  }
);

export default TeamMembersDropdown;
