import React, { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import EmptyCounterparty from './components/emptyCounterparty';
import { useApolloClient, useQuery } from '@apollo/client';
import { queriesV2 } from '../../../api';
import { useTeamInfo } from '../../../team-id-context';
import { useDealId } from '../../../deal-id-context';
import LoadingSpinner from '../../loading-spinner';
import { TeamMember, User } from '../../lease-wizard/lease-editor/LeaseEditorTypes';
import { useDealRoles } from '../../../shared/hooks/useDealRoles/useDealRoles';
import RemoveCounterpartyModal from './components/removeCounterpartyModal';
import CounterpartyInfoItem from "./components/counterpartyInfoItem";
import {
  useLeaseDocumentTeamPermissions
} from "../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions";
import {v4 as uuidv4} from 'uuid';
import { useSelector } from "react-redux";
import { selectCurrentDeal } from "../../../store/deals/deals.selectors";
import { useAuth } from "../../../auth";

const CounterpartySection = () => {
  const apolloClient = useApolloClient();
  const { teamId } = useTeamInfo();
  const dealId = useDealId();
  const [{ user }] = useAuth();
  const { isCreator } = useDealRoles(dealId);
  const currentDeal = useSelector(selectCurrentDeal);

  // Get counterparty deal information
  const { loading: isDealDataLoading, data: dealData } = useQuery(queriesV2.getDealTeamsByTeamId, {
    skip: !teamId || !dealId,
    variables: {
      dealId,
      teamId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const counterpartyTeam = useMemo(() => {
    return isCreator ? dealData?.getDealById.receiverTeam : dealData?.getDealById.team;
    }, [dealData?.getDealById.receiverTeam, dealData?.getDealById.team, isCreator]
  )
  const dealMembers = dealData?.getDealById?.members;
  const counterpartyInvitedEmail = dealData?.getDealById?.receiverEmail;
  const isDealAccepted = dealData?.getDealById?.receiverAcceptedInvitationDate;

  const counterpartyMembersWithAccessToDeal = useMemo(() => {
    const dealMembersIds = dealMembers?.map((member: User) => member.id);
    return counterpartyTeam?.teamMembers?.filter((member: TeamMember) =>
      dealMembersIds.includes(member.user.id)
    );
  }, [dealMembers, counterpartyTeam]);


  const counterpartyListItems = !isDealAccepted
    ? [{ user: { email: counterpartyInvitedEmail, id: uuidv4() } }]
    : counterpartyMembersWithAccessToDeal;

  const { hasInviteSendingPermission } = useLeaseDocumentTeamPermissions({ teamId, apolloClient });
  const hasCreatorInvitePermission = isCreator && hasInviteSendingPermission;

  const showRemoveModal = isDealAccepted && hasCreatorInvitePermission;

  const invitationSentBy = currentDeal?.invitationSentBy;
  const counterpartyAdminUserId = isCreator ? counterpartyTeam?.adminUser?.id : invitationSentBy?.id;

  const renderCounterparties = () =>
    !counterpartyTeam
    ? (<EmptyCounterparty canInvite={hasCreatorInvitePermission} refreshCounterpartyData={() => {}} />)
    : (
      <div className="pr-3">
        {showRemoveModal && <RemoveCounterpartyModal dealId={dealId} teamId={teamId} userId={user.id}/>}
        <Container className="m-0 p-0 w-100 flex-column">
          {
            counterpartyListItems?.map((teamMember: TeamMember) =>
              <CounterpartyInfoItem
                key={teamMember.user.id}
                teamMember={teamMember}
                isDealAccepted={isDealAccepted}
                hasCreatorInvitePermission={hasCreatorInvitePermission}
                dealId={dealId}
                counterpartyInvitedEmail={counterpartyInvitedEmail}
                counterpartyAdminUserId={counterpartyAdminUserId}
              />
            )}
        </Container>
      </div>
    )

  return (
    <Container className="mt-3 p-0 flex-column">
      {!isDealDataLoading ? renderCounterparties(): (<LoadingSpinner />)}
    </Container>
  );
};

export default CounterpartySection;
