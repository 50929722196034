import React, { ExoticComponent, Fragment, ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import ErrorMessage from '../components/error-message';
import {
  AddNewTemplateName,
  CreateNewTemplateOnSave,
  EditTemplateOnSave,
  AddNewTemplateRestrictedEditing,
  AddNewClauseName, CreateNewClauseOnSave, EditClauseOnSave,
} from '../consts/forms-constants';
import Toggle from '../components/toggle';
import Tooltip from '../components/tooltip';
import { TemplateType } from '../consts/forms-types';
import { DocumentSourceEnum } from '../components/lease-wizard/lease-editor/constants/EditorConstats';
import styles from '../pages/templates/styles.module.scss';

interface AddNewTemplateFormProps {
  bodyContainer: ExoticComponent<{ children?: ReactNode }>;
  actionsContainer: ExoticComponent<{ children?: ReactNode }>;
  renderSecondaryAction: any;
  errors: any;
  values: any;
  isSubmitting: boolean;
  isValid: boolean;
  templateFormEditMode: boolean;
  isRestrictedEditingEnabled: boolean;
  handleRestrictedEditingChange: () => void;
  templateType?: TemplateType | null;
  templateSource?: DocumentSourceEnum;
}

const AddNewTemplateForm = ({
  bodyContainer: BodyContainer = Fragment,
  actionsContainer: ActionsContainer = Fragment,
  renderSecondaryAction,
  errors,
  values,
  isSubmitting,
  isValid,
  templateFormEditMode,
  isRestrictedEditingEnabled,
  handleRestrictedEditingChange,
  templateType,
  templateSource,
}: AddNewTemplateFormProps) => {
  const newTemplateName = templateType === TemplateType.CLAUSE ? AddNewClauseName : AddNewTemplateName;
  const createButtonText = templateType === TemplateType.CLAUSE ? CreateNewClauseOnSave : CreateNewTemplateOnSave;
  const editButtonText = templateType === TemplateType.CLAUSE ? EditClauseOnSave : EditTemplateOnSave;
  return (
    <Form noValidate>
      <div className={styles.modalBody}>
        <BodyContainer>
          {errors._general && (
            <Alert variant="danger">
              <ErrorMessage>{errors._general}</ErrorMessage>
            </Alert>
          )}

          <Row>
            <Col lg={12}>
              <TextField
                //@ts-ignore TODO update TextField to ts
                id="addNewTemplate.name"
                name="template_name"
                type="template_name"
                label={
                  <FormattedMessage id={newTemplateName.id} defaultMessage={newTemplateName.defaultMessage} />
                }
              />
            </Col>
          </Row>
          {templateType === TemplateType.DOCUMENT && templateSource !== DocumentSourceEnum.IMPORTED && (
            <Row>
              <Col lg={12}>
                <div className={styles.restrictedEditingField}>
                  <span className={styles.restrictedEditingField__label}>
                    <FormattedMessage
                      id={AddNewTemplateRestrictedEditing.id}
                      defaultMessage={AddNewTemplateRestrictedEditing.defaultMessage}
                    />
                    <span className={styles.restrictedEditingField__tooltip}>
                      <Tooltip testId="add-form-restricted-editing-tooltip">
                        {AddNewTemplateRestrictedEditing.TOOLTIP_MESSAGE}
                      </Tooltip>
                    </span>
                  </span>
                  <Toggle
                    checked={isRestrictedEditingEnabled}
                    disabled={templateFormEditMode}
                    isCustomDisabledState={false}
                    onChange={handleRestrictedEditingChange}
                  />
                </div>
              </Col>
            </Row>
          )}
        </BodyContainer>
      </div>
      <div className={styles.buttonsContainer}>
        <ActionsContainer>
          {renderSecondaryAction && renderSecondaryAction()}
          <SubmitButton
            label={
              templateFormEditMode ? (
                <FormattedMessage id={editButtonText.id} defaultMessage={editButtonText.defaultMessage} />
              ) : (
                <FormattedMessage
                  id={createButtonText.id}
                  defaultMessage={createButtonText.defaultMessage}
                />
              )
            }
            disabled={isSubmitting || !isValid}
            isSubmitting={isSubmitting}
          />
        </ActionsContainer>
      </div>
    </Form>
  );
};

export default AddNewTemplateForm;
