import { Plugin } from '@ckeditor/ckeditor5-core';
import { DropdownButtonView, DropdownPanelView, DropdownView, ToolbarView } from '@ckeditor/ckeditor5-ui';

import { clickOutsideHandler } from '@ckeditor/ckeditor5-ui';

/**
 * @class FormattingOptions
 * @extends module:core/plugin~Plugin
 * @classdesc
 * The FormattingOptions plugin provides a dropdown in the CKEditor toolbar with various formatting options.
 * It includes accessibility features and ensures that the formatting options remain open when interacting with
 * the dropdown or other parts of the editor.
 *
 * We have built this because we want to make sure the dropdown stays open when the user clicks one of the
 * options in the dropdown menu.
 */
class FormattingOptions extends Plugin {
  static get pluginName() {
    return 'FormattingOptions';
  }

  constructor( editor ) {
    super( editor );

    editor.ui.componentFactory.add( 'formattingOptions', locale => {
      const t = locale.t;
      const buttonView = new DropdownButtonView( locale );
      const panelView = new DropdownPanelView( locale );
      const dropdownView = new DropdownView( locale, buttonView, panelView );
      const toolbarView = this.toolbarView = dropdownView.toolbarView = new ToolbarView( locale );

      // Accessibility labels
      toolbarView.set( {
        ariaLabel: t( 'Formatting options toolbar' )
      } );
      dropdownView.set( {
        label: t( 'Formatting options' )
      } );

      dropdownView.extendTemplate( {
        attributes: {
          class: [ 'ck-toolbar-dropdown' ]
        }
      } );

      dropdownView.keystrokes.set( 'arrowdown', ( data, cancel ) => {
        if ( dropdownView.isOpen ) {
          toolbarView.focus();
          cancel();
        }
      } );

      dropdownView.keystrokes.set( 'arrowup', ( data, cancel ) => {
        if ( dropdownView.isOpen ) {
          toolbarView.focusLast();
          cancel();
        }
      } );

      /** The formatting options should not close when the user clicked:
       * the dropdown or it contents,
       * any editing root,
       * any floating UI in the "body" collection
       * It should close, for instance, when another (main) toolbar button was pressed, though.
       */
      dropdownView.on( 'render', () => {
        clickOutsideHandler( {
          emitter: dropdownView,
          activator: () => dropdownView.isOpen,
          callback: () => { dropdownView.isOpen = false; },
          contextElements: [
            dropdownView.element,
            ...[ ...editor.ui.getEditableElementsNames() ].map( name => editor.ui.getEditableElement( name ) ),
            document.querySelector( '.ck-body-wrapper' )
          ]
        } );
      } );

      buttonView.bind( 'isOn' ).to( dropdownView, 'isOpen' );
      buttonView.bind( 'isEnabled' ).to( dropdownView );

      buttonView.set( {
        label: 'Formatting',
        tooltip: t('Formatting options'),
        withText: true
      } );

      dropdownView.panelView.children.add( toolbarView );

      toolbarView.fillFromConfig(
        editor.config.get( 'formattingOptions' ),
        editor.ui.componentFactory
      );

      return dropdownView;
    } );
  }
}

export default FormattingOptions
