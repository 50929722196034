const InitialsSvgComponent = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="400.000000pt"
      height="400.000000pt"
      viewBox="0 0 400.000000 400.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <rect width="100%" height="100%" fill="#afafaf" />
      <g transform="translate(-5.000000,380.000000) scale(0.0800000,-0.100000)" fill="currentColor" stroke="none">
        <path
          d="M2165 3400 c-252 -18 -543 -117 -839 -286 -177 -101 -467 -287 -505
-324 -48 -47 -64 -93 -44 -132 26 -52 117 -136 156 -143 21 -4 37 -12 37 -19
0 -22 -22 -93 -82 -268 -58 -169 -62 -178 -87 -178 -36 0 -132 -50 -141 -75
-11 -31 8 -72 51 -109 24 -20 39 -42 39 -55 0 -12 -34 -125 -75 -251 -41 -126
-75 -238 -75 -251 0 -12 -18 -74 -39 -138 -58 -174 -155 -594 -142 -619 5 -10
32 -30 58 -43 26 -13 67 -37 90 -53 35 -24 49 -28 82 -23 64 8 109 41 116 85
30 188 128 589 210 861 51 169 64 201 78 201 5 0 42 -24 83 -54 48 -35 92 -58
125 -66 105 -25 299 6 451 74 103 46 388 312 551 516 195 243 380 579 428 775
24 100 26 259 4 333 -25 84 -163 208 -255 231 -56 14 -167 18 -275 11z m161
-320 c26 -10 27 -12 20 -71 -14 -140 -157 -474 -284 -664 -161 -241 -405 -488
-595 -603 -53 -32 -55 -33 -96 -18 -49 16 -97 48 -164 108 l-48 43 35 125 c40
144 70 230 173 500 41 107 84 218 95 247 15 42 31 61 81 99 115 86 193 117
490 195 188 49 246 56 293 39z"
        />
        <path
          d="M3900 3245 c-8 -2 -49 -9 -90 -15 -109 -18 -327 -92 -457 -156 -128
-63 -350 -208 -435 -283 -79 -72 -98 -115 -98 -228 0 -90 0 -91 32 -118 42
-36 76 -30 176 29 45 27 80 42 85 37 6 -6 -23 -92 -71 -213 -87 -221 -169
-448 -239 -658 -52 -158 -165 -549 -184 -634 -7 -32 -18 -60 -25 -63 -6 -2
-34 7 -60 21 -59 29 -235 47 -273 26 -51 -27 -44 -110 15 -179 39 -45 137
-110 232 -152 41 -19 83 -48 117 -83 73 -74 132 -101 218 -101 52 0 72 4 81
16 11 16 17 15 86 -2 41 -11 120 -24 175 -30 119 -14 177 -2 311 60 275 129
522 361 752 706 121 182 281 473 381 693 28 61 73 161 101 220 81 179 103 265
104 412 0 130 -12 207 -46 273 -36 72 -156 184 -296 278 -140 93 -200 118
-332 139 -59 9 -223 12 -260 5z m385 -285 c168 -82 222 -287 139 -528 -78
-227 -265 -619 -403 -847 -189 -312 -484 -666 -631 -757 -110 -69 -153 -81
-290 -86 -168 -5 -168 -5 -150 114 28 193 105 479 196 733 118 325 474 1134
523 1186 34 35 44 64 31 85 -8 12 8 21 88 49 201 70 250 81 352 78 78 -2 104
-6 145 -27z"
        />
        <path d="M330 130 l0 -100 2233 2 2232 3 3 98 3 97 -2236 0 -2235 0 0 -100z" />
      </g>
    </svg>
  );
};

export default InitialsSvgComponent;
