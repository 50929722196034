import React from 'react';
import Button from 'react-bootstrap/Button';
import { TitleFormattedMessage } from '../../../titleFormattedMessage';

interface LeaseDocumentWizardHeaderDownloadSignedPDFButtonProps {
  onClick: () => void;
}

const LeaseDocumentWizardHeaderDownloadSignedPDFButton = ({
  onClick,
}: LeaseDocumentWizardHeaderDownloadSignedPDFButtonProps) => (
  <Button onClick={onClick} variant="primary" className="mr-2 ml-2 lease-document-header__download-button">
    <TitleFormattedMessage
      id="lease-document-wizard-header.action.download"
      defaultMessage="Download"
      data-testid="lease-document-wizard-header-download-action"
    />
  </Button>
);

export default LeaseDocumentWizardHeaderDownloadSignedPDFButton;
