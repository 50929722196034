import React from 'react';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../loading-spinner';
import ShowEmailReplyModal from './ShowEmailReplyModal';
import { getDocumentFeedbackById } from '../../../api/queries_v2';

type ShowEmailReplyModalProps = {
  isOpen: boolean;
  activityId: string | null;
  onClose: () => void;
};

const ShowEmailReplyModalContainer = ({ isOpen, onClose, activityId }: ShowEmailReplyModalProps) => {
  const { data: emailData, loading: emailDataLoading, error: emailDataError } = useQuery(getDocumentFeedbackById, {
    variables: { id: activityId! },
    skip: !activityId,
  });

  if (emailDataLoading || emailDataError) {
    return <LoadingSpinner />;
  }

  return emailData ? (
    <ShowEmailReplyModal isOpen={isOpen} onClose={onClose} emailData={emailData.getDocumentFeedbackById} />
  ) : null;
};

export default ShowEmailReplyModalContainer;
