import React, { ReactNode } from 'react';
import { Field } from 'formik';

import { BaseField, BaseFieldWrap } from './base';

interface TextFieldProps {
  before?: any;
  afterField?: ReactNode;
  after?: any;
  id?: string;
  label?: string | ReactNode;
  name: string;
  text?: string;
  isShared?: boolean;
  type?: string;
  autoComplete?: string;
  disabled?: boolean;
}

const TextField = React.forwardRef(
  ({ before, afterField, after, id, label, name, text, isShared, ...rest }: TextFieldProps, ref) => {
    return (
      <BaseFieldWrap {...{ before, afterField, after, id, label, name, text, isShared }}>
        <InlineTextField ref={ref} name={name} {...rest} />
      </BaseFieldWrap>
    );
  }
);

interface InlineTextFieldProps {
  name: string;
  as?: any;
}
const InlineTextField = React.forwardRef(({ name, as = Field, ...rest }: InlineTextFieldProps, ref) => (
  <BaseField ref={ref} name={name} as={as} {...rest} />
));

export { TextField, InlineTextField };
