import React, { useState, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import { useNotifications } from '../notifications';
import { resetPassword } from '../api/auth';
import { paths, useNavigate } from '../routing';
import ErrorMessage from '../components/error-message';
import SubmitButton from '../components/submit-button';
import PasswordStrength from '../components/password-strength';
import { TextField } from '../components/fields';
import getPasswordStrength from '../utils/getPasswordStrength';

interface ResetPasswordFormProps {
  errors: { [key: string]: any };
  values: { [key: string]: any };
  isSubmitting: boolean;
  isValid: boolean;
}

const ResetPasswordForm = ({ errors, values, isSubmitting, isValid }: ResetPasswordFormProps) => {
  const [score, setScore] = useState<number | null>(null);

  const updateScore = useCallback(
    async password => {
      setScore(await getPasswordStrength(password));
    },
    [setScore]
  );

  useEffect(() => {
    updateScore(values.password);
  }, [updateScore, values.password]);

  return (
    <Form noValidate>
      {errors._general && (
        <Alert variant="danger">
          <ErrorMessage>{errors._general}</ErrorMessage>
        </Alert>
      )}

      <TextField
        id="resetPasswordForm.password"
        name="password"
        type="password"
        label={<FormattedMessage id="form.new-password.label" defaultMessage="New password" />}
        autoComplete="new-password"
        afterField={<PasswordStrength score={score} />}
      />

      <TextField
        id="resetPasswordForm.passwordConfirmation"
        name="passwordConfirmation"
        type="password"
        label={<FormattedMessage id="form.repeat-new-password.label" defaultMessage="Repeat new password" />}
        autoComplete="new-password"
      />

      <div className="text-right">
        <SubmitButton
          label={<FormattedMessage id="cta.change-password" defaultMessage="Update password" />}
          disabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
          data-testid="change-password-button"
        />
      </div>
    </Form>
  );
};

const schema = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(8)
    // @ts-ignore
    .minPasswordStrength(3),
  passwordConfirmation: yup
    .string()
    .required()
    // @ts-ignore
    .equalTo(yup.ref('password'), { id: 'phrase.passwords-must-match', defaultMessage: 'Passwords must match' }),
});

interface ResetPasswordFormikProps {
  resetPasswordToken: string;
}
const ResetPasswordFormik = ({ resetPasswordToken }: ResetPasswordFormikProps) => {
  const [, { success: notifySuccess }] = useNotifications();
  const navigate = useNavigate();

  const onSubmit = async (values: { [key: string]: any }, actions: { [key: string]: any }) => {
    // @ts-ignore
    const { success, errors } = await resetPassword({ ...values, resetPasswordToken });

    if (success) {
      navigate(paths.auth.login);
      notifySuccess({
        message: {
          id: 'phrase.reset-password.success',
          defaultMessage: 'Your password has been updated! You can now sign in.',
        },
      });
    } else {
      actions.setErrors(errors);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      component={ResetPasswordForm}
      initialValues={{ password: '', passwordConfirmation: '' }}
    />
  );
};

export default ResetPasswordFormik;
