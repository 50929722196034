import React, { ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  LeaseDocumentWizardCTAType,
  LeaseDocumentWizardCTAStack,
} from '../lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import classNames from 'classnames';

import './lease-document-wizard-modal.scss';

type LeaseDocumentWizardModalProps = {
  className?: string;
  size: 'sm' | 'lg';
  show: boolean;
  onHide: () => void;
  title?: ReactNode | string;
  children: ReactNode | string;
  modalCTAs?: Array<LeaseDocumentWizardCTAType> | null;
  titleClassName?: string;
  templateUpload?: boolean;
};

export const LeaseDocumentWizardModal = ({
  children,
  className,
  size,
  show,
  onHide,
  title,
  modalCTAs,
  titleClassName,
  templateUpload,
}: LeaseDocumentWizardModalProps) => (
  <Modal size={size} show={show} onHide={onHide} className={`lease-document-wizard-modal ${className}`} scrollable>
    <Modal.Header closeButton className={`lease-document-wizard-modal__header ${templateUpload && 'template_modal'}`}>
      <Modal.Title className={classNames('lease-document-wizard-modal__title', titleClassName)} as="h2">
        {title}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className={`lease-document-wizard-modal__body ${templateUpload && 'template_modal'}`}>
      {children}
    </Modal.Body>

    {!!modalCTAs?.length && (
      <Modal.Footer className={`lease-document-wizard-modal__footer ${templateUpload && 'template_modal'}`}>
        <LeaseDocumentWizardCTAStack buttonCTAs={modalCTAs} direction="horizontal" />
      </Modal.Footer>
    )}
  </Modal>
);
