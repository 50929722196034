import React, { useEffect, useState } from 'react';
import MobileSidebar from '../../mobile-layout/mobile-sidebar';
import { FormattedMessage } from 'react-intl';
import DropdownPanel from '../../dropdown-panel';
import { SortingForm } from './SortingForm';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../../../components/titleFormattedMessage';

import styles from './styles.module.scss';
import { StatusForm } from './StatusForm';

interface FilterSidebarProps {
  isOpen: boolean;
  filterStatuses: Set<string>;
  allStatuses: Array<string>;
  onClose: () => void;
  setFilterStatuses: (value: Set<string>) => void;
  setSorting: (value: string) => void;
}

export interface FilterData {
  sort: string;
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  isOpen,
  onClose,
  filterStatuses,
  allStatuses,
  setSorting,
  setFilterStatuses,
}) => {
  const [statuses, setStatuses] = useState(filterStatuses);

  useEffect(() => {
    setStatuses(filterStatuses);
  }, [filterStatuses]);

  const onSubmit = async (values: FilterData, actions: any) => {
    setSorting(values.sort);
    setFilterStatuses(statuses);
    onClose();
  };

  const onReset = (resetForm: any) => {
    resetForm && resetForm();
    setFilterStatuses(new Set());
    onClose();
  };

  return (
    <MobileSidebar
      header={
        <div data-testid="deals-header" role={'heading'} className={'ml-3'}>
          <FormattedMessage id="title.filter" defaultMessage="Filter" />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik onSubmit={onSubmit} initialValues={{ sort: '' }}>
        {formikProps => (
          <Form noValidate className={styles.mobileFilterForm}>
            <div>
              <DropdownPanel title="Sorting" hasBorder>
                <SortingForm />
              </DropdownPanel>
              <DropdownPanel title="All Statuses">
                <StatusForm filtered={statuses} allStatuses={allStatuses} setFilterStatuses={setStatuses} />
              </DropdownPanel>
            </div>
            <div className={styles.mobileFilterBtns}>
              <Button onClick={() => onReset(formikProps.resetForm)} variant="btn btn-outline-primary shadow-none">
                <TitleFormattedMessage id="mobileFilter.clearBtn" defaultMessage="Clear" />
              </Button>
              <Button type="submit" variant="btn btn-primary shadow-none">
                <TitleFormattedMessage id="mobileFilter.applyBtn" defaultMessage="Apply" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </MobileSidebar>
  );
};

export default FilterSidebar;
