import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import styles from './styles.module.scss';
import TeamToggleButtons from './components/teamToggleButtons';
import CounterpartySection from './counterpartySection';
import DealTeamSection from './dealTeamSection';

export enum CurrentTeamSidebarTypesEnum {
  counterparty = 'counterparty',
  dealteam = 'dealteam',
}

const DocumentTeamSidebarContainer = () => {
  const [currentTeamSidebarType, setCurrentTeamSidebarType] = useState<CurrentTeamSidebarTypesEnum>(
    CurrentTeamSidebarTypesEnum.counterparty
  );

  const renderSidebarSection = () =>
    currentTeamSidebarType === CurrentTeamSidebarTypesEnum.counterparty ? <CounterpartySection /> : <DealTeamSection />;

  return (
    <>
      <Container className={styles.teamSidebarTopToggleContainer}>
        <h2>Participants</h2>
        <TeamToggleButtons
          setCurrentTeamSidebarType={setCurrentTeamSidebarType}
          currentTeamSidebarType={currentTeamSidebarType}
        />
        {renderSidebarSection()}
      </Container>
    </>
  );
};

export default DocumentTeamSidebarContainer;
