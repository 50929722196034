import { LeaseDocumentStateType } from '../lease-document.reducer';
import { SetDocumentTrackChangesModeAction } from '../actions/lease-document.actions';

export const handleSetDocumentTrackChangesMode = (
    state: LeaseDocumentStateType,
    action: SetDocumentTrackChangesModeAction
): LeaseDocumentStateType => {
    return {
        ...state,
        trackChangesMode: action.trackChangesMode,
    };
};
