import { TeamPermissionName } from '../store/permissions/permissions.reducer';
import { Permissions } from '../shared/constants/permissions';

type TeamPermission = {
  id: string;
  key: TeamPermissionName;
};

export const getPermissionId = (teamPermissions: TeamPermission[], permissionName: Permissions) => {
  const permission = teamPermissions.find(teamPermission => teamPermission.key === permissionName);

  if (!permission) {
    console.warn(`Permission ${permissionName} is not found`);
    return;
  }

  return permission.id;
};
