import React, { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import DocumentActionsDropdownContainer from './DocumentActionsDropdownContainer';
import LoadingSpinner from '../loading-spinner';
import { useDocumentRole } from '../../shared/hooks/useDocumentRole/useDocumentRole';
import { getDocumentStatusIndicator } from './helpers/documentStatusIndication';
import { DocumentStatusEnum } from '../../shared/constants/leaseDocument';
import { ReactComponent as DangerIcon } from '../../shared/icons/alert-danger-icon.svg';
import {
  ApprovalRequestThreshold,
  InternalApprovalReview,
} from '../lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/RequestInternalApprovalModalConstants';
import Tooltip from '../tooltip';
import { InternalApprovalApproversTooltipTable } from '../lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/InternalApprovalApproversTooltipTable';
import { approversCounter } from '../lease-wizard/lease-document-wizard-header/components/headerActions/requestReview/InternalApprovalBadgeContainer';
import {
  signersCounter,
  SignersTooltipTable,
} from '../lease-wizard/lease-document-wizard-header/components/headerActions/sendToPreparingToSignInternal/signingProcessBadgeContainer';
import { DocumentSigner } from '../../store/lease-document/actions/set-lease-document-signers.action';
import { ColumnsCellType, DocumentTableColumnProps } from './helpers/documentTableTypes';
import styles from './DocumentTable.module.scss';
import { defineAlertableDocumentTooltipMessage } from './helpers/documentDefinitionHelpers';

export const DocumentTableColumns = ({
  alertableDocumentsIDs,
  teamId,
  dealId,
  handleEditDocument,
  isDealShared,
  permissions,
}: DocumentTableColumnProps) => [
  {
    Header: <FormattedMessage id="title.documents.name" defaultMessage=" Name" />,
    accessor: 'name',
    Cell: ({
      cell: {
        value,
        row: {
          values: { status, document },
        },
      },
    }: ColumnsCellType) => {
      const isDocumentAlertable = alertableDocumentsIDs?.includes(document.id);
      const tooltipMessage = defineAlertableDocumentTooltipMessage({ documentStatus: status });

      return (
        <span className={styles.documentTitle} data-testid={'document-name-cell'}>
          {isDocumentAlertable && (
            <span className="mr-2">
              <Tooltip
                testId="alertable-icon-tooltip"
                icon={<DangerIcon width={20} height={20} data-testid={'alertable-icon'} />}
                placement="bottom-start"
              >
                <FormattedMessage id="cta.go-to.details" defaultMessage={tooltipMessage} />
              </Tooltip>
            </span>
          )}
          <FormattedMessage id="cta.go-to.details" defaultMessage={value} />
        </span>
      );
    },
  },
  {
    Header: <FormattedMessage id="title.deals.template-status" defaultMessage="Status" />,
    accessor: 'status',
    Cell: ({
      cell: {
        row: {
          values: { status, document },
        },
      },
    }: ColumnsCellType) => {
      const documentStatus = getDocumentStatusIndicator(status, document.currentTeamPossession);
      const approvalRequestedReviews = document.approvalRequest?.reviews;
      const isOneConfirmationRequired = document.approvalRequest?.threshold === ApprovalRequestThreshold.ONE;
      const approvedReviewsRequests =
        approvalRequestedReviews?.filter((request: InternalApprovalReview) => !!request.approvedAt).length || 0;
      const totalApprovalReviewRequests = approvalRequestedReviews?.length || 0;

      const totalSigners = document.signers?.length || 0;
      const hasSignedNumber = document.signers?.filter((signer: DocumentSigner) => !!signer.signedAt).length || 0;

      const statusForDocumentWithActiveInternalApproval = () => (
        <div>
          <span>
            <FormattedMessage id="document-internal-approval.badge" defaultMessage="Approval Pending" />
          </span>
          <span className="pl-2">
            <Tooltip
              testId="internal-approvers-counter"
              icon={approversCounter({
                approvedReviewsRequests,
                isOneConfirmationRequired,
                totalApprovalReviewRequests,
              })}
              placement="bottom-end"
            >
              {isOneConfirmationRequired && <p className={styles.internalApprovalTooltipTitle}>1 Approval Required</p>}
              <InternalApprovalApproversTooltipTable approvalRequestedReviews={approvalRequestedReviews} />
            </Tooltip>
          </span>
        </div>
      );

      const statusForSigningDocument = () => (
        <div>
          <span>
            <FormattedMessage id="document-internal-approval.badge" defaultMessage="Signing" />
          </span>
          <span className="pl-2">
            <Tooltip
              testId="signers-approvers-counter"
              icon={signersCounter({
                hasSignedNumber,
                totalSigners,
              })}
              placement="bottom-end"
            >
              <SignersTooltipTable signers={document.signers || []} />
            </Tooltip>
          </span>
        </div>
      );

      return (
        <span className={styles.documentStatus} data-testid={'document-status-cell'}>
          {documentStatus === 'Approval Pending' ? (
            statusForDocumentWithActiveInternalApproval()
          ) : documentStatus === DocumentStatusEnum.SIGNING ? (
            statusForSigningDocument()
          ) : (
            <FormattedMessage id="phrase.template-status-readout" defaultMessage={documentStatus} values={{ status }} />
          )}
        </span>
      );
    },
  },
  {
    Header: <FormattedMessage id="title.documents.access" defaultMessage="Access" />,
    accessor: 'access',
    Cell: ({
      cell: {
        row: {
          values: { access },
        },
      },
    }: ColumnsCellType) => <span data-testid={'document-access-cell'}>{access}</span>,
  },
  {
    Header: (
      <span className="sr-only">
        <FormattedMessage id="title.deals.detailsLink" defaultMessage="Link to Deal Details" />
      </span>
    ),
    accessor: 'document',
    Cell: ({
      cell: {
        row: { values },
      },
    }: ColumnsCellType) => {
      const documentId = values.document.id;
      const hasCurrentTeamPossession = values.document.currentTeamPossession;
      const documentStatus = values.document.status;
      const { isDocumentCreator, isDocumentRoleLoading } = useDocumentRole(documentId);

      if (isDocumentRoleLoading) {
        return <LoadingSpinner />;
      }
      return (
        <div
          onClick={(evt: MouseEvent) => {
            evt.stopPropagation();
          }}
          className={styles.documentDropdownContainer}
        >
          <DocumentActionsDropdownContainer
            teamId={teamId}
            dealId={dealId}
            isDealShared={isDealShared}
            isDocumentCreator={isDocumentCreator}
            permissions={permissions}
            viewAction={() => handleEditDocument(documentId, hasCurrentTeamPossession, documentStatus)}
            documentData={values.document}
          />
        </div>
      );
    },
    disableSortBy: true,
  },
];
