import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { ReactComponent as DangerIcon } from '../../../shared/icons/alert-danger-icon.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface Props {
  title?: string;
  text: string | JSX.Element;
  shouldBeCentered?: boolean;
  largeCentered?: boolean;
}

const Warning = ({ text, title, shouldBeCentered = false, largeCentered = false }: Props) => {
  const centerValueOne = () => {
    if (shouldBeCentered) {
      return 3;
    }
    if (largeCentered) {
      return 2;
    }
    return 1
  }

  const centerValueTwo = () => {
    if (shouldBeCentered) {
      return 9;
    }
    if (largeCentered) {
      return 10;
    }
    return 11
  }
  return (
    <Alert variant="warning" className={classNames('px-1', styles.wrapper)}>
      <Row>
        <Col xs={centerValueOne()} className="d-flex justify-content-end p-0">
          <Row className="m-0">
            <DangerIcon width={20} height={20} />
          </Row>
        </Col>
        <Col xs={centerValueTwo()}>
          {title && (
            <Row className="m-0" data-testid="warning-title">
              <Alert.Heading className={classNames('mb-1', styles.title)}>{title}</Alert.Heading>
            </Row>
          )}
          <Row className={classNames('m-0', styles.text)} data-testid="warning-text">
            {text}
          </Row>
        </Col>
      </Row>
    </Alert>
  );
};

export default Warning;
