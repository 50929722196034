export enum DefaultNotificationMessages {
  NOT_READY_TO_BE_SIGNED = 'This Document is not ready to be signed. We have let the other party know you are prepared to sign the document.',
  START_CONVERSATION = 'Start conversation about this section by making a comment.',
  NO_ACCESS_TO_FEATURE = 'You do not have access to this feature. Please <a>contact Propdocs</a>.',
  ERROR_CREATING_THE_OFFER = 'There was an error creating Document.',
  UNKNOWN_ERROR = 'An unknown error has occurred. Please contact support.',
  NOT_PERMITTED_TO_SIGN_LEASE = 'You are not permitted to sign this Document.',
  ERROR_SIGNING_THE_LEASE = 'There was an error signing the Document.',
  SUCCESSFULLY_SIGNED_LEASE = 'You have successfully signed the lease.',
  DECLINED_LEASE = 'You have declined the Document.',
}
