// @ts-nocheck
import React, { useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { useTeamInfo } from '../../../team-id-context';
import { graphqlErrorsToUiErrors, useTeamPermissions } from '../../../api';
import LoadingSpinner from '../../loading-spinner';
import ErrorMessage from '../../error-message';
import { mutatePermissions } from '../utils';
import filterPermissions from '../../../utils/filter-permissions';
import { PermissionsChart } from '../permissionsChart';
import Toggle from '../../toggle';
import { Permissions } from '../../../shared/constants/permissions';
import { usePrevious } from '../../../utils/use-previous';
import { getPermissionId } from '../../../utils/permissions';
import {UserPermissionsType} from "../../../forms/addTeamMemberForm";

interface PermissionsFragmentProps {
  userPermissions: UserPermissionsType
}

const EditPermissionsFormFragment = ({ userPermissions }: PermissionsFragmentProps) => {
  const { teamId } = useTeamInfo();
  const { loading: isTeamPermissionsLoading, teamPermissions, error: graphqlError } = useTeamPermissions(teamId);
  const {
    values: { defaultPermissions },
    setFieldValue,
  } = useFormikContext();
  const editPermissionId = getPermissionId(teamPermissions, Permissions.Edit);
  const commentPermissionId = getPermissionId(teamPermissions, Permissions.Comment);
  const approvePermissionId = getPermissionId(teamPermissions, Permissions.Approve);
  const isEditEnabled = defaultPermissions.includes(editPermissionId);
  const isCommentEnabled = defaultPermissions.includes(commentPermissionId);
  const isApproveEnabled = defaultPermissions.includes(approvePermissionId);
  const prevPermissions = usePrevious(defaultPermissions);
  const prevDefaultPermissions = useMemo(() => prevPermissions || [], [prevPermissions]);

  useEffect(() => {
    const isPrevEditEnabled = prevDefaultPermissions.includes(editPermissionId);

    if (!isPrevEditEnabled && isEditEnabled && !isCommentEnabled) {
      setFieldValue('defaultPermissions', [...defaultPermissions, commentPermissionId]);
    }
  }, [
    defaultPermissions,
    commentPermissionId,
    editPermissionId,
    isCommentEnabled,
    isEditEnabled,
    setFieldValue,
    prevDefaultPermissions,
  ]);

  useEffect(() => {
    const isPrevApproveEnabled = prevDefaultPermissions.includes(approvePermissionId);

    if (!isPrevApproveEnabled && isApproveEnabled && !isEditEnabled) {
      setFieldValue('defaultPermissions', [...defaultPermissions, editPermissionId]);
    }
  }, [
    defaultPermissions,
    approvePermissionId,
    editPermissionId,
    isApproveEnabled,
    isEditEnabled,
    prevDefaultPermissions,
    setFieldValue,
  ]);

  if (isTeamPermissionsLoading) {
    return <LoadingSpinner />;
  }

  if (!teamPermissions) {
    const errors = graphqlErrorsToUiErrors(graphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  const isFieldDisabled = permissionKey => {
    const isComment = permissionKey === Permissions.Comment;
    const isEdit = permissionKey === Permissions.Edit;

    const isCommentDisabled = isEditEnabled && isComment;
    const isEditDisabled = isApproveEnabled && isEdit;

    return isCommentDisabled || isEditDisabled;
  };

  const allPermissions = mutatePermissions(teamPermissions.filter(filterPermissions));

  return (
    <PermissionsChart
      allPermissions={allPermissions}
      renderState={permission =>
        permission.key === Permissions.View ? (
          <>
            <Field as={Toggle} value="" disabled checked />
            <Field type="hidden" name="defaultPermissions" value={permission.id} />
          </>
        ) : (
          <Field
            as={Toggle}
            name="defaultPermissions"
            value={permission.id}
            disabled={isFieldDisabled(permission.key)}
            checked={defaultPermissions.includes(permission.id)}
            // update this, since admin permission is removed
            // isCustomDisabledState={isAdmin(permission.key)}
            isCustomDisabledState={false} // temp
          />
        )
      }
    />
  );
};

export default EditPermissionsFormFragment;
