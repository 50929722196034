import React, { ComponentType, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';

import Alert from 'react-bootstrap/Alert';

import { paths, A } from '../routing';
import { sendForgotPasswordEmail } from '../api/auth';
import ErrorMessage from '../components/error-message';
import SubmitButton from '../components/submit-button';
import { TextField } from '../components/fields';

import styles from './styles.module.scss';
import { NavLink } from 'react-router-dom';

interface ForgotPasswordFormProps {
  errors?: { [key: string]: any };
  values: { [key: string]: any };
  isSubmitting: boolean;
  isValid: boolean;
  status: any;
  setStatus: (...args: any[]) => any;
  validateForm: (...args: any[]) => any;
}

const ForgotPasswordForm = ({
  errors = {},
  status,
  setStatus,
  values,
  isSubmitting,
  isValid,
  validateForm,
}: ForgotPasswordFormProps) => (
  <Form noValidate>
    {status === 'success' && (
      <div className="body-text">
        <h1>
          <FormattedMessage id="title.reset-link-sent" defaultMessage="Reset link sent" />
        </h1>

        <p className="lead">
          <FormattedMessage
            id="phrase.forgot-password.email-sent"
            defaultMessage="We’ve sent a reset link to your email address. Click on the link in your email to create a new password."
          />
        </p>

        <p>
          <FormattedMessage id="phrase.forgot-password.no-email-prompt" defaultMessage="Didn’t receive an email?" />
          <br />
          <FormattedMessage
            id="phrase.forgot-password.check-spam-or-resend"
            defaultMessage="Check your spam folder or <a>resend the email</a>."
            values={{
              a: (msg: any) => (
                <A
                  href={paths.auth.forgotPassword}
                  // @ts-ignore
                  onClick={() => {
                    setStatus(null);
                    validateForm(); // Submit button is greyed out otherwise; form says it's invalid, unsure why
                  }}
                >
                  {msg}
                </A>
              ),
            }}
          />
        </p>
      </div>
    )}

    {status !== 'success' && (
      <>
        <div className="body-text">
          <h1>
            <FormattedMessage id="title.forgot-password" defaultMessage="Forgot password?" />
          </h1>

          <p className="lead">
            <FormattedMessage
              id="phrase.forgot-password.instructions"
              defaultMessage="Enter your work email address and we’ll send you a link to create a new password."
            />
          </p>
        </div>

        {errors._general && (
          <Alert variant="danger">
            <ErrorMessage>{errors._general}</ErrorMessage>
          </Alert>
        )}

        <TextField
          id="forgotPasswordForm.email"
          name="email"
          type="email"
          label={<FormattedMessage id="form.email.label" defaultMessage="Email" />}
          autoComplete="username"
        />

        <div className="text-right mt-5">
          <SubmitButton
            label={<FormattedMessage id="cta.send-reset-link-email" defaultMessage="Send reset link" />}
            disabled={isSubmitting || !isValid}
            isSubmitting={isSubmitting}
            data-testid="password-send-email-button"
            className="w-100"
          />
        </div>
        <div className={styles.dividerContainer}>
          <div className={styles.divider}>
            <FormattedMessage id="cta.sign.or" defaultMessage="OR" />
          </div>
        </div>
        <p className="body-text text-center pt-4">
          <NavLink to={paths.auth.login} data-testid="sign-up">
            <FormattedMessage id="cta.go-to.sign-in" defaultMessage="Sign In" />
          </NavLink>
        </p>
      </>
    )}
  </Form>
);

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});

const ForgotPasswordFormik = () => {
  const [email, setEmail] = useState('');

  const onSubmit = async ({ email }: { email: any }, actions: { [key: string]: any }) => {
    const { success, errors } = await sendForgotPasswordEmail({ email });

    if (success) {
      setEmail(email);
      actions.setStatus('success');
    }
    actions.setErrors(errors);
  };

  const FormComponent = ForgotPasswordForm as ComponentType<FormikProps<{ email: string }>>;

  return <Formik validationSchema={schema} onSubmit={onSubmit} component={FormComponent} initialValues={{ email }} />;
};

export default ForgotPasswordFormik;
