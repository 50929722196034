import React from 'react';
import { ModalActionsEnum, ModalActionTitle } from '../../../../../document-table/DocumentTableConstants';
import { LeaseDocumentWizardActionModal } from '../../../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/LeaseDocumentWizardActionModal';

interface Props {
  show: boolean;
  onHide: () => void;
  isDealShared: boolean;
  handler: () => void;
  isLoading: boolean;
  suggestionsAlert: { title: string; text: string } | null;
  optionalMessageInputRef: any;
  teamId: string;
}

const GrantAccessModal = ({
  show,
  onHide,
  isDealShared,
  handler,
  isLoading,
  suggestionsAlert,
  optionalMessageInputRef,
  teamId,
}: Props) => {
  return (
    <LeaseDocumentWizardActionModal
      show={show}
      onHide={onHide}
      isDealShared={isDealShared}
      handler={handler}
      isPossessionPending={isLoading}
      title={ModalActionTitle.GRANT_ACCESS}
      activeModal={ModalActionsEnum.GRANT_ACCESS}
      suggestionsAlert={suggestionsAlert}
      optionalMessageInputRef={optionalMessageInputRef}
      teamId={teamId}
    />
  );
};

export default GrantAccessModal;
