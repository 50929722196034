import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { ReactComponent as EditIcon } from '../../../../shared/icons/edit.svg';
import { ReactComponent as ConfirmIcon } from '../../../../shared/icons/checkmark-icon.svg';
import { ReactComponent as CancelIcon } from '../../../../shared/icons/cross-icon.svg';
import { mutations } from '../../../../api';
import { getDealById, getTeamById } from '../../../../api/queries';
import { useTeamInfo } from '../../../../team-id-context';
import styles from '../../../deals/styles.module.scss';

interface Props {
  dealId?: string;
  title: string;
  isDealTitleEditing?: boolean;
  isTeamTitleEditing?: boolean;
  className?: string;
}

const KEY_CODE_ENTER = 13;

const InputTitle = ({ dealId, title, isDealTitleEditing, isTeamTitleEditing, className }: Props) => {
  const dealTitleInputRef = useRef<HTMLInputElement>(null);
  const dealTitle = dealTitleInputRef?.current?.querySelector('span#deal-title') as HTMLElement;
  const { teamId } = useTeamInfo();

  const [updateDealDetails] = useMutation(mutations.updateDealDetails, {
    refetchQueries: [
      {
        query: getDealById,
        variables: {
          dealId,
        },
      },
    ],
  });

  const [updateTeam] = useMutation(mutations.updateTeam, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: {
          teamId,
        },
      },
    ],
  });

  const mutationForTitleUpdate = isDealTitleEditing ? updateDealDetails : updateTeam;

  const [isTitleEditing, setIsTitleEditing] = useState(false);

  useEffect(() => {
    isTitleEditing && dealTitle.focus();
  }, [isTitleEditing, dealTitle]);

  const handleKeyDown = useCallback(evt => {
    if (evt.keyCode === KEY_CODE_ENTER) {
      evt.preventDefault();
    }
  }, []);

  const submitDealTitleChange = useCallback(async () => {
    const dealTitleValue = dealTitleInputRef.current?.innerText;

    if (!!dealTitleValue?.trim()) {
      try {
        await mutationForTitleUpdate({
          variables: {
            dealId,
            id: teamId,
            title: dealTitleValue,
            name: dealTitleValue,
          },
        });
        setTimeout(() => {
          dealTitle.innerText = dealTitleValue;
        }, 400);
      } finally {
        setIsTitleEditing(false);
      }
    }
  }, [dealId, dealTitle, mutationForTitleUpdate, teamId]);

  const handleTitleCancel = useCallback(() => {
    setTimeout(() => {
      setIsTitleEditing(false);
      dealTitle.innerText = title;
    }, 400);
  }, [title, dealTitle]);

  return (
    <div className={classNames("d-flex align-items-end", className)}>
      <span
        className={classNames(styles.titleInput, isTeamTitleEditing && styles.teamTitleInput)}
        ref={dealTitleInputRef}
        onKeyDown={handleKeyDown}
        onBlur={handleTitleCancel}
      >
        <span
          role={'heading'}
          id="deal-title"
          contentEditable={isTitleEditing}
          suppressContentEditableWarning
          className={isTitleEditing ? styles.titleInput__title : ''}
        >
          {title}
        </span>
        {isTitleEditing && (
          <span className={styles.titleInputButtons}>
            <ConfirmIcon className={styles.titleInputButtons__confirm} onClick={submitDealTitleChange} />
            <CancelIcon className={styles.titleInputButtons__cancel} onClick={handleTitleCancel} />
          </span>
        )}
      </span>

      <span className={styles.titleInputButtons}>
        {!isTitleEditing && (
          <EditIcon
            className={classNames('ml-1', styles.titleInputButtons__edit)}
            onClick={() => {
              setIsTitleEditing(true);
            }}
          />
        )}
      </span>
    </div>
  );
};

export default InputTitle;
