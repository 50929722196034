import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { paths, useNavigate } from '../../routing';
import { useQuery } from '@apollo/client';
import { graphqlErrorsToUiErrors, queries } from '../../api';
import MagicLinkWrapper from './components/MagicLinkWrapper';
import LoadingSpinner from '../../components/loading-spinner';
import styles from './style.module.scss';
import RegisterInvitedCounterpartyForm from '../../forms/RegisterInvitedCounterpartyForm';
import ExpiredAuthTokenView from './components/ExpiredAuthTokenView';
import { useAuthTokenRedirect } from './helpers/authHelpers';

const CounterpartyInvite = ({ withConfirmation }: { withConfirmation?: boolean }) => {
  const { token } = useParams();
  const navigate = useNavigate();

  const { loading, data: invitationData, error: graphqlError } = useQuery(queries.getInvitationByToken, {
    skip: !token || withConfirmation,
    variables: {
      token: token!,
    },
  });

  // If the invite is already accepted then redirect the user to their deal
  const authToken = invitationData?.getInvitationByToken?.onboarding?.loginAuthToken || undefined;
  const redirectTo = invitationData?.getInvitationByToken?.onboarding?.redirectTo || undefined;
  const { isLoadingToken, expiredToken } = useAuthTokenRedirect({ token: authToken, redirectTo });
  const isDoc = invitationData?.getInvitationByToken?.document?.id;

  if (loading || isLoadingToken) {
    return (
      <MagicLinkWrapper loading={loading} background={'dealDetailsPage'}>
        <LoadingSpinner />
      </MagicLinkWrapper>
    );
  }

  if (graphqlError || !invitationData || !token || expiredToken) {
    let error = graphqlErrorsToUiErrors(graphqlError);
    if (error.invitationToken?.id === 'form.error.api.invitation-cancelled') {
      // @ts-ignore
      error = {
        id: 'phrase.register.invitation-cancelled',
        defaultMessage: error.invitationToken.defaultMessage,
      };
    } else if (error._general?.id === 'api.error.not-found') {
      // @ts-ignore
      error = {
        id: 'phrase.register.token-invalid',
        defaultMessage: 'This invitation link is invalid.',
      };
    }
    return <ExpiredAuthTokenView token={token || ''} />;
  }

  const invitation = invitationData.getInvitationByToken;
  if (invitation?.status === 'approved') {
    navigate(paths.home);
  }

  return (
    <MagicLinkWrapper
      loading={loading}
      background={isDoc ? 'blankDocPage' : 'dealDetailsPage'}
      titleMessage={{ id: 'title.join-the-team', defaultMessage: 'Welcome to Propdocs!' }}
    >
      <p className={styles.magicLinkSubHeader}>
        <FormattedMessage
          id="phrase.register.you-were-invited-to-join-team"
          defaultMessage={invitation?.onboarding.tagline}
          values={{
            b: (msg: string) => <strong>{msg}</strong>,
            senderName: invitation!.senderName,
            teamName: invitation!.teamName,
          }}
        />
      </p>

      <RegisterInvitedCounterpartyForm token={token} invitation={invitation} />
    </MagicLinkWrapper>
  );
};

export default CounterpartyInvite;
