import CancelExternalSigning from './cancelExternalSigning';
import React, { useState } from 'react';
import CancelExternalSigningModal from './cancelExternalSigningModal';

interface Props {
  className: string;
  dealId: string;
  teamId: string;
}

const CancelExternalSigningContainer = ({ dealId, teamId, className }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <CancelExternalSigning onClick={() => setShowModal(true)} className={className} />
      <CancelExternalSigningModal show={showModal} onHide={() => setShowModal(false)} dealId={dealId} teamId={teamId} />
    </>
  );
};

export default CancelExternalSigningContainer;
