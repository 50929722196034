export const HIGHLIGHT_CONFIG = {
  options: [
    {
      model: 'yellowMarker',
      class: 'marker-yellow',
      title: 'Yellow Marker',
      color: 'var(--ck-highlight-marker-yellow)',
      type: 'marker',
    },
  ],
};

export const FONT_SIZE_CONFIG = {
  options: ['default', 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  supportAllValues: true,
};

export const HEADER_FOOTER_FONT_SIZE_CONFIG = {
  options: ['default', 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  supportAllValues: true,
  // options: [
  //   '8pt',
  //   '9pt',
  //   '10pt',
  //   '11pt',
  //   '12pt',
  //   '13pt',
  //   '14pt',
  //   '15pt',
  //   '16pt',
  //   '17pt',
  //   '18pt',
  //   '19pt',
  //   '20pt',
  //   '21pt',
  //   '22pt',
  //   '23pt',
  //   '24pt',
  // ].map(val => {
  //   let valueToNum = val.split('pt').join('');

  //   return {
  //     model: `${Number(valueToNum) * 0.575}pt`,
  //     title: `${valueToNum}`,
  //     view: {
  //       name: 'span',
  //       styles: {
  //         'font-size': `${val}`,
  //       },
  //     },
  //   };
  // }),
  // supportAllValues: true,
};

export const FONT_FAMILY_CONFIG = {
  options: [
    'Arial, Helvetica, sans-serif',
    'Courier New, Courier, monospace',
    'Georgia, serif',
    'Lucida Sans Unicode, Lucida Grande, sans-serif',
    'Tahoma, Geneva, sans-serif',
    'Times New Roman, Times, serif',
    'Trebuchet MS, Helvetica, sans-serif',
    'Verdana, Geneva, sans-serif',
  ],
  supportAllValues: true,
};

export const FONT_COLOR_CONFIG = {
  colors: [
    {
      color: 'hsl(0, 0%, 0%)',
      label: 'Black',
    },
    {
      color: 'hsl(0, 0%, 60%)',
      label: 'Grey',
    },
    {
      color: 'hsl(0, 0%, 100%)',
      label: 'White',
      hasBorder: true,
    },
    {
      color: 'hsl(0, 75%, 60%)',
      label: 'Red',
    },
    {
      color: '#F0CA70',
      label: 'Yellow',
    },
    {
      color: '#DBFED5',
      label: 'Lime',
    },
    {
      color: '#43AFA4',
      label: 'Aqua',
    },
    {
      color: '#006C90',
      label: 'Navy',
    },
  ],
  columns: 4,
};

export const FONT_BG_COLOR_CONFIG = {
  colors: [
    {
      color: 'hsl(0, 75%, 60%)',
      label: 'Red',
    },
    {
      color: 'hsl(30, 75%, 60%)',
      label: 'Orange',
    },
    {
      color: 'hsl(60, 75%, 60%)',
      label: 'Yellow',
    },
    {
      color: 'hsl(90, 75%, 60%)',
      label: 'Light green',
    },
    {
      color: 'hsl(120, 75%, 60%)',
      label: 'Green',
    },
    // More colors.
    // ...
  ],
};

export const PAGINATION_CONFIG = {
  pageWidth: '8.5in',
  pageHeight: '11in',
  pageMargins: {
    top: '1in',
    bottom: '1in',
    left: '1in',
    right: '1in',
  },
  enableOnUnsupportedBrowsers: true,
};

export const TABLE_CONFIG = {
  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],

  tableProperties: {
    borderColors: FONT_COLOR_CONFIG.colors,
    backgroundColors: FONT_COLOR_CONFIG.colors,
  },

  tableCellProperties: {
    borderColors: FONT_COLOR_CONFIG.colors,
    backgroundColors: FONT_COLOR_CONFIG.colors,
    defaultProperties: {
      verticalAlignment: 'top',
    }
  },
};

export const ALIGNMENT_CONFIG = {
  options: ['left', 'center', 'right', 'justify'],
};
