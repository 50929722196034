import React from 'react';
import styles from './styles.module.scss'; // Make sure to import the CSS file

const ElementLoadingSpinner = () => {
  return (
    <div className={styles.spinner_container}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default ElementLoadingSpinner;
