import SendToExternalSigningModal from './sendToExternalSigningModal';
import React, { useState } from 'react';
import SendToExternalSigning from './sendToExternalSigning';

interface Props {
  documentId: string;
  dealId: string;
  teamId: string;
}

const SendToExternalSigningContainer = ({ documentId, dealId, teamId }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SendToExternalSigning onClick={() => setShowModal(true)} />
      <SendToExternalSigningModal
        show={showModal}
        onHide={() => setShowModal(false)}
        dealId={dealId}
        documentId={documentId}
        teamId={teamId}
      />
    </>
  );
};

export default SendToExternalSigningContainer;
